import Colors from '@/helpers/Colors'
import * as PIXI from 'pixi.js'
import MainBitmapText from '../pixi-scale/MainBitmapText'

export default class ButtonComponent extends PIXI.Container {
    public pixiBg = PIXI.Sprite.from('btn')
    public pixiText = new MainBitmapText('', {
        fontSize: 6
    })
    public onClick = () => {
        return
    }

    constructor(
        public text: string,
        protected colorDefault = Colors.Red500,
        protected colorOver = Colors.Red400,
        protected colorClick = Colors.Red300
    ) {
        super()
        this.addChild(this.pixiBg)
        this.addChild(this.pixiText)

        this.updateText(text)

        this.pixiBg.interactive = true
        this.pixiBg.cursor = 'pointer'
        this.pixiBg.tint = this.colorDefault
        this.pixiBg.on('mouseover', () => {
            this.pixiBg.tint = this.colorOver
        })
        this.pixiBg.on('mouseout', () => {
            this.pixiBg.tint = this.colorDefault
        })
        this.pixiBg.on('pointerdown', () => {
            this.pixiBg.tint = this.colorClick
        })
        this.pixiBg.on('pointerup', () => {
            this.pixiBg.tint = this.colorDefault
        })
        this.pixiBg.on('pointertap', () => {
            this.onClick()
        })
    }

    public updateText(text: string) {
        this.text = text
        this.pixiText.text = text
        this.pixiText.x = Math.floor(this.pixiBg.width / 2 - this.pixiText.width / 2)
        this.pixiText.y = 1
    }
}
