import Colors from '@/helpers/Colors'
import GalaxyService from '@/services/GalaxyService'
import * as PIXI from 'pixi.js'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import MapService from '../services/MapService'
import PlayerService from '../services/PlayerService'
import RankingSessionService from '../services/RankingSessionService'

export default class MiniLeaderboardComponent extends PIXI.Container {
    private graphics: PIXI.Graphics = new PIXI.Graphics()
    private timeText = new MainBitmapText('~ ---- mins', { fontSize: 4 })
    private rankText = new MainBitmapText('---', { fontSize: 4 })
    private rankPoints = new MainBitmapText('---------- pts', { fontSize: 4 })

    public onRefreshed = () => {
        return
    }

    constructor() {
        super()

        this.timeText.y = 3
        this.rankPoints.y = 10
        this.rankText.y = 10

        this.addChild(this.graphics)
        this.addChild(this.timeText)
        this.addChild(this.rankText)
        this.addChild(this.rankPoints)

        this.onResize()

        PlayerService.getInstance().setOnCurrentPlayerChange(miner => {
            this.refresh()
        })

        document.addEventListener(MapService.CURRENT_SOLAR_SYSTEM_CHANGED, () => {
            this.refresh()
        })

        this.refresh()

        setInterval(() => {
            this.refresh()
        }, 60000)
    }

    public async refresh() {
        const solarSystemID = MapService.getInstance().currentSolarSystemId

        if (!GalaxyService.getInstance().canDisplayLeaderboard(solarSystemID)) return

        const remainingBlock = await RankingSessionService.getInstance().remainingBlock()

        if (remainingBlock >= 0) {
            const remainingSecond = remainingBlock * RankingSessionService.AVERAGE_TIME_BY_BLOCK // 2.5 seconds by block
            this.timeText.text = '~ ' + Math.round(remainingSecond / 60) + ' Mins'
            this.onResize()

            const lastSession = await RankingSessionService.getInstance().lastSession()
            const startBlock = RankingSessionService.getInstance().startBlockFor(lastSession)
            const sorted = await RankingSessionService.getInstance().loadSession(solarSystemID, startBlock, null)

            const foundLineIndex = sorted.findIndex(
                (it: any) => it.ship == PlayerService.getInstance().miner.id && it.owner == PlayerService.getInstance().miner.owner.toLowerCase()
            )

            if (foundLineIndex >= 0) {
                const foundLine = sorted[foundLineIndex]
                this.rankPoints.text = foundLine.score + ' pts'
                this.rankText.text = foundLineIndex + 1
            } else {
                this.rankText.text = '---'
                this.rankPoints.text = '--------'
            }
        } else {
            this.rankText.text = '---'
            this.rankPoints.text = '--------'
        }
        this.onResize()
        this.onRefreshed()
    }

    private onResize() {
        this.graphics.clear()
        this.graphics.lineStyle(1, Colors.Gold).beginFill(Colors.GoldDarker)

        const rankWidth = this.rankText.width + 4 + this.rankPoints.width + 4 + 1
        this.graphics.drawRoundedRect(0, 9, rankWidth, this.rankPoints.height + 2, 1)

        this.graphics.drawRoundedRect(rankWidth - this.timeText.width - 4, 2, this.timeText.width + 4, this.timeText.height + 2, 1)
        this.graphics.moveTo(this.rankText.width + 4, 9)
        this.graphics.lineTo(this.rankText.width + 4, 16)

        this.timeText.x = rankWidth - this.timeText.width - 2
        this.rankText.x = 2
        this.rankPoints.x = this.rankText.width + 6
    }
}
