import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import NotificationService from '../NotificationService'
import { Transaction } from '@/game/models/Transaction'
import Events from '@/game/models/Events'
import PlayerService from '../PlayerService'
import { Notification } from '@/game/models/notification/Notification'
import { GasStationService } from '../GasStationService'
import { Web3Helper } from '@/helpers/Web3Helper'

export default class RoverManagerService {
    public static MINED_EVENT = 'mined'
    public static ROVER_DEPOSED_EVENT = 'roverDeposed'
    public static ROVER_HARVEST_EVENT = 'roverHarvest'

    public static instance: RoverManagerService

    public static getInstance(): RoverManagerService {
        if (!this.instance) {
            this.instance = new RoverManagerService()
        }
        return this.instance
    }

    private abi = [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'cometToken',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'time',
                    type: 'uint256'
                }
            ],
            name: 'RoverCollected',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'time',
                    type: 'uint256'
                }
            ],
            name: 'RoverDeposited',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'balance',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'roverCount',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'cumulatedRate',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'collectable',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'lastUpdate',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'StakingCometUpdated',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'timestamp',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'depositRover',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'timestamp',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'collectRover',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'time',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'canMine',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newCometManager',
                    type: 'address'
                }
            ],
            name: 'updateCometManager',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updateSolarSystemStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newGateway',
                    type: 'address'
                }
            ],
            name: 'updateGateway',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'newDeltaMiningTime',
                    type: 'uint256'
                }
            ],
            name: 'updateDeltaMiningTime',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint32',
                    name: 'distance',
                    type: 'uint32'
                }
            ],
            name: 'updateNoMiningZone',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                }
            ],
            name: 'roverOn',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                }
            ],
            name: 'roverOnSolarSystem',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256[]',
                    name: 'minerIds',
                    type: 'uint256[]'
                }
            ],
            name: 'roverOnOf',
            outputs: [
                {
                    internalType: 'address[]',
                    name: '',
                    type: 'address[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'noMiningZone',
            outputs: [
                {
                    internalType: 'uint32',
                    name: '',
                    type: 'uint32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    private writeRoverManager: any
    private readRoverManager: any

    constructor() {
        this.initContract()
        this.listenUpdateStakingCometEvent()
    }

    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        this.writeRoverManager = new web3.eth.Contract(this.abi, config.roverManagerAddress, { gasLimit: '1000000' })

        const wsWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readRoverManager = new wsWeb3.eth.Contract(this.abi, config.roverManagerAddress)
    }

    public async roverOnOf(minerIds: string[]): Promise<string[]> {
        return await this.readRoverManager.methods.roverOnOf(minerIds).call()
    }

    public async depositRover(minerId: string, cometAddr: string, time: number, solarSystemID: number, gasPriceGWEI?: number) {
        const account: string = await Web3Service.getInstance().getAccount()
        if (!gasPriceGWEI) {
            const gasPrices = await GasStationService.getInstance().getPrices()
            gasPriceGWEI = gasPrices.fast
        }

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.writeRoverManager.methods
                .depositRover(minerId, cometAddr, time, solarSystemID)
                .send({ from: account, gasPrice: Web3Helper.gweiToWei(gasPriceGWEI!) })
                .on('error', (error: any) => {
                    this.writeRoverManager.methods
                        .depositRover(minerId, cometAddr, time, solarSystemID)
                        .call({ from: account })
                        .catch((err: any) => {
                            reject(err)
                            tx.error = err.message
                            notif.title = 'Rover deposit failed.'
                            tx.onError()
                        })
                })
                .on('receipt', (data: any) => {
                    document.dispatchEvent(
                        new CustomEvent(RoverManagerService.MINED_EVENT, {
                            detail: { minerId: minerId, cometId: cometAddr, solarSystemID: solarSystemID }
                        })
                    )
                    document.dispatchEvent(
                        new CustomEvent(RoverManagerService.ROVER_DEPOSED_EVENT, {
                            detail: { minerId: minerId, cometId: cometAddr, solarSystemID: solarSystemID }
                        })
                    )
                    notif.title = 'Rover deposit succeed!'
                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif = NotificationService.getInstance().addFromTransaction(tx, () => {
                        PlayerService.getInstance().updateSpiceBalance()
                    })
                    notif.title = 'Rover deposit in progress'
                    resolve(data)
                })
        })
    }

    public async collectRover(minerId: string, cometAddr: string, time: number, solarSystemID: number, gasPriceGWEI?: number) {
        const account: string = await Web3Service.getInstance().getAccount()
        if (!gasPriceGWEI) {
            const gasPrices = await GasStationService.getInstance().getPrices()
            gasPriceGWEI = gasPrices.fast
        }

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.writeRoverManager.methods
                .collectRover(minerId, cometAddr, time, solarSystemID)
                .send({ from: account, gasPrice: Web3Helper.gweiToWei(gasPriceGWEI!) })
                .on('error', (error: any) => {
                    this.writeRoverManager.methods
                        .collectRover(minerId, cometAddr, time, solarSystemID)
                        .call({ from: account })
                        .catch((err: any) => {
                            reject(err)
                            tx.error = err.message
                            notif.title = 'Rover retrieving failed.'
                            tx.onError()
                        })
                })
                .on('receipt', (data: any) => {
                    document.dispatchEvent(
                        new CustomEvent(RoverManagerService.MINED_EVENT, {
                            detail: { minerId: minerId, cometId: cometAddr, solarSystemID: solarSystemID }
                        })
                    )
                    document.dispatchEvent(
                        new CustomEvent(RoverManagerService.ROVER_HARVEST_EVENT, {
                            detail: { minerId: minerId, cometId: cometAddr, solarSystemID: solarSystemID }
                        })
                    )
                    notif.title = 'Rover retrieving succeed!'
                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif = NotificationService.getInstance().addFromTransaction(tx, () => {
                        PlayerService.getInstance().updateSpiceBalance()
                    })
                    notif.title = 'Rover retrieving in progress'
                    resolve(data)
                })
        })
    }

    public async listenUpdateStakingCometEvent() {
        await this.readRoverManager.events
            .StakingCometUpdated()
            .on('data', function(event: any) {
                document.dispatchEvent(new CustomEvent(Events.UpdateStakingComet, { detail: event.returnValues }))
            })
            .on('error', function(error: any) {
                console.log('ERROR: listenNewCometEvent', error)
            })
    }
}
