import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Game from '../views/Game/Game.vue'

import TokenTransfer from '@/views/TokenTransfer/TokenTransfer.vue'
import Tube from '@/views/Tube/Tube.vue'

import Profile from '@/views/Profile/Profile.vue'

import Mule from '@/views/Shop/Mule/Mule.vue'
import Starterkit from '@/views/Shop/Starterkit/Starterkit.vue'
import Shop from '@/views/Shop/Shop.vue'
import Kiosk from '@/views/Kiosk/Kiosk.vue'
import Referral from '@/views/Referral/Referral.vue'
import Ambassador from '@/views/Referral/Ambassador.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Game',
        component: Game
    },
    {
        path: '/leaderboard',
        name: 'Leaderboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "leaderboard" */ '@/views/Leaderboard/Leaderboard.vue')
    },
    {
        path: '/shop/',
        name: 'Shop',
        component: Shop,
        meta: {
            title: 'Cometh.io Shop - The easiest and fastest way to play',
            description: ''
        }
    },
    {
        path: '/shop/mule',
        redirect: '/shop',
        name: 'Mule',
        component: Mule,
        meta: {
            title: 'Cometh.io M.U.L.E. - Start Your Space Odyssey',
            desc: 'Get on board now. Play, earn and learn everything Cometh vibrant ecosystem.'
        }
    },
    {
        path: '/shop/starterkit',
        name: 'Starterkit',
        component: Starterkit
    },
    {
        path: '/shop/starterkit/:name',
        name: 'Starterkit1',
        component: Starterkit,
        meta: {
            title: 'Cometh.io Starterkit - Start mining now'
        }
    },
    {
        path: '/tube',
        name: 'Tube',
        component: Tube,
        meta: {
            title: 'Cometh.io Tube - Truly Gamified DeFi',
            desc: 'Stake your MUST and unlock NFTs for free!'
        }
    },
    {
        path: '/kiosk/:nft?',
        name: 'Kiosk',
        component: Kiosk,
        meta: {
            title: 'Cometh.io Kiosk',
            desc: 'Stake your MUST and unlock NFTs for free!'
        }
    },
    {
        path: '/tokentransfer',
        name: 'TokenTransfer',
        component: TokenTransfer
    },
    {
        path: '/profile/:account',
        name: 'Profile',
        component: Profile,
        meta: {
            title: 'Cometh.io - Profile'
        }
    },
    {
        path: '/referral-program',
        name: 'Referral',
        component: Referral,
        meta: {
            title: 'Cometh.io Referral Program - Invite Friends. Earn Crypto Together',
            desc: 'Earn 10% commission when a friend buy a M.U.L.E with your referral Code.'
        }
    },
    {
        path: '/ambassador',
        name: 'Ambassador',
        component: Ambassador
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
