import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import PlayerGlobalStatComponent from './stats/PlayerGlobalStatComponent'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import TextButton from './TextButton'

export default class PlayerGlobalMenuComponent implements GameElement {
    private container: PIXI.Container = new PIXI.Container()
    private bg: PIXI.Sprite = PIXI.Sprite.from('player_menu_stats_bg')
    private currentStatComponent: PlayerGlobalStatComponent = new PlayerGlobalStatComponent()
    private title: PIXI.BitmapText = new MainBitmapText('Fleet Statistics', { fontSize: 8 })
    private leaderBoardBtn: TextButton = new TextButton('Leaderboard')

    constructor() {
        this.title.x = Math.floor(this.bg.width / 2 - this.title.width / 2)
        this.title.y = 28

        this.leaderBoardBtn.btn.texture = PIXI.Texture.from('btn_leaderboard')
        this.leaderBoardBtn.btn.tint = 0xffffff
        this.leaderBoardBtn.btn.width = this.leaderBoardBtn.btn.texture.width
        this.leaderBoardBtn.text.x = 20
        this.leaderBoardBtn.text.y = 4
        this.leaderBoardBtn.getContainer().position.x = Math.floor(this.bg.width / 2 - this.leaderBoardBtn.getContainer().width / 2)
        this.leaderBoardBtn.getContainer().position.y = this.bg.height - this.leaderBoardBtn.getContainer().height - 6

        this.currentStatComponent.getContainer().x = 10
        this.currentStatComponent.getContainer().y = 36

        this.leaderBoardBtn.getContainer().on('pointertap', () => {
            window.open(window.location + 'leaderboard')
        })

        this.container.addChild(this.bg)
        this.container.addChild(this.title)
        this.container.addChild(this.leaderBoardBtn.getContainer())
        this.container.addChild(this.currentStatComponent.getContainer())

        this.container.visible = false
        this.onResize()
    }

    async enterAnimation(callback: () => void): Promise<void> {
        this.currentStatComponent.update()
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.y = 21 * perc
            this.container.alpha = perc
            this.container.visible = true
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.y = 21 - 21 * perc
            this.container.alpha = 1 - perc
        })
        this.container.visible = false
        callback()
    }

    onResize(): void {
        this.setPositions()
    }

    public tick(): void {
        // nothing
    }

    public getContainer(): PIXI.Container {
        return this.container
    }

    private setPositions() {
        // tood
    }
}
