import { Star } from '@/game/models/Star'
import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import Colors from '@/helpers/Colors'
import * as PIXI from 'pixi.js'

export default class StarPreviewComponent extends PIXI.Container {
    private bg = PIXI.Sprite.from('bg_star_detail')
    private star1 = PIXI.Sprite.from('1_star')
    private sun1Title = new MainBitmapText('', { fontSize: 5 })
    private playerCountTitle = new MainBitmapText('Players', { fontSize: 4 })
    private playerCount = new MainBitmapText('', { fontSize: 4 })
    private cometsCountTitle = new MainBitmapText('Comets', { fontSize: 4 })
    private cometsCount = new MainBitmapText('', { fontSize: 4 })
    private coords = new MainBitmapText('', { fontSize: 4 })

    constructor() {
        super()
        this.addChild(this.bg)
        this.addChild(this.star1)
        this.addChild(this.sun1Title)
        this.addChild(this.playerCountTitle)
        this.addChild(this.playerCount)
        this.addChild(this.cometsCountTitle)
        this.addChild(this.cometsCount)
        this.addChild(this.coords)

        this.visible = false
    }

    public updateData(star: Star) {
        this.star1.x = 89

        this.star1.texture = PIXI.Texture.from(star.id + '_star')
        this.sun1Title.text = star.name
        this.playerCount.text = star.countMiners().toString()
        this.cometsCount.text = star.countComets().toString()
        this.coords.text = `x : ${star.x}    y : ${star.y}`

        this.sun1Title.x = 86 - this.sun1Title.width
        this.sun1Title.y = 14

        this.playerCountTitle.x = 86 - this.playerCountTitle.width
        this.playerCountTitle.y = this.sun1Title.y + this.sun1Title.height + 2
        this.playerCountTitle.tint = Colors.Blue800
        this.playerCount.x = this.playerCountTitle.x - this.playerCount.width - 2
        this.playerCount.y = this.playerCountTitle.y

        this.cometsCountTitle.x = this.playerCount.x - this.cometsCountTitle.width - 2
        this.cometsCountTitle.y = this.playerCountTitle.y
        this.cometsCountTitle.tint = Colors.Blue800
        this.cometsCount.x = this.cometsCountTitle.x - this.cometsCount.width - 2
        this.cometsCount.y = this.cometsCountTitle.y

        this.coords.y = this.bg.height - 9
        this.coords.x = 90 - this.coords.width
        this.visible = true
    }
}
