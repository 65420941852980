
import { Options, Vue } from "vue-class-component";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import ButtonSecondary from "@/components/ButtonSecondary.vue";

@Options({
    components: { ButtonPrimary, ButtonSecondary }
})
export default class Rules extends Vue {
    showRules = false;
}
