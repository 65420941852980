import Resolution from '@/helpers/Resolution'
import GameElement from '../GameElement'
import Miner from '../models/Miner'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import MinerPlayerComponent from './MinerPlayerComponent'
import PlayerService from '../services/PlayerService'
import MapDetailModal from './MapDetailModal'
import SelectPlayerShipButton from './SelectPlayerShipButton'
import Colors from '@/helpers/Colors'
import MainBitmapText from '../pixi-scale/MainBitmapText'

export default class MinerPreviewTooltip extends MapDetailModal implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public modalContainer: PIXI.Container = new PIXI.Container()
    public keyboard1 = PIXI.Sprite.from('keyboard_lmouse')
    public keyboard2 = PIXI.Sprite.from('keyboard_shift_lmouse')
    public keyboard1Text = new MainBitmapText('Set jump step', { fontSize: 4 })
    public keyboard2Text = new MainBitmapText('Add jump step', { fontSize: 4 })

    public onExit = (_: boolean) => {
        return
    }
    public onJumpClick = () => {
        return
    }

    public width = 82
    public height = 26

    private jumpCostText = new MainBitmapText('', {
        fontSize: 5,
        align: 'center'
    })

    constructor() {
        super()

        this.keyboard1.position.y = -14
        this.keyboard1Text.position.y = -14
        this.keyboard2.position.y = -7
        this.keyboard2Text.position.y = -7

        const costTitle = new MainBitmapText('PULL $', { fontSize: 5 })
        costTitle.position.x = 0
        costTitle.tint = Colors.Blue800

        this.jumpCostText.position.y = 0
        this.jumpCostText.position.x = costTitle.width + 2

        this.modalContainer.addChild(this.keyboard1)
        this.modalContainer.addChild(this.keyboard2)
        this.modalContainer.addChild(this.keyboard1Text)
        this.modalContainer.addChild(this.keyboard2Text)
        this.modalContainer.addChild(costTitle)
        this.modalContainer.addChild(this.jumpCostText)
        this.container.addChild(this.modalContainer)

        this.onResize()
    }

    tick(): void {
        // todo
    }

    async asyncUpdate(miner: Miner) {
        this.keyboard1.visible = this.keyboard1Text.visible = true
        this.keyboard2.texture = PIXI.Texture.from('keyboard_shift_lmouse')
        if (miner.isSelf) {
            this.keyboard1Text.text = 'Open own ship menu'
        } else {
            this.keyboard1Text.text = 'Set jump step'
        }

        this.keyboard2Text.text = 'Add jump step'

        this.entity = miner
        await (this.entity as Miner).updatePullingPrice()
        this.jumpCostText.text = (this.entity as Miner).pullPriceInSpice()
    }

    async asyncUpdateJumpStep(miner: Miner) {
        this.keyboard2.texture = PIXI.Texture.from('keyboard_rmouse')
        this.keyboard2Text.text = 'Remove jump step'
        this.keyboard1.visible = this.keyboard1Text.visible = false

        this.entity = miner
        await (this.entity as Miner).updatePullingPrice()
        this.jumpCostText.text = (this.entity as Miner).pullPriceInSpice()
    }

    update(): void {
        // nothing
    }

    onResize(): void {
        this.container.scale.set(Resolution.scale)
        this.keyboard1.scale.set(1 / Resolution.scale)
        this.keyboard2.scale.set(1 / Resolution.scale)

        this.keyboard1Text.x = this.keyboard1.width + 2
        this.keyboard2Text.x = this.keyboard2.width + 2
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    exit() {
        this.onExit(true)
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await animate('easeOutQuad', 300, (perc: number) => {
            // todo
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await animate('easeInQuad', 300, (perc: number) => {
            // todo
        })
        callback()
    }
}
