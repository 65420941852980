<template>
    <div class="tube">
        <Header class="z-50 relative" ref="header" />
        <main id="main" class="main" :class="{ loaded: isLoaded }" :style="{ transform: 'scale(' + scale + ')' }">
            <transition name="overlay">
                <div
                    class="w-full h-full absolute top-0 left-0 bg-black bg-opacity-50 text-white flex justify-center items-center"
                    style="z-index: 120"
                    :style="{ width: innerWidth / scale + 'px', height: (innerHeight - 56) / scale + 'px' }"
                    v-if="isLoadingData"
                >
                    <h1 class="text-2xl bg-theme-500 border-theme-800 border-4 rounded-lg px-12 py-6">Loading...</h1>
                </div>
            </transition>

            <transition name="overlay">
                <div
                    class="w-full h-full absolute top-0 left-0 bg-black bg-opacity-75 text-white flex justify-center items-center"
                    style="z-index: 120"
                    :style="{ width: innerWidth / scale + 'px', height: (innerHeight - 56) / scale + 'px' }"
                    v-if="claimed.id >= 0"
                >
                    <div>
                        <h1 class="text-4xl mb-16">Reward received !</h1>
                        <div class="flex">
                            <div class="h-40 w-40 bg-theme-400 mr-6 flex-shrink-0">
                                <img class="h-full w-full rounded shadow-lg border border-theme-800" :src="claimed.img" />
                            </div>
                            <div class="flex-1">
                                <p class="text-theme-800 text-2xl">{{ claimed.name }}</p>
                                <p class="text-white text-lg">{{ claimed.description }}</p>
                                <p class="text-xl text-gold mt-4">{{ claimed.dust }} DUST</p>
                            </div>
                        </div>
                        <div
                            class="
                            bg-gold
                            mt-16
                            text-lg
                            p-4
                            pointer-events-auto
                            rounded
                            shadow
                            hover:shadow-xl
                            transition-all
                            duration-100
                            text-black text-center
                            w-96
                            cursor-pointer
                            hover:bg-yellow-500
                        "
                            v-on:click="resetClaimed()"
                        >
                            Continue
                        </div>
                    </div>
                </div>
            </transition>

            <div
                class="claim w-full h-full absolute z-50 pointer-events-none"
                :style="{ width: innerWidth / scale + 'px', height: (innerHeight - 56) / scale + 'px' }"
                v-if="hasDustToMigrate()"
            >
                <div
                    class="
                    bg-gold
                    absolute
                    top-0
                    right-0
                    p-4
                    pointer-events-auto
                    hover:bg-yellow-500
                    cursor-pointer
                    rounded
                    mr-6
                    mt-6
                    shadow
                    hover:shadow-xl
                    transition-all
                    duration-100
                "
                    v-on:click.stop="openL1Claim()"
                >
                    Migrate L1 DUST
                </div>
                <transition name="overlay">
                    <div
                        v-if="mustDisplayL1Claim"
                        class="
                        bg-black bg-opacity-50
                        pointer-events-auto
                        absolute
                        w-full
                        h-full
                        flex
                        top-0
                        left-0
                        overflow-hidden
                        justify-center
                        items-center
                        cursor-default
                    "
                        v-on:click.stop="mustDisplayL1Claim = false"
                    >
                        <div class="bg-theme-400 border-4 rounded border-blue-400 text-white relative py-6 px-12 text-center" v-on:click.stop="">
                            <h1 class="text-xl">Migrate L1 DUST</h1>
                            <hr class="border-b border-theme-800 mt-6 mb-6" />
                            <p class="my-6">
                                You have <span class="text-gold">{{ formatedToken(dustInMigration) }} DUST</span> in L1 with <br />
                                <span class="text-theme-purple-500">{{ formatedToken(dustMigrationStakeRequieremnt) }} MUST</span> staked.
                                <button
                                    class="
                                    bg-gold
                                    ml-2
                                    text-sm
                                    border-b-2
                                    text-black
                                    border-yellow-500
                                    py-0
                                    rounded-lg
                                    px-2
                                    text-center
                                    hover:bg-yellow-400
                                "
                                    @click="copyToMigrateAmount"
                                >
                                    copy amount
                                </button>
                            </p>
                            <hr class="border-b border-theme-800 mt-6 mb-6" />
                            <p>
                                To migrate your <span class="text-gold">DUST</span> from L1,<br />
                                please <span class="text-theme-800">stake</span> at least the same amount of
                                <span class="text-theme-purple-500">MUST</span><br />
                                in the <span class="text-theme-800">Cometh Tube</span> and click on the migrate button.
                            </p>
                            <button
                                v-if="isMigrating"
                                class="
                                inline-flex
                                bg-gold
                                text-lg
                                p-4
                                pointer-events-auto
                                rounded
                                mt-6
                                shadow
                                hover:shadow-xl
                                transition-all
                                duration-100
                                text-black
                            "
                            >
                                <svg
                                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Wait...
                            </button>
                            <button
                                v-else
                                :class="!hasStakedRequierement() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-500 cursor-pointer'"
                                class="bg-gold text-lg p-4 pointer-events-auto rounded mt-6 shadow hover:shadow-xl transition-all duration-100 text-black"
                                :disabled="!hasStakedRequierement()"
                                v-on:click="migrate()"
                            >
                                Migrate
                            </button>
                        </div>
                    </div>
                </transition>
            </div>
            <transition name="overlay">
                <div
                    v-if="mustDisplayTubeDetail"
                    class="tube_absolute absolute top-0 left-0 overflow-hidden flex p-12 cursor-default"
                    :style="{ width: innerWidth / scale + 'px', height: (innerHeight - 56) / scale + 'px' }"
                >
                    <div class="bg-theme-400 border-4 rounded border-blue-400 flex-1 text-white relative" v-on:click.stop="">
                        <div class="absolute -top-5 right-6 z-50">
                            <div
                                class="
                                text-center
                                flex-shrink-0
                                h-8
                                w-8
                                text-white
                                float-left
                                mr-3
                                inline-block
                                bg-theme-500
                                rounded
                                border-2 border-theme-800
                                cursor-pointer
                                hover:bg-theme-600
                            "
                                v-on:click.stop="copyTubeUrl()"
                            >
                                <img class="margin-auto ml-1 mt-1 h-5 w-5" src="/assets/img/ic_link.png" />
                            </div>
                            <div
                                class="
                                text-center
                                flex-shrink-0
                                text-lg
                                h-8
                                w-8
                                text-white
                                inline-block
                                bg-theme-500
                                rounded
                                border-2 border-theme-800
                                cursor-pointer
                                hover:bg-theme-600
                            "
                                v-on:click="hideTubeDetail()"
                            >
                                X
                            </div>
                        </div>
                        <div class="h-full overflow-auto">
                            <div class="intro relative ml-12 mt-6 pb-16">
                                <div class="absolute top-0 left-0 z-50" style="width: 30px; height: calc(100% - 17px)">
                                    <div class="absolute bg-theme-300 w-6 ml-1 h-full" />
                                    <div class="absolute bg-theme-green-500 w-6 ml-1" :style="{ height: relativePercLevel(0) + '%' }" />
                                    <img class="absolute top-0 left-0 h-full" src="/assets/img/tube/tube_lvl_repeat.png" />
                                    <img class="absolute top-0 w-[30px]" src="/assets/img/tube/tube_lvl_start.png" />
                                </div>
                                <div class="flex ml-16">
                                    <h1 class="text-2xl mt-6 text-center flex-1 mr-24">Use your DUST to claim</h1>
                                </div>
                            </div>
                            <div class="poolPrizes mx-6">
                                <div
                                    class="pb-12 relative mb-5"
                                    v-for="(prizeGroup, index) in currentPoolPrizes"
                                    :key="index"
                                    :class="isLevelUnlocked(index) ? '' : ''"
                                >
                                    <div
                                        v-if="index < currentPoolPrizes.length - 1"
                                        class="bg-theme-green-500 absolute w-6 h-full ml-7 mt-5 z-20"
                                        :style="{ height: 'calc(' + relativePercLevel(index + 1) + '% - 19px)' }"
                                    />
                                    <div
                                        v-if="index < currentPoolPrizes.length - 1"
                                        class="bg-theme-300 absolute w-6 h-full ml-7 mt-5"
                                        style="height: calc(100% - 20px)"
                                    />
                                    <div class="bg-theme-300 absolute ml-7 -mt-5" style="width: 166px; height: 44px" />
                                    <div
                                        class="bg-theme-green-500 absolute ml-7 z-10"
                                        style="width: 166px; margin-top: -20px"
                                        :style="{ height: (44 * relativePercLevelForStepWrapper(index)) / 100 + 'px' }"
                                    />
                                    <img
                                        class="absolute ml-6 z-30"
                                        :src="
                                            '/assets/img/tube/' +
                                                (index == currentPoolPrizes.length - 1 ? 'tube_lvl_step_end' : 'tube_lvl_step') +
                                                '.png'
                                        "
                                        style="top: -21px"
                                    />
                                    <div class="absolute left-0 top-0 w-32 ml-14 text-center -mt-2 text-sm z-50">Level {{ index + 1 }}</div>
                                    <img
                                        class="absolute top-0 left-0 ml-6 z-30"
                                        v-if="index < currentPoolPrizes.length - 1"
                                        style="width: 30px; height: calc(100% - 33px); margin-top: 35px"
                                        src="/assets/img/tube/tube_lvl_repeat_no_shadow.png"
                                    />
                                    <div
                                        class="bg-theme-500 p-6 rounded border-4 flex flex-col"
                                        :class="isLevelUnlocked(index) ? 'border-theme-green-500' : 'border-theme-500'"
                                    >
                                        <div v-for="(prize, i) in prizeGroup" :key="i">
                                            <hr class="border-t border-theme-600 my-8" v-if="i > 0" />
                                            <div class="flex flex-row">
                                                <div class="ml-12 h-40 w-40 bg-theme-400 mr-6 flex-shrink-0">
                                                    <img class="h-full w-full rounded shadow-lg" :src="prize.img" />
                                                </div>
                                                <div class="flex-1">
                                                    <p class="text-theme-800 text-lg">{{ prize.name }}</p>
                                                    <p class="">{{ prize.description }}</p>
                                                    <p class="text-xl text-gold mt-4">{{ prize.dust }} DUST</p>
                                                    <p class="text-theme-800 text-lg">
                                                        Supply: {{ prize.fixedSupply ? prize.supply : ' unlimited' }}
                                                    </p>
                                                </div>

                                                <div class="flex-shrink-0 justify-end items-end h-full">
                                                    <button
                                                        v-if="prizeIndexBeingClaimed == index"
                                                        class="
                                                        inline-flex
                                                        bg-gold
                                                        border-b-4
                                                        text-black
                                                        border-yellow-500
                                                        py-3
                                                        rounded-lg
                                                        px-6
                                                        text-center
                                                        bg-opacity-60
                                                        cursor-default
                                                    "
                                                    >
                                                        <svg
                                                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                class="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                stroke-width="4"
                                                            ></circle>
                                                            <path
                                                                class="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            ></path>
                                                        </svg>
                                                        Wait...
                                                    </button>
                                                    <button
                                                        v-else-if="isLevelUnlocked(index) && prize.isClaimable"
                                                        @click="claim(index, prize)"
                                                        class="
                                                        bg-gold
                                                        border-b-4
                                                        text-black
                                                        border-yellow-500
                                                        py-3
                                                        rounded-lg
                                                        px-6
                                                        text-center
                                                        hover:bg-yellow-400
                                                    "
                                                    >
                                                        Claim
                                                    </button>
                                                    <div v-else-if="!prize.isClaimable" class="text-red-600">Sold out</div>
                                                    <div v-else-if="!isLevelUnlocked(index)" class="text-red-600">
                                                        Cannot claim,<br />
                                                        not enough DUST
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="comingSoonPrizes.length > 0">
                                    <h1 class="text-2xl text-center mb-12 -mt-6">Coming Soon</h1>

                                    <div class="pb-6 relative" v-for="(prize, index) in comingSoonPrizes" :key="index">
                                        <div class="bg-theme-300 border-theme-300 p-6 rounded border-4 flex flex-col">
                                            <div class="flex flex-row">
                                                <div class="ml-12 h-40 w-40 bg-theme-100 mr-6 flex-shrink-0">
                                                    <img class="h-full w-full rounded shadow-lg" :src="prize.img" />
                                                </div>
                                                <div class="flex-1">
                                                    <p class="text-theme-800 text-lg">{{ prize.name }}</p>
                                                    <p class="">{{ prize.description }}</p>
                                                    <!-- <p class="text-xl text-gold mt-4">{{ prize.dust }} DUST</p>
                                                <p class="text-theme-800 text-lg">Supply: {{ prize.fixedSupply ? prize.supply : ' unlimited' }}</p> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-96 ml-40 flex-shrink-0 relative">
                        <img
                            class="absolute top-1/2 -mt-24 -left-28 cursor-pointer opacity-75 hover:opacity-100"
                            src="/assets/img/tube/tube_chevron.png"
                            v-on:click.stop="prevTube()"
                        />
                        <img
                            class="absolute top-1/2 -mt-24 right-12 cursor-pointer transform mr-2 rotate-180 opacity-75 hover:opacity-100"
                            src="/assets/img/tube/tube_chevron.png"
                            v-on:click.stop="nextTube()"
                        />
                    </div>
                    <div class="w-96 flex flex-col mr-6 flex-shrink-0">
                        <div class="flex-1"></div>
                        <div
                            class="bg-theme-400 border-4 rounded border-blue-400 flex-shrink-0 text-white p-6 mb-6 text-lg text-center"
                            v-on:click.stop=""
                        >
                            <div class="mb-4 text-xl">Currently Staked</div>
                            <div class="text-theme-purple-500">{{ formattedStakedMust }} MUST</div>
                            <div class="text-gold">{{ dustRate }} DUST/DAY</div>
                        </div>
                        <div class="bg-theme-400 border-4 rounded border-blue-400 flex-shrink-0 text-white p-6 mb-6" v-on:click.stop="">
                            <div class="text-xl mb-4">
                                Stake Token <br /><span class="text-sm text-green-500">available: {{ mustBalance }} MUST</span>

                                <button
                                    class="
                                    bg-gold
                                    ml-2
                                    text-sm
                                    border-b-2
                                    text-black
                                    border-yellow-500
                                    py-0
                                    rounded-lg
                                    px-2
                                    text-center
                                    hover:bg-yellow-400
                                "
                                    @click="maxToStake"
                                >
                                    max
                                </button>
                            </div>
                            <div class="text-lg mb-4" v-if="!isStarted()">
                                Tube not yet started, cannot stake now. <br /><br />
                                Start on: {{ getFormattedStartDate() }}
                            </div>
                            <div v-if="isStarted()">
                                <input
                                    type="number"
                                    class="text-lg mb-4 bg-transparent border-b-4 border-white w-full focus:outline-none"
                                    placeholder="amount"
                                    v-model="stakeAmount"
                                    v-if="isTubeApproved"
                                />
                                <span class="text-red-500 text-sm">{{ inputStakingError }}</span>

                                <button
                                    v-if="isApproving"
                                    class="
                                inline-flex
                                bg-gold
                                border-b-4
                                text-black
                                border-yellow-500
                                py-3
                                rounded-lg
                                px-6
                                text-center
                                bg-opacity-60
                                cursor-default
                            "
                                >
                                    <svg
                                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                    Wait...
                                </button>
                                <button
                                    v-else-if="!isTubeApproved"
                                    @click="approveTube"
                                    class="bg-gold border-b-4 text-black border-yellow-500 py-3 rounded-lg px-6 text-center hover:bg-yellow-400"
                                >
                                    Approve
                                </button>

                                <button
                                    v-if="isStaking"
                                    class="
                                inline-flex
                                bg-gold
                                border-b-4
                                text-black
                                border-yellow-500
                                py-3
                                rounded-lg
                                px-6
                                text-center
                                bg-opacity-60
                                cursor-default
                            "
                                >
                                    <svg
                                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                    Wait...
                                </button>
                                <button
                                    v-else-if="isTubeApproved"
                                    @click="stake"
                                    class="bg-gold border-b-4 text-black border-yellow-500 py-3 rounded-lg px-6 text-center hover:bg-yellow-400"
                                >
                                    Stake
                                </button>
                            </div>
                        </div>
                        <div class="bg-theme-400 border-4 rounded border-blue-400 flex-shrink-0 text-white p-6" v-on:click.stop="">
                            <div class="text-xl mb-4">
                                UnStake Token <br /><span class="text-sm text-green-500">{{ formattedStakedMust }} MUST Staked</span>
                                <button
                                    class="
                                    bg-gold
                                    ml-2
                                    text-sm
                                    border-b-2
                                    text-black
                                    border-yellow-500
                                    py-0
                                    rounded-lg
                                    px-2
                                    text-center
                                    hover:bg-yellow-400
                                "
                                    @click="maxUnstake"
                                >
                                    max
                                </button>
                            </div>

                            <input
                                type="number"
                                class="text-lg mb-4 bg-transparent border-b-4 border-white w-full focus:outline-none"
                                placeholder="amount"
                                v-model="unstakeAmount"
                            />
                            <span class="text-red-500 text-sm">{{ inputUnstakingError }}</span>
                            <button
                                v-if="isUnstaking"
                                class="
                                inline-flex
                                bg-gold
                                border-b-4
                                text-black
                                border-yellow-500
                                py-3
                                rounded-lg
                                px-6
                                text-center
                                bg-opacity-60
                                cursor-default
                            "
                            >
                                <svg
                                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Wait...
                            </button>
                            <button
                                v-else
                                @click="unstake"
                                class="bg-gold border-b-4 text-black border-yellow-500 py-3 rounded-lg px-6 text-center hover:bg-yellow-400"
                            >
                                Unstake
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </main>
    </div>
</template>

<script lang="ts" src="./Tube.ts"></script>
<style scoped lang="scss" src="./Tube.scss"></style>
