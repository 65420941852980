import Resolution from '@/helpers/Resolution'
import { TilingSprite } from 'pixi.js'
import { TubeComponent } from './TubeComponent'
import TubePool from '@/game/models/tube/TubePool'
import { animate, AnimationService } from '@/helpers/AnimationHelper'
import TubePoolsData from '@/TubePoolsData'
import TubeLoaderService from '@/game/services/TubeLoaderService'
import BN from 'bn.js'
import config from '@/config'

export default class TubeCore {
    private pixi!: PIXI.Application
    private bgContainer: PIXI.Container = new PIXI.Container()
    private foregroundContainer: PIXI.Container = new PIXI.Container()
    private bg = PIXI.TilingSprite.from('tube_space_bg', { width: 800, height: 800 }) as TilingSprite
    private bgFrame = PIXI.Sprite.from('tube_bg_frame')
    private bgLeft = new PIXI.AnimatedSprite(
        (TubeLoaderService.getInstance().loader.resources['tube_bg_left'] as any).spritesheet!._frameKeys.map((a: string) => {
            return PIXI.Texture.from(a)
        })
    )
    private bgLeftRepeat = PIXI.Sprite.from('tube_bg_left_repeat')
    private end = PIXI.Sprite.from('tube_end')
    private endRepeat = PIXI.Sprite.from('tube_end_repeat')

    private isGrabing = false
    private startGrabX = 0
    private startGrabContainerX = 0
    private currentScale = 0
    private endPos = 0
    private tubeComponents: TubeComponent[] = []
    public tubes: Array<TubePool> = []

    public bindedEarned?: BN = undefined
    private bindedTo?: TubePool = undefined

    public onClickTube = () => {
        return
    }
    public onLoadingChange = (change: boolean) => {
        return
    }

    constructor() {
        this.pixi = new PIXI.Application({
            autoDensity: true,
            width: 1900,
            resolution: 1,
            antialias: false,
            height: 1068,
            powerPreference: 'high-performance'
        })

        for (let index = 0; index < TubePoolsData.data.length; index++) {
            const tubePool: TubePool = new TubePool(
                Number(TubePoolsData.data[index].id),
                TubePoolsData.data[index].prizes,
                TubePoolsData.data[index].name,
                TubePoolsData.data[index].placeHolder,
                TubePoolsData.data[index].description,
                TubePoolsData.data[index].tokenSymbol,
                TubePoolsData.data[index].poolType,
                TubePoolsData.data[index].poolContractAddress
            )

            this.tubes.push(tubePool)
        }

        const canvas = document.getElementsByTagName('canvas')
        for (let index = 0; index < canvas.length; index++) {
            const element = canvas[index]
            element.remove()
        }

        const main = document.getElementById('main')!
        main.appendChild(this.pixi.view)

        this.bg.scale.set(3)
        this.pixi.stage.addChild(this.bg)
        this.pixi.stage.addChild(this.bgContainer)
        this.pixi.stage.addChild(this.foregroundContainer)

        this.pixi.stage.interactive = true
        this.pixi.stage.cursor = 'grab'
        this.pixi.stage.on('pointerdown', (event: PIXI.InteractionEvent) => {
            this.isGrabing = true
            this.pixi.stage.cursor = 'grabbing'
            this.startGrabX = event.data.global.x
            this.startGrabContainerX = this.bgContainer.x
        })
        this.pixi.stage.on('pointerup', () => {
            this.isGrabing = false
            this.pixi.stage.cursor = 'grab'
        })
        this.pixi.stage.on('pointermove', (event: PIXI.InteractionEvent) => {
            if (this.isGrabing) {
                this.handleMove(event)
            }
        })

        this.bgContainer.scale.set(3)
        this.foregroundContainer.scale.set(3)

        const logo = new PIXI.AnimatedSprite(
            (TubeLoaderService.getInstance().loader.resources['tube_logo'] as any).spritesheet!._frameKeys.map((a: string) => {
                return PIXI.Texture.from(a)
            })
        )
        logo.x = 55
        logo.y = 48

        this.bgLeft.x = -78
        this.bgLeftRepeat.width = 600
        this.bgLeftRepeat.x = this.bgLeft.x - this.bgLeftRepeat.width

        this.bgFrame.width = 8000
        this.bgFrame.x = this.bgLeft.width + this.bgLeft.x

        this.bgContainer.addChild(this.bgLeft)
        this.bgContainer.addChild(this.bgLeftRepeat)
        this.bgContainer.addChild(logo)
        this.bgContainer.addChild(this.bgFrame)
        this.bgContainer.addChild(this.end)
        this.bgContainer.addChild(this.endRepeat)
        this.endRepeat.width = 6000

        this.bgLeft.animationSpeed = 0.17
        this.bgLeft.play()
        logo.animationSpeed = 0.17
        logo.play()
        const prof: PIXI.AnimatedSprite = new PIXI.AnimatedSprite(
            (TubeLoaderService.getInstance().loader.resources['tube_prof'] as any).spritesheet!._frameKeys.map((a: string) => {
                return PIXI.Texture.from(a)
            })
        )
        prof.animationSpeed = 0.17
        prof.play()
        prof.y = this.pixi.renderer.height / Resolution.scale - prof.height
        this.foregroundContainer.addChild(prof)

        let componentWidth = 0
        let i = 0
        this.tubes.forEach(it => {
            const comp = new TubeComponent(it)
            this.tubeComponents.push(comp)
            comp.onClick = async () => {
                this.bindToTube(it)
            }
            comp.y = 31
            comp.x = this.bgLeft.width + this.bgLeft.x + comp.width * i++
            componentWidth = comp.width
            this.bgContainer.addChild(comp)
        })

        this.pixi.ticker.add(() => {
            AnimationService.getInstance().loopers.forEach(it => {
                it.callback()
            })

            this.tubeComponents.forEach(it => it.tick())
        })

        this.endPos = this.bgLeft.width + this.bgLeft.x + this.tubes.length * componentWidth
        this.end.x = this.endPos - 1
        this.endRepeat.x = this.end.x + this.end.width
    }

    onResize(scale: number) {
        // nothing
        this.pixi.renderer.resize(Math.floor(window.innerWidth / scale), 1068)
        this.bg.width = window.innerWidth / scale
        this.currentScale = scale

        if (this.bindedTo != undefined) {
            this.bgContainer.x = this.bindedToX
        }
        this.checkMoveBoundaries()
    }

    handleMove(event: PIXI.InteractionEvent) {
        const x = event.data.global.x

        this.bgContainer.x = this.startGrabContainerX + x - this.startGrabX
        this.checkMoveBoundaries()
    }

    private checkMoveBoundaries() {
        if (this.bgContainer.x > 600) {
            this.bgContainer.x = 600
        }
        const rLimit = (-this.endPos + 300) * Resolution.scale * this.currentScale
        if (this.bgContainer.x < rLimit) {
            this.bgContainer.x = rLimit
        }

        this.bg.tilePosition.x = this.bgContainer.x / 100
        this.foregroundContainer.x = this.bgContainer.x * 1.3 - 50
    }

    public async bindToTube(it: TubePool) {
        this.onLoadingChange(true)
        this.bindedEarned = await it.earned()
        this.bindedTo = it
        this.onClickTube()
        const initialX = this.bgContainer.x
        await animate('easeOutQuad', 300, (perc: number) => {
            this.bgContainer.x = initialX * (1 - perc) + this.bindedToX * perc
            this.checkMoveBoundaries()
        })
        this.onResize(this.currentScale)
        this.onLoadingChange(false)
    }

    public bindToNextTube() {
        if (this.bindedTo) {
            this.bindToTube(this.tubes[(this.tubes.indexOf(this.bindedTo) + 1) % this.tubes.length])
        }
    }

    public bindToPrevTube() {
        if (this.bindedTo) {
            this.bindToTube(this.tubes[(this.tubes.length + this.tubes.indexOf(this.bindedTo) - 1) % this.tubes.length])
        }
    }

    public unlockTubeBinding() {
        this.bindedTo = undefined
    }

    get bindedToX() {
        if (this.bindedTo) {
            return (
                window.innerWidth / this.currentScale -
                900 -
                376 * 2 -
                144 * 3 * this.tubes.indexOf(this.bindedTo!) -
                this.bgLeft.width -
                this.bgLeft.x +
                64
            )
        } else {
            return 0
        }
    }

    get currentPool() {
        return this.bindedTo
    }

    refreshTubeComponentData() {
        if (this.bindedTo) {
            this.tubeComponents[this.tubes.indexOf(this.bindedTo)].loadPool()
        } else {
            this.tubeComponents[config.defaultTubeIndex].loadPool()
        }
    }
}
