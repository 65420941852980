import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'

export default class GSprite extends PIXI.Sprite {
    constructor(textureName: string) {
        super(PIXI.Texture.from(textureName))
        this.anchor.set(0.5, 0.5)
        this.scale.set(Resolution.scale)

        document.addEventListener('onScaleChange', this.onScaleChange)
    }

    setX(x: number) {
        const flooredX = Math.floor(x)
        this.position.x = flooredX
    }

    setY(y: number) {
        const flooredY = Math.floor(y)
        this.position.y = flooredY
    }

    destroy() {
        document.removeEventListener('onScaleChange', this.onScaleChange)
        super.destroy()
    }

    onScaleChange = () => {
        this.scale.set(Resolution.scale, Resolution.scale)
    }
}
