import config from '@/config'
import { Notification } from '@/game/models/notification/Notification'
import { Transaction } from '@/game/models/Transaction'
import NotificationService from '../NotificationService'
import Web3Service from '../Web3Service'

export default class CoolingBoosterManagerService {
    public static instance: CoolingBoosterManagerService

    public static COOLING_BOOSTER_EVENT = 'COOLING_BOOSTER_EVENT'

    public static getInstance(): CoolingBoosterManagerService {
        if (!this.instance) {
            this.instance = new CoolingBoosterManagerService()
        }
        return this.instance
    }

    private abi = [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'ssStore',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'sSpaceships',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'gItemsManager',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'cBoostId',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            inputs: [],
            name: 'coolingBoostId',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'coolingReduction',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'gameItemsManager',
            outputs: [
                {
                    internalType: 'contract GameItemsManager',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'solarSystemStore',
            outputs: [
                {
                    internalType: 'contract ISolarSystemStore',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'stackedSpaceships',
            outputs: [
                {
                    internalType: 'contract IERC721',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updateSolarSystemStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStackedSpaceships',
                    type: 'address'
                }
            ],
            name: 'updateStackedSpaceShips',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newGameItemsManager',
                    type: 'address'
                }
            ],
            name: 'updateGameItemsManager',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'newCoolingBoostId',
                    type: 'uint256'
                }
            ],
            name: 'updateCoolingBoostId',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'newCoolingReduction',
                    type: 'uint256'
                }
            ],
            name: 'updateCoolingReduction',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'useCoolingBoost',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    private contract: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        this.contract = new web3.eth.Contract(this.abi, config.coolingBoosterManagerAddress)
    }

    public async useCoolingBoost(minerId: string, solarSystemID: number) {
        const account: string = await Web3Service.getInstance().getAccount()

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.contract.methods
                .useCoolingBoost(minerId, solarSystemID)
                .send({ from: account })
                .on('error', (error: any) => {
                    this.contract.methods
                        .useCoolingBoost(minerId, solarSystemID)
                        .call({ from: account })
                        .catch((err: any) => {
                            reject(err)
                            tx.error = err.message
                            console.log(err.message)
                            tx.onError()
                            reject(err)
                            notif.title = 'Using Mining Cooler failed.'
                        })
                })
                .on('receipt', (data: any) => {
                    notif.title = 'Using Mining Cooler completed!'
                    document.dispatchEvent(
                        new CustomEvent(CoolingBoosterManagerService.COOLING_BOOSTER_EVENT, {
                            detail: { minerId: minerId, solarSystemID: solarSystemID }
                        })
                    )

                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif = NotificationService.getInstance().addFromTransaction(tx)
                    notif.title = 'Using Mining Cooler ...'
                    resolve(data)
                })
        })
    }
}
