import { animate } from '@/helpers/AnimationHelper'
import Colors from '@/helpers/Colors'
import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import ContextualMenuService, { ContextualMenuEvent } from '../services/ContextualMenuService'

export default class ContextualMenuManager extends PIXI.Container {
    public menuContainer = new PIXI.Container()
    private currentEvent?: ContextualMenuEvent
    private isOpened = false
    constructor() {
        super()

        this.addChild(this.menuContainer)
        this.visible = false
        document.addEventListener('click', () => {
            if (this.isOpened) {
                this.cancelMenu()
            }
        })
        ContextualMenuService.getInstance().setOnContextualMenuRequested(data => {
            this.constructMenu(data)
        })

        this.interactive = true
        this.interactiveChildren = true

        this.onResize()

        setInterval(() => {
            if (this.currentEvent) {
                this.setPositions()
            }
        }, 1000 / 20)
    }

    private async constructMenu(data: ContextualMenuEvent) {
        this.menuContainer.removeChildren()
        this.menuContainer.alpha = 0

        const bg = new PIXI.Graphics()

        this.currentEvent = data
        this.visible = true
        data.items.forEach((it, i) => {
            const line = new PIXI.Container()
            line.y = 1 + i * 10

            const bg = PIXI.Sprite.from('pixel')
            bg.tint = Colors.Blue500
            bg.alpha = 0

            bg.width = 30
            bg.height = 10
            bg.x = 1

            line.interactive = true
            line.cursor = 'pointer'
            line.on('mouseover', () => {
                bg.alpha = 1
            })
            line.on('mouseout', () => {
                bg.alpha = 0
            })
            line.on('pointertap', () => {
                it.onClick()
                this.cancelMenu()
            })

            const text = new MainBitmapText(it.title, { fontSize: 5 })
            text.x = 6
            text.y = 2

            line.addChild(bg)
            line.addChild(text)

            this.menuContainer.addChild(line)
        })

        let maxWidth = 24
        this.menuContainer.children.forEach(it => {
            if (it instanceof PIXI.Container) {
                const text = it.getChildAt(1) as PIXI.BitmapText
                if (text.width > maxWidth) {
                    maxWidth = text.width
                }
            }
        })
        maxWidth += 8

        this.menuContainer.children.forEach(it => {
            if (it instanceof PIXI.Container) {
                const sprite = it.getChildAt(0) as PIXI.Sprite
                sprite.width = maxWidth - 2
            }
        })
        this.menuContainer.addChildAt(bg, 0)

        bg.beginFill(Colors.Blue400)
        bg.lineStyle(1, Colors.Blue800)
        bg.drawRect(0, 0, maxWidth, this.menuContainer.height / Resolution.scale + 2)

        this.setPositions()

        await animate('easeOutQuad', 200, perc => {
            this.menuContainer.alpha = perc
        })
        this.isOpened = true
    }

    onResize() {
        this.menuContainer.scale.set(Resolution.scale)
    }

    setPositions() {
        if (this.currentEvent) {
            this.menuContainer.x = Math.floor(this.currentEvent.getX())
            this.menuContainer.y = Math.floor(this.currentEvent.getY())
        }
    }

    private async cancelMenu() {
        await animate('easeOutQuad', 200, perc => {
            this.menuContainer.alpha = 1 - perc
        })
        this.visible = false
        this.currentEvent = undefined
        this.isOpened = false
        this.menuContainer.removeChildren()
    }
}
