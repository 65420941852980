import { Options, Vue } from 'vue-class-component'
import config from '@/config'
import { CheckIcon, XIcon } from '@heroicons/vue/outline'

export class ShopItem {
    name!: string
    desc!: string
    price!: string
    id!: string
    miningRange!: string
    miningPower!: string
    coolDown!: string
    title!: string
    description!: string
    url!: string
    soldout!: boolean
    imgUrl!: string
}

@Options({
    components: { CheckIcon, XIcon },
    props: {
        items: Array
    }
})
export default class ShopItemList extends Vue {
    items!: ShopItem[]
    config = config

    async mounted() {
        console.log('mounted')
        console.log('items', this.items)
    }
}
