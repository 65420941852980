import * as PIXI from 'pixi.js'
import Colors from '@/helpers/Colors'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import TransactionsModal from './modals/TransactionsModal'
import GasPriceChoiceModal from './modals/GasPriceChoiceModal'
import { GasStationService } from '../services/GasStationService'
import { GasPrices } from '@/models/GasPrices'

export default class TxGasPreviewButton extends PIXI.Container {
    public btn = PIXI.Sprite.from('pixel')
    public gasBtn = PIXI.Sprite.from('pixel')
    public separator = PIXI.Sprite.from('pixel')
    public separatorBg = PIXI.Sprite.from('pixel')

    public gaslv1 = PIXI.Sprite.from('pixel')
    public gaslv2 = PIXI.Sprite.from('pixel')
    public gaslv3 = PIXI.Sprite.from('pixel')

    public gasChoice?: GasPriceChoiceModal
    public selectedPrice?: number = undefined

    public text = new MainBitmapText('', {
        fontSize: 4
    })
    public gasPriceText = new MainBitmapText('100\nGWEI', {
        fontSize: 3
    })
    private onDefaultGasCallback = () => {
        return
    }

    private primaryTint = Colors.Blue600
    private secondaryTint = Colors.Blue500

    public prices?: GasPrices

    onClick = (price: number) => {
        return
    }
    onResized = () => {
        return
    }

    constructor(public textStr: string) {
        super()

        this.btn.height = 10
        this.gasBtn.height = 10

        this.text.text = textStr
        this.separatorBg.height = this.btn.height
        this.separator.height = this.btn.height - 4
        this.separator.y = 2

        this.btn.interactive = true
        this.btn.cursor = 'pointer'
        this.btn.on('mouseover', () => {
            this.btn.alpha = 0.8
        })
        this.btn.on('mouseout', () => {
            this.btn.alpha = 1
        })
        this.btn.on('pointertap', () => {
            this.onClick(this.selectedPrice!)
        })
        this.gasBtn.interactive = true
        this.gasBtn.cursor = 'pointer'
        this.gasBtn.on('mouseover', () => {
            this.gasBtn.alpha = 0.8
        })
        this.gasBtn.on('mouseout', () => {
            this.gasBtn.alpha = 1
        })
        this.gasBtn.on('pointertap', () => {
            if (this.prices == undefined) return

            this.gasChoice = new GasPriceChoiceModal(this.prices!, this.selectedPrice!)
            this.getOnTopParent().addChild(this.gasChoice.getContainer())
            this.gasChoice.onRequireBind = () => {
                return this.getWindowXY()
            }
            this.gasChoice.onExit = () => {
                this.gasChoice?.exitAnimation(() => {
                    this.gasChoice?.getContainer().destroy()
                    this.gasChoice = undefined
                    return
                })
            }
            this.gasChoice.enterAnimation(() => {
                return
            })
            this.gasChoice.onValueSelected = (val: number) => {
                this.selectedPrice = val

                let def = val
                if (this.selectedPrice == Number(this.prices!.standard)) {
                    def = GasStationService.GAS_STANDARD
                } else if (this.selectedPrice == Number(this.prices!.fast)) {
                    def = GasStationService.GAS_FAST
                } else if (this.selectedPrice == Number(this.prices!.fastest)) {
                    def = GasStationService.GAS_FASTEST
                }

                GasStationService.getInstance().setDefault(def)
                this.updateValueText()
            }
            this.onResize()
        })

        this.addChild(this.btn)
        this.addChild(this.gasBtn)
        this.addChild(this.text)
        this.addChild(this.separatorBg)
        this.addChild(this.separator)
        this.addChild(this.gasPriceText)
        this.addChild(this.gaslv1)
        this.addChild(this.gaslv2)
        this.addChild(this.gaslv3)

        this.onDefaultGasCallback = GasStationService.getInstance().setOnDefaultGasUpdated(() => {
            this.selectedPrice = this.prices!.getDefault()
            this.updateValueText()
        })

        this.updateTint()
        this.onResize()

        this.getGasPrice()
    }

    public async getGasPrice() {
        this.prices = await GasStationService.getInstance().getPrices()
        if (!this.selectedPrice) {
            this.selectedPrice = this.prices!.getDefault()
        }

        this.updateValueText()
    }

    public async forceRefreshGasPrice() {
        await this.getGasPrice()
        this.selectedPrice = this.prices!.getDefault()
        this.updateValueText()
    }

    public updateValueText() {
        this.handleLvlDisplay()
        this.gasPriceText.text = this.selectedPrice?.toFixed(2) + '\nGWEI'
        this.onResize()
    }

    public setPrimaryTint(color: number): TxGasPreviewButton {
        this.primaryTint = color
        this.updateTint()

        return this
    }
    public setSecondaryTint(color: number): TxGasPreviewButton {
        this.secondaryTint = color
        this.updateTint()

        return this
    }

    public updateTint() {
        this.btn.tint = this.primaryTint
        this.gasBtn.tint = this.primaryTint
        this.separatorBg.tint = this.primaryTint
        this.separator.tint = this.secondaryTint

        this.gaslv1.tint = this.secondaryTint
        this.gaslv2.tint = this.secondaryTint
        this.gaslv3.tint = this.secondaryTint
    }

    onResize(): void {
        this.btn.width = this.text.width + 8
        this.gasBtn.width = this.gasPriceText.width + 10

        this.gasChoice?.onResize()
        this.text.x = 4
        this.text.y = Math.floor(this.btn.height / 2 - this.text.height / 2) + 2 / 3
        this.separator.x = this.btn.width
        this.separatorBg.x = this.separator.x
        this.gasBtn.x = this.separator.x + 1
        this.gasPriceText.x = this.gasBtn.x + 4
        this.gasPriceText.y = Math.floor(this.btn.height / 2 - this.gasPriceText.height / 2) + 2 / 3

        this.gaslv1.x = this.gasBtn.x + this.gasBtn.width - 3
        this.gaslv2.x = this.gaslv1.x
        this.gaslv3.x = this.gaslv1.x
        this.gaslv3.y = 2.333
        this.gaslv2.y = 4.666
        this.gaslv1.y = 7

        this.onResized()
    }

    private handleLvlDisplay() {
        let tint = Colors.Purple500
        let lvl = 3
        if (this.selectedPrice! < Number(this.prices?.fastest)) {
            tint = Colors.Green500
            lvl = 2
        }
        if (this.selectedPrice! < Number(this.prices?.fast)) {
            tint = Colors.Blue800
            lvl = 1
        }
        if (this.isCustom()) {
            tint = Colors.Gold
        }

        this.gaslv1.tint = lvl >= 1 ? tint : Colors.Blue300
        this.gaslv2.tint = lvl >= 2 ? tint : Colors.Blue300
        this.gaslv3.tint = lvl >= 3 ? tint : Colors.Blue300
    }

    private getOnTopParent() {
        let parent = this.parent
        while (parent.parent != null) {
            parent = parent.parent
        }

        return parent
    }

    private getWindowXY(): PIXI.Point {
        const parents = [this.parent, this, this.gasBtn]
        let parent = this.parent
        while (parent.parent != null) {
            parent = parent.parent
            parents.splice(0, 0, parent)
        }

        let currScale = this.scale.x
        let y = 0
        let x = 0
        parents.forEach(p => {
            y += p.y * currScale
            x += p.x * currScale

            if (p.width != p.scale.x) {
                currScale = currScale * p.scale.x
            }
        })

        return new PIXI.Point(x, y)
    }

    private isCustom() {
        return (
            this.selectedPrice != Number(this.prices!.standard) &&
            this.selectedPrice != Number(this.prices!.fast) &&
            this.selectedPrice != Number(this.prices!.fastest)
        )
    }

    destroy() {
        GasStationService.getInstance().removeOnDefaultGasUpdated(this.onDefaultGasCallback)
        super.destroy()
    }
}
