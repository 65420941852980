export default class ContextualMenuService {
    public static instance: ContextualMenuService

    public static getInstance(): ContextualMenuService {
        if (!this.instance) {
            this.instance = new ContextualMenuService()
        }

        return this.instance
    }

    public setOnContextualMenuRequested(callback: (event: ContextualMenuEvent) => void) {
        document.addEventListener('onContextualMenuRequested', (event: Event) => {
            callback((event as CustomEvent).detail)
        })
    }

    public requestContextualMenu(event: ContextualMenuEvent) {
        document.dispatchEvent(event.toJSEvent())
    }
}

export class ContextualMenuEvent {
    public items: ContextualMenuItem[] = []
    public getX: () => number = () => {
        return 0
    }
    public getY: () => number = () => {
        return 0
    }

    toJSEvent(): CustomEvent {
        return new CustomEvent('onContextualMenuRequested', { detail: this })
    }
}

export class ContextualMenuItem {
    constructor(
        public title = '',
        public onClick: () => void = () => {
            return
        }
    ) {
        // nothing
    }
}
