import { Options, Vue } from "vue-class-component";

@Options({
    props: {
        isLoading: Boolean,
        isDisabled: Boolean
    }
})
export default class Button extends Vue {
    isLoading = false
    isDisabled = false
}
