import BN from 'bn.js'
import Web3 from 'web3'

export default class PoolPrize {
    constructor(
        public id = -1,
        public points = new BN(0),
        public img = '',
        public name = '',
        public description = '',
        public fixedSupply = false,
        public supply = 0
    ) {}

    get dust() {
        return Web3.utils.fromWei(this.points, 'ether')
    }

    get isClaimable() {
        if (this.fixedSupply) {
            return this.supply > 0
        }
        return true
    }
}
