import GameElement from '@/game/GameElement'
import { animate } from '@/helpers/AnimationHelper'
import Resolution from '@/helpers/Resolution'

export default class ModalComponent implements GameElement {
    public onExit: () => void = () => {
        return
    }

    static POSITION_CENTER = 'position_center'
    static POSITION_BOTTOM = 'position_bottom'

    protected container: PIXI.Container = new PIXI.Container()
    protected bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected modalContainer: PIXI.Container = new PIXI.Container()
    protected dataContainer: PIXI.Container = new PIXI.Container()
    protected top: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected left: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected right: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected bottom: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected modalBg: PIXI.Sprite = PIXI.Sprite.from('pixel')

    protected position = ModalComponent.POSITION_BOTTOM
    protected hPosistion = 52

    public modalWidth = 0
    public modalHeight = 0

    protected loader: PIXI.AnimatedSprite = new PIXI.AnimatedSprite(
        (PIXI.Loader.shared.resources['loader'] as any).spritesheet!._frameKeys.map((a: string) => {
            return PIXI.Texture.from(a)
        })
    )

    constructor(width: number, height: number) {
        this.modalWidth = width
        this.modalHeight = height

        this.bg.tint = 0x00000
        this.bg.alpha = 0.5
        this.bg.interactive = true
        this.bg.cursor = 'pointer'
        this.bg.on('pointertap', () => {
            this.onExit()
        })

        this.container.addChild(this.bg)

        this.top.width = this.modalWidth
        this.top.tint = 0x49bfff

        // this.bottom.width = this.modalWidth
        this.bottom.position.y = this.modalHeight
        this.bottom.tint = 0x49bfff

        // this.left.height = this.modalHeight
        this.left.tint = 0x49bfff

        // this.right.height = this.modalHeight
        this.right.position.x = this.modalWidth - 1
        this.right.tint = 0x49bfff

        this.modalBg.y = 1
        this.modalBg.x = 1
        this.modalBg.height = this.modalHeight - 1
        this.modalBg.width = this.modalWidth - 2
        this.modalBg.tint = 0x103861
        this.modalBg.interactive = true

        this.loader.x = Math.floor(this.modalWidth / 2 - this.loader.width / 2)
        this.loader.y = Math.floor(this.modalHeight / 2 - this.loader.height / 2)
        this.loader.animationSpeed = 0.2
        this.loader.play()

        this.modalContainer.addChild(this.modalBg)
        this.modalContainer.addChild(this.top)
        this.modalContainer.addChild(this.left)
        this.modalContainer.addChild(this.right)
        this.modalContainer.addChild(this.bottom)
        this.modalContainer.addChild(this.loader)
        this.modalContainer.addChild(this.dataContainer)
        this.container.addChild(this.modalContainer)
        this.setPositions()

        this.modalContainer.alpha = 0
        this.bg.alpha = 0
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await animate('easeOutQuad', 300, (perc: number) => {
            const horizontalSize = this.modalWidth - 2
            const verticalSize = this.modalHeight - 1

            this.bg.alpha = perc * 0.5
            this.modalContainer.alpha = perc

            this.top.x = 1
            this.top.width = horizontalSize * perc

            this.bottom.x = 1 + horizontalSize - horizontalSize * perc
            this.bottom.width = horizontalSize * perc

            this.left.height = verticalSize * perc
            this.left.y = 1 + verticalSize - verticalSize * perc

            this.right.height = verticalSize * perc
            this.right.y = 1
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await animate('easeInQuad', 300, (perc: number) => {
            const horizontalSize = this.modalWidth - 2
            const verticalSize = this.modalHeight - 1

            this.modalContainer.alpha = 1 - perc
            this.bg.alpha = 0.5 - perc * 0.5

            this.bottom.x = 1
            this.top.width = horizontalSize - horizontalSize * perc

            this.bottom.width = horizontalSize - horizontalSize * perc
            this.bottom.x = 1 + horizontalSize * perc

            this.left.height = verticalSize - verticalSize * perc
            this.left.y = 1 + verticalSize * perc

            this.right.y = 1
            this.right.height = verticalSize - verticalSize * perc
        })
        callback()
    }

    onResize(): void {
        this.bg.width = Resolution.realWidth
        this.setPositions()
    }

    public tick(): void {
        // this.bg!.tilePosition.y += 0.1
    }

    public getContainer(): PIXI.Container {
        return this.container
    }

    protected setPositions() {
        this.bg.width = Resolution.realWidth
        this.bg.height = Resolution.realHeight
        this.modalContainer.scale.set(Resolution.scale)

        if (this.position === ModalComponent.POSITION_BOTTOM) {
            this.modalContainer.position.x = Math.floor(this.hPosistion * Resolution.scale)
            this.modalContainer.position.y = Math.floor(Resolution.realHeight - this.modalHeight * Resolution.scale - 36 * Resolution.scale)
        } else {
            this.modalContainer.position.x = Math.round(Resolution.realWidth / 2 - (this.modalWidth * Resolution.scale) / 2)
            this.modalContainer.position.y = Math.round(Resolution.realHeight / 2 - (this.modalHeight * Resolution.scale) / 2)
        }
    }
}
