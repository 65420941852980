<template>
    <div class="game" oncontextmenu="return false;">
        <main id="main" class="main" :class="{ loaded: isLoaded }">
            <transition
                enter-active-class="transition ease-out duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-in duration-1000"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div v-show="!isLoaded" class="absolute inset-0 overflow-y-auto bg-black">
                    <Header ref="header" />
                    <div class="md:hidden" v-if="!mobileIgnored">
                        <div class="pt-12 sm:pt-16 lg:pt-24">
                            <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                                <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                                    <div class="flex justify-center mb-6">
                                        <img class="w-32" style="image-rendering: auto;" src="@/assets/logo_cometh_v2.png" />
                                    </div>
                                    <h2 class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                                        The game is not yet available on mobile
                                    </h2>
                                    <img src="@/assets/welcome/gotodesktop.gif" />
                                    <p class="text-2xl font-extrabold text-white sm:text-4xl lg:text-5xl py-6">
                                        Please use your desktop to mine comet for now
                                    </p>
                                    <p
                                        class="text-xl cursor-pointer bg-theme-800 text-white inline-block px-6 py-2 rounded mt-6"
                                        v-on:click="ignoreMobileMode()"
                                    >
                                        Continue anyway
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Welcome
                        class="md:flex"
                        :class="mobileIgnored ? '' : 'hidden'"
                        id="onboarding"
                        @ready-to-play="play"
                        :clickedPlay="clickedPlay"
                        :loadProgress="loadProgress"
                        v-on:notConnected="showModal"
                    />

                    <!--Maintenance /-->
                </div>
            </transition>
        </main>
    </div>
</template>

<script lang="ts" src="./Game.ts"></script>
<style scoped lang="scss" src="./Game.scss"></style>
