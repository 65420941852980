import config from '@/config'
import Web3Service from '@/game/services/Web3Service'

export default class MulticallService {
    public static instance: MulticallService

    public static getInstance(): MulticallService {
        if (!this.instance) {
            this.instance = new MulticallService()
        }
        return this.instance
    }

    private multicallAbi = [
        {
            constant: true,
            inputs: [],
            name: 'getCurrentBlockTimestamp',
            outputs: [
                {
                    name: 'timestamp',
                    type: 'uint256'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [
                {
                    components: [
                        {
                            name: 'target',
                            type: 'address'
                        },
                        {
                            name: 'callData',
                            type: 'bytes'
                        }
                    ],
                    name: 'calls',
                    type: 'tuple[]'
                }
            ],
            name: 'aggregate',
            outputs: [
                {
                    name: 'blockNumber',
                    type: 'uint256'
                },
                {
                    name: 'returnData',
                    type: 'bytes[]'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'getLastBlockHash',
            outputs: [
                {
                    name: 'blockHash',
                    type: 'bytes32'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [
                {
                    name: 'addr',
                    type: 'address'
                }
            ],
            name: 'getEthBalance',
            outputs: [
                {
                    name: 'balance',
                    type: 'uint256'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'getCurrentBlockDifficulty',
            outputs: [
                {
                    name: 'difficulty',
                    type: 'uint256'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'getCurrentBlockGasLimit',
            outputs: [
                {
                    name: 'gaslimit',
                    type: 'uint256'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [],
            name: 'getCurrentBlockCoinbase',
            outputs: [
                {
                    name: 'coinbase',
                    type: 'address'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        },
        {
            constant: true,
            inputs: [
                {
                    name: 'blockNumber',
                    type: 'uint256'
                }
            ],
            name: 'getBlockHash',
            outputs: [
                {
                    name: 'blockHash',
                    type: 'bytes32'
                }
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function'
        }
    ]

    private multicall: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        const address = config.network == 137 ? '0xB7277C7d796D3Be4c4a017e9cd164887D604166e' : '0x743862432bCCd127f9fc0E45fc3bA9A43cdc3959'
        this.multicall = new wssWeb3.eth.Contract(this.multicallAbi, address)
    }

    public async erc20balanceOf(erc20: string[], holder: string) {
        const calls = []
        for (let i = 0; i < erc20.length; i++) {
            calls.push({target: erc20[i], callData: '0x70a08231000000000000000000000000' + holder.substring(2)});
        }
        return await this.multicall.methods.aggregate(calls).call()
    }

    public async stakingTokenOf(stakingReward: string[]): Promise<string[]> {
        const calls = []
        for (let i = 0; i < stakingReward.length; i++) {
            calls.push({target: stakingReward[i], callData: '0x72f702f3'});
        }

        const rawAddresses = (await this.multicall.methods.aggregate(calls).call()).returnData
        const addresses = Array<string>()
        for (let i = 0; i < rawAddresses.length; i++) {
            addresses.push('0x' + rawAddresses[i].substring(26))
        }
        return addresses
    }
}
