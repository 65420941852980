import Orbit from '@/game/models/position/Orbit'
import OrbitUtils from '@/game/models/position/Utils'
import Positionnable from './interfaces/Positionnable'
import TimeService from '../services/TimeService'
import BN from 'bn.js'
import CometSymbol from '../services/web3/Cometh/CometSymbol'

export default class Comete implements Positionnable {
    public piValue = Math.random() * Math.PI * 2
    public x = 0
    public y = 0

    public orbit: Orbit
    public id = ''
    public solarSystemID = 0
    public balance: BN = new BN(0)
    public unit: BN
    public symbol = ''
    public token: string

    constructor(from: any) {
        this.id = from.id
        this.orbit = new Orbit(from.orbit)
        this.token = from.token;
        this.unit = new BN(from.unit)
        new CometSymbol().symbol(this.token).then((symbol: string) => this.symbol = symbol)
        this.updateData(from)
    }

    public updateData(data: any) {
        this.balance = new BN(data.balance)
    }

    public updateCartesian() {
        const timeRef = TimeService.getInstance().now()
        const cartesian = this.orbit.getCartesian(timeRef)
        this.x = cartesian.x
        this.y = cartesian.y
        this.piValue = OrbitUtils.degreesToRadians(this.orbit.currentAngle(timeRef))
    }

    public revolutionDuration(): string {
        return Math.trunc(this.orbit.revolutionInSeconde() / 60) + ' Min'
    }

    public log() {
        const time = TimeService.getInstance().now()
        console.log(
            'orbit lastupdate: ',
            this.orbit.lastUpdate,
            ' distance: ',
            this.orbit.last.distance,
            ' angle',
            this.orbit.last.angle,
            ' center',
            this.orbit.center.x,
            ' - ',
            this.orbit.center.y,
            ' speed',
            this.orbit.rotationSpeed
        )
        console.log('time:', time, ' - ', 'x:', this.x, 'y:', this.y)
    }

    public totalReward(): string {
        return this.balance.toString()
    }

    public miningReward(): string {
        return this.unit.toString()
    }

    public isPending(): boolean {
        const time = TimeService.getInstance().now()
        return this.orbit.lastUpdate > time
    }
}
