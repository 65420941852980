<template>
<div class="">
    <div class="max-w-md mx-auto lg:max-w-5xl">
        <div class="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
            <div class="flex-1">
                <div>
                    <h3
                        class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800"
                    >
                        Referral
                    </h3>
                </div>
                <div class="mt-4 text-lg text-gray-600">
                    Get your referral code and share it with your friends to earn
                    <span class="font-semibold text-gray-900">10% commission</span> when they buy a M.U.L.E
                </div>
            </div>
            <div class="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                <router-link to="/referral-program">
                    <a
                        href="#"
                        class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                    >
                        Create my referral code
                    </a>
                </router-link>
            </div>
        </div>
    </div>
</div>

</template>
<script lang="ts" src="./CTAReferral.ts"></script>