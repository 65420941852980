import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'
import MainBitmapText from './MainBitmapText'

export default class GText extends MainBitmapText {
    constructor(text: string) {
        super(text, { fontSize: Resolution.fontSize })
        this.scale.set(Resolution.scale, Resolution.scale)

        document.addEventListener('onScaleChange', this.onScaleChange)
    }

    setX(x: number) {
        this.position.x = Math.floor(x) + ((x % Resolution.scale) / Resolution.scale) * 2
    }

    setY(y: number) {
        this.position.y = Math.floor(y) + ((y % Resolution.scale) / Resolution.scale) * 2
    }

    destroy() {
        document.removeEventListener('onScaleChange', this.onScaleChange)
        super.destroy()
    }

    onScaleChange = () => {
        this.scale.set(Resolution.scale, Resolution.scale)
    }
}
