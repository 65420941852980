export function msToTime(ms: number) {
    const h = Math.floor(ms / 60 / 60 / 1000)
    const m = Math.floor(ms / 60 / 1000) % 60
    const s = Math.floor(ms / 1000) % 60

    let result = `${s}s`
    if (m > 0) {
        result = `${m}m ` + result
    }
    if (h > 0) {
        result = `${h}h ` + result
    }

    return result
}

export function msToTimeHMFormat(ms: number) {
    const h = Math.floor(ms / 60 / 60 / 1000)
    const m = Math.floor(ms / 60 / 1000) % 60

    return `${h}h${('0' + m).slice(-2)}`
}

export function msToDateHelper(ms: number) {
    const date = new Date(ms)
    return date.toLocaleString()
}
