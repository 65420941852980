import { Options, Vue } from 'vue-class-component'

import ShipList from '@/components/profile/ship-list/ShipList.vue'
import config from '@/config'
import TheGraphService from '@/game/services/TheGraphService'
import { ShipWithGameStat } from '@/models/ShipWithGameStat'
import ShipService from '@/services/ShipService'
import RentalService from '@/services/RentalService'
import RentalWithStat from '@/models/Rental'
import LiquidityToken from '@/models/LiquidityToken'
import StakingRewardService from '@/services/StakingRewardService'
import TokenService from '@/services/TokenService'
import Token from '@/models/Token'
import Header from '@/components/header/Header.vue'
import StakedLiquidityToken from '@/models/StakedLiquidityToken'
import CTAReferral from '@/views/Referral/CTAReferral.vue'
import Modal from '@/components/modal/Modal.vue'
import ERC20Service from '@/game/services/web3/ERC20Service'
import Web3 from 'web3'
import ComethSwapTokenService from '@/services/ComethSwapTokenService'
import BigNumber from 'bignumber.js'

class PlayerStats {
    public earned!: string
    public mineCount!: string
    public jumpCount!: string
    public pullCount!: string
    public activeSinceDate!: string
    public lastActiveDate!: string
    public address!: string
}

class PlayerToken {
    public symbol!: string
    public name!: string
    public balance!: string
    public img!: string
    public address!: string
    public value!: string
    public price!: string
}

class PlayerFleet {
    public inGameShipCount!: string
    public mythicShipCount!: string
    public rareShipCount!: string
    public uncommonShipCount!: string
    public commonShipCount!: string
}

class Farming {
    public address!: string
    public tokenA!: string
    public tokenB!: string
    public LPTokenBalance!: string
    public claimable!: string
    public apy!: string
    public imgTokenA!: string
    public imgTokenB!: string
}

class Item {
    public address!: string
    public supply!: string
    public name!: string
    public img!: string
    public balance!: string
}

/*
class PlayerAchievements {

}
*/

@Options({
    components: {
        ShipList,
        Header,
        CTAReferral,
        Modal
    }
})
export default class Profile extends Vue {
    config = config
    playerStats: PlayerStats = new PlayerStats()
    playerFleet: PlayerFleet = new PlayerFleet()
    playerTokenList: PlayerToken[] = new Array<PlayerToken>()
    playerFarmingList: Farming[] = new Array<Farming>()
    playerItemList: Item[] = new Array<Item>()

    address = ''
    tokens = new Array<Token>()
    ships = new Array<ShipWithGameStat>()
    rentals = new Array<RentalWithStat>()
    liquidityTokens = new Array<LiquidityToken>()
    stakedLiquidityTokens = new Array<StakedLiquidityToken>()

    walletUSD = 0
    stakedUSD = 0

    isLoadingFleet = true
    isLoadingRentals = true
    isLoadingLiquidityTokens = true
    isLoadingStakedLiquidityTokens = true
    isLoadingTokens = true

    showTransferModal = false

    tokenToTransfer: Token = new Token('', '', '0.00018', '', 0, '2.3')
    amountToTransfer = ''
    addressToTransfer = ''

    amountError = ''
    isTransferring = false
    transferSucceeded = false

    get canTransfer() {
        return this.amountToTransfer.trim() != '' && this.addressToTransfer.trim() != ''
    }

    async mounted() {
        this.address = String(this.$route.params.account).toLowerCase()

        this.playerStats.earned = '8765'
        this.playerStats.mineCount = '578'
        this.playerStats.jumpCount = '73578'
        this.playerStats.pullCount = '10978'
        this.playerStats.activeSinceDate = 'March  2020'
        this.playerStats.lastActiveDate = '5 hours'
        this.playerStats.address = '0x0078c86e12186e2de1ff6fef5f1ada2bffde4ea5'

        this.playerFleet.inGameShipCount = '14'
        this.playerFleet.mythicShipCount = '1'
        this.playerFleet.rareShipCount = '3'
        this.playerFleet.uncommonShipCount = '6'
        this.playerFleet.commonShipCount = '4'

        // const token1 = new PlayerToken()
        // token1.symbol = "MUST"
        // token1.name = "MUST"
        // token1.balance = "76"
        // token1.img = "https://etherscan.io/token/images/cometh_32.png"
        // token1.address = "0x0078c86e12186e2de1ff6fef5f1ada2bffde4ea5"
        // token1.value = "22800"
        // token1.price = "322"
        //
        // const token2 = new PlayerToken()
        // token2.symbol = "SDT"
        // token2.name = "SDT"
        // token2.balance = "1276"
        // token2.img = "https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/xsdt.png"
        // token2.address = "0x0078c86e12186e2de1ff6fef5f1ada2bffde4ea5"
        // token2.value = "7988"
        // token2.price = "103"
        //
        // this.playerTokenList.push(token1)
        // this.playerTokenList.push(token2)

        ShipService.getInstance()
            .getFleet(this.address)
            .then(res => {
                this.ships = res
                this.isLoadingFleet = false
            })
        RentalService.getInstance()
            .getRentals(this.address)
            .then(res => {
                this.rentals = res
                this.isLoadingRentals = false
            })
        TheGraphService.getInstance()
            .getLiquidityPositionsOf(this.address)
            .then(res => {
                this.liquidityTokens = res
                this.liquidityTokens.forEach(token => {
                    this.stakedUSD += token.balanceUSD()
                })
                this.isLoadingLiquidityTokens = false
            })
        StakingRewardService.getInstance()
            .getStakedLiquidityPositions(this.address)
            .then(res => {
                this.stakedLiquidityTokens = res
                this.stakedLiquidityTokens.forEach(token => {
                    this.stakedUSD += token.liquidityToken.balanceUSD()
                })
                this.isLoadingStakedLiquidityTokens = false
            })

        this.initTokenList()

        // const famring1 = new Farming()
        // famring1.address = "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f"
        // famring1.tokenA = "MUST"
        // famring1.tokenB = "SDT"
        // famring1.LPTokenBalance = "98"
        // famring1.claimable = "900"
        // famring1.apy = "197"
        // famring1.imgTokenA = "https://etherscan.io/token/images/cometh_32.png"
        // famring1.imgTokenB = "https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/xsdt.png"
        //
        // this.playerFarmingList.push(famring1)

        // const item1 = new Item()
        // item1.address = "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f"
        // item1.img = "https://pixelartmaker-data-78746291193.nyc3.digitaloceanspaces.com/image/dae4ba47ef4a902.png"
        // item1.name = "Ticket for Polygon Common CUP"
        // item1.supply = "100"
        // item1.balance = "1"
        //
        // const item2 = new Item()
        // item2.address = "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f"
        // item2.img = "http://www.gyrostudiostechnology.com/minecraft/modpack/info/Industrial_Foregoing/machines/energy/Protein_Generator.png"
        // item2.name = "Comet Grabber"
        // item2.supply = "50"
        // item2.balance = "1"
        //
        // const item3 = new Item()
        // item3.address = "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f"
        // item3.img = "http://www.gyrostudiostechnology.com/minecraft/modpack/info/Industrial_Foregoing/machines/energy/Protein_Generator.png"
        // item3.name = "Cooling boost"
        // item3.supply = "500 "
        // item3.balance = "3"
        //
        // this.playerItemList.push(item1)
        // this.playerItemList.push(item2)
        // this.playerItemList.push(item3)
    }

    initTokenList() {
        TokenService.getInstance()
            .getTokens(this.address)
            .then(res => {
                this.tokens = res
                this.tokens.forEach(token => {
                    this.walletUSD += token.balanceUSD()
                })
                this.isLoadingTokens = false
            })
    }

    openTransferModal(token: Token) {
        this.tokenToTransfer = token
        this.amountToTransfer = ''
        this.addressToTransfer = ''
        this.showTransferModal = true
        this.isTransferring = false
        this.transferSucceeded = false
    }

    closeTransferModal() {
        if (!this.isTransferring) this.showTransferModal = false
    }

    async transfer() {
        if (!this.validateAmount()) {
            this.amountError = `Amount is too high (balance: ${parseFloat(this.tokenToTransfer.balance)})`
            return
        }

        this.isTransferring = true
        const comethswapTokens = await ComethSwapTokenService.getInstance().getTokensAddresses()
        const token = comethswapTokens.find(v => v.address.toLocaleLowerCase() === this.tokenToTransfer!.id)!
        const decimal = Web3.utils
            .toBN(10)
            .pow(Web3.utils.toBN(token.decimals))
            .toString()
        const weiAmount = new BigNumber(this.amountToTransfer.replace(/,/g, '.').toString()).times(new BigNumber(decimal)).toString()
        const erc20Service: ERC20Service = new ERC20Service(this.tokenToTransfer!.id)
        await erc20Service.transfer(this.addressToTransfer, weiAmount)
        this.isTransferring = false
        this.transferSucceeded = true
        this.initTokenList()
    }

    validateAmount() {
        return parseFloat(this.amountToTransfer.replace(/,/g, '.')) <= parseFloat(this.tokenToTransfer.balance)
    }

    formatedDollars(value: number) {
        return Math.floor(value * 100) / 100
    }

    formatedToken(value: number) {
        return Math.floor(value * 10000) / 10000
    }
}
