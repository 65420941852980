import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import NotificationService from '../NotificationService'
import { Transaction } from '@/game/models/Transaction'
import { Notification } from '@/game/models/notification/Notification'

export default class StakedSpaceshipsService {
    public static SHIP_LEAVE_GAME_EVENT = 'LEAVE_GAME'
    public static SHIP_ENTER_GAME_EVENT = 'ENTER_GAME'

    public static instance: StakedSpaceshipsService

    public static getInstance(): StakedSpaceshipsService {
        if (!this.instance) {
            this.instance = new StakedSpaceshipsService()
        }
        return this.instance
    }

    private abi = [
        {
            inputs: [
                {
                    internalType: 'string',
                    name: 'uri',
                    type: 'string'
                },
                {
                    internalType: 'address',
                    name: 'spaceshipsContract',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'approved',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'ApprovalForAll',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'gameId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'EnterGame',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'gameId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'LeaveGame',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            inputs: [],
            name: 'ID_TO_MODEL',
            outputs: [
                {
                    internalType: 'uint32',
                    name: '',
                    type: 'uint32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newERC1155',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'modelId',
                    type: 'uint256'
                }
            ],
            name: 'acceptNewERC1155',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'baseURI',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'exit',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'games',
            outputs: [
                {
                    internalType: 'address[]',
                    name: '',
                    type: 'address[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'gamesIds',
            outputs: [
                {
                    internalType: 'uint256[]',
                    name: '',
                    type: 'uint256[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'getApproved',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'inGame',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                }
            ],
            name: 'isApprovedForAll',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'onERC1155Received',
            outputs: [
                {
                    internalType: 'bytes4',
                    name: '',
                    type: 'bytes4'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'onERC721Received',
            outputs: [
                {
                    internalType: 'bytes4',
                    name: '',
                    type: 'bytes4'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'ownerOf',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: '_data',
                    type: 'bytes'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'spaceships',
            outputs: [
                {
                    internalType: 'contract ERC721',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'gameId',
                    type: 'uint256'
                }
            ],
            name: 'stake',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'tokenContract',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'gameId',
                    type: 'uint256'
                }
            ],
            name: 'stakeERC1155',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes4',
                    name: 'interfaceId',
                    type: 'bytes4'
                }
            ],
            name: 'supportsInterface',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'index',
                    type: 'uint256'
                }
            ],
            name: 'tokenByIndex',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'index',
                    type: 'uint256'
                }
            ],
            name: 'tokenOfOwnerByIndex',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'tokenURI',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'unstake',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'gameId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'gameAddress',
                    type: 'address'
                }
            ],
            name: 'updateGames',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                }
            ],
            name: 'tokensOfOwner',
            outputs: [
                {
                    internalType: 'uint256[]',
                    name: '',
                    type: 'uint256[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'gameId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'enterGame',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'leaveGame',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    private writeContract: any
    private readContract: any

    constructor() {
        this.initContract()
        this.listenEnterEvent()
        this.listenExitEvent()
    }

    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()

        this.writeContract = new web3.eth.Contract(this.abi, config.stackedSpaceshipAddress, { gasLimit: '1000000' })
        this.readContract = new wssWeb3.eth.Contract(this.abi, config.stackedSpaceshipAddress)
    }

    public async exit(minerID: string) {
        const web3 = Web3Service.getInstance().getWeb3()
        const account: string = await Web3Service.getInstance().getAccount()

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.writeContract.methods
                .exit(minerID, web3.eth.abi.encodeParameter('bool', true))
                .send({ from: account, gasPrice: '50000000000' })
                .on('error', (error: any) => {
                    reject(error)
                    notif.title = 'Exit failed.'
                    tx.onError()
                })
                .on('receipt', (data: any) => {
                    document.dispatchEvent(new CustomEvent(StakedSpaceshipsService.SHIP_LEAVE_GAME_EVENT, { detail: minerID }))
                    notif.title = 'Exit succeed!'
                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif = NotificationService.getInstance().addFromTransaction(tx)
                    notif.title = 'Exit in progress'
                    resolve(data)
                })
        })
    }

    public async listenEnterEvent() {
        await this.readContract.events
            .EnterGame()
            .on('data', function(event: any) {
                document.dispatchEvent(new CustomEvent(StakedSpaceshipsService.SHIP_ENTER_GAME_EVENT, { detail: event.returnValues.tokenId }))
            })
            .on('error', function(error: any) {
                console.log('ERROR: listenEnterEvent', error)
            })
    }

    public async listenExitEvent() {
        await this.readContract.events
            .LeaveGame()
            .on('data', function(event: any) {
                document.dispatchEvent(new CustomEvent(StakedSpaceshipsService.SHIP_LEAVE_GAME_EVENT, { detail: event.returnValues.tokenId }))
            })
            .on('error', function(error: any) {
                console.log('ERROR: listenExitEvent', error)
            })
    }

    public async tokensOfOwner() {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.readContract.methods.tokensOfOwner(account).call()
    }

    public async tokensOf(account: string) {
        return await this.readContract.methods.tokensOfOwner(account).call()
    }
}
