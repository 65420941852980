import RentingService from '@/game/services/web3/RentingService'
import Resolution from '@/helpers/Resolution'
import Rental from '@/models/Rental'

export default class PIXIAppService {
    public static instance: PIXIAppService

    public static getInstance(): PIXIAppService {
        if (!this.instance) {
            this.instance = new PIXIAppService()
        }
        return this.instance
    }

    private pixi?: PIXI.Application = undefined

    public getApplication() {
        if (!this.pixi) {
            this.pixi = new PIXI.Application({
                autoDensity: true,
                width: Resolution.realWidth,
                resolution: 1,
                antialias: false,
                height: Resolution.realHeight,
                powerPreference: 'high-performance',
                resizeTo: window
            })
        }

        return this.pixi!
    }

    public destroy() {
        this.pixi?.destroy()
        this.pixi = undefined
    }
}
