import Distance from '@/helpers/Distance'
import NotificationManager from '../components/notifications/NotificationManager'
import NotificationService from '../services/NotificationService'
import PlayerService from '../services/PlayerService'
import TimeService from '../services/TimeService'
import Comete from './Comete'
import StakingComet from './StakingComet'
import Miner from './Miner'
import { MiningNotification } from './notification/MiningNotification'
import { Notification } from './notification/Notification'
import { PortalTravelNotification } from './notification/PortalTravelNotification'
import { Portal } from './Portal'

export class Star {
    public x = 0
    public y = 0

    public minersLoaded = false
    public miners: Miner[] = []
    public minerCount = 0
    public cometes: Comete[] = []
    public stakingComets: StakingComet[] = []
    public portals: Portal[] = []
    public pendingComets: Comete[] = []
    public pendingStakingComets: StakingComet[] = []

    public countMiners() {
        if (this.minersLoaded) {
            return this.miners.length
        }

        return this.minerCount
    }

    public cometExist: (cometId: string) => boolean = () => {
        return false
    }

    constructor(public id = 1, public name = '') {}

    get galaxyX() {
        return Math.floor(this.x / 4000)
    }
    get galaxyY() {
        return Math.floor(this.y / 4000)
    }

    public update(fullUpdate: boolean) {
        if (fullUpdate) {
            this.miners.forEach(miner => {
                miner.updateCartesian()
            })
        } else {
            PlayerService.getInstance()
                .miningPlayers.filter(a => a.solarSystemID == this.id)
                .forEach(it => {
                    it.updateCartesian()
                })
        }

        this.cometes.forEach(comet => {
            comet.updateCartesian()
        })

        this.stakingComets.forEach(comet => {
            comet.updateCartesian()
        })

        this.portals.forEach(portal => {
            portal.updateCartesian()
        })

        this.checkComethColider()
        this.checkPortalColider()
    }

    public addMiner(miner: Miner) {
        if (this.miners.find(it => it.id == miner.id)) return

        miner.updateCartesian()
        this.miners.push(miner)
    }
    public removeMiner(minerId: string) {
        this.miners.forEach((m, i) => {
            if (m.id == minerId) {
                this.miners.splice(i, 1)
                return
            }
        })
    }

    private checkComethColider() {
        PlayerService.getInstance()
            .miningPlayers.filter(a => a.solarSystemID == this.id)
            .forEach(miner => {
                let distance = Number.MAX_VALUE
                this.cometes.concat(this.stakingComets).forEach(it => {
                    const distanceToPlayer = Distance.getDistance(it.x, it.y, miner.x, miner.y)
                    if (distanceToPlayer < distance) {
                        distance = distanceToPlayer
                        miner.nearestComet = it
                        miner.nearestComethDistance = distance
                    }
                })

                if (miner.nearestComethDistance <= miner.miningArea) {
                    const time = TimeService.getInstance().now()
                    if (miner.isNearComete == false) {
                        if (miner.isCool()) {
                            if (miner.isOutOfMiningZone()) {
                                if (
                                    miner.nearestComet != undefined &&
                                    miner.nearestComet instanceof StakingComet &&
                                    miner.nearestComet.id != miner.rover &&
                                    this.cometExist(miner.rover)
                                ) {
                                    //this.displayAlreadyUsedRoverNotif(miner)
                                    return
                                }

                                const miningNotif = new MiningNotification(miner, miner.nearestComet!, time)
                                NotificationService.getInstance().add(miningNotif)
                                miner.isNearComete = true
                            }
                        } else {
                            this.displayCooldownNotif(miner)
                        }
                    }
                } else {
                    miner.isNearComete = false
                }
            })
    }

    private checkPortalColider() {
        const miner = PlayerService.getInstance().miner
        if (miner.solarSystemID == this.id) {
            let closePortal
            for (let i = 0; i < this.portals.length; ++i) {
                const portal = this.portals[i]
                const distanceToPlayer = Distance.getDistance(portal.x, portal.y, miner.x, miner.y)
                if (distanceToPlayer <= portal.area) {
                    closePortal = portal
                }
            }

            if (closePortal != undefined) {
                const travelModalID = PortalTravelNotification.getNotifIdFor(miner.id)
                if (NotificationManager.getInstance().isNotificationDisplayed(travelModalID) == false && miner.isTraveling == false) {
                    const travel = new PortalTravelNotification(PlayerService.getInstance().miner, closePortal, TimeService.getInstance().now())
                    travel.id = travelModalID
                    NotificationService.getInstance().add(travel)
                }
            } else {
                miner.isTraveling = false
            }
        }
    }

    private async displayCooldownNotif(miner: Miner) {
        if (NotificationManager.getInstance().isNotificationDisplayed(miner.id) == false) {
            const notif = new Notification()
            notif.id = miner.id
            notif.title = 'Miner need to cooldown.\nWait for it to be ready.'
            NotificationService.getInstance().add(notif)
        }
    }

    private async displayAlreadyUsedRoverNotif(miner: Miner) {
        if (NotificationManager.getInstance().isNotificationDisplayed(miner.id) == false) {
            const notif = new Notification()
            notif.id = miner.id
            notif.title = 'Rover already deployed.\nPick it up or wait its return\nwhen the comet ends'
            NotificationService.getInstance().add(notif)
        }
    }

    public countComets(): number {
        return this.cometes.length + this.stakingComets.length
    }
}
