import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'

export default class GTilingSprite extends PIXI.TilingSprite {
    constructor(textureName: string) {
        super(PIXI.Texture.from(textureName))
        this.scale.set(Resolution.scale, Resolution.scale)

        document.addEventListener('onScaleChange', this.onScaleChange)
    }

    setX(x: number) {
        this.position.x = Math.floor(x)
    }

    setY(y: number) {
        this.position.y = Math.floor(y)
    }

    destroy() {
        document.removeEventListener('onScaleChange', this.onScaleChange)
        super.destroy()
    }

    onScaleChange = () => {
        this.scale.set(Resolution.scale, Resolution.scale)
    }
}
