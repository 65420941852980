<template>
    <Header class="z-50 relative" ref="header" />

    <div class="pb-12 flex bg-gray-100">
        <div class="flex flex-col w-0 flex-1">
            <div class="flex-1 focus:outline-none">
                <main class="flex-1 relative pb-8 z-0">
                    <!-- Page header -->
                    <div class="bg-white shadow">
                        <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                            <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                                <div class="flex-1 min-w-0">
                                    <!-- Profile -->

                                    <div class="flex items-center">
                                        <div>
                                            <div class="flex items-center">
                                                <div class="w-3/5">
                                                    <h1 class=" ml-3 text-2xl mb-2 font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                                                        {{ address }}
                                                    </h1>
                                                </div>

                                                <div class="w-2/5">
                                                    <div class="rounded-lg bg-gray-100 px-3 py-3 sm:p-4 lg:flex flex-col">
                                                        <div class="flex-1">
                                                            <div>
                                                                <h3
                                                                    class="inline-flex px-2 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800"
                                                                >
                                                                    Referral
                                                                </h3>
                                                            </div>
                                                            <div class="mt-4 mb-4 text-sm text-gray-600">
                                                                Get your referral code and share it with your friends to earn
                                                                <span class="font-semibold text-gray-900">10% commission</span> when they buy a
                                                                M.U.L.E
                                                            </div>
                                                        </div>
                                                        <div class="rounded-md shadow lg:mt-0 lg:flex-shrink-0">
                                                            <router-link to="/referral-program">
                                                                <a
                                                                    href="#"
                                                                    class="flex px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                                                                >
                                                                    Create my referral code
                                                                </a>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4"></div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-8">
                        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                            <h2 class="text-lg leading-6 font-medium text-gray-900">Overview</h2>
                            <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                                <!-- Card -->

                                <div class="bg-white overflow-hidden shadow rounded-lg">
                                    <div class="p-5">
                                        <div class="flex items-center">
                                            <div class="flex-shrink-0">
                                                <svg
                                                    class="h-6 w-6 text-gray-400"
                                                    x-description="Heroicon name: outline/scale"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                                                    ></path>
                                                </svg>
                                            </div>
                                            <div class="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt class="text-sm font-medium text-gray-500 truncate">
                                                        Wallet
                                                    </dt>
                                                    <dd>
                                                        <div class="text-lg font-medium text-gray-900">${{ formatedDollars(walletUSD) }}</div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="bg-white overflow-hidden shadow rounded-lg">
                                    <div class="p-5">
                                        <div class="flex items-center">
                                            <div class="flex-shrink-0">
                                                <svg
                                                    class="h-6 w-6 text-gray-400"
                                                    x-description="Heroicon name: outline/refresh"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                                    ></path>
                                                </svg>
                                            </div>
                                            <div class="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt class="text-sm font-medium text-gray-500 truncate">
                                                        Staked Asset
                                                    </dt>
                                                    <dd>
                                                        <div class="text-lg font-medium text-gray-900">${{ formatedDollars(stakedUSD) }}</div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                            Wallet
                        </h2>
                        <div class="hidden sm:block">
                            <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div class="flex flex-col mt-2">
                                    <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Assets
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Price
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Balance
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        value
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Trade
                                                    </th>
                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Transfer
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr div v-for="item in tokens" v-bind:key="item.id" class="bg-white">
                                                    <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        <div class="flex">
                                                            <div class="flex items-center space-x-2">
                                                                <div class="flex flex-shrink-0 -space-x-1">
                                                                    <img class="max-w-none h-6 w-6 rounded-full ring-2 ring-white" :src="item.img" />
                                                                </div>
                                                                <span>{{ item.symbol }} </span>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span class="text-gray-900 font-medium">${{ formatedDollars(item.priceUSD) }} </span>
                                                    </td>
                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span class="text-gray-900 font-medium">{{ formatedToken(item.balance) }} </span>
                                                    </td>

                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span class="text-gray-900 font-medium">${{ formatedDollars(item.balanceUSD()) }} </span>
                                                    </td>

                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <div>
                                                            <a
                                                                target="_blank"
                                                                :href="'https://swap.cometh.io/#/swap?outputCurrency=' + item.id"
                                                                class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                                            >
                                                                Trade
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <div>
                                                            <button
                                                                @click="openTransferModal(item)"
                                                                type="button"
                                                                class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                                            >
                                                                Transfer
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div v-if="isLoadingTokens" class="flex justify-center pt-3">
                                    <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#000" stroke-width="4"></circle>
                                        <path
                                            class="opacity-75"
                                            fill="#000"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                            Fleet ({{ ships.length }})
                        </h2>
                        <div class="hidden sm:block">
                            <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div class="flex flex-col mt-2">
                                    <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Model - Scarity - Race - <span class="italic">SN</span>
                                                    </th>

                                                    <th
                                                        class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block"
                                                    >
                                                        State
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Jump
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Pull
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Earned
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Details
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr div v-for="item in ships" :key="item.name" class="bg-white">
                                                    <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        <div class="flex">
                                                            <a href="#" class="group inline-flex space-x-2 truncate text-sm">
                                                                <img class="h-8" :src="item.image" alt="" />
                                                                <p class="text-gray-500 truncate group-hover:text-gray-900">
                                                                    {{ item.model }} - {{ item.scarcity }} - {{ item.race }} -
                                                                    <span class="italic"> {{ item.serialNumber }} of {{ item.supply }} </span>
                                                                </p>
                                                            </a>
                                                        </div>
                                                    </td>

                                                    <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                                                        <span
                                                            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 capitalize"
                                                        >
                                                            <span v-if="item.state() == 2">borrowed</span>
                                                            <span v-else-if="item.state() == 3">loaned</span>
                                                            <span v-else>owned</span>
                                                        </span>
                                                    </td>

                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span class="text-gray-900 font-medium">{{ item.jumpsCount }} </span>
                                                    </td>
                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span class="text-gray-900 font-medium">{{ item.pullsCount }} </span>
                                                    </td>

                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span class="text-gray-900 font-medium">${{ formatedDollars(item.earned()) }} </span>
                                                        USD
                                                    </td>

                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <div>
                                                            <a
                                                                :href="
                                                                    'https://opensea.io/assets/matic/0xe07eafe9ffc1363b6c4c466837a08aced54f9df9/' +
                                                                        item.id
                                                                "
                                                                target="_blank"
                                                                class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                                            >
                                                                view
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div v-if="isLoadingFleet" class="flex justify-center pt-3">
                                    <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#000" stroke-width="4"></circle>
                                        <path
                                            class="opacity-75"
                                            fill="#000"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                            Rentals ({{ rentals.length }})
                        </h2>
                        <div class="hidden sm:block">
                            <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div class="flex flex-col mt-2">
                                    <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Contract Address
                                                    </th>

                                                    <th
                                                        class="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block"
                                                    >
                                                        Status
                                                    </th>

                                                    <!-- <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Reward
                                                </th> -->

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Claimed
                                                    </th>

                                                    <!-- <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Claimable
                                                </th> -->

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Details
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr div v-for="item in rentals" v-bind:key="item.id" class="bg-white">
                                                    <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        <div class="flex">
                                                            <a href="#" class="group inline-flex space-x-2 truncate text-sm">
                                                                <!-- <img class="h-8" :src="config.shipImageUrl + '/' + item.modelId + '.png'" alt="" /> -->
                                                                <p class="text-gray-500 truncate group-hover:text-gray-900">
                                                                    {{ item.id }}
                                                                </p>
                                                            </a>
                                                        </div>
                                                    </td>

                                                    <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                                                        <span
                                                            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 capitalize"
                                                        >
                                                            <!-- <span v-if="item"></span>{{item.status}} -->
                                                            open
                                                        </span>
                                                    </td>

                                                    <!-- <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                    <span class="text-gray-900 font-medium">${{formatedDollars(item.reward())}} </span>
                                                    USD
                                                </td> -->
                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span class="text-gray-900 font-medium">${{ formatedDollars(item.claimed()) }} </span>
                                                        USD
                                                    </td>

                                                    <!-- <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                    <button type="button" class="inline-flex items-center px-3 py-0.5 rounded-full bg-green-50 text-sm font-medium text-green-700 hover:bg-green-100 border border-green-300">
                                                        <svg class="-ml-1 mr-0.5 h-5 w-5 text-green-400" x-description="Heroicon name: solid/plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span>
                                                            ${{formatedDollars(item.reward() - item.claimed())}} USD
                                                        </span>
                                                    </button>
                                                </td> -->

                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <div>
                                                            <a
                                                                :href="'https://rental.cometh.io/rental/' + item.id"
                                                                target="_blank"
                                                                class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                                            >
                                                                view
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div v-if="isLoadingRentals" class="flex justify-center pt-3">
                                    <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#000" stroke-width="4"></circle>
                                        <path
                                            class="opacity-75"
                                            fill="#000"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                            ComethSwap LP
                        </h2>
                        <div class="hidden sm:block">
                            <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div class="flex flex-col mt-2">
                                    <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Pool
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        LP Balance
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Token 1
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Token 2
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Value
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Details
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div v-if="isLoadingLiquidityTokens" class="flex justify-center pt-3">
                                        <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#000" stroke-width="4"></circle>
                                            <path
                                                class="opacity-75"
                                                fill="#000"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                            Farming
                        </h2>
                        <div class="hidden sm:block">
                            <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div class="flex flex-col mt-2">
                                    <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Pool
                                                    </th>

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        LP
                                                    </th>

                                                    <!-- <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    APY
                                                </th>

                                                <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Claimable
                                                </th> -->

                                                    <th
                                                        class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Details
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr div v-for="item in stakedLiquidityTokens" v-bind:key="item.liquidityToken.id" class="bg-white">
                                                    <td class="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        <div class="flex">
                                                            <div class="flex items-center space-x-2">
                                                                <div class="flex flex-shrink-0 -space-x-1">
                                                                    <img
                                                                        class="max-w-none h-6 w-6 rounded-full ring-2 ring-white"
                                                                        :src="item.liquidityToken.token0img"
                                                                        alt="Leslie Alexander"
                                                                    />

                                                                    <img
                                                                        class="max-w-none h-6 w-6 rounded-full ring-2 ring-white"
                                                                        :src="item.liquidityToken.token1img"
                                                                        alt="Michael Foster"
                                                                    />
                                                                </div>
                                                                <span>{{ item.liquidityToken.pair }}</span>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span class="text-gray-900 font-medium"
                                                            >{{ formatedToken(item.liquidityToken.balance) }}
                                                        </span>
                                                    </td>
                                                    <!-- <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                    <span class="text-gray-900 font-medium">${{item.apy}} </span>
                                                     %
                                                </td> -->
                                                    <!-- <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                    <button type="button" class="inline-flex items-center px-3 py-0.5 rounded-full bg-green-50 text-sm font-medium text-green-700 hover:bg-green-100 border border-green-300">
                                                        <svg class="-ml-1 mr-0.5 h-5 w-5 text-green-400" x-description="Heroicon name: solid/plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span>
                                                            ${{item.claimable}}
                                                             USD
                                                        </span>
                                                    </button>
                                                </td> -->

                                                    <td class="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <div>
                                                            <a
                                                                :href="
                                                                    'https://swap.cometh.io/#/stake/' +
                                                                        item.liquidityToken.address0 +
                                                                        '/' +
                                                                        item.liquidityToken.address1 +
                                                                        '/' +
                                                                        item.stakingReward
                                                                "
                                                                target="_blank"
                                                                class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                                            >
                                                                view
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div v-if="isLoadingStakedLiquidityTokens" class="flex justify-center pt-3">
                                    <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#000" stroke-width="4"></circle>
                                        <path
                                            class="opacity-75"
                                            fill="#000"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>

    <Modal :open="showTransferModal" @close="closeTransferModal">
        <div v-if="transferSucceeded" class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
            </div>
            <div class="flex-1 mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Success</h3>
                <div class="w-full mt-2">
                    <p>Transfer finished!</p>
                </div>
            </div>
        </div>
        <div v-else class="sm:flex sm:items-start">
            <div class="flex-1 mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="flex text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Transfer
                    <img class="ml-2 max-w-none h-6 w-6 rounded-full ring-2 ring-white" :src="tokenToTransfer.img" />
                    <span>{{ tokenToTransfer.symbol }} </span>
                </h3>
                <div class="w-full mt-2">
                    <label for="amount" class="block text-sm font-medium text-gray-700">Amount</label>
                    <div class="mt-1">
                        <input
                            @input="amountError = ''"
                            v-model="amountToTransfer"
                            placeholder="0.0"
                            type="text"
                            name="amount"
                            id="amount"
                            class="w-full max-w-xs px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                        />
                        <span class="text-sm text-red-500">{{ amountError }}</span>
                    </div>
                </div>
                <div class="mt-3">
                    <label for="address" class="block text-sm font-medium text-gray-700">Address <span class="text-xs italic text-gray">(on the Polygon Network)</span></label>
                    
                    <div class="mt-1">
                        <input
                            v-model="addressToTransfer"
                            placeholder=""
                            type="text"
                            name="address"
                            id="address"
                            class="w-full max-w-xs px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <template v-if="transferSucceeded">
                <button
                    @click="showTransferModal = false"
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                    Close
                </button>
            </template>
            <template v-else>
                <button
                    @click="transfer"
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm"
                    :class="[
                        isTransferring || !canTransfer
                            ? 'bg-opacity-70'
                            : 'hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                    ]"
                    :disabled="isTransferring || !canTransfer"
                >
                    <svg
                        v-if="isTransferring"
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    Transfer
                </button>
                <button
                    v-if="!isTransferring"
                    @click="showTransferModal = false"
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                    Cancel
                </button>
            </template>
        </div>
    </Modal>
</template>

<script lang="ts" src="./Profile.ts"></script>
