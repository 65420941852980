import { Portal } from './Portal'
import { Star } from './Star'

export default class GameMap {
    public stars: Star[] = []
    public portals: Portal[] = []
    public currentStar!: Star

    public cometExist: (cometId: string) => boolean = (cometId) => {
        if (cometId == '0x0000000000000000000000000000000000000000') {
            return false
        }

        for (let i = 0; i < this.stars.length; i++) {
            for (let j = 0; j < this.stars[i].stakingComets.length; j++) {
                if (this.stars[i].stakingComets[j].id == cometId) {
                    return true
                }
            }
        }
        return false
    }
}
