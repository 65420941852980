<template>
    <div class="text-white pt-10 h-full">
        <div class="max-w-sm mx-auto flex flex-col items-center">
            <img class="w-72" src="@/assets/logo.png" />

            <div class="py-3"></div>

            <h1 class="font-audiowide">Explore the galaxy to find juicy comets</h1>
            <router-link to="/leaderboard" class="bg-gold text-black rounded px-4 py-2 mt-4 font-audiowide hover:bg-yellow-400">
                🏆 Leaderboard
            </router-link>
        </div>

        <div class="py-8"></div>

        <!-- Full size container -->
        <div class="relative pt-8 pb-12">
            <div class="absolute inset-0 opacity-50">
                <video-background :src="require('@/assets/videos/game.mp4')" class="absolute h-full"></video-background>
            </div>

 <div class="relative max-w-3xl mx-auto h-72 flex items-center">
                <div class="flex flex-col w-1/3 h-full justify-center font-audiowide">
                    <div class="flex flex-col space-y-4">
            <div class="w-full">
           <div class="flex flex-col items-center space-y-4 text-red-500">
                <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <p class="text-center text-lg">Game under maintenance, please come back later</p>

                
            </div>
            </div>
                    </div>
                </div>
        </div>

    
        </div>

        <div class="py-4"></div>

        <div class="text-right text-white font-audiowide px-24 py-5">
            <hr class="mb-4 border-dotted border-top-4" />

            <a class="no-underline hover:underline" href="https://cometh.io">website</a> ·
            <a class="no-underline hover:underline" href="https://bridge.cometh.io">bridge</a>
            <button
                v-if="connectedAccount"
                @click="clearCacheProvier()"
                class="ml-6 inline-flex items-center font-audiowide rounded-sm bg-y bg-yellow-500 text-black hover:bg-yellow-300 px-3 py-1 text-sm"
            >
                Disconnect Wallet
            </button>
        </div>
    </div>
</template>


