import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
})
export default class ButtonWithLoader extends Vue {
    @Prop()
    color = 'gray'

    get backgroundColor() {
        switch (this.color) {
            case 'blue':
                return 'bg-blue-500'
            default:
                return 'bg-gray-800'
        }
    }

    get hoverColor() {
        switch (this.color) {
            case 'blue':
                return 'bg-blue-600'
            default:
                return 'bg-gray-900'
        }
    }
}
