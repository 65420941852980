import Item from '@/game/models/Items'
import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import ItemInfosComponent from '../items/ItemInfosComponent'
import ModalComponent from './ModalComponent'
import UseItemModal from './UseItemModal'
import GameItemsService from '../../services/web3/GameItemsService'

export default class ItemsModal extends ModalComponent {
    private coolingBoosterCount: MainBitmapText
    private coolingCountPanel = PIXI.Sprite.from('item_count_panel')

    private cupTicketCount: MainBitmapText
    private cupTicketCountPanel = PIXI.Sprite.from('item_count_panel')

    private balanceOfItem = 0

    constructor() {
        super(56, 32)
        this.hPosistion = 130

        const infoPanel = new ItemInfosComponent(Item.coolingBooster)
        infoPanel.y = -infoPanel.height - 6
        infoPanel.x = (this.modalWidth - infoPanel.width) / 2

        const coolingBooster = PIXI.Sprite.from(Item.coolingBooster.icon)
        coolingBooster.x = 8
        coolingBooster.y = 6
        coolingBooster.interactive = true
        coolingBooster.cursor = 'pointer'

        coolingBooster.on('mouseover', () => {
            this.modalContainer.addChild(infoPanel)
        })
        coolingBooster.on('mouseout', () => {
            this.modalContainer.removeChild(infoPanel)
        })

        coolingBooster.on('pointertap', async () => {
            if (this.balanceOfItem > 0) {
                document.dispatchEvent(new CustomEvent(UseItemModal.OPEN_USE_ITEM_EVENT, { detail: Item.coolingBooster }))
                this.onExit()
            }
        })

        this.coolingCountPanel = PIXI.Sprite.from('item_count_panel')
        this.coolingCountPanel.x = coolingBooster.x
        this.coolingCountPanel.y = coolingBooster.y + coolingBooster.height - 1

        this.coolingBoosterCount = new MainBitmapText('', {
            fontSize: 4
        })
        this.coolingBoosterCount.x = this.coolingBoosterCount.y = this.coolingCountPanel.y + 1

        const infoCupPanel = new ItemInfosComponent(Item.grandPrixTicket)
        infoCupPanel.y = -infoPanel.height - 6
        infoCupPanel.x = (this.modalWidth - infoPanel.width) / 2

        const cupTicket = PIXI.Sprite.from(Item.grandPrixTicket.icon)
        cupTicket.x = coolingBooster.x + coolingBooster.width + 8
        cupTicket.y = 6
        cupTicket.interactive = true
        cupTicket.cursor = 'pointer'

        cupTicket.on('mouseover', () => {
            this.modalContainer.addChild(infoCupPanel)
        })
        cupTicket.on('mouseout', () => {
            this.modalContainer.removeChild(infoCupPanel)
        })

        this.cupTicketCountPanel = PIXI.Sprite.from('item_count_panel')
        this.cupTicketCountPanel.x = cupTicket.x
        this.cupTicketCountPanel.y = cupTicket.y + cupTicket.height - 1

        this.cupTicketCount = new MainBitmapText('', {
            fontSize: 4
        })
        this.cupTicketCount.x = this.cupTicketCount.y = this.cupTicketCountPanel.y + 1

        const titleBottom = PIXI.Sprite.from('modal_bottom_title')
        titleBottom.x = (this.modalWidth - titleBottom.width) / 2
        titleBottom.y = this.modalHeight - 1

        const titleText = new MainBitmapText('Items', {
            fontSize: 5
        })
        titleText.x = (this.modalWidth - titleText.width) / 2
        titleText.y = titleBottom.y + 1

        this.modalContainer.addChild(titleBottom)

        this.modalContainer.addChild(this.coolingCountPanel)
        this.modalContainer.addChild(coolingBooster)
        this.modalContainer.addChild(this.coolingBoosterCount)

        this.modalContainer.addChild(this.cupTicketCountPanel)
        this.modalContainer.addChild(cupTicket)
        this.modalContainer.addChild(this.cupTicketCount)

        this.modalContainer.addChild(titleText)
        this.setPositions()

        this.loader.visible = false
        this.retrieveItemsBalance()
    }

    async retrieveItemsBalance() {
        this.balanceOfItem = await GameItemsService.getInstance().balanceOf(Item.coolingBooster.id)
        this.coolingBoosterCount.text = 'x' + this.balanceOfItem
        this.coolingBoosterCount.x = this.coolingCountPanel.x + (this.coolingCountPanel.width - this.coolingBoosterCount.width) / 2

        const cupBalance = await GameItemsService.getInstance().balanceOf(Item.grandPrixTicket.id)
        this.cupTicketCount.text = 'x' + cupBalance
        this.cupTicketCount.x = this.cupTicketCountPanel.x + (this.cupTicketCountPanel.width - this.cupTicketCount.width) / 2
    }
}
