import TimeService from '../services/TimeService'
import Orbit from './position/Orbit'
import BN from 'bn.js'

export class Portal {
    public x = 0
    public y = 0

    constructor(public id: number, public orbit: Orbit, public fromId: number, public toId: number, public area = 30, public fees: BN) {}

    public updateCartesian() {
        const timeRef = TimeService.getInstance().now()
        const cartesian = this.orbit.getCartesian(timeRef)
        this.x = cartesian.x
        this.y = cartesian.y
    }
}
