import Web3 from 'web3'
import BN from 'bn.js'
import NFTService from '../services/NFTService'
import Orbit from './position/Orbit'
import Polar from './position/Polar'
import Comete from './Comete'
import Positionnable from './interfaces/Positionnable'
import MinerManagerService from '../services/web3/MinerManagerService'
import JumpManagerService from '../services/web3/JumpManagerService'
import MapService from '../services/MapService'
import Distance from '@/helpers/Distance'
import TimeService from '../services/TimeService'
import * as PIXI from 'pixi.js'
import Colors from '@/helpers/Colors'

export default class Miner implements Positionnable {
    public static RARITY_COMMON = 'common'
    public static RARITY_UNCOMMON = 'uncommon'
    public static RARITY_RARE = 'rare'
    public static RARITY_MYTHIC = 'mythic'

    public nearestComethDistance = Number.MAX_VALUE
    public nearestComet?: Comete

    public x = 0
    public y = 0
    public isSelf = false
    public isNearComete = false
    public isTraveling = false
    public miningArea = 0
    public drill = 0
    public cooldown = 0
    public roverPower = 0
    public solarSystemID = 0
    public rover = ''

    public pullingPrice: BN = new BN(0)
    public orbit = new Orbit({ last: new Polar({ distance: 0, angle: 0 }) })
    public id = ''
    public owner = ''

    public lastAction = 0

    private _primaryColor?: number
    private _extraColor?: number

    private _metadata?: any

    public static MULE_MODEL_ID = 1000

    public static modelFromId(minerId: string) {
        const id = Math.floor(Number(minerId) / 1_000_000)
        return id ? id : 1
    }

    constructor(from: any) {
        if (from.id == 0) {
            return
        }
        this.update(from)
    }

    public update(from: any) {
        this.id = from.id
        this.owner = from.owner
        this.orbit = new Orbit(from.orbit)
        this.pullingPrice = new BN(from.pullingPrice)
        this.solarSystemID = Number(from.solarSystemID)

        this.miningArea = from.miningArea
        this.lastAction = from.lastAction
        this.drill = Number(from.drill)
        this.cooldown = Number(from.cooldown)
        this.roverPower = Number(from.roverPower)
        this.rover = from.roverOn
    }

    public async updateFromMetaData() {
        await this.fetchMetadata()

        this.miningArea = this._metadata.stats.miningArea
        this.lastAction = this._metadata.stats.lastAction
        this.drill = Number(this._metadata.stats.drill)
        this.cooldown = Number(this._metadata.stats.cooldown)
        this.roverPower = Number(this._metadata.stats.roverPower)
    }

    public modelId(): number {
        return Miner.modelFromId(this.id)
    }

    public async updatePullingPrice() {
        const minimumPullPrice = new BN(await JumpManagerService.getInstance().getPullingMinFee(this.solarSystemID))
        const minerUpdated = new Miner(await MinerManagerService.getInstance().getMiner(this.id, this.solarSystemID))
        if (minerUpdated.pullingPrice.lt(minimumPullPrice)) {
          minerUpdated.pullingPrice = minimumPullPrice
        }
        this.pullingPrice = minerUpdated.pullingPrice
    }

    public pullPriceInSpice(): string {
        return Web3.utils.fromWei(this.pullingPrice, 'ether')
    }

    public async name() {
        if (this.id == '') {
            return 0
        }
        if (this._metadata != null) {
            return this._metadata.name!
        }

        await this.fetchMetadata()
        return this._metadata.name!
    }

    public async race() {
        if (this.id == '') {
            return ''
        }
        if (this._metadata != null) {
            return this.findAttribute('race')
        }

        await this.fetchMetadata()
        return this.findAttribute('race')
    }

    public async rarity() {
        if (this.id == '') {
            return ''
        }
        if (this._metadata != null) {
            return this.findAttribute('scarcity')
        }

        await this.fetchMetadata()
        return this.findAttribute('scarcity')
    }

    public findAttribute(trait: string): string {
        const attributes = this._metadata.attributes as Array<any>

        let result = ''
        attributes.forEach(item => {
            if (item.trait_type == trait) {
                result = item.value
            }
        })

        return result
    }

    public async primaryColor() {
        if (this.id == '') {
            return Colors.Blue600
        }
        if (this._primaryColor != null) {
            return this._primaryColor!
        }

        await this.fetchMetadata()
        return this._primaryColor!
    }

    public async extraColor() {
        if (this.id == '') {
            return Colors.Green400
        }
        if (this._extraColor != null) {
            return this._extraColor!
        }

        await this.fetchMetadata()
        return this._extraColor!
    }

    public isCool(): boolean {
        return this.isCoolIn() < 0
    }

    public isCoolIn(): number {
        const timeRef = TimeService.getInstance().now()
        if (this.lastAction == undefined) {
            return 0
        } else {
            return this.cooldown - (timeRef - this.lastAction)
        }
    }

    public updateCartesian() {
        const timeRef = TimeService.getInstance().now()
        const cartesian = this.orbit.getCartesian(timeRef)
        this.x = cartesian.x
        this.y = cartesian.y
    }

    public isOutOfMiningZone() {
        const distance = Math.abs(
            Distance.getDistance(this.x, this.y, MapService.getInstance().noMiningZone.x, MapService.getInstance().noMiningZone.y)
        )
        return distance > MapService.getInstance().noMiningZone.radius
    }

    public pullingQuantileIndex() {
        const quantiles = MapService.getInstance().quantiles
        const price = parseFloat(this.pullPriceInSpice())

        let index = 0
        const foundPerfectIndex = quantiles.indexOf(price)
        if (foundPerfectIndex >= 0) {
            index = foundPerfectIndex
        } else {
            quantiles.forEach((it, i) => {
                if (it <= price) {
                    index++
                }
            })
        }

        return index
    }

    private async fetchMetadata() {
        const metadata = await NFTService.getInstance().getMetadata(this.id)
        this._metadata = metadata.data
        this._extraColor = PIXI.utils.string2hex(this.findAttribute('extra_color'))
        this._primaryColor = PIXI.utils.string2hex(this.findAttribute('primary_color'))
    }
}
