import Resolution from '@/helpers/Resolution'
import GameElement from '../../GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import PlayerService from '../../services/PlayerService'
import ComethMinerGameService from '../../services/web3/ComethMinerGameService'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import JumpManagerService from '../../services/web3/JumpManagerService'
import ModalComponent from './ModalComponent'

export default class PullPriceModal extends ModalComponent {
    private price!: PIXI.BitmapText
    private textIndicator: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private indicatorInterval: NodeJS.Timeout

    constructor() {
        super(200, 104)
        this.position = ModalComponent.POSITION_CENTER

        const titleText = new MainBitmapText('UPDATE PULL PRICE', {
            fontSize: 8
        })
        titleText.x = Math.floor(this.modalWidth / 2 - titleText.width / 2)
        titleText.y = 6

        const title2 = new MainBitmapText('When someone wants to pull to your ship,', {
            fontSize: 6
        })

        title2.y = titleText.y + titleText.height + 4
        title2.x = Math.floor(this.modalWidth / 2 - title2.width / 2)

        const title3 = new MainBitmapText('he will have to pay the required amout.', {
            fontSize: 6
        })
        title3.y = title2.y + title2.height
        title3.x = Math.floor(this.modalWidth / 2 - title3.width / 2)

        const inputLine = PIXI.Sprite.from('pixel')
        inputLine.width = 80
        inputLine.interactive = true
        inputLine.cursor = 'pointer'
        inputLine.hitArea = new PIXI.Rectangle(-16, -16, 80, 16)
        inputLine.x = 48
        inputLine.y = 64

        const priceIcon = PIXI.Sprite.from('ic_spice')
        priceIcon.y = 52
        priceIcon.x = 134

        this.price = new MainBitmapText(PlayerService.getInstance().miner.pullPriceInSpice(), {
            fontSize: 6
        })

        this.price.x = 48
        this.price.y = 53.5

        this.textIndicator.height = 5
        this.textIndicator.y = 55
        this.textIndicator.x = this.price.x + this.price.width

        this.indicatorInterval = setInterval(() => {
            this.textIndicator.visible = !this.textIndicator.visible
        }, 500)

        const noticeFee = new MainBitmapText('5% of this amount will be collected by Cometh to generate new comets', {
            fontSize: 4,
            align: 'center'
        })

        noticeFee.y = 67
        noticeFee.x = Math.floor(this.modalWidth / 2 - noticeFee.width / 2)

        const btn = PIXI.Sprite.from('btn_big')
        btn.x = this.modalWidth / 2 - btn.width / 2
        btn.y = 78
        btn.interactive = true
        btn.cursor = 'pointer'
        btn.on('pointerdown', () => {
            btn.texture = PIXI.Texture.from('btn_big_pressed')
        })
        btn.on('pointerup', () => {
            btn.texture = PIXI.Texture.from('btn_big')
        })
        btn.on('mouseout', () => {
            btn.texture = PIXI.Texture.from('btn_big')
        })
        btn.on('pointertap', async () => {
            const cost = parseFloat(this.price.text)

            if (!isNaN(cost)) {
                await JumpManagerService.getInstance().changePullingPrice(
                    PlayerService.getInstance().miner.id,
                    cost,
                    PlayerService.getInstance().miner.solarSystemID
                )
            }

            this.onExit()
        })

        const btnText = new MainBitmapText('CONFIRM', {
            fontSize: 6
        })
        btnText.x = Math.floor(this.modalWidth / 2 - btnText.width / 2)
        btnText.y = 81

        document.addEventListener('keydown', this.onKeyDown)

        this.modalContainer.addChild(titleText)
        this.modalContainer.addChild(title2)
        this.modalContainer.addChild(title3)
        this.modalContainer.addChild(inputLine)
        this.modalContainer.addChild(priceIcon)
        this.modalContainer.addChild(this.price)
        this.modalContainer.addChild(this.textIndicator)
        this.modalContainer.addChild(noticeFee)
        this.modalContainer.addChild(btn)
        this.modalContainer.addChild(btnText)
        this.setPositions()

        this.loader.visible = false
        this.dataContainer.visible = true
    }

    public onKeyDown = (event: KeyboardEvent) => {
        if (event.code == 'Backspace') {
            this.price.text = this.price.text.substr(0, this.price.text.length - 1)
            event.preventDefault()
        }

        const value = parseInt(event.key)
        if (isNaN(value)) {
            if ((event.key == '.' || event.key == ',') && this.price.text.indexOf('.') < 0) {
                this.price.text += '.'
            }
        } else {
            this.price.text = this.price.text += value.toFixed(0)
        }
        this.textIndicator.x = this.price.x + this.price.width
    }
}
