import SessionTransactionNotifService from '@/game/services/SessionTransactionService'

export enum NotificationType {
    Success,
    Error,
    Portal,
    Default
}

export class Notification {
    private _title = ''
    public id = ''

    public onTitleChanged = () => {
        return
    }

    constructor(public type: NotificationType = NotificationType.Default) {}

    get title(): string {
        return this._title
    }

    set title(value: string) {
        this._title = value
        this.onTitleChanged()
        SessionTransactionNotifService.getInstance().dispatchTxChanged()
    }
}
