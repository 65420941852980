export enum TransactionStatus {
    InProgress,
    Failed,
    Success
}

export class Transaction {
    public hash = '0x123456789012345678901234567890'
    public status: TransactionStatus = TransactionStatus.InProgress
    public error = ''
    public onSuccess: () => void = () => {
        return
    }
    public onError: () => void = () => {
        return
    }
}
