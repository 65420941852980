import Resolution from '@/helpers/Resolution'
import GameElement from '../GameElement'
import Miner from '../models/Miner'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import NotificationManager from './notifications/NotificationManager'
import BN from 'bn.js'
import PlayerService from '../services/PlayerService'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import Colors from '@/helpers/Colors'
import TextButton from './TextButton'
import Web3 from 'web3'
import SpiceService from '../services/web3/SpiceService'
import JumpManagerService from '../services/web3/JumpManagerService'
import MapService from '../services/MapService'
import JumpPriceTooHighModal from './JumpPriceTooHighModal'
import TxGasPreviewButton from './TxGasPreviewButton'
import WalletService from '../services/web3/WalletService'

export default class JumpConfirmModal implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public modalContainer: PIXI.Container = new PIXI.Container()
    public pathContainer: PIXI.Container = new PIXI.Container()
    public prevContainer: PIXI.Container = new PIXI.Container()
    public nextContainer: PIXI.Container = new PIXI.Container()
    public removerContainer: PIXI.Container = new PIXI.Container()
    public alertContainer: PIXI.Container = new PIXI.Container()

    public currentMinerName = new MainBitmapText('Ship name', { fontSize: 5 })
    public currenMinerPrice = new MainBitmapText('0.0001 MUST', { fontSize: 4 })
    public currentIndexText = new MainBitmapText('9', { fontSize: 5 })
    public prevIndexText = new MainBitmapText('9', { fontSize: 5 })
    public nextIndexText = new MainBitmapText('9', { fontSize: 5 })

    public mainPathBG = PIXI.Sprite.from('jump_ship_current')
    public nextPath = PIXI.Sprite.from('jump_ship_next')
    public prevPath = PIXI.Sprite.from('jump_ship_prev')
    public remover = PIXI.Sprite.from('jump_ship_cancel')

    private bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private top: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private left: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private right: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private bottom: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private notificationManager: NotificationManager = NotificationManager.getInstance()
    private confirmText!: PIXI.BitmapText
    private price!: PIXI.BitmapText
    private price$ = new MainBitmapText('0.01 $', { fontSize: 4, tint: Colors.Gold })
    private confirm: TxGasPreviewButton
    private cancel: TextButton

    private jumpPath: Miner[] = []
    public jumpIndex = 0
    public onJumpIndexUpdated = (index: number) => {
        return
    }
    public onStepRemoved = (index: number) => {
        return
    }

    public onConfirm = (gasPriceGWEI: number) => {
        return
    }

    public onCancel = () => {
        return
    }

    private width = 120

    constructor() {
        this.container.scale.set(Resolution.scale)
        this.container.interactive = true
        this.bg.width = this.width
        this.bg.height = this.height
        this.bg.tint = Colors.Blue400

        this.top.tint = Colors.Blue800
        this.bottom.tint = Colors.Blue800
        this.bottom.y = this.height

        // this.left.height = this.modalHeight
        this.left.tint = Colors.Blue800

        // this.right.height = this.modalHeight
        this.right.position.x = this.width - 1
        this.right.tint = Colors.Blue800

        this.mainPathBG.x = Math.floor(this.width / 2 - this.mainPathBG.width / 2)
        this.prevPath.x = Math.floor(this.mainPathBG.x - this.prevPath.width + 7)
        this.prevPath.y = 3
        this.nextPath.x = Math.floor(this.mainPathBG.x + this.mainPathBG.width - 1)
        this.nextPath.y = 3

        this.currentMinerName.x = this.mainPathBG.x + 12
        this.currentMinerName.y = 5
        this.currenMinerPrice.x = this.currentMinerName.x
        this.currenMinerPrice.y = this.currentMinerName.y + this.currentMinerName.height + 2

        this.removerContainer.interactive = true
        this.removerContainer.cursor = 'pointer'
        this.removerContainer.x = this.mainPathBG.x + this.mainPathBG.width - 24
        this.removerContainer.y = Math.floor(-this.remover.height / 2)
        this.removerContainer.on('pointertap', () => {
            this.onStepRemoved(this.jumpIndex)
        })

        this.pathContainer.addChild(this.mainPathBG)
        this.pathContainer.addChild(this.currentMinerName)
        this.pathContainer.addChild(this.currenMinerPrice)
        this.pathContainer.addChild(this.currentIndexText)
        this.removerContainer.addChild(this.remover)
        this.prevContainer.addChild(this.prevPath)
        this.prevContainer.addChild(this.prevIndexText)
        this.nextContainer.addChild(this.nextPath)
        this.nextContainer.addChild(this.nextIndexText)

        this.prevContainer.interactive = true
        this.prevContainer.cursor = 'pointer'
        this.prevContainer.on('click', () => {
            this.updateJumpIndex(this.jumpIndex - 1)
            this.refreshPagination()
        })
        this.nextContainer.interactive = true
        this.nextContainer.cursor = 'pointer'
        this.nextContainer.on('click', () => {
            this.updateJumpIndex(this.jumpIndex + 1)
            this.refreshPagination()
        })

        const rule = new MainBitmapText('Max ' + JumpManagerService.MAX_JUMP_PATH_LENGTH + ' jump points allowed', { fontSize: 4 })
        rule.y = -7
        rule.x = Math.floor(this.width / 2 - rule.width / 2)

        this.confirmText = new MainBitmapText('Confirm the ' + this.jumpPath.length + ' jump(s) for a total of', { fontSize: 5 })
        this.confirmText.y = 6
        this.confirmText.x = Math.floor(this.width / 2 - this.confirmText.width / 2)

        this.price = new MainBitmapText(0 + ' MUST', {
            fontSize: 5
        })
        this.price.tint = Colors.Red500
        this.price.y = Math.floor(this.confirmText.y + this.confirmText.height + 2)
        this.price$.y = this.price.y + this.price.height

        const alertBg = PIXI.Sprite.from('pixel')
        alertBg.width = this.width - 12
        alertBg.tint = Colors.Red300
        alertBg.x = 6

        const alertText = new MainBitmapText('This ship has a mining opportunity.\nIf you jump, it will be dismissed', {
            fontSize: 5 - 1
        })
        alertText.x = alertBg.x + 6
        alertText.y = 4
        alertText.alpha = 0.8

        alertBg.height = alertText.height + 8
        this.alertContainer.y = this.price$.y + this.price$.height + 4
        this.alertContainer.addChild(alertBg)
        this.alertContainer.addChild(alertText)

        this.confirm = new TxGasPreviewButton('JUMP')
        this.confirm.onResized = () => {
            this.onResize()
        }
        this.confirm.onClick = (gasPriceGWEI: number) => {
            let totalPullingPrice: BN = new BN(0)

            this.jumpPath.forEach(item => {
                totalPullingPrice = totalPullingPrice.add(item.pullingPrice)
            })

            if (PlayerService.getInstance().allowance.gte(totalPullingPrice)) {
                const price = parseFloat(Web3.utils.fromWei(this.totalPullingPrice, 'ether'))
                const maxPrice = 0.1
                if (price >= maxPrice * this.jumpPath.length) {
                    const modal = new JumpPriceTooHighModal()

                    this.container.parent.parent.addChild(modal.getContainer())
                    modal.enterAnimation(() => {
                        return
                    })
                    modal.onExit = () => {
                        modal.exitAnimation(() => {
                            modal.getContainer().destroy({ children: true })
                        })
                    }
                    modal.onContinue = () => {
                        modal.onExit()
                        this.onConfirm(gasPriceGWEI)
                    }
                    // TODO open confirm modal
                } else {
                    this.onConfirm(gasPriceGWEI)
                }
            } else {
                PlayerService.getInstance().approveGame(10000000)
            }
        }

        this.cancel = new TextButton('CANCEL')
        this.cancel.btn.tint = Colors.Red400
        this.cancel.container.on('pointertap', () => {
            this.onCancel()
        })

        this.container.alpha = 0
        this.container.addChild(this.pathContainer)
        this.pathContainer.addChild(this.prevContainer)
        this.pathContainer.addChild(this.nextContainer)
        this.pathContainer.addChild(this.removerContainer)
        this.container.addChild(this.modalContainer)
        this.modalContainer.addChild(this.bg)
        this.modalContainer.addChild(this.top)
        this.modalContainer.addChild(this.bottom)
        this.modalContainer.addChild(this.left)
        this.modalContainer.addChild(this.right)
        this.modalContainer.addChild(this.alertContainer)
        this.modalContainer.addChild(this.confirmText)
        this.modalContainer.addChild(rule)
        this.modalContainer.addChild(this.price)
        this.modalContainer.addChild(this.price$)
        this.modalContainer.addChild(this.confirm)
        this.modalContainer.addChild(this.cancel.container)

        // Hide $ price as we dont want to display it for now
        this.price$.visible = false

        this.onResize()

        document.addEventListener(SpiceService.ALLOWANCE_CHANGED_EVENT, () => {
            this.checkAllowance()
        })
        this.checkAllowance()
        this.checkMiningAlert()
    }

    async update(jumpPath: Miner[]) {
        this.jumpIndex++
        this.jumpPath = jumpPath

        const ethPrice = Number(Web3.utils.fromWei(this.totalPullingPrice, 'ether'))
        this.price.text = ethPrice + ' MUST'

        /* Prevent call for $ price as we dont want to display it for now
        const price = await WalletService.getInstance().USDPriceOf(WalletService.MUST_ADDRESS)
        this.price$.text = (ethPrice * price).toFixed(2) + ' $' */

        if (this.jumpPath.length > 1) {
            this.confirmText.text = 'Confirm the ' + this.jumpPath.length + ' jumps for a total of'
        } else {
            this.confirmText.text = 'Confirm the jump for'
        }

        if (this.jumpIndex > this.jumpPath.length - 1) {
            this.jumpIndex = this.jumpPath.length - 1
        }

        this.checkAllowance()
        this.refreshPagination()
        this.onResize()
    }

    public updateJumpIndex(index: number) {
        this.jumpIndex = index
        this.refreshPagination()
        this.onJumpIndexUpdated(index)
    }

    checkAllowance = () => {
        if (PlayerService.getInstance().allowance.lt(this.totalPullingPrice)) {
            this.confirm.text.text = 'APPROVE'
        } else {
            this.confirm.text.text = 'JUMP'
        }
        this.confirm.onResize()
    }

    checkMiningAlert = () => {
        if (this.notificationManager.hasMiningNotification(PlayerService.getInstance().miner.id)) {
            this.alertContainer.visible = true

            this.confirm.y = this.alertContainer.y + this.alertContainer.height + 4
            this.cancel.container.y = this.confirm.y

            this.bg.height = this.height
        } else {
            this.bg.height = this.height - this.alertContainer.height
            this.alertContainer.visible = false

            this.confirm.y = this.price$.y + this.price$.height + 4
            this.cancel.container.y = this.confirm.y
        }

        this.right.height = this.bg.height
        this.left.height = this.bg.height

        this.onResize()
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        this.confirm.x = Math.floor(this.width * (2 / 3) - this.confirm.width / 2 + this.confirm.gasBtn.width / 2)
        this.cancel.container.x = Math.floor(this.width * (1 / 3) - this.cancel.container.width / 2)

        this.bottom.y = this.bg.height - 1
        this.container.scale.set(Resolution.scale)
        this.container.position.x = Math.floor(Resolution.realWidth / 2 - (this.width * Resolution.scale) / 2)
        this.container.position.y = Math.floor(Resolution.realHeight - this.container.height - Resolution.margin6)
        this.confirmText.x = Math.floor(this.width / 2 - this.confirmText.width / 2)
        this.price.x = Math.floor(this.width / 2 - this.price.textWidth / 2)
        this.price$.x = Math.floor(this.width / 2 - this.price$.textWidth / 2)

        this.modalContainer.y = this.pathContainer.height + 4

        this.currentIndexText.y = Math.floor(this.mainPathBG.height / 2 - this.currentIndexText.height / 2)
        this.currentIndexText.x = Math.floor(this.mainPathBG.x + this.mainPathBG.width - 7 - this.currentIndexText.width / 2) - 2 / 3

        this.prevIndexText.y = Math.floor(this.prevPath.y + this.prevPath.height / 2 - this.prevIndexText.height / 2)
        this.prevIndexText.x = Math.floor(this.prevPath.x + this.prevPath.width - 15 - this.prevIndexText.width / 2) - 2 / 3

        this.nextIndexText.y = Math.floor(this.nextPath.y + this.nextPath.height / 2 - this.nextIndexText.height / 2)
        this.nextIndexText.x = Math.floor(this.nextPath.x + this.nextPath.width - 7 - this.nextIndexText.width / 2) - 2 / 3
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    async enterAnimation(callback: () => void): Promise<void> {
        this.container.visible = true
        this.notificationManager.setOnNotificationListChanged(this.checkMiningAlert)
        this.confirm.forceRefreshGasPrice()
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.alpha = perc
            this.top.width = this.width * perc
            this.bottom.width = this.width * perc
            this.bottom.x = this.width - this.width * perc
            this.left.height = this.bg.height * perc
            this.left.y = this.bg.height - this.bg.height * perc
            this.right.height = this.bg.height * perc
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        this.notificationManager.removedOnNotificationListChanged(this.checkMiningAlert)
        await animate('easeInQuad', 300, (perc: number) => {
            this.container.alpha = 1 - perc
            this.top.width = this.width - this.width * perc
            this.bottom.width = this.width - this.width * perc
            this.bottom.x = this.width * perc
            this.left.height = this.bg.height - this.bg.height * perc
            this.left.y = this.bg.height * perc
            this.right.height = this.bg.height - this.bg.height * perc
        })
        this.container.visible = false
        callback()
    }

    get totalPullingPrice(): BN {
        return this.jumpPath.reduce((prev, curr) => {
            return prev.add(curr.pullingPrice)
        }, new BN(0))
    }

    private async refreshPagination() {
        if (this.jumpPath.length == 0) return

        if (this.jumpIndex == 0) {
            this.prevContainer.visible = false
        } else {
            this.prevContainer.visible = true
            this.prevIndexText.text = this.jumpIndex.toFixed(0)
        }

        if (this.jumpIndex == this.jumpPath.length - 1) {
            this.nextContainer.visible = false
        } else {
            this.nextContainer.visible = true
            this.nextIndexText.text = (this.jumpIndex + 2).toFixed(0)
        }

        const item = this.jumpPath[this.jumpIndex]
        this.currentMinerName.text = 'Loading...'
        this.currenMinerPrice.text = item.pullPriceInSpice() + ' MUST'
        this.currentIndexText.text = (this.jumpIndex + 1).toFixed(0)
        const name: string = await item.name()
        this.currentMinerName.text = name.length > 17 ? name.substring(0, 14) + '...' : name

        this.onResize()
    }

    get height(): number {
        return 44 + this.alertContainer.height
    }
}
