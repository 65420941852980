<template>
    <div class="relative w-screen h-screen overflow-hidden font-audiowide">
        <Header ref="header" />

        <!-- app -->
        <div id="app-container" class="origin-top-left overflow-hidden" :style="{ width: appWidth + 'px', height: appHeight + 'px' }">
            <!-- background -->
            <img class="absolute top-0" :width="appWidth" :height="appHeight" src="/img/hangar.png" />

            <!-- Auth container -->
            <div :style="{ width: appWidth + 'px', height: appHeight + 'px' }" class="absolute top-0" :class="{ 'opacity-0': !isAuth }">
                <transition name="slide-fade-ltr">
                    <!-- client -->
                    <img v-if="isAuth" class="absolute bottom-0 left-[25px]" width="546" height="651" src="/img/client.gif" />
                </transition>

                <transition name="slide-fade-rtl">
                    <!-- bar -->
                    <img v-if="isAuth" class="absolute bottom-0 right-0" width="1296" height="1068" src="/img/bar.gif" />
                </transition>

                <transition name="slide-fade-rtl">
                    <template v-if="isAuth && token !== ''">
                        <div
                            v-if="token === 'gecko'"
                            class="hidden xl:block gecko absolute right-[310px] bottom-[450px] w-60 overflow-hidden rounded-md border-2 border-[#57c2ff]"
                        >
                            <img src="@/assets/kiosk/gecko.png" />
                        </div>
                        <div
                            v-else-if="token === 'frozen-croissant'"
                            class="hidden xl:block gecko absolute right-[335px] bottom-[450px]  overflow-hidden rounded-md border-2 border-[#57c2ff]"
                        >
                            <img src="@/assets/kiosk/frozen-croissant.png" />
                        </div>
                    </template>
                </transition>
            </div>
            <!-- end Auth container -->
        </div>

        <transition name="fade">
            <div v-if="isAuth">
                <div
                    class="fixed inset-0 w-full max-w-md px-5 py-4 text-white mt-16 lg:mt-0 lg:absolute lg:inset-auto lg:left-[380px] lg:bottom-[280px]"
                >
                    <transition name="loading" mode="out-in">
                        <div v-if="loading" class="flex flex-col items-center">
                            <div class="text-center neon text-[#f7fc3c] animate-pulse mt-10 lg:-mt-64 text-2xl">NFT is coming</div>
                            <p v-if="transactionHash" class="text-center mt-2">
                                Pending txHash:<br />
                                {{ transactionHash }}
                            </p>
                            <svg
                                class="animate-spin h-10 w-10 mt-6 text-[#f7fc3c]"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path
                                    class="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        </div>
                        <div v-else class="flex flex-col items-stretch w-full lg:max-w-3xl">
                            <div class="mb-4 neon text-[#f7fc3c] text-2xl text-center">Redeem your NFT</div>
                            <div
                                v-if="token === 'gecko'"
                                class="my-2 xl:hidden mx-auto max-w-[200px] overflow-hidden rounded-md border-2 border-[#57c2ff]"
                            >
                                <img src="@/assets/kiosk/gecko.png" />
                            </div>
                            <div
                                v-else-if="token === 'frozen-croissant'"
                                class="my-2 xl:hidden mx-auto max-w-[200px] overflow-hidden rounded-md border-2 border-[#57c2ff]"
                            >
                                <img src="@/assets/kiosk/frozen-croissant.png" />
                            </div>

                            <!-- <label for="code">Code</label> -->
                            <input
                                v-model="code"
                                placeholder="Code"
                                class="w-full font-inter mt-2 flex-auto  text-[#00e7ff] border-2 border-[#00e7ff] bg-[#184960] bg-opacity-80  items-center justify-center rounded  focus:outline-none px-3 py-2"
                            />
                            <span v-if="!isCodeValid" class="mt-1 text-red-500">Invalid code</span>
                            <input
                                v-model="address"
                                placeholder="Address"
                                class="font-inter mt-2 flex-auto  text-[#00e7ff] border-2 border-[#00e7ff] bg-[#184960] bg-opacity-80 hover:bg-[#113647] hover:bg-opacity-80 items-center justify-center rounded focus:outline-none px-3 py-2"
                            />
                            <button
                                @click="redeem"
                                :class="{ 'hover:bg-[#113647]': !loading }"
                                class="mt-8 flex-none neon-blue text-[#00e7ff] border-2 border-[#00e7ff] bg-[#184960]   hover:bg-opacity-80 flex items-center justify-center rounded  focus:outline-none px-6 py-3 cursor-pointer uppercase"
                            >
                                <svg
                                    v-if="loading"
                                    class="-ml-1 mr-2 animate-spin h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                <span>Redeem</span>
                            </button>
                            <div
                                v-if="redeemError"
                                class="flex mt-2 rounded bg-red-500 px-4 py-2 bg-opacity-80 border border-red-200 font-inter text-red-200"
                            >
                                {{ redeemError }}
                            </div>
                            <div
                                v-if="serverError"
                                class="flex mt-2 rounded bg-red-500 px-4 py-2 bg-opacity-80 border border-red-200 font-inter text-red-200"
                            >
                                <p>
                                    An unknown error has occured. Please contact Cometh support on
                                    <a href="//discord.gg/gAWTyctkze" target="_blank" class="underline">Discord</a> or
                                    <a href="//t.me/cometh_io" target="_blank" class="underline">Telegram</a>.
                                </p>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>

        <!-- connection overlay -->
        <div v-if="!isAuth" class="fixed inset-0 w-full h-full text-white transition-opacity flex items-center justify-center">
            <div class="absolute inset-0 bg-black bg-opacity-80"></div>

            <div class="relative flex flex-col items-center px-4">
                <h1 class="mb-8 neon text-[#f7fc3c] text-2xl text-center">
                    Please connect your wallet before entering the kiosk
                </h1>
                <button
                    @click="openConnection()"
                    class="neon-blue text-[#00e7ff] border-2 border-[#00e7ff] bg-[#184960] bg-opacity-80 hover:bg-[#113647] hover:bg-opacity-80 flex items-center justify-center rounded text-2xl focus:outline-none px-6 py-3 uppercase"
                >
                    Connect my wallet
                </button>
            </div>
        </div>
        <!-- end connection overlay -->
        <!--  modal -->

        <!-- <Modal :open="modal" @close="closeModal">
            <div class="bg-theme-400">
                <img v-if="tokenImg" src="https://storage.googleapis.com/gameitems.service.cometh.io/images/polygon_ticket_cup.png" alt="" />
                <svg v-else class="animate-spin -ml-1 mr-3 h-5 w-5 text-[#00e7ff]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </div>
        </Modal> -->

        <transition leave-active-class="duration-500">
            <div v-show="modal" id="kiosk_claim_successful">
                <div class="fixed z-50 inset-0 flex items-center justify-center">
                    <transition name="overlay">
                        <div v-show="modal" @click="closeModal()" class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"></div>
                    </transition>

                    <div class="relative">
                        <transition name="modal">
                            <div v-show="modal" class="flex flex-col items-center">
                                <div class="neon text-[#f7fc3c] text-2xl text-center flex flex-col">
                                    <span class="text-3xl mb-2">Congratulations!</span>
                                    <span>You redeemed this NFT</span>
                                </div>
                                <img class="mt-8 neon-box overflow-hidden rounded-md border-2 border-[#57c2ff]" width="300" :src="tokenImg" />
                                <div v-if="transactionHash" class="">
                                    <a
                                        target="_blank"
                                        :href="`${config.polygonscanUrl + transactionHash}`"
                                        class="inline-flex neon text-[#f7fc3c] hover:underline"
                                    >
                                        <span>View tx</span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="ml-2 h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                            />
                                        </svg>
                                    </a>
                                </div>

                                <!-- <router-link to="/">
                                    <a
                                        @click="closeModal()"
                                        href="#"
                                        class="neon-blue text-[#00e7ff] border-2 border-[#00e7ff] bg-[#184960] bg-opacity-80 hover:bg-[#113647] hover:bg-opacity-80 flex items-center justify-center rounded text-2xl focus:outline-none px-6 py-3 uppercase"
                                    >
                                        play
                                    </a>
                                </router-link> -->
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </transition>
        <!--  end modal -->
    </div>
</template>

<script lang="ts" src="./Kiosk.ts"></script>

<style scoped>
/*
::v-global allows us to apply css to a div outside of Kiosk component,
which is the case of confetti canvas that is added to the body
 */
::v-global(#confetti-canvas) {
    position: absolute;
    top: 0;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(10px);
    }
}
.gecko {
    animation-name: float;
    animation-duration: 2s;
    animation-direction: alternate;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    box-shadow: 0 0 0.2rem #57c2ff, 0 0 0.2rem #57c2ff, 0 0 2rem #57c2ff, 0 0 0.8rem #57c2ff, 0 0 2.8rem #57c2ff, inset 0 0 1.3rem #57c2ff;
}
.neon-box {
    box-shadow: 0 0 0.2rem #57c2ff, 0 0 0.2rem #57c2ff, 0 0 2rem #57c2ff, 0 0 0.8rem #57c2ff, 0 0 2.8rem #57c2ff, inset 0 0 1.3rem #57c2ff;
}
.neon {
    text-shadow: 1px 1px 20px #f7fc3c, 1px 1px 20px black;
}
.neon-blue {
    text-shadow: 1px 1px 20px #00c0ff;
}

.overlay-enter-active,
.overlay-leave-active {
    transition: opacity 0.5s;
}
.overlay-enter-from,
.overlay-leave-to {
    opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
    transition: all 0.5s;
}
.modal-enter-from,
.modal-leave-to {
    transform: translateY(10px);
    opacity: 0;
}

/* FADE AND SCALE */
.fade-scale-enter-active {
    transition: all 0.3s ease-out;
}

.fade-scale-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: scale(0.7);
    opacity: 0;
}

/* SLIDE AND FADE IN FROM RIGHT TO LEFT */
.slide-fade-rtl-enter-active {
    transition: all 0.3s ease-out;
    transition-delay: 0.3s;
}

.slide-fade-rtl-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-rtl-enter-from,
.slide-fade-rtl-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
/* SLIDE AND FADE IN FROM LEFT TO RIGHT */
.slide-fade-ltr-enter-active {
    transition: all 0.3s ease-out;
    transition-delay: 0.3s;
}

.slide-fade-ltr-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-ltr-enter-from,
.slide-fade-ltr-leave-to {
    transform: translateX(-20px);
    opacity: 0;
}
/* FADE IN */
.fade-enter-active {
    transition: opacity 1s;
    transition-delay: 0.7s;
}
.fade-enter-from {
    opacity: 0;
}

.loading-enter-active,
.loading-leave-active {
    transition: opacity 0.3s;
}
.loading-enter-from,
.loading-leave-to {
    opacity: 0;
}
.loading-enter-to,
.loading-leave-from {
    opacity: 1;
}
</style>
