import { Vue } from 'vue-class-component'
// import { Percent, ChainId, Token, TokenAmount, Pair, TradeType, Route, Trade, Fetcher, JSBI } from '@cometh-game/comethswap-sdk'
// import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import Web3Service from '@/game/services/Web3Service'
import SpaceShipsService from '@/game/services/web3/SpaceShipsService'
import NFTService from '@/game/services/NFTService'
import { Options } from 'vue-class-component'
import Header from '@/components/header/Header.vue'
import HeaderTs from '@/components/header/Header'
import Connection, { ConnectionType } from "@/components/connection/Connection"
import config from '@/config'
import Button from '@/components/button/Button.vue'
import GameItemsService from '@/game/services/web3/GameItemsService'

class AvailableShip {
  public internalId!: number
  public name!: string
  public transferring!: boolean
  public originalId!: number
  public newId!: number
  public img!:string
}

@Options({
  components: {
    Header, Button
  }
})
export default class TokenTransfer extends Vue {
  $refs!: {
    header: HeaderTs
  }

  loginModal = false
  isConnectedWallet = false
  isChangingConnection = false;
  showConfirmationModal = false;

  config = config
  isLoadingShips = true
  availableShips = new Map<number, AvailableShip>();
  showTransferModal = false;
  isTransferring = false;
  transferSuccessful = false;
  receiverAddress = ''
  addressError = false;
  shipToTransfer: AvailableShip = new AvailableShip

  async mounted() {
    this.isChangingConnection = Connection.getInstance().isConnecting;

    Connection.getInstance().on('connecting', () => {
      console.log('connecting...');
      this.isChangingConnection = true;
    })

    Connection.getInstance().on('providerChanged', async provider => {
      console.log('provider changed...');
      this.isChangingConnection = false;
      // check if disconnected
      if (!provider) {
        // user is disconnected
        return;
      }

      await this.init();
    })

    const web3 = Web3Service.getInstance().getWssWeb3();
    this.init();
  }

  async init() {

    if (Connection.getInstance().getConnectionType() == ConnectionType.Metamask) {
      try {
        await (window as any).ethereum.request({
          id: 1,
          jsonrpc: '2.0',
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x' + config.network.toString(16),
              chainName: config.l2NetworkLabel,
              rpcUrls: [config.rpc],
              iconUrls: ['https://polygon.technology/wp-content/uploads/2021/01/logo-polygon.png'],
              nativeCurrency: {
                name: 'Matic',
                symbol: 'Matic',
                decimals: 18
              },
              blockExplorerUrls: [config.etherscanUrl]
            }
          ]
        })
      } catch (e) {
        // metamask is probably outdated here
        console.log(e)
      }
    }

    this.retrieveShip();
    
  }

  async retrieveShip() {
    this.isLoadingShips = true

    const shipsOnL2 = await SpaceShipsService.getInstance().tokensOfConnected()

    shipsOnL2.map(async (id) => {
      const metadata = await NFTService.getInstance().getMetadata(id.toString())
      const name = metadata.data.name
      const ship = new AvailableShip()
      ship.internalId = id
      ship.name = name
      ship.img = config.shipImageUrl + '/' +id + '.png'
      this.availableShips.set(ship.internalId, ship)
    })

    const geckoBalance = await GameItemsService.getInstance().balanceOf(4)

    if(geckoBalance>0){
      const gecko = new AvailableShip()
      gecko.name = 'Gecko Pilot';
      gecko.img = 'https://storage.googleapis.com/cometh-game-items/images/gecko_pilot.png'
      gecko.internalId = 4
      this.availableShips.set(gecko.internalId, gecko);
    }

    console.log('availableShips:',this.availableShips)

    this.isLoadingShips = false

  }

  buyCrypto() {
    this.$refs.header.buyCrypto();
  }

  async onRampPurchased(payload: any) {
    console.log(payload);
    this.init();
  }

  askForLogin() {
    this.loginModal = true
  }

  cancelLogin() {
    this.loginModal = false
  }

  openTransferModal(ship: AvailableShip) {
    this.shipToTransfer = ship
    this.showTransferModal = true;
  }

  closeTransferModal() {
    this.showTransferModal = false;
    this.retrieveShip();
  }

  async transfer() {
    if (!this.receiverAddress) {
      this.addressError = true;
      return;
    }

    if (this.shipToTransfer === null) {
      return
    }

    console.log("shipToTransfer", this.shipToTransfer);

    this.isTransferring = true

    const to = await Web3Service.getInstance().getAccount()

    if(this.shipToTransfer.internalId == 4){
      console.log(await GameItemsService.getInstance().safeTransferFrom(this.receiverAddress, this.shipToTransfer.internalId.toString(), 1, '0x'));
    } else{
      console.log(await SpaceShipsService.getInstance().safeTransferFrom(this.receiverAddress, this.shipToTransfer.internalId.toString(), '0x'));
    }
    
    
    this.isTransferring = false
    this.transferSuccessful = true

    setTimeout(() => {
      this.closeTransferModal()
    }, 5000)

  }
}
