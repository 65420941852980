import Resolution from '@/helpers/Resolution'
import GameElement from './GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import SoundService from './services/SoundService'
import Colors from '@/helpers/Colors'
import ButtonComponent from './components/ButtonComponent'
import MainBitmapText from './pixi-scale/MainBitmapText'

export default class GSettings implements GameElement {
    public onExit: () => void = () => {
        return
    }
    private container: PIXI.Container = new PIXI.Container()
    private bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private modalContainer: PIXI.Container = new PIXI.Container()
    private top: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private left: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private right: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private bottom: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private separator: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private modalBg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private logo: PIXI.Sprite = PIXI.Sprite.from('logo')

    private modalWidth = 180
    private modalHeight = 120

    private volumeValue!: PIXI.BitmapText
    private volumeProgress!: PIXI.Sprite
    private volumeIndicator!: PIXI.Sprite

    private fullscreenYes!: PIXI.BitmapText
    private fullscreenNo!: PIXI.BitmapText

    constructor(public pixi: PIXI.Application) {
        this.bg.tint = 0x00000
        this.bg.alpha = 0.5
        this.bg.interactive = true
        this.bg.cursor = 'pointer'
        this.bg.on('pointertap', () => {
            this.onExit()
        })

        this.container.addChild(this.bg)

        this.top.width = this.modalWidth
        this.top.tint = 0x49bfff

        // this.bottom.width = this.modalWidth
        this.bottom.position.y = this.modalHeight
        this.bottom.tint = 0x49bfff

        // this.left.height = this.modalHeight
        this.left.tint = 0x49bfff

        // this.right.height = this.modalHeight
        this.right.position.x = this.modalWidth - 1
        this.right.tint = 0x49bfff

        this.separator.position.y = 62
        this.separator.tint = 0x49bfff
        this.separator.width = this.modalWidth

        this.modalBg.height = this.modalHeight
        this.modalBg.width = this.modalWidth
        this.modalBg.tint = Colors.Blue300
        this.modalBg.interactive = true

        const fullScreenTitle = new MainBitmapText('Fullscreen', {
            fontSize: Resolution.fontSize
        })
        fullScreenTitle.position.x = 16
        fullScreenTitle.position.y = 28

        let isFullscreen = false
        this.fullscreenYes = new MainBitmapText('YES', {
            fontSize: Resolution.fontSize
        })
        this.fullscreenYes.position.y = fullScreenTitle.y
        this.fullscreenYes.position.x = this.modalWidth - 16 - this.fullscreenYes.width
        this.fullscreenYes.tint = 0x04050c
        this.fullscreenYes.interactive = true
        this.fullscreenYes.cursor = 'pointer'
        this.fullscreenYes.on('pointertap', () => {
            document.body.requestFullscreen()
        })

        this.fullscreenNo = new MainBitmapText('NO', {
            fontSize: Resolution.fontSize
        })
        this.fullscreenNo.position.x = this.fullscreenYes.x - 8 - this.fullscreenNo.width
        this.fullscreenNo.position.y = fullScreenTitle.y
        this.fullscreenNo.interactive = true
        this.fullscreenNo.cursor = 'pointer'
        this.fullscreenNo.on('pointertap', () => {
            if (isFullscreen) {
                document.exitFullscreen()
            }
        })

        document.addEventListener('fullscreenchange', ev => {
            if (document.fullscreenElement) {
                isFullscreen = true
                this.fullscreenYes.tint = 0xffffff
                this.fullscreenNo.tint = 0x04050c
            } else {
                this.fullscreenYes.tint = 0x04050c
                this.fullscreenNo.tint = 0xffffff
                isFullscreen = false
            }
        })

        const logoutButton = new ButtonComponent('Logout')
        logoutButton.x = Math.floor(this.modalWidth / 2 - logoutButton.width / 2)
        logoutButton.y = 44
        logoutButton.onClick = () => {
            window.location.href = '/'
        }

        this.setPositions()

        this.logo.position.x = this.modalWidth / 2 - this.logo.width / 2
        this.logo.position.y = -this.logo.height * 2

        const comethText = new MainBitmapText('COMETH has been created by a group of', {
            fontSize: 6
        })
        comethText.position.x = Math.floor(this.modalBg.width / 2 - comethText.width / 2)
        comethText.position.y = this.separator.y + 8

        const comethText2 = new MainBitmapText('Blockchain enthusiasts and gamers.', {
            fontSize: 6
        })
        comethText2.position.x = Math.floor(this.modalBg.width / 2 - comethText2.width / 2)
        comethText2.position.y = comethText.y + comethText.height

        const comethText3 = new MainBitmapText('See more about the team or contact us !', {
            fontSize: 6,
            align: 'center'
        })
        comethText3.position.x = Math.floor(this.modalBg.width / 2 - comethText3.width / 2)
        comethText3.position.y = comethText2.y + comethText2.height

        const telegram = PIXI.Sprite.from('btn_telegram')
        telegram.y = comethText3.y + comethText3.height + 8
        telegram.x = this.modalWidth / 2 - telegram.width / 2
        this.setupShareBtn(telegram, 'telegram@cometh', 't.me/cometh_io')
        const web = PIXI.Sprite.from('btn_web')
        web.y = telegram.y
        web.x = telegram.x - 24
        this.setupShareBtn(web, 'cometh.io', 'cometh.io')
        const twitter = PIXI.Sprite.from('btn_twitter')
        twitter.y = telegram.y
        twitter.x = telegram.x + 24
        this.setupShareBtn(twitter, 'twitter@cometh', 'twitter.com/MUSTcometh')

        this.modalContainer.addChild(this.modalBg)
        this.modalContainer.addChild(this.top)
        this.modalContainer.addChild(this.left)
        this.modalContainer.addChild(this.right)
        this.modalContainer.addChild(this.bottom)
        this.modalContainer.addChild(this.logo)
        this.modalContainer.addChild(this.separator)

        this.modalContainer.addChild(web)
        this.modalContainer.addChild(telegram)
        this.modalContainer.addChild(twitter)
        this.modalContainer.addChild(logoutButton)
        this.modalContainer.addChild(this.logo)
        this.modalContainer.addChild(this.logo)

        this.setupVolume()
        this.modalContainer.addChild(fullScreenTitle)
        this.modalContainer.addChild(this.fullscreenYes)
        this.modalContainer.addChild(this.fullscreenNo)
        this.modalContainer.addChild(comethText)
        this.modalContainer.addChild(comethText2)
        this.modalContainer.addChild(comethText3)
        this.container.addChild(this.modalContainer)
        this.setPositions()

        this.modalContainer.alpha = 0
        this.bg.alpha = 0
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await animate('easeOutQuad', 300, (perc: number) => {
            this.bg.alpha = perc * 0.5
            this.modalContainer.alpha = perc
            this.top.width = this.modalWidth * perc
            this.bottom.width = this.modalWidth * perc
            this.bottom.x = this.modalWidth - this.modalWidth * perc
            this.left.height = this.modalHeight * perc
            this.left.y = this.modalHeight - this.modalHeight * perc
            this.right.height = this.modalHeight * perc
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await animate('easeInQuad', 300, (perc: number) => {
            this.modalContainer.alpha = 1 - perc
            this.bg.alpha = 0.5 - perc * 0.5
            this.top.width = this.modalWidth - this.modalWidth * perc
            this.bottom.width = this.modalWidth - this.modalWidth * perc
            this.bottom.x = this.modalWidth * perc
            this.left.height = this.modalHeight - this.modalHeight * perc
            this.left.y = this.modalHeight * perc
            this.right.height = this.modalHeight - this.modalHeight * perc
        })
        callback()
    }

    onResize(): void {
        this.bg.width = Resolution.realWidth
        this.setPositions()
    }

    public tick(): void {
        // this.bg!.tilePosition.y += 0.1
    }

    public getContainer(): PIXI.Container {
        return this.container
    }

    private setPositions() {
        this.bg.width = Resolution.realWidth
        this.bg.height = Resolution.realHeight
        this.modalContainer.scale.set(Resolution.scale)
        this.modalContainer.position.x = Math.floor(Resolution.realWidth / 2 - (this.modalWidth * Resolution.scale) / 2)
        this.modalContainer.position.y = Math.floor(Resolution.realHeight / 2 - (this.modalHeight * Resolution.scale) / 2)
    }

    private setupShareBtn(btn: PIXI.Sprite, shareText: string, shareUrl: string) {
        const text = new MainBitmapText(shareText, {
            fontSize: Resolution.fontSize
        })

        btn.interactive = true
        btn.cursor = 'pointer'
        btn.on('mouseover', () => {
            text.y = btn.y + btn.height + text.height
            text.x = btn.x + btn.width / 2 - text.width / 2
            this.modalContainer.addChild(text)
        })
        btn.on('mouseout', () => {
            this.modalContainer.removeChild(text)
        })
        btn.on('pointertap', () => {
            window.open('https://' + shareUrl)
        })
    }

    public setupVolume() {
        const volumeTitle = new MainBitmapText('Volume', {
            fontSize: Resolution.fontSize
        })
        volumeTitle.x = 16
        volumeTitle.y = 12

        const volumeContainer = new PIXI.Container()
        volumeContainer.x = 64
        volumeContainer.y = 15
        volumeContainer.interactive = true
        volumeContainer.cursor = 'pointer'
        let isVolumeDragging = false

        const volumeBar = PIXI.Sprite.from('pixel')
        volumeBar.width = 100
        volumeBar.y = 1
        volumeBar.tint = 0x04050c

        this.volumeProgress = PIXI.Sprite.from('pixel')
        this.volumeProgress.y = 1
        this.volumeProgress.tint = 0x49bfff
        this.volumeProgress.width = volumeBar.width * SoundService.getInstance().getMainVolume()

        this.volumeIndicator = PIXI.Sprite.from('progress_indicator')
        this.volumeIndicator.x = volumeBar.width * SoundService.getInstance().getMainVolume() - 2

        this.volumeValue = new MainBitmapText((SoundService.getInstance().getMainVolume() * 100).toFixed(0), {
            fontSize: Resolution.fontSize
        })
        this.volumeValue.x = volumeContainer.x + volumeBar.width / 2 - this.volumeValue.width / 2
        this.volumeValue.y = 6

        this.modalContainer.addChild(volumeTitle)
        this.modalContainer.addChild(volumeContainer)
        this.modalContainer.addChild(this.volumeValue)
        volumeContainer.addChild(volumeBar)
        volumeContainer.addChild(this.volumeProgress)
        volumeContainer.addChild(this.volumeIndicator)

        volumeContainer.hitArea = new PIXI.Rectangle(-4, -4, volumeBar.width + 4, 8)
        volumeContainer.on('pointerdown', () => {
            isVolumeDragging = true
        })
        document.addEventListener('pointerup', () => {
            isVolumeDragging = false
        })
        volumeContainer.on('pointermove', (event: PIXI.InteractionEvent) => {
            if (isVolumeDragging) {
                const x = (event.data.global.x - (this.modalContainer.x + volumeContainer.x * Resolution.scale)) / Resolution.scale
                let volume = x / volumeBar.width
                if (volume < 0) volume = 0
                if (volume > 1) volume = 1
                this.volumeProgress.width = volumeBar.width * volume
                this.volumeIndicator.x = this.volumeProgress.width - 2
                this.volumeValue.text = (volume * 100).toFixed(0)
                SoundService.getInstance().updateMainVolume(volume)
            }
        })
    }
}
