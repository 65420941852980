import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import Colors from '@/helpers/Colors'
import ModalComponent from './ModalComponent'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import Web3Service from '../../services/Web3Service'
import SessionTransactionService from '../../services/SessionTransactionService'
import { Transaction, TransactionStatus } from '../../models/Transaction'
import { getFormatedTx } from '@/helpers/StringHelper'
import { TransactionNotification } from '../../models/notification/TransactionNotification'
import ScrollableContainer from '@/helpers/ScrollHelper'
import TextButton from '../TextButton'

class SessionTransactionComponent extends PIXI.Container {
    private txTitle: PIXI.BitmapText = new MainBitmapText('', { fontSize: 4 })
    private txSubtitle: PIXI.BitmapText = new MainBitmapText('', { fontSize: 4 })
    private bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private copyTrace = new MainBitmapText('COPY ERROR', { fontSize: 4 })
    private loader: PIXI.AnimatedSprite = new PIXI.AnimatedSprite(
        (PIXI.Loader.shared.resources['loader'] as any).spritesheet!._frameKeys.map((a: string) => {
            return PIXI.Texture.from(a)
        })
    )
    public onClick = () => {
        return
    }
    private hasMouseOver = false

    constructor(public notification: TransactionNotification) {
        super()

        this.x = 1
        this.bg.width = 119
        this.bg.height = 22
        this.bg.tint = Colors.Blue300

        this.bg.interactive = true
        this.bg.cursor = 'pointer'
        this.bg.on('mouseover', () => {
            this.hasMouseOver = true
            this.update()
        })
        this.bg.on('mouseout', () => {
            this.hasMouseOver = false
            this.update()
        })
        this.bg.on('pointertap', () => {
            this.onClick()
        })

        this.copyTrace.y = Math.floor(11 - this.copyTrace.height / 2)
        this.copyTrace.x = this.bg.width - this.copyTrace.width - 6

        this.copyTrace.interactive = true
        this.copyTrace.cursor = 'pointer'
        this.copyTrace.on('pointerdown', () => {
            this.copyTrace.tint = 0xff4444
        })

        this.copyTrace.on('mouseout', () => {
            this.copyTrace.tint = 0xffffff
        })
        this.copyTrace.on('pointerup', () => {
            this.copyTrace.tint = 0xffffff
        })
        this.copyTrace.on('pointertap', () => {
            navigator.clipboard.writeText(notification.transaction.error).then(
                () => {
                    this.copyTrace.text = 'Copied to clipboard!'
                    this.copyTrace.x = this.bg.width - this.copyTrace.width - 6
                    this.copyTrace.cursor = 'default'
                    this.copyTrace.interactive = false

                    setTimeout(() => {
                        this.copyTrace.text = 'COPY ERROR'
                        this.copyTrace.x = this.bg.width - this.copyTrace.width - 6
                        this.copyTrace.interactive = true
                        this.copyTrace.cursor = 'pointer'
                    }, 5000)
                },
                function() {
                    console.log('error')
                }
            )
        })

        this.addChild(this.bg)
        this.addChild(this.txTitle)
        this.addChild(this.txSubtitle)
        this.addChild(this.loader)
        this.addChild(this.copyTrace)

        this.txTitle.x = 6
        this.txTitle.y = 6

        this.txSubtitle.x = 6
        this.txSubtitle.y = this.txTitle.y + 6

        this.loader.animationSpeed = 0.2
        this.loader.play()

        this.update()
    }

    public update() {
        this.txTitle.text = this.notification.title
        this.txSubtitle.text = getFormatedTx(this.notification.transaction)

        this.loader.y = 6 + 1 / 3
        this.loader.x = this.bg.width - this.loader.width - 8

        this.loader.visible = this.notification.transaction.status == TransactionStatus.InProgress

        this.loader.visible = false
        if (this.notification.transaction.status == TransactionStatus.InProgress) {
            this.loader.visible = true
            this.txSubtitle.tint = Colors.Blue600
            this.bg.tint = this.hasMouseOver ? Colors.Blue400 : Colors.Blue300
        } else if (this.notification.transaction.status == TransactionStatus.Success) {
            this.txSubtitle.tint = Colors.Green500
            this.bg.tint = this.hasMouseOver ? Colors.Green400 : Colors.Green300
        } else {
            this.txSubtitle.tint = Colors.Red500
            this.bg.tint = this.hasMouseOver ? Colors.Red400 : Colors.Red300
        }

        this.copyTrace.visible = this.notification.transaction.error.length > 0
    }
}

export default class TransactionsModal extends ModalComponent {
    private scrollContainer: ScrollableContainer = new ScrollableContainer(26, 119)
    private clearButton: TextButton = new TextButton('clear')

    private nbTransactions: MainBitmapText = new MainBitmapText('0 transactions this session', { fontSize: 4 })

    private hasScrolled = false
    private txComponents: SessionTransactionComponent[] = []

    constructor() {
        super(120, 120)
        this.hPosistion = 12

        const titleBottom = PIXI.Sprite.from('modal_bottom_title')
        titleBottom.x = Math.floor((this.modalWidth - titleBottom.width) / 2 - 10)
        titleBottom.y = this.modalHeight - 1

        const titleText = new MainBitmapText('TX', {
            fontSize: 5
        })

        titleText.x = Math.floor((this.modalWidth - titleText.width) / 2 - 10)
        titleText.y = titleBottom.y + 1

        this.nbTransactions.tint = Colors.Blue600
        this.nbTransactions.x = 8
        this.nbTransactions.y = 6

        this.clearButton.container.x = this.modalWidth - this.clearButton.container.width - 8
        this.clearButton.container.y = 5

        this.clearButton.container.on('pointertap', () => {
            this.txComponents = this.txComponents.filter(a => {
                const shouldKeep = a.notification.transaction.status == TransactionStatus.InProgress
                if (!shouldKeep) {
                    a.destroy({ children: true })
                }
                return shouldKeep
            })
            this.checkItemPositions()
            this.updateTitle()
            this.scrollContainer.checkScrollState()
            SessionTransactionService.getInstance().clear()
        })

        this.modalContainer.addChild(this.modalBg)
        this.modalContainer.addChild(this.top)
        this.modalContainer.addChild(this.left)
        this.modalContainer.addChild(this.right)
        this.modalContainer.addChild(this.bottom)
        this.modalContainer.addChild(this.scrollContainer.wrapper)
        this.modalContainer.addChild(this.nbTransactions)
        this.modalContainer.addChild(this.clearButton.container)
        this.modalContainer.addChild(titleBottom)
        this.modalContainer.addChild(titleText)

        this.container.addChild(this.modalContainer)
        this.setPositions()

        SessionTransactionService.getInstance().setOnTxAdded(tx => {
            this.addTx(tx)
        })
        SessionTransactionService.getInstance().setOnTxListChanged(tx => {
            this.txComponents.forEach(it => it.update())
            this.updateTitle()
        })

        this.modalContainer.alpha = 0
        this.bg.alpha = 0
        this.scrollContainer.updateHeight(this.modalHeight - 26)
    }

    public tick(): void {
        // this.bg!.tilePosition.y += 0.1
        this.scrollContainer.tick()
    }

    private addTx(transaction: TransactionNotification) {
        const component = new SessionTransactionComponent(transaction)
        this.txComponents.push(component)
        component.onClick = () => {
            if (!this.scrollContainer.hasMoved) {
                Web3Service.getInstance().openTxScan(component.notification.transaction.hash)
            }
        }

        this.checkItemPositions()

        this.scrollContainer.addChild(component)
        this.scrollContainer.checkScrollState()

        this.updateTitle()
    }

    private updateTitle() {
        const txCount = SessionTransactionService.getInstance().transactions.length
        this.nbTransactions.text = `${txCount} transaction${txCount > 1 ? 's' : ''} this session`
    }

    private checkItemPositions() {
        const a = this.txComponents.map(a => a)
        a.reverse().forEach((it, i) => {
            it.y = it.height * i
        })
    }
}
