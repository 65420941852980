import { Options, Vue } from 'vue-class-component'
import Header from '@/components/header/Header.vue'
import ComethHeader from '@/components/header/Header'
import Connection from '@/components/connection/Connection'
import config from '@/config'
import axios from 'axios'
import Modal from '@/components/modal/Modal.vue'
import * as KioskTokenService from './KioskTokenService'
import { ethers } from 'ethers'
import { useRoute } from 'vue-router'
import mixpanel from 'mixpanel-browser'

@Options({
    components: { Header, Modal }
})
export default class Kiosk extends Vue {
    $refs!: {
        header: ComethHeader
    }

    route = useRoute()
    config = config

    appWidth = 1899
    appHeight = 1068

    connection: Connection = Connection.getInstance()

    isAuth = false
    isOnRequiredNetwork = false

    modal = false

    email = ''
    isCodeValid = true

    code = ''
    address = ''
    loading = false
    redeemError = ''
    serverError = false
    contract = ''
    relayTransactionHash = ''
    transactionHash = ''
    tokenId = ''
    tokenImg = ''
    token: string | string[] = ''

    created(): void {
        window.addEventListener('resize', this.resizeApp)
    }

    async mounted(): Promise<void> {
        this.resizeApp()
        await this.connect()

        this.email = Connection.getInstance().getConnectedEmail() || ''
        mixpanel.init(config.mixpanelProjectToken, { debug: process.env.VUE_APP_DEV === 'true' })

        if (this.route.query.gecko !== undefined) {
            this.token = 'gecko'
        } else {
            this.token = this.route.params.nft
        }
        this.code = this.route.query.code?.toString() ?? ''
    }

    async connect(): Promise<void> {
        this.connection.on('providerChanged', async () => await this.load())
    }

    async load(): Promise<void> {
        this.isAuth = !!this.connection.connectedAddress
        if (!this.isAuth) {
            return
        }
        this.address = this.connection.connectedAddress ?? ''

        const networkId = await this.connection.getWeb3().eth.net.getId()
        this.isOnRequiredNetwork = networkId === config.network

        if (this.isOnRequiredNetwork) {
            //
        } else {
            await (window as any).ethereum.request({
                id: 1,
                jsonrpc: '2.0',
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: '0x' + config.network.toString(16),
                        chainName: config.networkLabel,
                        rpcUrls: [config.rpc],
                        iconUrls: ['https://polygon.technology/wp-content/uploads/2021/01/logo-polygon.png'],
                        nativeCurrency: {
                            name: 'Matic',
                            symbol: 'Matic',
                            decimals: 18
                        },
                        blockExplorerUrls: [config.etherscanUrl]
                    }
                ]
            })
        }
    }

    openConnection(): void {
        this.$refs.header.openModal()
    }

    openModal(): void {
        this.modal = true
        ;(window as any).startConfetti()
    }

    closeModal(): void {
        this.modal = false
        ;(window as any).stopConfetti()
    }

    async redeem() {
        if (this.loading) {
            return
        }

        this.loading = true
        this.transactionHash = ''
        this.relayTransactionHash = ''
        this.redeemError = ''
        this.serverError = false

        const url = 'https://europe-west1-grand-incentive-328715.cloudfunctions.net/nftClaimer'

        axios
            .post(url, { code: this.code, address: this.address })
            .then(async response => {
                this.contract = response.data.contract
                this.tokenId = response.data.tokenId

                this.transactionHash = response.data.txHash
                this.relayTransactionHash = response.data.relayTransactionHash

                if (this.relayTransactionHash) {
                    const receipt: any = await this.waitRelayedTransaction(this.relayTransactionHash)
                    this.transactionHash = receipt.transactionHash
                } else {
                    const itx = new ethers.providers.StaticJsonRpcProvider(config.innerRpc)
                    await itx.waitForTransaction(this.transactionHash)
                }
                this.onRedeemSuccess()
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 500) {
                        this.serverError = true
                    } else {
                        this.redeemError = error.response.data.error
                    }
                }
                this.loading = false
            })
    }

    private wait = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async waitRelayedTransaction(relayTransactionHash: any) {
        let mined = false

        while (!mined) {
            const itx = new ethers.providers.StaticJsonRpcProvider(config.kioskProviderUrl)

            const statusResponse = await itx.send('relay_getTransactionStatus', [relayTransactionHash])

            if (statusResponse.broadcasts) {
                for (let i = 0; i < statusResponse.broadcasts.length; i++) {
                    const bc = statusResponse.broadcasts[i]
                    const receipt = await itx.getTransactionReceipt(bc.ethTxHash)
                    if (receipt && receipt.confirmations && receipt.confirmations > 1) {
                        mined = true
                        return receipt
                    }
                }
            }
            await this.wait(1000)
        }
    }

    async onRedeemSuccess() {
        mixpanel.identify(this.address)
        mixpanel.track('Redeem', {
            nft: this.token
        })

        await this.getTokenImage()
        this.code = ''
        this.loading = false
        this.openModal()
    }

    async getTokenImage() {
        const url = await KioskTokenService.uri(this.contract, this.tokenId)

        axios
            .get(url.replace('{id}', this.tokenId))
            .then(response => {
                this.tokenImg = response.data.image
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 500) {
                        this.serverError = true
                    } else {
                        this.redeemError = error.response.data.error
                    }
                }
            })
    }

    resizeApp(): void {
        const appContainer = document.querySelector('#app-container')

        const currentWidth = window.innerWidth
        const currentHeight = window.innerHeight

        const widthRatio = currentWidth / this.appWidth
        const heightRatio = currentHeight / this.appHeight
        ;(appContainer as HTMLElement).style.transform = `scale(${Math.max(widthRatio, heightRatio)})`
    }
}
