
import { Options, Vue } from "vue-class-component";

@Options({
    props: {
        href: String,
        color: {
            type: String,
            default: "blue"
        }
    }
})
export default class ButtonLink extends Vue {
    href!: string;
    color!: string;
}
