import * as PIXI from 'pixi.js'
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import GCore from '@/game/GCore'
import Resolution from '@/helpers/Resolution'
import Header from '@/components/header/Header.vue'
import HeaderTs from '@/components/header/Header'
import TubeCore from '@/tube/TubeCore'
import Connection, { ConnectionType } from '@/components/connection/Connection'
import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import TubeService from '@/game/services/web3/TubeService'
import SpiceService from '@/game/services/web3/SpiceService'
import BN from 'bn.js'
import PoolPrize from '@/game/models/tube/PoolPrize'
import TubePool from '@/game/models/tube/TubePool'
import TubeLoaderService from '@/game/services/TubeLoaderService'
import Web3 from 'web3'
import DustMigrationService from '@/services/blockchain/DustMigrationService'
import { CurrencyHelper } from '@/helpers/CurrencyHelper'

@Options({
    components: {
        Header
    }
})
export default class Tube extends Vue {
    $refs!: {
        header: HeaderTs
    }
    private core?: TubeCore = undefined

    public loadProgress = 0
    public isLoaded = false
    public clickedPlay = false
    public scale = 1
    public innerWidth = 0
    public innerHeight = 0
    public mustDisplayTubeDetail = false
    public mustDisplayL1Claim = false

    public stepPercent = 80
    public maxLevel = 0

    public connectedAccount = ''
    public networkId = ''
    public claimed: PoolPrize = new PoolPrize()
    public currentPoolPrizes: PoolPrize[][] = []
    public comingSoonPrizes: PoolPrize[] = []
    checkingNetwork = true
    networkError = false
    connectedToL2 = false

    stakeAmount = 0
    unstakeAmount = 0
    isStaking = false
    isApproving = false
    isTubeApproved = false
    isUnstaking = false
    isMigrating = false

    dustMigrationStakeRequieremnt = ''
    dustInMigration = '0'
    l2stakeAmount = ''

    stakedWeiValue = ''
    formattedStakedMust = ''
    dustRate = ''
    nextLevelDate = 'todo'

    maxStake = ''
    rewardRate = ''
    token = ''
    name = ''
    description = ''

    prizeIndexBeingClaimed = -1

    inputStakingError = ''
    inputUnstakingError = ''

    isLoadingData = false

    mustBalanceWei = ''
    mustBalance = '0'

    get isLoading() {
        return this.checkingNetwork
    }

    async created() {
        this.rescaleUI()
        this.isLoadingData = true
        const connection = Connection.getInstance()
        await connection.load()
        Connection.getInstance().on('providerChanged', async provider => {
            if (!provider) {
                this.checkingNetwork = true
                return
            }
            await this.initWeb3()
        })
        await this.initWeb3()

        document.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key == 'Escape') {
                this.hideTubeDetail()
            }
        })
    }

    destroyed() {
        console.log('DESTROYED')
    }

    async initWeb3() {
        const connection = Connection.getInstance()
        if (!connection.isConnected()) {
            this.$refs.header.openModal()
            return
        }

        const provider = await Connection.getInstance().provider

        await Promise.all([this.loadConnectedAccount(), this.loadNetworkId(), this.loadDustMigration()])

        this.subscribeToProviderEvents(provider)
    }

    async loadDustMigration() {
        const account: string = await Web3Service.getInstance().getAccount()
        this.dustInMigration = await DustMigrationService.getInstance().balanceOf(account)
        if (this.dustInMigration != '0') {
            this.dustMigrationStakeRequieremnt = await DustMigrationService.getInstance().requirementOf(account)
            this.l2stakeAmount = await TubeService.getInstance().balanceOfAccount(config.spiceAddress)
        }
    }

    async loadConnectedAccount() {
        this.connectedAccount = await Web3Service.getInstance().getAccount()
    }

    async loadNetworkId() {
        this.checkingNetwork = true

        if (Connection.getInstance().getConnectionType() == ConnectionType.Metamask) {
            try {
                await (window as any).ethereum.request({
                    id: 1,
                    jsonrpc: '2.0',
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x' + config.network.toString(16),
                            chainName: config.l2NetworkLabel,
                            rpcUrls: [config.rpc],
                            iconUrls: ['https://polygon.technology/wp-content/uploads/2021/01/logo-polygon.png'],
                            nativeCurrency: {
                                name: 'Matic',
                                symbol: 'Matic',
                                decimals: 18
                            },
                            blockExplorerUrls: [config.etherscanUrl]
                        }
                    ]
                })
            } catch (e) {
                // metamask is probably outdated here
                console.log(e)
            }
        }

        this.networkId = await Web3Service.getInstance()
            .getWeb3()
            .eth.net.getId()
        this.connectedToL2 = this.networkId === config.network

        if (this.connectedToL2) {
            this.checkingNetwork = false
        } else {
            this.networkError = true
        }
    }

    private subscribeToProviderEvents(provider: any) {
        provider.on('chainChanged', () => {
            this.loadNetworkId()
        })

        provider.on('accountsChanged', () => {
            this.loadConnectedAccount()
        })
    }

    @Watch('connectedAccount')
    @Watch('checkingNetwork')
    init() {
        if (this.checkingNetwork) return
        if (!this.connectedToL2) return

        PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST
        window.PIXI = PIXI as any
        TubeLoaderService.getInstance().loader.onProgress.add(progress => {
            this.loadProgress = progress.progress
        })
        TubeLoaderService.getInstance().loader.onComplete.add(() => {
            this.setReady()
        })

        try {
            TubeLoaderService.getInstance().load()
        } catch (err) {
            this.setReady()
        }
    }

    public nextTube() {
        this.core?.bindToNextTube()
    }

    public prevTube() {
        this.core?.bindToPrevTube()
    }

    public resetTubeInput() {
        this.stakeAmount = 0
        this.unstakeAmount = 0
    }

    private setReady() {
        this.declareFonts()
        this.core = new TubeCore()
        setTimeout(() => {
            this.isLoaded = true
        }, 100)
        this.rescaleUI()
        window.onresize = () => {
            this.rescaleUI()
        }
        this.core.onClickTube = () => {
            this.mustDisplayTubeDetail = true
            this.checkTubeAllowance()
            this.refreshTubeData()
            this.currentPoolPrizes = this.getGroupedPoolPrizes()
            this.comingSoonPrizes = this.getComingSoonPrizes()
            this.resetTubeInput()
        }
        this.core.onLoadingChange = (change: boolean) => {
            this.isLoadingData = change
        }

        this.isLoadingData = false

        if (this.$route.query.pool) {
            const foundTubePool = this.core.tubes.find(it => it.id == (this.$route.query.pool as any))
            if (foundTubePool) {
                setTimeout(() => {
                    this.core!.bindToTube(foundTubePool)
                }, 1000)
            }
        }
    }

    private rescaleUI() {
        this.scale = window.innerHeight / 1124
        this.innerWidth = window.innerWidth
        this.innerHeight = window.innerHeight
        this.core?.onResize(this.scale)
    }

    private declareFonts() {
        const options = {
            chars: [
                ['a', 'z'],
                ['A', 'Z'],
                ['0', '9'],
                `:-_!@#$%^&*()~{}[].[]{}()<>*+-=!?^$|/•àâªæáäãåāéèêëęėēîïìíįīôœºöòóõøōûùüúūÿçÀÂªÆÁÄÃÅĀÉÈÈÊËĘĖĒÎÏÌÍĮĪÔŒºÖÒÓÕØÛÙÜÚŪŸ', `
            ] as any,
            resolution: 2
        }

        const fontSizes = [3, 4, 5, 6, 7, 8]
        const resolutions = Resolution.availableScales
        resolutions.forEach(a => {
            fontSizes.forEach(b => {
                PIXI.BitmapFont.from(
                    `main_${a}_${b}`,
                    {
                        fontFamily: 'MainFont',
                        fill: '#FFFFFF',
                        fontSize: a * b
                    },
                    options
                )
            })
        })
    }

    public relativePercLevel(level: number) {
        let prevStep = new BN(0)

        if (level > 0) {
            prevStep = this.core!.currentPool!.prizes[level - 1].points
        }
        let currStep = this.core!.currentPool!.prizes[level].points

        if (prevStep.eq(currStep)) {
            return 100
        }

        currStep = currStep.sub(
            currStep
                .sub(prevStep)
                .muln(10)
                .divn(100)
        )
        let earned = this.currentEarning().sub(prevStep)

        if (earned.isNeg()) {
            earned = new BN(0)
        }

        const maxPrice = currStep.sub(prevStep)
        const updated = maxPrice.isZero() ? new BN(0) : earned.muln(100).div(maxPrice)

        const perc = Math.max(0, Math.min(100, updated.toNumber()))

        return perc
    }

    public relativePercLevelForStepWrapper(level: number) {
        let prevStep = new BN(0)

        if (level > 0) {
            prevStep = this.core!.currentPool!.prizes[level - 1].points
        }

        const currStep = this.core!.currentPool!.prizes[level].points

        if (prevStep.eq(currStep)) {
            return 100
        }
        prevStep = prevStep.add(
            currStep
                .sub(prevStep)
                .muln(90)
                .divn(100)
        )
        let earned = this.currentEarning().sub(prevStep)

        if (earned.isNeg()) {
            earned = new BN(0)
        }

        const maxPrice = currStep.sub(prevStep)
        const updated = maxPrice.isZero() ? new BN(0) : earned.muln(100).div(maxPrice)

        const perc = Math.max(0, Math.min(100, updated.toNumber()))

        return perc
    }

    public isStarted(): boolean {
        if (!this.core?.currentPool) {
            return false
        }
        return this.core!.currentPool!.isStarted()
    }
    public getFormattedStartDate() {
        const startDate = new Date(this.core!.currentPool!.periodStart * 1000)
        const options: Intl.DateTimeFormatOptions = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }
        return new Intl.DateTimeFormat('en-US', options).format(startDate)
    }

    public isLevelUnlocked(level: number) {
        const currStep = this.core!.currentPool!.prizes[level].points
        const earned = this.currentEarning()
        return earned.gte(currStep)
    }

    public currentEarning() {
        return this.core?.bindedEarned ? this.core?.bindedEarned : new BN(0)
    }

    public hideTubeDetail() {
        this.mustDisplayTubeDetail = false
        this.core?.unlockTubeBinding()
    }

    public copyTubeUrl() {
        const text = window.location.origin + '/tube?pool=' + this.core?.currentPool?.id
        navigator.clipboard.writeText(text).then(
            () => {
                this.$toasts.success('Tube Link copied to clipboard.', {})
            },
            err => {
                this.$toasts.error('Tube Link copy failed.', {})
            }
        )
    }

    public async checkTubeAllowance() {
        const account = await Web3Service.getInstance().getAccount()
        const allowance = new BN(await SpiceService.getInstance().getAllowance(account, config.tubeAddress))
        this.isTubeApproved = allowance.gt(new BN('0'))
    }

    public async approveTube() {
        this.isApproving = true
        await SpiceService.getInstance().approveSync(config.tubeAddress, 10000000)
        this.isApproving = false
        this.isTubeApproved = true
    }

    public maxUnstake() {
        this.unstakeAmount = Number(CurrencyHelper.weiToEthFull(this.stakedWeiValue.toString()))
    }

    public maxToStake() {
        this.stakeAmount = Number(CurrencyHelper.weiToEthFull(this.mustBalanceWei))
    }

    public copyToMigrateAmount() {
        const formatedToStakeAmount = CurrencyHelper.weiToEthFull(this.dustMigrationStakeRequieremnt.toString()).toString()
        navigator.clipboard.writeText(formatedToStakeAmount).then(
            () => {
                this.$toasts.success('Amount copied to clipboard.', {})
            },
            err => {
                this.$toasts.error('Amountcopy failed.', {})
            }
        )
    }

    public async stake() {
        if (!this.core?.currentPool) return

        this.inputStakingError = ''

        const toStake = new BN(Web3.utils.toWei(this.stakeAmount.toString(), 'ether'))

        if (toStake.gt(new BN(this.mustBalanceWei))) {
            this.inputStakingError = 'The number exceeds the maximum amount'
            return
        }

        this.isStaking = true
        try {
            await TubeService.getInstance().stake(this.core.currentPool.id, toStake.toString())
            this.$toasts.success('Staking succeeded', {})
        } catch (e) {
            this.$toasts.error('Staking failed.', {})
        }
        this.isStaking = false
        this.stakeAmount = 0
        this.refreshTubeData()
    }

    public async unstake() {
        if (!this.core?.currentPool) return

        this.inputUnstakingError = ''
        const toUnstake = new BN(Web3.utils.toWei(this.unstakeAmount.toString(), 'ether'))

        if (toUnstake.gt(new BN(this.stakedWeiValue))) {
            this.inputUnstakingError = 'The number exceeds the staked amount'
            return
        }

        this.isUnstaking = true
        try {
            await TubeService.getInstance().withdraw(this.core.currentPool.id, toUnstake.toString())
            this.$toasts.success('Unstaking succeeded.', {})
        } catch (e) {
            this.$toasts.error('Unstaking failed.', {})
        }
        this.isUnstaking = false
        this.unstakeAmount = 0
        this.refreshTubeData()
    }

    public async refreshTubeData() {
        if (!this.core?.currentPool) return

        this.stakedWeiValue = await TubeService.getInstance().balanceOf(this.core.currentPool.id)
        this.formattedStakedMust = CurrencyHelper.weiToEthRounded(this.stakedWeiValue).toString()
        this.dustRate = this.core.currentPool.getDustPerDayRateFromTokenAmount(Number(this.formattedStakedMust))
        this.maxStake = this.core.currentPool.maxStake.toString()
        this.rewardRate = this.core.currentPool.getDustPerDayRateFromTokenAmount(1)
        this.token = this.core.currentPool.tokenSymbol
        this.name = this.core.currentPool.name

        this.mustBalanceWei = await SpiceService.getInstance().getBalance(await Web3Service.getInstance().getAccount())
        this.mustBalance = CurrencyHelper.weiToEthRounded(this.mustBalanceWei).toString()
        this.core.refreshTubeComponentData()
    }

    public openL1Claim() {
        this.mustDisplayL1Claim = true
    }

    public hasDustToMigrate() {
        return this.dustInMigration != '0'
    }

    public hasStakedRequierement() {
        return new BN(this.l2stakeAmount).gte(new BN(this.dustMigrationStakeRequieremnt))
    }

    public async claim(prizeIndex: any, prize: PoolPrize) {
        if (!this.core?.currentPool) return

        this.prizeIndexBeingClaimed = prizeIndex
        try {
            await TubeService.getInstance().redeem(this.core.currentPool.id, prize.id)

            if (prize.fixedSupply) {
                const newSupply = await this.core.currentPool.getPrizeSupply(prize.id)
                this.core.currentPool.prizes[prizeIndex].supply = newSupply
            }

            const w = window as any
            w.startConfetti()
            this.claimed = prize
            await this.refreshTubeData()
        } catch (e) {
            this.$toasts.error('Claiming failed.', {})
        }

        this.prizeIndexBeingClaimed = -1
    }

    public async migrate() {
        this.isMigrating = true
        await DustMigrationService.getInstance().redeem()
        this.dustInMigration = '0'
        this.isMigrating = false
        this.core?.refreshTubeComponentData()
    }

    public formatedToken(amount: string) {
        return Math.floor(parseFloat(Web3.utils.fromWei(amount)) * 1000) / 1000
    }

    public getGroupedPoolPrizes() {
        return this.core?.currentPool?.getGroupedPoolPrize() ? this.core.currentPool?.getGroupedPoolPrize() : []
    }

    public getComingSoonPrizes() {
        return []
    }

    public resetClaimed() {
        this.claimed = new PoolPrize()
        const w = window as any
        w.stopConfetti()
    }
}
