import config from '@/config'
import { Notification } from '@/game/models/notification/Notification'
import { PortalTravelNotification } from '@/game/models/notification/PortalTravelNotification'
import { Transaction } from '@/game/models/Transaction'
import Web3Service from '@/game/services/Web3Service'
import NotificationService from '../NotificationService'

export default class PortalService {
    public static instance: PortalService
    public static TRAVELED_EVENT = 'TRAVELED_EVENT'

    public static getInstance(): PortalService {
        if (!this.instance) {
            this.instance = new PortalService()
        }
        return this.instance
    }

    private abi = [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'pStore',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'ssStore',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'mManager',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'gtw',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'fromSystemID',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'toSystemID',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'fees',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint32',
                    name: 'newDistance',
                    type: 'uint32'
                },
                {
                    indexed: false,
                    internalType: 'int128',
                    name: 'newAngle',
                    type: 'int128'
                },
                {
                    indexed: false,
                    internalType: 'int32',
                    name: 'rotationSpeed',
                    type: 'int32'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'updateTime',
                    type: 'uint256'
                }
            ],
            name: 'Traveled',
            type: 'event'
        },
        {
            inputs: [],
            name: 'defaultRotationSpeed',
            outputs: [
                {
                    internalType: 'int32',
                    name: '',
                    type: 'int32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'feeReceiver',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'gateway',
            outputs: [
                {
                    internalType: 'contract IERC721',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'module',
                    type: 'address'
                }
            ],
            name: 'isModule',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'modules',
            outputs: [
                {
                    internalType: 'address[]',
                    name: '',
                    type: 'address[]'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'mustManager',
            outputs: [
                {
                    internalType: 'contract MustManager',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'portalStore',
            outputs: [
                {
                    internalType: 'contract IPortalStore',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'portalTravelArea',
            outputs: [
                {
                    internalType: 'uint8',
                    name: '',
                    type: 'uint8'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'solarSystemStore',
            outputs: [
                {
                    internalType: 'contract ISolarSystemStore',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newModule',
                    type: 'address'
                }
            ],
            name: 'updateModule',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address[]',
                    name: 'newModules',
                    type: 'address[]'
                }
            ],
            name: 'updateModules',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updateSolarSystemStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updatePortalStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'int32',
                    name: 'speed',
                    type: 'int32'
                }
            ],
            name: 'updateDefaultRotationSpeed',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint8',
                    name: 'area',
                    type: 'uint8'
                }
            ],
            name: 'updatePortalTravelArea',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newMustManager',
                    type: 'address'
                }
            ],
            name: 'updateMustManager',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newGateway',
                    type: 'address'
                }
            ],
            name: 'updateGateway',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newFeeReceiver',
                    type: 'address'
                }
            ],
            name: 'updateFeeReceiver',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'portalId',
                    type: 'uint256'
                }
            ],
            name: 'removePortal',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'portalId',
                    type: 'uint256'
                }
            ],
            name: 'getPortal',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'system1Id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'system2Id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'fees',
                            type: 'uint256'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'system1Orbit',
                            type: 'tuple'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'system2Orbit',
                            type: 'tuple'
                        }
                    ],
                    internalType: 'struct Portal',
                    name: '',
                    type: 'tuple'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'getPortals',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'system1Id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'system2Id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'fees',
                            type: 'uint256'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'system1Orbit',
                            type: 'tuple'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'system2Orbit',
                            type: 'tuple'
                        }
                    ],
                    internalType: 'struct Portal[]',
                    name: '',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                }
            ],
            name: 'getMinerLastDestination',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256[]',
                    name: 'minerIds',
                    type: 'uint256[]'
                }
            ],
            name: 'getMinersLastDestination',
            outputs: [
                {
                    internalType: 'uint256[]',
                    name: '',
                    type: 'uint256[]'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'system1Id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'system2Id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'fees',
                    type: 'uint256'
                },
                {
                    internalType: 'uint32',
                    name: 'system1DistanceToCenter',
                    type: 'uint32'
                },
                {
                    internalType: 'uint32',
                    name: 'system2DistanceToCenter',
                    type: 'uint32'
                },
                {
                    internalType: 'int128',
                    name: 'system1Angle',
                    type: 'int128'
                },
                {
                    internalType: 'int128',
                    name: 'system2Angle',
                    type: 'int128'
                },
                {
                    internalType: 'int32',
                    name: 'rotationSpeed',
                    type: 'int32'
                }
            ],
            name: 'addPortal',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'portalId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'system1Id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'system2Id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'fees',
                    type: 'uint256'
                },
                {
                    internalType: 'uint32',
                    name: 'system1DistanceToCenter',
                    type: 'uint32'
                },
                {
                    internalType: 'uint32',
                    name: 'system2DistanceToCenter',
                    type: 'uint32'
                },
                {
                    internalType: 'int128',
                    name: 'system1Angle',
                    type: 'int128'
                },
                {
                    internalType: 'int128',
                    name: 'system2Angle',
                    type: 'int128'
                },
                {
                    internalType: 'int32',
                    name: 'rotationSpeed',
                    type: 'int32'
                }
            ],
            name: 'updatePortal',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'portalId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'fromSystemId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'toSystemId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'time',
                    type: 'uint256'
                }
            ],
            name: 'travel',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    private readPortalManager: any
    private writePortalManager: any

    constructor() {
        this.initContract()
        this.listenTraveled()
    }

    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        this.writePortalManager = new web3.eth.Contract(this.abi, config.portalManagerAddress, { gasLimit: '1000000' })

        const innerWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readPortalManager = new innerWeb3.eth.Contract(this.abi, config.portalManagerAddress)
    }

    public async getPortals() {
        return await this.readPortalManager.methods.getPortals().call()
    }

    public async listenTraveled() {
        await this.readPortalManager.events
            .Traveled()
            .on('data', function(event: any) {
                document.dispatchEvent(
                    new CustomEvent(PortalService.TRAVELED_EVENT, {
                        detail: event.returnValues
                    })
                )
            })
            .on('error', function(error: any) {
                console.log('ERROR: listenPullEvent', error)
            })
    }

    public async getMinersLastDestination(minerIds: string[]) {
        return await this.readPortalManager.methods.getMinersLastDestination(minerIds).call()
    }

    public async travel(minerId: string, portalId: number, from: number, to: number, time: number) {
        const tx = new Transaction()
        const account: string = await Web3Service.getInstance().getAccount()

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.writePortalManager.methods
                .travel(minerId, portalId, from, to, time)
                .send({ from: account, gasPrice: '50000000000' })
                .on('error', (error: any) => {
                    this.writePortalManager.methods
                        .travel(minerId, portalId, from, to, time)
                        .call({ from: account })
                        .catch((err: any) => {
                            reject(err)
                            tx.error = err.message
                            console.log(err.message)
                            tx.onError()
                            reject(err)
                            notif.title = 'Travel failed.'
                        })
                })
                .on('receipt', (data: any) => {
                    notif.title = 'Travel succeed'
                    document.dispatchEvent(
                        new CustomEvent(PortalService.TRAVELED_EVENT, {
                            detail: { minerId: minerId, fromSystemID: from, toSystemID: to }
                        })
                    )
                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif.id = PortalTravelNotification.getNotifIdFor(minerId)
                    notif = NotificationService.getInstance().addFromTransaction(tx)
                    notif.title = 'Travel in progress'
                    resolve(data)
                })
        })
    }
}
