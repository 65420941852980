import config from '@/config'
import { AbiItem } from 'web3-utils'
import Connection from '@/components/connection/Connection'

const abi: AbiItem[] = [
    { inputs: [{ internalType: 'string', name: 'uri_', type: 'string' }], stateMutability: 'nonpayable', type: 'constructor' },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: true, internalType: 'address', name: 'operator', type: 'address' },
            { indexed: false, internalType: 'bool', name: 'approved', type: 'bool' }
        ],
        name: 'ApprovalForAll',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'address', name: 'userAddress', type: 'address' },
            { indexed: false, internalType: 'address payable', name: 'relayerAddress', type: 'address' },
            { indexed: false, internalType: 'bytes', name: 'functionSignature', type: 'bytes' }
        ],
        name: 'MetaTransactionExecuted',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'operator', type: 'address' },
            { indexed: true, internalType: 'address', name: 'from', type: 'address' },
            { indexed: true, internalType: 'address', name: 'to', type: 'address' },
            { indexed: false, internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
            { indexed: false, internalType: 'uint256[]', name: 'values', type: 'uint256[]' }
        ],
        name: 'TransferBatch',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'operator', type: 'address' },
            { indexed: true, internalType: 'address', name: 'from', type: 'address' },
            { indexed: true, internalType: 'address', name: 'to', type: 'address' },
            { indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' },
            { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
        ],
        name: 'TransferSingle',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'string', name: 'value', type: 'string' },
            { indexed: true, internalType: 'uint256', name: 'id', type: 'uint256' }
        ],
        name: 'URI',
        type: 'event'
    },
    {
        inputs: [],
        name: 'ERC712_VERSION',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'uint256', name: 'id', type: 'uint256' }
        ],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address[]', name: 'accounts', type: 'address[]' },
            { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }
        ],
        name: 'balanceOfBatch',
        outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'userAddress', type: 'address' },
            { internalType: 'bytes', name: 'functionSignature', type: 'bytes' },
            { internalType: 'bytes32', name: 'sigR', type: 'bytes32' },
            { internalType: 'bytes32', name: 'sigS', type: 'bytes32' },
            { internalType: 'uint8', name: 'sigV', type: 'uint8' }
        ],
        name: 'executeMetaTransaction',
        outputs: [{ internalType: 'bytes', name: '', type: 'bytes' }],
        stateMutability: 'payable',
        type: 'function'
    },
    { inputs: [], name: 'getChainId', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'pure', type: 'function' },
    {
        inputs: [],
        name: 'getDomainSeperator',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
        name: 'getNonce',
        outputs: [{ internalType: 'uint256', name: 'nonce', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'address', name: 'operator', type: 'address' }
        ],
        name: 'isApprovedForAll',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
            { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
            { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        name: 'safeBatchTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'id', type: 'uint256' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' }
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'operator', type: 'address' },
            { internalType: 'bool', name: 'approved', type: 'bool' }
        ],
        name: 'setApprovalForAll',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
        name: 'supportsInterface',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'uri',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'uint256', name: 'id', type: 'uint256' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' }
        ],
        name: 'mint',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    }
]

const ticketIds = config.tickets.map(ticket => ticket.ticketId)

const modelByTicketId = new Map(config.tickets.map(ticket => [ticket.ticketId, ticket.model]))

function contract() {
    const web3 = Connection.getInstance().getWeb3()
    return new web3.eth.Contract(abi, '0xe7a68d8924ace3cef938aa729a0cab5e4af62ac7')
    // return new web3.eth.Contract(abi, config.ticketsAddress, {
    //     from: Connection.getInstance().connectedAddress
    // })
}

export async function balanceOf(address: string, id: string) {
    return contract()
        .methods.balanceOf(address, id)
        .call()
}

export async function uri(contractAddress: string, id: string) {
    const web3 = Connection.getInstance().getWeb3()
    const contract = new web3.eth.Contract(abi, contractAddress)
    return contract.methods.uri(id).call()
}

// export async function tokensOf(account: string): Promise<{ ticketId: string; model: string; balance: number; uri: string }[]> {
//     const balances = ticketIds.map(async (ticketId: string) => ({
//         ticketId,
//         model: modelByTicketId.get(ticketId) as string,
//         balance: Number(await balanceOf(account, ticketId.toString())),
//         uri: await uri(ticketId)
//     }))
//     return Promise.all(balances)
// }

export async function safeTransferFrom(from: string, to: string, id: string, amount: number) {
    return await contract()
        .methods.safeBatchTransferFrom(from, to, [id], [amount], '0x')
        .send()
}

// export async function countOf(account: string): Promise<number> {
//     const tokens = await tokensOf(account)
//     return tokens.reduce((balance, token) => balance + token.balance, 0)
// }
