import { Portal } from '@/game/models/Portal'
import { Star } from '@/game/models/Star'
import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import MapService from '@/game/services/MapService'
import Colors from '@/helpers/Colors'
import Resolution from '@/helpers/Resolution'
import GalaxyService from '@/services/GalaxyService'
import { OutlineFilter } from '@pixi/filter-outline'
import * as PIXI from 'pixi.js'
import Web3 from 'web3'
import CupPreviewComponent from '../CupPreviewComponent'

export default class PortalPreviewComponent extends PIXI.Container {
    public starFrom!: Star
    public starTo!: Star

    private cupPreview?: CupPreviewComponent

    constructor(public portal: Portal) {
        super()

        this.starFrom = MapService.getInstance().map.stars.filter(a => {
            return a.id == portal.fromId
        })[0]
        this.starTo = MapService.getInstance().map.stars.filter(a => {
            return a.id == portal.toId
        })[0]

        const bg = PIXI.Sprite.from('bg_portal_info')
        const star1 = PIXI.Sprite.from(this.starFrom.id + '_star')
        const star2 = PIXI.Sprite.from(this.starTo.id + '_star')
        star2.x = 93

        const sun1Title = new MainBitmapText(this.starFrom.name, { fontSize: 4 })
        sun1Title.x = 37
        sun1Title.y = 3

        const sun2Title = new MainBitmapText(this.starTo.name, { fontSize: 4 })
        sun2Title.x = 91 - sun2Title.width
        sun2Title.y = 12

        const cup = PIXI.Sprite.from('ic_portal_cup')
        cup.y = 26
        cup.visible = false
        if (GalaxyService.getInstance().isStadium(this.portal.fromId)) {
            cup.x = -3
            cup.visible = true
        } else if (GalaxyService.getInstance().isStadium(this.portal.toId)) {
            cup.x = bg.width - 13
            cup.visible = true
        }

        const playerCountTitle = new MainBitmapText('Players', { fontSize: 4 })
        playerCountTitle.x = 90 - playerCountTitle.width
        playerCountTitle.y = sun2Title.y + sun2Title.height + 1
        playerCountTitle.tint = Colors.Blue800
        const playerCount = new MainBitmapText(this.starTo.miners.length + '', { fontSize: 4 })
        playerCount.x = playerCountTitle.x - playerCount.width - 2
        playerCount.y = playerCountTitle.y

        const cometsCountTitle = new MainBitmapText('Comets', { fontSize: 4 })
        cometsCountTitle.x = 91 - cometsCountTitle.width
        cometsCountTitle.y = playerCountTitle.y + playerCountTitle.height + 1
        cometsCountTitle.tint = Colors.Blue800
        const cometsCount = new MainBitmapText(this.starTo.cometes.length + '', { fontSize: 4 })
        cometsCount.x = cometsCountTitle.x - cometsCount.width - 2
        cometsCount.y = cometsCountTitle.y

        const travelPrice = new MainBitmapText(Web3.utils.fromWei(this.portal.fees, 'ether') + ' MUST', { fontSize: 4 })
        travelPrice.x = 94 - travelPrice.width
        travelPrice.y = bg.height - 8
        const travelPriceTitle = new MainBitmapText('Travel $', { fontSize: 4 })
        travelPriceTitle.x = travelPrice.x - travelPriceTitle.width - 2
        travelPriceTitle.y = travelPrice.y
        travelPriceTitle.tint = Colors.Blue800

        const infoText = new MainBitmapText('Select ships in the area to initiate travel', { fontSize: 4 })
        infoText.x = Math.floor(bg.width / 2 - infoText.width / 2)
        infoText.y = bg.height + 2
        infoText.filters = [new OutlineFilter(Resolution.scale, Colors.Blue300) as any]

        this.addChild(bg)
        this.addChild(star1)
        this.addChild(star2)
        this.addChild(cup)
        this.addChild(sun1Title)
        this.addChild(sun2Title)
        this.addChild(playerCountTitle)
        this.addChild(playerCount)
        this.addChild(cometsCountTitle)
        this.addChild(cometsCount)
        this.addChild(travelPriceTitle)
        this.addChild(travelPrice)
        this.addChild(infoText)

        const currentCup = GalaxyService.getInstance().getCurrentCup(this.portal.toId)
        if (currentCup) {
            this.cupPreview = new CupPreviewComponent(currentCup)
            this.cupPreview.y = bg.height + 10
            this.cupPreview.x = Math.floor(bg.width / 2 - this.cupPreview.width / 2)
            this.addChild(this.cupPreview)
        }
    }
}
