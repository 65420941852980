import GalaxyService from '@/services/GalaxyService'
import { BigNumber } from 'bignumber.js'
import BN from 'bn.js'
import TheGraphService from './TheGraphService'
import Web3Service from './Web3Service'

export default class RankingSessionService {
    static ACADEMY_START_BLOCK = 17063000
    static ACADEMY_SESSION_DURATION = 6000
    static AVERAGE_TIME_BY_BLOCK = 2.4
    public static instance: RankingSessionService

    public static getInstance(): RankingSessionService {
        if (!this.instance) {
            this.instance = new RankingSessionService()
        }

        return this.instance
    }

    public startBlock = 10
    public sessionDuration = 10
    public isCup = false

    public updateForSystemId(solarSystemID: number) {
        const currentCup = GalaxyService.getInstance().getCurrentCup(solarSystemID)
        if (GalaxyService.getInstance().isAcademy(solarSystemID)) {
            this.startBlock = RankingSessionService.ACADEMY_START_BLOCK
            this.sessionDuration = RankingSessionService.ACADEMY_SESSION_DURATION
            this.isCup = false
        } else if (currentCup) {
            this.startBlock = currentCup.startBlock
            this.sessionDuration = currentCup.sessionDuration
            this.isCup = true
        }
        console.log('Update system', this.startBlock, this.sessionDuration)
    }

    public startBlockFor(session: number) {
        return this.startBlock + session * this.sessionDuration
    }

    public endBlockFor(session: number) {
        return this.startBlockFor(session) + this.sessionDuration
    }

    public async loadSession(solarSystemID: number, startBlock: number, endBlock: number | null) {
        const [start, end] = await TheGraphService.getInstance().getLeaderboard(solarSystemID, startBlock, endBlock)

        const startMap = new Map(start.map((ship: any) => [ship.id, ship]))

        const diff = end.map((ship: any) => {
            const startValueUSD = startMap.has(ship.id) ? (startMap.get(ship.id) as any).totalRewardsUSD : '0'
            const startValue = startMap.has(ship.id) ? (startMap.get(ship.id) as any).minesCount : 0

            let scoreValue

            if (this.isCup) {
                scoreValue = parseFloat(new BigNumber(ship.totalRewardsUSD).minus(new BigNumber(startValueUSD)).toPrecision(5, 0))
            } else {
                scoreValue = ship.minesCount - startValue
            }

            return {
                ...ship,
                score: scoreValue
            }
        })

        return diff.sort((a: any, b: any) => b.score - a.score)
    }

    public async remainingBlock() {
        const lastSession = await this.lastSession()
        const endBlock = this.endBlockFor(lastSession)

        const block = await Web3Service.getInstance()
            .getWssWeb3()
            .eth.getBlockNumber()

        return new BN(endBlock).sub(new BN(block)).toNumber()
    }
    public async lastSession() {
        const block = await Web3Service.getInstance()
            .getWssWeb3()
            .eth.getBlockNumber()
        return Number(Math.floor((block - this.startBlock) / this.sessionDuration))
    }
}
