import config from '@/config'
import Web3Service from '@/game/services/Web3Service'

export default class ComethMinerGameService {
    public static instance: ComethMinerGameService

    public static getInstance(): ComethMinerGameService {
        if (!this.instance) {
            this.instance = new ComethMinerGameService()
        }
        return this.instance
    }

    private gameAbi = [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newMustManager',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'newSolarSystemStore',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'newGateway',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'newPortalStore',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'Paused',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'model',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'RedirectShips',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'Unpaused',
            type: 'event'
        },
        {
            inputs: [],
            name: 'defaultPullingPrice',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'defaultPullingPriceBySolarSystem',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'defaultRotationSpeed',
            outputs: [
                {
                    internalType: 'int32',
                    name: '',
                    type: 'int32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'feeReceiver',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'gateway',
            outputs: [
                {
                    internalType: 'contract IERC721',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'leaveFee',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'leaveFeeBySolarSystem',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'miners',
            outputs: [
                {
                    internalType: 'contract IMinerStoreV2',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'mustManager',
            outputs: [
                {
                    internalType: 'contract MustManager',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'paused',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'portalStore',
            outputs: [
                {
                    internalType: 'contract IPortalStore',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'rotationSpeedBySolarSystem',
            outputs: [
                {
                    internalType: 'int32',
                    name: '',
                    type: 'int32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'solarSystemByModel',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'solarSystemStore',
            outputs: [
                {
                    internalType: 'contract ISolarSystemStore',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updateSolarSystemStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newGateway',
                    type: 'address'
                }
            ],
            name: 'updateGateway',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newMustManager',
                    type: 'address'
                }
            ],
            name: 'updateMustManager',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updatePortalStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'newFee',
                    type: 'uint256'
                }
            ],
            name: 'updateLeaveFee',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newFeeReceiver',
                    type: 'address'
                }
            ],
            name: 'updateFeeReceiver',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'newPrice',
                    type: 'uint256'
                }
            ],
            name: 'updateDefaultPullingPrice',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'int32',
                    name: 'speed',
                    type: 'int32'
                }
            ],
            name: 'updateDefaultRotationSpeed',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'model',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'updateSolarSystemForModel',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'newDefault',
                    type: 'uint256'
                }
            ],
            name: 'updateSolarSystemPullingPrice',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'newDefault',
                    type: 'uint256'
                }
            ],
            name: 'updateSolarSystemLeaveFee',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                },
                {
                    internalType: 'int32',
                    name: 'newDefault',
                    type: 'int32'
                }
            ],
            name: 'updateSolarSystemRotationSpeed',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                }
            ],
            name: 'enter',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                }
            ],
            name: 'leave',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    private game: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.game = new wssWeb3.eth.Contract(this.gameAbi, config.gameMinerAddress)
    }

    public async leaveFee(solarSystemID: number): Promise<string> {
        const fee = await this.game.methods.leaveFeeBySolarSystem(solarSystemID).call()

        if (fee != '0') {
            return fee
        }

        return await this.game.methods.leaveFee().call()
    }
}
