import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import NotificationService from '../NotificationService'
import { Transaction } from '@/game/models/Transaction'
import PlayerService from '../PlayerService'
import { Notification } from '@/game/models/notification/Notification'
import { GasStationService } from '@/game/services/GasStationService'
import { Web3Helper } from '@/helpers/Web3Helper'

export default class MiningManagerService {
    public static MINED_EVENT = 'mined'
    public static UPDATE_COMET = 'updateComet'

    public static instance: MiningManagerService

    public static getInstance(): MiningManagerService {
        if (!this.instance) {
            this.instance = new MiningManagerService()
        }
        return this.instance
    }

    private abi = [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'cometToken',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'time',
                    type: 'uint256'
                }
            ],
            name: 'Mined',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'timestamp',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'mine',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'time',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'canMine',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newCometManager',
                    type: 'address'
                }
            ],
            name: 'updateCometManager',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updateSolarSystemStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newGateway',
                    type: 'address'
                }
            ],
            name: 'updateGateway',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'newDeltaMiningTime',
                    type: 'uint256'
                }
            ],
            name: 'updateDeltaMiningTime',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint32',
                    name: 'distance',
                    type: 'uint32'
                }
            ],
            name: 'updateNoMiningZone',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint16',
                    name: 'newMax',
                    type: 'uint16'
                }
            ],
            name: 'updateMaxMiningPercentCut',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint16',
                    name: 'newFrameSize',
                    type: 'uint16'
                }
            ],
            name: 'updateTimeFrameSize',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'timestamp',
                    type: 'uint256'
                }
            ],
            name: 'getTimeFrame',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint16',
                    name: 'newCutGap',
                    type: 'uint16'
                }
            ],
            name: 'updateCutGap',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'noMiningZone',
            outputs: [
                {
                    internalType: 'uint32',
                    name: '',
                    type: 'uint32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    private writeMiningManager: any
    private readMiningManager: any

    public propagatedEvent = new Map<string, boolean>()

    constructor() {
        this.initContract()
        this.listenMinedEvent()
    }

    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        this.writeMiningManager = new web3.eth.Contract(this.abi, config.miningManagerAddress, { gasLimit: '1000000' })

        const wsWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readMiningManager = new wsWeb3.eth.Contract(this.abi, config.miningManagerAddress)
    }

    public async getNoMiningZone(): Promise<number> {
        return await this.readMiningManager.methods.noMiningZone().call()
    }

    public async mine(minerId: string, cometAddr: string, time: number, solarSystemID: number, gasPriceGWEI?: number) {
        const account: string = await Web3Service.getInstance().getAccount()
        if (!gasPriceGWEI) {
            const gasPrices = await GasStationService.getInstance().getPrices()
            gasPriceGWEI = gasPrices.fast
        }

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.writeMiningManager.methods
                .mine(minerId, cometAddr, time, solarSystemID)
                .send({ from: account, gasPrice: Web3Helper.gweiToWei(gasPriceGWEI!) })
                .on('error', (error: any) => {
                    this.writeMiningManager.methods
                        .mine(minerId, cometAddr, time, solarSystemID)
                        .call({ from: account })
                        .catch((err: any) => {
                            reject(err)
                            tx.error = err.message
                            notif.title = 'Mining failed.'
                            tx.onError()
                        })
                })
                .on('receipt', (data: any) => {
                    document.dispatchEvent(
                        new CustomEvent(MiningManagerService.MINED_EVENT, { detail: { minerId: minerId, solarSystemID: solarSystemID } })
                    )
                    notif.title = 'Mining succeed!'
                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif = NotificationService.getInstance().addFromTransaction(tx, () => {
                        PlayerService.getInstance().updateSpiceBalance()
                    })
                    notif.title = 'Mining in progress'
                    resolve(data)
                })
        })
    }

    public async listenMinedEvent() {
        await this.readMiningManager.events
            .Mined()
            .on('data', function(event: any) {
                if (MiningManagerService.getInstance().propagatedEvent.has(event.id)) return
                MiningManagerService.getInstance().propagatedEvent.set(event.id, true)
                document.dispatchEvent(new CustomEvent(MiningManagerService.UPDATE_COMET, { detail: event.returnValues }))
            })
            .on('error', function(error: any) {
                console.log('ERROR: listenNewCometEvent', error)
            })
    }
}
