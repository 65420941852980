
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        open: Boolean
    },
    emits: ['close']
})
export default class Modal extends Vue {
    open = false
}
