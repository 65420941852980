import axios from 'axios'

export default class NFTService {
    public static instance: NFTService

    private _cache: Map<string, any>

    public static getInstance(): NFTService {
        if (!this.instance) {
            this.instance = new NFTService()
        }

        return this.instance
    }

    private constructor() {
        this._cache = new Map<string, any>()
    }

    public async getColor(id: string) {
        return axios.get('https://europe-west1-rockside-showcase.cloudfunctions.net/cometh-spaceship-color/' + id)
    }

    public async getMetadata(id: string) {
        return axios.get('https://nft.service.cometh.io/' + id)
    }

    public async getModelMetadata(id: string) {
        if (this._cache.has(id)) return this._cache.get(id)

        const result = await axios.get('https://model.service.cometh.io/' + id)
        this._cache.set(id, result.data)
        return result.data
    }
}
