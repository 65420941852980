import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './index.css'
import VideoBackground from 'vue-responsive-video-background-player'
import VueMyToasts from 'vue-my-toasts'
import DefaultToastComponent from '@/components/toasts/DefaultToastComponent.vue'

createApp(App)
    .use(router)
    .use(VueMyToasts, {
        component: DefaultToastComponent,
        options: {
            width: '400px',
            position: 'top-right',
            padding: '5rem 1rem',
        },
    })
    .component('video-background', VideoBackground)
    .mount('#app')
