import { Notification, NotificationType } from '@/game/models/notification/Notification'
import { GasStationService } from '@/game/services/GasStationService'
import NotificationService from '@/game/services/NotificationService'
import Colors from '@/helpers/Colors'
import Resolution from '@/helpers/Resolution'
import { GasPrices } from '@/models/GasPrices'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import TextButton from '../TextButton'
import ModalComponent from './ModalComponent'

export default class GasPriceChoiceModal extends ModalComponent {
    public btnStandard = PIXI.Sprite.from('btn_gas_default')
    public btnFast = PIXI.Sprite.from('btn_gas_default')
    public btnFastest = PIXI.Sprite.from('btn_gas_default')
    public btnCustom = PIXI.Sprite.from('btn_gas_big')
    public btnConfirm = new TextButton('Confirm')
    public valueCustom = new MainBitmapText('Click to set ', { fontSize: 4 })
    protected closer: PIXI.Sprite = PIXI.Sprite.from('notif_close')

    public onValueSelected = (val: number) => {
        return
    }

    constructor(private gasPrices: GasPrices, private selected: number) {
        super(134, 50)
        this.hPosistion = 50
        this.loader.visible = false

        const title = new MainBitmapText('Select your Gas Price (in GWEI)', { fontSize: 5 })

        title.x = Math.floor(this.modalWidth / 2 - title.width / 2)
        title.y = 5

        const titleStandard = new MainBitmapText('STANDARD', { fontSize: 3 })
        const valueStandard = new MainBitmapText(Number(this.gasPrices.standard).toFixed(2), { fontSize: 4 })
        const imgStandard = PIXI.Sprite.from('ic_gas_standard')

        this.btnStandard.y = 15
        this.btnStandard.x = 4
        imgStandard.x = this.btnStandard.x + 5
        imgStandard.y = this.btnStandard.y + 4
        titleStandard.y = 18
        titleStandard.x = this.btnStandard.x + 14
        titleStandard.alpha = 0.7
        valueStandard.y = titleStandard.y + titleStandard.height
        valueStandard.x = titleStandard.x

        const titleFast = new MainBitmapText('FAST', { fontSize: 3 })
        const valueFast = new MainBitmapText(Number(this.gasPrices.fast).toFixed(2), { fontSize: 4 })
        const imgFast = PIXI.Sprite.from('ic_gas_fast')

        this.btnFast.y = 15
        this.btnFast.x = this.btnStandard.x + this.btnStandard.width + 3
        imgFast.x = this.btnFast.x + 3
        imgFast.y = this.btnFast.y + 3
        titleFast.y = 18
        titleFast.x = this.btnFast.x + 14
        titleFast.alpha = 0.7
        valueFast.y = titleFast.y + titleFast.height
        valueFast.x = titleFast.x

        const titleFastest = new MainBitmapText('FASTEST', { fontSize: 3 })
        const valueFastest = new MainBitmapText(Number(this.gasPrices.fastest).toFixed(2), { fontSize: 4 })
        const imgFastest = PIXI.Sprite.from('ic_gas_fastest')

        this.btnFastest.y = 15
        this.btnFastest.x = this.btnFast.x + this.btnFast.width + 3
        imgFastest.x = this.btnFastest.x - 1
        imgFastest.y = this.btnFastest.y + 3
        titleFastest.y = 18
        titleFastest.x = this.btnFastest.x + 14
        titleFastest.alpha = 0.7
        valueFastest.y = titleFastest.y + titleFastest.height
        valueFastest.x = titleFastest.x

        const titleCustom = new MainBitmapText('CUSTOM GAS PRICE', { fontSize: 3 })
        const imgCustom = PIXI.Sprite.from('ic_gas_custom')

        this.btnCustom.y = this.btnFast.y + this.btnFast.height + 3
        this.btnCustom.x = this.btnStandard.x
        imgCustom.x = this.btnCustom.x + 3
        imgCustom.y = this.btnCustom.y + 3
        titleCustom.y = imgCustom.y
        titleCustom.x = this.btnCustom.x + 17
        titleCustom.alpha = 0.7
        this.valueCustom.y = titleCustom.y + titleCustom.height
        this.valueCustom.x = titleCustom.x

        this.btnConfirm.btn.tint = Colors.Green500
        this.btnConfirm.container.x = this.modalWidth - this.btnConfirm.container.width - 4
        this.btnConfirm.container.y = this.modalHeight - this.btnConfirm.container.height - 4

        this.closer.position.y = -4
        this.closer.position.x = this.modalWidth - this.closer.width + 4
        this.closer.interactive = true
        this.closer.cursor = 'pointer'
        this.closer.tint = Colors.Blue800

        this.modalContainer.addChild(title)
        this.modalContainer.addChild(this.btnStandard)
        this.modalContainer.addChild(this.btnFast)
        this.modalContainer.addChild(this.btnFastest)
        this.modalContainer.addChild(this.btnCustom)
        this.modalContainer.addChild(imgStandard)
        this.modalContainer.addChild(imgFast)
        this.modalContainer.addChild(imgFastest)
        this.modalContainer.addChild(imgCustom)
        this.modalContainer.addChild(titleStandard)
        this.modalContainer.addChild(valueStandard)
        this.modalContainer.addChild(titleFast)
        this.modalContainer.addChild(valueFast)
        this.modalContainer.addChild(titleFastest)
        this.modalContainer.addChild(valueFastest)
        this.modalContainer.addChild(titleCustom)
        this.modalContainer.addChild(this.valueCustom)
        this.modalContainer.addChild(this.closer)
        this.modalContainer.addChild(this.btnConfirm.getContainer())

        this.closer.on('pointertap', () => {
            this.onExit()
        })

        this.btnStandard.interactive = true
        this.btnStandard.cursor = 'pointer'
        this.btnStandard.on('mouseover', () => {
            this.btnStandard.tint = Colors.Blue800
        })
        this.btnStandard.on('mouseout', () => {
            this.tint()
        })
        this.btnStandard.on('mouseout', () => {
            this.tint()
        })
        this.btnStandard.on('pointertap', () => {
            this.selectValue(this.gasPrices.standard)
        })

        this.btnFast.interactive = true
        this.btnFast.cursor = 'pointer'
        this.btnFast.on('mouseover', () => {
            this.btnFast.tint = Colors.Green500
        })
        this.btnFast.on('mouseout', () => {
            this.tint()
        })
        this.btnFast.on('mouseout', () => {
            this.tint()
        })
        this.btnFast.on('pointertap', () => {
            this.selectValue(this.gasPrices.fast)
        })

        this.btnFastest.interactive = true
        this.btnFastest.cursor = 'pointer'
        this.btnFastest.on('mouseover', () => {
            this.btnFastest.tint = Colors.Purple500
        })
        this.btnFastest.on('mouseout', () => {
            this.tint()
        })
        this.btnFastest.on('mouseout', () => {
            this.tint()
        })
        this.btnFastest.on('pointertap', () => {
            this.selectValue(this.gasPrices.fastest)
        })

        this.btnCustom.interactive = true
        this.btnCustom.cursor = 'pointer'
        this.btnCustom.on('mouseover', () => {
            this.btnCustom.tint = Colors.Gold
        })
        this.btnCustom.on('mouseout', () => {
            this.tint()
        })
        this.btnCustom.on('mouseout', () => {
            this.tint()
        })
        this.btnCustom.on('pointertap', () => {
            const enteredValue = prompt('Enter your desired Gas Price in GWEI')
            if (Number(enteredValue) > 0) {
                this.selectValue(Number(enteredValue)!)
            } else {
                const notif = new Notification(NotificationType.Error)
                notif.title = 'Invalid Gas Price entered.'
                NotificationService.getInstance().add(notif)
            }
        })

        this.btnConfirm.container.on('pointertap', () => {
            this.onValueSelected(this.selected)
            this.onExit()
        })

        this.tint()
        this.setPositions()
        this.onResize()
    }

    public onRequireBind: () => PIXI.Point = () => {
        return new PIXI.Point(0, 0)
    }

    onResize() {
        super.onResize()
        this.modalContainer.scale.set(Resolution.scale)
        const pos = this.onRequireBind()

        this.modalContainer.x = pos.x
        this.modalContainer.y = pos.y - this.modalHeight * Resolution.scale - Resolution.margin6
    }

    private tint() {
        this.btnStandard.tint = Colors.Blue500
        this.btnFast.tint = Colors.Blue500
        this.btnFastest.tint = Colors.Blue500
        this.btnCustom.tint = Colors.Blue500

        if (this.selected == Number(this.gasPrices.standard)) {
            this.btnStandard.tint = Colors.Blue800
        } else if (this.selected == Number(this.gasPrices.fast)) {
            this.btnFast.tint = Colors.Green500
        } else if (this.selected == Number(this.gasPrices.fastest)) {
            this.btnFastest.tint = Colors.Purple500
        } else {
            this.btnCustom.tint = Colors.Gold
        }
    }

    private selectValue(val: number) {
        this.selected = Number(val)
        this.tint()
        this.valueCustom.text = this.isCustom() ? this.selected.toFixed(2) : 'Click to set'
    }

    private isCustom() {
        return (
            this.selected != Number(this.gasPrices.standard) &&
            this.selected != Number(this.gasPrices.fast) &&
            this.selected != Number(this.gasPrices.fastest)
        )
    }
}
