import { Options, Vue } from 'vue-class-component'
import config from '@/config'



@Options({
    props: {
       
    }
})
export default class CTAReferral extends Vue {
    config = config
}
