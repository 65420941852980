<template>
    <nav class="relative z-10 bg-white shadow font-audiowide">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex">
                    <div class="-ml-2 mr-2 flex items-center md:hidden">
                        <!-- Mobile menu button -->
                        <button
                            @click="open = !open"
                            type="button"
                            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span class="sr-only">Open main menu</span>
                            <!--
                            Icon when menu is closed.

                            Heroicon name: outline/menu

                            Menu open: "hidden", Menu closed: "block"
                            -->
                            <svg
                                :class="[open ? 'hidden' : 'block']"
                                class="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            <!--
                            Icon when menu is open.

                            Heroicon name: outline/x

                            Menu open: "block", Menu closed: "hidden"
                            -->
                            <svg
                                :class="[open ? 'block' : 'hidden']"
                                class="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="flex-shrink-0 flex items-center">
                        <router-link to="/">
                            <img class="h-10 w-auto" style="image-rendering: auto;" src="@/assets/logo_square.png" alt="Cometh" />
                        </router-link>
                        <!-- <img class="hidden lg:block h-8 w-auto" src="@/assets/logo.png" alt="Cometh" /> -->
                    </div>
                    <div class="hidden md:ml-6 md:flex md:space-x-6">
                        <router-link to="/" custom v-slot="{ href, navigate, isActive }">
                            <NavLink :href="href" @click="navigate" :isActive="isActive">Game</NavLink>
                        </router-link>

                        <router-link to="/tube" custom v-slot="{ href, navigate, isActive }">
                            <NavLink :href="href" @click="navigate" :isActive="isActive">Tube</NavLink>
                        </router-link>

                        <router-link to="/shop" custom v-slot="{ href, navigate, isActive }">
                            <NavLink :href="href" @click="navigate" :isActive="isActive">Shop</NavLink>
                        </router-link>

                        <!-- <router-link to="/kiosk" custom v-slot="{ href, navigate, isActive }">
                            <NavLink :href="href" @click="navigate" :isActive="isActive">Kiosk</NavLink>
                        </router-link> -->

                        <!-- <router-link to="/referral-program" custom v-slot="{ href, navigate, isActive }">
                            <NavLink :href="href" @click="navigate" :isActive="isActive">Referral</NavLink>
                        </router-link> -->

                        <router-link
                            v-if="isAuth"
                            :to="{ name: 'Profile', params: { account: address } }"
                            custom
                            v-slot="{ href, navigate, isActive }"
                        >
                            <NavLink :href="href" @click="navigate" :isActive="isActive">Profile</NavLink>
                        </router-link>

                        <!-- <NavLink href="//rental.cometh.io" target="_blank">
                            Rental
                        </NavLink> -->
                        <NavLink href="//swap.cometh.io" target="_blank">
                            Swap
                        </NavLink>
                        <!-- <NavLink href="//docs.cometh.io" target="_blank">
                            Docs
                        </NavLink> -->
                    </div>
                </div>
                <div v-if="isAuth" class="flex items-center">
                    <div id="dropdown" class="relative hidden md:ml-4 md:flex-shrink-0 md:inline-block text-left">
                        <div>
                            <button
                                @click.stop="toggleDropdown"
                                type="button"
                                class="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                                id="menu-button"
                                aria-expanded="true"
                                aria-haspopup="true"
                            >
                                <span class="hidden lg:inline">{{ subStrAddress }}</span>
                                <svg
                                    v-if="isProviderEmail"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="w-4 h-4 ml-1"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                    />
                                </svg>
                                <svg v-if="isProviderStakeDAO" viewBox="0 0 64 54" class="text-black dark:text-white w-4 ml-1 mb-px">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M13.3734 34.4709L14.3282 41.1736L9.55243 42.131L5.73146 41.1736L0 31.5987L1.91049 14.3628L8.59676 5.7453L17.1935 4.78789L25.7903 0L37.2541 5.7453L34.3879 8.61751C42.6664 9.89405 48.0796 10.8523 50.6266 11.4906C54.4476 12.448 61.1347 17.2359 62.0895 18.1933C63.0452 19.1507 62.5894 23.552 62.0895 26.8108C61.7557 28.9834 61.1191 30.579 60.179 31.5987L64 53.6216H55.4032L57.3137 51.7068L49.6718 35.4283L28.6564 38.3014L21.0162 53.6216H12.4195L15.2848 48.8337L21.0162 28.7256L17.1952 24.896L8.59676 26.8108L7.64108 34.4709L10.5064 37.344L13.3725 34.4709H13.3734ZM26.8108 45.7799L29.6649 39.8988L33.4703 38.9189L36.3243 53.6216H26.8108L28.7135 52.6417L26.8108 45.7799ZM45.1459 39.1222L48 37.1892L52.7568 45.4054L51.8054 53.6216H43.2432L45.1459 50.7217V39.1222V39.1222Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                                <img
                                    v-if="isProviderMetamask"
                                    class="max-w-none h-4 ml-1"
                                    src="https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png"
                                />
                                <!-- Heroicon name: solid/chevron-down -->
                                <svg
                                    :class="{ 'rotate-180': dropDownOpen }"
                                    class="-mr-1 ml-2 h-5 w-5 transform transition-transform duration-300"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>

                        <transition
                            enter-active-class="transition ease-out duration-200"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                        >
                            <div
                                v-show="dropDownOpen"
                                class="origin-top-right absolute z-50 right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabindex="-1"
                            >
                                <div class="px-5 py-4">
                                    <div class="flex justify-between items-center mb-2">
                                        <span class="uppercase text-xs text-gray-400">address</span>
                                        <span
                                            :class="[addressCopied ? 'opacity-100' : 'opacity-0']"
                                            class="transition duration-300 text-xs font-bold text-green-500"
                                            >Copied!</span
                                        >
                                    </div>
                                    <div class="flex items-center space-x-3 mb-3">
                                        <div class="flex items-center space-x-1">
                                            <span> {{ subStrAddress }} </span>
                                            <!-- Stake DAO icon -->
                                            <svg v-if="isProviderStakeDAO" width="64" viewBox="0 0 64 54" class="text-black dark:text-white w-3">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M13.3734 34.4709L14.3282 41.1736L9.55243 42.131L5.73146 41.1736L0 31.5987L1.91049 14.3628L8.59676 5.7453L17.1935 4.78789L25.7903 0L37.2541 5.7453L34.3879 8.61751C42.6664 9.89405 48.0796 10.8523 50.6266 11.4906C54.4476 12.448 61.1347 17.2359 62.0895 18.1933C63.0452 19.1507 62.5894 23.552 62.0895 26.8108C61.7557 28.9834 61.1191 30.579 60.179 31.5987L64 53.6216H55.4032L57.3137 51.7068L49.6718 35.4283L28.6564 38.3014L21.0162 53.6216H12.4195L15.2848 48.8337L21.0162 28.7256L17.1952 24.896L8.59676 26.8108L7.64108 34.4709L10.5064 37.344L13.3725 34.4709H13.3734ZM26.8108 45.7799L29.6649 39.8988L33.4703 38.9189L36.3243 53.6216H26.8108L28.7135 52.6417L26.8108 45.7799ZM45.1459 39.1222L48 37.1892L52.7568 45.4054L51.8054 53.6216H43.2432L45.1459 50.7217V39.1222V39.1222Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                            <!-- Metamask icon -->
                                            <img
                                                v-if="isProviderMetamask"
                                                class="max-w-none h-4"
                                                src="https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png"
                                            />
                                        </div>

                                        <div class="flex items-center space-x-1">
                                            <button
                                                @click="copyAddress"
                                                class="border border-gray-300 rounded-md p-1.5 hover:bg-gray-100 focus:outline-none"
                                            >
                                                <!-- Copy icon -->
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-4 w-4"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                                                    />
                                                </svg>
                                            </button>
                                            <a
                                                :href="`https://explorer.matic.network/address/${address}`"
                                                target="_blank"
                                                class="border border-gray-300 rounded-md p-1.5 hover:bg-gray-100"
                                            >
                                                <!-- External link icon -->
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-4 w-4"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>

                                    <button @click="openModal" class="border border-gray-300 rounded-md px-2.5 py-1.5 hover:bg-gray-100">
                                        Change
                                    </button>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="flex-shrink-0 ml-4">
                        <button
                            @click="buyCrypto()"
                            type="button"
                            class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <span>Buy Crypto</span>
                        </button>
                    </div>
                </div>
                <div v-else class="flex items-center">
                    <div class="flex-shrink-0 ">
                        <button
                            v-if="isConnecting"
                            type="button"
                            class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 shadow-sm focus:outline-none  bg-opacity-75"
                        >
                            <svg
                                class="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path
                                    class="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            <span>Connecting</span>
                        </button>
                        <button
                            v-else
                            @click="openModal"
                            type="button"
                            class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <span>Connect</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile menu, show/hide based on menu state. -->
        <div v-show="open" class="md:hidden" id="mobile-menu">
            <div class="pt-2 pb-3 space-y-1">
                <router-link to="/" custom v-slot="{ href, navigate, isActive }">
                    <NavLinkMobile :href="href" @click="navigate" :isActive="isActive">Game</NavLinkMobile>
                </router-link>

                <router-link to="/tube" custom v-slot="{ href, navigate, isActive }">
                    <NavLinkMobile :href="href" @click="navigate" :isActive="isActive">Tube</NavLinkMobile>
                </router-link>

                <router-link to="/shop" custom v-slot="{ href, navigate, isActive }">
                    <NavLinkMobile :href="href" @click="navigate" :isActive="isActive">Shop</NavLinkMobile>
                </router-link>

                <!-- <router-link to="/kiosk" custom v-slot="{ href, navigate, isActive }">
                    <NavLinkMobile :href="href" @click="navigate" :isActive="isActive">Kiosk</NavLinkMobile>
                </router-link> -->

                <!-- <router-link to="/referral-program" custom v-slot="{ href, navigate, isActive }">
                    <NavLinkMobile :href="href" @click="navigate" :isActive="isActive">Referral</NavLinkMobile>
                </router-link> -->

                <router-link v-if="isAuth" :to="{ name: 'Profile', params: { account: address } }" custom v-slot="{ href, navigate, isActive }">
                    <NavLinkMobile :href="href" @click="navigate" :isActive="isActive">Profile</NavLinkMobile>
                </router-link>

                <!-- <NavLinkMobile href="//rental.cometh.io" target="_blank">
                    Rental
                </NavLinkMobile> -->
                <NavLinkMobile href="//swap.cometh.io" target="_blank">
                    Swap
                </NavLinkMobile>
                <!-- <NavLinkMobile href="//docs.cometh.io" target="_blank">
                    Docs
                </NavLinkMobile> -->
            </div>
            <div v-if="isAuth" class="pt-4 pb-3 border-t border-gray-200">
                <div class="flex items-center px-4 sm:px-6">
                    <div class="flex-shrink-0">
                        <svg
                            v-if="isProviderEmail"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-8 h-8"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                        </svg>
                        <img
                            v-if="isProviderMetamask"
                            class="h-10 w-10 rounded-full"
                            src="https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png"
                        />
                        <svg v-if="isProviderStakeDAO" viewBox="0 0 64 54" class="text-black dark:text-white w-10 mb-px">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.3734 34.4709L14.3282 41.1736L9.55243 42.131L5.73146 41.1736L0 31.5987L1.91049 14.3628L8.59676 5.7453L17.1935 4.78789L25.7903 0L37.2541 5.7453L34.3879 8.61751C42.6664 9.89405 48.0796 10.8523 50.6266 11.4906C54.4476 12.448 61.1347 17.2359 62.0895 18.1933C63.0452 19.1507 62.5894 23.552 62.0895 26.8108C61.7557 28.9834 61.1191 30.579 60.179 31.5987L64 53.6216H55.4032L57.3137 51.7068L49.6718 35.4283L28.6564 38.3014L21.0162 53.6216H12.4195L15.2848 48.8337L21.0162 28.7256L17.1952 24.896L8.59676 26.8108L7.64108 34.4709L10.5064 37.344L13.3725 34.4709H13.3734ZM26.8108 45.7799L29.6649 39.8988L33.4703 38.9189L36.3243 53.6216H26.8108L28.7135 52.6417L26.8108 45.7799ZM45.1459 39.1222L48 37.1892L52.7568 45.4054L51.8054 53.6216H43.2432L45.1459 50.7217V39.1222V39.1222Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                    <div class="ml-3">
                        <div class="text-base font-medium text-gray-800">Address</div>
                        <div class="text-sm font-medium text-gray-500">{{ subStrAddress }}</div>
                    </div>
                    <div class="ml-auto flex items-center">
                        <span :class="[addressCopied ? 'opacity-100' : 'opacity-0']" class="transition duration-300 text-xs font-bold text-green-500"
                            >Copied!</span
                        >
                        <button
                            @click="copyAddress"
                            class="flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <!-- Copy icon -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                                />
                            </svg>
                        </button>
                        <a
                            :href="`https://explorer.matic.network/address/${address}`"
                            target="_blank"
                            class="ml-3 flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <!-- External link icon -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="mt-3 space-y-1">
                    <a
                        @click="openModal"
                        href="#"
                        class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                    >
                        Change Provider
                    </a>
                </div>
            </div>
        </div>
    </nav>

    <!-- modal -->

    <Modal :open="modalOpen" @close="closeModal">
        <div class="flex flex-col">
            <div class="mb-4 flex justify-between items-center">
                <span class="text-lg font-medium">Select wallet provider</span>
                <button
                    @click="closeModal"
                    class="rounded-full w-6 h-6 p-1 hover:bg-gray-300 hover:text-gray-600 transition duration-300 ease-in-out focus:outline-none"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <p class="text-sm font-medium text-gray-700 mb-1">Sign in with</p>

            <!-- EMAIL -->
            <div
                v-show="!isEditingEmail"
                @click="editEmail"
                key="mail_button"
                class="flex items-center justify-between px-2.5 h-12 border border-gray-300 rounded-md hover:bg-gray-100 cursor-pointer"
            >
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                    </svg>

                    <span>{{ email ? email : 'Email' }}</span>
                </div>
                <svg
                    v-if="isAuth && isProviderEmail && !isEditingEmail"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-green-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
            </div>
            <div v-show="isEditingEmail">
                <div class="flex items-center justify-between px-2.5 h-12 border border-gray-300 rounded-md">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                    </svg>
                    <input
                        ref="emailInput"
                        v-model="email"
                        type="text"
                        placeholder="foo@bar.com"
                        autofocus
                        @keyup.enter="saveEmail"
                        @keyup.esc="cancelEmail"
                        class="flex-1 h-full focus:outline-none"
                    />
                    <button
                        @click.self="saveEmail"
                        class="flex items-center bg-green-500 text-green-100 hover:bg-green-600 rounded px-1.5 py-1 text-xs ml-2 uppercase"
                    >
                        sign in
                    </button>
                </div>
                <span v-if="emailError" class="text-red-500 text-xs ml-1">Please provide a valid mail address</span>
            </div>
            <!-- END EMAIL -->

            <div class="my-6 relative">
                <div class="absolute inset-0 flex items-center">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-center text-sm">
                    <span class="px-2 bg-white text-gray-500"> Or sign in with </span>
                </div>
            </div>

            <!-- METAMASK -->
            <button
                @click="useMetamask"
                class="flex items-center justify-between px-2.5 h-12 border border-gray-300 rounded-md hover:bg-gray-100 mb-2"
            >
                <span class="flex items-center">
                    <img class="h-4 mr-2" src="https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png" />
                    Metamask
                </span>
                <svg
                    v-if="isConnectingMetamask"
                    class="animate-spin h-5 w-5 text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
                <svg
                    v-else-if="isAuth && isProviderMetamask"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-green-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
            </button>
            <!-- END METAMASK -->

            <!-- STAKE DAO -->
            <div
                v-if="!isEditingStakeDao"
                @click="editStakeDao"
                class="flex items-center justify-between px-2.5 h-12 border border-gray-300 rounded-md hover:bg-gray-100 cursor-pointer mb-4"
            >
                <div class="flex items-center">
                    <svg viewBox="0 0 64 54" class="w-4 mr-2 mb-px">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.3734 34.4709L14.3282 41.1736L9.55243 42.131L5.73146 41.1736L0 31.5987L1.91049 14.3628L8.59676 5.7453L17.1935 4.78789L25.7903 0L37.2541 5.7453L34.3879 8.61751C42.6664 9.89405 48.0796 10.8523 50.6266 11.4906C54.4476 12.448 61.1347 17.2359 62.0895 18.1933C63.0452 19.1507 62.5894 23.552 62.0895 26.8108C61.7557 28.9834 61.1191 30.579 60.179 31.5987L64 53.6216H55.4032L57.3137 51.7068L49.6718 35.4283L28.6564 38.3014L21.0162 53.6216H12.4195L15.2848 48.8337L21.0162 28.7256L17.1952 24.896L8.59676 26.8108L7.64108 34.4709L10.5064 37.344L13.3725 34.4709H13.3734ZM26.8108 45.7799L29.6649 39.8988L33.4703 38.9189L36.3243 53.6216H26.8108L28.7135 52.6417L26.8108 45.7799ZM45.1459 39.1222L48 37.1892L52.7568 45.4054L51.8054 53.6216H43.2432L45.1459 50.7217V39.1222V39.1222Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                    <span>{{ stakeDaoEmail ? stakeDaoEmail : 'Stake DAO' }}</span>
                </div>
                <svg
                    v-if="isAuth && isProviderStakeDAO && !isEditingStakeDao"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-green-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
            </div>
            <div v-if="isEditingStakeDao" class="mb-4">
                <div class="flex items-center justify-between px-2.5 h-12 border border-gray-300 rounded-md">
                    <svg viewBox="0 0 64 54" class="w-4 mr-2 mb-px">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.3734 34.4709L14.3282 41.1736L9.55243 42.131L5.73146 41.1736L0 31.5987L1.91049 14.3628L8.59676 5.7453L17.1935 4.78789L25.7903 0L37.2541 5.7453L34.3879 8.61751C42.6664 9.89405 48.0796 10.8523 50.6266 11.4906C54.4476 12.448 61.1347 17.2359 62.0895 18.1933C63.0452 19.1507 62.5894 23.552 62.0895 26.8108C61.7557 28.9834 61.1191 30.579 60.179 31.5987L64 53.6216H55.4032L57.3137 51.7068L49.6718 35.4283L28.6564 38.3014L21.0162 53.6216H12.4195L15.2848 48.8337L21.0162 28.7256L17.1952 24.896L8.59676 26.8108L7.64108 34.4709L10.5064 37.344L13.3725 34.4709H13.3734ZM26.8108 45.7799L29.6649 39.8988L33.4703 38.9189L36.3243 53.6216H26.8108L28.7135 52.6417L26.8108 45.7799ZM45.1459 39.1222L48 37.1892L52.7568 45.4054L51.8054 53.6216H43.2432L45.1459 50.7217V39.1222V39.1222Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                    <input
                        ref="stakeDaoInput"
                        v-model="stakeDaoEmail"
                        type="text"
                        placeholder="foo@bar.com"
                        autofocus
                        @keyup.enter="saveStakeDao"
                        @keyup.esc="cancelStakeDao"
                        class="flex-1 h-full focus:outline-none"
                    />
                    <svg
                        v-if="isConnectingStakeDao"
                        class="animate-spin h-5 w-5 text-black"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    <button
                        v-else
                        @click.self="saveStakeDao"
                        class="flex items-center bg-green-500 text-green-100 hover:bg-green-600 rounded px-1.5 py-1 text-xs ml-2 uppercase"
                    >
                        connect
                    </button>
                </div>
                <span v-if="stakeDaoError" class="text-red-500 text-xs ml-1">Please provide a valid mail address</span>
            </div>
            <!-- END STAKE DAO -->

            <button
                v-if="isAuth"
                @click="logout"
                class="inline-flex items-center justify-center text-red-400 text-xs border border-transparent rounded-md px-2.5 py-1.5 hover:bg-red-100 hover:border-red-100"
            >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 -ml-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                </svg>
                Logout
            </button>
        </div>
    </Modal>
</template>

<script lang="ts" src="./Header.ts"></script>
