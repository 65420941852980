import Resolution from '@/helpers/Resolution'
import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import Colors from '@/helpers/Colors'
import ButtonComponent from './ButtonComponent'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import TextButton from './TextButton'

export default class JumpPriceTooHighModal implements GameElement {
    public onExit: () => void = () => {
        return
    }
    public onContinue: () => void = () => {
        return
    }
    private container: PIXI.Container = new PIXI.Container()
    private bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private modalContainer: PIXI.Container = new PIXI.Container()
    private top: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private left: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private right: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private bottom: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private modalBg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private okBtn: TextButton
    private cancelButton: TextButton

    private modalWidth = 126
    private modalHeight = 52

    constructor() {
        this.bg.tint = 0
        this.bg.alpha = 0
        this.bg.interactive = true
        this.bg.cursor = 'pointer'
        this.bg.on('pointertap', () => {
            this.onExit()
        })

        this.container.addChild(this.bg)

        this.top.tint = Colors.Red500

        // this.bottom.width = this.modalWidth
        this.bottom.position.y = this.modalHeight
        this.bottom.tint = Colors.Red500

        // this.left.height = this.modalHeight
        this.left.tint = Colors.Red500

        // this.right.height = this.modalHeight
        this.right.position.x = this.modalWidth - 1
        this.right.tint = Colors.Red500

        this.modalBg.height = this.modalHeight
        this.modalBg.width = this.modalWidth
        this.modalBg.tint = Colors.Red300
        this.modalBg.interactive = true

        const title = new MainBitmapText('Jump Warning !', {
            fontSize: 6,
            align: 'center'
        })
        title.y = 6
        title.x = Math.floor(this.modalWidth / 2 - title.width / 2)

        const text = new MainBitmapText('The jump price is really high', {
            fontSize: 5,
            align: 'center'
        })
        text.y = title.y + title.height + 4
        text.x = Math.floor(this.modalWidth / 2 - text.width / 2)
        const text2 = new MainBitmapText(' do you really want to continue ?', {
            fontSize: 5,
            align: 'center'
        })
        text2.y = text.y + text.height
        text2.x = Math.floor(this.modalWidth / 2 - text2.width / 2)

        this.okBtn = new TextButton('Jump !')
        this.okBtn.container.x = Math.floor((this.modalWidth * 2) / 3 - this.okBtn.container.width / 2)
        this.okBtn.container.position.y = this.modalHeight - 16
        this.okBtn.container.interactive = true
        this.okBtn.container.cursor = 'pointer'
        this.okBtn.container.on('pointertap', async () => {
            this.onContinue()
        })

        this.cancelButton = new TextButton('Cancel')
        this.cancelButton.btn.tint = Colors.Red500
        this.cancelButton.container.x = Math.floor(this.modalWidth / 3 - this.cancelButton.container.width / 2)
        this.cancelButton.container.position.y = this.modalHeight - 16
        this.cancelButton.container.interactive = true
        this.cancelButton.container.cursor = 'pointer'
        this.cancelButton.container.on('pointertap', async () => {
            this.onExit()
        })

        this.modalContainer.addChild(this.modalBg)
        this.modalContainer.addChild(this.top)
        this.modalContainer.addChild(this.left)
        this.modalContainer.addChild(this.right)
        this.modalContainer.addChild(this.bottom)
        this.modalContainer.addChild(title)
        this.modalContainer.addChild(text)
        this.modalContainer.addChild(text2)

        this.modalContainer.addChild(this.okBtn.container)
        this.modalContainer.addChild(this.cancelButton.container)
        this.container.addChild(this.modalContainer)
        this.setPositions()
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await animate('easeOutQuad', 300, (perc: number) => {
            this.bg.alpha = perc * 0.5
            this.modalContainer.alpha = perc
            this.top.width = this.modalWidth * perc
            this.bottom.width = this.modalWidth * perc
            this.bottom.x = this.modalWidth - this.modalWidth * perc
            this.left.height = this.modalHeight * perc
            this.left.y = this.modalHeight - this.modalHeight * perc
            this.right.height = this.modalHeight * perc
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await animate('easeInQuad', 300, (perc: number) => {
            this.modalContainer.alpha = 1 - perc
            this.bg.alpha = 0.5 - perc * 0.5
            this.top.width = this.modalWidth - this.modalWidth * perc
            this.bottom.width = this.modalWidth - this.modalWidth * perc
            this.bottom.x = this.modalWidth * perc
            this.left.height = this.modalHeight - this.modalHeight * perc
            this.left.y = this.modalHeight * perc
            this.right.height = this.modalHeight - this.modalHeight * perc
        })
        callback()
    }

    onResize(): void {
        this.bg.width = Resolution.realWidth
        this.setPositions()
    }

    public tick(): void {
        // this.bg!.tilePosition.y += 0.1
    }

    public getContainer(): PIXI.Container {
        return this.container
    }

    private setPositions() {
        this.bg.width = Resolution.realWidth
        this.bg.height = Resolution.realHeight
        this.modalContainer.scale.set(Resolution.scale)
        this.modalContainer.position.x = Math.round(Resolution.realWidth / 2 - (this.modalWidth * Resolution.scale) / 2)
        this.modalContainer.position.y = Math.round(Resolution.realHeight / 2 - (this.modalHeight * Resolution.scale) / 2)
    }
}
