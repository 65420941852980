<template>
    <div class="fixed inset-0 bg-gray-100 overflow-y-auto">
        <Header ref="header" v-on:rampPurchaseSuccessful="onRampPurchased" />
        <div class="bg-gray-100 h-full">
            <div class="pt-12 sm:pt-16 lg:pt-20">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="text-center">
                        <div class="max-w-sm mx-auto flex flex-col items-center mb-4">
                            <img class="w-32" src="@/assets/logo.png" />
                        </div>
                        <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Token Transfer</h2>
                        <p class="mt-4 text-xl text-gray-600">Transfer your Cometh NFTs.</p>

                        <!-- Ship list container -->
                        <div class="w-full max-w-md mx-auto">
                            <transition name="mode-fade" mode="out-in">
                                <div v-if="isLoadingShips" class="">
                                    <div v-for="i in 3" :key="i" class="flex items-center p-3 animate-pulse">
                                        <div class="bg-gray-700 h-16 w-12 rounded-sm"></div>
                                        <div class="flex-1">
                                            <div class="w-2/3 h-5 rounded-sm bg-gray-700 ml-4"></div>
                                        </div>
                                        <button class="bg-gray-700 font-audiowide w-16 h-8 rounded-sm ml-4"></button>
                                    </div>
                                </div>
                                <div v-else-if="availableShips.size" class="flex flex-col divide-y divide-white divide-opacity-25">
                                    <transition-group name="list">
                                        <div v-for="[index, ship] in availableShips" :key="index" class="flex items-center p-3">
                                            <img class="h-16" :src="ship.img" />
                                            <div class="flex-1 font-audiowide text-sm ml-4 text-left">{{ ship.name || 'Unknown' }}</div>
                                            <button
                                                v-if="!ship.transferring"
                                                @click="openTransferModal(ship)"
                                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                            >
                                                Transfer
                                            </button>

                                            <button
                                                v-else
                                                class="inline-flex items-center bg-blue-501 font-audiowide rounded-sm bg-gold text-black hover:bg-yellow-300 px-3 py-1 text-sm cursor-not-allowed opacity-25"
                                            >
                                                <svg
                                                    class="animate-spin -ml-1 mr-3 h-5 w-5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                    <path
                                                        class="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                                <svg
                                                    class="w-4 h-4 -mr-1 ml-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </transition-group>
                                </div>
                                <div v-else class="font-audiowide">
                                    <p class="mb-2 mt-4">No spaceship in your wallet. <br />How to get one?</p>
                                    <p>
                                        - Borrow a spaceship on<a
                                            class="pl-2 mr-2 text-gold no-underline hover:underline"
                                            href="https://rental.cometh.io"
                                            >Cometh Rental</a
                                        >
                                    </p>
                                    <p>
                                        - Or get one on<a class="pl-2 mr-2 text-gold no-underline hover:underline" href="https://matic.opensea.io/"
                                            >Opensea</a
                                        >
                                    </p>
                                    <p>
                                        - Or bridge one on<a class="pl-2 mr-2 text-gold no-underline hover:underline" href="https://bridge.cometh.io"
                                            >Cometh Bridge</a
                                        >
                                    </p>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <transition name="overlay">
            <div v-show="showTransferModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <transition name="overlay">
                        <div
                            v-show="showTransferModal"
                            @click="showTransferModal = false"
                            class="fixed inset-0 bg-black bg-opacity-70 transition-opacity"
                            aria-hidden="true"
                        ></div>
                    </transition>

                    <!-- This element is to trick the browser into centering the modal contents. -->
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <transition name="modal">
                        <div
                            v-show="showTransferModal"
                            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                        >
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-6 w-6 text-blue-600"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                                        />
                                    </svg>
                                </div>
                                <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                        Token Transfer - {{ shipToTransfer.name }}
                                    </h3>
                                    <div class="mt-2">
                                        <p>Add address</p>
                                        <p class="text-sm text-gray-500">Your asset will be transferred to this address.</p>
                                    </div>
                                    <div class="mt-4">
                                        <input
                                            type="text"
                                            v-model="receiverAddress"
                                            @keyup="addressError = false"
                                            :class="[addressError ? 'border-red-300' : 'border-gray-300']"
                                            class="w-full px-2.5 h-10 text-sm border rounded-md"
                                            placeholder="Enter receiver address"
                                        />
                                        <p v-if="addressError" class="text-xs text-red-500 mt-1">Enter a valid address</p>
                                        <p v-else class="text-xs text-gray-500 mt-1">Enter only matic network address</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <Button v-if="transferSuccessful" @click="closeTransferModal" class="bg-green-600 text-white focus:ring-green-500">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-5 w-5 -ml-1 mr-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Transferred
                                </Button>
                                <Button
                                    v-else
                                    @click="transfer"
                                    :isLoading="isTransferring"
                                    :class="{ 'hover:bg-blue-700 focus:ring-blue-500': !isTransferring }"
                                    class="bg-blue-600 text-white"
                                >
                                    Transfer
                                </Button>
                                <Button
                                    v-if="!isTransferring && !transferSuccessful"
                                    @click="closeTransferModal"
                                    :class="{ 'hover:bg-gray-50 focus:ring-blue-500': !isTransferring }"
                                    class="border-gray-300 bg-white text-gray-700"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" src="./TokenTransfer.ts"></script>
