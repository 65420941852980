import Miner from '../Miner'
import { Portal } from '../Portal'
import { Notification } from './Notification'

export class PortalTravelNotification extends Notification {
    public portal: Portal
    public time: number
    public miner: Miner

    public static getNotifIdFor(minerId: string) {
        return 'portal_' + minerId
    }

    constructor(miner: Miner, portal: Portal, time: number) {
        super()
        this.id = PortalTravelNotification.getNotifIdFor(miner.id)
        this.miner = miner
        this.portal = portal
        this.time = time
    }
}
