import * as PIXISound from 'pixi-sound'
import * as PIXI from 'pixi.js'

export default class MouseService {
    public static instance: MouseService

    public static getInstance(): MouseService {
        if (!this.instance) {
            this.instance = new MouseService()
        }

        return this.instance
    }

    public mobileIgnored = false

    public position: PIXI.ObservablePoint = new PIXI.ObservablePoint(
        () => {
            return
        },
        0,
        0,
        0
    )

    constructor() {
        document.addEventListener('mousemove', (event: MouseEvent) => {
            const mouseX = event.clientX
            const mouseY = event.clientY

            this.position.set(mouseX, mouseY)
        })
    }
}
