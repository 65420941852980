export default class Colors {
    public static Gold = 0xffd700
    public static GoldDarker = 0x746620
    public static Blue100 = 0x020d18
    public static Blue300 = 0x081f36
    public static Blue400 = 0x103861
    public static Blue500 = 0x0f608b
    public static Blue600 = 0x1f80ab
    public static Blue800 = 0x49bfff
    public static Red500 = 0xf70a0a
    public static Orange500 = 0xffa200
    public static Red400 = 0xc70e0e
    public static Red300 = 0x930a0a
    public static Green500 = 0x5ad700
    public static Green400 = 0x47a702
    public static Green300 = 0x36740a

    public static Purple100 = 0x480c47
    public static Purple300 = 0x7c137a
    public static Purple500 = 0xdb0fd9

    public static Orange = 0xffad33
    public static White = 0xffffff
    public static Black = 0x000000

    public static RarityCommon = Colors.Blue500
    public static RarityUncommon = Colors.Blue800
    public static RarityRare = 0xf9e52e
    public static RarityMythic = 0x5a2fc3

    public static byRarity(rarity: string) {
        switch (rarity.toLowerCase()) {
            case 'common':
                return this.RarityCommon
            case 'uncommon':
                return this.RarityUncommon
            case 'rare':
                return this.RarityRare
            case 'mythic':
                return this.RarityMythic
            default:
                return this.Blue800
        }
    }
}
