import * as PIXI from 'pixi.js'
import { BigNumber } from 'bignumber.js'
import { uniq } from 'lodash'
import ModalComponent from './ModalComponent'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import ScrollableContainer from '@/helpers/ScrollHelper'
import Colors from '@/helpers/Colors'
import NFTService from '@/game/services/NFTService'
import PlayerService from '@/game/services/PlayerService'
import MapService from '@/game/services/MapService'
import TheGraphService from '@/game/services/TheGraphService'
import RankingSessionService from '@/game/services/RankingSessionService'

import Resolution from '@/helpers/Resolution'
import { getFormatedHash } from '@/helpers/StringHelper'
import { animate } from '@/helpers/AnimationHelper'
import GalaxyService from '@/services/GalaxyService'

class LeaderboardLine {
    constructor(public owner: string, public ship: string, public score: number, public name: string) {}
}

export class Reward {
    constructor(public title: string, public topLimitWins: number, public img: string) {}
}

class RewardComponent extends PIXI.Container {
    public modalWidth = 100
    public modalHeight = 100
    protected top: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected left: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected right: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected bottom: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected modalBg: PIXI.Sprite = PIXI.Sprite.from('pixel')

    constructor(public reward: Reward) {
        super()

        this.top.width = this.modalWidth
        this.top.tint = 0x49bfff

        // this.bottom.width = this.modalWidth
        this.bottom.position.y = this.modalHeight
        this.bottom.tint = 0x49bfff

        // this.left.height = this.modalHeight
        this.left.tint = 0x49bfff

        // this.right.height = this.modalHeight
        this.right.position.x = this.modalWidth - 1
        this.right.tint = 0x49bfff

        this.modalBg.y = 1
        this.modalBg.x = 1
        this.modalBg.height = this.modalHeight - 1
        this.modalBg.width = this.modalWidth - 2
        this.modalBg.tint = 0x103861
        this.modalBg.interactive = true

        const title = new MainBitmapText('Rewards !', { fontSize: 7 })
        title.x = Math.floor(this.modalWidth / 2 - title.width / 2)
        title.y = 6

        const subTitle = new MainBitmapText(`Top ${reward.topLimitWins} wins`, { fontSize: 5 })
        subTitle.x = Math.floor(this.modalWidth / 2 - subTitle.width / 2)
        subTitle.y = title.y + 12
        const rewardTitle = new MainBitmapText(`${reward.title}`, { fontSize: 5, tint: Colors.Gold })
        rewardTitle.x = Math.floor(this.modalWidth / 2 - rewardTitle.width / 2)
        rewardTitle.y = subTitle.y + 6

        const imgBg = PIXI.Sprite.from('pixel')
        imgBg.width = 52
        imgBg.height = 52
        imgBg.y = subTitle.y + 22
        imgBg.tint = Colors.Blue300
        imgBg.x = Math.floor(this.modalWidth / 2 - imgBg.width / 2)
        const img = PIXI.Sprite.from(reward.img)
        img.scale.set(1 / 9)
        img.y = imgBg.y + Math.floor(imgBg.height / 2 - img.height / 2)
        img.x = Math.floor(this.modalWidth / 2 - img.width / 2)

        this.addChild(this.modalBg)
        this.addChild(this.top)
        this.addChild(this.left)
        this.addChild(this.right)
        this.addChild(this.bottom)
        this.addChild(title)
        this.addChild(subTitle)
        this.addChild(rewardTitle)
        this.addChild(imgBg)
        this.addChild(img)
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await animate('easeOutQuad', 300, (perc: number) => {
            const horizontalSize = this.modalWidth - 2
            const verticalSize = this.modalHeight - 1

            this.alpha = perc

            this.top.x = 1
            this.top.width = horizontalSize * perc

            this.bottom.x = 1 + horizontalSize - horizontalSize * perc
            this.bottom.width = horizontalSize * perc

            this.left.height = verticalSize * perc
            this.left.y = 1 + verticalSize - verticalSize * perc

            this.right.height = verticalSize * perc
            this.right.y = 1
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await animate('easeInQuad', 300, (perc: number) => {
            const horizontalSize = this.modalWidth - 2
            const verticalSize = this.modalHeight - 1

            this.alpha = 1 - perc

            this.bottom.x = 1
            this.top.width = horizontalSize - horizontalSize * perc

            this.bottom.width = horizontalSize - horizontalSize * perc
            this.bottom.x = 1 + horizontalSize * perc

            this.left.height = verticalSize - verticalSize * perc
            this.left.y = 1 + verticalSize * perc

            this.right.y = 1
            this.right.height = verticalSize - verticalSize * perc
        })
        callback()
    }
}

class LeaderboardLineComponent extends PIXI.Container {
    public bg = PIXI.Sprite.from('pixel')
    public rankText = new MainBitmapText('123', { fontSize: 5 })
    public playerName = new MainBitmapText('MULE 1845', { fontSize: 4 })
    public playerAddr = new MainBitmapText('0x123456789123456789123456789', { fontSize: 4 })
    public scoreText = new MainBitmapText('5487', { fontSize: 5 })

    constructor(public line: LeaderboardLine, rank: number) {
        super()

        this.bg.tint = line.ship == PlayerService.getInstance().miner.id ? Colors.Blue500 : Colors.Blue400
        this.bg.width = 156 - 2
        this.bg.height = 20
        this.bg.x = 1
        this.rankText.text = rank.toString()
        this.playerName.text = this.line.name
        this.rankText.text = rank.toString()
        this.scoreText.text = line.score.toString()
        this.playerAddr.text = getFormatedHash(this.line.owner, 12)

        this.rankText.y = 6
        this.rankText.x = 6
        this.playerAddr.y = 5
        this.playerAddr.x = 28
        this.playerName.y = this.playerAddr.y + this.playerAddr.height
        this.playerName.x = this.playerAddr.x
        this.playerAddr.alpha = 0.5
        this.scoreText.y = 6
        this.scoreText.x = this.bg.width - this.scoreText.width - 6

        this.addChild(this.bg)
        this.addChild(this.rankText)
        this.addChild(this.playerName)
        this.addChild(this.playerAddr)
        this.addChild(this.scoreText)
    }
}

export default class LeaderboardModal extends ModalComponent {
    // TEMP, to move serverside later
    public rewards: Map<number, Reward> = new Map()
    private scrollContainer: ScrollableContainer = new ScrollableContainer(26, 156)
    private groupWrapperContainer: PIXI.Container = new PIXI.Container()
    private groupContainer: PIXI.Container = new PIXI.Container()
    private currentLeaderboard: LeaderboardLine[] = []
    private currentLeaderboardComponents: LeaderboardLineComponent[] = []
    private currentShipLine?: LeaderboardLineComponent
    private shipNotInCompetition = new MainBitmapText('Your current ship is not in this competition', { fontSize: 4 })
    private solHasNoLeaderboard = new MainBitmapText('No leaderboard available for this solar system', { fontSize: 4 })

    private prevButton = PIXI.Sprite.from('ic_arrow')
    private nextButton = PIXI.Sprite.from('ic_arrow')

    private sessionNumber = new MainBitmapText('Session    ', { fontSize: 6 })
    private currentSession = -1
    private rewardComponent?: RewardComponent

    constructor() {
        super(156, 170)
        this.hPosistion = 80

        this.rewards.set(4, new Reward('Ticket: Galactic Grand Prix', 1, 'polygon_ticket_grandprix'))
        const currentCupReward = GalaxyService.getInstance().getCurrentCupReward()
        if (currentCupReward) {
            this.rewards.set(100, currentCupReward!)
        }

        this.modalContainer.addChild(this.modalBg)
        this.modalContainer.addChild(this.top)
        this.modalContainer.addChild(this.left)
        this.modalContainer.addChild(this.right)
        this.modalContainer.addChild(this.bottom)
        this.modalContainer.addChild(this.scrollContainer.wrapper)
        this.modalContainer.addChild(this.loader)

        this.loader.y = Math.floor(this.modalHeight / 2 - this.loader.height / 2 + 28)
        const title = new MainBitmapText('Ranking', { fontSize: 8 })

        title.x = Math.floor((this.modalWidth - title.width) / 2)
        title.y = 6

        const sep1 = PIXI.Sprite.from('pixel')
        sep1.tint = Colors.Blue800
        sep1.width = this.modalWidth - 12
        sep1.x = 6
        sep1.y = title.y + title.height + 6

        this.sessionNumber.x = Math.floor((this.modalWidth - this.sessionNumber.width) / 2)
        this.sessionNumber.y = sep1.y + 3

        this.prevButton.interactive = true
        this.prevButton.cursor = 'pointer'
        this.nextButton.interactive = true
        this.nextButton.cursor = 'pointer'
        this.prevButton.anchor.set(0.5)
        this.prevButton.y = this.sessionNumber.y + 4.333
        this.prevButton.rotation = Math.PI
        this.prevButton.x = 10
        this.prevButton.on('pointertap', () => {
            this.loadPrevious()
        })

        this.nextButton.y = this.sessionNumber.y - 3.6666
        this.nextButton.x = this.modalWidth - this.nextButton.width - 2
        this.nextButton.on('pointertap', () => {
            this.loadNext()
        })

        const sep2 = PIXI.Sprite.from('pixel')
        sep2.tint = Colors.Blue800
        sep2.width = this.modalWidth - 12
        sep2.x = 6
        sep2.y = sep1.y + 14

        const sep3 = PIXI.Sprite.from('pixel')
        sep3.tint = Colors.Blue800
        sep3.width = this.modalWidth - 12
        sep3.x = 6
        sep3.y = sep2.y + 10

        const sep4 = PIXI.Sprite.from('pixel')
        sep4.tint = Colors.Blue800
        sep4.width = this.modalWidth - 12
        sep4.x = 6
        sep4.y = sep3.y + 19

        const rank = new MainBitmapText('Rank', { fontSize: 4 })
        rank.x = 6
        rank.y = sep2.y + 3
        rank.tint = Colors.Blue800

        const miner = new MainBitmapText('Miner', { fontSize: 4 })
        miner.x = 28
        miner.y = sep2.y + 3
        miner.tint = Colors.Blue800

        const score = new MainBitmapText('Score', { fontSize: 4 })
        score.x = 135
        score.y = sep2.y + 3
        score.tint = Colors.Blue800

        const youWrapper = PIXI.Sprite.from('ic_you_wrapper')
        youWrapper.y = sep3.y + 4
        youWrapper.x = -6
        const youText = new MainBitmapText('YOU', { fontSize: 3 })
        youText.y = youWrapper.y + 10
        youText.x = -3.6666
        youText.rotation = -Math.PI / 2

        this.shipNotInCompetition.y = sep3.y + 7
        this.shipNotInCompetition.x = Math.floor(this.modalWidth / 2 - this.shipNotInCompetition.width / 2)

        this.solHasNoLeaderboard.y = 106
        this.solHasNoLeaderboard.x = Math.floor(this.modalWidth / 2 - this.solHasNoLeaderboard.width / 2)

        this.modalContainer.addChild(title)
        this.modalContainer.addChild(this.groupWrapperContainer)
        this.modalContainer.addChild(sep1)
        this.modalContainer.addChild(sep2)
        this.modalContainer.addChild(sep3)
        this.modalContainer.addChild(sep4)
        this.modalContainer.addChild(sep4)
        this.modalContainer.addChild(youWrapper)
        this.modalContainer.addChild(youText)
        this.modalContainer.addChild(rank)
        this.modalContainer.addChild(miner)
        this.modalContainer.addChild(score)
        this.modalContainer.addChild(this.shipNotInCompetition)
        this.modalContainer.addChild(this.solHasNoLeaderboard)
        this.modalContainer.addChild(this.sessionNumber)
        this.modalContainer.addChild(this.prevButton)
        this.modalContainer.addChild(this.nextButton)
        this.groupWrapperContainer.addChild(this.groupContainer)
        this.setPositions()

        this.modalContainer.alpha = 0
        this.bg.alpha = 0
        this.scrollContainer.wrapper.y = sep4.y + 1
        this.scrollContainer.updateHeight(this.modalHeight - sep4.y - 1)
        this.scrollContainer.scrollIndicator.tint = Colors.Blue800

        let lastScroll = 0
        this.scrollContainer.onScrolled = (y, v) => {
            const newScroll = Math.floor(y / 20)
            if (lastScroll != newScroll) {
                lastScroll = newScroll
                this.handleScrollRecycle(Math.abs(y))
            }
        }

        PlayerService.getInstance().setOnCurrentPlayerChange(miner => {
            this.checkCurrentShipRank()
        })
    }

    public tick(): void {
        this.scrollContainer.tick()
    }

    public loadPrevious() {
        this.refresh(this.currentSession - 1)
    }

    public loadNext() {
        this.refresh(this.currentSession + 1)
    }

    public refresh(session = -1) {
        this.currentLeaderboard = []
        this.currentLeaderboardComponents = []
        this.currentShipLine?.destroy({ children: true })
        this.scrollContainer.removeChildren()
        this.selectBlockInterval(session)

        const reward = this.rewards.get(Number(MapService.getInstance().currentSolarSystemId))
        this.rewardComponent?.destroy()
        if (reward) {
            this.rewardComponent = new RewardComponent(reward)
            this.container.addChild(this.rewardComponent)
            this.onResize()
            this.rewardComponent.enterAnimation(() => {
                return
            })
        } else {
            this.rewardComponent?.destroy()
            this.rewardComponent = undefined
        }
    }

    public async selectBlockInterval(session: number) {
        this.solHasNoLeaderboard.visible = false
        this.loader.visible = true
        this.currentLeaderboard = await this.getLeaderboardData(session)
        this.loader.visible = false
        let currY = 0
        this.scrollContainer.removeChildren()
        this.currentLeaderboard.forEach((it, i) => {
            const lineComponent = new LeaderboardLineComponent(it, i + 1)
            this.currentLeaderboardComponents.push(lineComponent)
            lineComponent.y = currY
            currY += 20
        })
        const bg = PIXI.Sprite.from('pixel')
        bg.x = 1
        bg.width = this.modalWidth - 2
        bg.height = this.currentLeaderboard.length * 20
        bg.tint = Colors.Blue400
        this.scrollContainer.addChild(bg)

        this.handleScrollRecycle(0)
        this.checkCurrentShipRank()
    }

    public async getLeaderboardData(session: number) {
        const solarSystemID = MapService.getInstance().currentSolarSystemId
        const maxSession = await RankingSessionService.getInstance().lastSession()

        if (session == -1) {
            this.currentSession = maxSession
        } else {
            this.currentSession = session
        }

        if (this.currentSession >= 0) {
            if (this.currentSession == maxSession) {
                this.nextButton.visible = false
            } else {
                this.nextButton.visible = true
            }

            if (this.currentSession == 0) {
                this.prevButton.visible = false
            } else {
                this.prevButton.visible = true
            }

            this.sessionNumber.text = 'Session ' + (this.currentSession + 1) + '/' + (maxSession + 1)
            this.onResize()

            const startBlock = RankingSessionService.getInstance().startBlockFor(this.currentSession)
            const endBlock = this.currentSession == maxSession ? null : RankingSessionService.getInstance().endBlockFor(this.currentSession)

            const sorted = await RankingSessionService.getInstance().loadSession(solarSystemID, startBlock, endBlock)

            const models: Array<string> = uniq(sorted.map((ship: any) => new BigNumber(ship.ship).dividedToIntegerBy('1000000').toString()))

            const metadataByModel = new Map<string, any>(
                (await Promise.all(
                    models.map(async model => {
                        const metadata = await NFTService.getInstance().getModelMetadata(model.toString())
                        return [model, metadata]
                    })
                )) as Array<[string, string]>
            )

            const data = sorted.map(async (ship: any) => {
                const model = new BigNumber(ship.ship).dividedToIntegerBy('1000000').toString()
                const serial = new BigNumber(ship.ship).modulo('1000000')
                const metadata = metadataByModel.get(model)
                const name = `${metadata.name} - ${serial.toNumber() + 1}`
                return new LeaderboardLine(ship.owner, ship.ship, ship.score, name)
            })

            return (await Promise.all(data)) as LeaderboardLine[]
        } else {
            this.sessionNumber.text = 'Not yet started'
            return []
        }
    }

    private handleScrollRecycle(y: number) {
        const i = Math.floor(y / 20)
        const l = this.currentLeaderboardComponents.length

        for (let index = i; index < i + 10; index++) {
            const element = this.currentLeaderboardComponents[index]
            if (element) {
                this.scrollContainer.addChild(element)
            }
        }

        if (i > 2) {
            this.scrollContainer.removeChild(this.currentLeaderboardComponents[i - 2])
        }
        if (i > 1) {
            this.scrollContainer.removeChild(this.currentLeaderboardComponents[i - 1])
        }
        if (i < l - 11) {
            this.scrollContainer.removeChild(this.currentLeaderboardComponents[i + 10])
        }
        if (i < l - 12) {
            this.scrollContainer.removeChild(this.currentLeaderboardComponents[i + 11])
        }

        if (this.scrollContainer.children.length > 15) {
            this.scrollContainer.children.forEach((elem, index) => {
                if (index == 0) return

                if (i < index || i > index + 10) {
                    this.scrollContainer.removeChild(elem)
                }
            })
        }
    }

    private checkCurrentShipRank() {
        this.currentShipLine?.destroy({ children: true })
        const foundLine = this.currentLeaderboard.find(
            it => it.ship == PlayerService.getInstance().miner.id && it.owner == PlayerService.getInstance().miner.owner.toLowerCase()
        )
        if (foundLine) {
            this.currentShipLine = new LeaderboardLineComponent(foundLine, this.currentLeaderboard.indexOf(foundLine) + 1)
            this.currentShipLine.y = 46
            this.modalContainer.addChildAt(this.currentShipLine, 3)
            this.shipNotInCompetition.visible = false
        } else {
            this.shipNotInCompetition.visible = true
        }
    }

    async exitAnimation(callback: () => void): Promise<void> {
        this.rewardComponent?.exitAnimation(() => {
            return
        })
        super.exitAnimation(callback)
    }
    async enterAnimation(callback: () => void): Promise<void> {
        this.rewardComponent?.enterAnimation(() => {
            return
        })
        super.enterAnimation(callback)
    }

    onResize() {
        super.onResize()
        this.modalContainer.position.x = Math.round(Resolution.realWidth - this.modalWidth * Resolution.scale - 63 * Resolution.scale)
        this.modalContainer.position.y = Math.round(Resolution.realHeight - this.modalHeight * Resolution.scale - 39 * Resolution.scale)
        this.sessionNumber.x = Math.floor((this.modalWidth - this.sessionNumber.width) / 2)

        if (this.rewardComponent) {
            this.rewardComponent.scale.set(Resolution.scale)
            this.rewardComponent.x = this.modalContainer.x - this.rewardComponent.modalWidth * Resolution.scale - Resolution.margin2 * 4
            this.rewardComponent.y = this.modalContainer.y
        }
    }
}
