import { Transaction } from '@/game/models/Transaction'

export function cutNumber(number: string, length = 3): string {
    const indexOfNon0 = Math.min(...[1, 2, 3, 4, 5, 6, 7, 8, 9].map(it => number.indexOf(it.toString())).filter(it => it > 0))

    if (indexOfNon0 > 0 && number.length > 5 + length) {
        return number.substring(0, indexOfNon0 + length) + '...'
    } else {
        return number
    }
}

export function getFormatedHash(hash: string, length = 6) {
    return hash.substr(0, length + 2) + '...' + hash.substr(length - length, length)
}

export function getFormatedTx(transaction: Transaction) {
    return getFormatedHash(transaction.hash, 6)
}

export function cutText(txt: string, length: number) {
    return txt.length > length ? txt.substr(0, length - 3) + '...' : txt
}

export function autoNewLine(str: string, length: number) {
    const cutted = str.split(' ')
    let currLineLength = 0
    return cutted
        .reduce((prev, curr) => {
            let connector = ' '
            if (currLineLength + curr.length > length) {
                connector = '\n'
                currLineLength = curr.length
            } else {
                connector = ' '
                currLineLength += curr.length + 1
            }
            return prev + connector + curr
        }, '')
        .trim()
}
