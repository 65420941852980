import { Notification } from '../models/notification/Notification'
import { Transaction } from '../models/Transaction'
import { TransactionNotification } from '../models/notification/TransactionNotification'
import SessionTransactionService from './SessionTransactionService'

export default class NotificationService {
    public static instance: NotificationService

    public static getInstance(): NotificationService {
        if (!this.instance) {
            this.instance = new NotificationService()
        }

        return this.instance
    }

    private newPostedNotification?: Notification
    public notifications: Notification[] = []

    public setOnNewNotification(callback: (message: Notification) => void) {
        document.addEventListener('onNotification', () => {
            callback(this.newPostedNotification!)
            this.newPostedNotification = undefined
        })
    }

    public removeOnNewNotification(callback: (message: Notification) => void) {
        document.removeEventListener('onNotification' as any, callback)
    }

    public setOnNotificationUpdated(callback: () => void) {
        document.addEventListener('onNotificationUpdated', () => {
            callback()
        })
    }

    public dispatchNotificationUpdated() {
        document.dispatchEvent(new Event('onNotificationUpdated'))
    }

    public add(notif: Notification): Notification {
        this.newPostedNotification = notif
        this.notifications.push(this.newPostedNotification)
        if (this.notifications.length > 6) {
            this.notifications.splice(0, 1)
        }
        document.dispatchEvent(new Event('onNotification'))
        return notif
    }

    public addFromTransaction(
        tx: Transaction,
        onNotificationSuccess = () => {
            return
        }
    ): Notification {
        const notif = new TransactionNotification(tx, onNotificationSuccess)
        SessionTransactionService.getInstance().addTx(notif)
        return this.add(notif)
    }
}
