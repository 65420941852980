export default class Utils {
    public static degreesToRadians(degrees: number): number {
        const pi = Math.PI
        return degrees * (pi / 180)
    }

    public static radiansToDegrees(radian: number): number {
        const pi = Math.PI
        return radian * (180 / pi)
    }
}
