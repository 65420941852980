import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import Miner from '../models/Miner'

export default class MinerPlayerComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public bodyContainer: PIXI.Container = new PIXI.Container()
    public body = PIXI.AnimatedSprite.from('pixel') as PIXI.AnimatedSprite
    public bodyShadow = PIXI.AnimatedSprite.from('pixel') as PIXI.AnimatedSprite
    public extra = PIXI.AnimatedSprite.from('pixel') as PIXI.AnimatedSprite
    public extraShadow = PIXI.AnimatedSprite.from('pixel') as PIXI.AnimatedSprite
    public width = this.body.width
    public height = this.body.height

    public miner: Miner

    // to test a collection, use Math.floor(firstCollectionId + Math.random() * 5)
    private randomId = Math.floor(41 + Math.random() * 4)

    constructor(miner: Miner, public animating = true) {
        this.container.addChild(this.bodyContainer)
        this.bodyContainer.addChild(this.body)
        this.bodyContainer.addChild(this.bodyShadow)

        this.miner = miner
        this.display()
    }

    public update(miner: Miner) {
        this.miner = miner
        this.display()
    }

    public display() {
        this.body.destroy()
        this.bodyShadow.destroy()
        this.extra.destroy()
        this.extraShadow.destroy()
        this.body = this.fromTag('BodyColor')
        this.body.tint = 0x289fdb
        this.bodyShadow = this.fromTag('BodyShadow')

        this.extra = this.fromTag('ExtraColor')
        this.extraShadow = this.fromTag('ExtraShadow')
        this.extra.tint = 0xffbf00
        if (this.animating) {
            this.extra.animationSpeed = 0.2
            this.extraShadow.animationSpeed = 0.2
            this.extra.play()
            this.extraShadow.play()
        }

        this.bodyContainer.addChild(this.body)
        this.bodyContainer.addChild(this.bodyShadow)
        this.bodyContainer.addChild(this.extra)
        this.bodyContainer.addChild(this.extraShadow)

        this.onResize()
        this.updatePrimaryColor()
        this.updateExtraColor()
    }

    async updatePrimaryColor() {
        this.body.tint = await this.miner.primaryColor()
    }

    async updateExtraColor() {
        this.extra.tint = await this.miner.extraColor()
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        // nothing
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    async enterAnimation(callback: () => void): Promise<void> {
        return await callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        return await callback()
    }

    private fromTag(tag: string): PIXI.AnimatedSprite {
        return PIXI.AnimatedSprite.fromFrames(
            Object.keys(PIXI.Loader.shared.resources[`${this.minerId}_miner`].data.frames).filter((a: string) => {
                return a.indexOf(tag) >= 0
            })
        )
    }

    get minerId() {
        // use  this.randomId for debug
        return this.miner.modelId()
    }
}
