import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import Resolution from '@/helpers/Resolution'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import Colors from '@/helpers/Colors'
import { animate } from '@/helpers/AnimationHelper'
import Comete from '../models/Comete'
import StakingComet from '../models/StakingComet'
import CometComponent from './CometComponent'
import Web3 from 'web3'
import Web3Service from '../services/Web3Service'
import { GlowFilter } from '@pixi/filter-glow'
import Miner from '../models/Miner'
import StakedCometShipListComponent from './StakedCometShipListComponent'
import { addTooltip, TooltipPosition } from '@/helpers/TooltipHelper'
import PlayerService from '../services/PlayerService'
import BN from 'bn.js'
import TimeService from '../services/TimeService'
import { cutNumber } from '@/helpers/StringHelper'
import WalletService from '../services/web3/WalletService'

export default class CometPreviewComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public rewContainer: PIXI.Container = new PIXI.Container()
    public secondRowContainer: PIXI.Container = new PIXI.Container()
    public cometStackContainer: PIXI.Container = new PIXI.Container()
    public mask = PIXI.Sprite.from('preview_mask')
    public circle = PIXI.Sprite.from('preview_circle')
    public tokenText = new MainBitmapText('...', { fontSize: 5 })
    public totalRewardTitle = new MainBitmapText('Rwd', { fontSize: 4 })
    public miningReward = new MainBitmapText('...', { fontSize: 4 })
    public rwd$Title = new MainBitmapText('Rwd$', { fontSize: 4 })
    public rwd$ = new MainBitmapText('...$', { fontSize: 4 })
    public totalRemainTitle = new MainBitmapText('Rmn', { fontSize: 4 })
    public allShipsMinedTitle = new MainBitmapText('Claimable (all)', { fontSize: 4 })
    public allShipsMinedValue = new MainBitmapText('0', { fontSize: 4 })
    public totalRemainText = new MainBitmapText('...', { fontSize: 4 })
    public coord = new MainBitmapText('', { fontSize: 4 })
    public closeBtn = PIXI.Sprite.from('ic_preview_close')
    public linkIcon = PIXI.Sprite.from('ic_link')
    public stakedCometMinerList = new StakedCometShipListComponent()

    public collectPercBar = PIXI.Sprite.from('comet_stack_rover_count_bar')
    public slotsBar = PIXI.Sprite.from('comet_stack_slots_bar')
    public gainBar = PIXI.Sprite.from('comet_stack_gain_bar')
    public collectPercBarText = new MainBitmapText('...', { fontSize: 4 })
    public slotsBarText = new MainBitmapText('...', { fontSize: 4 })
    public gainBarText = new MainBitmapText('...', { fontSize: 4 })

    public onRequireClose: () => void = () => {
        return
    }
    public previewBtn = PIXI.Sprite.from('ic_preview_more')
    public onRequirePreview: (comet: Comete | StakingComet) => void = () => {
        return
    }
    public onExitPreview: () => void = () => {
        return
    }

    public comet?: Comete | StakingComet
    public cometComponent = new CometComponent(new Comete({ id: 0 }))

    constructor() {
        this.circle = PIXI.Sprite.from('preview_circle')
        this.circle.x = 111
        this.mask.x = 112
        this.mask.y = 1
        const bar = PIXI.Sprite.from('preview_cometh_bar')

        this.cometComponent.getContainer().scale.set(1 / 3 / 2)
        this.cometComponent.getContainer().position.x = Math.floor(this.circle.x + 24 - this.cometComponent.getContainer().width / 2)
        this.cometComponent.getContainer().position.y = Math.floor(this.circle.y + 24 - this.cometComponent.getContainer().height / 2)

        this.linkIcon = PIXI.Sprite.from('ic_link')
        this.linkIcon.scale.set(1 / 3)
        this.linkIcon.position.x = 106 - this.linkIcon.width

        this.collectPercBar.y = 44
        this.collectPercBar.x = 5
        this.collectPercBar.tint = Colors.Blue800
        this.slotsBar.y = this.collectPercBar.y
        this.slotsBar.x = this.collectPercBar.x + this.collectPercBar.width + 1
        this.gainBar.y = this.slotsBar.y
        this.gainBar.x = this.slotsBar.x + this.slotsBar.width + 1

        this.collectPercBar.interactive = true
        this.slotsBar.interactive = true
        this.gainBar.interactive = true
        addTooltip(this.collectPercBar, this.cometStackContainer, 'Collectable / Remain ratio', TooltipPosition.Top)
        addTooltip(this.slotsBar, this.cometStackContainer, 'Comet rover slots', TooltipPosition.Top)
        addTooltip(this.gainBar, this.cometStackContainer, 'Total potential collected gain', TooltipPosition.Top)

        this.totalRewardTitle.tint = Colors.Blue800
        this.totalRemainTitle.tint = Colors.Blue800
        this.allShipsMinedTitle.tint = Colors.Blue800
        this.rwd$Title.tint = Colors.Gold
        this.container.addChild(this.circle)
        this.container.addChild(bar)
        this.container.addChild(this.linkIcon)
        this.container.addChild(this.tokenText)
        this.rewContainer.addChild(this.totalRewardTitle)
        this.rewContainer.addChild(this.miningReward)
        this.rewContainer.addChild(this.rwd$Title)
        this.rewContainer.addChild(this.rwd$)
        this.secondRowContainer.addChild(this.allShipsMinedTitle)
        this.secondRowContainer.addChild(this.allShipsMinedValue)
        this.secondRowContainer.addChild(this.totalRemainTitle)
        this.secondRowContainer.addChild(this.totalRemainText)
        this.container.addChild(this.rewContainer)
        this.container.addChild(this.secondRowContainer)
        this.container.addChild(this.coord)
        this.container.addChild(this.mask)
        this.container.addChild(this.cometComponent.getContainer())
        this.cometComponent.getContainer().mask = this.mask
        this.container.addChild(this.closeBtn)
        this.handleCloseBtn()
        this.container.addChild(this.previewBtn)
        this.handlePreview()

        this.stakedCometMinerList.y = 55
        this.cometStackContainer.addChild(this.collectPercBar)
        this.cometStackContainer.addChild(this.slotsBar)
        this.cometStackContainer.addChild(this.gainBar)
        this.cometStackContainer.addChild(this.collectPercBarText)
        this.cometStackContainer.addChild(this.slotsBarText)
        this.cometStackContainer.addChild(this.gainBarText)
        this.container.addChild(this.cometStackContainer)
        this.cometStackContainer.addChild(this.stakedCometMinerList)

        this.container.alpha = 0
        this.container.visible = false
        this.refreshData()

        document.addEventListener('serverPostTick', () => {
            this.refreshCoords()
            this.refreshStakingComet()
            this.refreshStackPaginer()
        })
        this.onResize()
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        this.container.scale.set(Resolution.scale)

        this.tokenText.x = 100 - this.tokenText.textWidth
        this.tokenText.y = 13
        this.linkIcon.position.y = 13
        this.rewContainer.y = 20
        this.secondRowContainer.y = 26

        this.totalRewardTitle.x = 0
        this.miningReward.x = this.totalRewardTitle.x + this.totalRewardTitle.width + 2
        this.rwd$Title.x = this.miningReward.x + this.miningReward.width + 2
        this.rwd$.x = this.rwd$Title.x + this.rwd$Title.width + 2

        this.allShipsMinedValue.x = 0
        this.allShipsMinedTitle.x = this.allShipsMinedValue.x - this.allShipsMinedTitle.textWidth - 2
        this.totalRemainTitle.x = this.allShipsMinedValue.x + this.allShipsMinedValue.width + 2
        this.totalRemainText.x = this.totalRemainTitle.x + this.totalRemainTitle.width + 2

        this.collectPercBarText.y = 46
        this.collectPercBarText.x = Math.floor(23 - this.collectPercBarText.width / 2)
        this.slotsBarText.y = 46
        this.slotsBarText.x = Math.floor(56 - this.slotsBarText.width / 2)
        this.gainBarText.y = 46
        this.gainBarText.x = Math.floor(100 - this.gainBarText.width / 2)

        this.rewContainer.x = 108 - this.rewContainer.width
        this.secondRowContainer.x = 108 - (this.totalRemainText.x + this.totalRemainText.width)
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    async enterAnimation(callback: () => void): Promise<void> {
        if (this.container.alpha == 1) return
        this.container.visible = true
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.alpha = perc
            this.container.y = 0 + Resolution.margin6 * perc
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        if (this.container.alpha < 1) return
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.alpha = 1 - perc
            this.container.y = Resolution.margin6 - Resolution.margin6 * perc
        })
        this.container.visible = false
        callback()
    }

    public update(comet?: Comete | StakingComet) {
        this.comet = comet
        if (comet) {
            // this.cometComponent.update(miner)
            this.cometComponent.update()
            this.refreshData()

            if (comet instanceof StakingComet) {
                this.stakedCometMinerList.updateComet(comet)
                this.totalRewardTitle.text = 'Rwd/h'
                this.rwd$Title.text = 'Rwd$/h'
                this.allShipsMinedTitle.visible = true
                this.allShipsMinedValue.visible = true
            } else {
                this.totalRewardTitle.text = 'Rwd'
                this.rwd$Title.text = 'Rwd$'
                this.allShipsMinedTitle.visible = false
                this.allShipsMinedValue.visible = false
            }

            this.onResize()
        }
    }

    private async refreshData() {
        this.tokenText.text = 'Loading cometh data ...'
        this.miningReward.text = '...'
        this.totalRemainText.text = '...'
        this.onResize()
        if (this.comet != undefined) {
            this.refreshCoords()

            if (this.comet instanceof StakingComet) {
                this.cometStackContainer.visible = true
                this.cometComponent.body.filters = [new GlowFilter({ color: Colors.Green500, outerStrength: 2 })]
            } else {
                this.cometComponent.body.filters = []
                this.cometStackContainer.visible = false
            }

            this.tokenText.text = this.comet.symbol + ' Comet'
            this.putLinkOnToken()
            this.onResize()
            this.totalRemainText.text = cutNumber(Web3.utils.fromWei(this.comet.totalReward(), 'ether'), 5)
            this.onResize()
            const reward = Number(Web3.utils.fromWei(this.comet.miningReward(), 'ether'))
            this.miningReward.text = cutNumber(Web3.utils.fromWei(this.comet.miningReward(), 'ether'))
            this.onResize()
            this.rwd$.text = (reward * (await WalletService.getInstance().USDPriceOf(this.comet.token))).toFixed(2)
            this.onResize()
        }
    }

    private refreshCoords() {
        if (this.comet != undefined) {
            this.coord.text = `x : ${this.comet.x}   y : ${this.comet.y}   Rev : ${this.comet.revolutionDuration()}`
            this.coord.x = 111 - this.coord.width
            this.coord.y = 36

            this.onResize()
        }
    }

    private refreshStackPaginer() {
        if (this.comet instanceof StakingComet) {
            this.stakedCometMinerList.refreshData()
        }
    }

    private async refreshStakingComet() {
        if (this.comet instanceof StakingComet) {
            const collectable = this.comet.liveCollectable()
            this.allShipsMinedValue.text = cutNumber(Web3.utils.fromWei(collectable, 'ether'), 5)
            this.slotsBarText.text = this.comet.roverCount + '/' + this.comet.capacity

            const collectablePercentage = this.comet.collectablePercentage()
            this.collectPercBarText.text = collectablePercentage.toString()

            if (this.comet.collectablePercentage() >= 100) {
                this.collectPercBar.tint = Colors.Red500
            } else if (this.comet.collectablePercentage() >= 75) {
                this.collectPercBar.tint = Colors.Orange500
            } else {
                this.collectPercBar.tint = Colors.Blue800
            }

            let totalGain = new BN(0)
            const miners = this.stakedCometMinerList.miners
            for (let i = 0; i < miners.length; i++) {
                const minerItem = miners[i]
                if (minerItem.depositTime > 0) {
                    const totalMinerGain = this.comet.rate
                        .muln(miners[i].miner.roverPower)
                        .muln(Math.floor(TimeService.getInstance().now()) - minerItem.depositTime)
                        .divn(100)
                    totalGain = totalGain.add(totalMinerGain)
                }
            }
            this.gainBarText.text = cutNumber(Web3.utils.fromWei(totalGain, 'ether'))

            this.onResize()
        }
    }

    async putLinkOnToken() {
        if (this.comet) {
            if (this.comet.token == '0x0000000000000000000000000000000000000000') {
                return
            }

            this.tokenText.hitArea = new PIXI.Rectangle(0, 0, this.tokenText.width + 6, this.tokenText.height)
            this.tokenText.interactive = true
            this.tokenText.cursor = 'pointer'
            this.tokenText.on('pointertap', async () => {
                Web3Service.getInstance().openTokenScan(this.comet!.token)
            })
        }
    }

    private handleCloseBtn() {
        this.closeBtn.x = 146
        this.closeBtn.interactive = true
        this.closeBtn.cursor = 'pointer'
        this.closeBtn.on('mouseover', () => {
            this.closeBtn.texture = PIXI.Texture.from('ic_preview_close_hover')
        })
        this.closeBtn.on('mouseout', () => {
            this.closeBtn.texture = PIXI.Texture.from('ic_preview_close')
        })
        this.closeBtn.on('pointertap', () => {
            this.onRequireClose()
        })
    }

    private handlePreview() {
        this.previewBtn.x = 146
        this.previewBtn.y = 34
        this.previewBtn.interactive = true
        this.previewBtn.cursor = 'pointer'
        this.previewBtn.on('mouseover', () => {
            this.previewBtn.texture = PIXI.Texture.from('ic_preview_more_hover')
            if (this.container.alpha == 1) this.onRequirePreview(this.comet!)
        })
        this.previewBtn.on('mouseout', () => {
            this.previewBtn.texture = PIXI.Texture.from('ic_preview_more')
            this.onExitPreview()
        })
    }
}
