import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'

export default class GAnimatedSprite extends PIXI.AnimatedSprite {
    constructor(textureName: string) {
        super(
            (PIXI.Loader.shared.resources[textureName] as any).spritesheet!._frameKeys.map((a: string) => {
                return PIXI.Texture.from(a)
            })
        )
        this.anchor.set(0.5, 0.5)
        this.scale.set(Resolution.scale, Resolution.scale)

        document.addEventListener('onScaleChange', this.onScaleChange)
    }

    setX(x: number) {
        this.position.x = Math.floor(x)
    }

    setY(y: number) {
        this.position.y = Math.floor(y)
    }

    destroy() {
        document.removeEventListener('onScaleChange', this.onScaleChange)
        super.destroy()
    }

    onScaleChange = () => {
        this.scale.set(Resolution.scale, Resolution.scale)
    }
}
