
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        href: String,
        isActive: Boolean
    }
})
export default class NavLink extends Vue {}
