<template>
    <!-- hero -->
    <div class="relative mobile:pt-44 mobile:px-4">
        <img class="mobile:hidden absolute inset-0 w-full h-full object-cover" src="@/assets/tournament/bg_hero.png" />
        <img
            class="
                hidden
                mobile:inline
                absolute
                inset-0
                w-full
                h-full
                object-cover
            "
            src="@/assets/tournament/bg_hero_mobile.png"
        />
        <div class="relative flex flex-col items-center">
            <h1
                class="
                    font-display font-medium
                    text-[90px]
                    mobile:text-[60px]
                    leading-[80px]
                    mobile:leading-[50px]
                    uppercase
                    text-white text-center
                "
            >
                Polygon Galactic Grand Prix<br />
            </h1>
            <span
                class="
                    mt-1
                    text-purple text-2xl
                    leading-[26px]
                    uppercase
                    font-display font-semibold
                "
                >By Cometh</span
            >
            <p
                class="
                    mt-4
                    text-lg
                    mobile:text-base
                    leading-7
                    mobile:leading-6
                    font-medium
                    text-center text-white
                "
            >
                Line up your spaceships during the galaxy's biggest competition.<br />The 1st blockchain eSport event
            </p>

            <!-- countdown -->
            <div class="mt-8 text-white">
                <vue-countdown :time="time" :transform="transformSlotProps" v-slot="{ days, hours, minutes, seconds }">
                    <div class="flex space-x-20 mobile:space-x-10">
                        <div class="text-center">
                            <div
                                class="
                                    font-pixel
                                    text-[60px]
                                    mobile:text-[50px]
                                    leading-[60px]
                                    mobile:leading-[50px]
                                "
                            >
                                {{ days }}
                            </div>
                            <div
                                class="
                                    mt-1.5
                                    uppercase
                                    font-medium
                                    text-sm
                                    mobile:text-xs
                                    leading-[18px]
                                "
                            >
                                days
                            </div>
                        </div>
                        <div class="text-center">
                            <div
                                class="
                                    font-pixel
                                    text-[60px]
                                    mobile:text-[50px]
                                    leading-[60px]
                                    mobile:leading-[50px]
                                "
                            >
                                {{ hours }}
                            </div>
                            <div
                                class="
                                    mt-1.5
                                    uppercase
                                    font-medium
                                    text-sm
                                    mobile:text-xs
                                    leading-[18px]
                                "
                            >
                                hours
                            </div>
                        </div>
                        <div class="text-center">
                            <div
                                class="
                                    font-pixel
                                    text-[60px]
                                    mobile:text-[50px]
                                    leading-[60px]
                                    mobile:leading-[50px]
                                "
                            >
                                {{ minutes }}
                            </div>
                            <div
                                class="
                                    mt-1.5
                                    uppercase
                                    font-medium
                                    text-sm
                                    mobile:text-xs
                                    leading-[18px]
                                "
                            >
                                minutes
                            </div>
                        </div>
                        <div class="text-center">
                            <div
                                class="
                                    font-pixel
                                    text-[60px]
                                    mobile:text-[50px]
                                    leading-[60px]
                                    mobile:leading-[50px]
                                "
                            >
                                {{ seconds }}
                            </div>
                            <div
                                class="
                                    mt-1.5
                                    uppercase
                                    font-medium
                                    text-sm
                                    mobile:text-xs
                                    leading-[18px]
                                "
                            >
                                seconds
                            </div>
                        </div>
                    </div>
                </vue-countdown>
            </div>
            <!-- end countdown -->

            <div class="flex mobile:flex-col mt-10 mobile:mt-12">
                <!--
                <ButtonPrimary class="w-[168px] mobile:w-[200px] h-[60px]">
                    Subscribe
                </ButtonPrimary>
                -->
                <ButtonPrimary @click="$emit('buy')" class="w-[200px] h-[60px] ml-5 mobile:ml-0 mobile:mt-6">
                    Play
                </ButtonPrimary>
            </div>
            <div class="mt-2 mobile:mt-[78px] w-full max-w-[1170px] mx-auto flex">
                <div
                    class="
                        flex
                        items-center
                        justify-center
                        w-[210px]
                        h-[66px]
                        self-start
                    "
                >
                    <img src="@/assets/tournament/logo_polygon.png" alt="Polygon" />
                </div>
            </div>
        </div>
    </div>
    <!-- end hero -->
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import VueCountdown from '@chenfengyuan/vue-countdown'
import ButtonPrimary from '@/components/ButtonPrimary.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'

@Options({
    components: { VueCountdown, ButtonPrimary, ButtonSecondary }
})
export default class PolygonCup extends Vue {
    get time() {
        const utc = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getTime()
        return new Date("July 17, 2021 13:00:00").getTime() - utc
    }

    transformSlotProps(props: any) {
        return {
            days: props.days.toString().padStart(2, '0'),
            hours: props.hours.toString().padStart(2, '0'),
            minutes: props.minutes.toString().padStart(2, '0'),
            seconds: props.seconds.toString().padStart(2, '0')
        }
    }
}
</script>

<style scoped></style>
