import Item from '@/game/models/Items'
import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import * as PIXI from 'pixi.js'

export default class ItemInfosComponent extends PIXI.Container {
    public onExit: () => void = () => {
        return
    }
    protected bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected modalContainer: PIXI.Container = new PIXI.Container()
    protected top: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected left: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected right: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected bottom: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected topTitle: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected rightTitle: PIXI.Sprite = PIXI.Sprite.from('pixel')

    protected modalBg: PIXI.Sprite = PIXI.Sprite.from('pixel')

    constructor(item: Item) {
        super()

        this.bg.tint = 0x00000
        this.bg.alpha = 0.5
        this.bg.interactive = true
        this.bg.cursor = 'pointer'
        this.bg.on('pointertap', () => {
            this.onExit()
        })

        this.addChild(this.bg)

        const modalWidth = 95

        const itemIcon = PIXI.Sprite.from(item.icon)
        itemIcon.x = 0
        itemIcon.y = 0

        const topStart = 13

        this.top.y = topStart
        this.top.x = 5
        this.top.width = modalWidth - 5
        this.top.tint = 0x49bfff

        const infosText = new MainBitmapText(item.description, {
            fontSize: 4
        })

        infosText.position.x = 10
        infosText.position.y = topStart + 6

        let modalHeight = 0
        modalHeight = infosText.position.y + infosText.height + 6

        this.bottom.width = modalWidth - 6
        this.bottom.x = 5
        this.bottom.position.y = modalHeight
        this.bottom.tint = 0x49bfff

        this.left.x = 4
        this.left.y = topStart + 1
        this.left.height = modalHeight - this.left.y
        this.left.tint = 0x49bfff

        this.right.y = topStart + 1
        this.right.height = modalHeight - this.right.y
        this.right.position.x = modalWidth - 1
        this.right.tint = 0x49bfff

        this.topTitle.y = 4
        this.topTitle.x = 5
        this.topTitle.width = modalWidth - 5
        this.topTitle.tint = 0x49bfff

        this.rightTitle.y = 5
        this.rightTitle.height = 8
        this.rightTitle.position.x = modalWidth
        this.rightTitle.tint = 0x49bfff

        this.modalBg.y = 5
        this.modalBg.x = 5
        this.modalBg.height = modalHeight - 5
        this.modalBg.width = modalWidth - 5
        this.modalBg.tint = 0x103861
        this.modalBg.interactive = true

        const titleText = new MainBitmapText(item.name, {
            fontSize: 5
        })

        titleText.position.x = 18
        titleText.position.y = 6

        this.modalContainer.addChild(this.modalBg)
        this.modalContainer.addChild(this.top)
        this.modalContainer.addChild(this.left)
        this.modalContainer.addChild(this.right)
        this.modalContainer.addChild(this.bottom)
        this.modalContainer.addChild(this.rightTitle)
        this.modalContainer.addChild(this.topTitle)
        this.modalContainer.addChild(this.topTitle)
        this.modalContainer.addChild(infosText)
        this.modalContainer.addChild(itemIcon)
        this.modalContainer.addChild(titleText)
        this.addChild(this.modalContainer)
    }
}
