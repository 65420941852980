<template>
    <div class="fixed inset-0 bg-gray-100 overflow-y-auto">
        <Header ref="header" v-on:rampPurchaseSuccessful="onRampPurchased" />
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="bg-gray-100 h-full">
            <div class="pt-12 sm:pt-16 lg:pt-20">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="text-center">
                        <div class="max-w-sm mx-auto flex flex-col items-center mb-4">
                            <img class="w-32" src="@/assets/logo.png" />
                        </div>
                        <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Starter Kit</h2>
                        <p class="mt-4 text-xl text-gray-600">The easiest and fastest way to play.</p>
                    </div>
                </div>
            </div>
            <div class="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
                <div class="relative">
                    <div class="absolute inset-0 h-1/2 bg-gray-100"></div>
                    <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                            <div class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 flex flex-col items-center lg:justify-center lg:p-12">
                                <img class="object-cover h-64" :src='shopItem.imgUrl' alt="" />
                                <div class="mb-2">
                                    <ul class="flex space-x-2 text-sm text-gray-500">
                                        <li class="group relative cursor-pointer flex items-center">
                                            <div
                                                class="opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-out absolute -left-2 -top-full -mt-4 -ml-px flex space-x-2 justify-center bg-white rounded border border-gray-500 whitespace-nowrap px-2 py-1"
                                            >
                                                <svg
                                                    class="w-4 h-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0"
                                                    />
                                                </svg>
                                                <span>Mining range</span>
                                            </div>
                                            <svg
                                                class="w-4 h-4 mr-1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0"
                                                />
                                            </svg>
                                            <span>{{shopItem.miningRange}} km</span>
                                        </li>
                                        <li class="group relative cursor-pointer flex items-center">
                                            <div
                                                class="opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-out absolute -left-2 -top-full -mt-4 -ml-px flex space-x-2 justify-center bg-white rounded border border-gray-500 whitespace-nowrap px-2 py-1"
                                            >
                                                <svg
                                                    class="w-4 h-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                                                    />
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                                                    />
                                                </svg>
                                                <span>Mining power</span>
                                            </div>
                                            <svg
                                                class="w-4 h-4 mr-1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                                                />
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                                                />
                                            </svg>
                                            <span>{{shopItem.miningPower}}</span>
                                        </li>
                                        <li class="group relative cursor-pointer flex items-center">
                                            <div
                                                class="opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-out absolute right-0 -top-full -mt-4 -mr-1 flex space-x-2 justify-center bg-white rounded border border-gray-500 whitespace-nowrap px-2 py-1"
                                            >
                                                <svg
                                                    class="w-4 h-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                                <span>Cooldown</span>
                                            </div>
                                            <svg
                                                class="w-4 h-4 mr-1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                            <span>{{shopItem.coolDown}}s</span>
                                        </li>
                                    </ul>
                                </div>
                                <p>{{shopItem.desc}}</p>
                                <p class="text-xs">{{ displayedQuantityLeft }} packs left</p>
                            </div>
                            <div class="flex-1 bg-white px-6 py-8 lg:p-12">
                                <h3 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">Start mining now with The {{shopItem.name}}</h3>

                                <p class="mt-6 text-base text-gray-500">
                                    {{shopItem.description}}
                                </p>

                                <div class="mt-8">
                                    <div class="flex items-center">
                                        <h4 class="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                                            What's included
                                        </h4>
                                        <div class="flex-1 border-t-2 border-gray-200"></div>
                                    </div>
                                    <ul class="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                        <li class="flex items-start lg:col-span-1">
                                            <div class="flex-shrink-0">
                                                <!-- Heroicon name: solid/check-circle -->
                                                <svg
                                                    class="h-5 w-5 text-green-400"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                            <p class="ml-3 text-sm text-gray-700">1 Unique NFT</p>
                                        </li>

                                        <li class="flex items-start lg:col-span-1">
                                            <div class="flex-shrink-0">
                                                <!-- Heroicon name: solid/check-circle -->
                                                <svg
                                                    class="h-5 w-5 text-green-400"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                            <p class="ml-3 text-sm text-gray-700">
                                                <span v-if="displayedMustAmount">{{ displayedMustAmount }} Must for in game actions</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                v-if="isOrderComplete"
                                class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12"
                            >
                                <p class="flex items-center text-xl leading-6 font-medium text-gray-900">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-8 w-8 mr-2 text-green-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <span>Thank you!</span>
                                </p>
                                <!-- <div class="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                    <span v-if="displayedStarterKitPrice">{{ displayedStarterKitPrice }} </span>
                                    <span class="ml-3 text-xl font-medium text-gray-500"> Matic </span>
                                </div> -->
                                <p class="mt-4 text-lg font-medium text-gray-500">Jump right now into the game.</p>
                                <div class="mt-6">
                                    <div class="rounded-md shadow">
                                        <a
                                            href="/"
                                            class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600"
                                        >
                                            Play
                                        </a>
                                    </div>
                                </div>
                                <div class="mt-4 text-sm">
                                    <a href="https://youtu.be/1GA-ngxakrU?t=110" target="_blank" class="font-medium text-gray-900">
                                        Game tutorial Video
                                        <span class="font-normal text-gray-500"> (3 minutes) </span>
                                    </a>
                                </div>
                            </div>
                            <div v-else class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                                <p class="text-lg leading-6 font-medium text-gray-900">Pay once, own it forever</p>
                                <div class="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                    <span v-if="displayedStarterKitPrice">{{ displayedStarterKitPrice }} </span>
                                    <span class="ml-3 text-xl font-medium text-gray-500"> Matic </span>
                                </div>
                                <p class="mt-4 text-sm">
                                    <a href="#" class="font-medium text-gray-500 underline"> Buy Matic With your Credit Card </a>
                                </p>
                                <div class="mt-6">
                                    <div class="rounded-md shadow">
                                        <a
                                            v-if="isBuying"
                                            class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 bg-opacity-80 cursor-default"
                                        >
                                            <svg
                                                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path
                                                    class="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                            Buying...
                                        </a>

                                        <a
                                            v-else-if="isChangingConnection"
                                            class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 bg-opacity-60 cursor-default"
                                        >
                                            <svg
                                                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path
                                                    class="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                            Connecting...
                                        </a>

                                        <a
                                            v-else-if="isInsufficientBalance"
                                            class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 bg-opacity-60 cursor-default"
                                        >
                                            Get Access
                                        </a>

                                        <a
                                            v-else
                                            @click="buy"
                                            href="#"
                                            class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                                        >
                                            Get Access
                                        </a>
                                    </div>
                                    <div class="mt-6">
                                        <p v-if="isInsufficientBalance" class="text-yellow-700">
                                            ⚠️ Insufficient Balance:
                                            <button
                                                @click="buyCrypto"
                                                class="border border-gray-300 rounded-md px-2.5 py-1.5 hover:bg-gray-50 text-sm"
                                            >
                                                Buy Matic
                                            </button>
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-4 text-sm">
                                    <a href="https://youtu.be/1GA-ngxakrU?t=110" target="_blank" class="font-medium text-gray-900">
                                        Game tutorial Video
                                        <span class="font-normal text-gray-500"> (3 minutes) </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Modal :open="showConfirmationModal" @close="showConfirmationModal = false">
        <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                    class="h-6 w-6 text-yellow-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                <div class="mt-2">
                    <p class="text-sm text-gray-500">
                        Are you ready to start playing Cometh? Click buy to initiate the transaction (this action cannot be undone).
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
                @click="confirmPurchase"
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
                Buy
            </button>
            <button
                @click="showConfirmationModal = false"
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            >
                Cancel
            </button>
        </div>
    </Modal>
</template>

<script lang="ts" src="./Starterkit.ts"></script>

<style scoped>
.overlay-enter-active {
    transition: opacity ease-out 0.3s;
}
.overlay-leave-active {
    transition: opacity ease-in 0.2s;
}
.overlay-enter-from,
.overlay-leave-to {
    opacity: 0;
}
.modal-enter-active {
    transition: all ease-out 0.3s;
}
.modal-leave-active {
    transition: all ease-in 0.2s;
}
.modal-enter-from,
.modal-leave-to {
    transform: scale(0.95);
    opacity: 0;
}
</style>
