export const MULTI_TOKEN_POOL_TYPE = 'MULTI_TOKEN_POOL_TYPE'
export const ERC1155_POOL_TYPE = 'ERC1155_POOL_TYPE'

// MATIC
export default {
    data: [
        {
            id: '0',
            name: 'Cometh',
            placeHolder: 'tube_img_cometh',
            description: '',
            poolType: MULTI_TOKEN_POOL_TYPE,
            poolContractAddress: '0x1Bd8831E33F847364276D8dDdcc0334a330298C2',
            prizes: [
                {
                    id: '10',
                    img: '/assets/img/tube/items/comics-top.jpeg',
                    name: 'The Cometh Files Top',
                    description: 'First Edition [Color] - Total Supply: 50'
                },
                {
                    id: '11',
                    img: '/assets/img/tube/items/comics-middle.jpeg',
                    name: 'The Cometh Files Middle',
                    description: 'First Edition [Color] - Total Supply: 50'
                },
                {
                    id: '12',
                    img: '/assets/img/tube/items/comics-bottom.jpeg',
                    name: 'The Cometh Files Bottom',
                    description: 'First Edition [Color] - Total Supply: 50'
                }
            ],
            tokenSymbol: 'MUST'
        },
        {
            id: '1',
            name: 'Game Items',
            placeHolder: 'tube_img_items',
            poolType: ERC1155_POOL_TYPE,
            poolContractAddress: '',
            description: '',
            prizes: [
                {
                    id: '0',
                    img: '/assets/img/tube/items/cooling_booster_full.png',
                    name: 'Mining Cooler',
                    description:
                        'Cooling Down is too slow?\nUse this amazing Mining Cooler\nYour ship is ready to mine again\n in the blink of an eye. (-120sec. on cooldown) '
                }
            ],
            tokenSymbol: 'MUST'
        },
        {
            id: '3',
            name: 'Stake DAO',
            placeHolder: 'tube_img_stakedao',
            poolType: MULTI_TOKEN_POOL_TYPE,
            poolContractAddress: '0xF205183ed054Fb34A272Cbb4398359317dd7FF31',
            description: '',
            prizes: [
                {
                    id: '1',
                    img: '/assets/img/tube/items/doggo.png',
                    name: 'Dogg’O',
                    description: 'Herd - Uncommon - Total Supply: 60'
                },
                {
                    id: '2',
                    img: '/assets/img/tube/items/graff.png',
                    name: 'G-raff',
                    description: 'Herd - Rare - Total Supply: 8'
                }
            ],
            tokenSymbol: 'MUST'
        },
        {
            id: '2',
            name: 'APY Vision',
            placeHolder: 'tube_img_apyvision',
            poolType: MULTI_TOKEN_POOL_TYPE,
            poolContractAddress: '0xdCf5d5D63341CE16d9429eEc00c619B23059A025',
            description: '',
            prizes: [
                {
                    id: '1',
                    img: '/assets/img/tube/items/detos.png',
                    name: 'Detos',
                    description: 'Vysteons - Common - Total Supply: 50'
                },
                {
                    id: '2',
                    img: '/assets/img/tube/items/foresight.png',
                    name: 'Foresight',
                    description: 'Vysteons - Uncommon - Total Supply: 24'
                },
                {
                    id: '3',
                    img: '/assets/img/tube/items/annuarre.png',
                    name: 'Annuarre',
                    description: 'Vysteons - Rare - Total Supply: 4'
                }
            ],
            tokenSymbol: 'MUST'
        }
    ]
}

//MUMBAI

/*data: [
        {
            id: '0',
            name: 'Items',
            poolType: ERC1155_POOL_TYPE,
            placeHolder: 'tube_img_items',
            poolContractAddress: '',
            description: '',
            prizes: [
                {
                    id: '0',
                    address: '0xe7A68d8924AcE3Cef938aA729A0cAB5e4AF62ac7',
                    img: '/assets/img/tube/items/cooling_booster_full.png',
                    name: 'Cooling Booster',
                    description: 'Blabla iteem description...'
                }
            ],
            tokenSymbol: 'MUST'
        },
        {
            id: '1',
            name: 'Cometh',
            placeHolder: 'tube_img_cometh',
            description: '',
            poolType: MULTI_TOKEN_POOL_TYPE,
            poolContractAddress: '0xb3678b79b55d3594f6ABF09E9131823825A07Fcf',
            prizes: [
                {
                    id: '1',
                    img: '/assets/img/tube/items/consul.png',
                    name: 'Consul',
                    description: "Al'Ong Mythical ship"
                },
                {
                    id: '9',
                    img: '/assets/img/tube/items/gemini.png',
                    name: 'Gemini',
                    description: 'Asterion Rare Ship'
                },
                {
                    id: '27',
                    img: '/assets/img/tube/items/xxxx.png',
                    name: 'XXXX',
                    description: 'todo'
                }
            ],
            tokenSymbol: 'MUST'
        }
    ]
}*/
