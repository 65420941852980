import NotificationComponent from './NotificationComponent'
import Web3 from 'web3'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import TextButton from '../TextButton'
import Colors from '@/helpers/Colors'
import { PortalTravelNotification } from '@/game/models/notification/PortalTravelNotification'
import MapService from '@/game/services/MapService'
import PortalService from '@/game/services/web3/PortalService'
import GalaxyService from '@/services/GalaxyService'
import GameItemsService from '@/game/services/web3/GameItemsService'
import Item from '@/game/models/Items'
import { autoNewLine } from '@/helpers/StringHelper'
import NotificationService from '@/game/services/NotificationService'

// can travel during 3 minutes
const MAX_TRAVEL_TIME = 150000

export default class PortalTravelNotificationComponent extends NotificationComponent {
    protected minerName: MainBitmapText

    protected travelText: MainBitmapText
    protected travelPrice: MainBitmapText
    protected errorText: MainBitmapText
    protected mineButton: TextButton

    public onClick = () => {
        return
    }
    protected mainTint = Colors.Purple500
    protected secondTint = Colors.Purple100
    private isApproved = false

    constructor(public notification: PortalTravelNotification) {
        super(notification)
        this.bg.interactive = false

        this.title.text = 'Portal Encounter'
        this.title.x = 22
        this.title.y = 5
        this.onResize()
        this.closer.visible = true

        this.minerName = new MainBitmapText('', { fontSize: 4 })
        this.minerName.y = this.title.y + 7
        this.minerName.x = 6
        this.minerName.tint = this.mainTint
        this.dataContainer.addChild(this.minerName)

        const header = PIXI.Sprite.from('ic_portal')
        header.y = -6
        header.x = 4

        this.travelText = new MainBitmapText('', { fontSize: 4 })
        this.travelText.y = this.minerName.y + 7
        this.travelText.x = 6
        this.dataContainer.addChild(this.travelText)

        this.mineButton = new TextButton('TRAVEL')
        this.mineButton.btn.tint = this.mainTint
        this.mineButton.container.on('pointertap', () => {
            this.travelTouched()
        })

        this.errorText = new MainBitmapText('', { fontSize: 4, tint: Colors.Purple500 })
        this.errorText.x = this.width - this.errorText.width - 5
        this.dataContainer.addChild(this.errorText)

        this.travelPrice = new MainBitmapText('', { fontSize: 4, align: 'right' })
        this.travelPrice.x = this.mineButton.container.x - this.travelPrice.width - 3
        this.dataContainer.addChild(this.travelPrice)

        this.container.addChild(header)
        this.dataContainer.addChild(this.mineButton.container)

        setTimeout(() => {
            this.onDestroy()
        }, MAX_TRAVEL_TIME)

        document.addEventListener(GameItemsService.APPROVED_OPERATOR, () => {
            this.verifyApproval()
        })
        this.verifyApproval()
        this.tint()
        this.onResize()
        this.updateDisplay()
    }

    async travelTouched() {
        if (this.isApproved || !GalaxyService.getInstance().isStadium(this.notification.portal.toId)) {
            this.notification.miner.isTraveling = true
            PortalService.getInstance().travel(
                this.notification.miner.id,
                this.notification.portal.id,
                this.notification.portal.fromId,
                this.notification.portal.toId,
                this.notification.time
            )

            this.onDestroy()
        } else {
            GameItemsService.getInstance().setApprovedForGameItemsManager()
        }
    }

    async updateDisplay() {
        this.minerName.text = await this.notification.miner.name()
        const fromStar = MapService.getInstance().map.stars.find(a => a.id == this.notification.portal.fromId)?.name
        const toStar = MapService.getInstance().map.stars.find(a => a.id == this.notification.portal.toId)?.name

        this.travelText.text = autoNewLine(`Do you want to travel from ${fromStar} to ${toStar}`, 28)
        this.travelPrice.text = Web3.utils.fromWei(this.notification.portal.fees, 'ether') + '\nMUST'
        this.onResize()

        await this.checkTravelCondition()

        this.onResize()
        NotificationService.getInstance().dispatchNotificationUpdated()
    }

    tick(): void {
        // nothing
    }

    defineHeight() {
        this.height = this.dataContainer.height + 10
    }

    public onResize() {
        super.onResize()

        if (this.mineButton) {
            this.errorText.y = this.travelText.y + this.travelText.height + 3
            this.mineButton.container.y = this.travelText.y + this.travelText.height + 3
            this.mineButton.container.x = this.width - this.mineButton.container.width - 5
            this.travelPrice.y = this.mineButton.container.y + 1
            this.travelPrice.x = this.mineButton.container.x - this.travelPrice.width - 3
            this.errorText.x = this.width - this.errorText.width - 5
        }
    }

    async checkTravelCondition() {
        let error = ''
        const ticketCount = await GameItemsService.getInstance().balanceOf(Item.grandPrixTicket.id)
        const currentCup = GalaxyService.getInstance().getCurrentCup(this.notification.portal.toId)

        if (GalaxyService.getInstance().isStadium(this.notification.portal.toId)) {
            if (!(await GameItemsService.getInstance().isApprovedForGameItemsManager())) {
                this.mineButton.text.text = 'APPROVE'
                this.mineButton.updateButtonSize()
                this.onResize()
                return
            } else {
                this.mineButton.text.text = 'TRAVEL'
                this.mineButton.updateButtonSize()
                this.onResize()
            }

            const currentRarity = (await this.notification.miner.rarity()).toLocaleLowerCase()

            // Grand Prix need to have allowed Rarity OR a Ticket
            if (currentCup != undefined && !currentCup!.doAllowRarity(currentRarity) && ticketCount == 0) {
                error = 'You need a ticket to\ntravel to the Stadium'
            }
        }

        if (GalaxyService.getInstance().isStadium(this.notification.portal.fromId)) {
            const isCurrentShipMULE = this.notification.miner.modelId() == 1000
            // if Stadium -> NonAcademy, everyone but not mule
            if (GalaxyService.getInstance().isAcademy(this.notification.portal.toId) && !isCurrentShipMULE) {
                error = 'Non-M.U.L.E ships\nare not allowed'
            }
            // if Stadium -> Academy, MULE
            else if (!GalaxyService.getInstance().isAcademy(this.notification.portal.toId) && isCurrentShipMULE) {
                error = 'M.U.L.E are\nnot allowed'
            }
        }

        this.errorText.text = error
        if (error) {
            this.mineButton.container.visible = false
            this.travelPrice.visible = false
        } else {
            this.mineButton.container.visible = true
            this.travelPrice.visible = true
        }
    }

    public async exitAnimation(callback: () => void): Promise<void> {
        super.exitAnimation(callback)
        // TODO remove loop
    }

    async verifyApproval() {
        this.isApproved = await GameItemsService.getInstance().isApprovedForGameItemsManager()
        this.checkTravelCondition()
    }
}
