
import { Options, Vue } from 'vue-class-component'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import ButtonLink from '@/components/ButtonLink.vue'
import ButtonPrimary from '@/components/ButtonPrimary.vue'

@Options({
    components: { ButtonSecondary, ButtonLink, ButtonPrimary },
    props: {
        color: {
            type: String,
            default: 'purple'
        }
    }
})
export default class Game extends Vue {
    color!: string
}
