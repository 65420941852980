import NotificationComponent from './NotificationComponent'
import { MiningNotification } from '../../models/notification/MiningNotification'
import Web3 from 'web3'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import MiningManagerService from '../../services/web3/MiningManagerService'
import TxGasPreviewButton from '../TxGasPreviewButton'
import Colors from '@/helpers/Colors'
import WalletService from '@/game/services/web3/WalletService'

// can mine a comet during 3 minutes / adding mining time display notig for 2.5 minutes.
const MAX_MINING_TIME = 150000

export default class MiningNotificationComponent extends NotificationComponent {
    protected minerName: MainBitmapText

    public mineButton = new TxGasPreviewButton('MINE')
    protected reward: MainBitmapText
    protected reward$: MainBitmapText
    public onClick = () => {
        return
    }

    constructor(public notification: MiningNotification) {
        super(notification)
        this.bg.interactive = false

        this.title.text = 'Comet Mining'
        this.title.x = 24
        this.closer.visible = true

        this.minerName = new MainBitmapText('', { fontSize: 4 })
        this.minerName.y = this.title.y + 7
        this.minerName.x = 6
        this.minerName.tint = this.mainTint
        this.dataContainer.addChild(this.minerName)

        const header = PIXI.Sprite.from('ic_notif_mining')
        header.y = -4.3
        header.x = 4

        this.reward = new MainBitmapText('', { fontSize: 4 })
        this.reward.y = this.minerName.y + 7
        this.reward.x = 6
        this.dataContainer.addChild(this.reward)

        this.reward$ = new MainBitmapText('', { fontSize: 4, tint: Colors.Gold })
        this.reward$.y = this.reward.y + 5
        this.reward$.x = 6
        this.dataContainer.addChild(this.reward$)

        this.mineButton.y = this.reward$.y + 7
        this.mineButton.onClick = (gasPrice: number) => {
            this.mineTouched(gasPrice)
        }
        this.mineButton.onResized = () => {
            this.onResize()
        }
        this.container.addChild(header)
        this.dataContainer.addChild(this.mineButton)

        setTimeout(() => {
            this.onDestroy()
        }, MAX_MINING_TIME)

        this.onResize()
        this.updateDisplay()
    }

    mineTouched(gasPrice: number) {
        MiningManagerService.getInstance().mine(
            this.notification.miner.id,
            this.notification.comete.id,
            this.notification.time,
            this.notification.miner.solarSystemID,
            gasPrice
        )

        this.onDestroy()
    }

    async updateDisplay() {
        this.minerName.text = await this.notification.miner.name()
        const reward = Web3.utils.fromWei(this.notification.comete.miningReward(), 'ether')
        const tokenSymbol = this.notification.comete.symbol
        this.reward.text = reward + ' ' + tokenSymbol
        this.reward$.text = (Number(reward) * (await WalletService.getInstance().USDPriceOf(this.notification.comete.token))).toFixed(2) + ' $'
    }

    tick(): void {
        // nothing
    }

    defineHeight() {
        this.height = this.dataContainer.height + 10
    }

    onResize() {
        super.onResize()

        if (this.mineButton) {
            this.mineButton.x = this.width - this.mineButton.width - 6
        }
    }
}
