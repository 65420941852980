import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import Web3 from 'web3'
import { Transaction } from '@/game/models/Transaction'
import NotificationService from '../NotificationService'
import PlayerService from '../PlayerService'
import BN from 'bn.js'
import { Notification } from '@/game/models/notification/Notification'

export default class SpiceService {
    public static instance: SpiceService

    public static ALLOWANCE_CHANGED_EVENT = 'ALLOWANCE_CHANGED'

    public static getInstance(): SpiceService {
        if (!this.instance) {
            this.instance = new SpiceService()
        }
        return this.instance
    }

    private abi = [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            inputs: [],
            name: 'DECIMALS',
            outputs: [
                {
                    internalType: 'uint8',
                    name: '',
                    type: 'uint8'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'DOMAIN_SEPARATOR',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'INITIAL_SUPPLY',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'NAME',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'PERMIT_TYPEHASH',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'SYMBOL',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                }
            ],
            name: 'allowance',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'approve',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'decimals',
            outputs: [
                {
                    internalType: 'uint8',
                    name: '',
                    type: 'uint8'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'subtractedValue',
                    type: 'uint256'
                }
            ],
            name: 'decreaseAllowance',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'addedValue',
                    type: 'uint256'
                }
            ],
            name: 'increaseAllowance',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'transfer',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'transferFrom',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getChainId',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'chainId',
                    type: 'uint256'
                }
            ],
            stateMutability: 'pure',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'deadline',
                    type: 'uint256'
                },
                {
                    internalType: 'uint8',
                    name: 'v',
                    type: 'uint8'
                },
                {
                    internalType: 'bytes32',
                    name: 'r',
                    type: 'bytes32'
                },
                {
                    internalType: 'bytes32',
                    name: 's',
                    type: 'bytes32'
                }
            ],
            name: 'permit',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                }
            ],
            name: 'nonces',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'deadline',
                    type: 'uint256'
                }
            ],
            name: 'permitMessage',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        }
    ]

    private readContract: any
    private writeContract: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        this.writeContract = new web3.eth.Contract(this.abi, config.spiceAddress, { gasLimit: '1000000' })

        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readContract = new wssWeb3.eth.Contract(this.abi, config.spiceAddress)
    }

    public async getBalance(account: string) {
        return await this.readContract.methods.balanceOf(account).call()
    }

    public async getAllowance(account: string, spender: string) {
        return await this.readContract.methods.allowance(account, spender).call()
    }

    public async approveSync(spender: string, amount: number) {
        const account: string = await Web3Service.getInstance().getAccount()
        const weiPrice = Web3.utils.toWei(amount.toString(), 'ether')
        await this.writeContract.methods.approve(spender, weiPrice).send({ from: account, gasPrice: '50000000000' });
    }

    public async approve(spender: string, amount: number) {
        const account: string = await Web3Service.getInstance().getAccount()

        const weiPrice = Web3.utils.toWei(amount.toString(), 'ether')

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.writeContract.methods
                .approve(spender, weiPrice)
                .send({ from: account, gasPrice: '50000000000' })
                .on('error', (error: any) => {
                    reject(error)
                    notif.title = 'Allowance update failed.'
                    tx.onError()
                })
                .on('receipt', (data: any) => {
                    notif.title = 'Allowance updated !'
                    document.dispatchEvent(new Event(SpiceService.ALLOWANCE_CHANGED_EVENT))
                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif = NotificationService.getInstance().addFromTransaction(tx)
                    notif.title = 'Allowance update...'
                    resolve(amount)
                })
        })
    }

    public requestApprove() {
        document.dispatchEvent(new Event('onApproveRequested'))
    }

    public setOnApproveRequested(callback: () => void) {
        document.addEventListener('onApproveRequested', () => {
            callback()
        })
    }
}
