import RentingService from '@/game/services/web3/RentingService'
import StakedSpaceshipsService from '@/game/services/web3/StakedSpaceshipsService'
import SpaceShipsService from '@/game/services/web3/SpaceShipsService'
import { ShipWithGameStat, builRentedShip, State } from '@/models/ShipWithGameStat'
import Web3 from 'web3'

enum Rarity {
  MULE,
  COMMON,
  UNCOMMON,
  RARE,
  MYTHIC,
}

const RarityPerModel = new Map<number, Rarity>()
RarityPerModel.set(1, Rarity.COMMON)
RarityPerModel.set(2, Rarity.COMMON)
RarityPerModel.set(3, Rarity.UNCOMMON)
RarityPerModel.set(4, Rarity.UNCOMMON)
RarityPerModel.set(5, Rarity.RARE)
RarityPerModel.set(6, Rarity.COMMON)
RarityPerModel.set(7, Rarity.COMMON)
RarityPerModel.set(8, Rarity.UNCOMMON)
RarityPerModel.set(9, Rarity.UNCOMMON)
RarityPerModel.set(10, Rarity.RARE)
RarityPerModel.set(11, Rarity.MYTHIC)
RarityPerModel.set(12, Rarity.COMMON)
RarityPerModel.set(13, Rarity.UNCOMMON)
RarityPerModel.set(14, Rarity.RARE)
RarityPerModel.set(15, Rarity.MYTHIC)
RarityPerModel.set(16, Rarity.UNCOMMON)
RarityPerModel.set(17, Rarity.COMMON)
RarityPerModel.set(18, Rarity.UNCOMMON)
RarityPerModel.set(19, Rarity.RARE)
RarityPerModel.set(20, Rarity.MYTHIC)
RarityPerModel.set(21, Rarity.COMMON)
RarityPerModel.set(22, Rarity.UNCOMMON)
RarityPerModel.set(23, Rarity.RARE)
RarityPerModel.set(24, Rarity.MYTHIC)
RarityPerModel.set(25, Rarity.UNCOMMON)
RarityPerModel.set(26, Rarity.UNCOMMON)
RarityPerModel.set(27, Rarity.RARE)
RarityPerModel.set(28, Rarity.MYTHIC)
RarityPerModel.set(29, Rarity.UNCOMMON)
RarityPerModel.set(30, Rarity.UNCOMMON)
RarityPerModel.set(31, Rarity.RARE)
RarityPerModel.set(32, Rarity.MYTHIC)
RarityPerModel.set(33, Rarity.UNCOMMON)
RarityPerModel.set(34, Rarity.RARE)
RarityPerModel.set(35, Rarity.RARE)
RarityPerModel.set(36, Rarity.MYTHIC)
RarityPerModel.set(37, Rarity.UNCOMMON)
RarityPerModel.set(38, Rarity.RARE)
RarityPerModel.set(39, Rarity.RARE)
RarityPerModel.set(40, Rarity.MYTHIC)
RarityPerModel.set(41, Rarity.UNCOMMON)
RarityPerModel.set(42, Rarity.RARE)
RarityPerModel.set(43, Rarity.RARE)
RarityPerModel.set(44, Rarity.MYTHIC)
RarityPerModel.set(45, Rarity.COMMON)
RarityPerModel.set(46, Rarity.UNCOMMON)
RarityPerModel.set(47, Rarity.RARE)
RarityPerModel.set(48, Rarity.MYTHIC)
RarityPerModel.set(49, Rarity.MYTHIC)
RarityPerModel.set(50, Rarity.RARE)
RarityPerModel.set(51, Rarity.MYTHIC)
RarityPerModel.set(52, Rarity.COMMON)
RarityPerModel.set(53, Rarity.UNCOMMON)
RarityPerModel.set(54, Rarity.RARE)
RarityPerModel.set(55, Rarity.MYTHIC)
RarityPerModel.set(56, Rarity.COMMON)
RarityPerModel.set(57, Rarity.UNCOMMON)
RarityPerModel.set(58, Rarity.RARE)
RarityPerModel.set(59, Rarity.MYTHIC)
RarityPerModel.set(60, Rarity.RARE)
RarityPerModel.set(61, Rarity.COMMON)
RarityPerModel.set(62, Rarity.UNCOMMON)
RarityPerModel.set(63, Rarity.MYTHIC)
RarityPerModel.set(64, Rarity.COMMON)
RarityPerModel.set(1000, Rarity.MULE)

export default class ShipService {
    public static instance: ShipService

    public static getInstance(): ShipService {
        if (!this.instance) {
            this.instance = new ShipService()
        }
        return this.instance
    }

    public readonly dollarPerMonthPerRarity = {
      common: 2,
      uncommon: 8,
      rare: 125,
      mythic: 167,
    }

    public readonly mustPerRarity = {
      common: 1,
      uncommon: 4,
      rare: 55,
      mythic: 68,
    }

    sortRarities(ships: Array<string>): any {
      const modelDivider = Web3.utils.toBN("1000000")

      return ships.reduce((acc, ship) => {
        const tokenId = Web3.utils.toBN(ship)
        const model = tokenId.div(modelDivider).toNumber()

        if (!RarityPerModel.has(model)) throw new Error(`cannot determine rarity for ship ${ship}`)
        const rarity = RarityPerModel.get(model)

        if (rarity === Rarity.COMMON) acc.commons++
        else if (rarity === Rarity.UNCOMMON) acc.uncommons++
        else if (rarity === Rarity.RARE) acc.rares++
        else if (rarity === Rarity.MYTHIC) acc.mythics++

        return acc
      }, { commons: 0, uncommons: 0, rares: 0, mythics: 0 })
    }

    findOptimalMustRequirement(staked: number, player: any) {
      let value = 0
      const optimal = {
        total: 0,
        commons: 0,
        uncommons: 0,
        rares: 0,
        mythics: 0,
      }

      const totalMythics = player.mythics
      const totalRares = player.rares
      const totalUncommons = player.uncommons
      const totalCommons = player.commons

      const cont = true
      while (cont) {
        if (value + this.mustPerRarity.mythic <= staked && optimal.mythics < totalMythics) {
          optimal.mythics++
            value += this.mustPerRarity.mythic
        } else if (value + this.mustPerRarity.rare <= staked && optimal.rares < totalRares) {
          optimal.rares++
            value += this.mustPerRarity.rare
        } else if (value + this.mustPerRarity.uncommon <= staked && optimal.uncommons < totalUncommons) {
          optimal.uncommons++
            value += this.mustPerRarity.uncommon 
        } else if (value + this.mustPerRarity.common <= staked && optimal.commons < totalCommons) {
          optimal.commons++
            value += this.mustPerRarity.common
        } else {
          break
        }
        optimal.total++
      }
        return optimal
    }

    async getOwnedShips(account: string): Promise<ShipWithGameStat[]> {
        const ships = Array<ShipWithGameStat>()
        const [ownedShips, ownedShipsInGame] = await Promise.all([
            SpaceShipsService.getInstance().tokensOf(account),
            StakedSpaceshipsService.getInstance().tokensOf(account)
        ])

        await Promise.all(ownedShips.concat(ownedShipsInGame).map(async (id) => {
            const ship = await (new ShipWithGameStat(id.toString(), account)).init()
            ships.push(ship)
        }))

        return ships
    }

    async getBorrowedShips(account: string): Promise<ShipWithGameStat[]> {
        const ships = Array<ShipWithGameStat>()
        const lending = await RentingService.getInstance().rentingsOf(account)
        for (let i = 0; i < lending.length; i++) {
            const [borrowed, borrowedInGame] = await Promise.all([
                SpaceShipsService.getInstance().tokensOf(lending[i].id),
                StakedSpaceshipsService.getInstance().tokensOf(lending[i].id)
            ])
            await Promise.all(borrowed.concat(borrowedInGame).map(async (id) => {
                const ship = await builRentedShip(id.toString(), lending[i].id, 100 - lending[i].percentageForLender, State.Borrowed)
                ships.push(ship)
            }))
        }
        return ships
    }

    async getLoanedShips(account: string): Promise<ShipWithGameStat[]> {
        const ships = Array<ShipWithGameStat>()
        const lending = await RentingService.getInstance().rentingsGrantedOf(account)
        for (let i = 0; i < lending.length; i++) {
            const [lent, lentInGame] = await Promise.all([
                SpaceShipsService.getInstance().tokensOf(lending[i].id),
                StakedSpaceshipsService.getInstance().tokensOf(lending[i].id)
            ])
            await Promise.all(lent.concat(lentInGame).map(async (id) => {
                const ship = await builRentedShip(id.toString(), lending[i].id, lending[i].percentageForLender, State.Loaned)
                ships.push(ship)
            }))
        }
        return ships
    }

    async getFleet(account: string): Promise<ShipWithGameStat[]> {
        const owned = await this.getOwnedShips(account)
        const borrowed = await this.getBorrowedShips(account)

        return owned.concat(borrowed)
    }
}
