import * as PIXI from 'pixi.js'

export default class KeyboardService {
    public static instance: KeyboardService

    public static getInstance(): KeyboardService {
        if (!this.instance) {
            this.instance = new KeyboardService()
        }

        return this.instance
    }

    public isShiftPressed = false

    constructor() {
        document.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key == 'Shift' && !this.isShiftPressed) {
                this.isShiftPressed = true
                document.dispatchEvent(new Event('onShiftStateChanged'))
            }
        })

        document.addEventListener('keyup', (event: KeyboardEvent) => {
            if (event.key == 'Shift' && this.isShiftPressed) {
                this.isShiftPressed = false
                document.dispatchEvent(new Event('onShiftStateChanged'))
            }
        })
    }

    public setOnShiftStateChanged(callback: (shiftState: boolean) => void) {
        document.addEventListener('onShiftStateChanged', () => {
            callback(this.isShiftPressed)
        })
    }
}
