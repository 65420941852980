import { Star } from '@/game/models/Star'
import GSprite from '@/game/pixi-scale/GSprite'
import GTilingSprite from '@/game/pixi-scale/GTilingSprite'
import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import MapService from '@/game/services/MapService'
import PlayerService from '@/game/services/PlayerService'
import CometManagerService from '@/game/services/web3/CometManagerService'
import StakedSpaceshipsService from '@/game/services/web3/StakedSpaceshipsService'
import { animate } from '@/helpers/AnimationHelper'
import Colors from '@/helpers/Colors'
import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'
import StarPreviewComponent from './StarPreviewComponent'

class SolarSystemItem {
    constructor(
        public star: Star,
        public container: PIXI.Container,
        public shipCounter: MainBitmapText,
        public selfShipCounter: MainBitmapText,
        public cometCounter: MainBitmapText
    ) {}
}

export default class GalaxyComponent extends PIXI.Container {
    public onClose = () => {
        return
    }

    private bg = new GTilingSprite('map_bg') as GTilingSprite
    private spaceBg = new GSprite('bg_galaxy')
    private isDragging = false

    public mapContainer = new PIXI.Container()
    private dataContainer = new PIXI.Container()
    public mapWidth = 500
    public mapHeight = 500
    private mapGraphics = new PIXI.Graphics()
    private starDetail = new StarPreviewComponent()
    private solarSystems: SolarSystemItem[] = []

    constructor() {
        super()

        this.interactive = true
        this.cursor = 'move'
        this.addChild(this.mapContainer)
        this.mapContainer.addChild(this.spaceBg)
        this.addChild(this.bg)
        this.addChild(this.dataContainer)

        this.dataContainer.addChild(this.mapGraphics)
        this.dataContainer.scale.set(Resolution.scale)
        this.onResize()

        this.bg.alpha = 0.9
        this.mapContainer.x = Resolution.realWidth / 2 - this.spaceBg.width / 2
        this.mapContainer.y = Resolution.realHeight / 2 - this.spaceBg.height / 2

        this.mapWidth = this.spaceBg.width * Resolution.scale
        this.mapHeight = this.spaceBg.height * Resolution.scale

        let lastMouseX = 0
        let lastMouseY = 0
        this.on('pointerdown', (event: PIXI.InteractionEvent) => {
            this.isDragging = true
        })
        document.addEventListener('pointerup', () => {
            this.isDragging = false
        })

        this.on('pointertap', (event: PIXI.InteractionEvent) => {
            // This callback is only to get the positions of click on galactic map
            console.log(
                'Galactic Coords : ',
                Math.floor(
                    ((lastMouseX - this.mapContainer.position.x - this.mapContainer.width / 2 + 20 * Resolution.scale) * 4000) / Resolution.scale
                ),
                Math.floor(
                    ((lastMouseY - this.mapContainer.position.y - this.mapContainer.height / 2 - 10 * Resolution.scale) * 4000) / Resolution.scale
                )
            )
        })
        this.on('pointermove', (event: PIXI.InteractionEvent) => {
            if (this.isDragging) {
                this.mapContainer.position.x += event.data.global.x - lastMouseX
                this.mapContainer.position.y += event.data.global.y - lastMouseY

                this.checkPositions()
            }

            lastMouseX = event.data.global.x
            lastMouseY = event.data.global.y
        })

        document.addEventListener(MapService.CURRENT_SOLAR_SYSTEM_CHANGED, () => {
            this.setInfos()
        })

        document.addEventListener(MapService.MINER_ADDED_ON_MAP_EVENT, () => {
            this.setInfos()
        })

        document.addEventListener(MapService.COMET_ADDED_ON_MAP_EVENT, () => {
            this.setInfos()
        })

        document.addEventListener(MapService.STAKING_COMET_ADDED_ON_MAP_EVENT, () => {
            this.setInfos()
        })

        document.addEventListener(StakedSpaceshipsService.SHIP_LEAVE_GAME_EVENT, () => {
            this.setInfos()
        })

        document.addEventListener(MapService.SOLAR_SYSTEM_MINER_REMOVED, () => {
            this.setInfos()
        })

        document.addEventListener(CometManagerService.REMOVE_COMET_EVENT, () => {
            this.setInfos()
        })

        this.loadData()
    }

    onResize() {
        this.bg.anchor.set(0, 0)
        this.bg.width = Resolution.realWidth / Resolution.scale
        this.bg.height = Resolution.realHeight / Resolution.scale

        this.mapWidth = this.spaceBg.width * Resolution.scale
        this.mapHeight = this.spaceBg.height * Resolution.scale
        this.spaceBg.anchor.set(0, 0)
        this.dataContainer.scale.set(Resolution.scale)

        this.checkPositions()
    }

    public async centerToStar(star: Star, doAnimate = false) {
        const fromX = this.mapContainer.x
        const fromY = this.mapContainer.y
        const toX = Math.floor(-star.galaxyX * Resolution.scale - this.mapWidth / Resolution.scale / 2 + Resolution.realWidth / 2)
        const toY = Math.floor(-star.galaxyY * Resolution.scale - this.mapHeight / Resolution.scale / 2 + Resolution.realHeight / 2)

        if (doAnimate) {
            await animate('easeOutQuad', 700, perc => {
                this.mapContainer.x = fromX * (1 - perc) + toX * perc
                this.mapContainer.y = fromY * (1 - perc) + toY * perc
                this.checkPositions()
            })
        } else {
            this.mapContainer.x = toX
            this.mapContainer.y = toY
            this.checkPositions()
        }
    }

    private checkPositions() {
        if (this.mapContainer.x > 0) {
            this.mapContainer.x = 0
        } else if (this.mapContainer.x < -this.mapWidth / Resolution.scale + Resolution.realWidth) {
            this.mapContainer.x = -this.mapWidth / Resolution.scale + Resolution.realWidth
        }

        if (this.mapContainer.y > 0) {
            this.mapContainer.y = 0
        } else if (this.mapContainer.y < -this.mapHeight / Resolution.scale + Resolution.realHeight) {
            this.mapContainer.y = -this.mapHeight / Resolution.scale + Resolution.realHeight
        }

        this.bg.tilePosition.x = this.mapContainer.position.x / Resolution.scale
        this.bg.tilePosition.y = this.mapContainer.position.y / Resolution.scale

        this.dataContainer.x = Math.floor(this.mapWidth / Resolution.scale / 2 + this.mapContainer.x) - 20 * Resolution.scale
        this.dataContainer.y = Math.floor(this.mapHeight / Resolution.scale / 2 + this.mapContainer.y) + 10 * Resolution.scale
    }

    private loadData() {
        this.dataContainer.removeChildren()
        this.dataContainer.addChild(this.mapGraphics)
        this.mapGraphics.clear()
        MapService.getInstance().map.portals.forEach(it => {
            const fromStar = MapService.getInstance().map.stars.filter(a => {
                return a.id == it.fromId
            })[0]
            const toStar = MapService.getInstance().map.stars.filter(a => {
                return a.id == it.toId
            })[0]

            this.mapGraphics
                .lineStyle(1, Colors.Blue600)
                .moveTo(fromStar.galaxyX, fromStar.galaxyY)
                .lineTo(toStar.galaxyX, toStar.galaxyY)
        })

        MapService.getInstance().map.stars.forEach(it => {
            const itemContainer = new PIXI.Container()
            itemContainer.x = it.galaxyX
            itemContainer.y = it.galaxyY

            const graphic = new PIXI.Graphics()
            graphic.lineStyle(1, Colors.Blue800)
            graphic.drawCircle(0, 0, 8)
            graphic.beginFill(Colors.Blue800)
            graphic.drawCircle(0, 0, 1)

            graphic.hitArea = new PIXI.Circle(0, 0, 8)
            graphic.interactive = true
            graphic.cursor = 'pointer'
            graphic.on('mouseover', () => {
                this.starDetail.updateData(it)
                this.dataContainer.addChild(this.starDetail)
                this.starDetail.x = Math.floor(itemContainer.x - this.starDetail.width - 12)
                this.starDetail.y = Math.floor(itemContainer.y - this.starDetail.height / 2)

                graphic.clear()
                graphic.lineStyle(1, 0xffffff)
                graphic.drawCircle(0, 0, 8)
                graphic.beginFill(0xffffff)
                graphic.drawCircle(0, 0, 1)
            })
            graphic.on('mouseout', () => {
                this.dataContainer.removeChild(this.starDetail)

                graphic.clear()
                graphic.lineStyle(1, Colors.Blue800)
                graphic.drawCircle(0, 0, 8)
                graphic.beginFill(Colors.Blue800)
                graphic.drawCircle(0, 0, 1)
            })
            graphic.on('pointertap', async () => {
                this.onClose()
                if (it.id != MapService.getInstance().currentSolarSystemId) {
                    await MapService.getInstance().switchToSolarSystem(it.id)
                } else {
                    document.dispatchEvent(new Event(MapService.CURRENT_SOLAR_SYSTEM_CHANGED))
                }
            })

            const title = new MainBitmapText(it.name, { fontSize: 6 })
            title.x = 12
            title.y = -4

            const shipIcon = PIXI.Sprite.from('ic_galaxy_ship')
            shipIcon.x = 12
            shipIcon.y = 4

            const shipText = new MainBitmapText(it.countMiners().toString(), { fontSize: 4 })
            shipText.x = shipIcon.x + shipIcon.width + 2
            shipText.y = shipIcon.y + 2

            const ownShipIcon = PIXI.Sprite.from('ic_galaxy_own_ship')
            ownShipIcon.x = 12
            ownShipIcon.y = 12

            const ownCount = PlayerService.getInstance().miningPlayers.filter(a => a.solarSystemID == it.id).length
            const ownShipText = new MainBitmapText(ownCount + '', { fontSize: 4 })
            ownShipText.x = ownShipIcon.x + ownShipIcon.width + 2
            ownShipText.y = ownShipIcon.y + 2

            const cometIcon = PIXI.Sprite.from('ic_galaxy_comet')
            cometIcon.x = 12
            cometIcon.y = 20

            const cometText = new MainBitmapText(it.countComets() + '', { fontSize: 4 })
            cometText.x = cometIcon.x + cometIcon.width + 2
            cometText.y = cometIcon.y + 2

            itemContainer.addChild(graphic)
            itemContainer.addChild(title)
            itemContainer.addChild(shipIcon)
            itemContainer.addChild(shipText)
            itemContainer.addChild(ownShipIcon)
            itemContainer.addChild(ownShipText)
            itemContainer.addChild(cometIcon)
            itemContainer.addChild(cometText)

            const solarSystem = new SolarSystemItem(it, itemContainer, shipText, ownShipText, cometText)
            this.solarSystems.push(solarSystem)

            this.dataContainer.addChild(itemContainer)
        })
    }

    private setInfos() {
        this.solarSystems.forEach(it => {
            it.shipCounter.text = it.star.countMiners().toString()
            const ownCount = PlayerService.getInstance().miningPlayers.filter(a => a.solarSystemID == it.star.id).length
            it.selfShipCounter.text = ownCount + ''
            it.cometCounter.text = it.star.countComets() + ''
        })
    }
}
