import { TransactionNotification } from '../models/notification/TransactionNotification'
import { Transaction, TransactionStatus } from '../models/Transaction'

export default class SessionTransactionNotifService {
    public static instance: SessionTransactionNotifService

    public timeDiff = 0

    public static getInstance(): SessionTransactionNotifService {
        if (!this.instance) {
            this.instance = new SessionTransactionNotifService()
        }

        return this.instance
    }

    public transactions: TransactionNotification[] = []

    public addTx(tx: TransactionNotification) {
        this.transactions.push(tx)
        this.dispatchTxChanged()
        document.dispatchEvent(new CustomEvent('onTxAddedToList', { detail: tx }))
    }

    public setOnTxFinished(callback: (title: string, isSuccess: boolean) => void) {
        document.addEventListener('onSessionTxFinished', (event: any) => {
            callback(event.detail.title, event.detail.isSuccess)
        })
    }

    public dispatchTxFinished(message: string, isSuccess: boolean) {
        document.dispatchEvent(new CustomEvent('onSessionTxFinished', { detail: { title: message, isSuccess: isSuccess } }))
    }

    public setOnTxListChanged(callback: (txList: TransactionNotification[]) => void) {
        document.addEventListener('onTxListChanged', () => {
            callback(this.transactions)
        })
    }

    public setOnTxAdded(callback: (tx: TransactionNotification) => void) {
        document.addEventListener('onTxAddedToList', (event: any) => {
            callback(event.detail as TransactionNotification)
        })
    }

    public clear() {
        this.transactions = this.transactions.filter(a => a.transaction.status == TransactionStatus.InProgress)
        this.dispatchTxChanged()
    }

    public dispatchTxChanged() {
        document.dispatchEvent(new Event('onTxListChanged'))
    }
}
