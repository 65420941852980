import MulticallService from '@/game/services/web3/MulticallService'
import StakingRewards from '@/constants/StakingRewards'
import TheGraphService from '@/game/services/TheGraphService'
import Web3 from 'web3'
import StakedLiquidityToken from '@/models/StakedLiquidityToken'

export default class StakingRewardService {
    public static instance: StakingRewardService

    public static getInstance(): StakingRewardService {
        if (!this.instance) {
            this.instance = new StakingRewardService()
        }
        return this.instance
    }

    async getStakedLiquidityPositions(holder: string) {
        const stakingRewards = StakingRewards.Classic.concat(StakingRewards.Multiple)

        let stakingRewardsBalances = Array<string>()
        stakingRewardsBalances = (await MulticallService.getInstance().erc20balanceOf(stakingRewards, holder)).returnData

        const balancesStaked = new Map<string, string>();
        for (let i = 0; i < stakingRewardsBalances.length; i++) {
            if (stakingRewardsBalances[i] == '0x0000000000000000000000000000000000000000000000000000000000000000') continue
            balancesStaked.set(stakingRewards[i], stakingRewardsBalances[i])
        }
        const stakingRewardsWhereStaked = Array.from(balancesStaked.keys())
        const liquidityPositionsAddresses = await MulticallService.getInstance().stakingTokenOf(stakingRewardsWhereStaked)

        const holderBalances = new Map<string, string>();
        const holderStakingRewards = new Map<string, string>();
        for (let i = 0; i < stakingRewardsWhereStaked.length; i++) {
            const balance = balancesStaked.get(stakingRewardsWhereStaked[i])
            if(!balance) continue
            holderBalances.set(liquidityPositionsAddresses[i], Web3.utils.fromWei(balance, 'ether'))
            holderStakingRewards.set(liquidityPositionsAddresses[i], stakingRewardsWhereStaked[i])
        }

        const stakedLiquidityTokens = Array<StakedLiquidityToken>()

        const liquidityPositions = await TheGraphService.getInstance().getPairs(liquidityPositionsAddresses)
        for (let i = 0; i < liquidityPositions.length; i++) {
            const balance = holderBalances.get(liquidityPositions[i].id)
            const stakingReward = holderStakingRewards.get(liquidityPositions[i].id)
            if (!balance || !stakingReward) continue
            liquidityPositions[i].balance = balance
            stakedLiquidityTokens.push(new StakedLiquidityToken(
                stakingReward,
                liquidityPositions[i]
            ))
        }

        return stakedLiquidityTokens
    }
}
