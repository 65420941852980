<template>
    <!-- container fixing body overflow hidden -->
    <!--<div class="h-screen overflow-auto bg-white">-->
    <Header ref="header" v-on:rampPurchaseSuccessful="onRampPurchased" />

    <div class="bg-black">
        <div class="pt-12 sm:pt-16 lg:pt-24">
            <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                    <h2 class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                        Cometh shop
                    </h2>
                </div>
            </div>
        </div>

        <div class="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
            <div class="relative">
                <div class="absolute inset-0 h-4/5 bg-black"></div>
                <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="max-w-md mx-auto lg:max-w-5xl">
                        <div class="relative flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <img
                                src="@/assets/ships/mule.png"
                                class="w-40  -right-10  -top-8 lg:-right-10 -rotate-45 lg:-top-12 absolute transform lg:w-60"
                            />
                            <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                <div>
                                    <h3
                                        class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-blue-100 text-blue-600"
                                        id="tier-standard"
                                    >
                                        M.U.L.E
                                    </h3>
                                </div>
                                <div class="mt-4 flex items-baseline text-5xl font-extrabold font-audiowide">
                                    10
                                    <span class="ml-1 text-2xl font-medium text-gray-500">
                                        Matic
                                    </span>
                                </div>
                                <p class="mt-5 text-lg text-gray-500">
                                    M.U.L.E. are the training ships provided by the Galactic Federation.
                                </p>
                            </div>
                            <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                                <ul class="space-y-4">
                                    <!-- <li class="flex items-start">
                                        <div class="flex-shrink-0">
                                            <XIcon class="h-6 w-6 text-red-500" />
                                        </div>
                                        <p class="ml-3 text-base text-gray-700">
                                            Allowed in Galactic Academy and Polygon Grand Prix
                                        </p>
                                    </li> -->

                                    <li class="flex items-start">
                                        <div class="flex-shrink-0">
                                            <XIcon class="h-6 w-6 text-red-500" />
                                        </div>
                                        <p class="ml-3 text-base text-gray-700">
                                            Can mine juicy comets
                                        </p>
                                    </li>
                                    <li class="flex items-start">
                                        <div class="flex-shrink-0">
                                            <XIcon class="h-6 w-6 text-red-500" />
                                        </div>
                                        <p class="ml-3 text-base text-gray-700">
                                            Unique look & feel
                                        </p>
                                    </li>
                                </ul>
                                <div class="rounded-md shadow">
                                    <div
                                        class="flex items-center justify-center px-5 py-3 border border-gray-300 text-base font-medium rounded-md bg-gray-300 text-gray-500 "
                                    >
                                        Sold out
                                    </div>
                                    <!-- <router-link
                                        to="/shop/mule"
                                        class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400"
                                        aria-describedby="tier-standard"
                                    >
                                        Get started
                                    </router-link> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-8 lg:space-y-0">
                        <ShopItemList :items="shopItems" />
                    </div>
                </div>
            </div>

            <CTAReferral class="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5" />
        </div>
    </div>
</template>

<script lang="ts" src="./Shop.ts"></script>
