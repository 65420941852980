
import { defineToastComponent } from 'vue-my-toasts'
export default defineToastComponent({
    name: 'DefaultToastComponent',
    props: {
        badge: {
            type: [String, Boolean],
            required: false,
            default: false
        }
    },
    computed: {
        color() {
            switch (this.type) {
                case 'base':
                    return 'blue'
                case 'warning':
                    return 'orange'
                case 'error':
                    return 'red'
                case 'success':
                    return 'green'
                default:
                    return 'blue'
            }
        }
    }
})
