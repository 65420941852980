<template>
    <div class="overflow-x-hidden">
        <Header
            ref="header"
            v-on:rampPurchaseSuccessful="onRampPurchased"
            v-on:rampCloseRequestConfirmed="onRampCloseRequestConfirmed"
            v-on:rampPurchaseCreated="onRampPurchaseCreated"
            v-on:rampPurchaseFailed="onRampPurchaseFailed"
        />
        <div class="gradient px-4 pt-[150px] pb-[140px] mobile:py-10">
            <div class="max-w-[1140px] mx-auto">
                <div class="grid grid-cols-12 gap-[30px] items-center">
                    <div
                        class="
                            col-start-1
                            col-span-7
                            row-span-2
                            mobile:col-start-1
                            mobile:col-span-12
                        "
                    >
                        <h1
                            class="
                                glow
                                text-white
                                font-display
                                uppercase
                                font-medium
                                text-[100px]
                                leading-[80px]
                                mobile:text-[50px]
                                mobile:leading-[40px]
                                mobile:text-center
                            "
                        >
                            Start your<br />space odyssey
                        </h1>
                        <p
                            class="
                    mt-4
                    text-lg
                    mobile:text-base
                    leading-7
                    mobile:leading-6
                    font-medium
                    mobile:text-center text-white
                "
                        >
                            Get on board now. Play, earn and learn everything Cometh vibrant ecosystem.
                        </p>
                        <ul
                            class="
                                mt-8
                                mobile:mt-7
                                text-white text-[18px]
                                mobile:text-base
                                leading-[26px]
                                font-medium
                                flex flex-col
                                space-y-6
                            "
                        >
                            <li class="flex items-center">
                                <svg class="flex-shrink-0 mr-4 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <defs>
                                        <filter id="a" color-interpolation-filters="auto">
                                            <feColorMatrix
                                                in="SourceGraphic"
                                                values="0 0 0 0 0.513725 0 0 0 0 0.286275 0 0 0 0 0.898039 0 0 0 1.000000 0"
                                            />
                                        </filter>
                                    </defs>
                                    <g fill="none" fill-rule="evenodd" filter="url(#a)" transform="translate(-248 -2905)">
                                        <path d="M248 2905h24v24h-24z" />
                                        <path fill="#021A2D" fill-rule="nonzero" d="M257 2921.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
                                    </g>
                                </svg>
                                Allowed in Galactic Academy and tournament arenas
                            </li>
                            <li class="flex items-center">
                                <svg class="flex-shrink-0 mr-4 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <defs>
                                        <filter id="a" color-interpolation-filters="auto">
                                            <feColorMatrix
                                                in="SourceGraphic"
                                                values="0 0 0 0 0.513725 0 0 0 0 0.286275 0 0 0 0 0.898039 0 0 0 1.000000 0"
                                            />
                                        </filter>
                                    </defs>
                                    <g fill="none" fill-rule="evenodd" filter="url(#a)" transform="translate(-248 -2905)">
                                        <path d="M248 2905h24v24h-24z" />
                                        <path fill="#021A2D" fill-rule="nonzero" d="M257 2921.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
                                    </g></svg
                                >{{ displayedMustAmount }} Must for in game actions
                            </li>
                        </ul>
                    </div>
                    <div
                        class="col-start-9 col-span-4 mobile:col-start-1
                            mobile:col-span-12 mobile:order-first -mt-20 mobile:-mt-28"
                    >
                        <div class="relative h-[340px] overflow-hidden">
                            <img class="absolute w-full max-w-[375px] transform lg:scale-150" src="@/assets/ships/mule.png" />
                            <h2
                                class="
                                    absolute
                                    bottom-0 right-0
                                    font-display font-medium
                                    text-[90px]
                                    mobile:text-[60px]
                                    leading-[80px]
                                    mobile:leading-[50px]
                                    uppercase
                                    text-white text-center
                                "
                            >
                                ${{ USDPrice }}
                                <span class="text-right block text-xl">( {{ displayedStarterKitPrice }} Matic )</span>
                            </h2>
                        </div>
                    </div>
                    <div
                        v-if="isOrderComplete"
                        id="mule_purchase_successful"
                        class="col-start-9 col-span-4 mobile:col-start-1
                            mobile:col-span-12 lg:-mt-20 text-white"
                    >
                        <p class="flex items-center text-xl leading-6 font-medium text-white">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-8 w-8 mr-2 text-green-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                            <span>Thank you!</span>
                        </p>
                        <!-- <div class="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                    <span v-if="displayedStarterKitPrice">{{ displayedStarterKitPrice }} </span>
                                    <span class="ml-3 text-xl font-medium text-gray-500"> Matic </span>
                                </div> -->
                        <p class="mt-4 text-lg font-medium text-white">Jump right now into the game.</p>
                        <div class="mt-6">
                            <div class="rounded-md shadow">
                                <a
                                    href="/"
                                    class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600"
                                >
                                    Play
                                </a>
                            </div>
                        </div>
                        <div class="mt-4 text-sm">
                            <a href="https://youtu.be/1GA-ngxakrU?t=110" target="_blank" class="font-medium text-whites">
                                Game tutorial Video
                                <span class="font-normal text-gray-500"> (3 minutes) </span>
                            </a>
                        </div>
                    </div>
                    <div
                        v-else
                        class="col-start-9 col-span-4 mobile:col-start-1
                            mobile:col-span-12 lg:-mt-10 "
                    >
                        <div class="flex justify-center">
                            <ButtonPrimary @click="buy" color="yellow" :loading="isBuying" class="w-full h-[60px] text-3xl lg:mt-6">
                                Play
                            </ButtonPrimary>
                        </div>

                        <div v-if="isRampPurchaseCreated" class="mt-2">
                            <div class="px-2 py-2 text-white group flex items-center">
                                <loader-icon />
                                <p class="ml-2">Matic Transfer in progress, please wait</p>
                            </div>
                        </div>

                        <!-- referral code -->
                        <div v-if="validReferralCodeAdded" class="mt-10">
                            <div class="group flex items-center mb-2  text-xs text-gray-400">
                                <span class="text-sm "
                                    >YOUR REFERRAL CODE: <b class="font-bold glow-yellow">{{ referralID }}</b></span
                                >
                                <button class="ml-2" @click="removeCode">
                                    <XIcon class="w-4 h-4 text-red-400" />
                                </button>
                            </div>
                            <div class="cashback  px-4 py-2 text-gray-400">
                                <!-- <CheckIcon class="h-6 w-6 text-green-500" /> -->
                                You earn <span class="glow-yellow uppercase">10% cashback</span> in MUST
                            </div>
                        </div>
                        <div v-else class="mt-10 mx-auto max-w-[250px]">
                            <label for="referralID" class="flex self-start uppercase text-sm text-white">Got a referral code ?</label>
                            <div class="flex mt-1 ">
                                <input
                                    type="text"
                                    id="referralID"
                                    :class="[codeError ? 'border-red-500 text-red-500' : 'border-[#8349E5]']"
                                    class="border  w-full px-4 py-2 outline-none bg-transparent bg-opacity-20 text-white"
                                    v-model="referralID"
                                />
                                <ButtonSecondary class="w-14 ml-2" @click="validateCode">
                                    <svg
                                        v-if="validatingCode"
                                        class="animate-spin h-5 w-5 "
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                    <span v-else>Add</span>
                                </ButtonSecondary>
                            </div>
                            <span v-if="codeError" class="text-red-500 mt-1 flex self-start">Invalid code</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Countdown @buy="buy" />
        <Rules @buy="buy" />
        <Academy @buy="buy" />
        <Ships @buy="buy" />
        <Game @buy="buy" />
    </div>

    <Modal :open="showConfirmationModal" @close="showConfirmationModal = false">
        <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon class="w-6 h-6 text-yellow-600" />
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                <div class="mt-2">
                    <p class="text-sm text-gray-500">
                        Are you ready to start playing Cometh? Click buy to initiate the transaction (this action cannot be undone).
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
                @click="confirmPurchase"
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
                Buy
            </button>
            <button
                @click="showConfirmationModal = false"
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            >
                Cancel
            </button>
        </div>
    </Modal>
</template>

<script lang="ts" src="./Mule.ts"></script>

<style scoped>
.gradient {
    background-image: linear-gradient(23deg, #010002 7%, #02192b 100%);
}
.glow {
    text-shadow: 0 0 14px #8349e5;
}
.glow-yellow {
    color: #fde700;
    text-shadow: 0 0 14px #fde700;
}
.cashback {
    border: 1px solid #fde700;
}
</style>
