import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import Resolution from '@/helpers/Resolution'
import { animate } from '@/helpers/AnimationHelper'
import Comete from '../models/Comete'
import Miner from '../models/Miner'
import Colors from '@/helpers/Colors'
import CometComponent from './CometComponent'
import MinerPlayerComponent from './MinerPlayerComponent'

export default class EntityPreviewZoomComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public entityContainer: PIXI.Container = new PIXI.Container()
    private width = 220
    private bg: PIXI.TilingSprite = PIXI.TilingSprite.from('bg', {
        width: this.width - 2,
        height: this.width - 2
    }) as PIXI.TilingSprite
    private bg2: PIXI.TilingSprite = PIXI.TilingSprite.from('bg2', {
        width: this.width - 2,
        height: this.width - 2
    }) as PIXI.TilingSprite
    private bg3: PIXI.TilingSprite = PIXI.TilingSprite.from('bg3', {
        width: this.width - 2,
        height: this.width - 2
    }) as PIXI.TilingSprite
    private entity?: Comete | Miner
    private targetY = 6
    private cometComponent?: CometComponent
    private minerComponent?: MinerPlayerComponent

    constructor() {
        const modalBg = new PIXI.Graphics()
        modalBg.beginFill(0)
        modalBg.lineStyle(1, Colors.Blue800)
        modalBg.drawRect(0, 0, this.width, this.width)

        this.bg.x = this.bg.y = 1
        this.bg2.x = this.bg2.y = 1
        this.bg3.x = this.bg3.y = 1

        const mask = new PIXI.Graphics()
        mask.beginFill(0xffffff)
        mask.drawRect(0, 0, this.width - 2, this.width - 2)
        mask.x = mask.y = 1

        this.container.addChild(modalBg)
        this.container.addChild(this.bg)
        this.container.addChild(this.bg2)
        this.container.addChild(this.bg3)
        this.container.addChild(mask)
        this.entityContainer.mask = mask

        this.container.addChild(this.entityContainer)

        this.container.visible = false
        this.onResize()
    }

    tick(): void {
        this.bg!.tilePosition.y += PIXI.Ticker.shared.deltaMS / 200
        this.bg2!.tilePosition.y += PIXI.Ticker.shared.deltaMS / 100
        this.bg3!.tilePosition.y += PIXI.Ticker.shared.deltaMS / 50

        this.cometComponent?.tick()
    }

    onResize(): void {
        this.container.scale.set(Resolution.scale)
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    async enterAnimation(callback: () => void): Promise<void> {
        if (this.container.alpha == 1) return
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.alpha = perc
            this.container.y = 0 + Resolution.margin6 * perc
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        if (this.container.alpha < 1) return
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.alpha = 1 - perc
            this.container.y = Resolution.margin6 - Resolution.margin6 * perc
        })
        callback()
    }

    public setComet(comet: Comete) {
        if (this.entity?.id == comet.id) return

        this.clearEntityContainer()

        this.entity = comet
        this.cometComponent = new CometComponent(this.entity!)
        this.entityContainer.addChild(this.cometComponent.getContainer())

        this.cometComponent.getContainer().position.x = Math.floor(this.width / 2 - this.cometComponent.getContainer().width / 2)
        this.cometComponent.getContainer().position.y = Math.floor(this.width / 2 - this.cometComponent.getContainer().height / 2)
        this.container.visible = true
    }

    public setMiner(miner: Miner) {
        this.entity = miner

        this.minerComponent = new MinerPlayerComponent(this.entity!)
        this.entityContainer.addChild(this.minerComponent.getContainer())

        this.minerComponent.getContainer().position.x = Math.floor(this.width / 2 - this.minerComponent.getContainer().width / 2)
        this.minerComponent.getContainer().position.y = Math.floor(this.width / 2 - this.minerComponent.getContainer().height / 2)
        this.container.visible = true
    }

    public clearEntityContainer() {
        this.entityContainer.removeChildren()
        if (this.minerComponent) {
            this.minerComponent.getContainer().destroy({ children: true })
            this.minerComponent = undefined
        }
        if (this.cometComponent) {
            this.cometComponent.getContainer().destroy({ children: true })
            this.cometComponent = undefined
        }

        this.entity = undefined
        this.container.visible = false
    }
}
