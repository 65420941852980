<template>
    <a
        :href="href"
        :class="color"
        class="
            border
            uppercase
            font-pixel
            text-2xl
            leading-6
            inline-flex
            justify-center
            items-center
            cursor-pointer
        "
    >
        <slot />
    </a>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        href: String,
        color: {
            type: String,
            default: 'purple'
        }
    }
})
export default class ButtonSecondary extends Vue {
    href!: string
    color!: string
}
</script>

<style scoped>
a.blue {
    color: #57c2ff;
    border: 2px solid #57c2ff;
}
a.blue:hover {
    background: rgba(87, 194, 255, 0.3);
}
a.purple {
    color: #8349e5;
    border: 2px solid #8349e5;
}
a.purple:hover {
    background: rgba(108, 50, 207, 0.3);
}
a.white {
    color: #fff;
    border: 2px solid #ffffff;
}
a.white:hover {
    background: rgba(255, 255, 255, 0.3);
}
a.yellow {
    color: #fcc300;
    border: 2px solid #fcc300;
}
a.yellow:hover {
    background: rgba(252, 195, 0, 0.3);
}
</style>
