<template>
    <a
        :href="href"
        @click="navigate"
        class=" inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
        :class="[isActive ? 'border-blue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
    >
        <slot />
    </a>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        href: String,
        isActive: Boolean
    }
})
export default class NavLink extends Vue {}
</script>
