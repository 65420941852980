import Resolution from '@/helpers/Resolution'
import TutorialComponent from '../components/TutorialComponent'
import TutorialStep from '../models/TutorialStep'
import * as PIXI from 'pixi.js'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import Colors from '@/helpers/Colors'

export class HelpChapter {
    constructor(public icon: string, public title: string, public chapterDetail: PIXI.DisplayObject[]) {}
}

export default class TutorialService {
    public static chapters: HelpChapter[] = []
    public static instance: TutorialService

    public static initChapters() {
        this.chapters = [
            new HelpChapter('ic_tuto_1', 'How to Jump ?', [
                PIXI.Sprite.from('tuto1'),
                new MainBitmapText(
                    `To make a Jump, hover a ship with your
mouse and left click.
                    
Then, validate and wait for the
transaction to be mined.`,
                    { fontSize: 4 }
                ),

                new MainBitmapText(
                    `A Jump move you half the distance
to the targeted ship`,
                    { fontSize: 4, tint: Colors.Blue800 }
                )
            ]),
            new HelpChapter('ic_tuto_2', 'How to Multi-Jump ?', [
                PIXI.Sprite.from('tuto2'),
                new MainBitmapText(
                    `To make a Multi-Jump, hold your [SHIFT]
key and left click.
                    
Then, validate and wait for the
transaction to be mined.`,
                    { fontSize: 4 }
                ),
                new MainBitmapText('Note: You can stack 10 Jumps max.', { fontSize: 4, tint: Colors.Blue800 }),
                new MainBitmapText(
                    `Care ! If one of your target jump before
you, the final destination will change.`,
                    {
                        fontSize: 4,
                        tint: Colors.Red500
                    }
                )
            ]),
            new HelpChapter('ic_tuto_3', 'How to Mine a Comet ?', [
                PIXI.Sprite.from('tuto3'),
                new MainBitmapText(
                    `To mine a comet, Jump on other ships 
to make your orbit cross the comet orbit.
                    
When the comet enters your mining area
(visible when you zoom on your ship),
a notification will allow you to
mine the comet.`,
                    { fontSize: 4 }
                ),

                new MainBitmapText(
                    `You'll have to wait your ship engines
to be ready to mine a comet again`,
                    { fontSize: 4, tint: Colors.Red500 }
                )
            ]),
            new HelpChapter('ic_tuto_4', 'How to Mine a Radioactive\nComet with your rover ?', [
                PIXI.Sprite.from('tuto4'),
                new MainBitmapText(
                    `The radioactives comets cannot be
directly mined. As for mining, make the
comet enter your mining area and
deposit your rover.

When you want to get your gain,
make the comet enter your mining area
again. Then you'll be able to get your
rover back.`,
                    { fontSize: 4 }
                )
            ])
        ]
    }

    public static getInstance(): TutorialService {
        if (!this.instance) {
            this.instance = new TutorialService()
        }

        return this.instance
    }

    private currentComponent?: TutorialComponent

    public pixi!: PIXI.Application

    public startTutorialForMain(force = false) {
        if (localStorage.getItem('mainTutorial') && !force) return

        this.currentComponent = new TutorialComponent('mainTutorial', [
            new TutorialStep(
                [
                    `Welcome on COMETH, miner.\nI'm COBOTH. I'm here to help you to gather\na lot and a lot of MUST.\nLets do a quick check of your mining interface`
                ],
                () => {
                    return new PIXI.Point(Resolution.realWidth / 3 / Resolution.scale, Resolution.realHeight / 2 / Resolution.scale)
                }
            ),
            new TutorialStep([`This is the notification menu.\nYou will be able to see how many MUST\nyou won from other players.`], (w, h) => {
                return new PIXI.Point(0, Resolution.realHeight / Resolution.scale - h - 128)
            }),
            new TutorialStep([`At the left, it's settings menu.\nYou'll be able to turn down or up\nthe music from your ship.`], (w, h) => {
                return new PIXI.Point(Resolution.realWidth / 2 / Resolution.scale - w - 32, Resolution.realHeight / Resolution.scale - h - 24)
            }),
            new TutorialStep(
                [`The center menu is this current screen.\nIt shows your beautiful ship and\na global view of your mining work.`],
                (w, h) => {
                    return new PIXI.Point(Resolution.realWidth / 2 / Resolution.scale - w / 2, Resolution.realHeight / Resolution.scale - h - 36)
                }
            ),
            new TutorialStep([`Oh, and this, just below me,\nit's your MUST.\nThe COMETH currency`], (w, h) => {
                return new PIXI.Point(Resolution.realWidth / 2 / Resolution.scale - w / 2, Resolution.realHeight / Resolution.scale - h - 36)
            }),
            new TutorialStep(
                [`This is the GARAGE menu.\nWe'll talk about it later, but it's from here\nthat you'll be able to upgrade your ship`],
                (w, h) => {
                    return new PIXI.Point(Resolution.realWidth / 2 / Resolution.scale - w / 2 + 32, Resolution.realHeight / Resolution.scale - h - 24)
                }
            ),
            new TutorialStep([`This is the MINIMAP.\nYou can open it with the button below\nI'll tell you more about it later`], (w, h) => {
                return new PIXI.Point(Resolution.realWidth / Resolution.scale - w, Resolution.realHeight / Resolution.scale - h - 78)
            }),
            new TutorialStep(
                [
                    `To end our trip, lets talk about this.\nThis is the PULL PRICE menu.\nWhen someone wants to come to you, they have to pay\nand you can chose the price of the pull.\nClick on it to change the price`
                ],
                (w, h) => {
                    return new PIXI.Point(Resolution.realWidth / 2 / Resolution.scale - w - 24, 24)
                }
            ),
            new TutorialStep([`Well ... It's time to mine now !\nIf you need me, click on me, I'm here for you.\nHappy mining !`], (w, h) => {
                return new PIXI.Point(Resolution.realWidth / 3 / Resolution.scale, Resolution.realHeight / 2 / Resolution.scale)
            })
        ])
        this.currentComponent!.onEnd = () => {
            localStorage.setItem(this.currentComponent!.id, 'true')
        }
        this.pixi.stage.addChild(this.currentComponent!.getContainer())
    }

    public startTutorialForMap(force = false) {
        if (localStorage.getItem('mapTutorial') && !force) return

        this.currentComponent = new TutorialComponent('mapTutorial', [
            new TutorialStep([`Welcome to Cometh.\n\nThis is the MAP screen, you will be spending most of your time here.`], () => {
                return new PIXI.Point(Resolution.realWidth / 3 / Resolution.scale, Resolution.realHeight / 2 / Resolution.scale)
            }),
            new TutorialStep([`Select a miner (spaceship) or a comet\nto display its orbit.`], () => {
                return new PIXI.Point(Resolution.realWidth / 3 / Resolution.scale, Resolution.realHeight / 2 / Resolution.scale)
            }),
            new TutorialStep(
                [`Zooming on the map, you can see miner mining area.\nWhen a comet enter this area I will warn you when you can mine.`],
                () => {
                    return new PIXI.Point(Resolution.realWidth / 3 / Resolution.scale, Resolution.realHeight / 2 / Resolution.scale)
                }
            ),
            new TutorialStep(
                [
                    `To move, pull on other miners.\n\nClick on the miner you are targeting to initiate a jump.\nBE CAREFUL, jumping only goes half the distance.`
                ],
                () => {
                    return new PIXI.Point(Resolution.realWidth / 3 / Resolution.scale, Resolution.realHeight / 2 / Resolution.scale)
                }
            )
        ])
        this.currentComponent!.onEnd = () => {
            localStorage.setItem(this.currentComponent!.id, 'true')
        }
        this.pixi.stage.addChild(this.currentComponent!.getContainer())
    }

    public hasTutorial() {
        return this.currentComponent != undefined
    }

    public onResize() {
        this.currentComponent?.onResize()
    }

    public clearTutorial() {
        this.currentComponent?.getContainer().destroy()
        this.currentComponent = undefined
    }
}
