import * as PIXI from 'pixi.js'

export default class TutorialStep {
    constructor(
        public texts: string[],
        public robotPosition: (robotWidth: number, robotHeight: number) => PIXI.Point = () => {
            return new PIXI.Point(0, 0)
        }
    ) {}
}
