import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import Web3 from 'web3'
import { Transaction } from '@/game/models/Transaction'
import NotificationService from '../NotificationService'
import PlayerService from '../PlayerService'
import BN from 'bn.js'
import { Notification } from '@/game/models/notification/Notification'

export default class ConverterService {
    public static instance: ConverterService

    public static ALLOWANCE_CHANGED_EVENT = 'ALLOWANCE_CHANGED'

    public static getInstance(): ConverterService {
        if (!this.instance) {
            this.instance = new ConverterService()
        }
        return this.instance
    }

    private erc1155Abi = [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'ApprovalForAll',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256[]',
                    name: 'ids',
                    type: 'uint256[]'
                },
                {
                    indexed: false,
                    internalType: 'uint256[]',
                    name: 'values',
                    type: 'uint256[]'
                }
            ],
            name: 'TransferBatch',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                }
            ],
            name: 'TransferSingle',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'string',
                    name: 'value',
                    type: 'string'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                }
            ],
            name: 'URI',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'bytes4',
                    name: 'interfaceId',
                    type: 'bytes4'
                }
            ],
            name: 'supportsInterface',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address[]',
                    name: 'accounts',
                    type: 'address[]'
                },
                {
                    internalType: 'uint256[]',
                    name: 'ids',
                    type: 'uint256[]'
                }
            ],
            name: 'balanceOfBatch',
            outputs: [
                {
                    internalType: 'uint256[]',
                    name: '',
                    type: 'uint256[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                }
            ],
            name: 'isApprovedForAll',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256[]',
                    name: 'ids',
                    type: 'uint256[]'
                },
                {
                    internalType: 'uint256[]',
                    name: 'amounts',
                    type: 'uint256[]'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'safeBatchTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    private converterAbi = [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'onERC721Received',
            outputs: [
                {
                    internalType: 'bytes4',
                    name: '',
                    type: 'bytes4'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'onERC1155Received',
            outputs: [
                {
                    internalType: 'bytes4',
                    name: '',
                    type: 'bytes4'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'convertERC721',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'convertERC1155',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newERC1155',
                    type: 'address'
                },
                {
                    internalType: 'contract ITokenIdConverter',
                    name: 'converter',
                    type: 'address'
                }
            ],
            name: 'acceptNewERC721',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newERC1155',
                    type: 'address'
                },
                {
                    internalType: 'contract ITokenIdConverter',
                    name: 'tokenIdConverter',
                    type: 'address'
                }
            ],
            name: 'acceptNewERC1155',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'modelIdFor',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        }
    ]

    private readErc1155: any
    private writeErc1155: any
    private readMules: any
    private writeMules: any

    private readConverter: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readErc1155 = new wssWeb3.eth.Contract(this.erc1155Abi, config.erc1155Address)
        this.readConverter = new wssWeb3.eth.Contract(this.converterAbi, config.converterAddress)
        this.readMules = new wssWeb3.eth.Contract(this.erc1155Abi, config.mulesAddress)

        const writeWeb3 = Web3Service.getInstance().getWeb3()
        this.writeMules = new writeWeb3.eth.Contract(this.erc1155Abi, config.mulesAddress)
        this.writeErc1155 = new writeWeb3.eth.Contract(this.erc1155Abi, config.erc1155Address)
    }
    public async tokensOfConnected(): Promise<Map<number, number[]>> {
        const account: string = await Web3Service.getInstance().getAccount()

        const accounts = []
        const ids = []
        for (let i = 0; i < config.erc1155Ids.length; i++) {
            accounts[i] = account
            ids[i] = config.erc1155Ids[i]
        }

        const balances = await this.readErc1155.methods.balanceOfBatch(accounts, ids).call()

        const tokens = new Map<number, number[]>()
        if (balances === null) return tokens
        for (let i = 0; i < config.erc1155Ids.length; i++) {
            if (balances[i] != 0) {
                const modelId = await this.modelIdFor(config.erc1155Address, config.erc1155Ids[i])
                const token = []
                for (let j = 0; j < balances[i]; j++) {
                    token.push(modelId)
                }
                tokens.set(config.erc1155Ids[i], token)
            }
        }

        const mulesBalance = parseInt(await this.readMules.methods.balanceOf(account, config.mulesId).call())
        const mulesModelId = await this.modelIdFor(config.mulesAddress, config.mulesId)

        if (mulesBalance > 0) {
            const mulesTokens = new Array(mulesBalance).fill(mulesModelId)
            console.log(mulesTokens)
            tokens.set(config.mulesId, mulesTokens)
        }
        return tokens
    }

    public async modelIdFor(token: string, id: number): Promise<number> {
        const modelId = await this.readConverter.methods.modelIdFor(token, id).call()
        return modelId
    }

    public async erc1155SafeTransferFrom(to: string, tokenId: string, data: number) {
        const web3 = Web3Service.getInstance().getWeb3()
        const account: string = await Web3Service.getInstance().getAccount()

        let contract = this.writeErc1155
        if (tokenId === config.mulesId.toString()) {
            contract = this.writeMules
        }

        await contract.methods
            .safeTransferFrom(account, to, tokenId, 1, web3.eth.abi.encodeParameter('uint256', data))
            .send({ from: account, gasPrice: '5000000000', gasLimit: '8000000' })
    }
}
