<template>
    <div v-for="item in items" :key="item.price">
        <div class="relative flex flex-col rounded-lg shadow-lg overflow-hidden">
            <img :src="item.imgUrl" class="w-32 lg:w-40 -right-10 lg:right-0 -top-6 -rotate-45  absolute transform" />

            <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                <div>
                    <h3
                        class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-blue-100 text-blue-600"
                        id="tier-standard"
                    >
                        {{ item.name }} Starter Kit
                    </h3>
                    <!-- <h4
                        v-if="item.soldout"
                        class="inline-flex ml-1 px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-red-100 text-blue-600"
                        id="tier-standard"
                    >
                        soldout
                    </h4> -->
                </div>
                <div class="mt-4 flex items-baseline text-5xl font-extrabold font-audiowide">
                    {{ parseFloat(item.price) + 40.5 }}
                    <span class="ml-1 text-2xl font-medium text-gray-500">
                        Matic
                    </span>
                </div>
                <p class="mt-5 text-lg text-gray-500">{{ item.description.substr(0, 90) }}...</p>
            </div>
            <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                <ul class="space-y-4">
                    <!-- <li class="flex items-start">
                        <div class="flex-shrink-0">
                            <XIcon class="h-6 w-6 text-red-500" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            Allowed in Polygon Grand Prix
                        </p>
                    </li> -->

                    <li class="flex items-start">
                        <div class="flex-shrink-0">
                            <CheckIcon class="h-6 w-6 text-green-500" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            Can mine juicy comets
                        </p>
                    </li>
                    <li class="flex items-start">
                        <div class="flex-shrink-0">
                            <CheckIcon class="h-6 w-6 text-green-500" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                            Unique look & feel
                        </p>
                    </li>
                </ul>
                <div class="rounded-md shadow">
                    <div
                        v-if="1"
                        class="flex items-center justify-center px-5 py-3 border border-gray-300 text-base font-medium rounded-md bg-gray-300 text-gray-500 "
                    >
                        Sold out
                    </div>
                    <router-link
                        v-else
                        :to="`/shop/starterkit/${item.url}`"
                        class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400"
                        aria-describedby="tier-standard"
                    >
                        Get started
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./ShopItemList.ts"></script>
