import Colors from '@/helpers/Colors'
import { msToTime, msToTimeHMFormat } from '@/helpers/TimeHelper'
import * as PIXI from 'pixi.js'
import Miner from '../models/Miner'
import StakingComet from '../models/StakingComet'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import PlayerService from '../services/PlayerService'
import StakingCometContractService from '../services/web3/Cometh/StakingCometContractService'
import MinerPlayerComponent from './MinerPlayerComponent'
import BN from 'bn.js'
import Web3 from 'web3'
import TimeService from '../services/TimeService'
import { cutNumber } from '@/helpers/StringHelper'

class MinerInComet extends PIXI.Container {
    public text = new MainBitmapText('...', { fontSize: 4 })
    public reward = -1
    public depositTime = -1
    private bg = PIXI.Sprite.from('comet_stack_miner_container')
    public minerId = ''

    constructor(public miner: Miner, public stakingCometContractService: StakingCometContractService) {
        super()
        this.minerId = miner.id
        this.addChild(this.bg)

        const minerComp = new MinerPlayerComponent(miner, false)
        minerComp.update(this.miner)
        const minerComponent = minerComp.container
        minerComponent.scale.set(1 / 12)
        minerComponent.x = Math.floor(this.bg.width / 2 - minerComponent.width / 2) + 0.33
        minerComponent.y = Math.floor(this.bg.height / 2 - minerComponent.height / 2) + 0.33
        this.addChild(minerComponent)

        this.stakingCometContractService.rewardOf(this.miner.id).then(it => (this.reward = it))
        this.stakingCometContractService.depositTime(this.miner.id).then(it => (this.depositTime = it))

        this.addChild(this.text)
        this.text.y = 13
        this.text.x = Math.floor(this.bg.width / 2 - this.text.width / 2) + 0.33

        this.hitArea = new PIXI.Rectangle(0, 0, this.bg.width, this.bg.height)
    }

    public update() {
        if (this.depositTime > 0) {
            this.text.text = msToTimeHMFormat((TimeService.getInstance().now() - this.depositTime) * 1000)
        } else {
            this.text.text = '...'
        }
        this.text.x = Math.floor(this.bg.width / 2 - this.text.textWidth / 2) + 0.33
    }
}

export default class StakedCometShipListComponent extends PIXI.Container {
    private shipListContainer = new PIXI.Container()
    private detailContainer = new PIXI.Container()
    public miners: MinerInComet[] = []
    private index = 0
    private prevBtn = PIXI.Sprite.from('comet_stack_list_prev')
    private nextBtn = PIXI.Sprite.from('comet_stack_list_next')
    private prevBtnText = new MainBitmapText('0', { fontSize: 4 })
    private nextBtnText = new MainBitmapText('0', { fontSize: 4 })

    private detailTime = new MainBitmapText('12h 43m 53s', { fontSize: 4 })
    private detailGain = new MainBitmapText('0.000025', { fontSize: 4 })
    private detailGainToken = new MainBitmapText('MUST', { fontSize: 4 })

    private comete?: StakingComet
    private stakingCometContractService?: StakingCometContractService
    private overringMiner?: MinerInComet
    constructor() {
        super()

        this.addChild(this.shipListContainer)
        this.addChild(this.prevBtn)
        this.addChild(this.nextBtn)
        this.addChild(this.prevBtnText)
        this.addChild(this.nextBtnText)

        this.detailContainer.visible = false
        this.addChild(this.detailContainer)
        const bg = PIXI.Sprite.from('comet_stack_ship_detail_bg')
        this.detailContainer.addChild(bg)
        this.detailContainer.addChild(this.detailTime)
        this.detailContainer.addChild(this.detailGain)
        this.detailContainer.addChild(this.detailGainToken)

        this.detailTime.y = 5
        this.detailTime.x = 14

        this.detailGain.y = 13
        this.detailGain.x = 14
        this.detailGainToken.y = 17
        this.detailGainToken.x = 14
        this.detailGainToken.tint = Colors.Blue800

        this.prevBtn.x = 0
        this.prevBtn.y = 1
        this.prevBtnText.y = 15.333

        this.nextBtn.x = 118
        this.nextBtn.y = 1
        this.nextBtnText.y = 15.333

        this.nextBtn.interactive = true
        this.nextBtn.cursor = 'pointer'
        this.nextBtn.on('pointertap', () => {
            this.index++
            this.updatePagination()
        })

        this.prevBtn.interactive = true
        this.prevBtn.cursor = 'pointer'
        this.prevBtn.on('pointertap', () => {
            this.index--
            this.updatePagination()
        })

        this.update()
    }

    updateComet(comet: StakingComet) {
        this.index = 0
        this.comete = comet
        this.stakingCometContractService = new StakingCometContractService(this.comete)
        this.update()
    }

    update() {
        if (this.comete == undefined) return

        this.miners = this.comete.playerMiners.map(it => {
            const existingMiner = this.miners.find(m => m.minerId == it.id)
            if (existingMiner) return existingMiner

            const minerContainer = new MinerInComet(it, this.stakingCometContractService!)
            minerContainer.interactive = true
            minerContainer.cursor = 'pointer'
            minerContainer.on('mouseover', () => {
                this.detailContainer.visible = true
                this.detailContainer.y = 21
                this.detailContainer.x = Math.floor(minerContainer.x + minerContainer.width / 2 - this.detailContainer.width / 2)
                this.overringMiner = minerContainer
                this.updateDetail()
            })
            minerContainer.on('mouseout', () => {
                this.detailContainer.visible = false
                this.overringMiner = undefined
            })
            minerContainer.on('pointertap', () => {
                const foundMiner = PlayerService.getInstance().miningPlayers.find(a => a.id == it.id)
                if (foundMiner) {
                    PlayerService.getInstance().updateMainPlayer(foundMiner)
                }
            })
            return minerContainer
        })
        this.updatePagination()

        this.visible = this.miners.length > 0
    }

    public refreshData() {
        this.miners.slice(this.index, 5).forEach(it => {
            it.update()
        })
        this.updateDetail()
    }

    private updatePagination() {
        this.shipListContainer.removeChildren()

        this.nextBtn.alpha = this.isNextVisible ? 1 : 0.3
        this.nextBtn.interactive = this.isNextVisible
        this.nextBtnText.alpha = this.isNextVisible ? 1 : 0.3

        this.prevBtn.alpha = this.isPrevVisible ? 1 : 0.3
        this.prevBtn.interactive = this.isPrevVisible
        this.prevBtnText.alpha = this.isPrevVisible ? 1 : 0.3

        this.prevBtnText.text = Math.max(this.index, 0).toFixed(0)
        this.nextBtnText.text = Math.max(0, this.miners.length - this.index - 5).toFixed(0)

        this.prevBtnText.x = Math.floor(4 - this.prevBtnText.width / 2) + 0.33
        this.nextBtnText.x = Math.floor(126 - this.nextBtnText.width / 2) + 0.33

        for (let i = 0; i < Math.min(5, this.miners.length); i++) {
            const miner = this.miners[i + this.index]
            miner.update()
            this.shipListContainer.addChild(miner)
            miner.x = this.prevBtn.width + this.prevBtn.x + 1 + 21 * i
        }
    }

    private async updateDetail() {
        if (this.comete && this.overringMiner) {
            this.detailTime.text = msToTime((TimeService.getInstance().now() - this.overringMiner.depositTime) * 1000)
            const gain = cutNumber(
                Web3.utils.fromWei(this.comete.rate.muln(this.overringMiner.miner.roverPower).muln(TimeService.getInstance().now() - this.overringMiner.depositTime).divn(100), 'ether')
            )
            this.detailGain.text = gain.length > 17 ? gain.substring(0, 14) + '...' : gain
            this.detailGainToken.text = this.comete?.symbol
        }
    }

    get isNextVisible() {
        return this.miners.length > 5 && this.index + 5 < this.miners.length
    }

    get isPrevVisible() {
        return this.miners.length > 5 && this.index > 0
    }
}
