import { Notification } from './Notification'
import { Transaction } from '../Transaction'

export class TransactionNotification extends Notification {
    constructor(
        public transaction: Transaction,
        public onNotificationSuccess = () => {
            return
        }
    ) {
        super()
    }
}
