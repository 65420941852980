import axios from 'axios'

export default class ComethSwapTokenService {
    public static instance: ComethSwapTokenService

    private source = 'https://unpkg.com/@cometh-game/default-token-list@latest/build/comethswap-default.tokenlist.json'
    private tokenlist?: any

    public static getInstance(): ComethSwapTokenService {
        if (!this.instance) {
            this.instance = new ComethSwapTokenService()
        }
        return this.instance
    }

    async getTokensAddresses(): Promise<{address:string; decimals:number}[]> {
        await this.fetchMetadata()
        return this.tokenlist.tokens

    }

    async getImageURL(symbol: string): Promise<string> {
        await this.fetchMetadata()
        if (symbol == 'WETH') symbol = 'ETH'
        const token = this.tokenlist.tokens.find((token: any) => token.symbol.toLowerCase() == symbol.toLowerCase());
        if(!token) return ''
        return token.logoURI
    }

    private async fetchMetadata() {
        if (this.tokenlist != null) return
        this.tokenlist = (await axios.get(this.source)).data
    }
}
