import Web3 from 'web3'

export class CurrencyHelper {
    public static weiToEthRounded(amount: string, precision = 3) {
        const floatAmount = parseFloat(Web3.utils.fromWei(amount.toString(), 'ether'))
        return Number(floatAmount.toFixed(precision))
    }

    public static weiToEthFull(amount: string) {
        const floatAmount = parseFloat(Web3.utils.fromWei(amount.toString(), 'ether'))
        return floatAmount
    }
}
