import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import Miner from '../models/Miner'
import Colors from '@/helpers/Colors'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import PlayerService from '../services/PlayerService'

export default class ShipCharacteristicsComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public title!: PIXI.BitmapText
    public raceRarity!: PIXI.BitmapText
    public drillTitle!: PIXI.BitmapText
    public radarTitle!: PIXI.BitmapText
    public cooldownTitle!: PIXI.BitmapText
    public roverPowerTitle!: PIXI.BitmapText
    public drillValue!: PIXI.BitmapText
    public radarValue!: PIXI.BitmapText
    public cooldownValue!: PIXI.BitmapText
    public roverPowerValue!: PIXI.BitmapText
    public drillIcon: PIXI.Sprite = PIXI.Sprite.from('ic_drill')
    public radarIcon: PIXI.Sprite = PIXI.Sprite.from('ic_radar')
    public cooldownIcon: PIXI.Sprite = PIXI.Sprite.from('ic_drill_cooldown')
    public machineryIcon: PIXI.Sprite = PIXI.Sprite.from('ic_machinery')
    public mineStatus!: PIXI.BitmapText
    public miner!: Miner
    public width = 100
    public lastCooldown = 1
    private isReady = false

    public async setup(miner: Miner) {
        this.container.removeChildren()
        this.miner = miner
        this.lastCooldown = 1
        this.title = new MainBitmapText(await this.miner.name(), {
            fontSize: 5
        })
        this.raceRarity = new MainBitmapText((await this.miner.rarity()) + ' ' + (await this.miner.race()), {
            fontSize: 5
        })

        this.title.x = Math.floor(this.width / 2 - this.title.width / 2)
        this.title.y = 4

        this.raceRarity.x = Math.floor(this.width / 2 - this.raceRarity.width / 2)
        this.raceRarity.y = 10

        this.radarIcon.x = 6
        this.radarIcon.y = 21
        this.drillIcon.x = 6
        this.drillIcon.y = this.radarIcon.y + this.radarIcon.height + 2
        this.cooldownIcon.x = 6
        this.cooldownIcon.y = this.drillIcon.y + this.drillIcon.height + 2
        this.machineryIcon.x = 6
        this.machineryIcon.y = this.cooldownIcon.y + this.cooldownIcon.height + 2

        this.drillTitle = new MainBitmapText('Mining Power', {
            fontSize: 4
        })
        this.drillTitle.tint = Colors.Blue800
        this.drillTitle.x = Math.floor(this.width / 2 - this.drillTitle.width / 2)
        this.drillTitle.y = this.drillIcon.y + 1
        this.drillValue = new MainBitmapText(this.miner.drill + '', {
            fontSize: 5
        })
        this.drillValue.x = Math.floor(this.width / 2 - this.drillValue.width / 2)
        this.drillValue.y = this.drillIcon.y + 7

        this.radarTitle = new MainBitmapText('Mining Range', {
            fontSize: 4
        })
        this.radarTitle.tint = Colors.Blue800
        this.radarTitle.x = Math.floor(this.width / 2 - this.radarTitle.width / 2)
        this.radarTitle.y = this.radarIcon.y + 1
        this.radarValue = new MainBitmapText(this.miner.miningArea + ' km', {
            fontSize: 5
        })
        this.radarValue.x = Math.floor(this.width / 2 - this.radarValue.width / 2)
        this.radarValue.y = this.radarIcon.y + 7

        this.cooldownTitle = new MainBitmapText('Mining Cooldown', {
            fontSize: 4
        })
        this.cooldownTitle.tint = Colors.Blue800
        this.cooldownTitle.x = Math.floor(this.width / 2 - this.cooldownTitle.width / 2)
        this.cooldownTitle.y = this.cooldownIcon.y + 1
        this.cooldownValue = new MainBitmapText(this.miner.cooldown + ' s', {
            fontSize: 5
        })
        this.cooldownValue.x = Math.floor(this.width / 2 - this.cooldownValue.width / 2)
        this.cooldownValue.y = this.cooldownIcon.y + 7

        const machinery = this.miner.roverPower + ''
        this.roverPowerTitle = new MainBitmapText('Rover Power', {
            fontSize: 4
        })
        this.roverPowerTitle.tint = Colors.Blue800
        this.roverPowerTitle.x = Math.floor(this.width / 2 - this.roverPowerTitle.width / 2)
        this.roverPowerTitle.y = this.machineryIcon.y + 1
        this.roverPowerValue = new MainBitmapText(machinery, {
            fontSize: 5
        })
        this.roverPowerValue.x = Math.floor(this.width / 2 - this.roverPowerValue.width / 2)
        this.roverPowerValue.y = this.machineryIcon.y + 7

        this.mineStatus = new MainBitmapText(' ', {
            fontSize: 5
        })
        this.mineStatus.x = Math.floor(this.width / 2 - this.mineStatus.width / 2)
        this.mineStatus.y = this.machineryIcon.y + this.machineryIcon.height + 6

        this.container.addChild(this.title)
        this.container.addChild(this.raceRarity)
        this.container.addChild(this.drillTitle)
        this.container.addChild(this.radarTitle)
        this.container.addChild(this.cooldownTitle)
        this.container.addChild(this.roverPowerTitle)
        this.container.addChild(this.drillValue)
        this.container.addChild(this.radarValue)
        this.container.addChild(this.cooldownValue)
        this.container.addChild(this.roverPowerValue)
        this.container.addChild(this.drillIcon)
        this.container.addChild(this.radarIcon)
        this.container.addChild(this.cooldownIcon)
        this.container.addChild(this.machineryIcon)
        this.container.addChild(this.mineStatus)
        this.onResize()
        this.checkMiningStatus()
        this.isReady = true

        PlayerService.getInstance().setOnCurrentPlayerChange(miner => {
            if (miner.id == this.miner.id) {
                this.miner = miner
                this.checkMiningStatus()
            }
        })
    }

    tick(): void {
        if (this.isReady) {
            this.checkMiningStatus()
        }
    }

    onResize(): void {
        // On resize
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await callback()
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    private checkMiningStatus() {
        const isCoolIn = this.miner.isCoolIn()
        if (isNaN(isCoolIn)) return
        if ((isCoolIn <= 0 && this.lastCooldown > 0) || this.miner.lastAction == 0) {
            this.mineStatus.text = 'Mining is ready'
            this.mineStatus.tint = Colors.Green500

            this.mineStatus.x = Math.floor(this.width / 2 - this.mineStatus.width / 2)
            this.lastCooldown = isCoolIn
        } else if (isCoolIn > 0 && this.lastCooldown != isCoolIn) {
            this.mineStatus.text = 'Mining is ready in ' + isCoolIn + 's'
            this.mineStatus.tint = Colors.Red500

            this.mineStatus.x = Math.floor(this.width / 2 - this.mineStatus.width / 2)
            this.lastCooldown = isCoolIn
        }
    }
}
