import Web3Service from '@/game/services/Web3Service'
import axios from 'axios'
import BN from 'bignumber.js'
import Web3 from 'web3'

class USDPriceCache {
    token = ''
    price = 0
    updatedAt = 0
}

export default class WalletService {
    public static instance: WalletService

    public static getInstance(): WalletService {
        if (!this.instance) {
            this.instance = new WalletService()
        }
        return this.instance
    }
    public static MATIC_MUST_PAIR_ADDRESS = '0x80676b414a905de269d0ac593322af821b683b92'
    public static GHS_MUST_PAIR_ADDRESS = '0x70640c8e29561f3704b1014e036baaa46640d0e2'

    public static PICKLE_MUST_PAIR_ADDRESS = '0xb0b5e3bd18eb1e316bcd0bba876570b3c1779c55'
    public static MUST_WETH_PAIR_ADDRESS = '0x8826c072657983939c26e684edcfb0e4133f0b3d'
    public static BBADGER_MUST_PAIR_ADDRESS = '0x8ecd4872ca4e2496872664377780071afeb7a85a'
    public static BDIGG_MUST_PAIR_ADDRESS = '0x6caa4da1de0924bccc29b4a3ffd34b59ff399375'
    public static ALCX_MUST_PAIR_ADDRESS = '0x10061571459eaea3c60dad42cce71b970f086297'
    public static GHST_MUST_PAIR_ADDRESS = '0x70640c8e29561f3704b1014e036baaa46640d0e2'
    public static MUST_ADDRESS = '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f'

    private uniPairAbi = [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount0',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount1',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                }
            ],
            name: 'Burn',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount0',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount1',
                    type: 'uint256'
                }
            ],
            name: 'Mint',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount0In',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount1In',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount0Out',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount1Out',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                }
            ],
            name: 'Swap',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint112',
                    name: 'reserve0',
                    type: 'uint112'
                },
                {
                    indexed: false,
                    internalType: 'uint112',
                    name: 'reserve1',
                    type: 'uint112'
                }
            ],
            name: 'Sync',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            inputs: [],
            name: 'DOMAIN_SEPARATOR',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'MINIMUM_LIQUIDITY',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [],
            name: 'PERMIT_TYPEHASH',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                }
            ],
            name: 'allowance',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                }
            ],
            name: 'approve',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                }
            ],
            name: 'burn',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amount0',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount1',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'decimals',
            outputs: [
                {
                    internalType: 'uint8',
                    name: '',
                    type: 'uint8'
                }
            ],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [],
            name: 'factory',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getReserves',
            outputs: [
                {
                    internalType: 'uint112',
                    name: 'reserve0',
                    type: 'uint112'
                },
                {
                    internalType: 'uint112',
                    name: 'reserve1',
                    type: 'uint112'
                },
                {
                    internalType: 'uint32',
                    name: 'blockTimestampLast',
                    type: 'uint32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'initialize',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'kLast',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                }
            ],
            name: 'mint',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'liquidity',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                }
            ],
            name: 'nonces',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'deadline',
                    type: 'uint256'
                },
                {
                    internalType: 'uint8',
                    name: 'v',
                    type: 'uint8'
                },
                {
                    internalType: 'bytes32',
                    name: 'r',
                    type: 'bytes32'
                },
                {
                    internalType: 'bytes32',
                    name: 's',
                    type: 'bytes32'
                }
            ],
            name: 'permit',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'price0CumulativeLast',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'price1CumulativeLast',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                }
            ],
            name: 'skim',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amount0Out',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount1Out',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'swap',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [],
            name: 'sync',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'token0',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'token1',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                }
            ],
            name: 'transfer',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                }
            ],
            name: 'transferFrom',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    public usdPriceCache: Map<string, USDPriceCache> = new Map()

    public async balanceUSD(account: string, token: string): Promise<any> {
        const balance = parseFloat(Web3.utils.fromWei(await this.balanceOf(account, token)))
        const price = await this.USDPriceOf(token)
        return price * balance
    }

    public async USDPriceOf(token: string) {
        const cache = this.usdPriceCache.get(token)
        if (cache && Date.now() - cache.updatedAt < 60000) {
            return cache.price
        }

        let price = 0
        if (token == '0x0000000000000000000000000000000000000000') {
            price = await this.maticPrice()
        }
        //WRAPPED WAMTIC
        else if (token == '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270') {
            price = await this.maticPrice()
        } else if (token == '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f') {
            price = await this.mustPrice()
        } else {
            const request = {
                query: '{pair(id: "' + token.toLowerCase() + '") {id   reserveUSD totalSupply   reserve1 reserve0  token0{ name } token1{ name }}}'
            }
            const thegraphurl = 'https://api.thegraph.com/subgraphs/name/cometh-game/comethswap'

            try {
                const result = await axios.post(thegraphurl, request)
                const pair = result.data['data']['pair']

                if (!pair) {
                    return 0
                }

                price = parseInt(pair['reserveUSD'], 10) / parseFloat(pair['totalSupply'])
            } catch (e) {
                return 0
            }
        }

        const usdCache = new USDPriceCache()
        usdCache.token = token
        usdCache.price = price
        usdCache.updatedAt = Date.now()
        this.usdPriceCache.set(token, usdCache)
        return price
    }

    public async maticPrice(): Promise<any> {
        const request = {
            query:
                '{pair(id: "0x80676b414a905de269d0ac593322af821b683b92") {id   reserveUSD totalSupply   reserve1 reserve0  token0{ name } token1{ name }}}'
        }

        const result = await axios.post('https://api.thegraph.com/subgraphs/name/cometh-game/comethswap', request)
        const pair = result.data['data']['pair']

        return parseInt(pair['reserveUSD'], 10) / 2 / parseInt(pair['reserve0'], 10)
    }

    public async mustPrice(): Promise<any> {
        const request = {
            query:
                '{pair(id: "0x12a2abcfcadc04681929f0c199bdf812967207e4") {id   reserveUSD totalSupply   reserve1 reserve0  token0{ name } token1{ name }}}'
        }

        const result = await axios.post('https://api.thegraph.com/subgraphs/name/cometh-game/comethswap', request)
        const pair = result.data['data']['pair']
        console.log('GOT PAIRR', pair)
        return parseInt(pair['reserve0'], 10) / parseInt(pair['reserve1'], 10)
    }

    public async balanceOf(account: string, token: string): Promise<any> {
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        const pair = new wssWeb3.eth.Contract(this.uniPairAbi, token)
        try {
            return await pair.methods.balanceOf(account).call()
        } catch (e) {
            return new BN(0)
        }
    }

    public async balanceOfConnected(token: string): Promise<any> {
        const account = await Web3Service.getInstance().getAccount()
        try {
            return await this.balanceOf(account, token)
        } catch (e) {
            return new BN(0)
        }
    }

    public async balanceUSDOfConnected(token: string): Promise<any> {
        const account = await Web3Service.getInstance().getAccount()
        try {
            return await this.balanceUSD(account, token)
        } catch (e) {
            return 0
        }
    }
}
