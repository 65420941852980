import config from '@/config'
import Web3Service from '@/game/services/Web3Service'

export default class CometManagerService {
    public static NEW_COMET_EVENT = 'NewComet'
    public static NEW_STAKING_COMET_EVENT = 'NewStakingComet'
    public static REMOVE_COMET_EVENT = 'RemoveComet'

    public static instance: CometManagerService

    public static getInstance(): CometManagerService {
        if (!this.instance) {
            this.instance = new CometManagerService()
        }
        return this.instance
    }

    private abi = [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'balance',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'unit',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'NewComet',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'balance',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'capacity',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'rate',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'NewStakingComet',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'RemoveComet',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updateSolarSystemStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'int256',
                    name: 'x',
                    type: 'int256'
                },
                {
                    internalType: 'int256',
                    name: 'y',
                    type: 'int256'
                },
                {
                    internalType: 'uint32',
                    name: 'distance',
                    type: 'uint32'
                },
                {
                    internalType: 'uint16',
                    name: 'rotationSpeed',
                    type: 'uint16'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'addComet',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'removeComet',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'time',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'cometPosition',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'int256',
                            name: 'x',
                            type: 'int256'
                        },
                        {
                            internalType: 'int256',
                            name: 'y',
                            type: 'int256'
                        }
                    ],
                    internalType: 'struct PositionsV2.Cartesian',
                    name: '',
                    type: 'tuple'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'getComet',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'id',
                            type: 'address'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'orbit',
                            type: 'tuple'
                        },
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'unit',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'balance',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'solarSystemID',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct ICometManagerV4.Comet',
                    name: '',
                    type: 'tuple'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'getStakingComet',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'id',
                            type: 'address'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'orbit',
                            type: 'tuple'
                        },
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'balance',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'rate',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'capacity',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'roverCount',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'cumulatedRate',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'collectable',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'lastUpdate',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'solarSystemID',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct ICometManagerV4.StakingComet',
                    name: '',
                    type: 'tuple'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'cometsFrom',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'id',
                            type: 'address'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'orbit',
                            type: 'tuple'
                        },
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'unit',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'balance',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'solarSystemID',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct ICometManagerV4.Comet[]',
                    name: '',
                    type: 'tuple[]'
                },
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'id',
                            type: 'address'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'orbit',
                            type: 'tuple'
                        },
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'balance',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'rate',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'capacity',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'roverCount',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'cumulatedRate',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'collectable',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'lastUpdate',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'solarSystemID',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct ICometManagerV4.StakingComet[]',
                    name: '',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'countCometIn',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        }
    ]

    private wssContract: any

    constructor() {
        this.initContract()
        this.listenNewCometEvent()
        this.listenNewStakingCometEvent()
        this.listenRemoveCometEvent()
    }

    public initContract() {
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.wssContract = new wssWeb3.eth.Contract(this.abi, config.cometManagerAddress)
    }

    public async cometsInSolarSystem(solarSystemID: number) {
        try {
            return await this.wssContract.methods.cometsFrom(solarSystemID).call()
        } catch (error) {
            console.log(error)
        }
        return [[], []]
    }

    public async getComet(cometId: string, solarSystemID: number) {
        return await this.wssContract.methods.getComet(cometId, solarSystemID).call()
    }

    public async getStakingComet(cometId: string, solarSystemID: number) {
        return await this.wssContract.methods.getStakingComet(cometId, solarSystemID).call()
    }

    public async listenNewCometEvent() {
        await this.wssContract.events
            .NewComet()
            .on('data', function(event: any) {
                document.dispatchEvent(new CustomEvent(CometManagerService.NEW_COMET_EVENT, { detail: event.returnValues }))
            })
            .on('error', function(error: any) {
                console.log('ERROR: listenNewCometEvent', error)
            })
    }

    public async listenNewStakingCometEvent() {
        await this.wssContract.events
            .NewStakingComet()
            .on('data', function(event: any) {
                document.dispatchEvent(new CustomEvent(CometManagerService.NEW_STAKING_COMET_EVENT, { detail: event.returnValues }))
            })
            .on('error', function(error: any) {
                console.log('ERROR: listenNewStakingCometEvent', error)
            })
    }

    public async listenRemoveCometEvent() {
        await this.wssContract.events
            .RemoveComet()
            .on('data', function(event: any) {
                document.dispatchEvent(new CustomEvent(CometManagerService.REMOVE_COMET_EVENT, { detail: event.returnValues }))
            })
            .on('error', function(error: any) {
                console.log('ERROR: listenRemoveCometEvent', error)
            })
    }
}
