import TubePoolsData from '@/TubePoolsData'
import * as PIXI from 'pixi.js'

export default class TubeLoaderService {
    public static instance: TubeLoaderService

    public timeDiff = 0

    public static getInstance(): TubeLoaderService {
        if (!this.instance) {
            this.instance = new TubeLoaderService()
        }

        return this.instance
    }

    public loader: PIXI.Loader = new PIXI.Loader()

    public load() {
        this.loader.add('tube_bg_frame', '/assets/img/tube/tube_bg_frame.png')
        this.loader.add('tube_bg_left', '/assets/img/tube/tube_bg_left.json')
        this.loader.add('tube_bg_left_repeat', '/assets/img/tube/tube_bg_left_repeat.png')
        this.loader.add('tube_logo', '/assets/img/tube/tube_logo.json')
        this.loader.add('tube_prof', '/assets/img/tube/tube_prof.json')
        this.loader.add('tube_top_tube', '/assets/img/tube/tube_top_tube.png')
        this.loader.add('tube_space_bg', '/assets/img/tube/tube_space_bg.png')
        this.loader.add('tube_end', '/assets/img/tube/tube_end.png')
        this.loader.add('tube_end_repeat', '/assets/img/tube/tube_end_repeat.png')
        this.loader.add('tube_info_bg', '/assets/img/tube/tube_info_bg.png')
        this.loader.add('pixel', '/assets/img/pixel.png')

        // TODO REFACTOR for each tube

        this.loader.add('tube_component_1', '/assets/img/tube/tube/1.png')
        this.loader.add('tube_component_1_hover', '/assets/img/tube/tube/1_hover.png')
        this.loader.add('tube_component_1_animation', '/assets/img/tube/tube/1_animation.json')

        TubePoolsData.data.forEach(it => {
            this.loader.add(it.placeHolder, '/assets/img/tube/img/' + it.placeHolder + '.png')
        })
        // TODO items

        setTimeout(() => {
            this.loader.load()
        }, 1000) // TODO find better solution to wait font loaded
    }
}
