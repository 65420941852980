import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import Resolution from '@/helpers/Resolution'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import Colors from '@/helpers/Colors'
import MinerPlayerComponent from './MinerPlayerComponent'
import Miner from '../models/Miner'
import { animate } from '@/helpers/AnimationHelper'
import TextButton from './TextButton'
import PlayerService from '../services/PlayerService'

export default class MinerJumpPreviewComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public mask = PIXI.Sprite.from('preview_mask')
    public circle = PIXI.Sprite.from('preview_circle')
    public title = new MainBitmapText('...', { fontSize: 5 })
    public pullTitle = new MainBitmapText('PULL $', { fontSize: 4 })
    public pullValue = new MainBitmapText('...', { fontSize: 4 })
    public coord = new MainBitmapText('', { fontSize: 4 })
    public closeBtn = PIXI.Sprite.from('ic_preview_close')
    public selectBtn = new TextButton('Select')
    public onRequireClose: () => void = () => {
        return
    }
    public previewBtn = PIXI.Sprite.from('ic_preview_more')
    public onRequirePreview: (miner: Miner) => void = () => {
        return
    }
    public onExitPreview: () => void = () => {
        return
    }

    public onOpening: (perc: number) => void = () => {
        return
    }
    public onClosing: (perc: number) => void = () => {
        return
    }

    public miner?: Miner
    public minerComponent = new MinerPlayerComponent(new Miner({ id: 0 }))

    constructor() {
        this.circle = PIXI.Sprite.from('preview_circle')
        this.circle.x = 111
        this.mask.x = 112
        this.mask.y = 1
        const bar = PIXI.Sprite.from('preview_miner_bar')

        this.minerComponent.getContainer().scale.set(1 / 3 / 2)
        this.minerComponent.getContainer().position.x = Math.floor(this.circle.x + 24 - this.minerComponent.getContainer().width / 2)
        this.minerComponent.getContainer().position.y = Math.floor(this.circle.y + 24 - this.minerComponent.getContainer().height / 2)

        this.selectBtn.container.x = 6
        this.selectBtn.container.y = 33
        this.selectBtn.btn.texture = PIXI.Texture.from('pixel')
        this.selectBtn.btn.height = 10
        this.selectBtn.text.fontSize = 5
        this.selectBtn.btn.tint = Colors.Green300
        this.selectBtn.btn.width += 4
        this.selectBtn.onResize()
        this.selectBtn.container.on('pointertap', () => {
            if (this.miner) {
                PlayerService.getInstance().updateMainPlayer(this.miner)
            }
        })

        this.pullTitle.tint = Colors.Blue800
        this.container.addChild(this.circle)
        this.container.addChild(bar)
        this.container.addChild(this.title)
        this.container.addChild(this.pullTitle)
        this.container.addChild(this.pullValue)
        this.container.addChild(this.coord)
        this.container.addChild(this.mask)
        this.container.addChild(this.selectBtn.container)
        this.container.addChild(this.minerComponent.getContainer())
        this.minerComponent.getContainer().mask = this.mask
        this.container.addChild(this.closeBtn)
        this.handleCloseBtn()
        this.container.addChild(this.previewBtn)
        this.handlePreview()

        this.container.alpha = 0
        this.container.visible = false
        this.refreshData()

        document.addEventListener('serverPostTick', () => {
            this.refreshCoords()
        })
        this.onResize()
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        this.container.scale.set(Resolution.scale)

        this.title.x = 108 - this.title.textWidth
        this.title.y = 15

        this.pullTitle.y = 24

        this.pullValue.x = 108 - this.pullValue.width
        this.pullValue.y = 24

        this.pullTitle.x = this.pullValue.x - this.pullTitle.width - 2
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    async enterAnimation(callback: () => void): Promise<void> {
        if (this.container.alpha == 1) return
        this.container.visible = true
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.alpha = perc
            this.container.y = 0 + Resolution.margin6 * perc
            this.onOpening(perc)
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        if (this.container.alpha < 1) return
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.alpha = 1 - perc
            this.container.y = Resolution.margin6 - Resolution.margin6 * perc
            this.onClosing(perc)
        })
        this.container.visible = false
        callback()
    }

    public update(miner?: Miner) {
        this.miner = miner
        if (miner) {
            this.minerComponent.update(miner)
            this.refreshData()
        }
    }

    private async refreshData() {
        if (this.miner?.isSelf) {
            this.selectBtn.container.visible = true
        } else {
            this.selectBtn.container.visible = false
        }

        this.title.text = '...'
        this.pullValue.text = '...'
        this.onResize()
        if (this.miner != undefined) {
            this.refreshCoords()
            this.title.text = await this.miner.name()
            this.pullValue.text = this.miner.pullPriceInSpice()

            this.onResize()
        }
    }

    private refreshCoords() {
        if (this.miner != undefined) {
            this.coord.text = `x : ${this.miner.x}   y : ${this.miner.y}`
            this.coord.x = 111 - this.coord.width
            this.coord.y = 36

            this.onResize()
        }
    }

    private handleCloseBtn() {
        this.closeBtn.x = 146
        this.closeBtn.interactive = true
        this.closeBtn.cursor = 'pointer'
        this.closeBtn.on('mouseover', () => {
            this.closeBtn.texture = PIXI.Texture.from('ic_preview_close_hover')
        })
        this.closeBtn.on('mouseout', () => {
            this.closeBtn.texture = PIXI.Texture.from('ic_preview_close')
        })
        this.closeBtn.on('pointertap', () => {
            this.onRequireClose()
        })
    }

    private handlePreview() {
        this.previewBtn.x = 146
        this.previewBtn.y = 34
        this.previewBtn.interactive = true
        this.previewBtn.cursor = 'pointer'
        this.previewBtn.on('mouseover', () => {
            this.previewBtn.texture = PIXI.Texture.from('ic_preview_more_hover')
            if (this.container.alpha == 1) this.onRequirePreview(this.miner!)
        })
        this.previewBtn.on('mouseout', () => {
            this.previewBtn.texture = PIXI.Texture.from('ic_preview_more')
            this.onExitPreview()
        })
    }
}
