import { Magic, LoginWithMagicLinkConfiguration } from 'magic-sdk';
import { EventEmitter } from 'events';
import Web3 from 'web3';
import Web3Modal from "web3modal";

import config from '@/config';

const customNodeOptions = {
  rpcUrl: "https://polygon-rpc.com", // Your own node URL
  chainId: 137 // Your own node's chainId
}

// Setting network to localhost blockchain

export enum ConnectionType {
  Metamask = 1,
  StakeDAO = 2,
  Cometh = 3,
}

export default class Connection extends EventEmitter {
  private static connection: Connection|null = null;

  private web3Modal: Web3Modal;

  private stakeDAOMagic: Magic;
  private comethMagic: Magic;
  // tmp to connect to stake DAO
  private _email!: string|null;

  public connectedAddress!: string|null;

  public provider!: any|null;

  private web3: any;
  private _isConnecting = false;

  get isConnecting() {
    return this._isConnecting;
  }

  public getWeb3() {
    return this.web3;
  }

  static getInstance(): Connection {
    if (!Connection.connection) {
      Connection.connection = new Connection();
    }
    return Connection.connection;
  }

  private constructor() {
    super();
    this.web3 = new Web3();
    const emailConnector = async (magic: Magic, options: any) => {
      let email = this._email;
      if (!email) {
        email = window.localStorage.getItem(options.cacheKey)
      }
      if (!email) return null;

      await magic.auth.loginWithMagicLink({ email })

      window.localStorage.setItem(options.cacheKey, email)
      return magic.rpcProvider
    };

    this.stakeDAOMagic = new Magic('pk_live_391F5C6974CA80FA', { network: customNodeOptions })
    this.comethMagic = new Magic('pk_live_6EEF1A3EA8E2564B', { network: customNodeOptions })

    const providerOptions = {
      "custom-stakedao": {
        display: {
          logo: "https://images.service.cometh.io/4000001.png",
          name: "StakeDAO",
          description: "StakeDAO"
        },
        package: this.stakeDAOMagic,
        options: {
          cacheKey: 'stakedao-email'
        },
        connector: emailConnector,
      },
      "custom-cometh": {
        display: {
          logo: "https://images.service.cometh.io/4000001.png",
          name: "Cometh",
          description: "Cometh"
        },
        package: this.comethMagic,
        options: {
          cacheKey: 'magic-email'
        },
        connector: emailConnector,
      }
    }

    this.web3Modal = new Web3Modal({
      theme: 'dark',
      cacheProvider: true,
      providerOptions: providerOptions
    })
  }

  public async load(): Promise<any|null> {
    if (!this.isConnected()) {
      return null;
    }
    return await this.reconnect()
  }


  public isConnected() {
    return !!this.web3Modal.cachedProvider
  }

  public getConnectionType(): ConnectionType|null {
    const map = new Map<string, ConnectionType>([
      ['injected', ConnectionType.Metamask],
      ['custom-stakedao', ConnectionType.StakeDAO],
      ['custom-cometh', ConnectionType.Cometh]
    ]);
    
    const connectedTo = this.web3Modal.cachedProvider;
    return map.get(connectedTo) ?? null;
  }

  private async fetchConnectionData() {
    this._isConnecting = true;
    this.emit('connecting', {});
    const provider = await this.web3Modal.connect();
    // do this first to update this.web3
    this.setProvider(provider);
    const address = (await this.web3.eth.getAccounts())[0];
    this.connectedAddress = address;

    this._isConnecting = false;
    this.emit('providerChanged', this.provider);
    return provider;
  }

  public async reconnect() {
    await this.fetchConnectionData();
  }

  public async connectStakeDAO(email: string) {
    this._email = email;
    await this.web3Modal.connectTo('custom-stakedao')
    this._email = null;

    await this.fetchConnectionData()
  }

  public async connectCometh(email: string) {
    this._email = email;
    await this.web3Modal.connectTo('custom-cometh')
    this._email = null;

    await this.fetchConnectionData()
  }

  public async connectMetamask() {
    await this.web3Modal.connectTo('injected')
    await this.fetchConnectionData()
  }

  private setProvider(provider: any|null) {
    if (provider === this.provider) {
      return;
    }
    this.provider = provider;
    this.web3.setProvider(provider);
  }

  public async logout() {
    this.web3Modal.clearCachedProvider();
    window.localStorage.removeItem('stakedao-email')
    window.localStorage.removeItem('magic-email')
    this.connectedAddress = null;
    this.setProvider(null);
    this.emit('providerChanged', this.provider);
  }

  public getConnectedEmail(): string|null {
    const connectionType = this.getConnectionType()
    if (!connectionType) return null

    const keyByType = new Map<ConnectionType, string>([
      [ConnectionType.Cometh, 'magic-email'],
      [ConnectionType.StakeDAO, 'stakedao-email'],
    ])

    const key = keyByType.get(connectionType)
    if (!key) return null
    return window.localStorage.getItem(key) ?? null
  }
}
