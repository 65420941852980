import BN from 'bn.js'

const REAL_FBITS = 40
const REAL_ONE = (new BN(2)).pow(new BN(REAL_FBITS))

export default class Polar {
    public distance = 0
    public angle = 0

    constructor(from: any) {
        this.distance = from.distance
        const real = new BN(from.angle.toString(10))
        const ipart = parseInt(real.div(REAL_ONE).toString())
        const fpart = parseInt(real.mod(REAL_ONE).toString())
        this.angle = ipart + fpart / Math.pow(2, REAL_FBITS)
    }
}
