import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import { Transaction } from '@/game/models/Transaction'

export default class StarterKitService {

    public static instance: StarterKitService

    public static getInstance(): StarterKitService {
        if (!this.instance) {
            this.instance = new StarterKitService()
        }
        return this.instance
    }

    private abi = [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spaceshipsAddress",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "comethswapRouterAddress",
              "type": "address"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            }
          ],
          "name": "AddedShip",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "previousOwner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "OwnershipTransferred",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "shipId",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "mustAmount",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "shipPrice",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "buyingPrice",
              "type": "uint256"
            }
          ],
          "name": "Sold",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "Withdrawn",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            }
          ],
          "name": "WithdrawnShip",
          "type": "event"
        },
        {
          "inputs": [],
          "name": "owner",
          "outputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "name": "priceByModel",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "renounceOwnership",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "spaceships",
          "outputs": [
            {
              "internalType": "contract IERC721",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "transferOwnership",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "uniswapRouter",
          "outputs": [
            {
              "internalType": "contract IUniswapV2Router02",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "stateMutability": "payable",
          "type": "receive"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "amountOutMin",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "deadline",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "model",
              "type": "uint256"
            }
          ],
          "name": "buyStarterKit",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "model",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "newShipPrice",
              "type": "uint256"
            }
          ],
          "name": "setShipPrice",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            }
          ],
          "name": "withdraw",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            }
          ],
          "name": "withdrawShip",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "",
              "type": "bytes"
            }
          ],
          "name": "onERC721Received",
          "outputs": [
            {
              "internalType": "bytes4",
              "name": "",
              "type": "bytes4"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "model",
              "type": "uint256"
            }
          ],
          "name": "getModelSupply",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ]
      
    private writeStarterKit: any;
    private wssStarterKit: any;

    constructor() {
        this.initContract()
    }

    public initContract() {

        const web3 = Web3Service.getInstance().getWeb3()
        this.writeStarterKit = new web3.eth.Contract(this.abi, config.starterKitAddress, { gasLimit: '1000000' })

        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.wssStarterKit = new wssWeb3.eth.Contract(this.abi, config.starterKitAddress)
    }

    public async getModelSupply(model:any){
      return await this.wssStarterKit.methods.getModelSupply(model).call();
    }

    public async buyStarterKit(expectedMust:any, to:any, deadline:any, model:any, starterKitPrice:any){
        this.initContract();
        const web3 = Web3Service.getInstance().getWeb3()
        const account: string = await Web3Service.getInstance().getAccount()
        await this.writeStarterKit.methods.buyStarterKit(expectedMust, to, deadline, model).send({ from: account, value: starterKitPrice, gasPrice: '50000000000'});
    }
}
