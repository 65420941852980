import TubePool from '@/game/models/tube/TubePool'
import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import TubeService from '@/game/services/web3/TubeService'
import Colors from '@/helpers/Colors'
import { GlowFilter } from '@pixi/filter-glow'
import * as PIXI from 'pixi.js'
import Web3 from 'web3'
import BN from 'bn.js'

import TubeLoaderService from '@/game/services/TubeLoaderService'
import { CurrencyHelper } from '@/helpers/CurrencyHelper'

export class TubeComponent extends PIXI.Container {
    private sprite = PIXI.Sprite.from('tube_component_1')
    private topTube = PIXI.Sprite.from('tube_top_tube')
    private progress = PIXI.Sprite.from('pixel')
    private item = PIXI.Sprite.from('pixel')
    private lvlTxt = new MainBitmapText('', { fontSize: 4 })
    private dustTxt = new MainBitmapText('', { fontSize: 6 })
    private tokenTxt = new MainBitmapText('', { fontSize: 6, align: 'center' })
    private nameTxt = new MainBitmapText('Items !', { fontSize: 6 })
    private maxTxt = new MainBitmapText('-', { fontSize: 5 })
    private dustGainTxt = new MainBitmapText('', { fontSize: 5 })
    private dustPriceRangeTxt = new MainBitmapText('', { fontSize: 5 })

    public onClick = () => {
        return
    }

    constructor(public tubePool: TubePool) {
        super()
        this.loadPool()

        this.topTube.y = 5
        this.sprite.x = 36

        this.item.alpha = 0.75

        this.lvlTxt.tint = Colors.Green500
        this.progress.tint = Colors.Green500
        this.dustTxt.tint = Colors.Gold
        this.tokenTxt.tint = Colors.Purple500
        this.maxTxt.tint = Colors.Blue800
        this.dustGainTxt.tint = Colors.Purple500
        this.dustPriceRangeTxt.tint = Colors.Gold

        const animation: PIXI.AnimatedSprite = new PIXI.AnimatedSprite(
            (TubeLoaderService.getInstance().loader.resources['tube_component_1_animation'] as any).spritesheet!._frameKeys.map((a: string) => {
                return PIXI.Texture.from(a)
            })
        )
        animation.x = 36
        animation.animationSpeed = 0.18
        animation.gotoAndPlay(Math.floor(Math.random() * animation.totalFrames))

        this.addChild(this.topTube)
        this.addChild(this.sprite)
        this.addChild(this.item)
        this.addChild(animation)
        this.addChild(this.progress)
        this.addChild(this.lvlTxt)
        this.addChild(this.dustTxt)
        this.addChild(this.tokenTxt)
        this.addChild(this.maxTxt)

        const infoWrapper = PIXI.Sprite.from('tube_info_bg')
        infoWrapper.x = 36
        infoWrapper.y = 249

        this.addChild(infoWrapper)
        this.addChild(this.nameTxt)
        this.addChild(this.maxTxt)
        this.addChild(this.dustGainTxt)
        this.addChild(this.dustPriceRangeTxt)

        this.interactive = true
        this.cursor = 'pointer'
        this.on('mouseover', () => {
            this.sprite.texture = PIXI.Texture.from('tube_component_1_hover')
        })
        this.on('mouseout', () => {
            this.sprite.texture = PIXI.Texture.from('tube_component_1')
        })
        this.on('pointertap', () => {
            this.onClick()
        })

        this.onResize()
    }

    public async loadPool() {
        await this.tubePool.load()

        this.lvlTxt.text = 'Lvl ' + (await this.getPoolLevel())

        this.onResize()
        const earned = await this.tubePool.earned()

        this.dustTxt.text = CurrencyHelper.weiToEthRounded(earned.toString()) + ' DUST'

        this.onResize()
        const staked = await this.tubePool.balanceOf()
        this.tokenTxt.text = CurrencyHelper.weiToEthRounded(staked.toString()) + ' ' + this.tubePool.tokenSymbol

        this.nameTxt.text = this.tubePool.name
        this.item.texture = PIXI.Texture.from(this.tubePool.placeHolder)
        if (this.item.texture.width > 64) {
            this.item.scale.set(1 / 3)
        } else {
            this.item.scale.set(1)
        }

        this.dustGainTxt.text = this.tubePool.getDustPerDayRateFromTokenAmount(1) + ' DUST/MUST/Day'
        this.maxTxt.text = 'Max: ' + Web3.utils.fromWei(this.tubePool.maxStake, 'ether') + ' ' + this.tubePool.tokenSymbol

        if (this.tubePool.prizes.length > 1) {
            this.dustPriceRangeTxt.text =
                'Prizes: ' +
                Web3.utils.fromWei(this.tubePool.getMinPoints(), 'ether') +
                ' to ' +
                Web3.utils.fromWei(this.tubePool.getMaxPoints(), 'ether') +
                ' DUST'
        } else {
            this.dustPriceRangeTxt.text = 'Prize: ' + Web3.utils.fromWei(this.tubePool.getMinPoints(), 'ether') + ' DUST'
        }

        this.onResize()
        this.progress.width = 90 * ((await this.getProgressToNextLevel(earned)) / 100)

        this.onResize()
    }

    public onResize() {
        this.lvlTxt.y = 53
        this.lvlTxt.x = this.sprite.x + this.sprite.width / 2 - this.lvlTxt.width / 2

        this.progress.y = 60
        this.progress.x = 45
        this.progress.height = 1

        this.dustTxt.y = 63
        this.dustTxt.x = this.sprite.x + this.sprite.width / 2 - this.dustTxt.width / 2

        this.tokenTxt.y = 221
        this.tokenTxt.x = Math.floor(this.sprite.x + this.sprite.width / 2 - this.tokenTxt.width / 2)

        this.nameTxt.y = 254
        this.nameTxt.x = Math.floor(this.sprite.x + this.sprite.width / 2 - this.nameTxt.width / 2)
        this.maxTxt.y = this.nameTxt.y + this.nameTxt.height + 3
        this.maxTxt.x = Math.floor(this.sprite.x + this.sprite.width / 2 - this.maxTxt.width / 2)
        this.dustGainTxt.y = this.maxTxt.y + this.maxTxt.height + 2
        this.dustGainTxt.x = Math.floor(this.sprite.x + this.sprite.width / 2 - this.dustGainTxt.width / 2)
        this.dustPriceRangeTxt.y = this.dustGainTxt.y + this.dustGainTxt.height + 2
        this.dustPriceRangeTxt.x = Math.floor(this.sprite.x + this.sprite.width / 2 - this.dustPriceRangeTxt.width / 2)

        this.item.x = 36 + Math.floor(this.sprite.width / 2 - this.item.width / 2)
    }

    tick() {
        this.item.y = this.sprite.height / 2 - this.item.height / 4 + 2 - Math.sin(Date.now() / 1000) * 4
    }

    async getPoolLevel() {
        const earned = await this.tubePool.earned()
        let lvl = 0
        this.tubePool.getGroupedPoolPrize().forEach((it, i) => {
            if (it[0].points.lte(earned)) {
                lvl = i + 1
            }
        })

        return lvl
    }

    private async getProgressToNextLevel(mEarned: BN) {
        const level = await this.getPoolLevel()
        if (level == this.tubePool.prizes.length) {
            return 100
        }

        let prevStep = new BN(0)

        if (level > 0) {
            prevStep = this.tubePool.prizes[level - 1].points
        }
        const currStep = this.tubePool.prizes[level].points
        if (currStep.eq(prevStep)) {
            return 100
        }

        let earned = mEarned.sub(prevStep)

        if (earned.isNeg()) {
            earned = new BN(0)
        }

        const maxPrice = currStep.sub(prevStep)
        const updated = maxPrice.isZero() ? new BN(0) : earned.muln(100).div(maxPrice)

        const perc = Math.max(0, Math.min(100, updated.toNumber()))

        return perc
    }
}
