import GameElement from '../../GameElement'
import * as PIXI from 'pixi.js'
import Miner from '../../models/Miner'
import PlayerService from '../../services/PlayerService'
import Colors from '@/helpers/Colors'
import StatsService from '../../services/StatsService'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import { addTooltip, TooltipPosition } from '@/helpers/TooltipHelper'
import { PlayerStats } from '@/game/models/PlayerStats'
import CometsEarningDetailComponent from './CometsEarningDetailComponent'

enum PlayerMenu {
    Fleet,
    Main,
    None
}

export default class PlayerShipStatComponent implements GameElement {
    private container: PIXI.Container = new PIXI.Container()
    private currentMiner: Miner = PlayerService.getInstance().miner
    private yieldValue: PIXI.BitmapText = new MainBitmapText('', { fontSize: 5 })
    private pulledValue: PIXI.BitmapText = new MainBitmapText('', { fontSize: 5 })
    private pulledEarningValue: PIXI.BitmapText = new MainBitmapText('', { fontSize: 5 })
    private cometMinedValue: PIXI.BitmapText = new MainBitmapText('', { fontSize: 5 })
    private cometMinedEarningValue: PIXI.BitmapText = new MainBitmapText('', { fontSize: 5 })
    private jumpsValue: PIXI.BitmapText = new MainBitmapText('', { fontSize: 5 })
    private jumpExpenseValue: PIXI.BitmapText = new MainBitmapText('', { fontSize: 5 })
    private totalValue: PIXI.BitmapText = new MainBitmapText('', { fontSize: 6 })
    private jumpExpenseValueMUST: PIXI.BitmapText = new MainBitmapText('', { fontSize: 3 })
    private cometMinedEarningValueMATIC: PIXI.BitmapText = new MainBitmapText('', { fontSize: 3 })
    private pulledEarningValueMUST: PIXI.BitmapText = new MainBitmapText('', { fontSize: 3 })
    private cometsEarningDetail?: CometsEarningDetailComponent
    public width = 92

    constructor(public miner: Miner) {
        const jumpsTitle = new MainBitmapText('Jumps', { fontSize: 5 })
        jumpsTitle.tint = Colors.Blue800
        jumpsTitle.x = 0
        jumpsTitle.y = 9

        const pulledTitle = new MainBitmapText('Pulled', { fontSize: 5 })
        pulledTitle.tint = Colors.Blue800
        pulledTitle.x = 0
        pulledTitle.y = jumpsTitle.y + jumpsTitle.height + 2

        const cometsTitle = new MainBitmapText('Comets mined', { fontSize: 5 })
        cometsTitle.tint = Colors.Blue800
        cometsTitle.x = 0
        cometsTitle.y = pulledTitle.y + pulledTitle.height + 2

        const yieldTitle = new MainBitmapText('Yield', { fontSize: 5 })
        yieldTitle.tint = Colors.Blue800
        yieldTitle.x = 0
        yieldTitle.y = cometsTitle.y + cometsTitle.height + 8

        const pulledEarningTitle = new MainBitmapText('Pull earning', { fontSize: 5 })
        pulledEarningTitle.tint = Colors.Blue800
        pulledEarningTitle.x = 0

        const cometsEarningTitle = new MainBitmapText('Comets earning', { fontSize: 5 })
        cometsEarningTitle.tint = Colors.Blue800
        cometsEarningTitle.x = 0

        const jumpExpenseTitle = new MainBitmapText('Jumps expense', { fontSize: 5 })
        jumpExpenseTitle.tint = Colors.Blue800
        jumpExpenseTitle.x = 0

        const totalTitle = new MainBitmapText('Total', { fontSize: 6 })
        totalTitle.tint = Colors.Blue800
        totalTitle.x = 0

        this.pulledValue.y = pulledTitle.y
        this.cometMinedValue.y = cometsTitle.y
        this.jumpsValue.y = jumpsTitle.y
        this.yieldValue.y = yieldTitle.y

        jumpExpenseTitle.y = yieldTitle.y + yieldTitle.height + 2
        this.jumpExpenseValue.y = jumpExpenseTitle.y
        this.jumpExpenseValueMUST.y = this.jumpExpenseValue.y + this.jumpExpenseValue.height + 1

        pulledEarningTitle.y = this.jumpExpenseValueMUST.y + this.jumpExpenseValueMUST.height + 2
        this.pulledEarningValue.y = pulledEarningTitle.y
        this.pulledEarningValueMUST.y = this.pulledEarningValue.y + this.pulledEarningValue.height + 1

        cometsEarningTitle.y = this.pulledEarningValueMUST.y + this.pulledEarningValueMUST.height + 2
        this.cometMinedEarningValue.y = cometsEarningTitle.y
        this.cometMinedEarningValueMATIC.y = this.cometMinedEarningValue.y + this.cometMinedEarningValue.height + 1

        totalTitle.y = this.cometMinedEarningValueMATIC.y + this.cometMinedEarningValueMATIC.height + 6
        this.totalValue.y = totalTitle.y

        this.container.addChild(yieldTitle)
        this.container.addChild(pulledTitle)
        this.container.addChild(pulledEarningTitle)
        this.container.addChild(cometsTitle)
        this.container.addChild(cometsEarningTitle)
        this.container.addChild(jumpsTitle)
        this.container.addChild(jumpExpenseTitle)
        this.container.addChild(totalTitle)

        this.pulledEarningValue.tint = Colors.Green500
        this.cometMinedEarningValue.tint = Colors.Green500
        this.jumpExpenseValue.tint = Colors.Red500
        this.jumpExpenseValueMUST.tint = Colors.Blue800
        this.pulledEarningValueMUST.tint = Colors.Blue800
        this.cometMinedEarningValueMATIC.tint = Colors.Blue800

        this.container.addChild(this.yieldValue)
        this.container.addChild(this.pulledValue)
        this.container.addChild(this.pulledEarningValue)
        this.container.addChild(this.pulledEarningValueMUST)
        this.container.addChild(this.cometMinedEarningValueMATIC)
        this.container.addChild(this.jumpExpenseValueMUST)
        this.container.addChild(this.cometMinedValue)
        this.container.addChild(this.cometMinedEarningValue)
        this.container.addChild(this.jumpsValue)
        this.container.addChild(this.jumpExpenseValue)
        this.container.addChild(this.totalValue)

        this.cometMinedEarningValueMATIC.interactive = true
        this.cometMinedEarningValueMATIC.cursor = 'pointer'
        this.cometMinedEarningValueMATIC.hitArea = new PIXI.Rectangle(-20, -7, 40, 12)
        this.cometMinedEarningValueMATIC.on('mouseover', () => {
            this.cometMinedEarningValueMATIC.tint = 0xffffff
            this.cometsEarningDetail?.enterAnimation(() => {
                return
            })
        })
        this.cometMinedEarningValueMATIC.on('mouseout', () => {
            this.cometMinedEarningValueMATIC.tint = Colors.Blue800
            this.cometsEarningDetail?.exitAnimation(() => {
                return
            })
        })

        PlayerService.getInstance().setOnCurrentPlayerChange(async miner => {
            this.currentMiner = miner
            this.onResize()
        })
        this.onResize()
    }

    async enterAnimation(callback: () => void): Promise<void> {
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        callback()
    }

    onResize(): void {
        //  this.container.scale.set(Resolution.scale)
    }

    public tick(): void {
        // nothing
    }

    public getContainer(): PIXI.Container {
        return this.container
    }

    public update(miner: Miner) {
        this.miner = miner

        this.yieldValue.text = '...'
        this.pulledValue.text = '...'
        this.pulledEarningValue.text = '...'
        this.cometMinedValue.text = '...'
        this.cometMinedEarningValue.text = '...'
        this.jumpsValue.text = '...'
        this.jumpExpenseValue.text = '...'
        this.totalValue.text = '...'
        this.jumpExpenseValueMUST.text = '...'
        this.cometMinedEarningValueMATIC.text = '...'
        this.pulledEarningValueMUST.text = '...'

        this.handleTextPositions()

        StatsService.getInstance()
            .getStatsMiner(this.currentMiner)
            .then((stat: PlayerStats) => {
                let yieldStr = stat.yield + '%'
                if (stat.pulledEarning + stat.cometsEarning > 0 && stat.jumpsExpense <= 0) {
                    yieldStr = 'Infinity '
                } else if (stat.jumpsExpense <= 0) {
                    yieldStr = '-'
                }
                if (this.cometsEarningDetail) {
                    this.container.removeChild(this.cometsEarningDetail.getContainer())
                    this.cometsEarningDetail.getContainer().destroy({ children: true })
                }
                this.cometsEarningDetail = new CometsEarningDetailComponent(stat)
                this.container.addChild(this.cometsEarningDetail.getContainer())
                this.cometsEarningDetail.getContainer().x = this.width + 6
                this.cometsEarningDetail.getContainer().y = Math.floor(
                    this.cometMinedEarningValueMATIC.y - this.cometsEarningDetail.getContainer().height / 2
                )

                if (stat.prices) {
                    this.yieldValue.text = yieldStr
                    this.pulledValue.text = stat.pulledCount + ''
                    this.cometMinedValue.text = stat.cometsMined + ''
                    this.jumpsValue.text = stat.jumpsCount + ''

                    this.pulledEarningValue.text = (stat.pulledEarning * stat.prices['must'].usd).toFixed(2) + ' $'
                    this.jumpExpenseValue.text = (stat.jumpsExpense * stat.prices['must'].usd).toFixed(2) + ' $'
                    this.cometMinedEarningValue.text = stat.cometsEarning.toFixed(2) + ' $'
                    this.totalValue.text = stat.totalEarning.toFixed(2) + ' $'

                    this.jumpExpenseValueMUST.text = stat.jumpsExpense + ' MUST'
                    this.cometMinedEarningValueMATIC.text = 'see more...'
                    this.pulledEarningValueMUST.text = stat.pulledEarning + ' MUST'
                }

                if (stat.totalEarning < 0) {
                    this.totalValue.tint = Colors.Red500
                } else {
                    this.totalValue.tint = Colors.Green500
                }

                this.handleTextPositions()
            })
    }

    private handleTextPositions() {
        this.yieldValue.x = this.width - this.yieldValue.textWidth
        this.pulledValue.x = this.width - this.pulledValue.textWidth
        this.pulledEarningValue.x = this.width - this.pulledEarningValue.textWidth
        this.cometMinedValue.x = this.width - this.cometMinedValue.textWidth
        this.cometMinedEarningValue.x = this.width - this.cometMinedEarningValue.textWidth
        this.jumpsValue.x = this.width - this.jumpsValue.textWidth
        this.jumpExpenseValue.x = this.width - this.jumpExpenseValue.textWidth
        this.totalValue.x = this.width - this.totalValue.textWidth
        this.jumpExpenseValueMUST.x = this.width - this.jumpExpenseValueMUST.textWidth
        this.cometMinedEarningValueMATIC.x = this.width - this.cometMinedEarningValueMATIC.textWidth
        this.pulledEarningValueMUST.x = this.width - this.pulledEarningValueMUST.textWidth
    }
}
