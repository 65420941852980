import MulticallService from '@/game/services/web3/MulticallService'
import StakingRewards from '@/constants/StakingRewards'
import TheGraphService from '@/game/services/TheGraphService'
import Web3 from 'web3'
import ComethSwapTokenService from '@/services/ComethSwapTokenService'
import BigNumber from 'bignumber.js'

export default class TokenService {
    public static instance: TokenService

    public static getInstance(): TokenService {
        if (!this.instance) {
            this.instance = new TokenService()
        }
        return this.instance
    }

    async getTokens(holder: string) {
        const comethswapTokens = await ComethSwapTokenService.getInstance().getTokensAddresses()

        const tokenAdresses = comethswapTokens.map(v => v.address)

        const tokensBalances = (await MulticallService.getInstance().erc20balanceOf(tokenAdresses, holder)).returnData

        const balances = new Map<string, string>();
        for (let i = 0; i < tokensBalances.length; i++) {
            if (
                tokensBalances[i] == '0x0000000000000000000000000000000000000000000000000000000000000000' ||
                tokensBalances[i] == '0x' ||
                tokensBalances[i] == '0x000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            ) continue

            const decimal = Web3.utils.toBN(10).pow(Web3.utils.toBN(comethswapTokens[i].decimals)).toString()
            //console.log('decimal:',decimal)

            

            const balance = new BigNumber(tokensBalances[i]).div(new BigNumber(decimal))
            balances.set(tokenAdresses[i].toLowerCase(), balance.toString())
            //balances.set(tokenAdresses[i].toLowerCase(), Web3.utils.fromWei(tokensBalances[i], decimal as any))
        }

        const tokensWithBalance = Array.from(balances.keys())
        const tokens = await TheGraphService.getInstance().getTokens(tokensWithBalance)
        for (let i = 0; i < tokens.length; i++) {
            const balance = balances.get(tokens[i].id)
            if (!balance) continue
            tokens[i].balance = balance
        }

        return tokens
    }
}
