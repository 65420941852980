import { Vue } from 'vue-class-component'
import { Percent, ChainId, Token, TokenAmount, Pair, TradeType, Route, Trade, Fetcher, JSBI } from '@cometh-game/comethswap-sdk'
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import Web3Service from '@/game/services/Web3Service'
import StarterKitService from '@/game/services/web3/StarterKitService'
import { Options } from 'vue-class-component'
import Header from '@/components/header/Header.vue'
import HeaderTs from '@/components/header/Header'
import Connection, { ConnectionType } from '@/components/connection/Connection'
import config from '@/config'
import Modal from '@/components/modal/Modal.vue'
import { ShopItem } from '@/views/Shop/ShopItemList'
import ShopData from '@/views/Shop/ShopData'

// Usage example
// Buy Starter Kit with 10 Matic, ship price is 8
// a contract transfer ship to sender and swap the rest (2) of Matic for Must token
@Options({
    components: {
        Header,
        Modal
    }
})
export default class Starterkit extends Vue {
    $refs!: {
        header: HeaderTs
    }

    public baseProvider!: JsonRpcProvider
    public matic!: Token
    public must!: Token
    public pair!: Pair
    public route!: Route
    public amountOut!: any

    public shipPrice = ''
    public maticToSwap = Web3Service.getInstance()
        .getWeb3()
        .utils.toWei('40.5', 'ether')
    public starterKitPrice!: any

    displayedMustAmount: string | null = null
    displayedStarterKitPrice = null
    displayedQuantityLeft: string | null = null

    //explorerModelId = 2

    isConnectedWallet = false
    hasEnoughMatic = false
    loginModal = false
    maticModal = false

    isOrderComplete = false

    isBuyingInitiated = false
    isBuying = false

    isInsufficientBalance = false
    isChangingConnection = false

    showConfirmationModal = false
    userHasConfirmed = false

    shopItem: ShopItem = new ShopItem()

    async mounted() {
        const name = this.$route.params.name ?? 'explorer'
        if (name === 'alata') {
            this.$router.replace('/shop')
        }
        this.shopItem = ShopData.getInstance()
            .getItems()
            .find(v => v.url === name) as ShopItem

        // Need to be equal to Starter Kit Price in Smart Contract
        this.shipPrice = Web3Service.getInstance()
            .getWeb3()
            .utils.toWei(this.shopItem.price, 'ether')

        this.isChangingConnection = Connection.getInstance().isConnecting

        Connection.getInstance().on('connecting', () => {
            console.log('connecting...')
            this.isChangingConnection = true
        })

        Connection.getInstance().on('providerChanged', async provider => {
            console.log('provider changed...')
            this.isChangingConnection = false
            // check if disconnected
            if (!provider) {
                // user is disconnected
                return
            }

            await this.init()
        })

        const web3 = Web3Service.getInstance().getWssWeb3()

        this.baseProvider = new Web3Provider(web3.currentProvider)

        console.log('this.shipPrice', this.shipPrice)
        console.log('this.maticToSwap', this.maticToSwap)

        this.starterKitPrice = new web3.utils.BN(this.shipPrice).add(new web3.utils.BN(this.maticToSwap))

        this.matic = await Fetcher.fetchTokenData(ChainId.MATIC, '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', this.baseProvider)
        this.must = await Fetcher.fetchTokenData(ChainId.MATIC, '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f', this.baseProvider)
        this.pair = await Fetcher.fetchPairData(this.matic, this.must, this.baseProvider)
        this.route = new Route([this.pair], this.matic)

        const trade = new Trade(this.route, new TokenAmount(this.matic, this.maticToSwap), TradeType.EXACT_INPUT)
        const slippageTolerance = new Percent('50', '10000') // 50 bips, or 0.50%
        this.amountOut = trade.minimumAmountOut(slippageTolerance).raw.toString() // needs to be converted to e.g. hex

        this.displayedStarterKitPrice = web3.utils.fromWei(this.starterKitPrice, 'ether')

        this.displayedMustAmount = trade.minimumAmountOut(slippageTolerance).toSignificant(6)

        this.displayedQuantityLeft = await StarterKitService.getInstance().getModelSupply(this.shopItem.id)
        console.log('displayedQuantityLeft:', this.displayedQuantityLeft)

        this.init()
    }

    async init() {
        if (Connection.getInstance().getConnectionType() == ConnectionType.Metamask) {
            try {
                await (window as any).ethereum.request({
                    id: 1,
                    jsonrpc: '2.0',
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x' + config.network.toString(16),
                            chainName: config.l2NetworkLabel,
                            rpcUrls: [config.rpc],
                            iconUrls: ['https://polygon.technology/wp-content/uploads/2021/01/logo-polygon.png'],
                            nativeCurrency: {
                                name: 'Matic',
                                symbol: 'Matic',
                                decimals: 18
                            },
                            blockExplorerUrls: [config.etherscanUrl]
                        }
                    ]
                })
            } catch (e) {
                // metamask is probably outdated here
                console.log(e)
            }
        }

        const provider = await Connection.getInstance().provider
        const web3 = Web3Service.getInstance().getWeb3()
        web3.setProvider(provider)
        const to = await Web3Service.getInstance().getAccount()

        if (!to) return

        const balance = new web3.utils.BN(await web3.eth.getBalance(to))

        this.isInsufficientBalance = balance.lt(this.starterKitPrice)

        if (this.isBuyingInitiated && !this.isInsufficientBalance) {
            this.buy()
        }
    }

    async buy() {
        if (!this.userHasConfirmed) {
            this.showConfirmationModal = true
            return
        }
        this.userHasConfirmed = false

        if (!Connection.getInstance().isConnected()) {
            console.log('user is not connected')
            this.isBuyingInitiated = true
            this.$refs.header.openModal()
            return
        }

        this.isBuying = true
        const provider = await Connection.getInstance().provider
        const web3 = Web3Service.getInstance().getWeb3()
        web3.setProvider(provider)

        const to = await Web3Service.getInstance().getAccount()

        const deadline = Math.floor(Date.now() / 1000) + 60 * (60 * 24) // 20 minutes from the current Unix time
        await StarterKitService.getInstance().buyStarterKit(this.amountOut, to, deadline, this.shopItem.id, this.starterKitPrice)

        this.isBuying = false
        this.isBuyingInitiated = false
        this.isOrderComplete = true
    }

    buyCrypto() {
        this.$refs.header.buyCrypto()
    }

    async onRampPurchased(payload: any) {
        console.log(payload)
        this.init()
    }

    askForLogin() {
        this.loginModal = true
    }

    cancelLogin() {
        this.loginModal = false
    }

    logWithEmail() {
        this.isConnectedWallet = true
        this.loginModal = false
        this.buy()
    }

    askForMatic() {
        this.maticModal = true
    }

    cancelMaticBuy() {
        this.maticModal = false
    }

    confirmPurchase() {
        this.userHasConfirmed = true
        this.showConfirmationModal = false
        this.buy()
    }
}
