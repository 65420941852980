import ComethSwapTokenService from '@/services/ComethSwapTokenService'
import TheGraphService from '@/game/services/TheGraphService'
import Web3 from 'web3'
import BN from 'bn.js'

export default class Token {
    id = ''
    symbol = ''
    derivedETH = ''
    totalSupply = ''
    img = ''
    priceUSD = ''
    balance = ''

    constructor(address: string, symbol: string, derivedETH: string, totalSupply: string, ethPrice: number, balance = '0') {
        this.id = address
        this.symbol = symbol
        this.derivedETH = derivedETH
        this.totalSupply = totalSupply
        this.balance = balance

        this.priceUSD = this.calculatePriceUSD(ethPrice)
    }

    balanceUSD() {
        console.log(this.balance)
        console.log(this.symbol)
        console.log(this.priceUSD)
        console.log("---")
        return parseFloat(this.balance) * parseFloat(this.priceUSD)
    }

    private calculatePriceUSD(ethPrice: number) {
        let derivedETH = this.derivedETH
        const index = derivedETH.indexOf('.')
        if (index != -1) {
            derivedETH = derivedETH.substring(0, index + 18)
        }
        return Web3.utils.fromWei(new BN(Web3.utils.toWei(derivedETH)).muln(ethPrice * 1000).divn(1000))
    }

    async init() {
        this.img = await ComethSwapTokenService.getInstance().getImageURL(this.symbol)
    }
}
