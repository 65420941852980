import GameElement from '../../GameElement'
import * as PIXI from 'pixi.js'
import { PlayerStats } from '@/game/models/PlayerStats'
import Colors from '@/helpers/Colors'
import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import { animate } from '@/helpers/AnimationHelper'

export default class CometsEarningDetailComponent implements GameElement {
    private container: PIXI.Container = new PIXI.Container()

    public width = 96
    private lineHeight = 14

    constructor(public stats: PlayerStats) {
        const graphic = new PIXI.Graphics()
        graphic.beginFill(Colors.Blue400)
        graphic.lineStyle(1, Colors.Blue800)
        graphic.drawRect(0, 0, this.width, this.stats.cometsEarningDetails?.length * this.lineHeight + 8)

        this.container.addChild(graphic)

        stats.cometsEarningDetails?.forEach((it, i) => {
            const title = new MainBitmapText(it.tokenName, { fontSize: 5 })
            title.tint = Colors.Blue800
            title.x = 6
            title.y = 6 + this.lineHeight * i

            const dollarAmount = new MainBitmapText(it.dollarAmount.toFixed(2) + ' $', { fontSize: 5 })
            dollarAmount.tint = Colors.Green500
            dollarAmount.x = this.width - dollarAmount.width - 6
            dollarAmount.y = title.y

            const tokenAmount = new MainBitmapText(it.tokenAmount + ' ' + it.tokenName, { fontSize: 3 })
            tokenAmount.tint = Colors.Blue800
            tokenAmount.x = this.width - tokenAmount.width - 6
            tokenAmount.y = title.y + title.height

            this.container.addChild(title)
            this.container.addChild(dollarAmount)
            this.container.addChild(tokenAmount)
        })

        this.container.visible = false
        this.container.alpha = 0

        this.onResize()
    }

    async enterAnimation(callback: () => void): Promise<void> {
        this.container.visible = true
        await animate('easeOutQuad', 100, (perc: number) => {
            this.container.alpha = perc
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        this.container.visible = true
        await animate('easeOutQuad', 100, (perc: number) => {
            this.container.alpha = 1 - perc
        })
        callback()
        this.container.visible = false
    }

    onResize(): void {
        //  this.container.scale.set(Resolution.scale)
    }

    public tick(): void {
        // nothing
    }

    public getContainer(): PIXI.Container {
        return this.container
    }
}
