import * as PIXI from 'pixi.js'
import { Options, Vue } from 'vue-class-component'
import GCore from '@/game/GCore'
import Resolution from '@/helpers/Resolution'
import Welcome from '@/components/welcome/Welcome.vue'
import Maintenance from '@/components/maintenance/Maintenance.vue'
import Header from '@/components/header/Header.vue'
import HeaderTs from '@/components/header/Header'
import MapService from '@/game/services/MapService'
import PlayerService from '@/game/services/PlayerService'
import MouseService from '@/game/services/MouseService'

@Options({
    components: {
        Welcome,
        Maintenance,
        Header
    }
})
export default class Game extends Vue {
    public static SHIP_MODEL_COUNT = 64

    $refs!: {
        header: HeaderTs
    }

    public static STARS_COUNT = 5
    public core!: GCore

    public mobileIgnored = false
    public loadProgress = 0
    public isLoaded = false
    public clickedPlay = false

    async mounted() {
        if (this.core != undefined) {
            this.core.destroy()
        }
        if (PIXI.Loader.shared.progress == 100) {
            this.isLoaded = true
            this.init()
        }
    }

    async play() {
        this.init()
        document.body.style.overflow = 'hidden'
    }

    showModal() {
        this.$refs.header.openModal()
    }

    handleSendMessage(event: any, value: string) {
        // Our event handler gets the event, as well as any
        // arguments the child passes to the event
        console.log('From the child:', value)
    }

    public ignoreMobileMode() {
        this.mobileIgnored = true
        MouseService.getInstance().mobileIgnored = true
    }

    init() {
        this.clickedPlay = true
        PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST
        PIXI.settings.ANISOTROPIC_LEVEL = 16
        window.PIXI = PIXI as any
        PIXI.Loader.shared.onProgress.add(progress => {
            this.loadProgress = progress.progress
        })
        PIXI.Loader.shared.onComplete.add(() => {
            this.setReady()
        })

        this.declareFonts()

        try {
            // MUSICS
            PIXI.Loader.shared.add('music1', '/assets/audio/music1.mp3')
            // IMAGES
            PIXI.Loader.shared.add('bg', '/assets/img/bg.png')
            PIXI.Loader.shared.add('bg2', '/assets/img/bg2.png')
            PIXI.Loader.shared.add('bg3', '/assets/img/bg3.png')
            PIXI.Loader.shared.add('map_bg', '/assets/img/map_bg.png')
            PIXI.Loader.shared.add('main_bar_side', '/assets/img/main_bar_side.png')
            PIXI.Loader.shared.add('main_bar_side_extand', '/assets/img/main_bar_side_extand.png')
            PIXI.Loader.shared.add('main_bar_center', '/assets/img/main_bar_center.png')
            PIXI.Loader.shared.add('main_bar_btn_bg', '/assets/img/main_bar_btn_bg.png')
            PIXI.Loader.shared.add('second_bar', '/assets/img/second_bar.png')
            PIXI.Loader.shared.add('comete', '/assets/img/comete.json')
            PIXI.Loader.shared.add('ic_comete', '/assets/img/ic_comete.png')
            PIXI.Loader.shared.add('ic_miner', '/assets/img/ic_miner.png')
            PIXI.Loader.shared.add('ic_notifs', '/assets/img/ic_notifs.png')
            PIXI.Loader.shared.add('ic_help', '/assets/img/ic_help.png')
            PIXI.Loader.shared.add('menu_ship', '/assets/img/menu_ship.png')
            PIXI.Loader.shared.add('ic_map_player', '/assets/img/ic_map_player.png')
            PIXI.Loader.shared.add('ic_garage', '/assets/img/ic_garage.png')
            PIXI.Loader.shared.add('ic_settings', '/assets/img/ic_settings.png')
            PIXI.Loader.shared.add('ic_close_map', '/assets/img/ic_close_map.png')
            PIXI.Loader.shared.add('chat_layout', '/assets/img/chat_layout.png')
            PIXI.Loader.shared.add('ic_chat', '/assets/img/ic_chat.png')
            PIXI.Loader.shared.add('loader', '/assets/img/loader.json')
            PIXI.Loader.shared.add('minimap_btn', '/assets/img/minimap_btn.png')
            PIXI.Loader.shared.add('minimap_layout', '/assets/img/minimap_layout.png')
            PIXI.Loader.shared.add('garage_bg', '/assets/img/garage_bg.png')
            PIXI.Loader.shared.add('ic_aura', '/assets/img/ic_aura.png')
            PIXI.Loader.shared.add('ic_arrow', '/assets/img/ic_arrow.png')
            PIXI.Loader.shared.add('ic_reactor', '/assets/img/ic_reactor.png')
            PIXI.Loader.shared.add('ic_ship', '/assets/img/ic_ship.png')
            PIXI.Loader.shared.add('ic_token', '/assets/img/ic_token.png')
            PIXI.Loader.shared.add('ic_spice', '/assets/img/ic_spice.png')
            PIXI.Loader.shared.add('ic_tuto_base', '/assets/img/ic_tuto_base.png')
            PIXI.Loader.shared.add('ic_help_circle', '/assets/img/ic_help_circle.png')
            PIXI.Loader.shared.add('pixel', '/assets/img/pixel.png')
            PIXI.Loader.shared.add('logo', '/assets/img/logo.png')
            PIXI.Loader.shared.add('sun', '/assets/img/sun.png')
            PIXI.Loader.shared.add('btn', '/assets/img/btn.png')
            PIXI.Loader.shared.add('map_center', '/assets/img/map_center.png')
            PIXI.Loader.shared.add('btn_pressed', '/assets/img/btn_pressed.png')
            PIXI.Loader.shared.add('ic_close', '/assets/img/ic_close.png')
            PIXI.Loader.shared.add('perk_reactor', '/assets/img/perk_reactor.json')
            PIXI.Loader.shared.add('perk_aura', '/assets/img/perk_aura.png')
            PIXI.Loader.shared.add('1_token', '/assets/img/tokens/token1.png')
            PIXI.Loader.shared.add('2_token', '/assets/img/tokens/token2.png')
            PIXI.Loader.shared.add('3_token', '/assets/img/tokens/token3.png')
            PIXI.Loader.shared.add('btn_jump', '/assets/img/btn_jump.png')
            PIXI.Loader.shared.add('btn_jump_pressed', '/assets/img/btn_jump_pressed.png')
            PIXI.Loader.shared.add('ic_spice_mini', '/assets/img/ic_spice_mini.png')
            PIXI.Loader.shared.add('btn_big', '/assets/img/btn_big.png')
            PIXI.Loader.shared.add('btn_big_pressed', '/assets/img/btn_big_pressed.png')
            PIXI.Loader.shared.add('new_message', '/assets/img/new_message.png')
            PIXI.Loader.shared.add('comete_aura', '/assets/img/comete_aura.json')
            PIXI.Loader.shared.add('mining_progress', '/assets/img/mining_progress.png')
            PIXI.Loader.shared.add('ic_fullscreen', '/assets/img/ic_fullscreen.png')
            PIXI.Loader.shared.add('ic_fullscreen_exit', '/assets/img/ic_fullscreen_exit.png')
            PIXI.Loader.shared.add('btn_twitter', '/assets/img/btn_twitter.png')
            PIXI.Loader.shared.add('btn_telegram', '/assets/img/btn_telegram.png')
            PIXI.Loader.shared.add('btn_web', '/assets/img/btn_web.png')
            PIXI.Loader.shared.add('notif_close', '/assets/img/notif_close.png')
            PIXI.Loader.shared.add('ic_link', '/assets/img/ic_link.png')
            PIXI.Loader.shared.add('ic_drill', '/assets/img/ic_drill.png')
            PIXI.Loader.shared.add('ic_drill_cooldown', '/assets/img/ic_drill_cooldown.png')
            PIXI.Loader.shared.add('ic_radar', '/assets/img/ic_radar.png')
            PIXI.Loader.shared.add('tuto', '/assets/img/tuto.json')
            PIXI.Loader.shared.add('progress_indicator', '/assets/img/progress_indicator.png')
            PIXI.Loader.shared.add('fleetBtn1', '/assets/img/fleet1.png')
            PIXI.Loader.shared.add('fleetBtn2', '/assets/img/fleet2.png')
            PIXI.Loader.shared.add('bgFleet', '/assets/img/bg_fleet.png')
            PIXI.Loader.shared.add('ic_spice_up', '/assets/img/ic_spice_up.png')
            PIXI.Loader.shared.add('ic_spice_down', '/assets/img/ic_spice_down.png')
            PIXI.Loader.shared.add('ic_own_ship', '/assets/img/ic_own_ship.png')
            PIXI.Loader.shared.add('ic_strategy_off', '/assets/img/ic_strategy_off.png')
            PIXI.Loader.shared.add('ic_strategy_on', '/assets/img/ic_strategy_on.png')
            PIXI.Loader.shared.add('ic_warning', '/assets/img/ic_warning.png')
            PIXI.Loader.shared.add('bg_no_mining', '/assets/img/bg_no_mining.png')
            PIXI.Loader.shared.add('bg_pull_price_bar', '/assets/img/bg_pull_price_bar.png')
            PIXI.Loader.shared.add('player_bar_btn_fleet', '/assets/img/player_bar_btn_fleet.png')
            PIXI.Loader.shared.add('player_bar_btn_fleet_hover', '/assets/img/player_bar_btn_fleet_hover.png')
            PIXI.Loader.shared.add('player_bar_btn_fleet_selected', '/assets/img/player_bar_btn_fleet_selected.png')
            PIXI.Loader.shared.add('player_bar_btn_stats', '/assets/img/player_bar_btn_stats.png')
            PIXI.Loader.shared.add('player_bar_btn_stats_hover', '/assets/img/player_bar_btn_stats_hover.png')
            PIXI.Loader.shared.add('player_bar_btn_stats_selected', '/assets/img/player_bar_btn_stats_selected.png')
            PIXI.Loader.shared.add('player_bar_main', '/assets/img/player_bar_main.png')
            PIXI.Loader.shared.add('player_bar_main_hover', '/assets/img/player_bar_main_hover.png')
            PIXI.Loader.shared.add('player_bar_main_selected', '/assets/img/player_bar_main_selected.png')
            PIXI.Loader.shared.add('player_bar_ui', '/assets/img/player_bar_ui.png')
            PIXI.Loader.shared.add('player_bar_ui_hover', '/assets/img/player_bar_ui_hover.png')
            PIXI.Loader.shared.add('player_bar_ui_selected', '/assets/img/player_bar_ui_selected.png')
            PIXI.Loader.shared.add('player_menu_ship_mask', '/assets/img/player_menu_ship_mask.png')
            PIXI.Loader.shared.add('player_menu_stats_bg', '/assets/img/player_menu_stats_bg.png')
            PIXI.Loader.shared.add('player_menu_ship_bg', '/assets/img/player_menu_ship_bg.png')
            PIXI.Loader.shared.add('player_bar_ship', '/assets/img/player_bar_ship.png')
            PIXI.Loader.shared.add('btn_leaderboard', '/assets/img/btn_leaderboard.png')
            PIXI.Loader.shared.add('pull_price_bar_ui', '/assets/img/pull_price_bar_ui.png')
            PIXI.Loader.shared.add('pull_price_bar_ui_hover', '/assets/img/pull_price_bar_ui_hover.png')
            PIXI.Loader.shared.add('pull_price_bar_ui_selected', '/assets/img/pull_price_bar_ui_selected.png')
            PIXI.Loader.shared.add('ic_wallet', '/assets/img/ic_wallet.png')
            PIXI.Loader.shared.add('ic_leaderboard', '/assets/img/ic_leaderboard.png')
            PIXI.Loader.shared.add('ic_allowance', '/assets/img/ic_allowance.png')
            PIXI.Loader.shared.add('preview_circle', '/assets/img/preview_circle.png')
            PIXI.Loader.shared.add('preview_mask', '/assets/img/preview_mask.png')
            PIXI.Loader.shared.add('preview_cometh_bar', '/assets/img/preview_cometh_bar.png')
            PIXI.Loader.shared.add('preview_miner_bar', '/assets/img/preview_miner_bar.png')
            PIXI.Loader.shared.add('ic_preview_close', '/assets/img/ic_preview_close.png')
            PIXI.Loader.shared.add('ic_preview_close_hover', '/assets/img/ic_preview_close_hover.png')
            PIXI.Loader.shared.add('ic_preview_more', '/assets/img/ic_preview_more.png')
            PIXI.Loader.shared.add('ic_preview_more_hover', '/assets/img/ic_preview_more_hover.png')
            PIXI.Loader.shared.add('ic_notif_mining', '/assets/img/ic_notif_mining.png')
            PIXI.Loader.shared.add('ic_portal', '/assets/img/ic_portal.png')
            PIXI.Loader.shared.add('ic_items', '/assets/img/ic_items.png')
            PIXI.Loader.shared.add('ic_cooling_booster', '/assets/img/ic_cooling_booster.png')
            PIXI.Loader.shared.add('item_cup_ticket', '/assets/img/item_cup_ticket.png')
            PIXI.Loader.shared.add('modal_bottom_title', '/assets/img/modal_bottom_title.png')
            PIXI.Loader.shared.add('item_count_panel', '/assets/img/item_count_panel.png')
            PIXI.Loader.shared.add('bg_portal_info', '/assets/img/bg_portal_info.png')
            PIXI.Loader.shared.add('bg_star_detail', '/assets/img/bg_star_detail.png')
            PIXI.Loader.shared.add('ic_back_to_solar', '/assets/img/ic_back_to_solar.png')
            PIXI.Loader.shared.add('ic_back_to_solar_ship', '/assets/img/ic_back_to_solar_ship.png')
            PIXI.Loader.shared.add('ic_galaxy', '/assets/img/ic_galaxy.png')
            PIXI.Loader.shared.add('bg_galaxy', '/assets/img/bg_galaxy.png')
            PIXI.Loader.shared.add('ic_galaxy_ship', '/assets/img/ic_galaxy_ship.png')
            PIXI.Loader.shared.add('ic_galaxy_own_ship', '/assets/img/ic_galaxy_own_ship.png')
            PIXI.Loader.shared.add('ic_galaxy_comet', '/assets/img/ic_galaxy_comet.png')
            PIXI.Loader.shared.add('bg_selector_ship', '/assets/img/bg_selector_ship.png')
            PIXI.Loader.shared.add('bg_selector_ship_selected', '/assets/img/bg_selector_ship_selected.png')
            PIXI.Loader.shared.add('bg_selector_sun_category', '/assets/img/bg_selector_sun_category.png')
            PIXI.Loader.shared.add('jump_ship_cancel', '/assets/img/jump_ship_cancel.png')
            PIXI.Loader.shared.add('jump_ship_counter', '/assets/img/jump_ship_counter.png')
            PIXI.Loader.shared.add('jump_ship_current', '/assets/img/jump_ship_current.png')
            PIXI.Loader.shared.add('jump_ship_next', '/assets/img/jump_ship_next.png')
            PIXI.Loader.shared.add('jump_ship_prev', '/assets/img/jump_ship_prev.png')
            PIXI.Loader.shared.add('keyboard_shift_lmouse', '/assets/img/keyboard_shift_lmouse.png')
            PIXI.Loader.shared.add('keyboard_lmouse', '/assets/img/keyboard_lmouse.png')
            PIXI.Loader.shared.add('keyboard_rmouse', '/assets/img/keyboard_rmouse.png')
            PIXI.Loader.shared.add('notif_header_comet_stack_in', '/assets/img/notif_header_comet_stack_in.png')
            PIXI.Loader.shared.add('notif_header_comet_stack_out', '/assets/img/notif_header_comet_stack_out.png')
            PIXI.Loader.shared.add('comet_progress_bar', '/assets/img/comet_progress_bar.png')
            PIXI.Loader.shared.add('comet_stack_list_next', '/assets/img/comet_stack_list_next.png')
            PIXI.Loader.shared.add('comet_stack_list_prev', '/assets/img/comet_stack_list_prev.png')
            PIXI.Loader.shared.add('comet_stack_miner_container', '/assets/img/comet_stack_miner_container.png')
            PIXI.Loader.shared.add('comet_stack_gain_bar', '/assets/img/comet_stack_gain_bar.png')
            PIXI.Loader.shared.add('comet_stack_rover_count_bar', '/assets/img/comet_stack_rover_count_bar.png')
            PIXI.Loader.shared.add('comet_stack_slots_bar', '/assets/img/comet_stack_slots_bar.png')
            PIXI.Loader.shared.add('comet_stack_ship_detail_bg', '/assets/img/comet_stack_ship_detail_bg.png')
            PIXI.Loader.shared.add('ic_machinery', '/assets/img/ic_machinery.png')
            PIXI.Loader.shared.add('ic_tx', '/assets/img/ic_tx.png')
            PIXI.Loader.shared.add('ic_staking_comet_rover', '/assets/img/ic_staking_comet_rover.png')
            PIXI.Loader.shared.add('tuto1', '/assets/img/tuto/tuto1.png')
            PIXI.Loader.shared.add('tuto2', '/assets/img/tuto/tuto2.png')
            PIXI.Loader.shared.add('tuto3', '/assets/img/tuto/tuto3.png')
            PIXI.Loader.shared.add('tuto4', '/assets/img/tuto/tuto4.png')
            PIXI.Loader.shared.add('ic_tuto_1', '/assets/img/tuto/ic_tuto_1.png')
            PIXI.Loader.shared.add('ic_tuto_2', '/assets/img/tuto/ic_tuto_2.png')
            PIXI.Loader.shared.add('ic_tuto_3', '/assets/img/tuto/ic_tuto_3.png')
            PIXI.Loader.shared.add('ic_tuto_4', '/assets/img/tuto/ic_tuto_4.png')
            PIXI.Loader.shared.add('ic_you_wrapper', '/assets/img/ic_you_wrapper.png')
            PIXI.Loader.shared.add('polygon_ticket_cup', '/assets/img/tube/items/polygon_ticket_cup.png')
            PIXI.Loader.shared.add('polygon_ticket_trophy', '/assets/img/tube/items/polygon_ticket_trophy.png')
            PIXI.Loader.shared.add('polygon_ticket_grandprix', '/assets/img/tube/items/polygon_ticket_grandprix.png')
            PIXI.Loader.shared.add('btn_gas_big', '/assets/img/btn_gas_big.png')
            PIXI.Loader.shared.add('btn_gas_default', '/assets/img/btn_gas_default.png')
            PIXI.Loader.shared.add('ic_gas_custom', '/assets/img/ic_gas_custom.png')
            PIXI.Loader.shared.add('ic_gas_fast', '/assets/img/ic_gas_fast.png')
            PIXI.Loader.shared.add('ic_gas_fastest', '/assets/img/ic_gas_fastest.png')
            PIXI.Loader.shared.add('ic_gas_standard', '/assets/img/ic_gas_standard.png')
            PIXI.Loader.shared.add('ic_portal_cup', '/assets/img/ic_portal_cup.png')
            PIXI.Loader.shared.add('ic_portal_stadium', '/assets/img/ic_portal_stadium.png')
            PIXI.Loader.shared.add('stadium_camera_ship', '/assets/img/stadium_camera_ship.png')
            PIXI.Loader.shared.add('bg_ship_rarity', '/assets/img/bg_ship_rarity.png')

            for (let i = 1; i <= Game.SHIP_MODEL_COUNT; i++) {
                PIXI.Loader.shared.add(i + '_miner', '/assets/img/miners/' + i + '.json')
            }

            // MULE SHIP
            PIXI.Loader.shared.add('1000_miner', '/assets/img/miners/1000.json')

            for (let i = 0; i <= Game.STARS_COUNT; i++) {
                PIXI.Loader.shared.add(i + '_star', '/assets/img/stars/' + i + '.png')
            }
            PIXI.Loader.shared.add(100 + '_star', '/assets/img/stars/' + 100 + '.png')
            PIXI.Loader.shared.add('star_bg_default', '/assets/img/stars/bg_default.png')
            PIXI.Loader.shared.add('star_bg_stadium', '/assets/img/stars/bg_stadium.png')
            PIXI.Loader.shared.add('cup_polygon', '/assets/img/cups/polygon.png')

            for (let i = 0; i < 2; i++) {
                PIXI.Loader.shared.add(i + '_comet', '/assets/img/comets/' + i + '.png')
                PIXI.Loader.shared.add(i + '_comet_particle', '/assets/img/comets/' + i + '_particle.png')
                PIXI.Loader.shared.add(i + '_comet_overlay', '/assets/img/comets/' + i + '_overlay.png')
            }

            // TEST PUBS
            PIXI.Loader.shared.add('test_pub', '/assets/img/pubs/test_pub.png')
            PIXI.Loader.shared.add('moons_pub', '/assets/img/pubs/moons_pub.png')
            PIXI.Loader.shared.add('stater_pub', '/assets/img/pubs/pub_stater.png')

            // TMP TO REMOVE
            PIXI.Loader.shared.add('tmp_card', '/assets/tmp/7000002.png')

            PIXI.Loader.shared.load()
        } catch (err) {
            console.log('CATCH ERR', err)
            this.setReady()
        }
    }

    private async setReady() {
        await MapService.getInstance().loadMiners()
        //console.log(PlayerService.getInstance().miningPlayers)
        this.core = new GCore()
        setTimeout(() => {
            this.isLoaded = true
        }, 100)
    }

    private declareFonts() {
        const options = {
            chars: [
                ['a', 'z'],
                ['A', 'Z'],
                ['0', '9'],
                `:-_!@#$%^&*()~{}[].[]{}()<>*+-=!?^$|/•àâªæáäãåāéèêëęėēîïìíįīôœºöòóõøōûùüúūÿçÀÂªÆÁÄÃÅĀÉÈÈÊËĘĖĒÎÏÌÍĮĪÔŒºÖÒÓÕØÛÙÜÚŪŸ', `
            ] as any,
            resolution: 2
        }

        const fontSizes = [3, 4, 5, 6, 7, 8]
        const resolutions = Resolution.availableScales
        resolutions.forEach(a => {
            fontSizes.forEach(b => {
                PIXI.BitmapFont.from(
                    `main_${a}_${b}`,
                    {
                        fontFamily: 'MainFont',
                        fill: '#FFFFFF',
                        fontSize: a * b
                    },
                    options
                )
            })
        })
    }
}
