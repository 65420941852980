import GameElement from '../GameElement'
import Miner from '../models/Miner'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import Comete from '../models/Comete'
import StakingComet from '../models/StakingComet'
import MapService from '../services/MapService'
import Distance from '@/helpers/Distance'
import PlayerService from '../services/PlayerService'
import GSprite from '../pixi-scale/GSprite'
import Resolution from '@/helpers/Resolution'
import GAnimatedSprite from '../pixi-scale/GAnimatedSprite'
import StakedSpaceshipsService from '../services/web3/StakedSpaceshipsService'
import CometManagerService from '../services/web3/CometManagerService'
import Colors from '@/helpers/Colors'
import { mean, quantile } from '@/helpers/MathHelper'
import { MapFilter } from '../models/map/MapFilter'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import { OutlineFilter } from '@pixi/filter-outline'
import MouseService from '../services/MouseService'
import { Portal } from '../models/Portal'
import PortalPreviewComponent from './portal/PortalPreviewComponent'
import { GlowFilter } from '@pixi/filter-glow'
import NotificationService from '../services/NotificationService'
import { MiningNotification } from '../models/notification/MiningNotification'
import KeyboardService from '../services/KeyboardService'
import PIXIAppService from '@/services/PIXIAppService'
import { StadiumExtraDecorator } from './mapExtraDecorator/StadiumExtraDecorator'
import { MapExtraDecoratorInterface } from './mapExtraDecorator/MapExtraDecoratorInterface'
import GalaxyService from '@/services/GalaxyService'
import { PortalTravelNotification } from '../models/notification/PortalTravelNotification'

class MinerItem {
    public quantileIndex = 0
    constructor(
        public miner: Miner,
        public sprite: PIXI.Sprite,
        public container: PIXI.Container,
        public isSelf: boolean,
        public isOverring: boolean = false
    ) {}

    setX(x: number) {
        if (this.sprite.parent == this.container) {
            this.sprite.x = 0
            this.container.x = x
        } else {
            this.sprite.x = x
        }
    }

    setY(y: number) {
        if (this.sprite.parent == this.container) {
            this.sprite.y = 0
            this.container.y = y
        } else {
            this.sprite.y = y
        }
    }

    setVisible(visible: boolean) {
        this.sprite.alpha = visible ? 1 : 0
        this.sprite.visible = visible

        if (this.sprite.parent == this.container) {
            this.container.visible = visible
        }
    }

    setParent(parent: PIXI.Container): boolean {
        if (parent instanceof PIXI.ParticleContainer) {
            if (this.sprite.parent != parent) {
                if (this.container.parent) {
                    this.container.parent.removeChild(this.container)
                }
                parent.addChild(this.sprite)
                return true
            }
        } else {
            if (!this.container.parent || this.miner.isSelf) {
                if (this.sprite.parent) {
                    this.sprite.parent.removeChild(this.sprite)
                }
                this.container.addChild(this.sprite)
                parent.addChild(this.container)
                return true
            }
        }
        return false
    }

    destroy() {
        this.sprite.destroy()
        this.container.destroy({ children: true })
    }
}
class CometItem {
    constructor(public comet: Comete | StakingComet, public container: PIXI.Container) {}
}
class PortalItem {
    constructor(public portal: Portal, public container: PIXI.Container) {}
}
class GraphicOrbit {
    constructor(public graphic: PIXI.Graphics, public onRedraw: (zoom: number) => void) {}

    redraw(zoom: number) {
        this.onRedraw(zoom)
    }
}

export default class MapComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public interactiveMinerContainer: PIXI.Container = new PIXI.Container()
    public nonInteractiveMinerContainer: PIXI.Container = new PIXI.ParticleContainer(2500, { tint: true })
    public noMiningZoneContainer: PIXI.Container = new PIXI.Container()
    public extraContainer: PIXI.Container = new PIXI.Container()
    public focusedComet?: Comete | StakingComet = undefined
    private disabledQuantile: number[] = []

    public onPlayerClick: (player: Miner) => void = miner => {
        return
    }

    public onCometClick: (comet: Comete | StakingComet) => void = comet => {
        return
    }

    public onMinerOver: (miner: Miner) => void = _ => {
        return
    }

    public onMinerOut: (miner: Miner) => void = () => {
        return
    }

    public onMinerLeaveInteractiveArea: (miner: Miner) => void = () => {
        return
    }

    public onReady: () => void = () => {
        return
    }

    public visibleArea = new PIXI.Rectangle(0, 0, 100, 100)
    public interactiveArea = new PIXI.Rectangle(0, 0, 100, 100)
    public zoom = 1
    public mapFilter: MapFilter = MapFilter.PullPrice

    private mapService: MapService = MapService.getInstance()
    private playerIndicator = new GSprite('ic_map_player')
    private sun = new GSprite('sun')
    private miners: MinerItem[] = []
    private comets: CometItem[] = []
    private portals: PortalItem[] = []
    private minerInArea: MinerItem[] = []

    private currentMiner: Miner = PlayerService.getInstance().miner
    private comethOrbitsGraphics: PIXI.Graphics = new PIXI.Graphics()
    private noMiningMask: PIXI.Graphics = new PIXI.Graphics()
    private noMiningZoneSprite = PIXI.TilingSprite.from('bg_no_mining', {
        width: MapService.getInstance().noMiningZone.radius * 2,
        height: MapService.getInstance().noMiningZone.radius * 2
    })
    private currentGraphic?: GraphicOrbit
    private playerGraphic = new PIXI.Graphics()
    private limitOrbitGraphic = new PIXI.Graphics()
    private viewScale = Resolution.scale
    private doDisplaySelfOnly = false
    private debugInteractiveArea = new PIXI.Graphics()
    private maxDistance = 0
    private extraDecorator?: MapExtraDecoratorInterface

    public onConfirm = () => {
        return
    }
    public onCancel = () => {
        return
    }

    async init() {
        this.miners.forEach(it => it.destroy())
        this.comets.forEach(it => it.container.destroy({ children: true }))
        this.portals.forEach(it => it.container.destroy({ children: true }))
        this.miners = []
        this.comets = []
        this.portals = []
        this.minerInArea = []
        this.currentGraphic?.graphic.destroy()
        this.currentGraphic = undefined
        this.interactiveMinerContainer.removeChildren()
        this.nonInteractiveMinerContainer.removeChildren()
        this.extraContainer.removeChildren()
        this.comethOrbitsGraphics.clear()
        this.playerGraphic.clear()

        this.mapService.map.currentStar.cometes.forEach((comete: Comete) => {
            this.constructComet(comete)
        })
        this.mapService.map.currentStar.stakingComets.forEach((comete: StakingComet) => {
            this.constructComet(comete)
        })
        this.mapService.map.currentStar.miners.forEach(miner => {
            this.constructMiner(miner)
        })
        this.mapService.map.currentStar.portals.forEach(portal => {
            this.constructPortal(portal)
        })
        this.currentMiner = PlayerService.getInstance().miner

        this.interactiveMinerContainer.sortChildren()

        this.sun.texture = PIXI.Texture.from(MapService.getInstance().map.currentStar.id + '_star')

        this.container.addChildAt(this.playerGraphic, 0)
        this.container.addChildAt(this.comethOrbitsGraphics, 0)
        this.container.addChildAt(this.limitOrbitGraphic, 0)
        this.container.addChildAt(this.extraContainer, 0)

        this.playerIndicator.anchor.set(0.5, 0.7)
        this.playerIndicator.position.x = this.currentMiner.x * this.scale
        this.playerIndicator.position.y = this.currentMiner.y * this.scale
        this.container.addChild(this.playerIndicator)

        this.updatedPlayer()
        this.drawPlayerGraphic()
        this.onResize()

        this.redrawData()
        this.updateAreaVisibility(new PIXI.Rectangle(0, 0, 100, 100))
        this.onReady()
    }

    constructor() {
        setInterval(() => {
            this.handleInteractiveArea()
        }, 200)

        const localQuantiles = localStorage.getItem('disabledQuantiles')
        if (localQuantiles) {
            this.disabledQuantile = localQuantiles.split(',').map(it => Number(it))
        }
        document.addEventListener('serverPostTick', () => {
            this.redrawData()
            this.checkVisibility()
            if (this.mapFilter == MapFilter.PullPrice) {
                this.checkPullingPriceRange()
            }
        })

        this.noMiningZoneContainer.addChild(this.noMiningZoneSprite)
        this.container.addChild(this.noMiningZoneContainer)
        this.noMiningZoneContainer.scale.set(Resolution.scale)
        this.drawNoMiningZone()
        this.container.addChild(this.noMiningMask)

        this.container.addChild(this.nonInteractiveMinerContainer)
        this.container.addChild(this.interactiveMinerContainer)
        this.container.addChild(this.sun)

        document.addEventListener(MapService.MINER_ADDED_ON_MAP_EVENT, event => {
            const miner = (event as CustomEvent).detail as Miner
            if (miner.solarSystemID != MapService.getInstance().currentSolarSystemId || this.miners.find(it => it.miner.id == miner.id)) return

            this.constructMiner(miner)

            this.nonInteractiveMinerContainer.sortChildren()
            this.interactiveMinerContainer.sortChildren()
        })

        document.addEventListener(MapService.COMET_ADDED_ON_MAP_EVENT, event => {
            const comet = (event as CustomEvent).detail as Comete
            if (comet.solarSystemID != MapService.getInstance().currentSolarSystemId || this.comets.find(it => it.comet.id == comet.id)) return
            this.constructComet(comet)
        })

        document.addEventListener(MapService.STAKING_COMET_ADDED_ON_MAP_EVENT, event => {
            const comet = (event as CustomEvent).detail as Comete
            if (comet.solarSystemID != MapService.getInstance().currentSolarSystemId || this.comets.find(it => it.comet.id == comet.id)) return
            this.constructComet(comet)
        })

        PlayerService.getInstance().setOnCurrentPlayerChange(miner => {
            this.currentMiner = miner
            this.updatedPlayer()
            this.onResize()
        })

        document.addEventListener(MapService.SOLAR_SYSTEM_MINER_ADDED, event => {
            const solarSystem = (event as CustomEvent).detail.solarSystemId
            const miner = (event as CustomEvent).detail.miner as Miner
            if (solarSystem != MapService.getInstance().currentSolarSystemId || this.miners.find(it => it.miner.id == miner.id)) return

            this.constructMiner(miner)

            this.nonInteractiveMinerContainer.sortChildren()
            this.interactiveMinerContainer.sortChildren()
        })
        document.addEventListener(MapService.SOLAR_SYSTEM_MINER_REMOVED, event => {
            const miner = (event as CustomEvent).detail.miner as Miner
            const solarSystem = (event as CustomEvent).detail.solarSystemId
            if (solarSystem != MapService.getInstance().currentSolarSystemId) return
            this.removeMiner(miner.id)
        })
        document.addEventListener(StakedSpaceshipsService.SHIP_LEAVE_GAME_EVENT, event => {
            const minerId = (event as CustomEvent).detail
            this.removeMiner(minerId)
        })

        document.addEventListener(CometManagerService.REMOVE_COMET_EVENT, event => {
            const cometId = (event as CustomEvent).detail.cometId
            this.comets.forEach((comet, index) => {
                if (comet.comet.id == cometId) {
                    this.container.removeChild(comet.container)
                    this.comets.splice(index, 1)
                    return
                }
            })
        })

        document.addEventListener(MapService.CURRENT_SOLAR_SYSTEM_CHANGED, () => {
            this.extraDecorator = GalaxyService.getInstance().getExtraDecorator(this.extraContainer, MapService.getInstance().map.currentStar.id)
            this.extraDecorator?.init()
            this.init()
        })

        if (MouseService.getInstance().mobileIgnored) {
            this.container.interactive = true
            this.container.interactiveChildren = true
            this.container.hitArea = new PIXI.Rectangle(-10000, -10000, 20000, 20000)
        }
        this.container.on('pointertap', (event: PIXI.InteractionEvent) => {
            if (!MouseService.getInstance().mobileIgnored) return

            const realX = (-this.container.x + event.data.global.x) / this.scale
            const realY = (-this.container.y + event.data.global.y) / this.scale

            const minX = realX - 9
            const maxX = realX + 9
            const minY = realY - 9
            const maxY = realY + 9

            const foundMiner = this.miners.find(it => {
                return it.miner.x < maxX && it.miner.x > minX && it.miner.y < maxY && it.miner.y > minY
            })
            if (foundMiner) {
                this.onPlayerClick(foundMiner.miner)
            }
        })
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        // nothing
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    displaySelfOnly() {
        this.doDisplaySelfOnly = true
        this.miners.forEach(it => {
            if (!it.isSelf) {
                it.container.visible = false
            }
        })
        this.comets.forEach(it => {
            it.container.alpha = 0.2
        })
        this.sun.visible = false
    }

    public getZoom(): number {
        return this.zoom
    }

    private removeMiner(minerId: string) {
        if (minerId == this.currentMiner.id) {
            this.currentGraphic?.graphic.destroy()
            this.currentGraphic = undefined
        }
        this.miners.forEach((miner, index) => {
            if (miner.miner.id == minerId) {
                miner.destroy()
                this.miners.splice(index, 1)
                this.checkVisibility()
                return
            }
        })

        this.nonInteractiveMinerContainer.sortChildren()
        this.interactiveMinerContainer.sortChildren()
    }

    public updateAreaVisibility(rect: PIXI.Rectangle) {
        this.visibleArea = rect
        this.checkVisibility()
        this.redrawData()
    }

    public updateZoom(zoom: number) {
        this.zoom = zoom
        this.drawNoMiningZone()
        this.drawPlayerGraphic()
        this.drawCometeOrbits()
        this.redrawData()
        if (this.mapFilter == MapFilter.PullPrice) {
            this.checkPullingPriceRange()
        }
        this.onResize()
    }

    public updateScale(scale: number) {
        this.viewScale = scale
        this.drawPlayerGraphic()
        this.drawCometeOrbits()
    }

    public drawPlayerGraphic() {
        this.playerGraphic.clear()
        this.playerGraphic.lineStyle(Resolution.scale, 0xaa0000, 1)
        this.playerGraphic.drawCircle(0, 0, Math.abs(Distance.getDistance(0, 0, this.currentMiner.x * this.scale, this.currentMiner.y * this.scale)))
        this.playerGraphic.endFill()
    }

    public drawCometeOrbits() {
        this.comethOrbitsGraphics.clear()
        this.comethOrbitsGraphics.lineStyle(Resolution.scale, Colors.Blue400, 1)
        const comets = this.comets.sort(a => (a.comet.id == this.focusedComet?.id ? 1 : -1))
        comets.forEach(it => {
            if (this.focusedComet && this.focusedComet.id == it.comet.id) {
                this.comethOrbitsGraphics.lineStyle(Resolution.scale, Colors.Blue800, 1)
            }
            this.comethOrbitsGraphics.drawCircle(
                it.comet.orbit.center.x * this.scale,
                it.comet.orbit.center.y * this.scale,
                it.comet.orbit.last.distance * this.scale
            )
            this.comethOrbitsGraphics.lineStyle(Resolution.scale, Colors.Blue400, 1)
        })

        this.comethOrbitsGraphics.lineStyle(Resolution.scale, Colors.Purple100, 1)
        this.portals.forEach(it => {
            this.comethOrbitsGraphics.drawCircle(
                it.portal.orbit.center.x * this.scale,
                it.portal.orbit.center.y * this.scale,
                it.portal.orbit.last.distance * this.scale
            )
        })
        this.comethOrbitsGraphics.endFill()
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.alpha = perc
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await animate('easeInQuad', 300, (perc: number) => {
            this.container.alpha = 1 - perc
        })
        callback()
    }

    private redrawData() {
        if (!this.container.visible) return

        this.extraDecorator?.updateParams({ scale: this.scale, distance: this.maxDistance })
        this.extraDecorator?.redraw()

        this.minerInArea.forEach(it => {
            it.setX(it.miner.x * this.scale)
            it.setY(it.miner.y * this.scale)

            this.tintShips(it)

            if (it.miner.isSelf) {
                const isCool = it.miner.isCool()
                const sprite = it.sprite
                if (isCool) {
                    sprite.alpha = 1
                } else if (!isCool) {
                    sprite.alpha = 0.5
                }
            }

            it.container.children[0].scale.set(this.scale)
            if (this.zoom > 0.2) {
                it.container.children[0].visible = it.miner.isSelf || it.isOverring
            } else {
                it.container.children[0].visible = false
            }
        })
        this.comets.forEach(it => {
            const angle = (it.comet.piValue / Math.PI / 2) * 360 - 180
            it.container.position.x = it.comet.x * this.scale
            it.container.position.y = it.comet.y * this.scale
            it.container.getChildAt(0).angle = angle
            if (it.container.children.length > 1) {
                if (!(it.comet instanceof StakingComet)) return
                const stackContainer: PIXI.Container = it.container.getChildAt(1) as PIXI.Container
                stackContainer.x = Resolution.margin2 * 2 * Math.abs(Math.cos((angle * Math.PI) / 180))
                stackContainer.y = Resolution.margin2 * 2 * Math.abs(Math.sin((angle * Math.PI) / 180))
                const text = stackContainer.getChildAt(1) as PIXI.BitmapText
                text.text = it.comet.playerMiners.length.toString()
            }
        })

        this.portals.forEach(it => {
            it.container.position.x = it.portal.x * this.scale
            it.container.position.y = it.portal.y * this.scale
            it.container.children[0].scale.set(this.scale)
        })

        this.playerIndicator.position.x = this.currentMiner.x * this.scale
        this.playerIndicator.position.y = this.currentMiner.y * this.scale
        this.drawPlayerGraphic()
        this.currentGraphic?.redraw(this.zoom)
    }

    private constructPortal(portal: Portal) {
        const portalContainer = new PIXI.Container()
        portalContainer.position.x = portal.x * this.scale
        portalContainer.position.y = portal.y * this.scale

        const isStadiumPortal = GalaxyService.getInstance().isStadium(portal.toId) || GalaxyService.getInstance().isStadium(portal.fromId)
        const portalSprite = new GSprite(isStadiumPortal ? 'ic_portal_stadium' : 'ic_portal')
        portalSprite.anchor.set(0.5, 0.5)

        portalSprite.filters = isStadiumPortal ? [new GlowFilter({ color: Colors.Purple500, quality: 0.9 })] : []
        const graphic = new PIXI.Graphics()
        graphic.lineStyle(1, Colors.Purple500, 0.3)
        graphic.beginFill(Colors.Purple500, 0.3)
        graphic.drawCircle(0, -1, portal.area)
        graphic.endFill()
        graphic.interactive = false
        portalContainer.addChild(graphic)

        portalSprite.interactive = true
        portalSprite.cursor = 'pointer'
        let portalPreview: PortalPreviewComponent | undefined = undefined
        portalSprite.on('mouseover', () => {
            portalPreview = new PortalPreviewComponent(portal)
            portalPreview.scale.set(Resolution.scale)
            portalPreview.x = 32
            portalPreview.y = Math.floor(-portalPreview.height / 2)
            const graphics = new PIXI.Graphics()
            this.container.addChildAt(graphics, 4)
            this.currentGraphic = new GraphicOrbit(graphics, (zoom: number) => {
                graphics.clear()
                graphics.lineStyle(Resolution.scale, Colors.Purple500, 1)
                graphics.drawCircle(portal.orbit.center.x * this.scale, portal.orbit.center.y * this.scale, portal.orbit.last.distance * this.scale)
            })
            this.currentGraphic.redraw(this.zoom)
            portalContainer.addChild(portalPreview)
        })

        portalSprite.on('mouseout', () => {
            if (this.currentGraphic) {
                this.container.removeChild(this.currentGraphic!.graphic)
                this.currentGraphic = undefined
            }
            if (portalPreview) {
                portalPreview.destroy({ children: true })
                portalPreview = undefined
            }
        })
        portalSprite.on('pointertap', () => {
            // DEV Only, to test notification for comets
            if (process.env.NODE_ENV == 'development' && KeyboardService.getInstance().isShiftPressed) {
                const notif = new PortalTravelNotification(PlayerService.getInstance().miner, portal, Date.now())
                NotificationService.getInstance().add(notif)
            }

            if (this.currentGraphic) {
                this.container.removeChild(this.currentGraphic!.graphic)
            }
        })
        portalContainer.addChild(portalSprite)

        this.portals.push(new PortalItem(portal, portalContainer))

        this.container.addChild(portalContainer)

        this.drawCometeOrbits()
    }

    private constructComet(comete: Comete | StakingComet) {
        const cometContainer = new PIXI.Container()
        cometContainer.position.x = comete.x * this.scale
        cometContainer.position.y = comete.y * this.scale

        const cometeSprite = new GAnimatedSprite('comete')
        cometeSprite.animationSpeed = 0.2
        cometeSprite.play()
        cometeSprite.anchor.set(0.5, 0.25)

        if (comete instanceof StakingComet) {
            cometeSprite.filters = [new GlowFilter({ color: Colors.Green500, outerStrength: 2 })]
        }

        cometContainer.interactive = true
        cometContainer.cursor = 'pointer'
        cometContainer.on('mouseover', () => {
            const graphics = new PIXI.Graphics()
            this.container.addChild(graphics)
            this.currentGraphic = new GraphicOrbit(graphics, (zoom: number) => {
                graphics.clear()
                graphics.lineStyle(Resolution.scale, 0x55bbff, 1)
                graphics.drawCircle(comete.orbit.center.x * this.scale, comete.orbit.center.y * this.scale, comete.orbit.last.distance * this.scale)
            })
            this.currentGraphic.redraw(this.zoom)
        })

        cometContainer.on('mouseout', () => {
            this.container.removeChild(this.currentGraphic!.graphic)
            this.currentGraphic = undefined
        })
        cometContainer.on('pointertap', () => {
            // DEV Only, to test notification for comets
            if (process.env.NODE_ENV == 'development' && KeyboardService.getInstance().isShiftPressed) {
                const mining = new MiningNotification(PlayerService.getInstance().miner, comete, Date.now())
                NotificationService.getInstance().add(mining)
            }

            this.onCometClick(comete)
            this.container.removeChild(this.currentGraphic!.graphic)
        })
        cometContainer.addChild(cometeSprite)

        this.comets.push(new CometItem(comete, cometContainer))

        if (comete instanceof StakingComet) {
            const stackContainer = new PIXI.Container()
            stackContainer.scale.set(Resolution.scale)
            const icon = PIXI.Sprite.from('ic_staking_comet_rover')
            const value = new MainBitmapText('0', { fontSize: 5 })
            icon.x = 0
            value.x = 12
            value.y = 2
            stackContainer.x = Resolution.margin6
            stackContainer.filters = [new OutlineFilter(Resolution.scale, Colors.Blue300) as any]

            stackContainer.addChild(icon)
            stackContainer.addChild(value)
            cometContainer.addChild(stackContainer)
        }

        this.container.addChild(cometContainer)

        this.drawCometeOrbits()
    }

    private constructMiner(miner: Miner) {
        const minerContainer = new PIXI.Container()
        minerContainer.interactive = true
        const minerSprite = new GSprite(miner.isSelf ? 'ic_own_ship' : 'ic_miner')
        minerSprite.tint = miner.isSelf ? Colors.Green500 : Colors.Blue800
        minerContainer.position.x = miner.x * this.scale - 1.5
        minerContainer.position.y = miner.y * this.scale - 1.5

        const graphic = new PIXI.Graphics()
        graphic.lineStyle(1, 0x55bbff, 0.3)
        graphic.beginFill(0x55bbff, 0.3)
        graphic.drawCircle(0, -1, miner.miningArea)
        graphic.endFill()
        graphic.visible = miner.isSelf
        minerContainer.addChild(graphic)
        // minerContainer.addChild(minerSprite)

        const minerItem = new MinerItem(miner, minerSprite, minerContainer, miner.isSelf)
        this.miners.push(minerItem)
        if (miner.isSelf) {
            this.interactiveMinerContainer.addChild(minerContainer)
        } else {
            this.nonInteractiveMinerContainer.addChild(minerSprite)
        }
        minerContainer.interactive = true
        minerContainer.cursor = 'pointer'
        minerContainer.on('mouseover', () => {
            minerItem.isOverring = true
            const graphics = new PIXI.Graphics()
            graphics.alpha = 0.5
            this.container.addChild(graphics)
            this.currentGraphic = new GraphicOrbit(graphics, (_: number) => {
                graphics?.clear()
                graphics?.lineStyle(Resolution.scale, 0x55bbff, 1)
                graphics?.drawCircle(0, 0, Math.abs(Distance.getDistance(0, 0, minerContainer.position.x + 1.5, minerContainer.position.y + 1.5)))
            })
            this.currentGraphic?.redraw(this.zoom)

            graphic.visible = true
            this.onMinerOver(miner)
        })
        minerContainer.on('mouseout', () => {
            this.container.removeChild(this.currentGraphic!.graphic)
            this.onMinerOut(miner)
            minerItem.isOverring = false
            graphic.visible = miner.isSelf
        })

        minerContainer.on('pointertap', () => {
            this.onPlayerClick(miner)
            this.container.removeChild(this.currentGraphic!.graphic)
        })
    }

    private drawNoMiningZone() {
        this.noMiningMask.clear()
        this.noMiningMask.beginFill(0xffffff)
        this.noMiningMask.drawCircle(0, 0, MapService.getInstance().noMiningZone.radius * this.scale)
        this.noMiningMask.endFill()

        this.noMiningZoneContainer.mask = this.noMiningMask
        this.noMiningZoneSprite.x = (-MapService.getInstance().noMiningZone.radius * 2 * this.zoom) / 2
        this.noMiningZoneSprite.y = (-MapService.getInstance().noMiningZone.radius * 2 * this.zoom) / 2
        this.noMiningZoneSprite.width = MapService.getInstance().noMiningZone.radius * 2 * this.zoom
        this.noMiningZoneSprite.height = MapService.getInstance().noMiningZone.radius * 2 * this.zoom
    }

    private updatedPlayer() {
        if (this.currentMiner.solarSystemID != MapService.getInstance().currentSolarSystemId) {
            this.playerIndicator.visible = false
            this.playerGraphic.visible = false
        } else {
            this.playerIndicator.visible = true
            this.playerGraphic.visible = true
            this.playerIndicator.position.x = this.currentMiner.x * this.scale
            this.playerIndicator.position.y = this.currentMiner.y * this.scale
            this.drawPlayerGraphic()
            if (this.doDisplaySelfOnly) {
                this.displaySelfOnly()
            }
        }
    }

    get scale(): number {
        return this.viewScale * this.zoom
    }

    private isInVisibleArea(x: number, y: number) {
        return (
            x > this.visibleArea.x &&
            x < this.visibleArea.x + this.visibleArea.width &&
            y > this.visibleArea.y &&
            y < this.visibleArea.y + this.visibleArea.height
        )
    }

    private isInInteractiveArea(x: number, y: number) {
        return (
            x > this.interactiveArea.x &&
            x < this.interactiveArea.x + this.interactiveArea.width &&
            y > this.interactiveArea.y &&
            y < this.interactiveArea.y + this.interactiveArea.height
        )
    }

    private checkVisibility() {
        if (!this.container.visible) return
        let maxDistance = 0
        this.minerInArea = this.miners.filter(it => {
            let isVisible = this.isInVisibleArea(it.miner.x * this.scale, it.miner.y * this.scale)
            it.setX(it.miner.x * this.scale)
            it.setY(it.miner.y * this.scale)

            if (isVisible && this.mapFilter == MapFilter.PullPrice && !it.isSelf) {
                const quantileIndex = it.miner.pullingQuantileIndex()
                if (this.isQuantileDisabled(quantileIndex)) {
                    isVisible = false
                }
            }

            it.setVisible(isVisible)

            const distance = Distance.getDistance(0, 0, it.miner.x, it.miner.y)
            if (maxDistance < distance) {
                maxDistance = distance
            }
            return isVisible
        })

        maxDistance = Math.floor(maxDistance / 10) * 11
        if (maxDistance < 1000) {
            maxDistance = 1000
        }
        if (this.maxDistance != maxDistance) {
            this.maxDistance = maxDistance
            this.extraContainer.removeChildren()
            this.extraDecorator?.init()
        }
    }

    private checkPullingPriceRange() {
        if (!this.container.visible) return
        this.minerInArea.forEach(miner => {
            const sprite = miner.sprite
            if (miner.miner.pullingPrice.gte(MapService.getInstance().getMinPullPrice())) {
                sprite.tint = this.getHeatMapPercColor(miner.miner.pullingQuantileIndex())
            } else {
                sprite.tint = Colors.Blue100
            }
        })
    }

    public getHeatMapPercColor(percent: number) {
        const colors = [0x0bff00, 0x10d007, 0x25a11f, 0x1cc4b5, 0x55d5ff, 0xf9ff00, 0xffa900, 0xff5d00, 0xff0000, 0xff00f5]
        return colors[Math.abs(percent)]
    }

    public setFriendlyFilter() {
        this.mapFilter = MapFilter.Friendly
        this.miners.forEach(it => {
            this.tintShips(it)
        })
        this.checkVisibility()
        this.checkPullingPriceRange()
    }

    public setPullPriceFilter() {
        this.mapFilter = MapFilter.PullPrice
        this.checkVisibility()
        this.checkPullingPriceRange()
    }

    private tintShips(it: MinerItem) {
        if (!this.container.visible) return

        if (this.mapFilter == MapFilter.Friendly) {
            const sprite = it.sprite
            if (it.miner.pullingPrice.gte(MapService.getInstance().getMinPullPrice()) && !it.miner.isSelf) {
                it.container.zIndex = 0
                sprite.tint = Colors.Blue800
            } else if (it.miner.isSelf) {
                sprite.tint = Colors.Green500
                it.container.zIndex = 1000
            } else {
                sprite.tint = Colors.Blue100
                it.container.zIndex = -1
            }
        }
    }

    private handleInteractiveArea() {
        if (!this.container.visible) return

        const areaSize = Math.max(75 * this.scale, 16)
        this.interactiveArea = new PIXI.Rectangle(
            MouseService.getInstance().position.x - areaSize / 2 - this.container.x,
            MouseService.getInstance().position.y - areaSize / 2 - this.container.y,
            areaSize,
            areaSize
        )

        if (this.debugInteractiveArea.parent) {
            this.debugInteractiveArea.clear()
            this.debugInteractiveArea.beginFill(Colors.Red500, 0.4)
            this.debugInteractiveArea.alpha = 0.4
            this.debugInteractiveArea.drawRect(0, 0, areaSize, areaSize)
            this.debugInteractiveArea.x = this.interactiveArea.x
            this.debugInteractiveArea.y = this.interactiveArea.y
            this.debugInteractiveArea.endFill()
        }

        this.minerInArea.forEach(it => {
            if (it.isSelf || this.isInInteractiveArea(it.miner.x * this.scale, it.miner.y * this.scale)) {
                it.setParent(this.interactiveMinerContainer)
            } else {
                if (it.setParent(this.nonInteractiveMinerContainer)) {
                    this.onMinerLeaveInteractiveArea(it.miner)
                }
            }

            it.setX(it.miner.x * this.scale)
            it.setY(it.miner.y * this.scale)
        })
    }

    public isQuantileDisabled(i: number) {
        return this.disabledQuantile.indexOf(i) >= 0
    }

    public disableQuantile(i: number) {
        !this.isQuantileDisabled(i) ? this.disabledQuantile.push(i) : []
        this.checkVisibility()

        localStorage.setItem('disabledQuantiles', this.disabledQuantile.join(','))
    }

    public enableQuantile(i: number) {
        const index = this.disabledQuantile.indexOf(i)
        index >= 0 ? this.disabledQuantile.splice(index, 1) : []
        this.checkVisibility()

        localStorage.setItem('disabledQuantiles', this.disabledQuantile.join(','))
    }
}
