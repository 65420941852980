import TheGraphService from '@/game/services/TheGraphService'
import ShipNFT from './ShipNFT'
import WalletService from '@/game/services/web3/WalletService'
import Web3 from 'web3'

export class Reward {
    amount = '0'
    usd = 0
}

export enum State {
    Owned = 1,
    Borrowed,
    Loaned
}

export class ShipWithGameStat extends ShipNFT {
    public owner = ''
    public jumpsCount = '0'
    public jumpsExpense = '0'
    public pullsCount = '0'
    public pullsEarnings = '0'
    public minesCount = '0'
    public rewards = new Map<string, Reward>()
    public usd = 0

    constructor(id: string, owner: string) {
        super(id)
        this.owner = owner
    }

    public async init(): Promise<ShipWithGameStat> {
        await this.updateFromTheGrpah()
        await this.updateFromMetaData()
        return this
    }

    private async updateFromTheGrpah(): Promise<ShipWithGameStat> {
        await this.update(await TheGraphService.getInstance().getPlayerShip(this.owner, this.id))
        return this
    }

    private async update(data: any): Promise<ShipWithGameStat> {
        if (!data) return this
        this.jumpsCount = data.jumpsCount
        this.jumpsExpense = data.jumpsExpense
        this.pullsCount = data.pullsCount
        this.pullsEarnings = data.pullsEarnings
        this.minesCount = data.minesCount
        for (let i = 0; i < data.rewards.length; i++) {
            const reward = new Reward()
            reward.amount = data.rewards[i].amount
            const walletService = WalletService.getInstance()
            let price = 0
            if (data.rewards[i].token.id == '0x0000000000000000000000000000000000000000') {
                price = await walletService.maticPrice()
            } else {
                price = await walletService.USDPriceOf(data.rewards[i].token.id)
            }
            reward.usd = price * parseFloat(Web3.utils.fromWei(data.rewards[i].amount))
            this.rewards.set(data.rewards[i].token.id, reward)
            this.usd += reward.usd
        }
        return this
    }

    public state: () => State = () => {
        return State.Owned
    }

    public earned: () => number = () => {
        return this.usd
    }
}

export async function builRentedShip(id: string, owner: string, rewardPercentage: number, state: State): Promise<ShipWithGameStat> {
    const ship = await new ShipWithGameStat(id, owner).init()
    ship.earned = () => {
        return Math.floor(ship.usd * rewardPercentage) / 100
    }
    ship.state = () => {
        return state
    }
    return ship
}
