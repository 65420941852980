<template>
    <div
        class="
            relative
            bg-white
            pt-[369px]
            mobile:pt-[71px]
            pb-[512px]
            mobile:pb-20
            overflow-hidden
            mobile:px-4
        "
    >
        <img
            class="
                absolute
                top-20
                mobile:top-5
                left-0
                w-[508px]
                mobile:w-[254px]
            "
            src="@/assets/tournament/bg_ships_orbits.png"
        />
        <div class="relative max-w-[1140px] mx-auto">
            <div class="grid grid-cols-12 gap-[30px] items-center">
                <div
                    class="
                        col-start-1
                        mobile:col-start-1
                        col-span-6
                        mobile:col-span-12
                    "
                >
                    <h2
                        class="
                            font-display
                            uppercase
                            font-medium
                            text-[120px]
                            leading-[100px]
                            mobile:text-[60px] mobile:leading-[50px]
                            text-[#021A2D]
                        "
                    >
                        Your spaceships<br />Your tokens
                    </h2>
                    <p class="leading-[26px] mt-5"></p>
                    <ButtonLink href="//cometh.io/collection" color="purple" class="mt-6">
                        See all collections
                    </ButtonLink>
                </div>
            </div>
        </div>
        <div
            class="
                absolute
                mobile:relative
                top-20
                mobile:top-0
                -right-28
                mobile:right-0 mobile:mt-[45px]
            "
        >
            <img
                class="
                    w-[708px]
                    image-rendering-pixelated
                    mobile:relative mobile:right-[-70px]
                "
                src="@/assets/tournament/bg_collection.png"
            />
            <div
                class="
                    gradient
                    absolute
                    bottom-0
                    left-0
                    right-0
                    w-screen
                    h-[50%]
                    mobile:h-[304px]
                "
            ></div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ButtonLink from '@/components/ButtonLink.vue'

@Options({
    components: { ButtonLink }
})
export default class Ships extends Vue {}
</script>

<style scoped>
.gradient {
    background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
</style>
