import Colors from '@/helpers/Colors'
import { msToDateHelper } from '@/helpers/TimeHelper'
import * as PIXI from 'pixi.js'
import { Cup } from '../models/Cup'
import Miner from '../models/Miner'
import MainBitmapText from '../pixi-scale/MainBitmapText'

export default class CupPreviewComponent extends PIXI.Container {
    private compWidth = 126
    private compHeight = 34

    constructor(public cup: Cup) {
        super()

        const graphic = new PIXI.Graphics()
        graphic.beginFill(Colors.Purple300)
        graphic.lineStyle(1, Colors.Purple500)
        graphic.drawRect(Math.floor(this.compHeight / 2), 0, this.compWidth - Math.floor(this.compHeight / 2), this.compHeight)

        const img = PIXI.Sprite.from(cup.requirement)
        img.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR
        img.texture.baseTexture.update()
        img.scale.set(1 / 9)
        img.x = this.compHeight / 2 - img.width / 2
        img.y = this.compHeight / 2 - img.height / 2

        const title = new MainBitmapText(cup.name, { fontSize: 5 })
        title.y = 3
        title.x = this.compHeight

        const require = new MainBitmapText('REQUIRE TICKET', { fontSize: 3, tint: Colors.Purple500 })
        require.y = title.y + title.height + 1
        require.x = title.x

        const startTitle = new MainBitmapText('START', { fontSize: 3, tint: Colors.Purple500 })
        startTitle.y = require.y + require.height + 1
        startTitle.x = title.x

        const startTime = new MainBitmapText(msToDateHelper(cup.startAt), { fontSize: 4 })
        startTime.y = startTitle.y - 1 / 3
        startTime.x = startTitle.x + startTitle.width + 2

        const endTitle = new MainBitmapText('END     ', { fontSize: 3, tint: Colors.Purple500 })
        endTitle.y = startTitle.y + startTitle.height + 1
        endTitle.x = title.x

        const endTime = new MainBitmapText(msToDateHelper(cup.endAt), { fontSize: 4 })
        endTime.y = endTitle.y - 1 / 3
        endTime.x = endTitle.x + endTitle.width + 2

        const allowed = new MainBitmapText('ALLOWED SHIPS', { fontSize: 3, tint: Colors.Purple500 })
        allowed.y = endTime.y + endTime.height + 4
        allowed.x = title.x

        this.addChild(graphic)
        this.addChild(img)
        this.addChild(title)
        this.addChild(startTitle)
        this.addChild(startTime)
        this.addChild(endTitle)
        this.addChild(endTime)
        this.addChild(require)
        this.addChild(allowed)

        const rarities = [Miner.RARITY_COMMON, Miner.RARITY_UNCOMMON, Miner.RARITY_RARE, Miner.RARITY_MYTHIC]
        rarities.forEach((it, i) => {
            this.constructRarity(it, i)
        })
    }

    private constructRarity(it: string, i: number) {
        const container = new PIXI.Container()
        const bg = PIXI.Sprite.from('bg_ship_rarity')

        const disabledColor = 0x666666
        let color = disabledColor
        switch (i) {
            case 0:
                color = 0xaaaaaa
                break
            case 1:
                color = Colors.Blue800
                break
            case 2:
                color = Colors.Gold
                break
            case 3:
                color = Colors.Purple500
                break
        }

        bg.tint = this.cup.rarities.indexOf(it) >= 0 ? color : disabledColor

        const text = new MainBitmapText(it, { fontSize: 3, tint: bg.tint })
        text.x = Math.floor(bg.width / 2 - text.width / 2)
        text.y = 1

        container.addChild(bg)
        container.addChild(text)

        container.x = 70 + (i % 2) * 27
        container.y = 25 + Math.floor(i / 2) * 7

        this.addChild(container)
    }
}
