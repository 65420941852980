export default class Cartesian {
    public x = 0
    public y = 0

    constructor(from: any) {
        if (from != undefined) {
            this.x = parseInt(from.x)
            this.y = parseInt(from.y)
        }
    }
}
