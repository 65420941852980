import { StadiumExtraDecorator } from '@/game/components/mapExtraDecorator/StadiumExtraDecorator'
import { Reward } from '@/game/components/modals/LeaderboardModal'
import { Cup } from '@/game/models/Cup'
import Miner from '@/game/models/Miner'
import TimeService from '@/game/services/TimeService'
import RentingService from '@/game/services/web3/RentingService'
import Rental from '@/models/Rental'

export default class GalaxyService {
    public static instance: GalaxyService

    public static getInstance(): GalaxyService {
        if (!this.instance) {
            this.instance = new GalaxyService()
        }
        return this.instance
    }

    public cups: Cup[] = [
        new Cup(
            'Polygon Galactic Cup',
            1625925600000,
            1625925600001,
            16709170, // startBlock
            7200, // sessionDuration
            'polygon_ticket_cup',
            [Miner.RARITY_COMMON]
        ),
        new Cup(
            'Polygon Galactic Trophy',
            1626526800000,
            1626526800001,
            16960700, // startBlock
            3000, // sessionDuration 2 hours / Block time average 2.4 seconde
            'polygon_ticket_trophy',
            [Miner.RARITY_COMMON, Miner.RARITY_UNCOMMON]
        ),
        new Cup(
            'Polygon Galactic Grand Prix',
            1627218000000,
            1627304400000,
            17255300, // startBlock
            3000, // sessionDuration
            'polygon_ticket_grandprix',
            [Miner.RARITY_RARE, Miner.RARITY_MYTHIC]
        )
    ]
    public stadiums = [100]
    public academies = [4, 5]

    public systemDecorationOverride: any = {
        100: {
            bg: 'star_bg_stadium'
        }
    }

    public isAcademy(solarSystemId: number) {
        return this.academies.indexOf(Number(solarSystemId)) >= 0
    }

    public canDisplayLeaderboard(solarSystemId: number) {
        return this.stadiums.concat(this.academies).indexOf(Number(solarSystemId)) >= 0
    }

    public isStadium(solarSystemId: number) {
        return this.stadiums.indexOf(Number(solarSystemId)) >= 0
    }

    public getExtraDecorator(container: PIXI.Container, solarSystemId: number) {
        if (this.isStadium(solarSystemId)) {
            return new StadiumExtraDecorator(container, 'Polygon Galactic Cup')
        } else {
            return undefined
        }
    }

    public getBackground(solarSystemId: number) {
        const item = this.systemDecorationOverride[solarSystemId]
        return item ? item.bg : 'star_bg_default'
    }

    public getCurrentCup(solarSystemId: number): Cup | undefined {
        let currentCup: Cup | undefined = undefined
        if (this.isStadium(solarSystemId)) {
            this.cups.forEach(it => {
                if (currentCup == undefined && it.endAt > Date.now()) {
                    currentCup = it
                }
            })
        }

        return currentCup
    }

    // TODO Refactor
    public getCurrentCupReward(): Reward | undefined {
        switch (this.cups.indexOf(this.getCurrentCup(100)!)) {
            case 0:
                return new Reward('30 000$ to share\nGalactic Trophy Ticket', 500, 'polygon_ticket_trophy')
            case 1:
                return new Reward('Ticket: Galactic\nGrand Prix', 100, 'polygon_ticket_grandprix')
            case 2:
                return new Reward('A Lot of Special Prizes', 3, 'cup_polygon')
            default:
                return undefined
        }
    }

    public getCometVisualId(solarSystemId: number) {
        if (this.isStadium(solarSystemId)) {
            return 1
        } else {
            return 0
        }
    }
}
