import Resolution from '@/helpers/Resolution'
import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import PlayerService from '../services/PlayerService'
import Colors from '@/helpers/Colors'
import Web3 from 'web3'
import BN from 'bn.js'
import { animate } from '@/helpers/AnimationHelper'
import { addTooltip } from '@/helpers/TooltipHelper'
import MainBitmapText from '../pixi-scale/MainBitmapText'

export default class MainBar implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    private spiceCount!: PIXI.BitmapText
    private spiceContainer: PIXI.Container = new PIXI.Container()
    private pullPriceContainer: PIXI.Container = new PIXI.Container()
    private pullPriceValue!: PIXI.BitmapText
    private pullPriceTitle!: PIXI.BitmapText
    private spiceTitle!: PIXI.BitmapText

    private mainBarCenter: PIXI.Sprite = PIXI.Sprite.from('main_bar_center')
    private mainBarLeft: PIXI.Sprite = PIXI.Sprite.from('main_bar_side')
    private mainBarRight: PIXI.Sprite = PIXI.Sprite.from('main_bar_side')
    private mainBarLeftExt: PIXI.Sprite = PIXI.Sprite.from('main_bar_side_extand')
    private mainBarRightExt: PIXI.Sprite = PIXI.Sprite.from('main_bar_side_extand')
    private mainBarBtnLeftExts: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private mainBarBtnRightExts: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private mainBarBtnLeft: PIXI.Sprite = PIXI.Sprite.from('main_bar_btn_bg')
    private mainBarBtnRight: PIXI.Sprite = PIXI.Sprite.from('main_bar_btn_bg')

    private gotFirstBalance = false
    private hasLostMust = false
    private canAddParticle = false

    public onMenuClick = () => {
        return
    }
    public onPullPriceClick = () => {
        return
    }
    public onSpiceClick = () => {
        return
    }

    constructor() {
        this.container.scale.set(Resolution.scale)

        const formattedSpices = Web3.utils.fromWei(PlayerService.getInstance().spices, 'ether')
        this.spiceCount = new MainBitmapText(formattedSpices, {
            fontSize: 5
        })

        this.mainBarCenter.anchor.set(0.5, 0)
        this.mainBarLeft.anchor.set(1, 0)
        this.mainBarLeftExt.anchor.set(1, 0)
        this.mainBarRight.scale.x *= -1
        this.mainBarRight.anchor.set(1, 0)
        this.mainBarBtnLeft.anchor.set(1, 0)
        this.mainBarBtnRight.scale.x *= -1
        this.mainBarBtnRight.anchor.set(1, 0)
        this.mainBarLeft.position.y = 12
        this.mainBarRight.position.y = 12
        this.mainBarLeftExt.position.y = 12
        this.mainBarRightExt.position.y = 12
        this.container.addChild(this.mainBarCenter)
        this.container.addChild(this.mainBarLeft)
        this.container.addChild(this.mainBarLeftExt)
        this.container.addChild(this.mainBarRight)
        this.container.addChild(this.mainBarRightExt)
        this.container.addChild(this.spiceContainer)
        this.container.addChild(this.pullPriceContainer)

        this.mainBarBtnLeftExts.height = 20
        this.mainBarBtnLeftExts.anchor.set(1, 0)
        this.mainBarBtnRightExts.height = 20

        this.spiceTitle = new MainBitmapText('MUST', {
            fontSize: 5
        })

        this.pullPriceValue = new MainBitmapText(PlayerService.getInstance().miner.pullPriceInSpice(), {
            fontSize: 5
        })

        this.pullPriceTitle = new MainBitmapText('Pull $', {
            fontSize: 5
        })

        this.mainBarBtnLeft.tint = Colors.Blue500
        this.mainBarBtnRight.tint = Colors.Blue500
        this.mainBarBtnLeftExts.tint = Colors.Blue500
        this.mainBarBtnRightExts.tint = Colors.Blue500

        this.pullPriceContainer.interactive = true
        this.pullPriceContainer.cursor = 'pointer'
        this.pullPriceContainer.on('pointertap', () => {
            this.onPullPriceClick()
        })
        this.pullPriceContainer.on('mouseover', () => {
            this.mainBarBtnLeft.tint = Colors.Blue600
            this.mainBarBtnLeftExts.tint = Colors.Blue600
        })
        this.pullPriceContainer.on('mouseout', () => {
            this.mainBarBtnLeft.tint = Colors.Blue500
            this.mainBarBtnLeftExts.tint = Colors.Blue500
        })

        this.spiceContainer.interactive = true
        this.spiceContainer.cursor = 'pointer'
        this.spiceContainer.on('pointertap', () => {
            this.onSpiceClick()
        })
        this.spiceContainer.on('mouseover', () => {
            this.mainBarBtnRight.tint = Colors.Blue600
            this.mainBarBtnRightExts.tint = Colors.Blue600
        })
        this.spiceContainer.on('mouseout', () => {
            this.mainBarBtnRight.tint = Colors.Blue500
            this.mainBarBtnRightExts.tint = Colors.Blue500
        })

        document.addEventListener('pullPriceUpdated', () => {
            this.pullPriceValue.text = PlayerService.getInstance().miner.pullPriceInSpice()
            this.onResize()
        })

        /*  PlayerService.getInstance().setOnSpiceChange((spice: BN, change: BN) => {
            this.spiceCount.text = Web3.utils.fromWei(spice, 'ether')
            this.onResize()

            if (!this.gotFirstBalance) {
                this.gotFirstBalance = true
            } else {
                this.handleBalanceChange(change)
            }
        }) */

        this.spiceContainer.y = 15

        this.spiceTitle.x = 5
        this.spiceTitle.y = 3

        this.spiceCount.x = 5
        this.spiceCount.y = 11

        this.pullPriceContainer.x = 25
        this.pullPriceContainer.y = 15

        this.spiceContainer.addChild(this.mainBarBtnRightExts)
        this.spiceContainer.addChild(this.mainBarBtnRight)
        this.spiceContainer.addChild(this.spiceTitle)
        this.spiceContainer.addChild(this.spiceCount)
        this.pullPriceContainer.addChild(this.mainBarBtnLeftExts)
        this.pullPriceContainer.addChild(this.mainBarBtnLeft)
        this.pullPriceContainer.addChild(this.pullPriceTitle)
        this.pullPriceContainer.addChild(this.pullPriceValue)
        this.pullPriceTitle.anchor = new PIXI.ObservablePoint(
            () => {
                return
            },
            1,
            0
        )
        this.pullPriceValue.anchor = new PIXI.ObservablePoint(
            () => {
                return
            },
            1,
            0
        )
        this.onResize()
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        this.container.scale.set(Resolution.scale)

        this.mainBarCenter.position.x = Math.floor(this.fullWidth / 2 + 11)

        this.mainBarLeftExt.position.x = this.mainBarCenter.position.x - 11
        this.mainBarLeftExt.width = this.extsWidth
        this.mainBarLeft.position.x = this.mainBarCenter.position.x - 11 - this.extsWidth
        this.mainBarBtnLeft.position.x = -this.extsWidth
        this.mainBarBtnLeftExts.width = this.extsWidth
        this.mainBarBtnLeftExts.position.x = 0

        this.mainBarRightExt.position.x = this.mainBarCenter.position.x + 11
        this.mainBarRightExt.width = this.extsWidth
        this.mainBarRight.position.x = this.mainBarCenter.position.x + 11 + this.extsWidth
        this.mainBarBtnRight.position.x = this.extsWidth
        this.mainBarBtnRightExts.width = this.extsWidth
        this.mainBarBtnRightExts.position.x = 0

        this.spiceContainer.position.x = this.mainBarRightExt.position.x
        this.pullPriceContainer.position.x = this.mainBarLeftExt.position.x

        this.pullPriceTitle.position.x = -5
        this.pullPriceTitle.position.y = 3

        this.pullPriceValue.position.x = -5
        this.pullPriceValue.position.y = 11

        this.container.position.x = Math.floor(Resolution.realWidth / 2 - (this.fullWidth * Resolution.scale) / 2) - 11 * Resolution.scale
        this.container.position.y = Resolution.realHeight - 35 * Resolution.scale
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    async enterAnimation(callback: () => void): Promise<void> {
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        callback()
    }

    get fullWidth() {
        return this.sideMaxWidth * 2 + 22
    }

    get sideMaxWidth() {
        let maxWidth = this.mainBarBtnLeft.width - 12

        if (this.spiceCount.width > maxWidth) {
            maxWidth = this.spiceCount.width
        }
        if (this.pullPriceValue.width > maxWidth) {
            maxWidth = this.pullPriceValue.width
        }

        return maxWidth + 12
    }

    private async handleBalanceChange(change: BN) {
        const changeEth = Web3.utils.fromWei(change.abs(), 'ether')

        const newMoneyContainer = new PIXI.Container()

        const icon = PIXI.Sprite.from('ic_spice_up')
        const bg = PIXI.Sprite.from('pixel')
        bg.width = 100
        bg.height = 16
        bg.x = 2
        const t: PIXI.Sprite = PIXI.Sprite.from('pixel')
        const b: PIXI.Sprite = PIXI.Sprite.from('pixel')
        t.x = bg.x
        b.x = bg.x
        b.y = icon.height - 1

        const text = new MainBitmapText('text', {
            fontSize: 5
        })
        if (change.isNeg()) {
            bg.tint = Colors.Green300
            t.tint = Colors.Green500
            b.tint = Colors.Green500
            text.text = `+ ${changeEth} MUST`
            icon.texture = PIXI.Texture.from('ic_spice_up')
        } else {
            bg.tint = Colors.Red300
            t.tint = Colors.Red500
            b.tint = Colors.Red500
            text.text = `- ${changeEth} MUST`
            icon.texture = PIXI.Texture.from('ic_spice_down')
        }

        newMoneyContainer.addChild(bg)
        newMoneyContainer.addChild(t)
        newMoneyContainer.addChild(b)
        newMoneyContainer.addChild(icon)
        newMoneyContainer.addChild(text)

        const width = icon.width + 6 * 2 + text.width
        bg.width = width - 2
        t.width = bg.width
        b.width = bg.width
        text.x = icon.width + 6
        text.y = 5

        this.hasLostMust = change.isNeg()
        this.canAddParticle = true

        newMoneyContainer.y = 0
        newMoneyContainer.x = this.fullWidth / 2 + 22
        newMoneyContainer.alpha = 0
        this.container.addChild(newMoneyContainer)
        await animate('easeOutQuad', 3000, perc => {
            newMoneyContainer.y = -20 * perc
            newMoneyContainer.alpha = Math.min(Math.sin(perc * Math.PI) * 2, 1)
        })
        this.container.removeChild(newMoneyContainer)
    }

    get extsWidth() {
        const size = this.sideMaxWidth - this.mainBarBtnLeft.width
        return size > 1 ? size : 1
    }
}
