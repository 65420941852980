import Item from '@/game/models/Items'
import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import PlayerService from '@/game/services/PlayerService'
import CoolingBoosterManagerService from '@/game/services/web3/CoolingBoosterManagerService'
import GameItemsService from '@/game/services/web3/GameItemsService'
import Colors from '@/helpers/Colors'
import ButtonComponent from '../ButtonComponent'
import ModalComponent from './ModalComponent'

export default class UseItemModal extends ModalComponent {
    public static OPEN_USE_ITEM_EVENT = 'openUseItemEvent'

    private ship = new MainBitmapText('', {
        tint: Colors.Blue800,
        fontSize: 5
    })

    private isApproved = false
    private okBtn = new ButtonComponent('Confirm', Colors.Blue800, Colors.Blue600, Colors.Blue500)

    constructor(item: Item) {
        super(105, 80)
        this.position = ModalComponent.POSITION_CENTER

        const titleText = new MainBitmapText('Consume Item', {
            fontSize: 8
        })
        titleText.x = Math.floor(this.modalWidth / 2 - titleText.width / 2)
        titleText.y = 6

        this.ship.x = (this.modalWidth - this.ship.width) / 2
        this.ship.y = titleText.y + titleText.height + 1
        this.setShipName()

        const title2 = new MainBitmapText('Do you confirm\nthe usage of the item:', {
            fontSize: 5,
            align: 'center'
        })
        title2.y = this.ship.y + this.ship.height + 4
        title2.x = Math.floor(this.modalWidth / 2 - title2.width / 2)

        const itemIcon = PIXI.Sprite.from(item.icon)
        itemIcon.x = 10
        itemIcon.y = title2.y + title2.height + 6

        const borderTop = PIXI.Sprite.from('pixel')
        borderTop.y = itemIcon.y + 3
        borderTop.x = itemIcon.x + itemIcon.width
        borderTop.width = this.modalWidth - borderTop.x - 11
        borderTop.tint = 0x49bfff

        const borderBottom = PIXI.Sprite.from('pixel')
        borderBottom.y = itemIcon.y + 12
        borderBottom.x = itemIcon.x + itemIcon.width
        borderBottom.width = this.modalWidth - borderTop.x - 11
        borderBottom.tint = 0x49bfff

        const borderRight = PIXI.Sprite.from('pixel')
        borderRight.y = itemIcon.y + 4
        borderRight.x = this.modalWidth - 11
        borderRight.height = 8
        borderRight.tint = 0x49bfff

        const itemName = new MainBitmapText(item.name, {
            fontSize: 5
        })
        itemName.x = borderTop.x + 4
        itemName.y = borderTop.y + 2

        const cancelBtn = new ButtonComponent('Cancel')
        cancelBtn.x = 10
        cancelBtn.position.y = this.modalHeight - cancelBtn.height - 4
        cancelBtn.interactive = true
        cancelBtn.cursor = 'pointer'
        cancelBtn.on('pointertap', async () => {
            this.onExit()
        })

        this.okBtn.x = this.modalWidth - this.okBtn.width - 10
        this.okBtn.position.y = this.modalHeight - this.okBtn.height - 4
        this.okBtn.interactive = true
        this.okBtn.cursor = 'pointer'
        this.okBtn.on('pointertap', async () => {
            if (this.isApproved) {
                await CoolingBoosterManagerService.getInstance().useCoolingBoost(
                    PlayerService.getInstance().miner.id,
                    PlayerService.getInstance().miner.solarSystemID
                )

                this.onExit()
            } else {
                await GameItemsService.getInstance().setApprovedForGameItemsManager()
            }
        })

        this.modalContainer.addChild(titleText)
        this.modalContainer.addChild(title2)
        this.modalContainer.addChild(this.ship)
        this.modalContainer.addChild(itemIcon)
        this.modalContainer.addChild(borderTop)
        this.modalContainer.addChild(borderBottom)
        this.modalContainer.addChild(borderRight)
        this.modalContainer.addChild(itemName)
        this.modalContainer.addChild(cancelBtn)
        this.modalContainer.addChild(this.okBtn)
        this.setPositions()

        this.loader.visible = false
        this.dataContainer.visible = true
        document.addEventListener(GameItemsService.APPROVED_OPERATOR, () => {
            this.verifyApproval()
        })
        this.verifyApproval()
    }

    async setShipName() {
        this.ship.text = await PlayerService.getInstance().miner.name()
        this.ship.x = (this.modalWidth - this.ship.width) / 2
    }

    updateActionButton() {
        if (this.isApproved) {
            this.okBtn.updateText('Confirm')
        } else {
            this.okBtn.updateText('Approve')
        }
    }

    async verifyApproval() {
        this.isApproved = await GameItemsService.getInstance().isApprovedForGameItemsManager()
        this.updateActionButton()
    }
}
