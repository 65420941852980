import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import Rent from '@/game/models/Rent'
import RentedMiner from '@/game/models/RentedMiner'
import StakedSpaceshipsService from './StakedSpaceshipsService'

export default class RentingService {
    public static instance: RentingService

    public static getInstance(): RentingService {
        if (!this.instance) {
            this.instance = new RentingService()
        }
        return this.instance
    }

    private factoryAbi = [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'offerId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'lender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'tenant',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'rentingContract',
                    type: 'address'
                }
            ],
            name: 'OfferAccepted',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'offerId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'lender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256[]',
                    name: 'nftIds',
                    type: 'uint256[]'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'percentageForLender',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'fee',
                    type: 'uint256'
                }
            ],
            name: 'OfferNew',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'offerId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'lender',
                    type: 'address'
                }
            ],
            name: 'OfferRemoved',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'rentingContract',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'lender',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'tenant',
                    type: 'address'
                }
            ],
            name: 'RentingContractClosed',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'onERC721Received',
            outputs: [
                {
                    internalType: 'bytes4',
                    name: '',
                    type: 'bytes4'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256[]',
                    name: 'nftIds',
                    type: 'uint256[]'
                },
                {
                    internalType: 'uint256',
                    name: 'duration',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'percentageForLender',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'fee',
                    type: 'uint256'
                }
            ],
            name: 'makeOffer',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'offerId',
                    type: 'uint256'
                }
            ],
            name: 'removeOffer',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'offerId',
                    type: 'uint256'
                }
            ],
            name: 'acceptOffer',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'closeRenting',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'newFee',
                    type: 'uint256'
                }
            ],
            name: 'updateLeaveFee',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'offerAmount',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                }
            ],
            name: 'offer',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256[]',
                            name: 'nftIds',
                            type: 'uint256[]'
                        },
                        {
                            internalType: 'address',
                            name: 'lender',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'duration',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'percentageForLender',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'fee',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct IRentingContractFactory.Offer',
                    name: '',
                    type: 'tuple'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'start',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'offersPaginated',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256[]',
                            name: 'nftIds',
                            type: 'uint256[]'
                        },
                        {
                            internalType: 'address',
                            name: 'lender',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'duration',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'percentageForLender',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'fee',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct IRentingContractFactory.Offer[]',
                    name: '',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'lender',
                    type: 'address'
                }
            ],
            name: 'offersOf',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256[]',
                            name: 'nftIds',
                            type: 'uint256[]'
                        },
                        {
                            internalType: 'address',
                            name: 'lender',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'duration',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'percentageForLender',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'fee',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct IRentingContractFactory.Offer[]',
                    name: '',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'rentingAmount',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'id',
                    type: 'address'
                }
            ],
            name: 'renting',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'id',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256[]',
                            name: 'nftIds',
                            type: 'uint256[]'
                        },
                        {
                            internalType: 'address',
                            name: 'lender',
                            type: 'address'
                        },
                        {
                            internalType: 'address',
                            name: 'tenant',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'start',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'end',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'percentageForLender',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct IRentingContractFactory.Renting',
                    name: '',
                    type: 'tuple'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'start',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'rentingsPaginated',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'id',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256[]',
                            name: 'nftIds',
                            type: 'uint256[]'
                        },
                        {
                            internalType: 'address',
                            name: 'lender',
                            type: 'address'
                        },
                        {
                            internalType: 'address',
                            name: 'tenant',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'start',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'end',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'percentageForLender',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct IRentingContractFactory.Renting[]',
                    name: '',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'lender',
                    type: 'address'
                }
            ],
            name: 'rentingsGrantedOf',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'id',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256[]',
                            name: 'nftIds',
                            type: 'uint256[]'
                        },
                        {
                            internalType: 'address',
                            name: 'lender',
                            type: 'address'
                        },
                        {
                            internalType: 'address',
                            name: 'tenant',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'start',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'end',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'percentageForLender',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct IRentingContractFactory.Renting[]',
                    name: '',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'tenant',
                    type: 'address'
                }
            ],
            name: 'rentingsReceivedOf',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'id',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256[]',
                            name: 'nftIds',
                            type: 'uint256[]'
                        },
                        {
                            internalType: 'address',
                            name: 'lender',
                            type: 'address'
                        },
                        {
                            internalType: 'address',
                            name: 'tenant',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'start',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'end',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'percentageForLender',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct IRentingContractFactory.Renting[]',
                    name: '',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        }
    ]

    private rentingContractAbi = [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'onERC721Received',
            outputs: [
                {
                    internalType: 'bytes4',
                    name: '',
                    type: 'bytes4'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'gameId',
                    type: 'uint256'
                }
            ],
            name: 'stake',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                }
            ],
            name: 'claim',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address[]',
                    name: 'tokens',
                    type: 'address[]'
                }
            ],
            name: 'claimBatch',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address[]',
                    name: 'tokens',
                    type: 'address[]'
                }
            ],
            name: 'claimBatchAndClose',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'close',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'nftIds',
            outputs: [
                {
                    internalType: 'uint256[]',
                    name: '',
                    type: 'uint256[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'lender',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'tenant',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'start',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'end',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'percentageForLender',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address[]',
                    name: 'tokens',
                    type: 'address[]'
                }
            ],
            name: 'alreadyClaimed',
            outputs: [
                {
                    internalType: 'uint256[]',
                    name: '',
                    type: 'uint256[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        }
    ]

    private readFactory: any

    private test = false

    constructor() {
        this.initContract()
    }

    public initContract() {
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readFactory = new wssWeb3.eth.Contract(this.factoryAbi, config.rentingFactoryAddress)

        if (config.rentingFactoryAddress == '0x0000000000000000000000000000000000000000') this.test = true
    }

    public async rentingsOfConnected(): Promise<Rent[]> {
        const account = await Web3Service.getInstance().getAccount()
        return await this.rentingsOf(account)
    }

    public async rentingsOf(account: string): Promise<Rent[]> {
        if (this.test) return []
        return await this.readFactory.methods.rentingsReceivedOf(account).call()
    }

    public async rentingsGrantedOf(account: string): Promise<Rent[]> {
        if (this.test) return []
        return await this.readFactory.methods.rentingsGrantedOf(account).call()
    }

    public async borrowedShips() {
        if (this.test) return []
        const nftIdsList = Array<string>()

        const lendContracts = await this.rentingsOfConnected()
        for (let i = 0; i < lendContracts.length; i++) {
            nftIdsList.push(...lendContracts[i].nftIds)
        }

        return nftIdsList
    }

    public async borrowedShipsInGame(): Promise<RentedMiner[]> {
        if (this.test) return []
        let rentedShip = Array<RentedMiner>()

        const lendContracts = await this.rentingsOfConnected()
        for (let i = 0; i < lendContracts.length; i++) {
            const nftIds = await StakedSpaceshipsService.getInstance().tokensOf(lendContracts[i].id)

            const contractShips = nftIds.map((minerId: string) => {
                const rentedShip = new RentedMiner()
                rentedShip.minerId = minerId
                rentedShip.contractAddress = lendContracts[i].id
                return rentedShip
            })
            rentedShip = rentedShip.concat(contractShips)
        }

        return rentedShip
    }

    public async stake(lendContractAddress: string, tokenId: number, gameId: number) {
        const web3 = Web3Service.getInstance().getWeb3()
        const account = await Web3Service.getInstance().getAccount()

        const lendContract = new web3.eth.Contract(this.rentingContractAbi, lendContractAddress)
        await lendContract.methods.stake(tokenId, gameId).send({ from: account, gasPrice: '50000000000' })
    }
}
