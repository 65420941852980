import Resolution from '@/helpers/Resolution'
import GameElement from '../../GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import Colors from '@/helpers/Colors'
import { Notification, NotificationType } from '../../models/notification/Notification'
import MainBitmapText from '../../pixi-scale/MainBitmapText'

export default class NotificationComponent implements GameElement {
    public static NotificationDestroyTimer = 30000

    public container: PIXI.Container = new PIXI.Container()
    protected dataContainer: PIXI.Container = new PIXI.Container()

    protected bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    protected borders: PIXI.Graphics = new PIXI.Graphics()
    protected closer: PIXI.Sprite = PIXI.Sprite.from('notif_close')
    protected title: PIXI.BitmapText

    public onClick = () => {
        return
    }
    public onDestroy = () => {
        return
    }
    public onHide = () => {
        return
    }

    protected mainTint = Colors.Blue800
    protected secondTint = Colors.Blue400
    protected width = 84
    public height = 22

    constructor(public notification: Notification) {
        this.bg.interactive = true
        this.bg.cursor = 'pointer'
        this.bg.on('pointertap', () => {
            this.onClick()
        })
        this.container.addChild(this.dataContainer)
        this.bg.tint = Colors.Blue400

        this.title = new MainBitmapText(notification.title, { fontSize: 4 })
        this.title.y = 5
        this.title.x = 6

        this.closer.position.y = -4
        this.closer.position.x = this.width - this.closer.width + 4
        this.closer.interactive = true
        this.closer.cursor = 'pointer'

        this.container.alpha = 0
        this.container.addChildAt(this.bg, 0)
        this.container.addChild(this.borders)
        this.container.addChild(this.closer)
        this.dataContainer.addChild(this.title)

        this.closer.on('pointertap', (event: PIXI.InteractionEvent) => {
            event.stopPropagation()
            this.onDestroy()
        })

        this.onResize()
        this.tint()
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        this.defineHeight()
        this.bg.width = this.width
        this.bg.height = this.height
        this.borders.clear()
        this.borders.lineStyle(1, this.mainTint)
        this.borders.drawRoundedRect(0, 0, this.width, this.height, 1)
    }

    defineHeight() {
        this.height = this.dataContainer.height + 12
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    tint() {
        if (this.notification.type == NotificationType.Success) {
            this.mainTint = Colors.Green500
            this.secondTint = Colors.Green300
        } else if (this.notification.type == NotificationType.Error) {
            this.mainTint = Colors.Red500
            this.secondTint = Colors.Red300
        }
        this.bg.tint = this.secondTint
        this.closer.tint = this.mainTint

        this.onResize()
    }

    async enterAnimation(callback: () => void): Promise<void> {
        this.container.visible = true
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.alpha = perc
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await animate('easeInQuad', 300, (perc: number) => {
            this.container.alpha = 1 - perc
        })
        callback()
        this.onHide()
    }
}
