import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import Colors from '@/helpers/Colors'
import PlayerService from '../services/PlayerService'
import Resolution from '@/helpers/Resolution'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import MapService from '../services/MapService'

export default class MiningStatusComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public mineStatus!: PIXI.BitmapText
    public cooldownIcon: PIXI.Sprite = PIXI.Sprite.from('ic_drill_cooldown')
    public lastCooldown = 1
    public bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    public t: PIXI.Sprite = PIXI.Sprite.from('pixel')
    public b: PIXI.Sprite = PIXI.Sprite.from('pixel')

    private width = 84
    constructor() {
        this.mineStatus = new MainBitmapText('Mining is ready in XXXs', {
            fontSize: 5,
            align: 'center'
        })
        this.mineStatus.x = this.cooldownIcon.width + 9
        this.mineStatus.y = 2

        this.bg.x = 3
        this.t.x = this.bg.x
        this.b.x = this.bg.x
        this.b.y = this.cooldownIcon.height - 1
        this.bg.width = this.width - 3
        this.t.width = this.bg.width
        this.b.width = this.bg.width
        this.bg.height = this.cooldownIcon.height
        this.bg.tint = Colors.Blue300

        this.container.addChild(this.bg)
        this.container.addChild(this.t)
        this.container.addChild(this.b)
        this.container.addChild(this.cooldownIcon)
        this.container.addChild(this.mineStatus)
        this.checkMiningStatus()

        PlayerService.getInstance().setOnCurrentPlayerChange(() => {
            this.checkMiningStatus()
        })

        document.addEventListener('serverPostTick', () => {
            this.checkMiningStatus()
        })
        this.onResize()
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        this.container.x = 6
        this.container.y = Math.floor(Resolution.realHeight / Resolution.scale - 70)
        this.mineStatus.x = Math.floor(this.cooldownIcon.width / 2 + this.width / 2 - this.mineStatus.width / 2)
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    async enterAnimation(callback: () => void): Promise<void> {
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        callback()
    }

    private checkMiningStatus() {
        const isCoolIn = PlayerService.getInstance().miner.isCoolIn()
        if (isCoolIn > 0 && this.lastCooldown != isCoolIn) {
            this.container.visible = true
            this.mineStatus.text = 'Mining is ready\nin ' + isCoolIn + 's'
            this.bg.tint = Colors.Red300
            this.t.tint = Colors.Red500
            this.b.tint = Colors.Red500

            this.mineStatus.y = this.cooldownIcon.height / 2 - this.mineStatus.height / 2 - 0.66
            this.lastCooldown = isCoolIn
            this.onResize()
        } else if (MapService.getInstance().map.cometExist(PlayerService.getInstance().miner.rover)) {
            this.container.visible = true
            this.mineStatus.text = 'Rover is on a comet'
            this.bg.tint = Colors.Red300
            this.t.tint = Colors.Red500
            this.b.tint = Colors.Red500
            this.mineStatus.y = this.cooldownIcon.height / 2 - this.mineStatus.height / 2 - 0.66
            this.onResize()
        } else {
            this.container.visible = false
            this.lastCooldown = isCoolIn
        }
    }
}
