import config from '@/config'
import { Notification } from '@/game/models/notification/Notification'
import { Transaction } from '@/game/models/Transaction'
import Web3Service from '@/game/services/Web3Service'
import NotificationService from '../NotificationService'

export default class GameItemsService {
    public static APPROVED_OPERATOR = 'APPROVED_OPERATOR'

    public static instance: GameItemsService

    public static getInstance(): GameItemsService {
        if (!this.instance) {
            this.instance = new GameItemsService()
        }
        return this.instance
    }

    private abi = [
        {
            inputs: [
                {
                    internalType: 'string',
                    name: '_name',
                    type: 'string'
                },
                {
                    internalType: 'string',
                    name: '_symbol',
                    type: 'string'
                },
                {
                    internalType: 'string',
                    name: '_uri',
                    type: 'string'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: '_owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: '_operator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'bool',
                    name: '_approved',
                    type: 'bool'
                }
            ],
            name: 'ApprovalForAll',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: '_operator',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: '_from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: '_to',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256[]',
                    name: '_ids',
                    type: 'uint256[]'
                },
                {
                    indexed: false,
                    internalType: 'uint256[]',
                    name: '_amounts',
                    type: 'uint256[]'
                }
            ],
            name: 'TransferBatch',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: '_operator',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: '_from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: '_to',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: '_id',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: '_amount',
                    type: 'uint256'
                }
            ],
            name: 'TransferSingle',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'string',
                    name: '_uri',
                    type: 'string'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: '_id',
                    type: 'uint256'
                }
            ],
            name: 'URI',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_owner',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: '_id',
                    type: 'uint256'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address[]',
                    name: '_owners',
                    type: 'address[]'
                },
                {
                    internalType: 'uint256[]',
                    name: '_ids',
                    type: 'uint256[]'
                }
            ],
            name: 'balanceOfBatch',
            outputs: [
                {
                    internalType: 'uint256[]',
                    name: '',
                    type: 'uint256[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_to',
                    type: 'address'
                },
                {
                    internalType: 'uint256[]',
                    name: '_ids',
                    type: 'uint256[]'
                },
                {
                    internalType: 'uint256[]',
                    name: '_quantities',
                    type: 'uint256[]'
                },
                {
                    internalType: 'bytes',
                    name: '_data',
                    type: 'bytes'
                }
            ],
            name: 'batchMint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_initialOwner',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: '_initialSupply',
                    type: 'uint256'
                },
                {
                    internalType: 'string',
                    name: '_uri',
                    type: 'string'
                },
                {
                    internalType: 'bytes',
                    name: '_data',
                    type: 'bytes'
                }
            ],
            name: 'create',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'creators',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '_operator',
                    type: 'address'
                }
            ],
            name: 'isApprovedForAll',
            outputs: [
                {
                    internalType: 'bool',
                    name: 'isOperator',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: '_id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_quantity',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: '_data',
                    type: 'bytes'
                }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '_to',
                    type: 'address'
                },
                {
                    internalType: 'uint256[]',
                    name: '_ids',
                    type: 'uint256[]'
                },
                {
                    internalType: 'uint256[]',
                    name: '_amounts',
                    type: 'uint256[]'
                },
                {
                    internalType: 'bytes',
                    name: '_data',
                    type: 'bytes'
                }
            ],
            name: 'safeBatchTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '_to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: '_id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_amount',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: '_data',
                    type: 'bytes'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_operator',
                    type: 'address'
                },
                {
                    internalType: 'bool',
                    name: '_approved',
                    type: 'bool'
                }
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'string',
                    name: '_newBaseMetadataURI',
                    type: 'string'
                }
            ],
            name: 'setBaseMetadataURI',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_to',
                    type: 'address'
                },
                {
                    internalType: 'uint256[]',
                    name: '_ids',
                    type: 'uint256[]'
                }
            ],
            name: 'setCreator',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes4',
                    name: '_interfaceID',
                    type: 'bytes4'
                }
            ],
            name: 'supportsInterface',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'tokenSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_id',
                    type: 'uint256'
                }
            ],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_id',
                    type: 'uint256'
                }
            ],
            name: 'uri',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        }
    ]

    private readContract: any
    private writeContract: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        this.writeContract = new web3.eth.Contract(this.abi, config.gameItemsAddress)

        const wsWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readContract = new web3.eth.Contract(this.abi, config.gameItemsAddress)
    }

    public async balanceOf(tokenId: number) {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.readContract.methods.balanceOf(account, tokenId).call()
    }

    public async isApprovedForGameItemsManager() {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.readContract.methods.isApprovedForAll(account, config.gameItemsManagerAddress).call()
    }

    public async setApprovedForGameItemsManager() {
        const account: string = await Web3Service.getInstance().getAccount()

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.writeContract.methods
                .setApprovalForAll(config.gameItemsManagerAddress, true)
                .send({ from: account })
                .on('error', (error: any) => {
                    reject(error)
                    tx.onError()
                    notif.title = 'Set approval failed.'
                })
                .on('receipt', (data: any) => {
                    notif.title = 'Set approval completed!'
                    document.dispatchEvent(new Event(GameItemsService.APPROVED_OPERATOR))
                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif = NotificationService.getInstance().addFromTransaction(tx)
                    notif.title = 'Set approval...'
                    resolve(data)
                })
        })
    }

    public async safeTransferFrom(to: string, tokenId: string, amount: number,  data: any) {
        const web3 = Web3Service.getInstance().getWeb3()
        const account: string = await Web3Service.getInstance().getAccount()

        await this.writeContract.methods.safeTransferFrom(account, to, tokenId, amount, data).send({ from: account, gasPrice: '50000000000' })
    }
}

