export default class StakingRewards {
    public static Classic = [
        '0x1c30Cfe08506BA215c02bc2723C6D310671BAb62',
        '0x2cc6a7A06B32E0796D8f9225E2e33ae51C93d715',
        '0x2328c83431a29613b1780706E0Af3679E3D04afd',
        '0x9512080848be125Bd900cBBa3B0454742D11FA93',
        '0x83bb796fBc69E013726129f768069e456CaDeA2b',
        '0x8e0dc90E432c3F234d24aa2CB47dD31cF2Ad8429',
        '0x90261B5B1Bf708EA264A60556e31d6b4eA481E48',
        '0x31a8fCe4d069191900E91B4Ba4bF3F3E7d1c7338',
        '0x03A2C998A05B544B236fd51A44EFe6505eb33a9a',
        '0x642A056F790af3eDa4F5639aDc54083285186D90',
        '0xFAd897402C0A70440e79A9Da6aAfb8a19D94D5eA',
        '0x26FFeB3452a8D5B507cB6dcc4EcA05d33D926AF7',
        '0x461CB0b067eBD1A402f0ed32caE1b89d42796E94',
        '0x6c99E24FcC6C6a3511057B5655369245A2298106',
        '0x52f68A09AEe9503367bc0cda0748C4D81807Ae9a',
        '0xd6161AdBf80602f0ecf74F92ce0271556ddbf98B',
        '0x363d359B3B165E9cBF85088cB6C4EfF4d4338B73',
        '0x3268BD010Ec504e6CFfB349009Ec1AFCA868d292',
        '0x67659dF49823f026E98afB2e9703C4D616EDB168',
        '0xfb23843D3561a3bC73BB14855286Bd4b0b3E15C6',
        '0xd3753dD9686549Ca458C5AFA7079C7Cf834396FE',
        '0xf5d2E95D90bf8959cdEF08628bafA0D7d0bfc8F7',
        '0x1C678EA856B368CC361A3389734fe451fEC8CEea',
        '0x449A45A2Db94Fb4cD14f7Af4FD2322649492225A',
        '0xc363539ce034E61Ec6D4d913313AabC628E9A848',
        '0xd0C5cCB10AA58DaADb1E49773DEF3Be399d6210D',
        '0x7D0ef2867Eb5CCfd3D38A66BAa6e372D43c6e8B9',
        '0x992C4Ba9C63730e3B4B3B45Cb90a837D1649fcB6',
        '0x06FE1A75D2C8dAb828a61c5D87bb6f5bb3D41Ec0',
        '0x4E61977fD700E8C8230006488b6Ef183B5a26f39',
        '0x9F8e609E5C1a73938739F63E2eB231a185df31b3',
        '0x9C9e24C8fe20ec9E3518524818e06504370cd400',
        '0x296A8b9F19d8b6282Bbc474847D8a005B4cA09e1',
        '0xb78c2D51278d9E5C7f26B9B0a5DAE45eCbcBce0F',
        '0x88030CC48CaC2a7Fa3e88De3ad7826AC29903114',
        '0xbbdeC9fe85342f45ab42f817931a261BFE0c9199',
        '0x5dE870843eA74F3f6A9A5253323788C2Ab94D9C5',
        '0xa03aE369f89993fFC5E5d5e338387b62EaD557a6',
        '0x87c23d32469FA9ddef7797Bf1d4018cf61C8b3e8',
        '0x3F59e87d1414B8e9a7683FAD9bD4d66b441F5247',
        '0xdA39652B724b149324D47A2bAe3aD3F323eD4685',
        '0x868Fc3c5e007a49308bf86168E3fd9D5DBC8ca30',
        '0x05cd83A952DF784Fc7856241822d2f109678Df72',
        '0x5A25c4f43d0bfcCc07Aa86f7e8a1a1A3bFd9b15d',
        '0x80190aF6c79f654cC5c05038b14c4BE1685f4544',
        '0x19aA3B72aC5423D8e3675372b4C5B89785Ed0896',
        '0x3Bda027733d5f1F2664583ab5FB3B9097eB38F12',
        '0x6633195293eCE07274Ff8641E732c50625DD67Ee',
        '0x9477D5496ee5f758b26FFAb540a0F5cc4C48D157',
        '0xf624aFd0F01417A53c99C5E9bD7E7462DFc0a22d',
        '0x303cAb00442D517D3a2b5d39649E931E21011790',
        '0x9FC212f2c2e4A8C9946aCA414aB96d7f02eB3094',
        '0x604E15beDe79e02458b5eF63891c1A70E42D12a2',
        '0xC6043B364BCB4A7df78b6d244C763dE8380c8AA8',
        '0x030ABe63Fcc1c0a86769553222B3cC648D44980e',
        '0xBfa28f54682678cFc4B71C0a9430fb3EEc5b90Bf',
        '0x38ce9B00cfFab9Ff6070a454A7603D77B88675bF',
        '0x6f4f5672F08E8706D0ce5F8f78F1B1bbe229b5C5',
        '0x05D7E5D668265bcaB7fEfd48aC5af0B758Fc90F5',
    ]
    public static Multiple = [
        '0x81AbDd138c50D577cda3116b763d2e3f82CE8eA2',
        '0xD6644eA185Ed9CE3Df1D91B54471D27B36a65c30',
        '0xc0a1dFb85734E465C5dadc5683DE58358C906598',
        '0x69Cb6f98E45c13A230d292bE0a6aF93a6521c39B',
        '0x93ca5835c6A36Cf58C653cD6dc20EC381DCb89Bd',
        '0x9668925653495E72Abe5EB3B5d1eDD04758e58C8',
        '0xFdA044F14B6590c211a50854F36C2eB5f9a9c0a0',
        '0xFD37a2266605830A1589E778E33A9A24394d2E93',
        '0x6779689d29C407Ab97545Bf2Cb8cbcB93370e5fe',
        '0xd65bfB8b20B08724F2772f95296dCd4ebEBACfcb',
        '0x1cFc3E212C7B9207C8388ed08B88570d1809E15a',
        '0x8Cf8C7Db0Ab27985973a0E01A8a5CE2CFfd468ed',
        '0x7C991dF9CDaFB1F62BefD5D6502fF81a7D85227b',
        '0x2F434bC10D44Db5161934FAaa58A462C8F1cBE52',
        '0xa964B2ef4C0326e5c0701b513d43d7f7f254E4C1',
        '0x066E267ff61F23d733538695da066ADA72a6fac3',
        '0x72c287b1c0252d2cfBA6a58f672CbC73979d9FA1',
        '0x9eCc7384cAd836F7cC99b3d231AEB1e5A3FA4F06',
        '0x3a68F45a3Bc648365a80AbafA2528cd3277eEB96',
        '0xAB4292c600ddF1845D41c00b374DbBA5062e0810',
        '0xcA9c316760AAAF865163b0DC5066cdb89A9562Ac',
        '0x0d0Bc4C6fF3aB091d7AFd6638943690602BC7743',
        '0x72f4aA45949013DE7a0Dec85A20d07c865D92FA9',
    ]
}
