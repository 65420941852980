import ComethSwapTokenService from '@/services/ComethSwapTokenService'
import LiquidityToken from '@/models/LiquidityToken'

export default class StakedLiquidityToken {
    liquidityToken: LiquidityToken;
    stakingReward: string;
    apy: string;

    constructor(stakingReward: string, liquidityToken: LiquidityToken) {
        this.stakingReward = stakingReward
        this.liquidityToken = liquidityToken
        this.apy = '0'
    }

}
