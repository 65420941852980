import BN from 'bn.js'
import GameMap from '../models/GameMap'
import Miner from '../models/Miner'
import Comete from '../models/Comete'
import StakingComet from '../models/StakingComet'

import MinerManagerService from '@/game/services/web3/MinerManagerService'
import StakedSpaceshipsService from '@/game/services/web3/StakedSpaceshipsService'
import CometManagerService from '@/game/services/web3/CometManagerService'
import { quantile } from '@/helpers/MathHelper'

import PlayerService from './PlayerService'
import { MiningNotification } from '../models/notification/MiningNotification'
import NotificationService from './NotificationService'
import { Notification, NotificationType } from '../models/notification/Notification'
import NoMiningZone from '../models/NoMiningZone'
import Web3 from 'web3'
import { Transaction, TransactionStatus } from '../models/Transaction'
import { TransactionNotification } from '../models/notification/TransactionNotification'
import JumpManagerService from './web3/JumpManagerService'
import MiningManagerService from './web3/MiningManagerService'
import SolarSystemService from './web3/SolarSystemService'
import PortalService from './web3/PortalService'
import { Star } from '../models/Star'
import RentingService from './web3/RentingService'

import { PortalTravelNotification } from '../models/notification/PortalTravelNotification'

import { Portal } from '../models/Portal'
import Events from '../models/Events'
import Orbit from '../models/position/Orbit'
import GMap from '../GMap'

import RoverManagerService from './web3/RoverManagerService'

import CoolingBoosterManagerService from './web3/CoolingBoosterManagerService'
import GalaxyService from '@/services/GalaxyService'
import RankingSessionService from './RankingSessionService'

export default class MapService {
    public static instance: MapService
    public static MINER_ADDED_ON_MAP_EVENT = 'MINER_ADDED_ON_MAP_EVENT'
    public static MINER_REMOVED_FROM_MAP_EVENT = 'MINER_REMOVED_FROM_MAP_EVENT'
    public static COMET_ADDED_ON_MAP_EVENT = 'COMET_ADDED_ON_MAP_EVENT'
    public static STAKING_COMET_ADDED_ON_MAP_EVENT = 'STAKING_COMET_ADDED_ON_MAP_EVENT'
    public static CURRENT_SOLAR_SYSTEM_CHANGING = 'CURRENT_SOLAR_SYSTEM_CHANGING'
    public static CURRENT_SOLAR_SYSTEM_CHANGED = 'CURRENT_SOLAR_SYSTEM_CHANGED'
    public static SOLAR_SYSTEM_MINER_REMOVED = 'SOLAR_SYSTEM_MINER_REMOVED'
    public static SOLAR_SYSTEM_MINER_ADDED = 'SOLAR_SYSTEM_MINER_ADDED'

    public muleDefaultSystem = 5

    public quantiles: number[] = []

    private playerRovers = new Map<string, Miner[]>()

    public static getInstance(): MapService {
        if (!this.instance) {
            this.instance = new MapService()
        }

        return this.instance
    }

    public map = new GameMap()
    public noMiningZone: NoMiningZone = new NoMiningZone()
    public rovers: string[] = []

    private starsBlackList: number[] = []
    private updateMinerStatus = new Map<string, boolean>()

    constructor() {
        document.addEventListener(PortalService.TRAVELED_EVENT, event => {
            const minerId = (event as CustomEvent).detail.minerId
            const from = (event as CustomEvent).detail.fromSystemID
            const to = (event as CustomEvent).detail.toSystemID

            this.minerTravelled(minerId, from, to)
        })

        document.addEventListener(JumpManagerService.JUMPED_EVENT, event => {
            const minerId = (event as CustomEvent).detail.minerId
            const solarSystemID = (event as CustomEvent).detail.solarSystemID
            this.updateMiner(minerId, solarSystemID)
            const playerMiner = PlayerService.getInstance().miningPlayers.find(it => it.id == minerId)
            if (playerMiner) {
                playerMiner.isNearComete = false
            }
        })

        document.addEventListener(MiningManagerService.MINED_EVENT, event => {
            const minerId = (event as CustomEvent).detail.minerId
            const solarSystemID = (event as CustomEvent).detail.solarSystemID
            const cometId = (event as CustomEvent).detail.cometId
            this.updateMiner(minerId, solarSystemID)
        })

        document.addEventListener(CoolingBoosterManagerService.COOLING_BOOSTER_EVENT, event => {
            const minerId = (event as CustomEvent).detail.minerId
            const solarSystemID = (event as CustomEvent).detail.solarSystemID
            this.updateMiner(minerId, solarSystemID)
        })

        document.addEventListener(JumpManagerService.PULLPRICE_CHANGED_EVENT, event => {
            const minerId = (event as CustomEvent).detail.minerId
            const newPrice = (event as CustomEvent).detail.price
            const solarSystemID = (event as CustomEvent).detail.solarSystemID
            this.updateMinerPullPrice(minerId, newPrice, solarSystemID)
        })

        document.addEventListener(StakedSpaceshipsService.SHIP_LEAVE_GAME_EVENT, event => {
            const minerId = (event as CustomEvent).detail

            this.map.stars.forEach(s => {
                s.removeMiner(minerId)
            })

            PlayerService.getInstance().miningPlayers = PlayerService.getInstance().miningPlayers.filter(it => it.id != minerId)

            document.dispatchEvent(new Event(MapService.MINER_REMOVED_FROM_MAP_EVENT))
        })

        document.addEventListener(StakedSpaceshipsService.SHIP_ENTER_GAME_EVENT, event => {
            const minerId = (event as CustomEvent).detail
            this.minerEnter(minerId)
        })

        document.addEventListener(CometManagerService.REMOVE_COMET_EVENT, event => {
            const cometId = (event as CustomEvent).detail.cometId

            this.map.stars.forEach(s => {
                s.cometes.forEach((comet, index) => {
                    if (comet.id == cometId) {
                        s.cometes.splice(index, 1)
                        return
                    }
                })
                s.stakingComets.forEach((comet, index) => {
                    if (comet.id == cometId) {
                        s.stakingComets.splice(index, 1)
                        return
                    }
                })
            })
        })

        document.addEventListener(CometManagerService.NEW_COMET_EVENT, event => {
            const cometId = (event as CustomEvent).detail.cometId
            const solarSystemID = (event as CustomEvent).detail.solarSystemID
            this.cometEnter(cometId, solarSystemID)
        })

        document.addEventListener(CometManagerService.NEW_STAKING_COMET_EVENT, event => {
            const cometId = (event as CustomEvent).detail.cometId
            const solarSystemID = (event as CustomEvent).detail.solarSystemID
            this.stakingCometEnter(cometId, solarSystemID)
        })

        document.addEventListener(Events.UpdateStakingComet, event => {
            this.updateStakingComet(event as CustomEvent)
        })

        document.addEventListener(MiningManagerService.UPDATE_COMET, event => {
            const solarSystemID = (event as CustomEvent).detail.solarSystemID
            const cometId = (event as CustomEvent).detail.cometId
            const amount = (event as CustomEvent).detail.amount

            const star = this.map.stars.find(it => it.id == solarSystemID)
            if (!star) return

            const comet = star.cometes.find(it => it.id == cometId)
            if (!comet) return
            comet.balance = comet.balance.sub(new BN(amount))
        })

        document.addEventListener(RoverManagerService.ROVER_DEPOSED_EVENT, event => {
            const minerId = (event as CustomEvent).detail.minerId
            const solarSystemID = (event as CustomEvent).detail.solarSystemID
            const cometId = (event as CustomEvent).detail.cometId

            const miner = PlayerService.getInstance().miningPlayers.find(it => it.id == minerId)
            if (!miner) return
            miner.rover = cometId

            const star = this.map.stars.find(it => it.id == solarSystemID)
            if (!star) return
            star.stakingComets.forEach(comet => {
                if (comet.id == cometId) {
                    comet.playerMiners.push(miner)
                }
            })
        })

        document.addEventListener(RoverManagerService.ROVER_HARVEST_EVENT, event => {
            const minerId = (event as CustomEvent).detail.minerId
            const solarSystemID = (event as CustomEvent).detail.solarSystemID
            const cometId = (event as CustomEvent).detail.cometId

            const miner = PlayerService.getInstance().miningPlayers.find(it => it.id == minerId)
            if (!miner) return
            miner.rover = '0x0000000000000000000000000000000000000000'

            const star = this.map.stars.find(it => it.id == solarSystemID)
            if (!star) return
            star.stakingComets.forEach(comet => {
                if (comet.id == cometId) {
                    const index = comet.playerMiners.findIndex(it => it.id == minerId)
                    comet.playerMiners.splice(index, 1)
                }
            })
        })
    }

    public async minerTravelled(minerId: string, fromId: number, toId: number) {
        const fromStar = this.map.stars.find(it => it.id == fromId)
        const toStar = this.map.stars.find(it => it.id == toId)

        const miner = fromStar?.miners.find(it => it.id == minerId)!
        fromStar?.removeMiner(minerId)
        const newMiner = new Miner(await MinerManagerService.getInstance().getMiner(minerId, toId))
        if (miner && newMiner) {
            miner.orbit = newMiner.orbit
            miner.lastAction = newMiner.lastAction
            miner.solarSystemID = newMiner.solarSystemID
            toStar?.addMiner(miner)
            document.dispatchEvent(
                new CustomEvent(MapService.SOLAR_SYSTEM_MINER_ADDED, {
                    detail: {
                        miner: miner,
                        solarSystemId: toStar?.id
                    }
                })
            )

            if (PlayerService.getInstance().miner.id == miner.id) {
                document.dispatchEvent(
                    new CustomEvent(GMap.SWITCH_TO_SOLAR_SYSTEM_EVENT, {
                        detail: toId
                    })
                )
            }

            document.dispatchEvent(
                new CustomEvent(MapService.SOLAR_SYSTEM_MINER_REMOVED, {
                    detail: {
                        miner: miner,
                        solarSystemId: fromStar?.id
                    }
                })
            )
        }
    }

    public getMinPullPrice() {
        // TODO load data from blockchain
        if (GalaxyService.getInstance().isAcademy(this.map.currentStar.id)) {
            return new BN('1000000000000')
        }

        return new BN('300000000000000')
    }

    public updateMinerPullPrice(minerId: string, newPrice: number, solarSystemID: number) {
        this.map.stars.forEach(s => {
            s.miners.forEach(miner => {
                if (miner.id == minerId) {
                    miner.pullingPrice = new BN(newPrice)
                    return
                }
            })
        })
    }

    public async minerEnter(minerId: string) {
        let solarSystemID = 0
        const modelId = Miner.modelFromId(minerId)

        //TODO load it from blockchain
        if (modelId == Miner.MULE_MODEL_ID) {
            solarSystemID = this.muleDefaultSystem
        }
        const newMiner = new Miner(await MinerManagerService.getInstance().getMiner(minerId, solarSystemID)) // hardcoded solar system 0
        const star = this.map.stars.find(a => a.id == solarSystemID)
        if (!star || star?.miners.find(it => it.id == minerId)) return

        star!.miners.push(newMiner)
        newMiner.updateCartesian()
        document.dispatchEvent(new CustomEvent(MapService.MINER_ADDED_ON_MAP_EVENT, { detail: newMiner }))
    }

    public async cometEnter(cometId: string, solarSystemID: number) {
        const newComet = new Comete(await CometManagerService.getInstance().getComet(cometId, solarSystemID))
        newComet.solarSystemID = solarSystemID
        newComet.updateCartesian()

        const star = this.map.stars.find(a => a.id == solarSystemID)
        if (star === undefined) return
        if (newComet.isPending()) {
            if (star.pendingComets.find(it => it.id == cometId)) return
            star.pendingComets.push(newComet)
            return
        }
        if (!star || star?.cometes.find(it => it.id == cometId)) return
        star?.cometes.push(newComet)

        const notif = new Notification(NotificationType.Default)
        notif.title = `A new comet appeared on\n${star.name}`
        NotificationService.getInstance().add(notif)

        document.dispatchEvent(new CustomEvent(MapService.COMET_ADDED_ON_MAP_EVENT, { detail: newComet }))
    }

    public async stakingCometEnter(cometId: string, solarSystemID: number) {
        const newComet = new StakingComet(await CometManagerService.getInstance().getStakingComet(cometId, solarSystemID))
        newComet.solarSystemID = solarSystemID
        newComet.updateCartesian()

        const star = this.map.stars.find(a => a.id == solarSystemID)
        if (star === undefined) return
        if (newComet.isPending()) {
            if (star.pendingStakingComets.find(it => it.id == cometId)) return
            star.pendingStakingComets.push(newComet)
            return
        }
        if (!star || star?.stakingComets.find(it => it.id == cometId)) return
        star?.stakingComets.push(newComet)

        const notif = new Notification(NotificationType.Default)
        notif.title = `A new radioactive comet\nappeared on\n${star.name}`
        NotificationService.getInstance().add(notif)

        document.dispatchEvent(new CustomEvent(MapService.STAKING_COMET_ADDED_ON_MAP_EVENT, { detail: newComet }))
    }

    public async updateStakingComet(event: CustomEvent) {
        const star = this.map.stars.find(s => s.id == event.detail.solarSystemID)
        if (!star) return
        const comet = star.stakingComets.find(c => c.id == event.detail.cometId)
        if (!comet) return
        comet.updateData(event.detail)
    }

    // TODO: Tangui - check the event this could spare some rpc call
    public async updateMiner(minerId: string, solarSystemID: number) {
        if (!this.updateMinerStatus.get(minerId)) {
            this.updateMinerStatus.set(minerId, true)
            try {
                const minerUpdated = new Miner(await MinerManagerService.getInstance().getMiner(minerId, solarSystemID))
                this.map.stars.forEach(s => {
                    s.miners.forEach(miner => {
                        if (miner.id == minerId) {
                            miner.orbit = minerUpdated.orbit
                            miner.lastAction = minerUpdated.lastAction
                            miner.updateCartesian()
                            return
                        }
                    })
                })
            } catch (error) {
                console.error('error updating miner:', minerId, error)
            } finally {
                this.updateMinerStatus.set(minerId, false)
            }
        }
    }

    public minerByID(id: string): Miner | undefined {
        return this.map.currentStar.miners.find(it => it.id == id)
    }

    public async loadMiners() {
        const portalsMap = new Map<number, Array<Portal>>()
        const portals = await PortalService.getInstance().getPortals()
        portals.forEach((portalInfos: any) => {
            const portal1 = new Portal(
                portalInfos.id,
                new Orbit(portalInfos.system1Orbit),
                parseInt(portalInfos.system1Id),
                parseInt(portalInfos.system2Id),
                30,
                new BN(portalInfos.fees)
            )

            let system1Array = portalsMap.get(portalInfos.system1Id)
            if (system1Array == undefined) {
                system1Array = new Array<Portal>()
            }

            if (this.starsBlackList.indexOf(portal1.toId) < 0) {
                this.map.portals.push(portal1)

                system1Array.push(portal1)
                portalsMap.set(portalInfos.system1Id, system1Array)
            }

            const portal2 = new Portal(
                portalInfos.id,
                new Orbit(portalInfos.system2Orbit),
                parseInt(portalInfos.system2Id),
                parseInt(portalInfos.system1Id),
                30,
                new BN(portalInfos.fees)
            )

            let system2Array = portalsMap.get(portalInfos.system2Id)
            if (system2Array == undefined) {
                system2Array = new Array<Portal>()
            }

            if (this.starsBlackList.indexOf(portal2.fromId) < 0) {
                system2Array.push(portal2)
                portalsMap.set(portalInfos.system2Id, system2Array)
            }
        })

        const solarSystems = (await SolarSystemService.getInstance().solarSystems()) as any[]
        solarSystems.forEach(it => {
            if (this.starsBlackList.indexOf(parseInt(it.id)) < 0) {
                const sol = new Star(it.id, it.name)
                sol.cometExist = this.map.cometExist
                sol.x = it.center.x
                sol.y = it.center.y
                const portals = portalsMap.get(it.id)
                if (portals != undefined) {
                    sol.portals = portals
                }
                this.map.stars.push(sol)
            }
        })

        let userMiners = Array<Miner>()
        let userMinersID: string[] = await StakedSpaceshipsService.getInstance().tokensOfOwner()
        PlayerService.getInstance().rentedMiners = await RentingService.getInstance().borrowedShipsInGame()

        const operatorMinorIDs = PlayerService.getInstance().rentedMiners.map(it => it.minerId)
        userMinersID = userMinersID.concat(operatorMinorIDs)

        this.noMiningZone.radius = await MiningManagerService.getInstance().getNoMiningZone()

        const userMinerSolarSystem = await PortalService.getInstance().getMinersLastDestination(userMinersID)

        this.rovers = await RoverManagerService.getInstance().roverOnOf(userMinersID)

        userMiners = userMinersID.map((id: string, i: number) => {
            const miner = new Miner({ id: id })
            miner.solarSystemID = userMinerSolarSystem[i]
            miner.rover = this.rovers[i]
            let minersOnComet = this.playerRovers.get(miner.rover)
            if (minersOnComet == undefined) {
                minersOnComet = []
            }
            this.playerRovers.set(miner.rover, minersOnComet.concat(miner))

            return miner
        })

        PlayerService.getInstance().miningPlayers = userMiners

        for (let index = 0; index < userMiners.length; index++) {
            const element = userMiners[index]
            await element.updateFromMetaData()
        }

        for (let i = 0; i < this.map.stars.length; i++) {
            const element = this.map.stars[i]
            await this.loadStar(element.id)
        }

        setInterval(() => {
            this.checkPendingComets()
            this.displayGame()
        }, 1000)

        return
    }

    public async switchToSolarSystem(id: number) {
        document.dispatchEvent(new Event(MapService.CURRENT_SOLAR_SYSTEM_CHANGING))
        const star = this.map.stars.find(it => it.id == id)!
        RankingSessionService.getInstance().updateForSystemId(id)
        await this.loadStarMiners(id)

        this.map.currentStar = star

        let userMinersID: string[] = await StakedSpaceshipsService.getInstance().tokensOfOwner()
        PlayerService.getInstance().rentedMiners = await RentingService.getInstance().borrowedShipsInGame()

        const operatorMinorIDs = PlayerService.getInstance().rentedMiners.map(it => it.minerId)
        userMinersID = userMinersID.concat(operatorMinorIDs)

        this.rovers = await RoverManagerService.getInstance().roverOnOf(userMinersID)
        userMinersID.forEach((id: string, i: number) => {
            const foundMiner = PlayerService.getInstance().miningPlayers.find(it => id == it.id)
            if (foundMiner) {
                foundMiner.rover = this.rovers[i]
            }
        })

        // this.displayTestNotifs()
        this.displayGame()
        document.dispatchEvent(new Event(MapService.CURRENT_SOLAR_SYSTEM_CHANGED))
    }

    private async loadStarMiners(id: number) {
        const star = this.map.stars.find(it => it.id == id)!

        if (star.minersLoaded == false) {
            const minimumPullPrice = new BN(await JumpManagerService.getInstance().getPullingMinFee(id))
            const minersList = await MinerManagerService.getInstance().minersInSolarSystem(id)
            const minerIds = PlayerService.getInstance().miningPlayers.map(a => a.id)
            minersList.forEach(a => {
                if (star.miners.find(it => it.id == a.id)) return
                const miner = new Miner(a)
                const i = minerIds.indexOf(a.id)
                miner.isSelf = i >= 0

                if (miner.pullingPrice.lt(minimumPullPrice)) {
                  miner.pullingPrice = minimumPullPrice
                }

                if (miner.isSelf) {
                    PlayerService.getInstance().miningPlayers[i] = miner
                    if (miner.id == PlayerService.getInstance().miner.id) {
                        PlayerService.getInstance().updateMainPlayer(miner)
                    }
                }

                star.miners.push(miner)
                star.minersLoaded = true
            })
        }

        return
    }

    private async loadStar(id: number) {
        const star = this.map.stars.find(it => it.id == id)!
        const cometsList = (await CometManagerService.getInstance().cometsInSolarSystem(id)) as Array<Array<Comete>>
        cometsList[0].forEach(a => {
            if (star.cometes.find(it => it.id == a.id)) return
            const comete = new Comete(a)
            if (comete.isPending()) {
                star.pendingComets.push(comete)
                return
            }
            star.cometes.push(comete)
        })
        cometsList[1].forEach(a => {
            if (star.stakingComets.find(it => it.id == a.id)) return
            const comete = new StakingComet(a)
            const minersOnComet = this.playerRovers.get(comete.id)
            if (minersOnComet != undefined) {
                comete.playerMiners = minersOnComet
            }
            if (comete.isPending()) {
                star.pendingStakingComets.push(comete)
                return
            }
            star.stakingComets.push(comete)
        })

        star.minerCount = parseInt(await MinerManagerService.getInstance().countMinerIn(id))
    }

    private async checkPendingComets() {
        this.map.stars.forEach(s => {
            s.pendingComets.forEach((comet, index) => {
                if (comet.isPending()) {
                    return
                }
                s.pendingComets.splice(index, 1)
                this.cometEnter(comet.id, s.id)
            })
        })
        this.map.stars.forEach(s => {
            s.pendingStakingComets.forEach((comet, index) => {
                if (comet.isPending()) {
                    return
                }
                s.pendingStakingComets.splice(index, 1)
                this.stakingCometEnter(comet.id, s.id)
            })
        })
    }

    private async displayGame() {
        this.map.stars.forEach(it => {
            it.update(it.id == this.currentSolarSystemId)
        })
        this.checkPullingPriceRange()
        document.dispatchEvent(new Event('serverPostTick'))
    }

    private checkPullingPriceRange() {
        if (!this.map.currentStar) return

        let prices = MapService.getInstance()
            .map.currentStar.miners.filter(a => {
                return a.pullingPrice.gte(MapService.getInstance().getMinPullPrice())
            })
            .map(miner => {
                return parseFloat(miner.pullPriceInSpice())
            })

        const clear99Perc = quantile(prices, 0.9)
        const clear1Perc = quantile(prices, 0.01)
        prices = prices.filter(it => it > clear1Perc && it < clear99Perc)
        this.quantiles = []
        for (let i = 0; i < 90; i += 10) {
            this.quantiles.push(quantile(prices, i / 90))
        }
    }

    private displayTestNotifs() {
        const error = new Notification(NotificationType.Error)
        error.title = 'Test error'
        NotificationService.getInstance().add(error)

        const success = new Notification(NotificationType.Success)
        success.title = 'Test success'
        NotificationService.getInstance().add(success)

        const tx1 = new Transaction()
        tx1.status = TransactionStatus.InProgress
        const progressTx = new TransactionNotification(tx1)
        progressTx.title = 'Test progressTx'
        progressTx.id = '0x123456789123456789'
        NotificationService.getInstance().add(progressTx)

        const tx2 = new Transaction()
        tx2.status = TransactionStatus.Success
        const successTx = new TransactionNotification(tx2)
        successTx.title = 'Test successTx'
        successTx.id = '0x123456789123456789'
        NotificationService.getInstance().add(successTx)
        tx2.onSuccess()

        const tx3 = new Transaction()
        tx3.status = TransactionStatus.Success
        tx3.error = 'test error message'
        const errorTx = new TransactionNotification(tx3)
        errorTx.title = 'Test errorTx'
        errorTx.id = '0x123456789123456789'
        NotificationService.getInstance().add(errorTx)
        tx3.onError()

        const mining = new MiningNotification(
            PlayerService.getInstance().miner,
            MapService.getInstance().map.currentStar.stakingComets[0],
            Date.now()
        )
        mining.title = 'Test mining'
        NotificationService.getInstance().add(mining)

        const travel = new PortalTravelNotification(
            PlayerService.getInstance().miner,
            MapService.getInstance().map.currentStar.portals[0],
            Date.now()
        )
        travel.title = 'Test travel'
        NotificationService.getInstance().add(travel)
    }

    get currentSolarSystemId(): number {
        if (this.map.currentStar) {
            return this.map.currentStar.id
        } else return -1
    }
}
