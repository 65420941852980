import GameElement from '../GameElement'
import Player from '../models/Miner'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import Comete from '../models/Comete'
import MapService from '../services/MapService'
import Distance from '@/helpers/Distance'
import PlayerService from '../services/PlayerService'
import GSprite from '../pixi-scale/GSprite'
import Resolution from '@/helpers/Resolution'
import GBackground from '../pixi-scale/GBackground'
import MapComponent from './MapComponent'

export default class MiniMapComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public miniMapContainer = new PIXI.Container()
    public mask = new GBackground()
    public minimapLayout = new GSprite('minimap_layout')
    public mapComponent = new MapComponent()
    public minimapBtn = new GSprite('minimap_btn')

    public onMapExtand = () => {
        return
    }

    public onConfirm = () => {
        return
    }
    public onCancel = () => {
        return
    }

    constructor() {
        const minimapLayout = this.minimapLayout
        this.container.addChild(minimapLayout)

        const mask = this.mask
        this.mask.setInitialWidth(this.minimapLayout.width - Resolution.margin6)
        this.mask.setInitialHeight(this.minimapLayout.height - Resolution.margin6)
        this.container.addChild(mask)
        this.miniMapContainer.mask = mask

        this.miniMapContainer.addChild(this.mapComponent.container)
        const minimapBtn = this.minimapBtn
        minimapBtn.cursor = 'pointer'
        minimapBtn.interactive = true
        minimapBtn.alpha = 0.8
        minimapBtn.on('mouseover', () => {
            minimapBtn.alpha = 1
        })
        minimapBtn.on('mouseout', () => {
            minimapBtn.alpha = 0.8
        })
        minimapBtn.on('pointertap', () => {
            this.onMapExtand()
        })
        this.container.addChild(minimapBtn)
        this.container.addChild(this.miniMapContainer)
        this.onResize()

        document.addEventListener('serverPostTick', () => {
            this.updateMapArea()
        })
        this.mapComponent.updateZoom(0.5)
    }

    tick(): void {
        const player = PlayerService.getInstance().miner
        this.miniMapContainer.position.x = -player.x * Resolution.scale * this.mapComponent.zoom + this.minimapBtn.width
        this.miniMapContainer.position.y = -player.y * Resolution.scale * this.mapComponent.zoom + Resolution.margin6
    }

    onResize(): void {
        const player = PlayerService.getInstance().miner
        this.miniMapContainer.position.x = -player.x * Resolution.scale
        this.miniMapContainer.position.y = -player.y * Resolution.scale

        this.container.position.x = Resolution.realWidth - this.container.width / 2 - Resolution.margin2 * 5
        this.container.position.y = Resolution.realHeight - this.container.height / 2
        this.minimapBtn.position.x = -this.minimapLayout.width / 2 + this.minimapBtn.width / 2 + Resolution.margin2 * 2
        this.minimapBtn.position.y = this.minimapLayout.height / 2 - this.minimapBtn.height / 2
        this.minimapLayout.position.x = this.minimapBtn.width
        this.mask.position.x = -this.minimapLayout.width / 2 + Resolution.margin6 + this.minimapLayout.position.x
        this.mask.position.y = -this.minimapLayout.height / 2 + Resolution.margin6
        this.mapComponent.updateScale(Resolution.scale)
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await callback()
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    private updateMapArea() {
        const player = PlayerService.getInstance().miner
        const x = player.x * Resolution.scale * this.mapComponent.zoom + Resolution.margin2 - this.mask.width / 2
        const y = player.y * Resolution.scale * this.mapComponent.zoom - Resolution.margin3 - this.mask.height / 2
        this.mapComponent.updateAreaVisibility(new PIXI.Rectangle(x, y, this.mask.width, this.mask.height))
    }
}
