import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'

export enum TooltipPosition {
    Left,
    Right,
    Top,
    Bottom
}

export function addTooltip(
    element: PIXI.Container | PIXI.Sprite,
    parentContainer: PIXI.Container,
    text: string | Function,
    position: TooltipPosition = TooltipPosition.Top
): void {
    const container: PIXI.Container = new PIXI.Container()
    const bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    bg.tint = 0x00000
    bg.alpha = 0.7

    const message = new MainBitmapText('', { fontSize: Resolution.fontSize })
    let killed = false

    container.addChild(bg)
    container.addChild(message)
    const resetPosition = () => {
        if (position == TooltipPosition.Top) {
            container.x = element.x + element.width / 2 - container.width / 2
            container.y = element.y - container.height - 2
        } else if (position == TooltipPosition.Left) {
            container.x = element.x - container.width - 2
            container.y = element.y + element.height / 2 - container.height / 2
        } else if (position == TooltipPosition.Bottom) {
            container.x = element.x + element.width / 2 - container.width / 2
            container.y = element.y + element.height + container.height + 2
        } else if (position == TooltipPosition.Right) {
            container.x = element.x + element.width + 2
            container.y = element.y + element.height / 2 - container.height / 2
        }

        container.x = Math.floor(container.x)
        container.y = Math.floor(container.y)
    }

    element.on('mouseout', () => {
        killed = true
        parentContainer.removeChild(container)
    })
    element.on('pointerdown', (event: any) => {
        parentContainer.removeChild(container)
        resetPosition()
    })
    element.on('pointerup', (event: any) => {
        setTimeout(() => {
            if (!killed) {
                parentContainer.addChild(container)
                resetPosition()
            }
        }, 33)
    })
    element.on('mouseover', (event: any) => {
        killed = false
        parentContainer.addChild(container)

        const messageText = text instanceof Function ? text() : text
        message.text = messageText

        bg.width = message.width + 12
        bg.height = message.height + 6
        message.x = 6
        message.y = 3
        resetPosition()
    })
}
