export class Cup {
    constructor(
        public name: string,
        public startAt: number,
        public endAt: number,
        public startBlock: number,
        public sessionDuration: number,
        public requirement: string,
        public rarities: string[]
    ) {}

    public doAllowRarity(rarity: string) {
        return this.rarities.indexOf(rarity) >= 0
    }
}
