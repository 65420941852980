import config from '@/config'
import Web3Service from '@/game/services/Web3Service'

export default class SolarSystemService {
    public static instance: SolarSystemService

    public static getInstance(): SolarSystemService {
        if (!this.instance) {
            this.instance = new SolarSystemService()
        }
        return this.instance
    }

    private abi = [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'ssStore',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'string',
                    name: 'name',
                    type: 'string'
                },
                {
                    indexed: false,
                    internalType: 'int256',
                    name: 'centerX',
                    type: 'int256'
                },
                {
                    indexed: false,
                    internalType: 'int256',
                    name: 'centerY',
                    type: 'int256'
                }
            ],
            name: 'NewSolarSystem',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'RemoveSolarSystem',
            type: 'event'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'solarSystemStore',
            outputs: [
                {
                    internalType: 'contract ISolarSystemStore',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updateSolarSystemStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'solarSystemId',
                    type: 'uint256'
                },
                {
                    components: [
                        {
                            internalType: 'int256',
                            name: 'x',
                            type: 'int256'
                        },
                        {
                            internalType: 'int256',
                            name: 'y',
                            type: 'int256'
                        }
                    ],
                    internalType: 'struct PositionsV2.Cartesian',
                    name: 'center',
                    type: 'tuple'
                },
                {
                    internalType: 'string',
                    name: 'name',
                    type: 'string'
                },
                {
                    internalType: 'address',
                    name: 'minerStore',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'cometStore',
                    type: 'address'
                }
            ],
            name: 'add',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'solarSystemId',
                    type: 'uint256'
                }
            ],
            name: 'remove',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'solarSystem',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'string',
                            name: 'name',
                            type: 'string'
                        },
                        {
                            components: [
                                {
                                    internalType: 'int256',
                                    name: 'x',
                                    type: 'int256'
                                },
                                {
                                    internalType: 'int256',
                                    name: 'y',
                                    type: 'int256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Cartesian',
                            name: 'center',
                            type: 'tuple'
                        }
                    ],
                    internalType: 'struct ISolarSystemManager.SolarSystem',
                    name: '',
                    type: 'tuple'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'solarSystems',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'string',
                            name: 'name',
                            type: 'string'
                        },
                        {
                            components: [
                                {
                                    internalType: 'int256',
                                    name: 'x',
                                    type: 'int256'
                                },
                                {
                                    internalType: 'int256',
                                    name: 'y',
                                    type: 'int256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Cartesian',
                            name: 'center',
                            type: 'tuple'
                        }
                    ],
                    internalType: 'struct ISolarSystemManager.SolarSystem[]',
                    name: '',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        }
    ]

    private solarSystemManager: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.solarSystemManager = new wssWeb3.eth.Contract(this.abi, config.solarSystemManagerAddress)
    }

    public async solarSystems() {
        return await this.solarSystemManager.methods.solarSystems().call()
    }
}
