import Resolution from '@/helpers/Resolution'
import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import TutorialStep from '../models/TutorialStep'
import TutorialService from '../services/TutorialService'
import MainBitmapText from '../pixi-scale/MainBitmapText'

export default class TutorialComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public textContainer: PIXI.Container = new PIXI.Container()
    public onEnd: () => void = () => {
        return
    }

    private bg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private textBg: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private top: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private bottom: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private textObject: PIXI.BitmapText = new MainBitmapText('a', {
        fontSize: Resolution.fontSize
    })
    private next!: PIXI.BitmapText
    private isReady = false

    private tutorialRobot!: PIXI.AnimatedSprite
    private currentStepIndex = 1
    private currentStep!: TutorialStep

    constructor(public id: string, public steps: TutorialStep[]) {
        this.container.scale.set(Resolution.scale)
        this.container.interactive = true
        this.container.cursor = 'pointer'
        this.container.on('pointertap', () => {
            if (this.isReady) {
                this.isReady = false
                this.setStep(this.currentStepIndex + 1)
            }
        })
        this.bg.tint = 0x000000

        this.textBg.tint = 0x081f36
        this.top.tint = 0x49bfff
        this.bottom.tint = 0x49bfff

        this.tutorialRobot = new PIXI.AnimatedSprite(
            (PIXI.Loader.shared.resources['tuto'] as any).spritesheet!._frameKeys.map((a: string) => {
                return PIXI.Texture.from(a)
            })
        )
        this.tutorialRobot.animationSpeed = 0.1
        this.tutorialRobot.play()
        this.tutorialRobot.x = 2
        this.tutorialRobot.y = Resolution.realHeight / Resolution.scale - this.tutorialRobot.height - 2

        const text = new MainBitmapText('COBOTH', {
            fontSize: Resolution.fontSize
        })
        text.y = -4 * Resolution.scale
        this.next = new MainBitmapText('NEXT', {
            fontSize: Resolution.fontSize
        })

        this.container.addChild(this.bg)
        this.textContainer.addChild(text)
        this.textContainer.addChild(this.textBg)
        this.textContainer.addChild(this.top)
        this.textContainer.addChild(this.bottom)
        this.textContainer.addChild(this.next)
        this.container.addChild(this.textContainer)
        this.container.addChild(this.tutorialRobot)

        this.onResize()

        this.setStep(0)
        this.fadeInBG()
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        this.container.scale.set(Resolution.scale)
        this.bg.width = Resolution.realWidth / Resolution.scale
        this.bg.height = Resolution.realHeight / Resolution.scale
        // nothing

        if (this.currentStep) {
            this.textBg.width = this.textObject.width + Resolution.margin6
            this.textBg.height = this.textObject.height + Resolution.margin3
            this.top.width = this.textBg.width
            this.bottom.width = this.textBg.width
            this.bottom.y = this.textBg.height
            this.textObject.x = this.textBg.width / 2 - this.textObject.width / 2
            this.textObject.y = this.textBg.height / 2 - this.textObject.height / 2
            this.next.y = this.textBg.height + 3
            this.next.x = this.textBg.width - this.next.width

            const robotX = this.currentStep.robotPosition(this.tutorialRobot.width, this.tutorialRobot.height).x
            let containerX = 0
            if (robotX + this.tutorialRobot.width + this.textContainer.width > Resolution.realWidth / Resolution.scale) {
                containerX = robotX - this.textContainer.width
            } else {
                containerX = robotX + this.tutorialRobot.width
            }
            this.textContainer.x = containerX
            this.textContainer.y = this.currentStep.robotPosition(this.tutorialRobot.width, this.tutorialRobot.height).y + 6

            if (this.isReady) {
                this.tutorialRobot.x = this.currentStep.robotPosition(this.tutorialRobot.width, this.tutorialRobot.height).x
                this.tutorialRobot.y = this.currentStep.robotPosition(this.tutorialRobot.width, this.tutorialRobot.height).y
            }
        }
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    private async setStep(step: number) {
        if (step >= this.steps.length) {
            this.currentStep = new TutorialStep([''], (w, h) => {
                return new PIXI.Point(2, Resolution.realHeight / Resolution.scale - this.tutorialRobot.height - 2)
            })
            this.fadeOutBG()
            this.animateLeaveText()
            this.onEnd()
            await this.animateRobotMove()
            TutorialService.getInstance().clearTutorial()
            return
        }

        this.currentStepIndex = step
        this.currentStep = this.steps[step]

        this.setupStepViews()
    }

    private animateEnterText() {
        animate('easeInQuad', 300, (perc: number) => {
            this.textContainer.alpha = perc
            this.top.width = this.textBg.width * perc
            this.bottom.width = this.textBg.width * perc
            this.bottom.x = (this.textBg.width - this.bottom.width) * perc
        }).then(() => {
            this.isReady = true
        })
    }

    private animateLeaveText() {
        animate('easeOutQuad', 300, (perc: number) => {
            this.textContainer.alpha = 1 - perc
            this.top.width = this.textBg.width * (1 - perc)
            this.bottom.width = this.textBg.width * (1 - perc)
            this.bottom.x = (this.textBg.width - this.bottom.width) * (1 - perc)
        })
    }

    private fadeOutBG() {
        animate('easeOutQuad', 1000, (perc: number) => {
            this.bg.alpha = 0.6 - 0.6 * perc
        })
    }
    private fadeInBG() {
        this.bg.alpha = 0
        animate('easeOutQuad', 1000, (perc: number) => {
            this.bg.alpha = 0.6 * perc
        })
    }

    private async animateRobotMove() {
        const distX = this.currentStep.robotPosition(this.tutorialRobot.width, this.tutorialRobot.height).x - this.tutorialRobot.x
        const distY = this.currentStep.robotPosition(this.tutorialRobot.width, this.tutorialRobot.height).y - this.tutorialRobot.y
        const iX = this.tutorialRobot.x
        const iY = this.tutorialRobot.y
        await animate('easeInOutCubic', 1000, (perc: number) => {
            this.tutorialRobot.x = iX + distX * perc
            this.tutorialRobot.y = iY + distY * perc
        })
    }

    async enterAnimation(callback: () => void): Promise<void> {
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        callback()
    }

    private setupStepViews() {
        if (this.textObject != undefined) {
            this.textObject.destroy()
        }

        this.animateLeaveText()
        this.animateRobotMove()
        setTimeout(() => {
            this.textObject = new MainBitmapText(this.currentStep.texts[0], {
                fontSize: 6
            })
            this.textContainer.addChild(this.textObject)

            this.onResize()
            this.animateEnterText()
        }, 500)
    }

    get textWidth() {
        return 0
    }

    get textHeight() {
        return 0
    }
}
