import * as PIXI from 'pixi.js'
import { Notification, NotificationType } from '../../models/notification/Notification'
import NotificationComponent from './NotificationComponent'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import Colors from '@/helpers/Colors'
import NotificationService from '@/game/services/NotificationService'
import { TransactionNotification } from '@/game/models/notification/TransactionNotification'
import { Transaction, TransactionStatus } from '@/game/models/Transaction'
import Web3Service from '@/game/services/Web3Service'
import { getFormatedTx } from '@/helpers/StringHelper'
import SessionTransactionNotifService from '@/game/services/SessionTransactionService'

export default class TransactionNotificationComponent extends NotificationComponent {
    protected txTitle: PIXI.BitmapText
    protected loader: PIXI.AnimatedSprite = new PIXI.AnimatedSprite(
        (PIXI.Loader.shared.resources['loader'] as any).spritesheet!._frameKeys.map((a: string) => {
            return PIXI.Texture.from(a)
        })
    )

    public onClick = () => {
        return
    }
    public notifications: TransactionNotification[] = []
    constructor(public notification: Notification) {
        super(notification)

        Web3Service.getInstance()
            .getWeb3()
            .eth.getTransactionCount(Web3Service.getInstance().connectedAccount)
            .then((a: any) => {
                console.log(a)
            })

        this.onResize()
        this.closer.interactive = false
        this.closer.alpha = 0

        this.title.y = 6 + 1 / 3
        this.txTitle = new MainBitmapText('', { fontSize: 4 })
        this.txTitle.tint = Colors.Blue600

        this.loader.x = this.width - this.loader.width - 6
        this.loader.y = 6 + 1 / 3
        this.loader.animationSpeed = 0.2
        this.loader.play()

        this.dataContainer.addChild(this.txTitle)
        this.dataContainer.addChild(this.loader)

        this.onResize()

        this.refreshView()
    }

    tick(): void {
        // nothing
    }

    defineHeight() {
        this.height = this.dataContainer.height + 12
    }

    public addNotifTx(notification: TransactionNotification) {
        if (this.notifications.length == 0) {
            this.enterAnimation(() => {
                return
            })
        }

        this.notifications.push(notification)

        notification.transaction.onSuccess = () => {
            if (notification.transaction.status == TransactionStatus.Success) return
            notification.onNotificationSuccess()
            notification.transaction.status = TransactionStatus.Success
            SessionTransactionNotifService.getInstance().dispatchTxFinished(notification.title, true)
            SessionTransactionNotifService.getInstance().dispatchTxChanged()
            this.removeTx(notification)
        }
        notification.onTitleChanged = () => {
            this.refreshView()
        }
        notification.transaction.onError = () => {
            notification.transaction.status = TransactionStatus.Failed
            SessionTransactionNotifService.getInstance().dispatchTxFinished(notification.title, false)
            SessionTransactionNotifService.getInstance().dispatchTxChanged()
            this.removeTx(notification)
        }

        this.refreshView()
    }

    private removeTx(notif: TransactionNotification) {
        this.notifications = this.notifications.filter(a => a.transaction.hash != notif.transaction.hash)
        this.refreshView()
    }

    private refreshView() {
        if (this.notifications.length > 1) {
            this.title.text = `${this.notifications.length} pending transactions`
            this.txTitle.text = `Click to see more`
        } else if (this.notifications.length == 1) {
            this.title.text = this.notifications[0].title
            this.txTitle.text = getFormatedTx(this.notifications[0].transaction)
        } else {
            this.exitAnimation(() => {
                return
            })
        }

        this.txTitle.y = this.title.y + 5
        this.txTitle.x = 6
    }
}
