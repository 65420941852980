import ComethSwapTokenService from '@/services/ComethSwapTokenService'

export default class LiquidityToken {
    id = ''
    pair = ''
    balance = ''
    token0 = ''
    token1 = ''
    address0 = ''
    address1 = ''

    reserve0 = ''
    reserve1 = ''
    totalSupply = ''
    reserveUSD = ''

    priceUSD = 0

    token0img = ''
    token1img = ''

    constructor(address: string, symbol0: string, id0: string, symbol1: string, id1: string, reserve0: string, reserve1: string, totalSupply: string, reserveUSD: string, balance = '0') {
        this.id = address
        this.token0 = symbol0
        this.token1 = symbol1
        this.address0 = id0
        this.address1 = id1
        this.pair = symbol0 + '/' + symbol1
        this.balance = balance

        this.reserve0 = reserve0
        this.reserve1 = reserve1
        this.totalSupply = totalSupply

        this.priceUSD = parseInt(reserveUSD, 10) / parseFloat(totalSupply)
    }

    token0Balance() {
        return parseFloat(this.reserve0) * parseFloat(this.balance) / parseFloat(this.totalSupply)
    }

    token1Balance() {
        return parseFloat(this.reserve1) * parseFloat(this.balance) / parseFloat(this.totalSupply)
    }

    balanceUSD() {
        return parseFloat(this.balance) * this.priceUSD
    }

    async init() {
      this.token0img = await ComethSwapTokenService.getInstance().getImageURL(this.token0)
      this.token1img = await ComethSwapTokenService.getInstance().getImageURL(this.token1)
    }
}
