
import { Options, Vue } from 'vue-class-component'
import VueCountdown from '@chenfengyuan/vue-countdown'
import ButtonPrimary from '@/components/ButtonPrimary.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'

@Options({
    components: { VueCountdown, ButtonPrimary, ButtonSecondary }
})
export default class PolygonCup extends Vue {
    get time() {
        const utc = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getTime()
        return new Date("July 17, 2021 13:00:00").getTime() - utc
    }

    transformSlotProps(props: any) {
        return {
            days: props.days.toString().padStart(2, '0'),
            hours: props.hours.toString().padStart(2, '0'),
            minutes: props.minutes.toString().padStart(2, '0'),
            seconds: props.seconds.toString().padStart(2, '0')
        }
    }
}
