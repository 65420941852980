import axios from 'axios'

export default class TimeService {
    public static instance: TimeService

    public timeDiff = 0

    public static getInstance(): TimeService {
        if (!this.instance) {
            this.instance = new TimeService()
        }

        return this.instance
    }

    public async refTime() {
        try {
            const result = await axios.get('https://showcase.api.linx.twenty57.net/UnixTime/tounix?date=now')
            return result.data
        } catch (e) {
            console.log('Error: Call time API failed')
            return this.now()
        }
    }

    public now() {
        return Math.floor(Date.now() / 1000)
    }

    public async calculateTimeDiff() {
        const refTime = await this.refTime()
        this.timeDiff = Math.abs(refTime - this.now())
        console.log('TIME DIFF: ', this.timeDiff)
    }

    public formattedNow() {
        const date = new Date(this.now() * 1000)
        return date.getHours() + ' ' + date.getMinutes() + ' ' + date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    }
}
