/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 */
export const EasingFunctions: any = {
    // no easing, no acceleration
    linear: (t: number) => t,
    // accelerating from zero velocity
    easeInQuad: (t: number) => t * t,
    // decelerating to zero velocity
    easeOutQuad: (t: number) => t * (2 - t),
    // acceleration until halfway, then deceleration
    easeInOutQuad: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
    // accelerating from zero velocity
    easeInCubic: (t: number) => t * t * t,
    // decelerating to zero velocity
    easeOutCubic: (t: number) => --t * t * t + 1,
    // acceleration until halfway, then deceleration
    easeInOutCubic: (t: number) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
    // accelerating from zero velocity
    easeInQuart: (t: number) => t * t * t * t,
    // decelerating to zero velocity
    easeOutQuart: (t: number) => 1 - --t * t * t * t,
    // acceleration until halfway, then deceleration
    easeInOutQuart: (t: number) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
    // accelerating from zero velocity
    easeInQuint: (t: number) => t * t * t * t * t,
    // decelerating to zero velocity
    easeOutQuint: (t: number) => 1 + --t * t * t * t * t,
    // acceleration until halfway, then deceleration
    easeInOutQuint: (t: number) => (t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t)
}

export class AnimationLooper {
    public lastTime = Date.now()
    public cleared = false
    constructor(public elapsedTime = 0, public duration = 0, public method = '', public callback: () => void) {}
}

export class AnimationService {
    public static instance: AnimationService

    public static getInstance(): AnimationService {
        if (!this.instance) {
            this.instance = new AnimationService()
        }

        return this.instance
    }

    public loopers: AnimationLooper[] = []

    public removeLooper(looper: AnimationLooper) {
        this.loopers.splice(this.loopers.indexOf(looper), 1)
    }
}
export async function animate(method: string, duration: number, callback: (perc: number) => void): Promise<void> {
    return new Promise(resolve => {
        const looper = new AnimationLooper(0, duration, method, () => {
            if (looper.cleared) return

            looper.elapsedTime += Date.now() - looper.lastTime
            looper.lastTime = Date.now()
            let perc = EasingFunctions[method](looper.elapsedTime / looper.duration)
            if (perc > 1) {
                perc = 1
            }
            callback(perc)

            if (looper.elapsedTime > duration) {
                looper.cleared = true
                callback(1)
                resolve()
                AnimationService.getInstance().removeLooper(looper)
            }
        })
        AnimationService.getInstance().loopers.push(looper)
    })
}
