import Web3Service from '@/game/services/Web3Service'
import axios from 'axios'
import Web3 from 'web3'
import config from '@/config'

export default class DustMigrationService {
    public static instance: DustMigrationService

    public static getInstance(): DustMigrationService {
        if (!this.instance) {
            this.instance = new DustMigrationService()
        }
        return this.instance
    }

    private dustMigrationAbi = [
        {
            inputs: [
                {
                    internalType: 'contract IMigratedTubeAuthorized',
                    name: 'newTube',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'newPool',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'must',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'holder',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'requirement',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'addHolder',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address[]',
                    name: 'holders',
                    type: 'address[]'
                },
                {
                    internalType: 'uint256[]',
                    name: 'conditions',
                    type: 'uint256[]'
                },
                {
                    internalType: 'uint256[]',
                    name: 'amounts',
                    type: 'uint256[]'
                }
            ],
            name: 'addHolders',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'pool',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'redeem',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'requirements',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'rewards',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    private writeDustMigration: any
    private readDustMigration: any

    constructor() {
        this.initContract()
    }
    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        this.writeDustMigration = new web3.eth.Contract(this.dustMigrationAbi, config.dustMigrationAddress, { gasLimit: '1000000' })

        const wsWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readDustMigration = new wsWeb3.eth.Contract(this.dustMigrationAbi, config.dustMigrationAddress)
    }

    public async balanceOf(holder: string) {
        return await this.readDustMigration.methods.rewards(holder).call()
    }

    public async requirementOf(holder: string) {
        return await this.readDustMigration.methods.requirements(holder).call()
    }

    public async redeem(): Promise<string> {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.writeDustMigration.methods.redeem().send({ from: account, gasPrice: '50000000000' })
    }
}
