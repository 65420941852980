import NotificationComponent from './NotificationComponent'
import { MiningNotification } from '../../models/notification/MiningNotification'
import Web3 from 'web3'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import TextButton from '../TextButton'
import RoverManagerService from '../../services/web3/RoverManagerService'
import StakingComet from '@/game/models/StakingComet'
import TxGasPreviewButton from '../TxGasPreviewButton'
import Colors from '@/helpers/Colors'
import WalletService from '@/game/services/web3/WalletService'

// can mine a comet during 3 minutes / adding mining time display notig for 2.5 minutes.
const MAX_MINING_TIME = 150000

export default class DepositRoverMiningNotification extends NotificationComponent {
    protected minerName: MainBitmapText

    protected reward: MainBitmapText
    protected reward$: MainBitmapText
    private comete: StakingComet
    private mineButton = new TxGasPreviewButton('DEPOSIT')

    public onClick = () => {
        return
    }

    constructor(public notification: MiningNotification) {
        super(notification)
        this.bg.interactive = false

        this.comete = notification.comete as StakingComet

        this.title.text = 'Radioactive Comet'
        this.title.x = 24
        this.onResize()
        this.closer.visible = true

        this.minerName = new MainBitmapText('', { fontSize: 4 })
        this.minerName.y = this.title.y + 7
        this.minerName.x = 6
        this.minerName.tint = this.mainTint
        this.dataContainer.addChild(this.minerName)

        const header = PIXI.Sprite.from('notif_header_comet_stack_in')
        header.y = -7.333
        header.x = 4

        this.reward = new MainBitmapText('', { fontSize: 4 })
        this.reward.x = 6
        this.reward.tint = this.mainTint
        this.dataContainer.addChild(this.reward)

        this.reward$ = new MainBitmapText('', { fontSize: 4, tint: Colors.Gold })
        this.reward$.x = 6
        this.dataContainer.addChild(this.reward$)

        this.mineButton.x = this.width - this.mineButton.btn.width - 6
        this.mineButton.onClick = (gasPrice: number) => {
            this.mineTouched(gasPrice)
        }
        this.mineButton.onResized = () => {
            this.onResize()
        }
        this.container.addChild(header)
        this.dataContainer.addChild(this.mineButton)

        setTimeout(() => {
            this.onDestroy()
        }, MAX_MINING_TIME)

        this.onResize()
        this.updateDisplay()
    }

    mineTouched(gasPrice: number) {
        RoverManagerService.getInstance().depositRover(
            this.notification.miner.id,
            this.notification.comete.id,
            this.notification.time,
            this.notification.miner.solarSystemID,
            gasPrice
        )

        const comete = this.notification.comete as StakingComet
        this.onDestroy()
    }

    async updateDisplay() {
        this.mineButton.visible = false
        this.minerName.text = await this.notification.miner.name()

        const depositTitle = new MainBitmapText('Deposit your rover to mine ', { fontSize: 4 })
        depositTitle.x = 6
        depositTitle.y = this.minerName.y + this.minerName.height + 2

        this.container.addChild(depositTitle)

        const reward = Web3.utils.fromWei(this.comete.miningReward(), 'ether')
        this.reward.text = reward + ' ' + this.comete.symbol + '/h'
        this.reward$.text = (Number(reward) * (await WalletService.getInstance().USDPriceOf(this.notification.comete.token))).toFixed(2) + '$/h'
        this.reward.y = depositTitle.y + depositTitle.height
        this.reward$.y = this.reward.y + this.reward.height
        this.mineButton.y = this.reward$.y + this.reward$.height + 3
        this.mineButton.visible = true
    }

    tick(): void {
        // nothing
    }

    defineHeight() {
        this.height = 49
    }

    onResize() {
        super.onResize()

        if (this.mineButton) {
            this.mineButton.x = this.width - this.mineButton.width - 6
        }
    }
}
