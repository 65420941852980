import { Options, Vue } from 'vue-class-component'

@Options({
    watch: {
        $route(to, from) {
            document.title = to.meta.title || 'Cometh.io - A DeFi powered space game with yield generating NFTs (spaceships)'
            document
                .querySelector('meta[name="description"]')
                ?.setAttribute(
                    'content',
                    to.meta.desc ||
                        'Cometh is a blockchain game that lets users own yield-generating NFTs, integrating both DeFi and NFT features into a single, fun gaming experience.'
                )
        }
    }
})
export default class App extends Vue {}
