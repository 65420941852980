export default {
    data: [
        {
            id: '2',
            name: 'explorer',
            url: 'explorer',
            desc: 'Common Human',
            imgUrl: 'https://images.service.cometh.io/2_ship.png',
            miningRange: '16.5',
            miningPower: '1',
            coolDown: '330',
            price: '159.5',
            soldout: false,
            description:
                'Initially designed for long distance travel at a time where humanity was bound find new resources to survive, the Explorer proved perfect for the exploitation of long period asteroids. It features a small windowed deck but a vast interior usually equipped with all necessary amenities to kill time from bilboquet to vintage bugged kernels.'
        },
        {
            id: '61',
            name: 'The Alata',
            url: 'alata',
            desc: 'Common The Yammerians',
            imgUrl: 'https://images.service.cometh.io/61_ship.png',
            price: '159.5',
            miningRange: '12',
            miningPower: '1.1',
            coolDown: '270',
            soldout: true,
            description:
                'The Alata is a small, winged scout ship designed to nimbly navigate the comet fields of the Must Sector searching for signs of rich fertilizer deposits.  Though unimposing, these ships have been known to defend their fertilizer discoveries from pirates and competitors using their agility and strategic flexibility.'
        },
        {
            id: '26',
            name: 'Monocle',
            url: 'monocle',
            desc: 'Uncommon Gotchi',
            imgUrl: 'https://images.service.cometh.io/26_ship.png',
            miningRange: '27',
            miningPower: '1.20',
            coolDown: '480',
            price: '959.5',
            soldout: false,
            description:
                'Monocle spaceships provide astounding flying capabilities thanks to an audacious asymmetric cockpit design. Designed by gentlemen, for gentlemen, the steering is remarquable and its course is always distinguished. The plate of the vessel shows “AGG -2, BRN +3” UFO on every model ever produced.'
        },

        {
            id: '58',
            name: 'G-raff',
            desc: 'Rare Herd',
            url: 'graff',
            imgUrl: 'https://images.service.cometh.io/58_ship.png',
            miningRange: '33',
            miningPower: '1.3',
            coolDown: '390',
            price: '29959.5',
            soldout: false,
            description:
                'The G-Raff is a flying monument, a testament to the greatness of the Safa’ary people. These mighty longships have been designed to roam the galaxy, trading and expanding the influence of the old democratic planet. The Safa’aries have been collecting wealth across many solar systems for hundreds of years, fending off terrible foes along the way. G-raffs are both coveted and feared by space pirates across the ether, they have been here for ages and for ages more they will be.'
        }
    ]
}
