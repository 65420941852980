import { Options, Vue } from 'vue-class-component'
import { Friend } from '@/views/Referral/Referral'
import config from '@/config'

@Options({
    props: {
        friends: Array,
    }
})
export default class FriendList extends Vue {
    friends!: Friend[]
    config = config
    mounted()
    {
        console.log(this.friends)
    }
}
