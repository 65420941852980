<template>
    <a
        :href="href"
        @click="navigate"
        class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
        :class="[
            isActive
                ? 'bg-blue-50 border-blue-500 text-blue-700'
                : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
        ]"
    >
        <slot />
    </a>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        href: String,
        isActive: Boolean
    }
})
export default class NavLinkMobile extends Vue {}
</script>
