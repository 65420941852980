<template>
    <div class="-mt-16 flex flex-col h-screen overflow-x-hidden overflow-y-auto relative gradient font-audiowide">
        <img class="absolute -top-40 -right-80 opacity-50" src="@/assets/welcome/bg_sun.png" />

        <!-- LOGO -->
        <div class="pt-24">
            <img class="h-40 mx-auto" style="image-rendering: auto;" src="@/assets/logo_cometh_v2.png" alt="Cometh" />
        </div>

        <!-- CENTER -->
        <div class="relative mt-6 flex-1 pb-10">
            <div class="flex flex-col h-full">
                <div class="flex flex-1 w-full max-w-5xl mx-auto space-x-32 divide">
                    <!-- SHIPS IN WALLET -->
                    <div class="w-1/2">
                        <h2 class="font-bold text-2xl uppercase text-white glow">
                            <span v-if="!isLoading">
                                {{ availableShips.size }}
                                Ship{{ availableShips.size > 1 ? 's' : '' }}
                            </span>
                            in your wallet
                        </h2>
                        <div class="relative mt-1">
                            <img src="@/assets/welcome/bg_top_right_corner.png" class="absolute -top-8 -right-8 w-24" />
                            <img src="@/assets/welcome/bg_bottom_left_corner.png" class="absolute -bottom-8 -left-8 w-20" />
                            <div class="relative h-60 hide-scrollbar pr-3">
                                <div v-if="isLoading" class="flex flex-col space-y-3 mt-3">
                                    <div v-for="i in 3" :key="i" class="flex items-center animate-pulse">
                                        <div class="bg-gray-700 h-16 w-12 rounded-sm"></div>
                                        <div class="flex-1">
                                            <div class="w-2/3 h-5 rounded-sm bg-gray-700 ml-4"></div>
                                        </div>
                                        <button class="bg-gray-700 w-16 h-8 rounded-sm ml-4"></button>
                                    </div>
                                </div>

                                <div v-else-if="availableShips.size">
                                    <div class="flex flex-col divide-y divide-white divide-opacity-25">
                                        <div v-for="[index, ship] in availableShips" :key="index" class="flex items-center p-3">
                                            <img class="h-16" :src="config.shipImageUrl + '/' + ship.internalId + '.png'" alt="" />
                                            <div class="flex-1 text-sm ml-4 text-white">{{ ship.name || 'Unknown' }}</div>
                                            <button
                                                v-if="!ship.sending"
                                                @click="send(ship)"
                                                class="inline-flex items-center bg-blue-501  rounded-sm bg-gold text-black hover:bg-yellow-300 px-3 py-1 text-sm"
                                            >
                                                Send
                                                <svg
                                                    class="w-4 h-4 -mr-1 ml-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                    />
                                                </svg>
                                            </button>

                                            <button
                                                v-if="ship.sending"
                                                class="inline-flex items-center bg-blue-501  rounded-sm bg-gold text-black hover:bg-yellow-300 px-3 py-1 text-sm cursor-not-allowed opacity-25"
                                            >
                                                <svg
                                                    class="animate-spin -ml-1 mr-3 h-5 w-5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                    <path
                                                        class="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                                <svg
                                                    class="w-4 h-4 -mr-1 ml-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="mt-4 text-white">
                                    <p>No spaceship in your wallet.</p>
                                    <p class="mt-2">How to get one:</p>
                                    <ul>
                                        <li>
                                            - Buy on
                                            <router-link to="/shop" class="text-gold no-underline hover:underline">
                                                Cometh Shop
                                            </router-link>
                                        </li>
                                        <li>
                                            - Borrow a spaceship on
                                            <a class="text-gold no-underline hover:underline" href="//rental.cometh.io">
                                                Cometh Rental
                                            </a>
                                        </li>
                                        <li>
                                            - Or get one on
                                            <a class=" text-gold no-underline hover:underline" href="//matic.opensea.io/">
                                                Opensea
                                            </a>
                                        </li>
                                        <li>
                                            - Or bridge one on
                                            <a class=" text-gold no-underline hover:underline" href="//bridge.cometh.io">
                                                Cometh Bridge
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SHIPS IN GAME -->
                    <div class="w-1/2">
                        <h2 class="font-bold text-2xl uppercase text-white glow">
                            <span v-if="!isLoading">
                                {{ shipsInGame.length }}
                                Ship{{ shipsInGame.length > 1 ? 's' : '' }}
                            </span>
                            in game
                        </h2>

                        <div class="relative mt-1">
                            <img src="@/assets/welcome/bg_top_right_corner.png" class="absolute -top-8 -right-8 w-24" />
                            <img src="@/assets/welcome/bg_bottom_left_corner.png" class="absolute -bottom-8 -left-8 w-20" />
                            <div class="relative h-60 hide-scrollbar pr-3">
                                <div v-if="isLoading" class="flex flex-col space-y-3 mt-3">
                                    <div v-for="i in 3" :key="i" class="flex items-center animate-pulse">
                                        <div class="bg-gray-700 h-16 w-12 rounded-sm"></div>
                                        <div class="flex-1">
                                            <div class="w-2/3 h-5 rounded-sm bg-gray-700 ml-4"></div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else-if="shipsInGame.length">
                                    <div class="flex-1 flex flex-col divide-y divide-white divide-opacity-25">
                                        <div v-for="i in shipsInGame" :key="i" class="flex items-center p-3">
                                            <img class="h-16" :src="imagesUrls.get(i)" alt="" />
                                            <div class="flex-1 text-sm ml-4 text-white">{{ names.get(i) || 'Unknown' }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="mt-4 text-white">You have no ship in game yet.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-10 flex w-full max-w-5xl mx-auto space-x-32">
                    <!-- SHIPS IN WALLET -->
                    <div class="w-1/2">
                        <h2 class="font-bold text-2xl uppercase text-white glow">
                            Your Reward
                        </h2>
                        <div class="relative mt-1">
                            <img src="@/assets/welcome/bg_top_right_corner.png" class="absolute -top-8 -right-8 w-24" />
                            <img src="@/assets/welcome/bg_bottom_left_corner.png" class="absolute -bottom-8 -left-8 w-20" />
                            <div class="relative pr-3 pt-2 pl-2 font-inter">
                                <ul class="text-white space-y-1">
                                    <li>
                                        Staked: <span class="text-gold">{{ mustOnMainTube.toFixed(3) }}</span> MUST on Main tube
                                        <span v-if="maxMustOnMainTube > mustOnMainTube" class="-mt-0.5 block text-white text-opacity-50 text-sm">
                                            To fully earn your shares, you must stake at least {{ maxMustOnMainTube }} MUST
                                        </span>
                                    </li>
                                    <li>
                                        Estimated earnings: <span class="text-gold">${{ earnings.toFixed(3) }}</span> by Week*
                                        <span class="-mt-0.5 block text-sm text-white text-opacity-50">
                                            * Rental earnings are not displayed
                                        </span>
                                    </li>
                                    <li>
                                        Available to claim: <span class="text-gold">${{ availableToClaimDollar.toFixed(3) }}</span>
                                        <span class="-mt-0.5 block text-sm text-white text-opacity-50 ml-2">
                                            - {{ availableToClaim.toFixed(5) }} MATIC/MUST
                                        </span>
                                    </li>
                                    <li>Last update: {{ rewardLastUpdate ? rewardLastUpdate.toLocaleString() : '-' }}</li>
                                </ul>
                                <div class="mt-3 flex space-x-2 items-center">
                                    <Button
                                        @click="claim"
                                        :isLoading="claiming"
                                        class="bg-gold  sm:ml-0 font-audiowide
                                    "
                                    >
                                        Claim
                                    </Button>
                                    <p v-if="claimSuccess" class="text-green-500 text-sm">Reward claimed!</p>
                                    <p v-else-if="claimError" class="text-green-500 text-sm">{{ claimErrorMsg }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/2 grid place-items-center">
                        <!-- PLAY ACTION -->
                        <div class="relative flex-1 h-full max-w-2xl mx-auto flex items-center my-8 text-white">
                            <div v-if="false" class="absolute -left-80">
                                <p>checkingNetwork: {{ checkingNetwork }}</p>
                                <p>networkError: {{ networkError }}</p>
                                <p>checkingSpaceships: {{ checkingSpaceships }}</p>
                                <p>spaceshipsError: {{ spaceshipsError }}</p>
                                <p>gameError: {{ gameError }}</p>
                            </div>
                            <div class="flex flex-col items-center justify-center space-y-4">
                                <transition name="fade" mode="out-in">
                                    <div v-if="checkingNetwork" class="w-full flex items-center">
                                        <loader-icon />
                                        <span class="ml-2 text-lg">Checking connection to {{ config.l2NetworkLabel }}</span>
                                    </div>
                                    <div v-else-if="networkError" class="flex space-x-2 items-center  text-red-500">
                                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                        </svg>
                                        <p class="text-center text-lg">Please connect to {{ config.l2NetworkLabel }}</p>
                                        <a
                                            href="//medium.com/cometh/10-9-ignition-5-4-3-2-1-0-all-engines-running-lift-off-d3e1878f93f2"
                                            target="_blank"
                                            class="bg-gold text-black rounded px-4 py-2 "
                                        >
                                            Help
                                        </a>
                                    </div>
                                    <div v-else-if="checkingSpaceships" class="flex items-center">
                                        <loader-icon />
                                        <span class="text-center text-lg ml-2">Loading spaceships</span>
                                    </div>
                                    <div v-else-if="spaceshipsError" class="w-full">
                                        <div class="flex space-x-2 items-center text-red-500">
                                            <svg
                                                class="w-5 h-5"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>

                                            <p class="text-center text-lg">No spaceships on {{ config.l2NetworkLabel }}</p>

                                            <a
                                                href="//game.cometh.io/shop"
                                                target="_blank"
                                                class="bg-gold hover:bg-yellow-400 text-black rounded px-4 py-2 "
                                            >
                                                Get Spaceships
                                            </a>
                                        </div>
                                    </div>
                                    <div v-else-if="gameError" class="flex w-full items-center text-red-500">
                                        <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                        <span class="ml-2 text-lg">No spacehips in game</span>
                                    </div>
                                </transition>
                                <div>
                                    <div
                                        v-if="clickedPlay"
                                        class="relative w-64 h-24 border border-blue-500 border-b-4 rounded-sm transition duration-700"
                                    >
                                        <div class="absolute left-0 top-0 h-full bg-blue-400" :style="{ width: loadProgress + '%' }"></div>
                                    </div>
                                    <button
                                        v-else
                                        @click="$emit('ready-to-play')"
                                        class="w-64 h-24 border-b-4 rounded-sm  text-2xl transition duration-700"
                                        :class="[
                                            canPlay
                                                ? 'text-white bg-blue-500 border-blue-700 hover:bg-blue-400  hover:border-blue-600'
                                                : 'bg-gray-700 border-gray-800 cursor-not-allowed'
                                        ]"
                                        :disabled="!canPlay"
                                    >
                                        <svg
                                            v-if="clickedPlay"
                                            class="animate-spin h-4 w-4 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path
                                                class="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                        <span v-else :class="{ 'text-gray-800': !canPlay }">
                                            ENTER GALAXY
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER -->
        <div class="border-white border-dotted border-t-2 pt-4 pb-5">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
                <div class="text-right">
                    <!-- <a class="hover:underline" href="https://cometh.io">website</a> ·
                    <a class="hover:underline" href="https://bridge.cometh.io">bridge</a> -->
                    <button
                        v-if="connectedAccount"
                        @click="clearCacheProvier()"
                        class="ml-6 inline-flex items-center  rounded-sm bg-y bg-yellow-500 text-black hover:bg-yellow-300 px-3 py-1 text-sm"
                    >
                        Disconnect Wallet
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./Welcome.ts"></script>

<style scoped>
.gradient {
    background-image: linear-gradient(23deg, #000000 7%, #02192b 100%);
}
.glow {
    text-shadow: 0 0 14px #269bf5;
}

.fade-enter-from {
    opacity: 0;
    transform: translateX(20px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease, transform 1s ease;
}

.fade-leave-to {
    opacity: 0;
    transform: translateX(-20px);
}

.hide-scrollbar {
    overflow-y: scroll;

    overflow-x: hidden;
}
.hide-scrollbar::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0.1);

    border-radius: 10px;

    width: 10px;
}
.hide-scrollbar::-webkit-scrollbar-thumb {
    background-color: #57c2ff;
    box-shadow: inset -2px -2px 0 0 rgba(42, 108, 176, 0.52), inset 2px 2px 0 0 rgba(255, 255, 255, 0.5);
    border-radius: 10px;

    /* border: 5px solid white; */
}

.hide-scrollbar::-webkit-scrollbar-thumb:vertical {
    height: 20px !important;

    width: 20px;
}
</style>
