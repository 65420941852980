import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import BN from 'bn.js'
import Web3 from 'web3'
import NotificationService from '../NotificationService'
import { Transaction } from '@/game/models/Transaction'
import PlayerService from '../PlayerService'
import { Notification } from '@/game/models/notification/Notification'
import { GasStationService } from '@/game/services/GasStationService'

import SessionTransactionNotifService from '../SessionTransactionService'
import { Web3Helper } from '@/helpers/Web3Helper'

export default class JumpManagerService {
    public static JUMPED_EVENT = 'jumped'
    public static PULLPRICE_CHANGED_EVENT = 'PULLPRICE_CHANGED_EVENT'
    public static MAX_JUMP_PATH_LENGTH = 10

    public static instance: JumpManagerService

    public static getInstance(): JumpManagerService {
        if (!this.instance) {
            this.instance = new JumpManagerService()
        }
        return this.instance
    }

    private abi = [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'targetId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'fee',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint32',
                    name: 'newDistance',
                    type: 'uint32'
                },
                {
                    indexed: false,
                    internalType: 'int128',
                    name: 'newAngle',
                    type: 'int128'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'updateTime',
                    type: 'uint256'
                }
            ],
            name: 'Jumped',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256'
                }
            ],
            name: 'PullPriceChanged',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'targetId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'jump',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256[]',
                    name: 'targetIds',
                    type: 'uint256[]'
                },
                {
                    internalType: 'uint256[]',
                    name: 'amounts',
                    type: 'uint256[]'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'batchjump',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'changePullingPrice',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newStore',
                    type: 'address'
                }
            ],
            name: 'updateSolarSystemStore',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newGateway',
                    type: 'address'
                }
            ],
            name: 'updateGateway',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newMustManager',
                    type: 'address'
                }
            ],
            name: 'updateMustManager',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newFeeReceiver',
                    type: 'address'
                }
            ],
            name: 'updateFeeReceiver',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'newFee',
                    type: 'uint256'
                }
            ],
            name: 'updatePullingPercentageFee',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'newFee',
                    type: 'uint256'
                }
            ],
            name: 'updatePullingMinFee',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'solarSystemID',
              type: 'uint256'
            }
          ],
          name: 'getPullingMinFee',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256'
            }
          ],
          stateMutability: 'view',
          type: 'function'
        }
    ]

    private writeJumpManager: any
    private wssJumpManager: any

    constructor() {
        this.initContract()
        this.listenPullEvent()
        this.listentPullPriceChanged()
    }

    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        this.writeJumpManager = new web3.eth.Contract(this.abi, config.jumpManagerAddress, { gasLimit: '1000000' })

        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.wssJumpManager = new wssWeb3.eth.Contract(this.abi, config.jumpManagerAddress)
    }

    public async batchjump(minerId: string, targetIds: string[], prices: string[], solarSystemID: number, gasPriceGWEI?: number) {
        const account: string = await Web3Service.getInstance().getAccount()
        if (!gasPriceGWEI) {
            const gasPrices = await GasStationService.getInstance().getPrices()
            gasPriceGWEI = gasPrices.fast
        }

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.writeJumpManager.methods
                .batchjump(minerId, targetIds, prices, solarSystemID)
                .send({ from: account, gasLimit: '4500000', gasPrice: Web3Helper.gweiToWei(gasPriceGWEI!) })
                .on('error', (error: any) => {
                    reject(error)
                    notif.title = 'Pulling failed.'
                    tx.onError()
                })
                .on('receipt', (data: any) => {
                    document.dispatchEvent(
                        new CustomEvent(JumpManagerService.JUMPED_EVENT, {
                            detail: { minerId: minerId, solarSystemID: solarSystemID }
                        })
                    )
                    notif.title = 'Pulling succeed!'
                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif = NotificationService.getInstance().addFromTransaction(tx, () => {
                        PlayerService.getInstance().updateSpiceBalance()
                    })
                    notif.title = 'Pulling in progress'
                    resolve(data)
                })
        })
    }

    public async listentPullPriceChanged() {
        await this.wssJumpManager.events
            .PullPriceChanged()
            .on('data', function(event: any) {
                document.dispatchEvent(new CustomEvent(JumpManagerService.PULLPRICE_CHANGED_EVENT, { detail: event.returnValues }))
            })
            .on('error', function(error: any) {
                console.log('ERROR: listentPullPriceChanged', error)
            })
    }

    public async listenPullEvent() {
        await this.wssJumpManager.events
            .Jumped()
            .on('data', function(event: any) {
                document.dispatchEvent(
                    new CustomEvent(JumpManagerService.JUMPED_EVENT, {
                        detail: { minerId: event.returnValues.minerId, solarSystemID: event.returnValues.solarSystemID }
                    })
                )
                const notifTx = SessionTransactionNotifService.getInstance().transactions.find(it => it.transaction.hash == event.transactionHash)
                if (!notifTx) return
                notifTx.title = 'Pulling succeed!'
                notifTx.transaction.onSuccess()
            })
            .on('error', function(error: any) {
                console.log('ERROR: listenPullEvent', error)
            })
    }

    public async getPullingMinFee(solarSystemID: number): Promise<string> {
      return await this.wssJumpManager.methods.getPullingMinFee(solarSystemID).call()
    }

    public async changePullingPrice(minerId: string, price: number, solarSystemID: number) {
        const account: string = await Web3Service.getInstance().getAccount()

        const weiPrice = Web3.utils.toWei(price.toString(), 'ether')

        return new Promise((resolve, reject) => {
            const tx = new Transaction()
            let notif: Notification = new Notification()

            this.writeJumpManager.methods
                .changePullingPrice(minerId, weiPrice, solarSystemID)
                .send({ from: account, gasPrice: '50000000000' })
                .on('error', (error: any) => {
                    reject(error)
                    notif.title = 'Pull Price update failed.'
                    tx.onError()
                })
                .on('receipt', (data: any) => {
                    PlayerService.getInstance().miner.pullingPrice = new BN(weiPrice)
                    document.dispatchEvent(new Event('pullPriceUpdated'))
                    notif.title = 'Pull Price updated !'
                    tx.onSuccess()
                })
                .on('transactionHash', (data: any) => {
                    tx.hash = data
                    notif = NotificationService.getInstance().addFromTransaction(tx)
                    notif.title = 'Pull Price update...'
                    resolve(price)
                })
        })
    }
}
