import Miner from '../models/Miner'
import BN from 'bn.js'
import SpiceService from '@/game/services/web3/SpiceService'
import config from '@/config'
import Web3Service from './Web3Service'
import RentedMiner from '../models/RentedMiner'

export default class PlayerService {
    public static instance: PlayerService

    public static getFinalJumpCost(to: Miner) {
        //TODO JUMP
        //return to.jumpCost
        return to.pullingPrice
    }

    public static getInstance(): PlayerService {
        if (!this.instance) {
            this.instance = new PlayerService()
        }

        return this.instance
    }

    constructor() {
        this.account = Web3Service.getInstance().connectedAccount
        this.updateSpiceBalance()
        setInterval(() => {
            this.updateSpiceBalance()
        }, 10000)
    }

    public miningPlayers: Miner[] = [new Miner({ id: 0 })]
    public rentedMiners: RentedMiner[] = new Array<RentedMiner>()

    public miner: Miner = this.miningPlayers[0]

    public spices: BN = new BN(0)
    public allowance: BN = new BN(0)

    private oldSpicesValue = this.spices
    private account = ''

    public async updateSpiceBalance() {
        const balance = await SpiceService.getInstance().getBalance(this.account)
        const allowance = await SpiceService.getInstance().getAllowance(this.account, config.mustManagerAddress)
        this.spices = new BN(balance)
        this.allowance = new BN(allowance)
        document.dispatchEvent(new Event('onSpiceChanged'))
    }

    public async approveGame(price: number) {
        await SpiceService.getInstance().approve(config.mustManagerAddress, price)
        this.updateSpiceBalance()
    }

    public setOnCurrentPlayerChange(callback: (miner: Miner) => void) {
        document.addEventListener('onPlayerShipChanged', () => {
            callback(this.miner)
        })
    }

    public onShipCountChanged(callback: (ships: Miner[]) => void) {
        document.addEventListener('onShipCountChanged', () => {
            callback(this.miningPlayers)
        })
    }
    public removeOnCurrentPlayerChange(callback: (miner: Miner) => void) {
        document.removeEventListener('onPlayerShipChanged' as any, callback)
    }

    public updateMainPlayer(player: Miner) {
        this.miner = player

        document.dispatchEvent(new Event('onPlayerShipChanged'))
        document.dispatchEvent(new Event('pullPriceUpdated'))
        document.dispatchEvent(new Event('onShipCountChanged'))
    }

    public setOnSpiceChange(callback: (spice: BN, changeAmount: BN) => void) {
        document.addEventListener('onSpiceChanged', () => {
            const change = this.oldSpicesValue.sub(this.spices)
            if (!change.isZero()) {
                callback(this.spices, change)
            }
            this.oldSpicesValue = this.spices
        })
    }
}
