import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import Miner from '../../models/Miner'

export default class MinerManagerService {
    public static instance: MinerManagerService

    public static getInstance(): MinerManagerService {
        if (!this.instance) {
            this.instance = new MinerManagerService()
        }
        return this.instance
    }

    private minerManagerAbi = [
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'cometId',
                    type: 'address'
                }
            ],
            name: 'deposeRover',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                }
            ],
            name: 'roverOn',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'getMiner',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'orbit',
                            type: 'tuple'
                        },
                        {
                            internalType: 'uint256',
                            name: 'pullingPrice',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'lastAction',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint8',
                            name: 'miningArea',
                            type: 'uint8'
                        },
                        {
                            internalType: 'uint256',
                            name: 'maxSpeed',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'cooldown',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'roverPower',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'drill',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'solarSystemID',
                            type: 'uint256'
                        },
                        {
                            internalType: 'address',
                            name: 'roverOn',
                            type: 'address'
                        }
                    ],
                    internalType: 'struct IMinerManagerV4.Miner',
                    name: '',
                    type: 'tuple'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'minerId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'time',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'minerPosition',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'int256',
                            name: 'x',
                            type: 'int256'
                        },
                        {
                            internalType: 'int256',
                            name: 'y',
                            type: 'int256'
                        }
                    ],
                    internalType: 'struct PositionsV2.Cartesian',
                    name: 'position',
                    type: 'tuple'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'start',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'minersPaginated',
            outputs: [
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'id',
                            type: 'uint256'
                        },
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address'
                        },
                        {
                            components: [
                                {
                                    components: [
                                        {
                                            internalType: 'int256',
                                            name: 'x',
                                            type: 'int256'
                                        },
                                        {
                                            internalType: 'int256',
                                            name: 'y',
                                            type: 'int256'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Cartesian',
                                    name: 'center',
                                    type: 'tuple'
                                },
                                {
                                    components: [
                                        {
                                            internalType: 'uint32',
                                            name: 'distance',
                                            type: 'uint32'
                                        },
                                        {
                                            internalType: 'int128',
                                            name: 'angle',
                                            type: 'int128'
                                        }
                                    ],
                                    internalType: 'struct PositionsV2.Polar',
                                    name: 'last',
                                    type: 'tuple'
                                },
                                {
                                    internalType: 'int32',
                                    name: 'rotationSpeed',
                                    type: 'int32'
                                },
                                {
                                    internalType: 'uint256',
                                    name: 'lastUpdate',
                                    type: 'uint256'
                                }
                            ],
                            internalType: 'struct PositionsV2.Orbit',
                            name: 'orbit',
                            type: 'tuple'
                        },
                        {
                            internalType: 'uint256',
                            name: 'pullingPrice',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'lastAction',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint8',
                            name: 'miningArea',
                            type: 'uint8'
                        },
                        {
                            internalType: 'uint256',
                            name: 'maxSpeed',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'cooldown',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'roverPower',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'drill',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'solarSystemID',
                            type: 'uint256'
                        },
                        {
                            internalType: 'address',
                            name: 'roverOn',
                            type: 'address'
                        }
                    ],
                    internalType: 'struct IMinerManagerV4.Miner[]',
                    name: '',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'solarSystemID',
                    type: 'uint256'
                }
            ],
            name: 'countMinerIn',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        }
    ]

    private readMinerManager: any
    private loadMinerManager: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readMinerManager = new wssWeb3.eth.Contract(this.minerManagerAbi, config.minerManagerAddress)

        const readWeb3 = Web3Service.getInstance().getReadWeb3()
        this.loadMinerManager = new readWeb3.eth.Contract(this.minerManagerAbi, config.minerManagerAddress)
    }

    private createLoadMinerPromise(start: number, pageSize: number, solarSystemID: number, minersList: Array<Miner>): Promise<any> {
        return new Promise(resolve => {
            this.loadMinerManager.methods
                .minersPaginated(start, pageSize, solarSystemID)
                .call()
                .then((miners: any) => {
                    minersList.push(...miners)
                    resolve(miners)
                })
        })
    }
    public async countMinerIn(solarSystemID: number): Promise<string> {
        return await this.readMinerManager.methods.countMinerIn(solarSystemID).call()
    }

    public async minersInSolarSystem(solarSystemID: number): Promise<Miner[]> {
        try {
            //console.log('')
            //console.log('')
            // console.log('##########')
            console.log('Loading Solar System ID', solarSystemID)
            const length = await this.loadMinerManager.methods.countMinerIn(solarSystemID).call()
            //console.log('Number of miner: ', length)
            const minersList = Array<Miner>()
            const pageSize = 25

            const promises = []
            const iteration = Math.floor(length / pageSize)
            const last = length % pageSize

            //console.log('Total : ', iteration, 'page size', pageSize, 'last', last, 'recap', pageSize * iteration + last)
            for (let i = 0; i < iteration; i++) {
                promises.push(this.createLoadMinerPromise(i * pageSize, pageSize, solarSystemID, minersList))
            }
            promises.push(this.createLoadMinerPromise(iteration * pageSize, last, solarSystemID, minersList))

            const callNumber = promises.length
            //console.log('Call Numeber', callNumber, 'Total ', iteration + 1)
            const simultanousCall = 10
            const numberOfRun = Math.trunc(callNumber / simultanousCall)

            //console.log('Number of Run', numberOfRun)
            for (let j = 0; j < numberOfRun; j++) {
                const start = j * simultanousCall
                const end = (j + 1) * simultanousCall - 1
                //console.log('Loop', start, end)
                //console.log('Miner', start * pageSize, end * pageSize)
                await Promise.all(promises.slice(start, end))
            }

            const finish = numberOfRun * simultanousCall

            // console.log('Outside Loop Miner', finish * pageSize)
            const remain = promises.slice(finish)
            //console.log('Outside Loop', finish, finish + remain.length)
            await Promise.all(remain)

            //console.log('Final number of miner', minersList.length)
            return minersList
        } catch (error) {
            console.log(error)
        }
        return []
    }

    public async getMiner(minerId: string, solarSystemID: number): Promise<[any]> {
        //console.log('GetMiner', minerId)
        return await this.readMinerManager.methods.getMiner(minerId, solarSystemID).call()
    }
}
