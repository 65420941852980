import { ShopItem } from '@/views/Shop/ShopItemList'
import shop from '@/data/shop'

export default class ShopData {
    public static instance: ShopData

    public static getInstance(): ShopData {
        if (!this.instance) {
            this.instance = new ShopData()
        }
        return this.instance
    }

    shopItems: ShopItem[] = []

    constructor() {
        shop.data.forEach(obj => {
            const shopitem = new ShopItem()
            shopitem.name = obj.name
            shopitem.miningRange = obj.miningRange
            shopitem.miningPower = obj.miningPower
            shopitem.price = obj.price
            shopitem.desc = obj.desc
            shopitem.coolDown = obj.coolDown
            shopitem.url = obj.url
            shopitem.imgUrl = obj.imgUrl
            shopitem.description = obj.description
            shopitem.id = obj.id
            shopitem.soldout = obj.soldout
            this.shopItems.push(shopitem)
        })
    }

    getItems() {
        return this.shopItems
    }
}
