<template>
    <div class="bg-white mobile:px-4">
        <div
            class="
                max-w-[1140px]
                mx-auto
                pt-[154px]
                mobile:pt-[64px]
                pb-[106px]
                mobile:pb-[46px]
            "
        >
            <div class="grid grid-cols-12 gap-[30px] items-center">
                <div
                    class="
                        col-start-2
                        mobile:col-start-1
                        col-span-4
                        mobile:col-span-12
                    "
                >
                    <h3
                        class="
                            font-display font-bold
                            text-[40px]
                            mobile:text-[30px]
                            leading-[44px]
                            uppercase
                        "
                    >
                        A unique event
                    </h3>
                    <p class="mt-[14px] font-body leading-[26px]">
For the first time in the history of blockchain, thousands of players will be competiting for ~$150,000 over a smart contract and token based game. Cometh with the support of Polygon will be kickstarting the new era of electronic sports, relying on universality, transparency, fairness and innovative content.
                    </p>
                </div>
                <div
                    class="
                        col-start-7
                        mobile:col-start-1
                        col-span-5
                        mobile:col-span-12
                    "
                >
                    <div class="folded relative">
                        <img
                            class="w-full"
                            src="@/assets/tournament/game-screenshot.png"
                            alt="Asteroid"
                        />
                        <div
                            class="
                                absolute
                                z-10
                                bottom-0
                                right-0
                                w-[18px]
                                h-[18px]
                                border-b-2 border-r-2 border-purple
                            "
                        ></div>
                    </div>
                </div>
            </div>
            <div
                class="
                    relative
                    grid grid-cols-12
                    gap-[30px]
                    items-center
                    mt-[144px]
                    mobile:mt-[60px]
                    pb-32
                "
            >
                <div
                    class="
                        mobile:order-last
                        col-start-2
                        mobile:col-start-1
                        col-span-4
                        mobile:col-span-12
                    "
                >
                    <img
                        src="@/assets/tournament/asteroid_card.png"
                        alt="Asteroid"
                    />
                </div>

                <div
                    class="
                        col-start-7
                        mobile:col-start-1
                        col-span-5
                        mobile:col-span-12
                    "
                >
                    <h3
                        class="
                            font-display font-bold
                            text-[40px]
                            mobile:text-[30px]
                            leading-[44px]
                            uppercase
                        "
                    >
                        $150.000 Prize Pool
                    </h3>

                    <ul class="flex space-x-20 mt-9 mobile:mt-7">
                        <li>
                            <div
                                class="
                                    font-pixel
                                    text-[22px]
                                    leading-[22px]
                                    text-purple
                                "
                            >
                                5800
                            </div>
                            <div
                                class="mt-1.5 font-display uppercase leading-5"
                            >
                                Seats
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    font-pixel
                                    text-[22px]
                                    leading-[22px]
                                    text-purple
                                "
                            >
                                3 x 4 hours
                            </div>
                            <div
                                class="mt-1.5 font-display uppercase leading-5"
                            >
                                of competition
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    font-pixel
                                    text-[22px]
                                    leading-[22px]
                                    text-purple
                                "
                            >
                                3
                            </div>
                            <div
                                class="mt-1.5 font-display uppercase leading-5"
                            >
                                Historical NFT
                            </div>
                        </li>
                    </ul>

                    <div
                        class="
                            mt-10
                            relative
                            w-[263px]
                            col-start-2 col-span-10
                            border-b-2 border-[#CDD1D5] border-opacity-40
                        "
                    >
                        <img
                            class="absolute -top-1 right-[23px]"
                            src="@/assets/tournament/ic_separator.svg"
                            alt=""
                        />
                    </div>

                    <p class="mt-7 font-body leading-[26px]">
                       Distributed over three sessions of increasing spaceship categories and rewards. Beginners can qualify for the events through the Cometh Academy, invitation can be unlocked by staking ⚗️ MUST or owning a rare Spaceship.
                    </p>
                </div>
                <img
                    class="
                        absolute
                        bottom-4
                        -right-20
                        mobile:left-0 mobile:w-[124px]
                    "
                    src="@/assets/tournament/ic_polygon.svg"
                    alt="Polygon Icon"
                />
            </div>

            <div class="mobile:mt-10 grid grid-cols-12 gap-[30px] items-center">
                <div
                    class="
                        col-start-2
                        mobile:col-start-1
                        col-span-10
                        mobile:col-span-12
                    "
                >
                    <div
                        class="
                            border-t border-b border-[#021A2D] border-opacity-10
                            pt-[17px]
                            pb-[14px]
                            cursor-pointer
                        "
                    >
                        <div
                            @click="showRules = !showRules"
                            class="
                                cursor-pointer
                                font-display font-bold
                                text-2xl
                                leading-[44px]
                                uppercase
                                flex
                                justify-between
                            "
                        >
                            POLYGON GALACTIC GRAND PRIX PROGRAM
                            <img
                                :class="{
                                    'transform rotate-180 ': showRules
                                }"
                                class="transition-transform"
                                src="@/assets/tournament/ic_chevron_down.svg"
                            />
                        </div>

                        <transition name="fade">
                            <p
                                v-if="showRules"
                                class="mt-4 pb-9 text-[#021A2D] leading-[26px]"
                            >
                            <ul>
                                <li>
                                <span class="text-purple">June 22nd and onward:</span> distribution of Polygon Galactic Cup tickets through Cometh Academy and through the Cometh TUBE for the Cup, Trophy and Grand Prix
                                </li>
                                <li>
                                 <span class="text-purple">July the 10th at 2pm UTC:</span> Polygon Galactic Cup, 1st stop on the road to the Grand Prix,  reserved to common Spaceships and their 4,000 pilots competing for ~$40,000 in Polygon based tokens. The Top 500 players receive a ticket for the Trophy
                                </li>

                                 <li>
                                 <span class="text-purple">July the 17th at 2pm UTC:</span> Polygon Galactic Trophy, last stop before the Grand Prix,  reserved to uncommon Spaceships and below for 1,500 players competing for ~$50,000 in Polygon based tokens. The Top 100 players receive a ticket for the Grand Prix
                                </li>
                                 <li>
                                 <span class="text-purple">July the 25th at 2pm UTC:</span> Polygon Galactic Grand Prix, reserved to the 300 best players piloting any Spaceship to compete for ~$60,000 in Polygon based tokens and incredible special awards of the best pilots of the season
                                </li>
                            </ul>
                            </p>
                        </transition>
                    </div>
                </div>
            </div>

            <div class="mt-[90px] mobile:mt-[60px]">
                <h3
                    class="
                        font-display
                        text-[40px]
                        mobile:text-[30px]
                        leading-[44px]
                        font-bold
                        text-center
                        uppercase
                    "
                >
                    SUMMER 2021 CLIMAX
                </h3>
                <div
                    class="
                        grid grid-cols-12
                        gap-[30px]
                        mt-[58px]
                        mobile:mt-[40px]
                    "
                >
                    <div
                        class="
                            col-start-3
                            mobile:col-start-1
                            col-span-2
                            mobile:col-span-12
                        "
                    >
                        <div class="text-center">
                            <div
                                class="
                                    text-purple
                                    font-pixel
                                    text-[60px]
                                    leading-[60px]
                                "
                            >
                                $40,000
                            </div>
                            <div
                                class="
                                    font-display
                                    text-[20px]
                                    leading-5
                                    mt-[18px]
                                "
                            >
                                <span class="">10th of July<br /></span>
                                <span class="text-[20px]"
                                    >Polygon Galactic Cup</span
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="
                            col-start-6
                            mobile:col-start-1
                            col-span-2
                            mobile:col-span-12
                        "
                    >
                        <div class="text-center">
                            <div
                                class="
                                    text-purple
                                    font-pixel
                                    text-[60px]
                                    leading-[60px]
                                "
                            >
                                $50,000
                            </div>
                            <div
                                class="
                                    font-display
                                    text-[20px]
                                    leading-5
                                    mt-[18px]
                                "
                            >
                                <span class="">17th of July<br /></span>
                                <span class="text-[20px]"
                                    >Polygon Galactic Trophy</span
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="
                            col-start-9
                            mobile:col-start-1
                            col-span-2
                            mobile:col-span-12
                        "
                    >
                        <div class="text-center">
                            <div
                                class="
                                    text-purple
                                    font-pixel
                                    text-[60px]
                                    leading-[60px]
                                "
                            >
                                $60,000
                            </div>
                            <div
                                class="
                                    font-display
                                    text-[20px]
                                    leading-5
                                    mt-[18px]
                                "
                            >
                                <span class="">25th of July<br /></span>
                                <span class="text-[20px]"
                                    >Polygon Galactic Grand Prix</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="grid grid-cols-12 gap-[30px] mt-16 mobile:mt-[66px]"
                >
                    <div
                        class="
                            relative
                            col-start-2
                            mobile:col-start-1
                            col-span-10
                            mobile:col-span-12
                            border-b-2 border-[#CDD1D5] border-opacity-40
                        "
                    >
                        <img
                            class="
                                absolute
                                -top-1
                                right-[88px]
                                mobile:right-[35px]
                            "
                            src="@/assets/tournament/ic_separator.svg"
                            alt=""
                        />
                    </div>
                </div>
                <div class="mt-12 flex justify-center">

                <ButtonPrimary @click="$emit('buy')" class="ml-5 w-[238px] h-[48px]">
                    Play
                </ButtonPrimary>

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import ButtonSecondary from "@/components/ButtonSecondary.vue";

@Options({
    components: { ButtonPrimary, ButtonSecondary }
})
export default class Rules extends Vue {
    showRules = false;
}
</script>

<style scoped>
.folded:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom: 45px solid white;
    border-left: 45px solid rgba(0, 0, 0, 0);
    width: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
