import * as PIXI from 'pixi.js'
import Colors from '@/helpers/Colors'
import ModalComponent from './ModalComponent'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import ScrollableContainer from '@/helpers/ScrollHelper'
import TextButton from '../TextButton'
import Resolution from '@/helpers/Resolution'
import TutorialService, { HelpChapter } from '@/game/services/TutorialService'

export default class HelpModal extends ModalComponent {
    public detailContainer = new PIXI.Container()

    constructor() {
        super(140, 22 + TutorialService.chapters.length * 26 + 4)
        this.hPosistion = 51

        const title = new MainBitmapText('Help', { fontSize: 8 })
        title.x = Math.floor(this.modalWidth / 2 - title.width / 2)
        title.y = 6

        this.modalContainer.addChild(this.modalBg)
        this.modalContainer.addChild(this.top)
        this.modalContainer.addChild(this.left)
        this.modalContainer.addChild(this.right)
        this.modalContainer.addChild(this.bottom)
        this.modalContainer.addChild(title)
        this.modalContainer.addChild(this.detailContainer)
        this.container.addChild(this.modalContainer)
        this.setPositions()

        this.detailContainer.x = this.modalWidth + 12

        let currY = 22
        TutorialService.chapters.forEach(tuto => {
            const container = new PIXI.Container()
            const bg = PIXI.Sprite.from('pixel')
            bg.tint = Colors.Blue400
            bg.width = this.modalWidth - 2
            bg.height = 26

            container.interactive = true
            container.cursor = 'pointer'
            container.on('mouseover', () => {
                bg.tint = Colors.Blue500
                this.displayTutorialDetail(tuto)
            })
            container.on('mouseout', () => {
                bg.tint = Colors.Blue400
                this.destroyTutorialDetail()
            })

            const icon = PIXI.Sprite.from(tuto.icon)
            icon.x = 6
            icon.y = 3

            const title = new MainBitmapText(tuto.title, { fontSize: 5 })
            title.x = icon.x + icon.width + 4
            title.y = Math.floor(bg.height / 2 - title.height / 2)

            const icHelp = PIXI.Sprite.from('ic_help_circle')
            icHelp.x = this.modalWidth - 16
            icHelp.y = 7

            container.x = 1
            container.addChild(bg)
            container.addChild(icon)
            container.addChild(title)
            container.addChild(icHelp)

            container.y = currY
            currY += 26
            this.modalContainer.addChild(container)
        })

        this.modalContainer.alpha = 0
        this.bg.alpha = 0
    }

    protected setPositions() {
        this.bg.width = Resolution.realWidth
        this.bg.height = Resolution.realHeight
        this.modalContainer.scale.set(Resolution.scale)

        this.modalContainer.position.x = Math.floor(this.hPosistion * Resolution.scale)
        this.modalContainer.position.y = Math.floor(Resolution.realHeight - this.modalHeight * Resolution.scale - 26 * Resolution.scale)
    }

    private displayTutorialDetail(tuto: HelpChapter) {
        const container = new PIXI.Container()

        const bg = PIXI.Sprite.from('pixel')
        bg.width = 110
        bg.tint = Colors.Blue400
        const graphics = new PIXI.Graphics()
        graphics.lineStyle(1, Colors.Blue800)

        const title = new MainBitmapText(tuto.title, { fontSize: 6 })
        title.x = Math.floor(bg.width / 2 - title.width / 2)
        title.y = 6

        container.addChild(bg)
        container.addChild(graphics)
        container.addChild(title)
        let baseY = title.y + title.height + 6
        tuto.chapterDetail.forEach(it => {
            if (it instanceof MainBitmapText) {
                it.x = 6
            } else if (it instanceof PIXI.Sprite) {
                it.x = Math.floor(bg.width / 2 - it.width / 2)
            }
            it.y = baseY

            if (it instanceof PIXI.Sprite || it instanceof MainBitmapText) {
                baseY += it.height + 6
            }
            container.addChild(it)
        })

        const height = container.height + 6
        bg.height = height
        graphics.drawRect(-1, -1, bg.width + 2, height + 2)

        this.detailContainer.addChild(container)
    }

    private destroyTutorialDetail() {
        this.detailContainer.removeChildren()
    }
}
