import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Web3 from 'web3'

import Connection, { ConnectionType } from '@/components/connection/Connection'
import NFTService from '@/game/services/NFTService'
import Web3Service from '@/game/services/Web3Service'
import SpaceShipsService from '@/game/services/web3/SpaceShipsService'
import ConverterService from '@/game/services/web3/ConverterService'
import StakedSpaceshipsService from '@/game/services/web3/StakedSpaceshipsService'
import ClaimableAirdropService from '@/game/services/web3/ClaimableAirdropService'
import TubeService from '@/game/services/web3/TubeService'
import WalletService from '@/game/services/web3/WalletService'
import RentingService from '@/game/services/web3/RentingService'
import ShipService from '@/services/ShipService'
import config from '@/config'

import LoaderIcon from '@/components/LoaderIcon.vue'
import Button from '@/components/button/Button.vue'

class AvailableShip {
    public internalId!: number
    public name!: string
    public sending!: boolean
    public originalId!: number
    public newId!: number
}

@Options({
    components: { LoaderIcon, Button }
})
export default class Welcome extends Vue {
    @Prop()
    public loadProgress = 0

    @Prop()
    public clickedPlay = false

    public connectedAccount = ''
    public networkId = ''
    public config = config

    public names = new Map<number, string>()
    public imagesUrls = new Map<number, string>()

    public availableShips = new Map<number, AvailableShip>()
    public borrowedShips = new Map<number, string>()

    private MATIC_MUST = '0x80676b414a905de269d0ac593322af821b683b92'

    connectedToL2 = false
    shipsInGame: number[] = []
    ownerShipsInGame: number[] = []

    // Steps management
    checkingNetwork = true
    networkError = false

    checkingSpaceships = false
    spaceshipsError = false
    gameError = false

    canPlay = false

    // Passive Reward
    mustOnMainTube = 0
    maxMustOnMainTube = 0
    earnings = 0
    availableToClaim = 0
    availableToClaimDollar = 0
    rewardLastUpdate: Date|null = null

    claiming = false
    claimError = false
    claimErrorMsg = ''
    claimSuccess = false

    get isLoading() {
        return this.checkingNetwork || this.checkingSpaceships
    }

    async mounted() {
        const connection = Connection.getInstance()
        await connection.load()
        Connection.getInstance().on('providerChanged', async provider => {
            if (!provider) {
                this.checkingNetwork = true
                return
            }
            await this.initWeb3()
        })
        await this.initWeb3()
    }

    async initWeb3() {
        const connection = Connection.getInstance()
        if (!connection.isConnected()) {
            this.$emit('notConnected')
            return
        }

        const provider = await Connection.getInstance().provider

        await Promise.all([this.loadConnectedAccount(), this.loadNetworkId()])

        this.subscribeToProviderEvents(provider)
    }

    async loadConnectedAccount() {
        this.connectedAccount = await Web3Service.getInstance().getAccount()
    }


    async loadNetworkId() {
        this.networkError = false
        this.checkingNetwork = true

        if (Connection.getInstance().getConnectionType() == ConnectionType.Metamask) {
            try {
                await (window as any).ethereum.request({
                    id: 1,
                    jsonrpc: '2.0',
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x' + config.network.toString(16),
                            chainName: config.l2NetworkLabel,
                            rpcUrls: [config.rpc],
                            iconUrls: ['https://polygon.technology/wp-content/uploads/2021/01/logo-polygon.png'],
                            nativeCurrency: {
                                name: 'Matic',
                                symbol: 'Matic',
                                decimals: 18
                            },
                            blockExplorerUrls: [config.etherscanUrl]
                        }
                    ]
                })
            } catch (e) {
                // metamask is probably outdated here
                console.log(e)
            }
        }

        this.networkId = await Web3Service.getInstance()
            .getWeb3()
            .eth.net.getId()
        this.connectedToL2 = this.networkId === config.network

        if (this.connectedToL2) {
            this.checkingNetwork = false
        } else {
            this.checkingNetwork = false
            this.networkError = true
        }
    }

    private subscribeToProviderEvents(provider: any) {
        provider.on('chainChanged', () => {
            this.loadNetworkId()
        })

        provider.on('accountsChanged', () => {
            this.loadConnectedAccount()
        })
    }

    @Watch('canPlay')
    async loadRewardInformation() {
      if (!this.canPlay) return

      const decimals = Web3.utils.toBN('1000000000000000000')
      const [mustInMainTube, mustPrice] = await Promise.all([
        TubeService.getInstance().balanceOf(0),
        WalletService.getInstance().mustPrice()
      ])
      const mustInMainTubeDecimal = Web3.utils.toBN(mustInMainTube).div(decimals).toNumber()
      // map transforms to an array... for some reason
      const ships = this.ownerShipsInGame.map(v => v.toString())

      const rarities = ShipService.getInstance().sortRarities(ships)

      const mustPerRarity = ShipService.getInstance().mustPerRarity
      const dollarPerMonthPerRarity = ShipService.getInstance().dollarPerMonthPerRarity

      const neededMustInMainTube = rarities.commons * mustPerRarity.common
        + rarities.uncommons * mustPerRarity.uncommon
        + rarities.rares * mustPerRarity.rare
        + rarities.mythics * mustPerRarity.mythic

      const INITIAL_MUST_PRICE = 60
      const scale = mustPrice / INITIAL_MUST_PRICE

      const calculateEarnings = (r: any) => {
        const perMonth = r.commons * (dollarPerMonthPerRarity.common * scale)
        + r.uncommons * (dollarPerMonthPerRarity.uncommon * scale)
        + r.rares * (dollarPerMonthPerRarity.rare * scale)
        + r.mythics * (dollarPerMonthPerRarity.mythic * scale)

        return perMonth / 30 * 7
      }

      console.log(rarities)

      //const maxEarningsPerWeek = calculateEarnings(rarities)
      const currentOptimal = ShipService.getInstance().findOptimalMustRequirement(mustInMainTubeDecimal, rarities)
      const currentEarningsPerWeek = calculateEarnings(currentOptimal)

      console.log(currentOptimal)

      const [lastUpdate, claimable] = await Promise.all([
        ClaimableAirdropService.getInstance().lastUpdate(),
        ClaimableAirdropService.getInstance().claimable(this.MATIC_MUST),
      ])

      const wssWeb3 = Web3Service.getInstance().getWssWeb3()

      console.log('last update', lastUpdate)

      if (lastUpdate > 0) {
        const lastUpdateBlock = await wssWeb3.eth.getBlock(lastUpdate)
        this.rewardLastUpdate = new Date(lastUpdateBlock.timestamp * 1000)
      }
      this.mustOnMainTube = parseFloat(Web3.utils.fromWei(mustInMainTube, 'ether'))
      this.maxMustOnMainTube = neededMustInMainTube

      console.log(neededMustInMainTube)

      this.earnings = currentEarningsPerWeek

      this.availableToClaim = parseFloat(Web3.utils.fromWei(claimable, 'ether'))

      const maticMustValue = await WalletService.getInstance().USDPriceOf(this.MATIC_MUST)
      this.availableToClaimDollar = this.availableToClaim * maticMustValue
    }

    @Watch('connectedAccount')
    @Watch('checkingNetwork')
    async fetchShips() {
        if (this.checkingNetwork) return
        if (!this.connectedToL2) return

        this.checkingSpaceships = true
        const [shipsOnL2, shipsInGame, erc1155OnL2] = await Promise.all([
            SpaceShipsService.getInstance().tokensOfConnected(),
            StakedSpaceshipsService.getInstance().tokensOfOwner(),
            ConverterService.getInstance().tokensOfConnected()
        ])

        const lending = await RentingService.getInstance().rentingsOfConnected()
        const borrowedShipOnL2List: number[] = []
        const borrowedShipInGameList: number[] = []
        for (let i = 0; i < lending.length; i++) {
            const [borrowedOnL2, borrowedInGame] = await Promise.all([
                SpaceShipsService.getInstance().tokensOf(lending[i].id),
                StakedSpaceshipsService.getInstance().tokensOf(lending[i].id)
            ])
            borrowedShipOnL2List.push(...borrowedOnL2)
            borrowedShipInGameList.push(...borrowedInGame)
            for (let j = 0; j < borrowedOnL2.length; j++) {
                this.borrowedShips.set(borrowedOnL2[j], lending[i].id)
            }
        }

        this.ownerShipsInGame = shipsInGame
        this.shipsInGame = shipsInGame.concat(borrowedShipInGameList)

        await Promise.all([
            ...shipsOnL2.map(async id => {
                const metadata = await NFTService.getInstance().getMetadata(id.toString())
                const name = metadata.data.name
                const ship = new AvailableShip()
                ship.internalId = id
                ship.name = name
                this.imagesUrls.set(ship.internalId, metadata.data.image)
                this.availableShips.set(ship.internalId, ship)
            }),
            ...borrowedShipOnL2List.map(async id => {
                const metadata = await NFTService.getInstance().getMetadata(id.toString())
                const name = metadata.data.name
                const ship = new AvailableShip()
                ship.internalId = id
                ship.name = name
                this.imagesUrls.set(ship.internalId, metadata.data.image)
                this.availableShips.set(ship.internalId, ship)
            }),
            ...this.shipsInGame.map(async id => {
                const metadata = await NFTService.getInstance().getMetadata(id.toString())
                const name = metadata.data.name
                this.imagesUrls.set(id, metadata.data.image)
                this.names.set(id, name)
            })
        ])

        for (const id of erc1155OnL2) {
            const theId = id[1][0].toString()
            const metadata = await NFTService.getInstance().getModelMetadata(theId)

            const name = metadata.name
            this.names.set(id[1][0], name)
            this.imagesUrls.set(id[1][0], metadata.image)
            for (let i = 0; i < id[1].length; i++) {
                const ship = new AvailableShip()
                ship.internalId = id[1][0] * 1000000 + i
                ship.newId = id[1][0]
                ship.originalId = id[0]
                ship.name = name
                this.availableShips.set(ship.internalId, ship)
            }
        }

        if (!this.availableShips.size && !this.shipsInGame.length) {
            this.checkingSpaceships = false
            this.spaceshipsError = true
            return
        }
        if (!this.shipsInGame.length) {
            this.checkingSpaceships = false
            this.spaceshipsError = false
            this.gameError = true
            return
        }
        this.checkingSpaceships = false
        this.spaceshipsError = false
        this.gameError = false
        this.canPlay = true
    }

    async send(ship: AvailableShip) {
        ship.sending = true
        try {
            switch (this.sourceOfShip(ship)) {
                case 'borrowed': {
                    const contractAddress = this.borrowedShips.get(ship.internalId)
                    if (contractAddress == undefined) return

                    await RentingService.getInstance().stake(contractAddress, ship.internalId, 1)
                    this.names.set(ship.internalId, ship.name)
                    this.shipsInGame.push(ship.internalId)
                    break
                }
                case 'converted': {
                    await ConverterService.getInstance().erc1155SafeTransferFrom(config.converterAddress, ship.originalId.toString(), 1)
                    this.shipsInGame = [...this.shipsInGame, ship.newId]
                    break
                }
                default: {
                    const web3 = Web3Service.getInstance().getWeb3()
                    await SpaceShipsService.getInstance().safeTransferFrom(
                        config.stackedSpaceshipAddress,
                        ship.internalId.toString(),
                        web3.eth.abi.encodeParameter('uint256', 1)
                    )
                    this.names.set(ship.internalId, ship.name)
                    this.shipsInGame = [...this.shipsInGame, ship.internalId]
                    break
                }
            }
            this.availableShips.delete(ship.internalId)
            this.gameError = false
            this.canPlay = true
        } catch (err) {
            console.error(err)
        }
        ship.sending = false
    }

    private sourceOfShip(ship: AvailableShip) {
        if (this.borrowedShips.has(ship.internalId)) return 'borrowed'
        if (ship.originalId) return 'converted'
        return 'original'
    }

    async claim() {
        this.claiming = true

        try {
          await ClaimableAirdropService.getInstance().claim([this.MATIC_MUST])
          this.availableToClaim = 0
          this.availableToClaimDollar = 0
          this.claimSuccess = true
        } catch(err) {
          console.error('could not claim passive reward', err)
        }

        this.claiming = false
    }
}
