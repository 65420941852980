export default class Item {
    constructor(public id = 0, public name = '', public description = '', public icon = '') {}

    static coolingBooster = new Item(
        0,
        'Mining Cooler',
        'Cooling Down is too slow?\nUse this amazing Mining Cooler\nYour ship is ready to mine again\n in the blink of an eye.',
        'ic_cooling_booster'
    )

    static grandPrixTicket = new Item(3, 'Galactic Grand Prix Ticket', 'Use this ticket when crossing\nthe portal to the stadium', 'item_cup_ticket')
}
