import Resolution from '@/helpers/Resolution'
import GameElement from './GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import PlayerService from './services/PlayerService'
import Miner from './models/Miner'
import MinerPlayerComponent from './components/MinerPlayerComponent'
import GTilingSprite from './pixi-scale/GTilingSprite'
import MiniMapComponent from './components/MiniMapComponent'
import CometComponent from './components/CometComponent'
import Comete from './models/Comete'

export default class GMain implements GameElement {
    private container: PIXI.Container = new PIXI.Container()
    private miningContainer: PIXI.ParticleContainer = new PIXI.ParticleContainer(1500, {})
    private minimap: MiniMapComponent = new MiniMapComponent()
    private bg!: GTilingSprite
    private bg2 = new GTilingSprite('bg2')
    private bg3 = new GTilingSprite('bg3')
    private currentMiner: Miner = PlayerService.getInstance().miner
    private minerComponent: MinerPlayerComponent = new MinerPlayerComponent(this.currentMiner)
    private cometComponent!: CometComponent

    constructor(public pixi: PIXI.Application, public onMapExtand: () => void) {
        this.bg = new GTilingSprite('bg') as GTilingSprite
        this.container.addChild(this.bg)
        this.container.addChild(this.bg2)
        this.container.addChild(this.bg3)

        this.cometComponent = new CometComponent(new Comete({ id: 0 }))
        this.container.addChild(this.minimap.container)
        this.container.addChild(this.cometComponent.getContainer())

        this.minimap.onMapExtand = () => {
            this.onMapExtand()
        }

        this.container.addChild(this.miningContainer)

        this.container.addChild(this.minerComponent.getContainer())

        document.addEventListener('minerUpdated', () => {
            this.minerComponent.update(PlayerService.getInstance().miner)
            this.onResize()
        })

        this.miningContainer.visible = false
        document.addEventListener('serverPostTick', () => {
            const player = PlayerService.getInstance().miner
            let comethX = (-this.cometComponent.scaledWidth / 2) * (1 + player.nearestComethDistance / player.miningArea)

            if (comethX > 0) {
                comethX = 0
            } else if (comethX < -2000) {
                // prevent too far away object, resizing too much the container
                comethX = -2000
            }
            this.cometComponent.getContainer().position.x = comethX
            this.miningContainer.visible = comethX > -this.cometComponent.scaledWidth
        })

        PlayerService.getInstance().setOnCurrentPlayerChange(miner => {
            this.currentMiner = miner
            this.minerComponent.miner = miner
            this.minerComponent.update(PlayerService.getInstance().miner)
            this.onResize()
        })

        this.setPositions()
        this.container.visible = false
        this.minimap.mapComponent.container.visible = false
    }

    async enterAnimation(callback: () => void): Promise<void> {
        this.container.visible = true
        this.minimap.mapComponent.container.visible = true
        this.onResize()
        this.minerComponent.container.y = Resolution.realHeight
        this.minimap.container.x = Resolution.realWidth
        const miniMapWidth = this.minimap.container.width + Resolution.margin6 * 3
        const minerContainer = this.minerComponent.getContainer()
        await animate('easeOutQuad', 300, (perc: number) => {
            this.minimap.container.x = Resolution.realWidth - (perc * miniMapWidth) / 2
            minerContainer.position.y = Resolution.realHeight - this.minerComponent.container.height / 2 - perc * (Resolution.realHeight / 2)
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        const miniMapWidth = this.minimap.container.width + Resolution.margin6 * 3
        await animate('easeInQuad', 300, (perc: number) => {
            this.minimap.container.position.x = Resolution.realWidth - miniMapWidth / 2 + perc * miniMapWidth
            this.minerComponent.getContainer().position.y =
                Resolution.realHeight / 2 - this.minerComponent.container.height / 2 - (Resolution.realWidth / 2) * perc
        })
        callback()
        this.container.visible = false
        this.minimap.mapComponent.container.visible = false
    }

    onResize(): void {
        this.bg.width = Resolution.realWidth / Resolution.scale
        this.bg.height = Resolution.realHeight / Resolution.scale
        this.bg2.width = this.bg.width
        this.bg2.height = this.bg.height
        this.bg3.width = this.bg.width
        this.bg3.height = this.bg.height
        this.setPositions()
        this.minimap.onResize()
    }

    public tick(): void {
        this.bg!.tilePosition.y += PIXI.Ticker.shared.deltaMS / 200
        this.bg2!.tilePosition.y += PIXI.Ticker.shared.deltaMS / 100
        this.bg3!.tilePosition.y += PIXI.Ticker.shared.deltaMS / 50
        this.minerComponent.tick()

        this.minimap.tick()
        this.cometComponent.tick()

        if (this.cometComponent.getContainer().visible) {
            if (Math.random() * 100 < 10) {
                const newParticle = PIXI.Sprite.from('pixel')
                newParticle.tint = 0x999999
                newParticle.x = 10
                newParticle.width = 16
                newParticle.height = 16
                newParticle.anchor.set(0.5)
                newParticle.scale.set(Resolution.scale + Math.random() * 3 * Resolution.scale)
                newParticle.y = Resolution.realHeight / 2 + 30 * Resolution.scale - Math.random() * 60 * Resolution.scale
                this.miningContainer.addChild(newParticle)
            }
            this.miningContainer.children.forEach(it => {
                if (it.x > Resolution.realWidth / 2) {
                    it.destroy()
                    return
                }
                it.x += (PIXI.Ticker.shared.deltaMS * Resolution.realWidth) / 4000
                it.rotation += 0.02
            })
        }
    }

    public getContainer(): PIXI.Container {
        return this.container
    }

    private setPositions() {
        const minerContainer = this.minerComponent.getContainer()
        minerContainer.scale.set(Resolution.scale)
        minerContainer.position.x = Math.floor(Resolution.realWidth / 2 - this.minerComponent.container.width / 2)
        minerContainer.position.y = Math.floor(Resolution.realHeight / 2 - this.minerComponent.container.height / 2)
        this.bg.width = Resolution.realWidth

        const container = this.cometComponent.getContainer()
        container.scale.set(Resolution.scale + 1.5)
        container.x = -this.cometComponent.scaledWidth * 2
        container.y = -70 * container.scale.x
    }
}
