import { Options, Vue } from 'vue-class-component'
import Header from '@/components/header/Header.vue'
import FriendList from '@/views/Referral/FriendList.vue'
import ReferralStarterKitService from '@/game/services/web3/ReferralStarterKitService'
import Web3Service from '@/game/services/Web3Service'
import axios from 'axios'

class Friend {
    address!: string
    date!: string
    must!: string
    txHash!: string
}

@Options({
    components: {
        Header,
        FriendList
    }
})
export default class Ambassador extends Vue {

    friends: Friend[] = []

    noCodeProvided = false
    codeProvidedIsInvalide = false
    code = ''

    async mounted() {
        await this.init()
    }

    async init() {

        if (this.$route.query.code) {
            this.noCodeProvided = false;
            this.code = this.$route.query.code?.toString()

            const account = await ReferralStarterKitService.getInstance().referrerByCode(this.code)
            console.log(account)

            if (account != '0x0000000000000000000000000000000000000000'){
                this.codeProvidedIsInvalide = false
                this.fetchReferrals(account)
            } else {
                this.codeProvidedIsInvalide = true
            }
        } else {
            this.noCodeProvided = true
        }
    }

    fetchReferrals(account: string) {

        axios({
        url: 'https://api.thegraph.com/subgraphs/name/cometh-game/cometh-referrals',
        method: 'post',
       
        data: {
            query: `
            {
                referrals(where: { referrer: "${account.toLowerCase()}"}) {
                  referrer
                  buyer
                  mustAmount
                  time
                  id
                }
              }
            `   
        }
        }).then((result:any) => {
            console.log(result.data)

            this.friends = [];

            result.data.data.referrals.forEach((data: any) => {
                const friend:Friend = new Friend()
                friend.address = data.buyer;
                friend.must = parseFloat(Web3Service.getInstance().getWeb3().utils.fromWei(data.mustAmount, 'ether')).toFixed(4)
                friend.date = new Date(data.time * 1000).toDateString();
                friend.txHash = data.id
                this.friends.push(friend)
            })
        });
    }
}
