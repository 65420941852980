import Web3 from 'web3'
import config from '@/config'
import Connection from '@/components/connection/Connection'

const wssWeb3 = new Web3()
const wsProvider = new Web3.providers.WebsocketProvider(config.innerWsRpc, {
    reconnect: {
        auto: true,
        delay: 35000, // ms
        maxAttempts: 10,
        onTimeout: false
    }
})
wssWeb3.setProvider(wsProvider)

const readWeb3 = new Web3(new Web3.providers.HttpProvider(config.innerRpc))

export default class Web3Service {
    public static instance: Web3Service

    public static getInstance(): Web3Service {
        if (!this.instance) {
            this.instance = new Web3Service()
        }
        return this.instance
    }

    get connectedAccount() {
        return Connection.getInstance().connectedAddress ?? ''
    }

    async getAccount(): Promise<string> {
        return this.connectedAccount
    }

    async getBalance(): Promise<string> {
        const account = await this.getAccount()
        return this.getWssWeb3().eth.getBalance(account)
    }

    getWeb3() {
        return Connection.getInstance().getWeb3()
    }

    getReadWeb3(): any {
        return readWeb3
    }

    getWssWeb3(): any {
        return wssWeb3
    }

    public async openTxScan(tx: string) {
        window.open(config.etherscanUrl + 'tx/' + tx)
    }

    public async openAddressScan(add: string) {
        window.open(config.etherscanUrl + 'address/' + add)
    }

    public async openTokenScan(add: string) {
        window.open(config.etherscanUrl + 'tokens/' + add)
    }
}
