import * as PIXI from 'pixi.js'
import GameElement from './GameElement'
import GMain from './GMain'
import GMap from './GMap'
import GSettings from './GSettings'
import UpdateService from './services/UpdateService'
import PullPriceModal from './components/modals/PullPriceModal'
import SpiceModal from './components/modals/SpiceModal'
import ShipExitModal from './components/modals/ShipExitModal'
import BadNetworkModal from './components/modals/BadNetworkModal'
import GSprite from './pixi-scale/GSprite'
import GText from './pixi-scale/GText'
import SoundService from './services/SoundService'
import TutorialService, { HelpChapter } from './services/TutorialService'
import Web3Service from '@/game/services/Web3Service'
import Resolution from '@/helpers/Resolution'
import MainBar from './components/MainBar'
import MapService from './services/MapService'
import NotificationManager from './components/notifications/NotificationManager'
import { addTooltip } from '@/helpers/TooltipHelper'
import SpiceService from './services/web3/SpiceService'
import MiningStatusComponent from './components/MiningStatusComponent'
import StakedSpaceshipsService from './services/web3/StakedSpaceshipsService'
import CometManagerService from './services/web3/CometManagerService'
import TimeService from './services/TimeService'
import BadTimeModal from './components/modals/BadTimeModal'
import PlayerMenuComponent from './components/PlayerMenuComponent'
import TextButton from './components/TextButton'
import EntityPreviewZoomComponent from './components/EntityPreviewZoomComponent'
import Comete from './models/Comete'
import Miner from './models/Miner'
import ContextualMenuManager from './components/ContextualMenuManager'
import WalletModal from './components/modals/WalletModal'
import NotificationService from './services/NotificationService'
import { Notification, NotificationType } from './models/notification/Notification'
import { TransactionNotification } from './models/notification/TransactionNotification'
import { Transaction, TransactionStatus } from './models/Transaction'
import { MiningNotification } from './models/notification/MiningNotification'
import { animate, AnimationLooper, AnimationService } from '@/helpers/AnimationHelper'
import JumpManagerService from './services/web3/JumpManagerService'
import PortalService from './services/web3/PortalService'
import KeyboardService from './services/KeyboardService'
import MainBitmapText from './pixi-scale/MainBitmapText'
import TransactionsModal from './components/modals/TransactionsModal'
import SessionTransactionService from './services/SessionTransactionService'
import ItemsModal from './components/modals/ItemsModals'
import UseItemModal from './components/modals/UseItemModal'
import Item from './models/Items'
import Colors from '@/helpers/Colors'
import HelpModal from './components/modals/HelpModal'
import PIXIAppService from '@/services/PIXIAppService'

export default class GCore implements GameElement {
    private pixi!: PIXI.Application
    private currentScreen!: GameElement
    private fpsCounter: PIXI.BitmapText
    private fullscreen = new GSprite('ic_fullscreen')
    private icSettings = PIXI.Sprite.from('ic_settings')
    private icWallet = PIXI.Sprite.from('ic_wallet')
    private icItems = PIXI.Sprite.from('ic_items')
    private icAllowance = PIXI.Sprite.from('ic_allowance')
    private icTx = PIXI.Sprite.from('ic_tx')
    private txProgressCount = new MainBitmapText('0', { fontSize: 3 })
    private txSuccessCount = new MainBitmapText('0', { fontSize: 3 })
    private txErrorCount = new MainBitmapText('0', { fontSize: 3 })
    private settingsContainer = new PIXI.Container()
    private robotContainer = new PIXI.Container()
    private currentPriceModal?: PullPriceModal
    private currentSpiceModal?: SpiceModal

    private txModal: TransactionsModal = new TransactionsModal()
    private helpModal: HelpModal

    private currentItemsModal?: ItemsModal

    private mainBar: MainBar = new MainBar()
    private notifBtn = new GSprite('ic_notifs')
    private badnetworkModal?: BadNetworkModal

    private tutorialRobot!: PIXI.AnimatedSprite
    private currentRobotText?: GText
    private currentRobotTextTimeout?: NodeJS.Timeout
    private main!: GMain
    private map!: GMap
    private settings!: GSettings
    private notificationManager: NotificationManager = NotificationManager.getInstance()
    private miningStatusComponent: MiningStatusComponent = new MiningStatusComponent()
    private playerMenuComponent: PlayerMenuComponent = new PlayerMenuComponent()
    private entityPreviewZoom: EntityPreviewZoomComponent = new EntityPreviewZoomComponent()
    private contextualMenuManager: ContextualMenuManager = new ContextualMenuManager()

    constructor() {
        SoundService.getInstance()
        UpdateService.getInstance()
        Resolution.checkScale()
        JumpManagerService.getInstance()
        CometManagerService.getInstance()
        PortalService.getInstance()
        StakedSpaceshipsService.getInstance()
        KeyboardService.getInstance()

        // Placed here because we need pixi to have loaded data
        TutorialService.initChapters()
        this.helpModal = new HelpModal()

        this.pixi = PIXIAppService.getInstance().getApplication()
        TutorialService.getInstance().pixi = this.pixi
        SpiceService.getInstance().setOnApproveRequested(() => {
            this.openSpiceModal()
        })

        const main = document.getElementById('main')!
        main.appendChild(this.pixi.view)

        this.main = new GMain(this.pixi, () => {
            this.setScreen(this.map)
        })

        this.settings = new GSettings(this.pixi)
        this.map = new GMap(this.pixi)
        this.map.onClose = () => {
            this.setScreen(this.main)
        }
        this.currentScreen = this.map
        this.map.enterAnimation(() => {
            return
        })

        this.settings.onExit = () => {
            this.settings.exitAnimation(() => {
                this.pixi.stage.removeChild(this.settings.getContainer())
            })
        }

        this.tutorialRobot = new PIXI.AnimatedSprite(
            (PIXI.Loader.shared.resources['tuto'] as any).spritesheet!._frameKeys.map((a: string) => {
                return PIXI.Texture.from(a)
            })
        )
        this.tutorialRobot.animationSpeed = 0.1
        this.tutorialRobot.play()
        this.tutorialRobot.interactive = true
        this.tutorialRobot.cursor = 'pointer'

        this.tutorialRobot.on('pointertap', () => {
            this.pixi.stage.addChild(this.helpModal.getContainer())
            this.helpModal.onExit = () => {
                this.helpModal.exitAnimation(() => {
                    this.pixi.stage.removeChild(this.helpModal.getContainer())
                })
            }
            this.helpModal.enterAnimation(() => {
                // opened
            })
            // }
        })
        addTooltip(this.tutorialRobot, this.robotContainer, 'Need Help ?')

        this.pixi.stage.addChild(this.currentScreen.getContainer())
        this.robotContainer.addChild(this.tutorialRobot)
        this.robotContainer.addChild(this.notificationManager.getContainer())
        this.robotContainer.addChild(this.miningStatusComponent.getContainer())
        this.pixi.stage.addChild(this.robotContainer)
        this.pixi.stage.addChild(this.playerMenuComponent.getContainer())
        this.pixi.stage.addChild(this.entityPreviewZoom.getContainer())
        this.pixi.stage.addChild(this.contextualMenuManager)

        let fpsCount = 0
        setInterval(() => {
            this.fpsCounter.text = fpsCount * 4 + ` (${window.innerWidth}x${window.innerHeight}) ${Resolution.scale}`
            fpsCount = 0
        }, 250)

        this.pixi.ticker.add(it => {
            fpsCount++
            this.tick()
            this.currentScreen.tick()
            AnimationService.getInstance().loopers.forEach(it => it.callback())
        })
        this.setupMenu()

        this.onResize()
        window.onresize = () => {
            this.onResize()
            Resolution.checkScale()
        }

        this.fpsCounter = new GText('60')
        this.fpsCounter.position.x = Resolution.margin6
        this.fpsCounter.position.y = Resolution.margin6

        this.pixi.stage.addChild(this.fpsCounter)

        document.addEventListener('onScaleChange', () => {
            setTimeout(() => {
                this.onResize()
            }, 100)
        })
        Resolution.checkScale()

        this.fpsCounter.visible = false
        document.addEventListener('keydown', KeyboardEvent => {
            if (KeyboardEvent.key == 'd') {
                this.fpsCounter.visible = true
            }
        })
        document.addEventListener('keyup', KeyboardEvent => {
            if (KeyboardEvent.key == 'd') {
                this.fpsCounter.visible = false
            }
        })

        document.addEventListener(ShipExitModal.OPEN_SHIP_EXIT_EVENT, event => {
            const miner = (event as CustomEvent).detail
            this.openExitShipModal(miner)
        })

        this.txProgressCount.x = this.icTx.x + 1.166 * Resolution.scale - this.txProgressCount.width / 2
        this.txSuccessCount.x = this.icTx.x + 1.166 * Resolution.scale - this.txProgressCount.width / 2
        this.txErrorCount.x = this.icTx.x + 1.166 * Resolution.scale - this.txProgressCount.width / 2
        SessionTransactionService.getInstance().setOnTxListChanged(tx => {
            const progressLength = tx.filter(it => it.transaction.status == TransactionStatus.InProgress).length
            const successLength = tx.filter(it => it.transaction.status == TransactionStatus.Success).length
            const errorLength = tx.filter(it => it.transaction.status == TransactionStatus.Failed).length
            this.txProgressCount.text = progressLength > 99 ? '99' : progressLength + ''
            this.txProgressCount.x = this.icTx.x + 1.166 * Resolution.scale - this.txProgressCount.width / 2

            this.txSuccessCount.text = successLength > 99 ? '99' : successLength + ''
            this.txSuccessCount.x = this.icTx.x + 1.166 * Resolution.scale - this.txSuccessCount.width / 2

            this.txErrorCount.text = errorLength > 99 ? '99' : errorLength + ''
            this.txErrorCount.x = this.icTx.x + 1.166 * Resolution.scale - this.txErrorCount.width / 2
        })

        document.addEventListener(UseItemModal.OPEN_USE_ITEM_EVENT, event => {
            const item = (event as CustomEvent).detail
            this.openUseItemModal(item)
        })

        this.notifBtn.interactive = true
        this.notifBtn.cursor = 'pointer'
        this.notifBtn.alpha = 0.8
        this.notifBtn.on('mouseover', () => {
            this.notifBtn.alpha = 1
        })
        this.notifBtn.on('mouseout', () => {
            this.notifBtn.alpha = 0.8
        })
        /* this.notifBtn.on('pointertap', () => {
            this.logsModal.enterAnimation(() => {
                return
            })
        })
        this.pixi.stage.addChild(this.logsModal.getContainer())
        this.logsModal.onExit = () => {
            this.logsModal.exitAnimation(() => {
                return
            })
        } */

        this.listenNetworkChange()
        this.checkTimeDiff()

        this.map.onEntityPreview = (entity, y) => {
            if (entity instanceof Comete) {
                this.entityPreviewZoom.setComet(entity)
            } else if (entity instanceof Miner) {
                this.entityPreviewZoom.setMiner(entity)
            }
            this.entityPreviewZoom.getContainer().x = Resolution.realWidth - this.entityPreviewZoom.getContainer().width - (6 + 48) * Resolution.scale
            this.entityPreviewZoom.getContainer().y = y
        }
        this.map.onEntityPreviewClear = () => {
            this.entityPreviewZoom.clearEntityContainer()
        }
        this.playerMenuComponent.onRequirePreview = (miner: Miner) => {
            this.entityPreviewZoom.setMiner(miner)

            this.entityPreviewZoom.getContainer().x = (6 + 48) * Resolution.scale
            this.entityPreviewZoom.getContainer().y = Resolution.margin6
        }
        this.playerMenuComponent.onExitPreview = () => {
            this.entityPreviewZoom.clearEntityContainer()
        }
    }

    public async checkTimeDiff() {
        await TimeService.getInstance().calculateTimeDiff()
        const timeDiff = TimeService.getInstance().timeDiff
        if (TimeService.getInstance().timeDiff > 20) {
            const badTimeModel = new BadTimeModal(timeDiff)
            badTimeModel.enterAnimation(() => {
                return
            })

            badTimeModel.onExit = () => {
                badTimeModel.exitAnimation(() => {
                    this.pixi.stage.removeChild(badTimeModel.getContainer())
                })
            }

            this.pixi.stage.addChild(badTimeModel.getContainer())
        }
    }

    public tick() {
        this.tutorialRobot.visible = !TutorialService.getInstance().hasTutorial()

        this.playerMenuComponent.tick()
        this.miningStatusComponent.tick()
        this.entityPreviewZoom.tick()
        this.txModal.tick()
    }

    public listenNetworkChange() {
        const web3 = Web3Service.getInstance().getWeb3()

        // Subscribe to chainId change
        web3.currentProvider.on('chainChanged', (chainId: number) => {
            if (chainId != 137) {
                if (this.badnetworkModal == undefined) {
                    this.openBadNetworkModal()
                }
            } else if (this.badnetworkModal) {
                this.badnetworkModal.exitAnimation(() => {
                    this.pixi.stage.removeChild(this.badnetworkModal!.getContainer())
                    this.badnetworkModal = undefined
                })
            }
        })
    }

    public onResize() {
        this.miningStatusComponent.onResize()
        this.playerMenuComponent.onResize()
        this.playerMenuComponent.getContainer().position.x = Resolution.margin6
        this.playerMenuComponent.getContainer().position.y = Resolution.margin6
        this.robotContainer.scale.set(Resolution.scale)
        this.tutorialRobot.x = Resolution.margin2
        this.tutorialRobot.y = Resolution.realHeight / Resolution.scale - this.tutorialRobot.height - 2
        this.notifBtn.x = this.tutorialRobot.position.x + this.tutorialRobot.width + Resolution.margin6
        this.notifBtn.y = this.tutorialRobot.position.y + this.notifBtn.height - Resolution.margin3

        this.fullscreen.x = this.fullscreen.width / 2 + Resolution.margin6
        this.fullscreen.y = Resolution.realHeight - this.fullscreen.height / 2 - Resolution.margin6

        this.settingsContainer.position.x = 46 * Resolution.scale + this.robotContainer.x + Resolution.margin6
        this.settingsContainer.position.y = Resolution.realHeight - this.icSettings.height * Resolution.scale - Resolution.margin6
        this.settingsContainer.scale.set(Resolution.scale)
        this.mainBar.onResize()
        this.currentScreen.onResize()
        this.settings.onResize()
        this.currentPriceModal?.onResize()
        this.currentSpiceModal?.onResize()
        this.notificationManager.onResize()
        this.txModal.onResize()
        this.helpModal.onResize()
        TutorialService.getInstance().onResize()

        this.entityPreviewZoom.onResize()
    }

    private setupMenu() {
        /**
         * TX
         */
        this.icTx.interactive = true
        this.icTx.alpha = 0.8
        this.icTx.on('pointertap', () => {
            this.openTxModal()
        })
        this.icTx.on('mouseover', () => {
            this.icTx.alpha = 1
        })
        this.icTx.on('mouseout', () => {
            this.icTx.alpha = 0.8
        })
        this.icTx.cursor = 'pointer'
        addTooltip(this.icTx, this.settingsContainer, 'Transactions')

        this.txProgressCount.y = 1
        this.txSuccessCount.y = 7
        this.txErrorCount.y = 13
        this.settingsContainer.addChild(this.icTx)
        this.settingsContainer.addChild(this.txProgressCount)
        this.settingsContainer.addChild(this.txSuccessCount)
        this.settingsContainer.addChild(this.txErrorCount)

        SessionTransactionService.getInstance().setOnTxFinished(async (title, isSuccess) => {
            const container = new PIXI.Container()
            const bg = PIXI.Sprite.from('pixel')
            const text = new MainBitmapText(title, { fontSize: 4 })

            container.addChild(bg)
            container.addChild(text)
            bg.tint = isSuccess ? Colors.Green300 : Colors.Red300
            bg.width = text.width + 8
            bg.height = text.height + 4
            text.x = 4
            text.y = 2
            container.x = Math.floor(this.icTx.x + this.icTx.width / 2 - container.width / 2)

            this.settingsContainer.addChild(container)
            await animate('easeOutQuad', 3000, perc => {
                container.y = -12 * perc
                container.alpha = 1 - Math.max(0, perc * 2 - 1)
            })
            this.settingsContainer.removeChild(container)
        })

        /**
         * SETTINGS
         */
        this.icSettings.interactive = true
        this.icSettings.alpha = 0.8
        this.icSettings.on('pointertap', () => {
            this.pixi.stage.addChild(this.settings.getContainer())
            this.settings.enterAnimation(() => {
                // opened
            })
        })
        this.icSettings.x = this.icTx.x + this.icTx.width + 6
        this.icSettings.on('mouseover', () => {
            this.icSettings.alpha = 1
        })
        this.icSettings.on('mouseout', () => {
            this.icSettings.alpha = 0.8
        })
        this.icSettings.cursor = 'pointer'
        addTooltip(this.icSettings, this.settingsContainer, 'Settings')
        this.settingsContainer.addChild(this.icSettings)

        /**
         * WALLET
         */
        this.icWallet.interactive = true
        this.icWallet.alpha = 0.8
        this.icWallet.on('pointertap', () => {
            const wallet = new WalletModal()
            this.pixi.stage.addChild(wallet.getContainer())
            wallet.onExit = () => {
                wallet.exitAnimation(() => {
                    this.pixi.stage.removeChild(wallet.getContainer())
                })
            }
            wallet.enterAnimation(() => {
                return
            })
        })
        this.icWallet.on('mouseover', () => {
            this.icWallet.alpha = 1
        })
        this.icWallet.on('mouseout', () => {
            this.icWallet.alpha = 0.8
        })
        this.icWallet.cursor = 'pointer'
        addTooltip(this.icWallet, this.settingsContainer, 'Wallet')

        this.icWallet.x = this.icSettings.x + this.icSettings.width + 6
        this.settingsContainer.addChild(this.icWallet)

        /**
         * MUST ALLOWANCE
         */
        this.icAllowance.interactive = true
        this.icAllowance.alpha = 0.8
        this.icAllowance.on('pointertap', () => {
            this.openSpiceModal()
        })
        this.icAllowance.on('mouseover', () => {
            this.icAllowance.alpha = 1
        })
        this.icAllowance.on('mouseout', () => {
            this.icAllowance.alpha = 0.8
        })
        this.icAllowance.cursor = 'pointer'
        addTooltip(this.icAllowance, this.settingsContainer, 'MUST Allowance')

        this.icAllowance.x = this.icWallet.x + this.icWallet.width + 6
        this.settingsContainer.addChild(this.icAllowance)

        /**
         * ITEMS
         */

        this.icItems.interactive = true
        this.icItems.alpha = 0.8
        this.icItems.on('pointertap', () => {
            this.openItemsModal()
        })
        this.icItems.on('mouseover', () => {
            this.icItems.alpha = 1
        })
        this.icItems.on('mouseout', () => {
            this.icItems.alpha = 0.8
        })
        this.icItems.cursor = 'pointer'
        addTooltip(this.icItems, this.settingsContainer, 'Items')

        this.icItems.x = this.icAllowance.x + this.icAllowance.width + 6
        this.settingsContainer.addChild(this.icItems)

        /**
         * END MENU
         */
        this.playerMenuComponent.onPullPriceClick = () => {
            this.openPullPriceModal()
        }

        document.addEventListener('requestOpenTxModal', () => {
            this.openTxModal()
        })

        this.pixi.stage.addChild(this.settingsContainer)
    }

    private openPullPriceModal() {
        this.currentPriceModal = new PullPriceModal()
        const modal = this.currentPriceModal
        modal.enterAnimation(() => {
            return
        })
        modal.onExit = () => {
            modal.exitAnimation(() => {
                this.currentPriceModal = undefined
                this.pixi.stage.removeChild(modal.getContainer())
            })
        }
        this.pixi.stage.addChild(modal.getContainer())
    }

    private openTxModal() {
        this.pixi.stage.addChild(this.txModal.getContainer())
        this.txModal.onExit = () => {
            this.txModal.exitAnimation(() => {
                this.pixi.stage.removeChild(this.txModal.getContainer())
            })
        }
        this.txModal.enterAnimation(() => {
            return
        })
    }

    private openBadNetworkModal() {
        this.badnetworkModal = new BadNetworkModal(this.pixi)
        this.badnetworkModal.enterAnimation(() => {
            return
        })

        this.badnetworkModal.onExit = () => {
            if (this.badnetworkModal) {
                this.badnetworkModal.exitAnimation(() => {
                    this.pixi.stage.removeChild(this.badnetworkModal!.getContainer())
                    this.badnetworkModal = undefined
                })
            }
        }

        this.pixi.stage.addChild(this.badnetworkModal.getContainer())
    }

    private openExitShipModal(miner: Miner) {
        const modal = new ShipExitModal(miner)

        modal.enterAnimation(() => {
            return
        })

        modal.onExit = () => {
            modal.exitAnimation(() => {
                this.pixi.stage.removeChild(modal.getContainer())
            })
        }

        this.pixi.stage.addChild(modal.getContainer())
    }

    private openUseItemModal(item: Item) {
        const modal = new UseItemModal(item)

        modal.enterAnimation(() => {
            return
        })

        modal.onExit = () => {
            modal.exitAnimation(() => {
                this.pixi.stage.removeChild(modal.getContainer())
            })
        }

        this.pixi.stage.addChild(modal.getContainer())
    }

    private openSpiceModal() {
        this.currentSpiceModal = new SpiceModal()
        const modal = this.currentSpiceModal
        modal.enterAnimation(() => {
            return
        })
        modal.onExit = () => {
            modal.exitAnimation(() => {
                this.currentSpiceModal = undefined
                this.pixi.stage.removeChild(modal.getContainer())
            })
        }
        this.pixi.stage.addChild(modal.getContainer())
    }

    private openItemsModal() {
        this.currentItemsModal = new ItemsModal()
        const modal = this.currentItemsModal
        modal.enterAnimation(() => {
            return
        })
        modal.onExit = () => {
            modal.exitAnimation(() => {
                this.currentItemsModal = undefined
                this.pixi.stage.removeChild(modal.getContainer())
            })
        }
        this.pixi.stage.addChild(modal.getContainer())
    }

    public getContainer(): PIXI.Container {
        return this.pixi.stage
    }

    public destroy() {
        this.pixi.destroy()
    }

    private setScreen(screen: GameElement) {
        if (screen == this.currentScreen) return
        const oldScreen = this.currentScreen
        oldScreen.exitAnimation(() => {
            this.pixi.stage.addChildAt(screen.getContainer(), 1)
            this.currentScreen = screen
            screen.enterAnimation(() => {
                this.pixi.stage.removeChild(oldScreen.getContainer())
            })
        })
    }

    public async enterAnimation(callback: () => void): Promise<void> {
        callback()
    }

    public async exitAnimation(callback: () => void): Promise<void> {
        callback()
    }
}
