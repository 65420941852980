import OrbitUtils from '@/game/models/position/Utils'
import TimeService from '../services/TimeService'
import Comete from './Comete'
import BN from 'bn.js'
import Miner from './Miner'

export default class StakingComete extends Comete {
    public rate: BN
    public capacity = 0
    public roverCount = 0
    public cumulatedRate: BN = new BN(0)
    public collectable: BN = new BN(0)
    public lastUpdate = 0
    public balance: BN = new BN(0)

    public playerMiners: Miner[] = []

    constructor(from: any) {
        super(from)
        this.rate = new BN(from.rate)
        this.capacity = parseInt(from.capacity)
        this.updateData(from)
    }

    public updateData(data: any) {
        this.balance = new BN(data.balance)
        this.collectable = new BN(data.collectable)
        this.cumulatedRate = new BN(data.cumulatedRate)
        this.roverCount = parseInt(data.roverCount)
        this.lastUpdate = parseInt(data.lastUpdate)
    }

    public updateCartesian() {
        const timeRef = TimeService.getInstance().now()
        const cartesian = this.orbit.getCartesian(timeRef)
        this.x = cartesian.x
        this.y = cartesian.y
        this.piValue = OrbitUtils.degreesToRadians(this.orbit.currentAngle(timeRef))
    }

    public liveCollectable() {
        const now = TimeService.getInstance().now()
        return this.collectable.add(this.rate.mul(this.cumulatedRate.mul(new BN(now - this.lastUpdate)).div(new BN(100))))
    }

    public collectablePercentage(): number {
        return this.liveCollectable()
            .mul(new BN(100))
            .div(this.balance)
            .toNumber()
    }

    public revolutionDuration(): string {
        return Math.trunc(this.orbit.revolutionInSeconde() / 60) + ' Min'
    }

    public log() {
        const time = TimeService.getInstance().now()
        console.log(
            'orbit lastupdate: ',
            this.orbit.lastUpdate,
            ' distance: ',
            this.orbit.last.distance,
            ' angle',
            this.orbit.last.angle,
            ' center',
            this.orbit.center.x,
            ' - ',
            this.orbit.center.y,
            ' speed',
            this.orbit.rotationSpeed
        )
        console.log('time:', time, ' - ', 'x:', this.x, 'y:', this.y)
    }

    public miningReward(): string {
        return this.rate.muln(3600).toString()
    }

    public isPending(): boolean {
        const time = TimeService.getInstance().now()
        return this.orbit.lastUpdate > time
    }
}
