import { Options, Vue } from 'vue-class-component'

import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk'
import NavLink from './NavLink.vue'
import NavLinkMobile from './NavLinkMobile.vue'
import Modal from '@/components/modal/Modal.vue'

import Connection, { ConnectionType } from '@/components/connection/Connection'

import { isMobile } from 'mobile-device-detect'
@Options({
    components: { NavLink, NavLinkMobile, Modal },
    emits: ['rampPurchaseSuccessful', 'signin', 'rampPurchaseCreated', 'rampPurchaseFailed', 'rampCloseRequestConfirmed']
})
export default class Header extends Vue {
    $refs!: {
        stakeDaoInput: HTMLFormElement
        addressSpan: HTMLElement
    }

    ConnectionType: any = ConnectionType

    dropDownOpen = false
    modalOpen = false

    isAuth = false

    provider!: ConnectionType | null
    address!: string

    subStrAddress!: string

    isEditingStakeDao = false
    stakeDaoError = false
    stakeDaoEmail = ''

    isEditingEmail = false
    emailError = false
    email = ''

    addressCopied = false

    connection!: Connection

    isProviderMetamask = false
    isProviderStakeDAO = false
    isProviderEmail = false

    isConnecting = false
    isConnectingMetamask = false
    isConnectingStakeDao = false

    // used for responsive
    open = false

    private setSubStrAddress() {
        this.subStrAddress = this.address.substring(0, 6) + '...' + this.address.substring(this.address.length - 4, this.address.length)
    }

    async mounted() {
        document.addEventListener('click', this.close)
        await this.loadConnection()
    }

    async loadConnection() {
        this.connection = Connection.getInstance()
        if (this.connection.isConnected()) {
            this.isConnecting = true
            await this.connection.load()
            this.refreshConnectionData()
        }
    }

    beforeDestroy() {
        document.removeEventListener('click', this.close)
    }

    refreshConnectionData() {
        this.isAuth = this.connection.isConnected()
        if (this.isAuth) {
            this.address = this.connection.connectedAddress as string
            this.setSubStrAddress()
            this.provider = this.connection.getConnectionType()
            this.isProviderEmail = this.provider === ConnectionType.Cometh
            this.isProviderMetamask = this.provider === ConnectionType.Metamask
            this.isProviderStakeDAO = this.provider === ConnectionType.StakeDAO
        }
        this.isConnecting = false
    }

    async useMetamask() {
        this.isConnectingMetamask = true
        await this.connection.connectMetamask()
        this.refreshConnectionData()
        //
        // interface stuff
        this.isEditingStakeDao = false
        this.stakeDaoEmail = ''
        this.email = ''
        this.closeModal()
        this.isConnectingMetamask = false
    }

    async useStakeDao() {
        await this.connection.connectStakeDAO(this.stakeDaoEmail)
        this.refreshConnectionData()
    }

    async useEmail() {
        await this.connection.connectCometh(this.email)
        await this.refreshConnectionData()
    }

    toggleDropdown() {
        this.dropDownOpen = !this.dropDownOpen
    }

    openModal() {
        this.modalOpen = true
        this.dropDownOpen = false
    }

    closeModal() {
        this.modalOpen = false
        this.isEditingStakeDao = false
    }

    close(e: any) {
        if (!this.isAuth) return

        const dropDown = document.querySelector('#dropdown')
        if (dropDown && !dropDown.contains(e.target)) {
            this.dropDownOpen = false
        }
    }

    editStakeDao() {
        this.isEditingStakeDao = true
    }

    async saveStakeDao() {
        if (!this.validateEmail(this.stakeDaoEmail)) {
            this.stakeDaoError = true
            return
        }

        this.isConnectingStakeDao = true
        this.stakeDaoError = false
        await this.useStakeDao()
        this.refreshConnectionData()
        this.isEditingStakeDao = false
        this.isConnectingStakeDao = false
        this.closeModal()
    }

    cancelStakeDao() {
        this.isEditingStakeDao = false
        this.stakeDaoEmail = ''
        this.stakeDaoError = false
    }

    editEmail() {
        this.isEditingEmail = true
    }

    /* eslint-disable no-extra-semi */
    /* eslint-disable @typescript-eslint/camelcase */
    async saveEmail() {
        if (!this.validateEmail(this.email)) {
            this.emailError = true
            return
        }

        this.emailError = false
        this.isEditingEmail = false
        await this.useEmail()
        if ((window as any).sendinblue) {
          ;(window as any).sendinblue.identify(this.email, {
            mule_referral_code: this.$route.query.code?.toString(),
            is_mobile: isMobile,
            user_agent: navigator.userAgent,
            language: navigator.language
          })
        }

        this.closeModal()
    }

    cancelEmail() {
        this.isEditingEmail = false
        this.email = ''
        this.emailError = false
    }

    validateEmail(email: string) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    copyAddress() {
        // create a tmp input
        const input = document.createElement('input')
        input.setAttribute('type', 'text')
        input.setAttribute('id', 'addressCopy')
        input.setAttribute('value', this.address as string)
        document.body.append(input)

        // copy the input value and remove it
        ;(document.querySelector('#addressCopy')! as HTMLInputElement).select()
        document.execCommand('copy')
        input.remove()

        this.addressCopied = true
        setTimeout(() => {
            this.addressCopied = false
        }, 2000)
    }

    buyCrypto(fiatValue = '250') {
        new RampInstantSDK({
            hostAppName: 'Cometh',
            hostLogoUrl: 'https://app.cometh.io/app/logo-cometh-swap.png',
            swapAsset: 'MATIC,MATIC_USDC',
            fiatCurrency: 'USD',
            fiatValue: fiatValue,
            hostApiKey: '5wu5z5ymf35bvuykedphb3cwmb9fzg6tt6a4unsx',
            userAddress: this.address as any,
            variant: 'auto'
        })
            .on('PURCHASE_SUCCESSFUL', (payload: any) => {
                this.$emit('rampPurchaseSuccessful', payload)
            })
            .on('PURCHASE_CREATED', (payload: any) => {
                this.$emit('rampPurchaseCreated', payload)
            })
            .on('PURCHASE_FAILED', (payload: any) => {
                this.$emit('rampPurchaseFailed', payload)
            })
            .on('WIDGET_CLOSE_REQUEST_CONFIRMED', (payload: any) => {
                this.$emit('rampCloseRequestConfirmed', payload)
            })
            .show()
    }

    async logout() {
        await this.connection.logout()
        this.refreshConnectionData()
    }

    getInfo() {
        alert(`status: ${this.isAuth ? 'connecté' : 'non connectée'}`)
    }
}
