import Resolution from '@/helpers/Resolution'
import GameElement from '../../GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import ButtonComponent from '../ButtonComponent'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import ModalComponent from './ModalComponent'

export default class BadNetworkModal extends ModalComponent {
    private okBtn: ButtonComponent

    constructor(public pixi: PIXI.Application) {
        super(200, 50)
        this.position = ModalComponent.POSITION_CENTER

        const titleText = new MainBitmapText('BAD NETWORK DETECTED', {
            fontSize: 8
        })
        titleText.x = Math.floor(this.modalWidth / 2 - titleText.width / 2)
        titleText.y = 6

        const title2 = new MainBitmapText('Please connect to Matic to continue playing.', {
            fontSize: 6
        })
        title2.y = titleText.y + titleText.height + 4
        title2.x = Math.floor(this.modalWidth / 2 - title2.width / 2)

        this.okBtn = new ButtonComponent('Ok')
        this.okBtn.x = this.modalWidth / 2 - this.okBtn.width / 2
        this.okBtn.position.y = 32
        this.okBtn.interactive = true
        this.okBtn.cursor = 'pointer'
        this.okBtn.on('pointertap', async () => {
            this.onExit()
        })

        this.modalContainer.addChild(titleText)
        this.modalContainer.addChild(title2)
        this.modalContainer.addChild(this.okBtn)
        this.setPositions()

        this.loader.visible = false
        this.dataContainer.visible = true
    }
}
