import { GasPrices } from '@/models/GasPrices'
import axios from 'axios'
import Web3 from 'web3'

const GAS_STATION_URL = 'https://gasstation-mainnet.matic.network/'

export class GasStationService {
    public static GAS_STANDARD = -1
    public static GAS_FAST = -2
    public static GAS_FASTEST = -3

    private static _instance: GasStationService
    public static getInstance(): GasStationService {
        if (!this._instance) {
            this._instance = new GasStationService()
        }
        return this._instance
    }
    private defaultGas = GasStationService.GAS_FAST

    constructor() {
        const localDef = Number(localStorage.getItem('GAS_DEFAULT'))
        this.defaultGas = localDef ? localDef : GasStationService.GAS_FAST
    }

    async getPrices(): Promise<GasPrices> {
        const defGas = Object.assign(new GasPrices(), {
            slow: 2,
            standard: 5,
            fast: 10,
            fastest: 30
        })

        try {
            const result = await axios.get('https://gasstation-mainnet.matic.network/')

            return Object.assign(new GasPrices(), {
                slow: result.data.safeLow > defGas.slow ? result.data.safeLow : defGas.slow,
                standard: result.data.standard > defGas.standard ? result.data.standard : defGas.standard,
                fast: result.data.fast > defGas.fast ? result.data.fast : defGas.fast,
                fastest: result.data.fastest > defGas.fastest ? result.data.fastest : defGas.fastest
            })
        } catch (e) {
            return defGas
        }
    }

    setDefault(val: number) {
        this.defaultGas = val
        localStorage.setItem('GAS_DEFAULT', val.toString())

        document.dispatchEvent(new Event('DEFAULT_GAS_UPDATED'))
    }

    setOnDefaultGasUpdated(callback: (val: number) => void): () => void {
        const cb = () => {
            callback(this.getDefault())
        }
        document.addEventListener('DEFAULT_GAS_UPDATED', cb)

        return cb
    }

    removeOnDefaultGasUpdated(callback: () => void) {
        document.removeEventListener('DEFAULT_GAS_UPDATED', callback)
    }

    getDefault() {
        return this.defaultGas
    }
}
