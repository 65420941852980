<template>
    <a
        :href="href"
        :class="color"
        class="
            inline-flex
            items-center
            text-lg
            leading-[18px]
            font-pixel
            uppercase
            text-[#57C2FF]
        "
    >
        <slot />

        <svg
            class="ml-2 w-6 h-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path
                d="M15 5l-1.41 1.41L18.17 11H2v2h16.17l-4.59 4.59L15 19l7-7z"
            />
        </svg>
    </a>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
    props: {
        href: String,
        color: {
            type: String,
            default: "blue"
        }
    }
})
export default class ButtonLink extends Vue {
    href!: string;
    color!: string;
}
</script>

<style scoped>
a.blue {
    color: #57c2ff;
}
a.blue:hover {
    text-shadow: 0 0 10px #57c2ff;
}
a.purple {
    color: #5656cd;
}
a.purple:hover {
    text-shadow: 0 0 10px #5656cd;
}
</style>
