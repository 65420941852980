import PlayerService from './PlayerService'

export default class UpdateService {
    public static instance: UpdateService
    public static BLOCK_TICK = 1400
    public static COMETH_RANGE = 13
    public static LAST_BLOCK_TICK_TIME = new Date().getTime()

    public static getInstance(): UpdateService {
        if (!this.instance) {
            this.instance = new UpdateService()
        }

        return this.instance
    }

    constructor() {
        setInterval(() => {
            UpdateService.LAST_BLOCK_TICK_TIME = new Date().getTime()

            if (Math.random() < 33) {
                const ships = PlayerService.getInstance().miningPlayers
                const ship = ships[Math.floor(Math.random() * ships.length)]
                //const earn = Math.floor(ship.jumpCost * (1 + (ship.aura ? ship.aura.power / 100 : 0)))
                // const bonus = earn - ship.jumpCost
                // PlayerService.getInstance().earn(earn)
                // MessageService.getInstance().postNewMessage(`Earned ${earn} (+${bonus}) spices (pull)`)
            }
        }, UpdateService.BLOCK_TICK)
    }
}
