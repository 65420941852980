import { Options, Vue } from 'vue-class-component'
import Header from '@/components/header/Header.vue'
import FriendList from '@/views/Referral/FriendList.vue'
import ReferralStarterKitService from '@/game/services/web3/ReferralStarterKitService'
import Connection, { ConnectionType } from '@/components/connection/Connection'
import config from '@/config'
import Web3Service from '@/game/services/Web3Service'
import axios from 'axios'

export class Friend {
    address!: string
    date!: string
    must!: string
    txHash!: string
}

@Options({
    components: {
        Header,
        FriendList
    }
})
export default class Referral extends Vue {
    referralCode: string | null = null
    commissionPercentage = 10
    friends: Friend[] = []

    isConnected = false
    isGeneratingReferralCode = false

    linkBaseUrl = window.location.origin +  '/shop/mule?code='

    isChangingConnection = false

    async mounted() {

        this.isChangingConnection = Connection.getInstance().isConnecting

        Connection.getInstance().on('connecting', () => {
            console.log('connecting...')
            this.isChangingConnection = true
        })

        Connection.getInstance().on('providerChanged', async provider => {
            console.log('provider changed...')
            this.isChangingConnection = false
            // check if disconnected
            if (!provider) {
                // user is disconnected
                return
            }
            this.isConnected = true;
            await this.init()
        })
    }

    async init() {
        if (Connection.getInstance().getConnectionType() == ConnectionType.Metamask) {
            try {
                await (window as any).ethereum.request({
                    id: 1,
                    jsonrpc: '2.0',
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x' + config.network.toString(16),
                            chainName: config.l2NetworkLabel,
                            rpcUrls: [config.rpc],
                            iconUrls: ['https://polygon.technology/wp-content/uploads/2021/01/logo-polygon.png'],
                            nativeCurrency: {
                                name: 'Matic',
                                symbol: 'Matic',
                                decimals: 18
                            },
                            blockExplorerUrls: [config.etherscanUrl]
                        }
                    ]
                })
            } catch (e) {
                // metamask is probably outdated here
                console.log(e)
            }
        }

        const web3 = Web3Service.getInstance().getWeb3()
        const account = await Web3Service.getInstance().getAccount()

        const code = await ReferralStarterKitService.getInstance().referralID(account)

        if (code != '0x0000000000000000000000000000000000000000000000000000000000000000') this.referralCode = code

        this.fetchReferrals(account)
    }

    get shortDirectLink() {
        return this.linkBaseUrl + this.referralCode?.toUpperCase()
        //return this.linkBaseUrl.substr(0, 15) + '...' + this.referralCode?.toUpperCase()
    }

    async generateCode() {
        this.isGeneratingReferralCode = true
        let code = await this.makeID(6)
        await ReferralStarterKitService.getInstance().makeReferralCode(code)

        const web3 = Web3Service.getInstance().getWeb3()
        const account = await Web3Service.getInstance().getAccount()
        code = await ReferralStarterKitService.getInstance().referralID(account)
        if (code != '0x0000000000000000000000000000000000000000000000000000000000000000') this.referralCode = code
        this.isGeneratingReferralCode = false;
    }

    makeID(length: number) {
        let result = ''
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        const charactersLength = characters.length
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
    }

    copyDirectLink() {
        this.copyToClipboard(this.linkBaseUrl  + this.referralCode?.toUpperCase())
    }

    copyCode() {
        this.copyToClipboard(this.referralCode!.toUpperCase())
    }

    copyToClipboard(value: string) {
        // create a tmp input
        const input = document.createElement('input')
        input.setAttribute('type', 'text')
        input.setAttribute('id', 'addressCopy')
        input.setAttribute('value', value)
        document.body.append(input)

        // copy the input value and remove it
        ;(document.querySelector('#addressCopy')! as HTMLInputElement).select()
        document.execCommand('copy')
        input.remove()
    }

    fetchReferrals(account: string) {

        
        axios({
        url: 'https://api.thegraph.com/subgraphs/name/cometh-game/cometh-referrals',
        method: 'post',
       
        data: {
            query: `
            {
                referrals(where: { referrer: "${account.toLowerCase()}"}) {
                  referrer
                  buyer
                  mustAmount
                  time
                  id
                }
              }
            `   
        }
        }).then((result:any) => {
            console.log(result.data)

            this.friends = [];

            result.data.data.referrals.forEach((data: any) => {
                const friend:Friend = new Friend()
                friend.address = data.buyer;
                friend.must = parseFloat(Web3Service.getInstance().getWeb3().utils.fromWei(data.mustAmount, 'ether')).toFixed(4)
                friend.date = new Date(data.time * 1000).toDateString();
                friend.txHash = data.id
                this.friends.push(friend)
            })
        });
    }
}
