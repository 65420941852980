import Comete from '@/game/models/Comete'
import { resolveTransitionHooks } from 'vue'
import Miner from '../Miner'
import { Notification } from './Notification'

export class MiningNotification extends Notification {
    public comete: Comete
    public time: number
    public miner: Miner

    constructor(miner: Miner, comete: Comete, time: number) {
        super()
        this.id = 'mining_' + miner.id
        this.miner = miner
        this.comete = comete
        this.time = time
    }
}
