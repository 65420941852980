import RentingService from '@/game/services/web3/RentingService'
import Rental from '@/models/Rental'

export default class RentalService {
    public static instance: RentalService

    public static getInstance(): RentalService {
        if (!this.instance) {
            this.instance = new RentalService()
        }
        return this.instance
    }

    async getRentals(account: string): Promise<Rental[]> {
        const rentals = Array<Rental>()
        const lendings = await RentingService.getInstance().rentingsGrantedOf(account)
        for (let i = 0; i < lendings.length; i++) {
            const rental = new Rental(lendings[i])
            rentals.push(await rental.init())
        }
        return rentals
    }
}
