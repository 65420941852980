import GameElement from '../GameElement'
import Positionnable from '../models/interfaces/Positionnable'
import * as PIXI from 'pixi.js'

export default class MapDetailModal implements GameElement {
    entity!: Positionnable

    public onExit = (move: boolean) => {
        return
    }
    update(): void {
        throw new Error('Method not implemented.')
    }
    tick(): void {
        throw new Error('Method not implemented.')
    }
    onResize(): void {
        throw new Error('Method not implemented.')
    }
    getContainer(): PIXI.Container {
        throw new Error('Method not implemented.')
    }
    enterAnimation(callback: () => void): Promise<void> {
        throw new Error('Method not implemented.')
    }
    exitAnimation(callback: () => void): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
