<template>
    <div class="bg-[#010101] pb-[92px]">
        <div class="gradient pt-[150px] mobile:pt-[68px] pb-[140px] mobile:px-4">
            <div class="max-w-[1140px] mx-auto">
                <div class="grid grid-cols-12 gap-[30px]">
                    <div
                        class="
                            col-start-2
                            mobile:col-start-1
                            col-span-5
                            mobile:col-span-12
                        "
                    >
                        <h2
                            class="
                                glow
                                text-white
                                font-display
                                uppercase
                                font-medium
                                text-[120px]
                                mobile:text-[60px]
                                leading-[100px]
                                mobile:leading-[50px]
                            "
                        >
                            Cometh Academy
                        </h2>
                        <h3
                            class="
                                text-white
                                font-display font-bold
                                text-[40px]
                                leading-[44px]
                                mobile:text-[30px] mobile:leading-[34px]
                                uppercase
                                mt-10
                                mobile:mt-2
                            "
                        >
                            the best way to start your adventure!
                        </h3>
                        <ul
                            class="
                                mt-8
                                mobile:mt-7
                                text-white text-[18px]
                                mobile:text-base
                                leading-[26px]
                                font-medium
                                flex flex-col
                                space-y-6
                            "
                        >
                            <li class="flex items-center">
                                <svg class="mr-4 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <defs>
                                        <filter id="a" color-interpolation-filters="auto">
                                            <feColorMatrix
                                                in="SourceGraphic"
                                                values="0 0 0 0 0.513725 0 0 0 0 0.286275 0 0 0 0 0.898039 0 0 0 1.000000 0"
                                            />
                                        </filter>
                                    </defs>
                                    <g fill="none" fill-rule="evenodd" filter="url(#a)" transform="translate(-248 -2905)">
                                        <path d="M248 2905h24v24h-24z" />
                                        <path fill="#021A2D" fill-rule="nonzero" d="M257 2921.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
                                    </g>
                                </svg>
                                A M.U.L.E. ship
                            </li>
                            <li class="flex items-center">
                                <svg class="mr-4 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <defs>
                                        <filter id="a" color-interpolation-filters="auto">
                                            <feColorMatrix
                                                in="SourceGraphic"
                                                values="0 0 0 0 0.513725 0 0 0 0 0.286275 0 0 0 0 0.898039 0 0 0 1.000000 0"
                                            />
                                        </filter>
                                    </defs>
                                    <g fill="none" fill-rule="evenodd" filter="url(#a)" transform="translate(-248 -2905)">
                                        <path d="M248 2905h24v24h-24z" />
                                        <path fill="#021A2D" fill-rule="nonzero" d="M257 2921.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
                                    </g></svg
                                >A unlimited learning area
                            </li>
                            <li class="flex items-center">
                                <svg class="mr-4 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <defs>
                                        <filter id="a" color-interpolation-filters="auto">
                                            <feColorMatrix
                                                in="SourceGraphic"
                                                values="0 0 0 0 0.513725 0 0 0 0 0.286275 0 0 0 0 0.898039 0 0 0 1.000000 0"
                                            />
                                        </filter>
                                    </defs>
                                    <g fill="none" fill-rule="evenodd" filter="url(#a)" transform="translate(-248 -2905)">
                                        <path d="M248 2905h24v24h-24z" />
                                        <path fill="#021A2D" fill-rule="nonzero" d="M257 2921.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
                                    </g></svg
                                >Save! Start playing from $10
                            </li>
                            <li
                                class="
                                    text-purple text-[18px]
                                    mobile:text-base
                                    leading-[26px]
                                    font-medium
                                "
                            >
                                + win your seat at the Polygon Galactic Grand Prix
                            </li>
                        </ul>

                    <ButtonPrimary @click="$emit('buy')" class="w-[200px] h-[60px] ml-5 mobile:ml-0 mobile:mt-6 mt-[38px]
                                mobile:mt-[30px]
                                text-xl
                                leading-5
                                w-[138px]
                                h-[48px]">
                        Play
                    </ButtonPrimary>
                    </div>
                    <div
                        class="
                            col-start-7
                            mobile:col-start-1
                            col-span-5
                            mobile:col-span-12
                            justify-self-end
                            mobile:px-8 mobile:-mt-10
                        "
                    >
                        <img
                            class="
                                mobile:hidden
                                max-w-[428px]
                                image-rendering-pixelated
                            "
                            src="@/assets/tournament/mule.png"
                            alt="Mule"
                        />
                        <img
                            class="
                                hidden
                                mobile:inline
                                image-rendering-pixelated
                            "
                            src="@/assets/tournament/mule_mobile.png"
                            alt="Mule"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import VideoBackground from 'vue-responsive-video-background-player'
import ButtonPrimary from '@/components/ButtonPrimary.vue'

@Options({
    components: { VideoBackground, ButtonPrimary }
})
export default class Academy extends Vue {}
</script>

<style scoped>
.gradient {
    background-image: linear-gradient(23deg, #000000 7%, #02192b 100%);
}
.glow {
    text-shadow: 0 0 14px #8349e5;
}
</style>
