export default class Resolution {
    static resolutionRatio = 1
    static availableScales = [1, 2, 3]
    static scale = 3
    static fontSize = 6
    static get realWidth() {
        return Math.floor(window.innerWidth / this.resolutionRatio)
    }

    static get realHeight() {
        return Math.floor(window.innerHeight / this.resolutionRatio)
    }

    static get margin6() {
        return 6 * this.scale
    }
    static get margin2() {
        return 2 * this.scale
    }
    static get margin3() {
        return 3 * this.scale
    }
    static get margin1() {
        return 1 * this.scale
    }

    static checkScale() {
        let scale = Math.min(window.innerWidth / 640, window.innerHeight / 360)
        scale = Math.floor(scale) + 1
        if (scale > 3) {
            scale = 3
        }
        Resolution.updateScale(scale)
        return
    }

    static updateScale(scale: number) {
        this.scale = scale
        document.dispatchEvent(new Event('onScaleChange'))
    }
}
