import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import Miner from '../models/Miner'
import Resolution from '@/helpers/Resolution'
import Colors from '@/helpers/Colors'
import MainBitmapText from '../pixi-scale/MainBitmapText'

export default class ExitShipButton implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    public btn = PIXI.Sprite.from('btn')
    public text = new MainBitmapText('Leave Galaxy', {
        fontSize: Resolution.fontSize
    })

    constructor() {
        this.container.addChild(this.btn)
        this.container.addChild(this.text)

        this.container.on('mouseover', () => {
            this.container.alpha = 0.8
        })
        this.container.on('mouseout', () => {
            this.container.alpha = 1
        })
        this.btn.width = this.text.width + 6

        this.btn.tint = Colors.Red500
        this.onResize()
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        this.text.x = this.btn.width / 2 - this.text.width / 2
        this.text.y = 1
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await callback()
    }

    getContainer(): PIXI.Container {
        return this.container
    }
}
