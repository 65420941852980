import config from '@/config'
import Web3Service from '@/game/services/Web3Service'

export default class TubeService {
    public static instance: TubeService

    public static getInstance(): TubeService {
        if (!this.instance) {
            this.instance = new TubeService()
        }
        return this.instance
    }

    private abi = [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_controller',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'Paused',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'artist',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'periodStart',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'rewardRate',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'maxStake',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'poolContract',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                }
            ],
            name: 'PoolAdded',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'prizeId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'points',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'mintFee',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'releaseTime',
                    type: 'uint256'
                }
            ],
            name: 'PrizeAdded',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'Redeemed',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'Staked',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'fromPoolId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'toPoolId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'Transferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'Unpaused',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'artist',
                    type: 'address'
                }
            ],
            name: 'UpdatedArtist',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'Withdrawn',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'points',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'mintFee',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'releaseTime',
                    type: 'uint256'
                }
            ],
            name: 'addPrize',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                }
            ],
            name: 'balanceOfAccount',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                }
            ],
            name: 'balanceOfPool',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'controller',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'periodStart',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'maxStake',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'rewardRate',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'controllerShare',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'artist',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'poolContract',
                    type: 'address'
                },
                {
                    internalType: 'contract IERC20',
                    name: 'token',
                    type: 'address'
                }
            ],
            name: 'createPool',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                }
            ],
            name: 'earned',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                }
            ],
            name: 'exit',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'paused',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'pendingWithdrawals',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'pools',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'periodStart',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'maxStake',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'rewardRate',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'feesCollected',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'spentPoints',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'controllerShare',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'artist',
                    type: 'address'
                },
                {
                    internalType: 'contract IPool',
                    name: 'poolContract',
                    type: 'address'
                },
                {
                    internalType: 'contract IERC20',
                    name: 'token',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'prize',
                    type: 'uint256'
                }
            ],
            name: 'prizeMintFee',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'prize',
                    type: 'uint256'
                }
            ],
            name: 'prizePoints',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'prize',
                    type: 'uint256'
                }
            ],
            name: 'prizeReleaseTime',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'prize',
                    type: 'uint256'
                }
            ],
            name: 'redeem',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                }
            ],
            name: 'rescuePoints',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'rescuer',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'artist',
                    type: 'address'
                }
            ],
            name: 'setArtist',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_controller',
                    type: 'address'
                }
            ],
            name: 'setController',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_controllerShare',
                    type: 'uint256'
                }
            ],
            name: 'setControllerShare',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_rescuer',
                    type: 'address'
                }
            ],
            name: 'setRescuer',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'stake',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'fromPool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'toPool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'transfer',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'fromPool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'toPool',
                    type: 'uint256'
                }
            ],
            name: 'transferAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'pool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'withdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'withdrawFee',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]
    private writeTube: any
    private readTube: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const web3 = Web3Service.getInstance().getWeb3()
        this.writeTube = new web3.eth.Contract(this.abi, config.tubeAddress, { gasLimit: '1000000' })

        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readTube = new wssWeb3.eth.Contract(this.abi, config.tubeAddress)
    }

    public async getPool(id: number) {
        return await this.readTube.methods.pools(id).call()
    }

    public async prizePoints(poolId: number, priceId: number) {
        return await this.readTube.methods.prizePoints(poolId, priceId).call()
    }

    public async earned(poolId: number): Promise<string> {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.readTube.methods.earned(account, poolId).call()
    }

    public async balanceOf(poolId: number): Promise<string> {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.readTube.methods.balanceOf(account, poolId).call()
    }

    public async balanceOfAccount(token: string): Promise<string> {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.readTube.methods.balanceOfAccount(account, token).call()
    }

    public async stake(poolId: number, amount: string): Promise<string> {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.writeTube.methods.stake(poolId, amount).send({ from: account, gasPrice: '50000000000' })
    }

    public async withdraw(poolId: number, amount: string): Promise<string> {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.writeTube.methods.withdraw(poolId, amount).send({ from: account, gasPrice: '50000000000' })
    }

    public async redeem(poolId: number, prizeId: number): Promise<string> {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.writeTube.methods.redeem(poolId, prizeId).send({ from: account, gasPrice: '50000000000' })
    }
}
