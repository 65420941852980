import Colors from '@/helpers/Colors'

import MainBitmapText from '../../pixi-scale/MainBitmapText'
import Web3Service from '../../services/Web3Service'
import WalletService from '../../services/web3/WalletService'

import Web3 from 'web3'
import ModalComponent from './ModalComponent'

export default class WalletModal extends ModalComponent {
    constructor() {
        super(120, 128)
        this.hPosistion = 50

        const titleBottom = PIXI.Sprite.from('modal_bottom_title')
        titleBottom.x = (this.modalWidth - titleBottom.width) / 2
        titleBottom.y = this.modalHeight - 1

        const titleText = new MainBitmapText('Wallet', {
            fontSize: 5
        })
        titleText.x = (this.modalWidth - titleText.width) / 2
        titleText.y = titleBottom.y + 1
        this.modalContainer.addChild(titleBottom)
        this.modalContainer.addChild(titleText)
        this.setPositions()

        this.getWalletDatas()
    }

    private async getWalletDatas() {
        this.dataContainer.visible = false
        this.dataContainer.y = 6
        const service = WalletService.getInstance()

        const pairs = [
            WalletService.MATIC_MUST_PAIR_ADDRESS,
            WalletService.PICKLE_MUST_PAIR_ADDRESS,
            WalletService.MUST_WETH_PAIR_ADDRESS,
            WalletService.BBADGER_MUST_PAIR_ADDRESS,
            WalletService.BDIGG_MUST_PAIR_ADDRESS,
            WalletService.GHST_MUST_PAIR_ADDRESS
        ]
        const pairsNames: any = {}
        pairsNames[WalletService.MATIC_MUST_PAIR_ADDRESS] = 'MATIC/MUST'
        pairsNames[WalletService.PICKLE_MUST_PAIR_ADDRESS] = 'PICKLE/MUST'
        pairsNames[WalletService.MUST_WETH_PAIR_ADDRESS] = 'MUST/WETH'
        pairsNames[WalletService.BBADGER_MUST_PAIR_ADDRESS] = 'bBADGER/MUST'
        pairsNames[WalletService.BDIGG_MUST_PAIR_ADDRESS] = 'MUST/bDIGG'
        pairsNames[WalletService.GHST_MUST_PAIR_ADDRESS] = 'GHST/MUST'

        let total = 0

        const maticTitle = new MainBitmapText('MATIC', { fontSize: 5 })
        maticTitle.tint = Colors.Blue800
        maticTitle.x = 6
        maticTitle.y = 22

        const maticBalance = Web3.utils.fromWei(await Web3Service.getInstance().getBalance(), 'ether')
        const value = new MainBitmapText(maticBalance, { fontSize: 4 })
        value.x = maticTitle.x
        value.y = maticTitle.y + maticTitle.height

        const maticPrice = await service.maticPrice()
        const dollarValue = maticPrice * parseFloat(maticBalance) // TODO Put real dollar value
        total += dollarValue

        const valueDollar = new MainBitmapText(dollarValue.toFixed(2) + ' $', { fontSize: 5 })
        valueDollar.tint = Colors.Green500
        valueDollar.y = maticTitle.y
        valueDollar.x = Math.floor(this.modalWidth - valueDollar.width - 6)

        this.dataContainer.addChild(maticTitle)
        this.dataContainer.addChild(value)
        this.dataContainer.addChild(valueDollar)

        for (let index = 0; index < pairs.length; index++) {
            const pair = pairs[index]

            const title = new MainBitmapText(pairsNames[pair], { fontSize: 5 })
            title.tint = Colors.Blue800
            title.x = 6
            title.y = maticTitle.y + 14 + 14 * index + 1

            const value = new MainBitmapText(Web3.utils.fromWei((await service.balanceOfConnected(pair)).toString(), 'ether'), { fontSize: 4 })
            value.x = title.x
            value.y = title.y + title.height

            const dollarValue = await service.balanceUSDOfConnected(pair)
            total += dollarValue

            const valueDollar = new MainBitmapText(dollarValue.toFixed(2) + ' $', { fontSize: 5 })
            valueDollar.tint = Colors.Green500
            valueDollar.y = title.y
            valueDollar.x = Math.floor(this.modalWidth - valueDollar.width - 6)

            this.dataContainer.addChild(title)
            this.dataContainer.addChild(value)
            this.dataContainer.addChild(valueDollar)
        }

        const totalView = new MainBitmapText(total.toFixed(2) + ' $', { fontSize: 6 })
        totalView.tint = Colors.Green500
        totalView.x = Math.floor(this.modalWidth / 2 - totalView.width / 2 + 2)
        totalView.y = 0

        this.dataContainer.addChild(totalView)
        this.setPositions()
        this.dataContainer.visible = true
        this.loader.visible = false
    }
}
