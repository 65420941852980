import { Options, Vue } from 'vue-class-component'

import { CheckIcon, XIcon } from '@heroicons/vue/outline'

import Connection, { ConnectionType } from '@/components/connection/Connection'
import Header from '@/components/header/Header.vue'
import ComethHeader from '@/components/header/Header'
import StarterkitClass from '@/views/Shop/Starterkit/Starterkit'
import ButtonWithLoader from '@/components/button-with-loader/ButtonWithLoader.vue'
import CTAReferral from '@/views/Referral/CTAReferral.vue'
import ShopItemList from '@/views/Shop/ShopItemList.vue'
import ShopData from '@/views/Shop/ShopData'
import { ShopItem } from '@/views/Shop/ShopItemList'


@Options({
    components: { Header, ButtonWithLoader, CheckIcon, XIcon, CTAReferral, ShopItemList }
})
export default class Shop extends Vue {
    $refs!: {
        header: ComethHeader
        starterkit: StarterkitClass
    }

    showCart = false
    isConnecting = false
    isConnected = false
    hasEnoughMatic = false
    isBuyingCrypto = false
    showConfirmationModal = false
    isBuying = false
    showSuccessfulPurchase = false
    shopItems:ShopItem[] = []

    mounted() {

        this.shopItems = ShopData.getInstance().getItems();

        Connection.getInstance().on('connecting', () => {
            console.log('connecting...')
            // this.isChangingConnection = true;
        })

        Connection.getInstance().on('providerChanged', async provider => {
            console.log('provider changed...')
            // this.isChangingConnection = false;
            // check if disconnected
            if (!provider) {
                // user is disconnected
                return
            }
            // await this.init();
        })   
    }

    signIn() {
        this.isConnecting = true

        setTimeout(() => {
            this.isConnected = true
        }, 3000)
        // this.$refs.header.openModal();
    }

    buyCrypto() {
        this.isBuyingCrypto = true

        setTimeout(() => {
            this.hasEnoughMatic = true
        }, 3000)
        // this.$refs.header.buyCrypto();
    }

    buyKit() {
        this.showConfirmationModal = true
    }

    confirmPurchase() {
        this.showConfirmationModal = false
        this.isBuying = true

        setTimeout(() => {
            this.showCart = false
            this.showSuccessfulPurchase = true
        }, 3000)
    }

    async onRampPurchased(payload: any) {
        // console.log(payload);
        console.log('shop onRampPurchased')

        this.$refs.starterkit.init()
    }

    addToCart(product: string) {
        this.showCart = true
    }
}
