<template>
    <a
        :href="href"
        :class="color"
        class="
            text-white
            uppercase
            font-pixel
            text-2xl
            leading-6
            focus:outline-none
            inline-flex
            justify-center
            items-center
            cursor-pointer
        "
    >
        <slot />
    </a>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        href: String,
        color: {
            type: String,
            default: 'purple'
        }
    }
})
export default class ButtonPrimary extends Vue {
    href!: string
    color!: string
}
</script>

<style scoped>
a.blue {
    background: #57c2ff;
    box-shadow: inset -2px -2px 0 0 rgba(42, 108, 176, 0.52), inset 2px 2px 0 0 rgba(255, 255, 255, 0.5);
}
a.blue:hover {
    background: #3aa0da;
    box-shadow: 0 0 14px 0 #57c2ff, inset -2px -2px 0 0 rgba(42, 108, 176, 0.52), inset 2px 2px 0 0 rgba(255, 255, 255, 0.5);
}
a.purple {
    background: #8349e5;
    box-shadow: inset -2px -2px 0 0 rgba(85, 37, 169, 0.38), inset 2px 2px 0 0 rgba(236, 226, 255, 0.58);
}
a.purple:hover {
    background: #6c32cf;
    box-shadow: 0 0 14px 0 #8349e5, inset -2px -2px 0 0 rgba(85, 37, 169, 0.38), inset 2px 2px 0 0 rgba(236, 226, 255, 0.58);
}
a.yellow {
    color: #af8700;
    background: #fcc300;
    box-shadow: inset -2px -2px 0 0 #af8700, inset 2px 2px 0 0 rgba(255, 255, 255, 0.5);
}
a.yellow:hover {
    color: #866700;
    background: #c19500;
    box-shadow: 0 0 10px 0 #fde700, inset -2px -2px 0 0 #af8700, inset 2px 2px 0 0 rgba(255, 255, 255, 0.5);
}
</style>
