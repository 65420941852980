import NFTService from '@/game/services/NFTService'

export default class ShipNFT {
    public id = '0'
    public miningArea = 0
    public drill = 0
    public cooldown = 0
    public roverPower = 0
    public serialNumber = '0'
    public name = ''
    public model = ''
    public race = ''
    public scarcity = ''
    public image = ''
    public supply = ''
    public modelId = 0

    private _metadata?: any

    constructor(id: string) {
        this.id = id
    }

    public async updateFromMetaData(): Promise<ShipNFT> {
        await this.fetchMetadata()

        this.name = this._metadata.name
        this.supply = this._metadata.name.split('/')[1]
        this.image = this._metadata.image

        this.serialNumber = this.findAttribute('serial_number')
        this.race = this.findAttribute('race')
        this.scarcity = this.findAttribute('scarcity')
        this.model = this.findAttribute('model')

        this.miningArea = this._metadata.stats.miningArea
        this.drill = Number(this._metadata.stats.drill)
        this.cooldown = Number(this._metadata.stats.cooldown)
        this.roverPower = Number(this._metadata.stats.roverPower)

        this.modelId = Math.floor(Number(this.id) / 1_000_000)
        return this
    }

    public findAttribute(trait: string): string {
        const attributes = this._metadata.attributes as Array<any>

        let result = ''
        attributes.forEach(item => {
            if (item.trait_type == trait) {
                result = item.value
            }
        })

        return result
    }

    private async fetchMetadata() {
        const metadata = await NFTService.getInstance().getMetadata(this.id)
        this._metadata = metadata.data
    }
}
