import axios from 'axios'
import Miner from '../models/Miner'
import { PlayerStats } from '../models/PlayerStats'
import PlayerService from './PlayerService'
import Web3Service from './Web3Service'

export default class StatsService {
    public static instance: StatsService

    public static getInstance(): StatsService {
        if (!this.instance) {
            this.instance = new StatsService()
        }

        return this.instance
    }

    public async getStatsMiner(miner: Miner): Promise<PlayerStats> {
        const account: string = await Web3Service.getInstance().getAccount()
        let playerId = account

        const rentedShip = PlayerService.getInstance().rentedMiners.find(it => it.minerId == miner.id)
        if (rentedShip) {
            playerId = rentedShip.contractAddress
        }

        return (await axios.get('https://game-data-api.cometh.io/api/graph/players/' + playerId + '/ships/' + miner.id + '/stats')).data
    }

    public async getStatsForPlayer(): Promise<PlayerStats> {
        const web3 = Web3Service.getInstance().getWeb3()
        const account: string = await Web3Service.getInstance().getAccount()
        return (await axios.get('https://game-data-api.cometh.io/api/graph/players/' + account + '/stats?period=BIG_BANG')).data
    }
}
