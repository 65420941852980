import ComethConfig from './game/models/ComethConfig'

let config: ComethConfig

if (process.env.VUE_APP_DEV == 'true') {
    config = {
        spiceAddress: process.env.SPICE_ADDRESS || '0xCd47A4992BeC3dd1851a448d512800dEC7ba9e9e',
        spaceshipsAddress: process.env.SPACESHIPS_ADDRESS || '0xe2E5094fd6e7AeE2046C08b98DD7e870d766194f',
        gameMinerAddress: process.env.GAME_MINER_ADDRESS || '0x5914968ABf37Ca1f5BdfF9f3ddF398d589A3E763',
        stackedSpaceshipAddress: process.env.STACKED_SPACESHIP_ADDRESS || '0xEb4d50b7C0432f63A749116a95579be317340bb5',
        mustManagerAddress: process.env.MUST_MANAGER_ADDRESS || '0x6a12e166666B158f85B01965F360a5b8Daf220a5',
        cometManagerAddress: process.env.COMET_MANAGER_ADDRESS || '0x95D9b260ef8071DD6bea36DE89d93dc7aF1bcD24',
        minerManagerAddress: process.env.MINER_MANAGER_ADDRESS || '0x656b71244231fE5f40D5847eAd9314d48eEFCaFE',
        jumpManagerAddress: process.env.JUMP_MANAGER_ADDRESS || '0x8648ee002DE41F069290b6eF048c966314a5591D',
        miningManagerAddress: process.env.MINING_MANAGER_ADDRESS || '0x22bF5d997e561f97858D3a642580c425293b3F9A',
        solarSystemManagerAddress: process.env.SOLAR_SYSTEM_MANAGER || '0x6D7B2363a5acE8effeFC9199795b98F1deE7477B',
        portalManagerAddress: process.env.PORTAL_MANAGER_ADDRESS || '0x7E5140eD02eaC50e5e6a8409861Ea99cC7fc919E',
        erc1155Address: process.env.ERC1155_ADDRESS || '0x81399A1eb874A0A6ccFDFD279fF4d8C1C0d3dea7',
        erc1155Ids: [145, 146, 147, 148],
        mulesAddress: process.env.MULES_ADDRESS || '0x36Eff42AFC9794d6E4c08323d8B878De1C683902',
        mulesId: 1000000000,
        converterAddress: process.env.CONVERTER_ADDRESS || '0x4B6e055b66f78cFDdE6bf296EAAf8d13108BE2E6',
        gameItemsAddress: process.env.GAME_ITEMS_ADDRESS || '0xe7A68d8924AcE3Cef938aA729A0cAB5e4AF62ac7',
        gameItemsManagerAddress: process.env.GAME_ITEMS_MANAGER_ADDRESS || '0xf0eDca8187467A4c7ea8070121aF8b527151B218',
        coolingBoosterManagerAddress: process.env.COOLING_BOOSTER_MANAGER_ADDRESS || '0xBBEf8fd6BDfab98A0e9e473Fa644313a57ac6047',

        network: process.env.NETWORK_ID || 80001,
        rentingFactoryAddress: process.env.RENTING_FACTORY_ADDRES || '0xdFBD6c7E552d23cc90075e995B86A844C62307F3',
        uniswapRouterAddress: process.env.UNISWAP_ROUTER_ADDRES || '0x93bcDc45f7e62f89a8e901DC4A0E2c6C427D9F25',
        starterKitAddress: process.env.STARTER_KIT_ADDRES || '0x74869cCf1c0Aa07793492A424f8026CBfb9FF741',

        blueShipAddress: process.env.BLUE_SHIP_ADDRESS || '0x74869cCf1c0Aa07793492A424f8026CBfb9FF741',
        referralStarterKitAddress: process.env.REFERRAL_STARTER_KIT_ADDRES || '0x059F9A265c63A19E6518A4ee4Bb21600fE326F06',

        roverManagerAddress: process.env.ROVER_MANAGER_ADDRESS || '0x5a85a8feA8553Dd074e6E94Cf8DCF7171B3fA94F',
        tubeAddress: process.env.TUBE_ADDRESS || '0x31842B19b6B4B84eb5ceE87Fd82FB0333B9019F4',
        dustMigrationAddress: process.env.DUST_MIGRATION_ADDRESS || '0xEccf89CA1ca7A6cAB941dca7c172E801d1103BB1',
        defaultTubeIndex: 1,

        networkLabel: process.env.NETWORK_LABEL || 'Mumbai-Testnet',
        etherscanUrl: process.env.ETHERSCAN_URL || 'https://explorer-mumbai.maticvigil.com/',
        web3ModalNetwork: process.env.WEB3_MODAL_NETWORK || 'matic',
        infuraId: process.env.INFURA_ID || '2e297f45a4fd4a84b371c9fe432e2e6f',
        shipImageUrl: process.env.SHIP_IMAGE_URL || 'https://images.service.cometh.io',

        l2NetworkLabel: process.env.L2_NETWORK_LABEL || 'Matic Mumbai',
        rpc: process.env.RPC || 'https://polygon-mumbai.infura.io/v3/acce0cfb78984d439d6e2ac7e1b82845',
        innerRpc: process.env.RPC || 'https://polygon-mumbai.infura.io/v3/acce0cfb78984d439d6e2ac7e1b82845',
        innerWsRpc: process.env.RPC || 'wss://matic-testnet-archive-ws.bwarelabs.com',

        polygonscanUrl: 'https://mumbai.polygonscan.com/tx/',

        // FOLLOWING DONT WORK
        ticketsAddress: '0x2d0D9b4075E231bFf33141d69DF49FFcF3be7642',
        /*
           process.env.VUE_APP_TICKETS_ADDRESS ||
           "0x6674d552c61861E89C603AF01775f3514801043A",
         */
        tickets: [
            { model: '45', ticketId: '10871620308167' }, // Konpeito
            { model: '46', ticketId: '3841620308557' }, // Ramune
            { model: '47', ticketId: '10691620308732' } // Anpan
        ],
        rewardClaimerAddress: '0xFcdE4B0D57aD803660AFCFf063FC2eB096B5f8b9',

        ticketsExchangeAddress: '0x30812ACfC2B69bE07463a41678c51bF097154ccD',

        machineUrl: process.env.VUE_APP_MACHINE_URL || 'https://dev.degacha.com/playgacha/4/0x3aE0670970C3273eD5181B995448Cd482Db8986E',
        claimableAirdropAddress: '0x03a2d4d9ff57699cc8Ea0c167b1Eb5Fa3Dd52f23',
        kioskProviderUrl: 'https://polygon-mainnet.infura.io/v3/33b7ec08483346aca7c272999fa78771',
        mixpanelProjectToken: 'e249bdb76032c1626fa30e7b96349aeb'
    }
} else {
    config = {
        spiceAddress: process.env.SPICE_ADDRESS || '0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f',
        spaceshipsAddress: process.env.SPACESHIPS_ADDRESS || '0x85BC2E8Aaad5dBc347db49Ea45D95486279eD918',
        gameMinerAddress: process.env.GAME_MINER_ADDRESS || '0x7A970052562bE9D031161fF7dE3B6C00e4E1bD89',
        stackedSpaceshipAddress: process.env.STACKED_SPACESHIP_ADDRESS || '0xe07EaFe9fFc1363b6c4C466837a08AceD54f9dF9',
        mustManagerAddress: process.env.MUST_MANAGER_ADDRESS || '0x9000c901bc55cB44C9a2c84cF96e8E0bD317760d',
        cometManagerAddress: process.env.COMET_MANAGER_ADDRESS || '0x78f3b8403A85A4c27E6a635Ec012De3eB8f1a072',
        minerManagerAddress: process.env.MINER_MANAGER_ADDRESS || '0xA7f7f2122d1758696B7c25191EC095AdC35fE4E1',
        jumpManagerAddress: process.env.JUMP_MANAGER_ADDRESS || '0x678914c1108c004DbbC8Ec5497E58f4Dc77BcBC0',
        miningManagerAddress: process.env.MINING_MANAGER_ADDRESS || '0x9179f718d366Ba1469001c940Cfd89746852d240',
        solarSystemManagerAddress: process.env.SOLAR_SYSTEM_MANAGER || '0x8C4572FE95c6AA84567489b577cB36A4CCd5851C',
        portalManagerAddress: process.env.PORTAL_MANAGER_ADDRESS || '0x9782C028755125764f5daCfDCfD804F80dA3c51F',
        erc1155Address: process.env.ERC1155_ADDRESS || '0xEC02799CF8EE0e975Ad4f2054Bc9E98eDB250702',
        erc1155Ids: [145, 146, 147, 148],
        mulesAddress: process.env.MULES_ADDRESS || '0xC0cEE8f3799Be895e8F53ee97Ab66d7581017b5f',
        mulesId: 1000000000,
        converterAddress: process.env.CONVERTER_ADDRESS || '0x57D0a0199CeE2Ae7Eb0f5cF4bCB892ad58085560',
        gameItemsAddress: process.env.GAME_ITEMS_ADDRESS || '0xbCa30d6d18F0c5aC15e0bE5C9B389d2Df207D19E',
        gameItemsManagerAddress: process.env.GAME_ITEMS_MANAGER_ADDRESS || '0x63c9F20A07d7E9111F304b6739e66833F6C4d3e1',
        coolingBoosterManagerAddress: process.env.COOLING_BOOSTER_MANAGER_ADDRESS || '0x23556DC03D2D1C0875cd2eaE9082eD5cBDBA6F47',

        network: process.env.NETWORK_ID || 137,
        rentingFactoryAddress: process.env.RENTING_FACTORY_ADDRES || '0xc7aD82C1Ca8f122765B6A2454CF5acb1b6b83C39',
        uniswapRouterAddress: process.env.UNISWAP_ROUTER_ADDRES || '0x93bcDc45f7e62f89a8e901DC4A0E2c6C427D9F25',
        starterKitAddress: process.env.STARTER_KIT_ADDRES || '0x74869cCf1c0Aa07793492A424f8026CBfb9FF741',

        blueShipAddress: process.env.BLUE_SHIP_ADDRESS || '0x74869cCf1c0Aa07793492A424f8026CBfb9FF741',
        referralStarterKitAddress: process.env.REFERRAL_STARTER_KIT_ADDRES || '0xD8E0E1d1e6df29BA6f0A5e7C4f863C66390633C9',

        roverManagerAddress: process.env.ROVER_MANAGER_ADDRESS || '0x3C92Fd7202A87a85F6f6FED174B6Af5D7ff9b040',
        tubeAddress: process.env.TUBE_ADDRESS || '0xB2a80D927C3eB227dE880DAD0EB957BFc0594566',
        dustMigrationAddress: process.env.DUST_MIGRATION_ADDRESS || '0xee8F8F05eea48AE1F2b03a060D44E63Ab73d064a',
        defaultTubeIndex: 0,

        networkLabel: process.env.NETWORK_LABEL || 'Matic',
        etherscanUrl: process.env.ETHERSCAN_URL || 'https://explorer-mainnet.maticvigil.com/',
        web3ModalNetwork: process.env.WEB3_MODAL_NETWORK || 'matic',
        infuraId: process.env.INFURA_ID || '2e297f45a4fd4a84b371c9fe432e2e6f',
        shipImageUrl: process.env.SHIP_IMAGE_URL || 'https://images.service.cometh.io',

        l2NetworkLabel: process.env.L2_NETWORK_LABEL || 'Matic',
        rpc: process.env.RPC || 'https://rpc-mainnet.matic.network',
        innerRpc: process.env.RPC || 'https://polygon-mainnet.infura.io/v3/acce0cfb78984d439d6e2ac7e1b82845',
        innerWsRpc: process.env.RPC || 'wss://polygon-mainnet.g.alchemy.com/v2/8AnnbI4BmVcp-t5Rr8DKVOr_FLah_fPL',

        polygonscanUrl: 'https://polygonscan.com/tx/',

        ticketsAddress: '0x2d0D9b4075E231bFf33141d69DF49FFcF3be7642',
        /*
           process.env.VUE_APP_TICKETS_ADDRESS ||
           "0x6674d552c61861E89C603AF01775f3514801043A",
         */
        tickets: [
            { model: '45', ticketId: '10871620308167' }, // Konpeito
            { model: '46', ticketId: '3841620308557' }, // Ramune
            { model: '47', ticketId: '10691620308732' } // Anpan
        ],
        rewardClaimerAddress: '0xFcdE4B0D57aD803660AFCFf063FC2eB096B5f8b9',

        ticketsExchangeAddress: '0x30812ACfC2B69bE07463a41678c51bF097154ccD',

        machineUrl: process.env.VUE_APP_MACHINE_URL || 'https://degacha.com/playgacha/3/0x45F68d7fCcB918b31A6868d489439CEd4A472730',

        claimableAirdropAddress: '0x3ACA2d3E0342217663D667bbe4E7a135183D4C21',
        kioskProviderUrl: 'https://polygon-mainnet.infura.io/v3/33b7ec08483346aca7c272999fa78771',
        mixpanelProjectToken: 'c6d30134ac83d269d50243fdca0fd0ed'
    }
}

export default config
