import config from '@/config'
import { AbiItem } from 'web3-utils'
import Web3Service from '@/game/services/Web3Service'

export default class SpaceShipsService {
    public static instance: SpaceShipsService

    public static getInstance(): SpaceShipsService {
        if (!this.instance) {
            this.instance = new SpaceShipsService()
        }
        return this.instance
    }
    private abi = [
        {
            inputs: [],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'approved',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'ApprovalForAll',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'maxSupply',
                    type: 'uint256'
                }
            ],
            name: 'NewModel',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'Paused',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'previousAdminRole',
                    type: 'bytes32'
                },
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'newAdminRole',
                    type: 'bytes32'
                }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                }
            ],
            name: 'RoleGranted',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                }
            ],
            name: 'RoleRevoked',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'Unpaused',
            type: 'event'
        },
        {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'ID_TO_MODEL',
            outputs: [
                {
                    internalType: 'uint32',
                    name: '',
                    type: 'uint32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'MODEL_CREATOR_ROLE',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'baseURI',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'getApproved',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                }
            ],
            name: 'getRoleAdmin',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'uint256',
                    name: 'index',
                    type: 'uint256'
                }
            ],
            name: 'getRoleMember',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                }
            ],
            name: 'getRoleMemberCount',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'hasRole',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                }
            ],
            name: 'isApprovedForAll',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'nextId',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'ownerOf',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'paused',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: '_data',
                    type: 'bytes'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'supply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes4',
                    name: 'interfaceId',
                    type: 'bytes4'
                }
            ],
            name: 'supportsInterface',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'index',
                    type: 'uint256'
                }
            ],
            name: 'tokenByIndex',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'index',
                    type: 'uint256'
                }
            ],
            name: 'tokenOfOwnerByIndex',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'tokenURI',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'maxSupply',
                    type: 'uint256'
                }
            ],
            name: 'newModel',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'model',
                    type: 'uint256'
                }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    private readContract: any
    private writeContract: any

    constructor() {
        this.initContract()
    }

    public initContract() {
        const wssWeb3 = Web3Service.getInstance().getWssWeb3()
        this.readContract = new wssWeb3.eth.Contract(this.abi, config.spaceshipsAddress)

        const web3 = Web3Service.getInstance().getWeb3()
        this.writeContract = new web3.eth.Contract(this.abi, config.spaceshipsAddress, { gasLimit: '1000000' })
    }

    public async balanceOf(address: string) {
        return await this.readContract.methods.balanceOf(address).call()
    }

    public async tokenOfOwnerByIndex(address: string, index: number) {
        return await this.readContract.methods.tokenOfOwnerByIndex(address, index).call()
    }

    public async getApproved(index: number) {
        return await this.readContract.methods.getApproved(index).call()
    }

    public async approve(address: string, tokenId: number) {
        const account: string = await Web3Service.getInstance().getAccount()
        return await this.writeContract.methods.approve(address, tokenId).send({ from: account, gasPrice: '50000000000' })
    }

    public async tokensOfConnected(): Promise<number[]> {
        const account: string = await Web3Service.getInstance().getAccount()
        const balance = await this.balanceOf(account)
        const tokens = []

        for (let i = 0; i < balance; i++) {
            tokens[i] = await this.tokenOfOwnerByIndex(account, i)
        }
        return tokens
    }

    public async tokensOf(address: string): Promise<number[]> {
        const balance = await this.balanceOf(address)
        const tokens = []
        for (let i = 0; i < balance; i++) {
            tokens[i] = await this.tokenOfOwnerByIndex(address, i)
        }
        return tokens
    }

    public async tokenURI(tokenId: number) {
        return await this.readContract.methods.tokenURI(tokenId).call()
    }

    public async supply(model: number) {
        return await this.readContract.methods.supply(model).call()
    }

    public async baseURI() {
        return await this.readContract.methods.baseURI().call()
    }

    public async nextId(model: number) {
        return await this.readContract.methods.nextId(model).call()
    }

    public async remaining(model: number) {
        const next = await this.nextId(model)
        const maxSupply = await this.supply(model)
        return maxSupply - next
    }

    public async spaceshipsOf(address: string): Promise<any> {
        const balance = Number(await this.balanceOf(address))
        const indexes = Array.from(Array(balance).keys())
        const spaceships: any = await Promise.all(
            Array.from(indexes).map(async index => {
                const tokenId = await this.readContract.methods.tokenOfOwnerByIndex(address, index).call()
                const image = await this.readContract.methods.tokenURI(tokenId).call()
                return { tokenId, image }
            })
        )
        return spaceships
    }

    public async safeTransferFrom(to: string, tokenId: string, data: any) {
        const web3 = Web3Service.getInstance().getWeb3()
        const account: string = await Web3Service.getInstance().getAccount()

        await this.writeContract.methods.safeTransferFrom(account, to, tokenId, data).send({ from: account, gasPrice: '50000000000' })
    }
}
