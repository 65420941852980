import Resolution from '@/helpers/Resolution'
import GameElement from '../../GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import NotificationService from '../../services/NotificationService'
import NotificationComponent from './NotificationComponent'
import { Notification, NotificationType } from '../../models/notification/Notification'
import { TransactionNotification } from '../../models/notification/TransactionNotification'
import TransactionNotificationComponent from './TransactionNotificationComponent'
import Web3Service from '../../services/Web3Service'
import MiningNotificationComponent from './MiningNotificationComponent'
import { MiningNotification } from '../../models/notification/MiningNotification'
import { PortalTravelNotification } from '@/game/models/notification/PortalTravelNotification'
import PortalTravelNotificationComponent from './PortalTravelNotificationComponent'
import StakingComet from '@/game/models/StakingComet'
import DepositRoverMiningNotification from './DepositRoverMiningNotification'
import HarvestRoverMiningNotification from './HarvestRoverMiningNotification'

export default class NotificationManager implements GameElement {
    public static instance: NotificationManager

    public static getInstance(): NotificationManager {
        if (!this.instance) {
            this.instance = new NotificationManager()
        }

        return this.instance
    }

    public container: PIXI.Container = new PIXI.Container()
    private txNotifComponent = new TransactionNotificationComponent(new Notification(NotificationType.Default))

    public notifications: NotificationComponent[] = []
    constructor() {
        this.notifications.push(this.txNotifComponent)
        this.container.addChild(this.txNotifComponent.getContainer())
        this.txNotifComponent.onHide = () => {
            this.txNotifComponent.getContainer().visible = false
            this.checkNotifPositions()
        }
        this.txNotifComponent.onClick = () => {
            if (this.txNotifComponent.notifications.length > 1) {
                document.dispatchEvent(new Event('requestOpenTxModal'))
            } else {
                Web3Service.getInstance().openTxScan(this.txNotifComponent.notifications[0].transaction.hash)
            }
        }
        this.checkNotifPositions()
        NotificationService.getInstance().setOnNotificationUpdated(() => {
            this.checkNotifPositions()
        })
        NotificationService.getInstance().setOnNewNotification((notif: Notification) => {
            let notifComponent: NotificationComponent
            if (notif instanceof TransactionNotification) {
                this.txNotifComponent.addNotifTx(notif)
            } else {
                if (notif instanceof MiningNotification) {
                    if (notif.comete instanceof StakingComet) {
                        if (notif.miner.rover == notif.comete.id) {
                            notifComponent = new HarvestRoverMiningNotification(notif)
                        } else {
                            notifComponent = new DepositRoverMiningNotification(notif)
                        }
                    } else {
                        notifComponent = new MiningNotificationComponent(notif)
                    }
                } else if (notif instanceof PortalTravelNotification) {
                    notifComponent = new PortalTravelNotificationComponent(notif)
                } else {
                    notifComponent = new NotificationComponent(notif)
                    notifComponent.onClick = () => {
                        this.destroyNotification(notifComponent)
                    }
                }

                notifComponent.container.visible = true
                notifComponent.enterAnimation(() => {
                    return
                })
                notifComponent.onDestroy = () => {
                    this.destroyNotification(notifComponent)
                }
                this.notifications.push(notifComponent)
                this.container.addChild(notifComponent.getContainer())
            }

            this.checkNotifPositions()
        })
    }

    cleanMiningNotification(minerId: string): void {
        this.notifications.forEach((a, index) => {
            if (a.notification.id == 'mining_' + minerId) {
                this.notifications.splice(index, 1)
                this.destroyNotification(a)
            }
        })
        this.checkNotifPositions()
    }

    hasMiningNotification(minerId: string): boolean {
        return this.notifications.filter(it => it.notification.id == 'mining_' + minerId).length > 0
    }

    tick(): void {
        // nothing
    }

    onResize(): void {
        this.notifications.forEach(a => a.onResize())
        this.checkNotifPositions()
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    async enterAnimation(callback: () => void): Promise<void> {
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        callback()
    }

    public isNotificationDisplayed(id: string) {
        let found = false
        this.notifications.forEach(a => {
            if (a.notification.id == id) {
                found = true
            }
        })
        return found
    }

    private checkNotifPositions() {
        let currentY = Math.floor(Resolution.realHeight / Resolution.scale - 70)
        this.container.children.forEach(a => {
            if (!a.visible) return

            const container = a as PIXI.Container
            container.x = 6
            container.y = Math.floor(currentY - container.height - 5)
            currentY = container.y
        })
        document.dispatchEvent(new Event('onNotificationListChanged'))
    }

    private destroyNotification(notifComponent: NotificationComponent) {
        notifComponent.exitAnimation(() => {
            notifComponent.getContainer().destroy()
            this.notifications.splice(this.notifications.indexOf(notifComponent), 1)
            this.checkNotifPositions()
        })
    }

    public setOnNotificationListChanged(callback: () => void) {
        document.addEventListener('onNotificationListChanged', () => {
            callback()
        })
    }

    public removedOnNotificationListChanged(callback: () => void) {
        document.removeEventListener('onNotificationListChanged' as any, callback)
    }
}
