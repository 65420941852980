import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import MinerPlayerComponent from './MinerPlayerComponent'
import Miner from '../models/Miner'
import PlayerService from '../services/PlayerService'
import ScrollableContainer from '@/helpers/ScrollHelper'
import ShipCharacteristicsComponent from './ShipCharacteristicsComponent'
import MapService from '../services/MapService'
import ShipExitModal from './modals/ShipExitModal'
import ExitShipButton from './ExitShipButton'
import Colors from '@/helpers/Colors'
import PlayerShipStatComponent from './stats/PlayerShipStatComponent'
import { animate } from '@/helpers/AnimationHelper'
import { Star } from '../models/Star'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import { GlowFilter } from '@pixi/filter-glow'
import { OutlineFilter } from '@pixi/filter-outline'
import RoverManagerService from '../services/web3/RoverManagerService'
import { cutText } from '@/helpers/StringHelper'

enum ShipMenu {
    Ship,
    Stat
}

class StarCateoryItem {
    constructor(public star: Star, public container: PIXI.Container) {}
}
class MinerSelectorItem {
    constructor(public miner: Miner, public container: PIXI.Container, public roverIndicator: PIXI.Sprite) {}
}

export default class PlayerShipSelectorComponent implements GameElement {
    private container: PIXI.Container = new PIXI.Container()
    private currentMiner: Miner = PlayerService.getInstance().miner
    private bg: PIXI.Sprite = PIXI.Sprite.from('player_menu_ship_bg')
    private fleetBtn: PIXI.Sprite = PIXI.Sprite.from('player_bar_btn_fleet')
    private statBtn: PIXI.Sprite = PIXI.Sprite.from('player_bar_btn_stats')
    private currentMenu: ShipMenu = ShipMenu.Ship
    private scrollContainer: ScrollableContainer
    private currentShipCharComponent: ShipCharacteristicsComponent = new ShipCharacteristicsComponent()
    private currentShipStatComponent: PlayerShipStatComponent = new PlayerShipStatComponent(this.currentMiner)
    private exitBtn = new ExitShipButton()
    private starItems: StarCateoryItem[] = []
    private minerItems: MinerSelectorItem[] = []

    constructor() {
        // this.container.addChild(this.bg)

        this.fleetBtn.x = 55
        this.fleetBtn.y = 26
        this.statBtn.x = 95
        this.statBtn.y = 26

        this.selectMenu(this.currentMenu)
        this.fleetBtn.interactive = true
        this.fleetBtn.cursor = 'pointer'
        this.fleetBtn.on('mouseover', () => {
            if (this.currentMenu == ShipMenu.Ship) return
            this.fleetBtn.texture = PIXI.Texture.from('player_bar_btn_fleet_hover')
        })
        this.fleetBtn.on('mouseout', () => {
            if (this.currentMenu == ShipMenu.Ship) return
            this.fleetBtn.texture = PIXI.Texture.from('player_bar_btn_fleet')
        })
        this.fleetBtn.on('pointertap', () => {
            this.selectMenu(ShipMenu.Ship)
        })

        this.statBtn.interactive = true
        this.statBtn.cursor = 'pointer'
        this.statBtn.on('mouseover', () => {
            if (this.currentMenu == ShipMenu.Stat) return
            this.statBtn.texture = PIXI.Texture.from('player_bar_btn_stats_hover')
        })
        this.statBtn.on('mouseout', () => {
            if (this.currentMenu == ShipMenu.Stat) return
            this.statBtn.texture = PIXI.Texture.from('player_bar_btn_stats')
        })
        this.statBtn.on('pointertap', () => {
            this.selectMenu(ShipMenu.Stat)
        })

        this.scrollContainer = new ScrollableContainer(20, 46)
        this.scrollContainer.scrollIndicator.tint = Colors.Blue800
        this.scrollContainer.onScrolled = (y: number, _: number) => {
            if (this.currentShipCharComponent) {
                // TODO Move selected indicator
            }
        }

        document.addEventListener(MapService.SOLAR_SYSTEM_MINER_REMOVED, () => {
            this.repositionate()
        })

        document.addEventListener(MapService.MINER_REMOVED_FROM_MAP_EVENT, event => {
            const currentMinerId = PlayerService.getInstance().miner.id
            const minerSizeChanged = this.minerItems.length != PlayerService.getInstance().miningPlayers.length
            const isFoundInMiners = PlayerService.getInstance().miningPlayers.find(a => {
                return a.id == currentMinerId
            })

            if (minerSizeChanged) {
                const newMinerArray: MinerSelectorItem[] = []
                this.minerItems.forEach(a => {
                    if (PlayerService.getInstance().miningPlayers.find(it => a.miner.id == it.id)) {
                        newMinerArray.push(a)
                    } else {
                        a.container.destroy({ children: true })
                    }
                })
                this.minerItems = newMinerArray
                this.repositionate()

                if (PlayerService.getInstance().miningPlayers.length == 0) {
                    window.location.href = '/'
                } else {
                    if (!isFoundInMiners) {
                        PlayerService.getInstance().updateMainPlayer(PlayerService.getInstance().miningPlayers[0])
                    }
                }
            }
        })

        document.addEventListener(RoverManagerService.ROVER_DEPOSED_EVENT, event => {
            this.repositionate()
        })

        document.addEventListener(RoverManagerService.ROVER_HARVEST_EVENT, event => {
            this.repositionate()
        })

        this.container.visible = false
        this.container.addChild(this.bg)
        this.container.addChild(this.statBtn)
        this.container.addChild(this.fleetBtn)
        this.container.addChild(this.scrollContainer.wrapper)
        this.container.addChild(this.currentShipCharComponent.getContainer())
        this.container.addChild(this.currentShipStatComponent.getContainer())
        this.container.addChild(this.exitBtn.getContainer())
        this.currentShipCharComponent.getContainer().x = 49
        this.currentShipCharComponent.getContainer().y = 40
        this.currentShipStatComponent.getContainer().x = 52
        this.currentShipStatComponent.getContainer().y = 40
        this.setPositions()

        PlayerService.getInstance().setOnCurrentPlayerChange(async miner => {
            this.currentMiner = miner
            this.currentShipCharComponent.setup(miner)
            this.currentShipStatComponent.update(miner)

            const name = (await miner.name()).split('•')[0]
            this.onResize()
            this.repositionate()
        })

        this.onResize()

        const exitBtnContainer = this.exitBtn.getContainer()
        exitBtnContainer.x = 73
        exitBtnContainer.y = this.bg.height - 18
        exitBtnContainer.interactive = true
        exitBtnContainer.interactiveChildren = true
        exitBtnContainer.cursor = 'pointer'
        exitBtnContainer.on('pointertap', () => {
            document.dispatchEvent(new CustomEvent(ShipExitModal.OPEN_SHIP_EXIT_EVENT, { detail: this.currentMiner }))
        })

        this.setupView()
    }

    async enterAnimation(callback: () => void): Promise<void> {
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.y = 21 * perc
            this.container.alpha = perc
            this.container.visible = true
        })
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        await animate('easeOutQuad', 300, (perc: number) => {
            this.container.y = 21 - 21 * perc
            this.container.alpha = 1 - perc
        })
        this.container.visible = false
        callback()
    }

    onResize(): void {
        this.setPositions()
        this.scrollContainer.checkScrollState()
    }

    public tick(): void {
        this.currentShipCharComponent.tick()
        this.scrollContainer.tick()
    }

    public getContainer(): PIXI.Container {
        return this.container
    }

    private setPositions() {
        // tood
    }

    private selectMenu(menu: ShipMenu) {
        // TODO something with menu
        this.currentMenu = menu

        if (this.currentMenu == ShipMenu.Ship) {
            this.fleetBtn.texture = PIXI.Texture.from('player_bar_btn_fleet_selected')
            this.statBtn.texture = PIXI.Texture.from('player_bar_btn_stats')
            this.currentShipStatComponent.getContainer().visible = false
            this.currentShipCharComponent.getContainer().visible = true
        } else if (this.currentMenu == ShipMenu.Stat) {
            this.fleetBtn.texture = PIXI.Texture.from('player_bar_btn_fleet')
            this.statBtn.texture = PIXI.Texture.from('player_bar_btn_stats_selected')
            this.currentShipStatComponent.getContainer().visible = true
            this.currentShipCharComponent.getContainer().visible = false
        } else {
            this.fleetBtn.texture = PIXI.Texture.from('player_bar_btn_fleet')
            this.statBtn.texture = PIXI.Texture.from('player_bar_btn_stats')
        }
    }

    private repositionate() {
        let y = 4
        let currentStar: StarCateoryItem | undefined = undefined
        const stars = this.starItems

        this.minerItems.forEach(miner => {
            const foundMiner = PlayerService.getInstance().miningPlayers.find(it => it.id == miner.miner.id)
            if (foundMiner) {
                miner.miner = foundMiner
            }
        })

        this.starItems.forEach(it => {
            if (it.container.parent) {
                it.container.parent.removeChild(it.container)
            }
        })

        this.minerItems = this.minerItems.sort((a, b) => a.miner.solarSystemID - b.miner.solarSystemID)
        this.minerItems.forEach(it => {
            if (it.miner.solarSystemID != currentStar?.star.id) {
                currentStar = stars.find(a => a.star.id == it.miner.solarSystemID)
                this.scrollContainer.addChild(currentStar!.container)
                currentStar!.container.y = y + 3

                y += 15
            }

            it.roverIndicator.visible = MapService.getInstance().map.cometExist(it.miner.rover)

            if (!it.container.parent) {
                this.scrollContainer.addChild(it.container)
            }
            const cardBg = it.container.getChildAt(0) as PIXI.Sprite
            if (it.miner.id == this.currentMiner.id) {
                cardBg.tint = Colors.Green400
            } else {
                cardBg.tint = Colors.Blue500
            }
            it.container.y = y
            y += 40
        })
    }

    private setupView() {
        this.scrollContainer.updateHeight(this.bg.height - 1 - this.scrollContainer.initialY)

        const stars = MapService.getInstance().map.stars
        stars.forEach(it => {
            const container = new PIXI.Container()

            const bg = PIXI.Sprite.from('bg_selector_sun_category')
            bg.x = 1

            const title = new MainBitmapText(cutText(it.name, 13), { fontSize: 4 })
            title.x = 4
            title.y = 2

            const sun = PIXI.Sprite.from(it.id + '_star')
            sun.scale.set(1 / 3)
            sun.x = bg.width - sun.width
            sun.y = -2.33

            container.addChild(bg)
            container.addChild(title)
            container.addChild(sun)

            this.starItems.push(new StarCateoryItem(it, container))
        })

        PlayerService.getInstance().miningPlayers.forEach((miner: Miner, i) => {
            /* const sprite = PIXI.Sprite.from('player_miner_' + miner.id)
            sprite.width = 274 / 3
            sprite.height = 122*/
            const spriteHeight = 40

            const sprite = new MinerPlayerComponent(miner, false)
            sprite.getContainer().scale.set(1 / 3 / 2)
            sprite.getContainer().y = -12

            const cardContainer = new PIXI.Container()
            cardContainer.y = 30 + spriteHeight * i
            cardContainer.interactive = true
            cardContainer.interactiveChildren = true
            cardContainer.cursor = 'pointer'
            cardContainer.hitArea = new PIXI.Rectangle(0, 0, 40, 40)

            const cardBg = PIXI.Sprite.from('bg_selector_ship')
            cardBg.x = 4
            cardBg.y = 1

            cardContainer.addChild(cardBg)
            cardContainer.addChild(sprite.getContainer())

            let hasMoved = false
            let pointerDownTime = 0
            cardContainer.on('mouseover', () => {
                cardBg.texture = PIXI.Texture.from('bg_selector_ship_selected')
            })
            cardContainer.on('mouseout', () => {
                cardBg.texture = PIXI.Texture.from('bg_selector_ship')
            })
            cardContainer.on('pointerdown', () => {
                pointerDownTime = Date.now()
                hasMoved = false
            })
            cardContainer.on('pointerup', () => {
                const clickTime = Date.now() - pointerDownTime
                if (!hasMoved || clickTime < 100) {
                    const foundMiner = PlayerService.getInstance().miningPlayers.find(it => it.id == miner.id)
                    if (foundMiner) {
                        PlayerService.getInstance().updateMainPlayer(foundMiner)
                    }
                }
            })
            cardContainer.on('pointermove', () => {
                hasMoved = true
            })

            const roverIndicator = PIXI.Sprite.from('ic_staking_comet_rover')
            cardContainer.addChild(roverIndicator)
            roverIndicator.x = 31
            roverIndicator.y = 28
            roverIndicator.filters = [new OutlineFilter(3, 0)]

            this.minerItems.push(new MinerSelectorItem(miner, cardContainer, roverIndicator))
        })

        this.repositionate()
        this.onResize()
    }
}
