<template>
    <div class="relative gradient">
        <svg class="absolute z-10 w-[147px] top-[-57px] left-[-57px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 147 114">
            <g fill="#FFF" fill-rule="evenodd">
                <path d="M69 101v12H45v-12zM147 56v12h-10V56z" />
                <path d="M56.569 0l56.569 56.569-56.569 56.569L0 56.569z" />
            </g>
        </svg>
        <img
            v-if="color === 'blue'"
            class="
                absolute
                z-20
                max-w-none
                w-[233px]
                mobile:max-w-[200px]
                top-[-140px]
                right-[130px]
                mobile:right-[-50px]
            "
            src="@/assets/tournament/comethanim.gif"
        />
        <!-- <img
            class="
                absolute
                z-20
                max-w-[233px]
                mobile:max-w-[200px]
                top-[-110px]
                right-[130px]
                mobile:right-[-50px]
                image-rendering-pixelated
            "
            src="@/assets/tournament/cometh_pixel_blue.png"
            alt=""
        /> -->
        <img
            v-else
            class="
                absolute
                z-20
                max-w-[233px]
                top-[-110px]
                right-[165px]
                mobile:-right-3
                image-rendering-pixelated
            "
            src="@/assets/tournament/sun.png"
        />
        <div class="relative z-10  pb-[238px] overflow-hidden px-4">
            <img
                class="
                    absolute
                    max-w-[1016px]
                    mobile:max-w-[812px]
                    top-[-110px]
                    right-[-230px]
                    mobile:right-[-320px]
                    image-rendering-pixelated
                "
                src="@/assets/tournament/bg_game.png"
            />

            <div class="relative max-w-[1140px] mx-auto pt-[165px]">
                <svg class="absolute z-10 h-[52px] top-52 left-[-118px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 52">
                    <defs>
                        <filter id="a" color-interpolation-filters="auto">
                            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g filter="url(#a)" transform="translate(.911)">
                            <g fill="#fff">
                                <path d="M2.089 42h-2v10h2z" />
                                <path d="M.089 50v2h10v-2z" />
                            </g>
                        </g>
                        <path fill="#FFF" d="M.911 0v5h7.524l2.674-2.356L8.435 0zM8 34h3v3H8z" />
                    </g>
                </svg>
                <div class="grid grid-cols-12 gap-[30px]">
                    <div
                        class="
                            col-start-2 col-span-8
                            mobile:col-start-1 mobile:col-span-12
                        "
                    >
                        <h2
                            :class="[color === 'blue' ? 'glow-blue' : 'glow-purple']"
                            class="
                                text-white
                                font-display
                                uppercase
                                font-medium
                                text-[120px]
                                leading-[100px]
                                mobile:text-[60px] mobile:leading-[50px]
                            "
                        >
                            THE LEADING<br />
                            blockchain<br />
                            game
                        </h2>
                    </div>
                </div>
            </div>

            <div
                class="
                    relative
                    max-w-[1140px]
                    mx-auto
                    mt-[122px]
                    mobile:mt-[55px]
                "
            >
                <div class="grid grid-cols-12 gap-[30px]">
                    <div
                        class="
                            col-start-2 col-span-5
                            mobile:col-start-1 mobile:col-span-12
                        "
                    >
                        <div class="w-full h-[333px]">
                            <img class="" src="@/assets/tournament/cometh-prof.png" />
                        </div>
                    </div>
                    <div
                        class="
                            col-start-8 col-span-4
                            mobile:col-start-1 mobile:col-span-12
                        "
                    >
                        <h3
                            class="
                                font-display font-bold
                                text-[40px]
                                leading-[44px]
                                mobile:text-[30px] mobile:leading-[30px]
                                uppercase
                                text-white
                            "
                        >
                            The ⚗️MUST Token fuels Cometh's economics
                        </h3>
                        <p class="font-body leading-[26px] text-white mt-2.5">
                            The ⚗️MUST is the native token of Cometh. When at rest, the ⚗️MUST let the players earn new game assets and game mechanics
                            are priced in ⚗️MUST.
                        </p>
                        <ButtonLink target="_blank" :href="'https://docs.cometh.io/'" :color="color" class="mt-7">
                            Read the docs
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative z-10 mt-[-113px]">
            <img class="absolute bottom-0 w-full" src="@/assets/tournament/bg_grid.png" />

            <div class="relative">
                <div class="pt-[43px]">
                    <div class="max-w-[1140px] mx-auto mobile:px-4">
                        <div class="grid grid-cols-12 gap-[30px]">
                            <div
                                class="
                                    col-start-2 col-span-4
                                    mobile:col-start-1 mobile:col-span-12
                                "
                            >
                                <h3
                                    class="
                                        font-display font-bold
                                        text-[40px]
                                        leading-[44px]
                                        mobile:text-[30px] mobile:leading-[30px]
                                        uppercase
                                        text-white
                                    "
                                >
                                    Must farming<br />for nft
                                </h3>
                                <p
                                    class="
                                        font-body
                                        leading-[26px]
                                        text-white
                                        mt-2.5
                                    "
                                >
                                    Stake your ⚗️MUST to redeem exclusive spaceships. Go to the next level, earn ⚗️MUST being a liquidity provider and
                                    access legendary spaceships.
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- GRAPH BACKGROUND -->
                    <div
                        class="
                            absolute
                            mobile:relative
                            top-0
                            w-full
                            h-[608px]
                            mobile:h-[200px]
                            overflow-hidden
                        "
                    >
                        <img
                            v-if="color === 'blue'"
                            class="
                                absolute
                                top-0
                                object-cover
                                mobile:max-w-none
                                mobile:h-[608px]
                                mobile:right-[-100px]
                                mobile:top-[-100px]
                            "
                            src="@/assets/tournament/bg_graph_blue.png"
                        />
                        <img
                            v-else
                            class="
                                absolute
                                top-0
                                object-cover
                                mobile:max-w-none
                                mobile:h-[608px]
                                mobile:right-[-100px]
                                mobile:top-[-100px]
                            "
                            src="@/assets/tournament/bg_graph.png"
                        />
                    </div>

                    <div class="max-w-[1140px] mx-auto mobile:px-4">
                        <div class="grid grid-cols-12 gap-[30px]">
                            <div
                                class="
                                    col-span-12
                                    place-self-end
                                    mobile:place-self-stretch
                                "
                            >
                                <div
                                    class="
                                        relative
                                        grid grid-cols-1
                                        mobile:grid-cols-1
                                        gap-x-[108px] gap-y-8
                                        mt-[150px]
                                        mobile:mt-8
                                        pt-[60px]
                                        px-20
                                        mobile:px-16
                                        pb-20
                                    "
                                >
                                    <template v-if="color === 'blue'">
                                        <img
                                            class="
                                                absolute
                                                -top-2
                                                -right-2
                                                w-20
                                                image-renderning-pixelated
                                            "
                                            src="@/assets/tournament/bg_top_right_corner.png"
                                        />
                                        <img
                                            class="
                                                absolute
                                                -bottom-2
                                                -left-2
                                                w-12
                                            "
                                            src="@/assets/tournament/bg_bottom_left_corner.png"
                                        />
                                        <img
                                            class="
                                                absolute
                                                -bottom-2.5
                                                right-24
                                                w-[120px]
                                            "
                                            src="@/assets/tournament/bg_bottom_border.png"
                                        />
                                    </template>
                                    <template v-else>
                                        <img
                                            class="
                                                absolute
                                                -top-2
                                                -right-2
                                                w-20
                                                image-renderning-pixelated
                                            "
                                            src="@/assets/tournament/bg_top_right_corner.png"
                                        />
                                        <img
                                            class="
                                                absolute
                                                -bottom-2
                                                -left-2
                                                w-12
                                            "
                                            src="@/assets/tournament/bg_bottom_left_corner.png"
                                        />
                                        <img
                                            class="
                                                absolute
                                                -bottom-2.5
                                                right-24
                                                w-[120px]
                                            "
                                            src="@/assets/tournament/bg_bottom_border.png"
                                        />
                                    </template>

                                    <div>
                                        <div
                                            :class="[color === 'blue' ? 'text-[#61BCF5]' : 'text-purple']"
                                            class="
                                                uppercase
                                                font-pixel
                                                text-[40px]
                                                leading-[44px]
                                            "
                                        >
                                            $15M
                                        </div>
                                        <div
                                            class="
                                                mt-2
                                                text-white
                                                uppercase
                                                font-display
                                                leading-5
                                                text-2xl
                                            "
                                        >
                                            Total Locked Value (TLV)
                                        </div>
                                    </div>
                                    <!-- <div>
                                        <div
                                            :class="[
                                                color === 'blue'
                                                    ? 'text-[#61BCF5]'
                                                    : 'text-purple'
                                            ]"
                                            class="
                                                uppercase
                                                font-pixel
                                                text-[22px]
                                                leading-[22px]
                                            "
                                        >
                                            4,000
                                        </div>
                                        <div
                                            class="
                                                mt-2
                                                text-white
                                                uppercase
                                                font-display
                                                leading-5
                                            "
                                        >
                                            unique players (+100% in 4 weeks)
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            :class="[
                                                color === 'blue'
                                                    ? 'text-[#61BCF5]'
                                                    : 'text-purple'
                                            ]"
                                            class="
                                                uppercase
                                                font-pixel
                                                text-[22px]
                                                leading-[22px]
                                            "
                                        >
                                            $200K
                                        </div>
                                        <div
                                            class="
                                                mt-2
                                                text-white
                                                uppercase
                                                font-display
                                                leading-5
                                            "
                                        >
                                            earned by the top 10 players
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            :class="[
                                                color === 'blue'
                                                    ? 'text-[#61BCF5]'
                                                    : 'text-purple'
                                            ]"
                                            class="
                                                uppercase
                                                font-pixel
                                                text-[22px]
                                                leading-[22px]
                                            "
                                        >
                                            $1.6M
                                        </div>
                                        <div
                                            class="
                                                mt-2
                                                text-white
                                                uppercase
                                                font-display
                                                leading-5
                                            "
                                        >
                                            of TVL
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- community -->
        <div class="relative mt-40 mobile:mt-28 pb-[112px] mobile:px-4 overflow-hidden">
            <img
                class="
                    absolute
                    bottom-0
                    max-w-none
                    w-full
                    mobile:w-[1440px]
                    image-rendering-pixelated
                "
                src="@/assets/tournament/bg_community.png"
            />
            <div class="relative flex flex-col items-center max-w-3xl mx-auto">
                <h3
                    class="
                        font-display font-bold
                        text-[40px]
                        leading-[44px]
                        mobile:text-[30px] mobile:leading-[30px]
                        uppercase
                        text-white
                    "
                >
                    Community
                </h3>
                <p
                    class="
                        font-body
                        leading-[26px]
                        text-white text-center
                        mt-8
                        mobile:mt-5
                    "
                >
                    More than just a game, Cometh is above all a hybrid community between DeFi power users and skilled gamers. The core team and the
                    contributors are active on these networks, don't hesitate to join us for the greatest experience.
                </p>
                <ul
                    class="
                        grid grid-cols-4
                        mobile:grid-cols-2
                        gap-5
                        mt-5
                        mobile:mt-10
                    "
                >
                    <li>
                        <a
                            href="https://discord.gg/gAWTyctkze"
                            target="_blank"
                            class="
                                inline-flex
                                items-center
                                justify-center
                                w-20
                                h-20
                                bg-[#011428] bg-opacity-50
                                hover:bg-opacity-100
                            "
                        >
                            <img class="w-[36px]" src="@/assets/tournament/ic_discord.png" alt="Discord" />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://t.me/cometh_io"
                            target="_blank"
                            class="
                                inline-flex
                                items-center
                                justify-center
                                w-20
                                h-20
                                bg-[#011428] bg-opacity-50
                                hover:bg-opacity-100
                            "
                        >
                            <img class="w-[30px]" src="@/assets/tournament/ic_telegram.png" alt="Telegram" />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://twitter.com/MUSTCometh"
                            target="_blank"
                            class="
                                inline-flex
                                items-center
                                justify-center
                                w-20
                                h-20
                                bg-[#011428] bg-opacity-50
                                hover:bg-opacity-100
                            "
                        >
                            <img class="w-[31px]" src="@/assets/tournament/ic_twitter.png" alt="Twitter" />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://medium.com/cometh"
                            target="_blank"
                            class="
                                inline-flex
                                items-center
                                justify-center
                                w-20
                                h-20
                                bg-[#011428] bg-opacity-50
                                hover:bg-opacity-100
                            "
                        >
                            <img class="w-[33px]" src="@/assets/tournament/ic_medium.png" alt="Medium" />
                        </a>
                    </li>
                </ul>

                <ButtonPrimary @click="$emit('buy')" class="yellow mt-9 text-xl leading-5 w-[207px] h-[48px]">
                    Play
                </ButtonPrimary>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import ButtonLink from '@/components/ButtonLink.vue'
import ButtonPrimary from '@/components/ButtonPrimary.vue'

@Options({
    components: { ButtonSecondary, ButtonLink, ButtonPrimary },
    props: {
        color: {
            type: String,
            default: 'purple'
        }
    }
})
export default class Game extends Vue {
    color!: string
}
</script>

<style scoped>
.gradient {
    background-image: linear-gradient(24deg, #000000 7%, #02192b 100%);
}
.glow-blue {
    text-shadow: 0 0 14px #269bf5;
}
.glow-purple {
    text-shadow: 0 0 14px #8349e5;
}
</style>
