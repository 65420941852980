<template>
    <!-- container fixing body overflow hidden -->
    <div class="w-screen h-screen overflow-x-hidden overflow-y-auto bg-gray-50">
        <Header />
        <transition name="fade" mode="out-in">
            <div v-if="isConnected" class="">
                <div class="relative bg-gradient-to-r from-gray-700 to-gray-800 shadow-xl overflow-hidden">
                    <div class="relative max-w-7xl mx-auto pt-8 md:pt-16 pb-10 px-4 sm:px-6 lg:px-8">
                        <div class="flex flex-col md:flex-row">
                            <div class="md:w-1/2">
                                <h1 class="text-white text-5xl font-medium tracking-wide leading-tight">Invite Friends.<br />Earn Crypto Together</h1>
                                <p class="text-gray-400 mt-6 text-lg">
                                    Earn <b class="text-white font-normal">{{ commissionPercentage }}% commission</b> when a friend buy a M.U.L.E with
                                    your referral Code.
                                </p>
                            </div>
                            <div class="md:w-1/2 flex justify-end">
                                <div class="w-full max-w-lg bg-gray-900 rounded-lg overflow-hidden">
                                    <div class="w-full h-1.5 bg-blue-400"></div>
                                    <div class="h-full pt-4 pb-6 px-6 -mt-1.5">
                                        <transition name="fade" mode="out-in">
                                            <div v-if="referralCode">
                                                <div class="flex">
                                                    <!-- referral id and url -->
                                                    <div class="">
                                                        <!-- default referral id -->
                                                        <div class="mt-2">
                                                            <p class="text-gray-400">Share Your Referral code</p>
                                                            <p class="flex items-end mt-1">
                                                                <span class="text-white text-3xl font-medium uppercase">{{ referralCode }}</span>
                                                                <a href="#" @click="copyCode" class="text-blue-400 p-1 ml-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        class="h-5 w-5"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2"
                                                                            d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </p>
                                                        </div>

                                                        <!-- default link -->
                                                        <div class="mt-4">
                                                            <p class="text-gray-400">Direct Link</p>
                                                            <p class="flex items-center mt-1">
                                                                <span class="text-white"> {{ shortDirectLink }} </span>
                                                                <a href="#" @click="copyDirectLink" class="text-blue-400 p-1 ml-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        class="h-5 w-5"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2"
                                                                            d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="flex bg-gray-800 rounded-lg py-4 px-5 mt-4">
                                                    <!-- you -->
                                                    <div class="w-1/2">
                                                        <p class="text-gray-400">You Receive</p>
                                                        <p class="mt-1 text-white text-xl tracking-tight">{{ commissionPercentage }}%</p>
                                                    </div>
                                                    <!-- friends -->
                                                    <div class="w-1/2">
                                                        <p class="text-gray-400">Friends Save</p>
                                                        <p class="mt-1 text-white text-xl tracking-tight">{{ commissionPercentage }}%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else-if="isGeneratingReferralCode" class="h-full flex items-center justify-center">
                                                <div class="flex items-center text-white">
                                                    <div class="relative mr-2">
                                                        <span class="flex h-4 w-4">
                                                            <span
                                                                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"
                                                            ></span>
                                                            <span class="relative inline-flex rounded-full h-4 w-4 bg-blue-500"></span>
                                                        </span>
                                                    </div>
                                                    Generating your referral code
                                                </div>
                                            </div>
                                            <div v-else class="h-full">
                                                <div class="mt-4">
                                                    <p class="flex items-end mt-1">
                                                        <a
                                                            @click.prevent="generateCode"
                                                            href="#"
                                                            class="flex items-center px-5 py-2 rounded hover:bg-blue-500 text-lg text-white bg-blue-400"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                class="h-6 w-6 mr-1"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                            >
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="2"
                                                                    d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                                                                />
                                                            </svg>
                                                            Get My Referral Code
                                                        </a>
                                                    </p>
                                                </div>
                                                <p class="text-gray-400 mt-6">
                                                    You will earn
                                                    <b class="font-normal text-blue-400">{{ commissionPercentage }}% commission</b> each time a friend
                                                    buy a M.U.L.E with you referral code.
                                                </p>
                                                <div class="flex bg-gray-800 rounded-lg py-4 px-5 mt-4">
                                                    <!-- you -->
                                                    <div class="w-1/2">
                                                        <p class="text-gray-400">You Receive</p>
                                                        <p class="mt-1 text-white text-xl tracking-tight">{{ commissionPercentage }}%</p>
                                                    </div>
                                                    <!-- friends -->
                                                    <div class="w-1/2">
                                                        <p class="text-gray-400">Friends Save</p>
                                                        <p class="mt-1 text-white text-xl tracking-tight">{{ commissionPercentage }}%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FriendList :friends="friends" />
            </div>
            <div v-else class="w-screen h-screen">
                <div class="flex items-center justify-center py-20">
                    <button @click="isConnected = true" class="bg-gold px-4 py-2 rounded font-audiowide">Connect Your Wallet</button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" src="./Referral.ts"></script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
