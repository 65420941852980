
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        href: String,
        color: {
            type: String,
            default: 'purple'
        }
    }
})
export default class ButtonPrimary extends Vue {
    href!: string
    color!: string
}
