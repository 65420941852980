import MainBitmapText from '@/game/pixi-scale/MainBitmapText'
import Colors from '@/helpers/Colors'
import PIXIAppService from '@/services/PIXIAppService'
import { MapExtraDecoratorInterface } from './MapExtraDecoratorInterface'

export class StadiumExtraDecorator implements MapExtraDecoratorInterface {
    private decoration: PIXI.Sprite[] = []
    private ads: PIXI.Sprite[] = []
    private scale = 1
    private distance = 1

    private adsData = [
        { logo: 'test_pub', url: 'https://polygon.technology/' },
        { logo: 'moons_pub', url: 'https://www.moontools.io/' },
        { logo: 'stater_pub', url: 'https://stater.co/' }
    ]

    constructor(public parent: PIXI.Container, public cupName: string) {}

    public redraw() {
        this.ads.forEach((it, i) => {
            it.scale.set((this.scale / 2) * (this.distance / 800))
            it.anchor.set(0.5)
            it.x = this.distance * 1.05 * Math.cos(Date.now() / 500000 + (i * Math.PI * 2) / this.ads.length + Math.PI / this.ads.length) * this.scale
            it.y = this.distance * 1.05 * Math.sin(Date.now() / 500000 + (i * Math.PI * 2) / this.ads.length + Math.PI / this.ads.length) * this.scale
            it.rotation = Math.PI / 2 + Math.atan2(it.y, it.x)

            if (!it.parent) {
                this.parent.addChild(it)
            }
        })
        this.decoration.forEach((it, i) => {
            it.scale.set(this.scale * (this.distance / 800))
            it.anchor.set(0.5)
            it.x = this.distance * Math.cos(Date.now() / 500000 + (i * Math.PI * 2) / this.decoration.length) * this.scale
            it.y = this.distance * Math.sin(Date.now() / 500000 + (i * Math.PI * 2) / this.decoration.length) * this.scale
            it.rotation = Math.PI / 2 + Math.atan2(it.y, it.x)

            if (!it.parent) {
                this.parent.addChild(it)
            }
        })
    }

    public updateParams(data: any) {
        this.scale = data.scale
        this.distance = data.distance
        this.redraw()
    }

    public init() {
        this.ads = []
        this.decoration = []
        const container = new PIXI.Container()
        const baseShip = PIXI.Sprite.from('stadium_camera_ship')
        const text = new MainBitmapText(this.cupName, { fontSize: 6 })

        const graphic = new PIXI.Graphics()
        graphic.lineStyle(1, Colors.Gold)
        graphic.beginFill(Colors.GoldDarker)
        graphic.drawRect(0, 0, Math.floor(text.width + 6), Math.floor(text.height + 4))
        graphic.endFill()

        text.y = 4
        text.x = 9

        baseShip.y = 48
        baseShip.x = Math.floor((text.width * 3) / 2 - (baseShip.width * 3) / 2)
        graphic.scale.set(3)
        text.scale.set(3)
        baseShip.scale.set(3)

        container.addChild(baseShip)
        container.addChild(graphic)
        container.addChild(text)

        const renderer = PIXIAppService.getInstance().getApplication().renderer
        const texture = renderer.generateTexture(container, PIXI.SCALE_MODES.NEAREST, 1)

        const size = 9
        for (let index = 0; index < size; index++) {
            const newShip = PIXI.Sprite.from(texture)
            newShip.anchor.set(0.5)

            if (index == 0) {
                const newPub = PIXI.Sprite.from('cup_polygon')
                newPub.anchor.set(0.5)

                this.decoration.push(newShip)
                this.ads.push(newPub)
            } else {
                const currentAdIndex = index % this.adsData.length
                const currentAdd = this.adsData[currentAdIndex]

                const newPub = PIXI.Sprite.from(currentAdd.logo)
                newPub.interactive = true
                newPub.cursor = 'pointer'
                newPub.on('pointertap', () => {
                    window.open(currentAdd.url, '_blank')!.focus()
                })

                newPub.anchor.set(0.5)

                this.decoration.push(newShip)
                this.ads.push(newPub)
            }
        }
    }
}
