import * as PIXI from 'pixi.js'

import PlayerService from '../../services/PlayerService'
import Web3 from 'web3'
import BN from 'bn.js'
import StakedSpaceshipsService from '../../services/web3/StakedSpaceshipsService'
import ComethMinerGameService from '../../services/web3/ComethMinerGameService'
import SpiceService from '../../services/web3/SpiceService'
import Colors from '@/helpers/Colors'
import ButtonComponent from '../ButtonComponent'
import MainBitmapText from '../../pixi-scale/MainBitmapText'
import ModalComponent from './ModalComponent'
import Miner from '../../models/Miner'

export default class ShipExitModal extends ModalComponent {
    public static OPEN_SHIP_EXIT_EVENT = 'openShipExitEvent'

    private amount!: PIXI.BitmapText
    private okBtn: ButtonComponent
    private errorMessage = new MainBitmapText('insufficient Must balance', { fontSize: 5 })

    private fees: BN = new BN(0)

    private hasApprove = false

    constructor(public miner: Miner) {
        super(200, 104)
        this.position = ModalComponent.POSITION_CENTER

        const titleText = new MainBitmapText('LEAVE GALAXY', {
            fontSize: 8
        })
        titleText.x = Math.floor(this.modalWidth / 2 - titleText.width / 2)
        titleText.y = 6

        const amountIcon = PIXI.Sprite.from('ic_spice')
        amountIcon.y = 47
        amountIcon.x = 134

        const title2 = new MainBitmapText('To leave this galaxy you need to pay the toll:', {
            fontSize: 6
        })
        title2.y = titleText.y + titleText.height + 4
        title2.x = Math.floor(this.modalWidth / 2 - title2.width / 2)

        //TODO GET PRICE ON CHAIN
        this.amount = new MainBitmapText('', {
            fontSize: 6
        })

        this.amount.x = 48
        this.amount.y = 47.5

        this.okBtn = new ButtonComponent('Approve')
        this.okBtn.x = this.modalWidth / 2 - this.okBtn.width / 2
        this.okBtn.position.y = 75
        this.okBtn.interactive = true
        this.okBtn.cursor = 'pointer'
        this.okBtn.on('pointertap', async () => {
            if (this.hasApprove) {
                StakedSpaceshipsService.getInstance().exit(this.miner.id)
                this.onExit()
            } else {
                PlayerService.getInstance().approveGame(10000000)
            }
        })

        this.errorMessage.position.y = this.okBtn.position.y + 15
        this.errorMessage.position.x = Math.floor(this.modalWidth / 2 - this.errorMessage.width / 2)
        this.errorMessage.visible = false
        this.errorMessage.tint = Colors.Red500

        this.modalContainer.addChild(titleText)
        this.modalContainer.addChild(title2)
        this.modalContainer.addChild(amountIcon)
        this.modalContainer.addChild(this.amount)
        this.modalContainer.addChild(this.okBtn)
        this.modalContainer.addChild(this.errorMessage)
        this.setPositions()
        this.updateLeaveFee()

        document.addEventListener(SpiceService.ALLOWANCE_CHANGED_EVENT, () => {
            this.onAllowanceChange()
        })

        this.loader.visible = false
        this.dataContainer.visible = true
    }

    async updateLeaveFee() {
        this.fees = new BN(await ComethMinerGameService.getInstance().leaveFee(this.miner.solarSystemID))
        this.amount.text = Web3.utils.fromWei(this.fees, 'ether')
        this.checkBtnAllowance()
    }

    private checkBtnAllowance() {
        this.hasApprove = PlayerService.getInstance().allowance.gte(this.fees)

        if (!this.hasApprove) {
            this.okBtn.updateText('Approve')
        } else {
            this.okBtn.updateText('Confirm')
        }

        if (PlayerService.getInstance().spices.lt(this.fees)) {
            this.okBtn.alpha = 0.4
            this.okBtn.interactive = false
            this.errorMessage.visible = true
        } else {
            this.okBtn.alpha = 1
            this.okBtn.interactive = true
            this.errorMessage.visible = false
        }
    }

    private async onAllowanceChange() {
        await PlayerService.getInstance().updateSpiceBalance()
        this.checkBtnAllowance()
    }
}
