import Polar from './Polar'
import Cartesian from './Cartesian'
import Utils from './Utils'

export default class Orbit {
    public last = new Polar({ distance: 0, angle: 0 })
    public lastUpdate = 0
    public rotationSpeed = 0
    public center = new Cartesian({ x: 0, y: 0 })

    constructor(from: any) {
        if (from != undefined) {
            this.last = new Polar(from.last)
            this.lastUpdate = parseInt(from.lastUpdate)
            this.rotationSpeed = parseInt(from.rotationSpeed)
            this.center = new Cartesian(from.center)
        }
    }

    public angularSpeedRadian() {
        return this.rotationSpeed / this.last.distance
    }

    public revolutionInSeconde() {
        const completeCircle = Utils.degreesToRadians(360)
        return completeCircle / this.angularSpeedRadian()
    }

    public currentAngle(timeRef: number) {
        const timeDiff = timeRef - this.lastUpdate
        const totalAngleRadian = this.angularSpeedRadian() * timeDiff

        return (this.last.angle + Utils.radiansToDegrees(totalAngleRadian)) % 360
    }

    public getCartesian(timeRef: number): Cartesian {
        const currentAngleRadian = Utils.degreesToRadians(this.currentAngle(timeRef))

        const x = Math.round(this.last.distance * Math.cos(currentAngleRadian) + this.center.x)
        const y = Math.round(this.last.distance * Math.sin(currentAngleRadian) + this.center.y)

        return { x, y }
    }
}
