import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'

export default class GBackground extends PIXI.Sprite {
    constructor(public initialWidth: number = 1, public initialHeight: number = 1) {
        super(PIXI.Texture.from('pixel'))
        this.onScaleChange()
        document.addEventListener('onScaleChange', this.onScaleChange)
    }

    setX(x: number) {
        this.position.x = Math.floor(x) + (x % Resolution.scale) / Resolution.scale
    }

    setY(y: number) {
        this.position.y = Math.floor(y)
    }

    setInitialWidth(w: number) {
        this.initialWidth = w / Resolution.scale
        this.width = w
    }

    setInitialHeight(w: number) {
        this.initialHeight = w / Resolution.scale
        this.height = w
    }

    destroy() {
        document.removeEventListener('onScaleChange', this.onScaleChange)
        super.destroy()
    }

    onScaleChange = () => {
        this.scale.set(Resolution.scale, Resolution.scale)
        this.width = this.initialWidth * Resolution.scale
        this.height = this.initialHeight * Resolution.scale
    }
}
