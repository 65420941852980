import Resolution from '@/helpers/Resolution'
import * as PIXI from 'pixi.js'

export default class MainBitmapText extends PIXI.BitmapText {
    constructor(
        text: string,
        style: {
            fontSize: number
            fontName?: string
            align?: string
            tint?: number
            letterSpacing?: number
            maxWidth?: number
        }
    ) {
        style.fontName = 'main_' + Resolution.scale + '_' + style.fontSize
        super(text, style as any)

        document.addEventListener('onScaleChange', () => {
            this.fontName = 'main_' + Resolution.scale + '_' + style.fontSize
        })
    }
}
