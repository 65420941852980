import Resolution from '@/helpers/Resolution'
import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import MinerPlayerComponent from './MinerPlayerComponent'
import Miner from '../models/Miner'
import PlayerService from '../services/PlayerService'
import Web3Service from '../services/Web3Service'
import PlayerShipSelectorComponent from './PlayerShipSelectorComponent'
import PlayerGlobalMenuComponent from './PlayerGlobalMenuComponent'
import MainBitmapText from '../pixi-scale/MainBitmapText'
import Web3 from 'web3'
import Colors from '@/helpers/Colors'
import ComethMinerGameService from '../services/web3/ComethMinerGameService'
import { PixelateFilter } from '@pixi/filter-pixelate'

enum PlayerMenu {
    Fleet,
    Main,
    None
}

export default class PlayerMenuComponent implements GameElement {
    public onPullPriceClick = () => {
        return
    }

    private container: PIXI.Container = new PIXI.Container()
    private shipContainer: PIXI.Container = new PIXI.Container()
    private currentMiner: Miner = PlayerService.getInstance().miner
    private minerComponent: MinerPlayerComponent = new MinerPlayerComponent(this.currentMiner)
    private account: PIXI.BitmapText = new MainBitmapText('', { fontSize: 4 })
    private mustCount: PIXI.BitmapText = new MainBitmapText('', { fontSize: 4 })
    private currentShip: PIXI.BitmapText = new MainBitmapText('', { fontSize: 4 })
    private pullPrice: PIXI.BitmapText = new MainBitmapText('', { fontSize: 4 })
    private shipMask: PIXI.Sprite = PIXI.Sprite.from('player_menu_ship_mask')
    public previewBtn = PIXI.Sprite.from('ic_preview_more')
    public onRequirePreview: (miner: Miner) => void = () => {
        return
    }
    public onExitPreview: () => void = () => {
        return
    }

    private mainBar = PIXI.Sprite.from('player_bar_main')
    private shipCircle = PIXI.Sprite.from('player_bar_ship')
    private fleetBar = PIXI.Sprite.from('player_bar_ui')
    private pullPriceBar = PIXI.Sprite.from('pull_price_bar_ui')
    private currentMenu: PlayerMenu = PlayerMenu.None

    private shipSelector: PlayerShipSelectorComponent = new PlayerShipSelectorComponent()
    private playerMenu: PlayerGlobalMenuComponent = new PlayerGlobalMenuComponent()

    constructor() {
        // this.container.addChild(this.bg)

        this.shipMask.x = 1
        this.shipMask.y = 1

        this.minerComponent.getContainer().scale.set(1 / 3 / 2)
        this.minerComponent.getContainer().position.x = Math.floor(24 - this.minerComponent.getContainer().width / 2)
        this.minerComponent.getContainer().position.y = Math.floor(24 - this.minerComponent.getContainer().height / 2)
        this.minerComponent.getContainer().mask = this.shipMask

        this.mainBar.y = 1

        this.account.x = 46
        this.account.y = 6
        this.mustCount.x = 50
        this.mustCount.y = 13
        this.currentShip.x = 50
        this.currentShip.y = 25
        this.currentShip.alpha = 0.8
        this.pullPrice.x = 46
        this.pullPrice.y = 34
        this.pullPrice.alpha = 0.8

        this.mainBar.interactive = true
        this.mainBar.cursor = 'pointer'
        this.mainBar.on('mouseover', () => {
            if (this.currentMenu == PlayerMenu.Main) return
            this.mainBar.texture = PIXI.Texture.from('player_bar_main_hover')
        })
        this.mainBar.on('mouseout', () => {
            if (this.currentMenu == PlayerMenu.Main) return
            this.mainBar.texture = PIXI.Texture.from('player_bar_main')
        })
        this.mainBar.on('pointertap', () => {
            this.selectMenu(PlayerMenu.Main)
        })

        this.fleetBar.x = 45
        this.fleetBar.y = 21
        this.fleetBar.interactive = true
        this.fleetBar.cursor = 'pointer'
        this.fleetBar.on('mouseover', this.onFleetOver)
        this.fleetBar.on('mouseout', this.onFleetOut)
        this.fleetBar.on('pointertap', () => {
            this.selectMenu(PlayerMenu.Fleet)
        })
        this.pullPriceBar.x = 38
        this.pullPriceBar.y = 31
        this.pullPriceBar.interactive = true
        this.pullPriceBar.cursor = 'pointer'
        this.pullPriceBar.on('mouseover', this.onPullOver)
        this.pullPriceBar.on('mouseout', this.onPullOut)
        this.pullPriceBar.on('pointertap', () => {
            this.onPullPriceClick()
        })

        this.container.addChild(this.shipSelector.getContainer())
        this.container.addChild(this.playerMenu.getContainer())

        this.container.addChild(this.mainBar)
        this.container.addChild(this.shipCircle)
        this.container.addChild(this.pullPriceBar)
        this.container.addChild(this.fleetBar)
        this.container.addChild(this.account)
        this.container.addChild(this.mustCount)
        this.container.addChild(this.currentShip)
        this.container.addChild(this.pullPrice)
        this.container.addChild(this.shipMask)
        this.container.addChild(this.minerComponent.getContainer())
        this.container.addChild(this.previewBtn)
        this.handlePreview()

        this.setPositions()

        this.setAccount()

        PlayerService.getInstance().setOnCurrentPlayerChange(async miner => {
            this.currentMiner = miner
            this.minerComponent.miner = miner
            this.minerComponent.update(PlayerService.getInstance().miner)

            const name = await miner.name()
            this.currentShip.text = name
            this.onResize()
        })

        this.mustCount.tint = Colors.Gold
        this.mustCount.text = Web3.utils.fromWei(PlayerService.getInstance().spices, 'ether') + ' MUST'
        PlayerService.getInstance().setOnSpiceChange((spice, changeAmount) => {
            this.mustCount.text = Web3.utils.fromWei(spice, 'ether') + ' MUST'
        })
        document.addEventListener('pullPriceUpdated', () => {
            this.pullPrice.text = 'PULL $     ' + PlayerService.getInstance().miner.pullPriceInSpice() + ' MUST'
        })
        this.onResize()
    }

    async enterAnimation(callback: () => void): Promise<void> {
        callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        callback()
    }

    onResize(): void {
        this.container.scale.set(Resolution.scale)
        this.setPositions()
    }

    public tick(): void {
        // this.bg!.tilePosition.y += 0.1
        this.shipSelector.tick()
    }

    public getContainer(): PIXI.Container {
        return this.container
    }

    private onFleetOver = () => {
        if (this.currentMenu == PlayerMenu.Fleet) return
        this.fleetBar.texture = PIXI.Texture.from('player_bar_ui_hover')
    }
    private onFleetOut = () => {
        if (this.currentMenu == PlayerMenu.Fleet) return
        this.fleetBar.texture = PIXI.Texture.from('player_bar_ui')
    }

    private onPullOver = () => {
        this.pullPriceBar.texture = PIXI.Texture.from('pull_price_bar_ui_hover')
    }
    private onPullOut = () => {
        this.pullPriceBar.texture = PIXI.Texture.from('pull_price_bar_ui')
    }

    private setPositions() {
        // tood
    }

    public async setAccount() {
        const accountStr: string = await Web3Service.getInstance().getAccount()

        this.account.text = accountStr.substr(0, 8) + '...' + accountStr.substr(accountStr.length - 8, accountStr.length)
        this.onResize()
    }

    private selectMenu(menu: PlayerMenu) {
        if (this.currentMenu == menu) {
            this.currentMenu = PlayerMenu.None
        } else {
            this.currentMenu = menu
        }

        this.account.alpha = 1
        this.mustCount.alpha = 1
        this.currentShip.alpha = 0.8

        if (this.currentMenu == PlayerMenu.Fleet) {
            this.fleetBar.texture = PIXI.Texture.from('player_bar_ui_selected')
            this.mainBar.texture = PIXI.Texture.from('player_bar_main')
            this.currentShip.alpha = 1
            this.account.alpha = 0.8
            this.mustCount.alpha = 0.8
            this.shipSelector.enterAnimation(() => {
                return
            })
            this.playerMenu.exitAnimation(() => {
                return
            })
        } else if (this.currentMenu == PlayerMenu.Main) {
            this.fleetBar.texture = PIXI.Texture.from('player_bar_ui')
            this.mainBar.texture = PIXI.Texture.from('player_bar_main_selected')

            this.shipSelector.exitAnimation(() => {
                return
            })
            this.playerMenu.enterAnimation(() => {
                return
            })
        } else {
            this.fleetBar.texture = PIXI.Texture.from('player_bar_ui')
            this.mainBar.texture = PIXI.Texture.from('player_bar_main')

            this.shipSelector.exitAnimation(() => {
                return
            })
            this.playerMenu.exitAnimation(() => {
                return
            })
        }
    }

    private handlePreview() {
        this.previewBtn.x = 0
        this.previewBtn.y = 35
        this.previewBtn.interactive = true
        this.previewBtn.cursor = 'pointer'
        this.previewBtn.on('mouseover', () => {
            this.previewBtn.texture = PIXI.Texture.from('ic_preview_more_hover')
            if (this.container.alpha == 1) this.onRequirePreview(PlayerService.getInstance().miner)
        })
        this.previewBtn.on('mouseout', () => {
            this.previewBtn.texture = PIXI.Texture.from('ic_preview_more')
            this.onExitPreview()
        })
    }
}
