import CometSymbol from '@/game/services/web3/Cometh/CometSymbol'
import TubeService from '@/game/services/web3/TubeService'
import BN from 'bn.js'
import PoolPrize from './PoolPrize'
import TimeService from '@/game/services/TimeService'
import MultiTokenPoolService from '@/game/services/web3/MultiTokenPoolService'
import { ERC1155_POOL_TYPE } from '@/TubePoolsData'
import { CurrencyHelper } from '@/helpers/CurrencyHelper'

export default class TubePool {
    public maxStake: BN = new BN(0)
    public rewardRate: BN = new BN(0)
    public token = ''
    public periodStart = 0
    public prizes: PoolPrize[] = new Array<PoolPrize>()

    constructor(
        public id = 0,
        public prizesData = [] as any,
        public name: string = '',
        public placeHolder = '',
        public description = '',
        public tokenSymbol = '',
        public type = '',
        public address = ''
    ) {}

    async load() {
        const poolData = await TubeService.getInstance().getPool(this.id)
        this.maxStake = new BN(poolData.maxStake)
        this.rewardRate = new BN(poolData.rewardRate)
        this.token = poolData.token
        this.periodStart = parseInt(poolData.periodStart)

        this.prizes = []
        for (let i = 0; i < this.prizesData.length; i++) {
            const id = this.prizesData[i]['id']
            const points = await TubeService.getInstance().prizePoints(this.id, this.prizesData[i]['id'])
            const img = this.prizesData[i]['img']
            const name = this.prizesData[i]['name']
            const description = this.prizesData[i]['description']

            const fixedSupply = this.type != ERC1155_POOL_TYPE

            let supply = 0
            if (fixedSupply) {
                supply = await this.getPrizeSupply(id)
            }

            this.prizes.push(new PoolPrize(id, new BN(points), img, name, description, fixedSupply, supply))
        }

        this.prizes = this.prizes.sort((a, b) => {
            return a.points.lt(b.points) ? -1 : 0
        })
    }

    async getPrizeSupply(prizeId: number) {
        return await MultiTokenPoolService.getInstance().getItemSupply(prizeId, this.address)
    }

    async earned(): Promise<BN> {
        const result = await TubeService.getInstance().earned(this.id)
        return new BN(result)
    }

    public isStarted(): boolean {
        const now = TimeService.getInstance().now()
        return this.periodStart < now
    }

    async balanceOf(): Promise<BN> {
        const result = await TubeService.getInstance().balanceOf(this.id)
        return new BN(result)
    }

    public getDustPerDayRateFromTokenAmount(tokenAmount: number) {
        const daySecMust = new BN(60 * 60 * 24 * tokenAmount)
        return CurrencyHelper.weiToEthRounded(this.rewardRate.mul(daySecMust).toString()).toString()
    }

    public getMinPoints() {
        let min = new BN('99999999999999999000000000000000000')
        this.prizes.forEach((prize: PoolPrize) => {
            if (prize.points.lt(min)) {
                min = prize.points
            }
        })

        return min
    }

    public getMaxPoints() {
        let max = new BN(0)
        this.prizes.forEach((prize: PoolPrize) => {
            if (prize.points.gt(max)) {
                max = prize.points
            }
        })

        return max
    }

    public getGroupedPoolPrize() {
        const groupedPrices: PoolPrize[][] = []
        let currGroup: PoolPrize[] = []
        let currPrice = new BN(0)
        this.prizes.forEach((it, i) => {
            if (!it.points.eq(currPrice)) {
                currGroup = []
                groupedPrices.push(currGroup)
            }
            currPrice = it.points

            currGroup.push(it)
        })

        return groupedPrices
    }
}
