import * as PIXI from 'pixi.js'
import Colors from './Colors'
import Resolution from './Resolution'

export default class ScrollableContainer extends PIXI.Container {
    public wrapper: PIXI.Container = new PIXI.Container()
    private scrollAcceleration = 0
    private scrollMaskHeight = 0
    private isDragging = false
    public hasMoved = false
    public scrollIndicator: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private scrollHeight = 0
    public scrollMask: PIXI.Sprite = PIXI.Sprite.from('pixel')
    private scrollAnimationCanceler?: NodeJS.Timeout
    public onScrolled: (scrollY: number, scrollVelocity: number) => void = () => {
        return
    }

    constructor(public initialY: number, private scrollWidth: number) {
        super()

        this.scrollIndicator.width = 2
        this.scrollIndicator.height = 6
        this.scrollIndicator.tint = Colors.Blue400
        this.scrollMask.width = scrollWidth
        this.interactive = true
        this.interactiveChildren = true

        this.wrapper.y = initialY
        this.wrapper.addChild(this)
        this.wrapper.addChild(this.scrollMask)
        this.wrapper.addChild(this.scrollIndicator)

        this.mask = this.scrollMask
        let baseY = 0
        let lastDelta = 0
        let distance = 0
        let startTime = 0
        this.on('pointerdown', (event: PIXI.InteractionEvent) => {
            this.isDragging = true
            this.scrollAcceleration = 0
            distance = 0
            lastDelta = 0
            startTime = Date.now()
            this.hasMoved = false
            baseY = this.y - event.data.global.y / Resolution.scale
        })
        document.addEventListener('pointerup', (event: MouseEvent) => {
            if (this.isDragging) {
                this.isDragging = false
            }

            const diff = lastDelta / (Resolution.scale * Resolution.scale) / 3
            const distanceModifier = Math.abs(distance) / 4
            const timeModifier = Math.max(1, 200 / (Date.now() - startTime))
            if (Math.sign(diff) == Math.sign(distance)) {
                this.scrollAcceleration = -diff * distanceModifier * timeModifier
            }

            distance = 0
            lastDelta = 0
        })
        document.addEventListener('pointermove', (event: MouseEvent) => {
            if (this.isDragging) {
                this.hasMoved = true
                const futurY = baseY + event.clientY / Resolution.scale
                const futurDelta = futurY - this.y
                if (Math.sign(futurDelta) != Math.sign(lastDelta)) {
                    distance = 0
                }
                lastDelta = futurDelta
                distance += lastDelta
                this.y = futurY
                this.onScrolled(this.y, lastDelta)
                this.checkScrollState()
                clearTimeout(this.scrollAnimationCanceler!)
                this.scrollAnimationCanceler = setTimeout(() => {
                    lastDelta = 0
                }, 35)
            }
        })
    }

    tick(): void {
        // nothing
        if (this.scrollAcceleration != 0) {
            if (!this.isDragging) {
                const scrolledValue = (PIXI.Ticker.shared.deltaMS * this.scrollAcceleration) / 4
                this.y -= scrolledValue
                this.scrollAcceleration -= this.scrollAcceleration / PIXI.Ticker.shared.deltaMS / 5
                if (Math.abs(this.scrollAcceleration) < 0.001) {
                    this.scrollAcceleration = 0
                }
                this.onScrolled(this.y, scrolledValue)
                this.checkScrollState()
            }
        }

        if (this.y < -this.scrollHeight + this.scrollMaskHeight) {
            this.y = -this.scrollHeight + this.scrollMaskHeight
            this.scrollAcceleration = 0
            this.onScrolled(this.y, 0)
            this.checkScrollState()
        }
        if (this.y > 0) {
            this.y = 0
            this.scrollAcceleration = 0
            this.onScrolled(this.y, 0)
            this.checkScrollState()
        }
    }

    public updateHeight(height: number) {
        this.scrollMask.height = height
        this.scrollMaskHeight = height
    }

    public checkScrollState() {
        const perc = Math.abs(this.y) / (this.scrollHeight - this.scrollMaskHeight)
        this.scrollIndicator.y = (this.scrollMaskHeight - 6) * perc
        this.scrollIndicator.x = this.width - 1

        if (this.scrollHeight < this.scrollMaskHeight) {
            this.scrollIndicator.visible = false
        } else {
            this.scrollIndicator.visible = true
        }
    }

    public onChildrenChange() {
        super.onChildrenChange()
        this.scrollHeight = this.height
    }
}
