import axios from 'axios'
import LiquidityToken from '@/models/LiquidityToken'
import Token from '@/models/Token'

export default class TheGraphService {
    public static instance: TheGraphService

    public static getInstance(): TheGraphService {
        if (!this.instance) {
            this.instance = new TheGraphService()
        }

        return this.instance
    }

    public async getPlayerShip(owner: string, ship: string) {
        const request = {
            query:
                `
            {
              playerShips (where: {player: "` +
                owner.toLowerCase() +
                `", ship: "` +
                ship +
                `"}) {
                ship
                jumpsCount
                jumpsExpense
                pullsCount
                pullsEarnings
                minesCount
                rewards {
                  token {
                    id
                  }
                  amount
                }
              }
            }
`
        }
        const thegraphurl = 'https://api.thegraph.com/subgraphs/name/cometh-game/cometh-stats'
        const result = await axios.post(thegraphurl, request)
        return result.data.data.playerShips[0]
    }

    public async getLeaderboard(solarSystemID: number, block: number, endBlock: number | null) {
        const request = {
            query: `
          {
            _meta{ block{ number } }
            start: ships(
              where: { solarSystem: ${solarSystemID} },
              block: { number: ${block} },
              orderBy: totalRewardsMust,
              orderDirection: desc,
              first: 1000
            ) {
              id
              ship
              owner
              totalRewardsUSD
              minesCount
            }
            end: ships(
              where: { solarSystem: ${solarSystemID} },
              ${endBlock ? `block: { number: ${endBlock} },` : ''}
              orderBy: totalRewardsMust,
              orderDirection: desc,
              first: 1000
            ) {
              id
              ship
              owner
              totalRewardsUSD
              minesCount
            }
          }
          `
        }
        const thegraphurl = 'https://api.thegraph.com/subgraphs/name/cometh-game/cometh-rankings'
        const result = await axios.post(thegraphurl, request)
        return [result.data.data.start, result.data.data.end, result.data.data['_meta'].block.number]
    }

    public async getRentalClaims(rentalId: string) {
        const request = {
            query:
                `
            {
              effectiveRentingContract (id: "` +
                rentalId.toLowerCase() +
                `") {
                claims{
                  claimer
                  token {
                    id
                  }
                  amount
                }
              }
            }
`
        }
        const thegraphurl = 'https://api.thegraph.com/subgraphs/name/cometh-game/cometh-rental'
        const result = await axios.post(thegraphurl, request)
        return result.data.data.effectiveRentingContract.claims
    }

    public async getETHUSDPrice() {
        const request = {
            query: `
            {
              bundle(id: 1) {
                ethPrice
              }
            }
`
        }
        const thegraphurl = 'https://api.thegraph.com/subgraphs/name/cometh-game/comethswap'
        const result = await axios.post(thegraphurl, request)
        return result.data.data.bundle.ethPrice
    }

    public async getLiquidityPositionsOf(user: string) {
        const request = {
            query:
                `
            {
              user(id: "` +
                user.toLowerCase() +
                `") {
                liquidityPositions {
                  id
                  pair {
                    token0 {
                      id
                      symbol
                    }
                    token1 {
                      id
                      symbol
                    }
                    reserve0
                    reserve1
                    totalSupply
                    reserveUSD
                  }
                  liquidityTokenBalance
                }
              }
            }
`
        }
        const thegraphurl = 'https://api.thegraph.com/subgraphs/name/cometh-game/comethswap'
        const result = await axios.post(thegraphurl, request)
        const liquidityTokenBalances = Array<LiquidityToken>()

        for (let i = 0; i < result.data.data.user.liquidityPositions.length; i++) {
            if (result.data.data.user.liquidityPositions[i].liquidityTokenBalance == '0') continue
            const liquidityPosition = new LiquidityToken(
                result.data.data.user.liquidityPositions[i].pair.id,
                result.data.data.user.liquidityPositions[i].pair.token0.symbol,
                result.data.data.user.liquidityPositions[i].pair.token0.id,
                result.data.data.user.liquidityPositions[i].pair.token1.symbol,
                result.data.data.user.liquidityPositions[i].pair.token1.id,
                result.data.data.user.liquidityPositions[i].pair.reserve0,
                result.data.data.user.liquidityPositions[i].pair.reserve1,
                result.data.data.user.liquidityPositions[i].pair.totalSupply,
                result.data.data.user.liquidityPositions[i].pair.reserveUSD,
                result.data.data.user.liquidityPositions[i].liquidityTokenBalance
            )
            await liquidityPosition.init()
            liquidityTokenBalances.push(liquidityPosition)
        }
        return liquidityTokenBalances
    }

    public async getTokens(addresses: string[]) {
        let data = ''
        for (let i = 0; i < addresses.length; i++) {
            data = data.concat(
                `
            token_` +
                    addresses[i].toLowerCase() +
                    `: token(id: "` +
                    addresses[i].toLowerCase() +
                    `") {
              id
              symbol
              derivedETH
              totalSupply
            }`
            )
        }
        const request = {
            query:
                `
            {
              ` +
                data +
                `
            }
`
        }
        const thegraphurl = 'https://api.thegraph.com/subgraphs/name/cometh-game/comethswap'
        const result = await axios.post(thegraphurl, request)
        const keys = Object.getOwnPropertyNames(result.data.data)

        const tokens = Array<Token>()

        const ethPrice = await this.getETHUSDPrice()
        for (let i = 0; i < keys.length; i++) {
            if (result.data.data[keys[i]] == null) {
                continue
            }
            const liquidityPosition = new Token(
                result.data.data[keys[i]].id,
                result.data.data[keys[i]].symbol,
                result.data.data[keys[i]].derivedETH,
                result.data.data[keys[i]].totalSupply,
                ethPrice
            )
            await liquidityPosition.init()
            tokens.push(liquidityPosition)
        }
        return tokens
    }

    public async getPairs(pairs: string[]) {
        if (pairs.length == 0) {
            return []
        }
        let data = ''
        for (let i = 0; i < pairs.length; i++) {
            data = data.concat(
                `
            pair_` +
                    pairs[i].toLowerCase() +
                    `: pair(id: "` +
                    pairs[i].toLowerCase() +
                    `") {
              id
              token0 {
                id
                symbol
              }
              token1 {
                id
                symbol
              }
              reserve0
              reserve1
              totalSupply
              reserveUSD
            }`
            )
        }
        const request = {
            query:
                `
            {
              ` +
                data +
                `
            }
`
        }
        const thegraphurl = 'https://api.thegraph.com/subgraphs/name/cometh-game/comethswap'
        const result = await axios.post(thegraphurl, request)
        const keys = Object.getOwnPropertyNames(result.data.data)

        const liquidityTokenBalances = Array<LiquidityToken>()

        for (let i = 0; i < keys.length; i++) {
            const liquidityPosition = new LiquidityToken(
                result.data.data[keys[i]].id,
                result.data.data[keys[i]].token0.symbol,
                result.data.data[keys[i]].token0.id,
                result.data.data[keys[i]].token1.symbol,
                result.data.data[keys[i]].token1.id,
                result.data.data[keys[i]].reserve0,
                result.data.data[keys[i]].reserve1,
                result.data.data[keys[i]].totalSupply,
                result.data.data[keys[i]].reserveUSD
            )
            await liquidityPosition.init()
            liquidityTokenBalances.push(liquidityPosition)
        }
        return liquidityTokenBalances
    }
}
