import { GasStationService } from '@/game/services/GasStationService'

export class GasPrices {
    slow = 0
    standard = 0
    fast = 0
    fastest = 0

    public getDefault() {
        let val = this.fast
        if (GasStationService.getInstance().getDefault() == GasStationService.GAS_STANDARD) {
            val = this.standard
        } else if (GasStationService.getInstance().getDefault() == GasStationService.GAS_FAST) {
            val = this.fast
        } else if (GasStationService.getInstance().getDefault() == GasStationService.GAS_FASTEST) {
            val = this.fastest
        } else {
            val = GasStationService.getInstance().getDefault()
        }

        return val
    }
}
