import GameElement from '../GameElement'
import * as PIXI from 'pixi.js'
import Miner from '../models/Miner'
import Comete from '../models/Comete'
import Resolution from '@/helpers/Resolution'
import StakingComet from '../models/StakingComet'
import Colors from '@/helpers/Colors'
import { GlowFilter } from '@pixi/filter-glow'
import GalaxyService from '@/services/GalaxyService'
import MapService from '../services/MapService'

export default class CometComponent implements GameElement {
    public container: PIXI.Container = new PIXI.Container()
    private cometeParticleContainer: PIXI.ParticleContainer = new PIXI.ParticleContainer(1500, {})
    public bodyContainer: PIXI.Container = new PIXI.Container()
    public body: PIXI.Sprite = PIXI.Sprite.from('0_comet')
    public overlay: PIXI.Sprite = PIXI.Sprite.from('0_comet_overlay')
    public width = this.body.width
    public height = this.body.height
    public parentHeight = 0

    constructor(public comet: Comete) {
        this.container.addChild(this.bodyContainer)
        this.bodyContainer.addChild(this.body)

        this.container.addChild(this.body)
        this.container.addChild(this.cometeParticleContainer)
        this.bodyContainer.addChild(this.body)
        this.bodyContainer.addChild(this.overlay)

        if (comet instanceof StakingComet) {
            this.body.filters = [new GlowFilter({ color: Colors.Green500, outerStrength: 2 })]
        } else {
            this.body.filters = []
        }

        this.update()
    }

    update() {
        this.body.texture = PIXI.Texture.from(this.cometVisualId + '_comet')
        this.overlay.texture = PIXI.Texture.from(this.cometVisualId + '_comet_overlay')
        this.onResize()
    }

    tick(): void {
        // nothing
        if (!this.parentHeight) {
            this.parentHeight = this.container.parent.height / this.container.scale.x
        }
        this.cometeParticleContainer.children.forEach(it => {
            if (it.y > this.parentHeight) {
                it.destroy()
                return
            }
            it.y += PIXI.Ticker.shared.deltaMS * (it as any).speed
            it.alpha += PIXI.Ticker.shared.deltaMS / 500
            if (it.alpha > 1) it.alpha = 1
        })
        if (Math.random() * 100 < 80) {
            const newParticle = PIXI.Sprite.from(this.cometVisualId + '_comet_particle')
            // newParticle.tint = Math.random() * 0x333333
            newParticle.x = 52 + Math.random() * (this.width - 110)
            newParticle.y = Math.abs(this.width / 2 - newParticle.x * 1.2)
            ;(newParticle as any).speed = (1 + Math.random()) / 6 - newParticle.y / 1000
            newParticle.scale.set(1 - newParticle.y / 600)
            newParticle.alpha = 0
            this.cometeParticleContainer.addChild(newParticle)
        }
    }

    onResize(): void {
        // nothing
    }

    getContainer(): PIXI.Container {
        return this.container
    }

    get scaledWidth(): number {
        return this.width * this.getContainer().scale.x
    }

    async enterAnimation(callback: () => void): Promise<void> {
        return await callback()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        return await callback()
    }

    get cometVisualId() {
        return GalaxyService.getInstance().getCometVisualId(MapService.getInstance().currentSolarSystemId)
    }
}
