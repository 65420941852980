import Resolution from '@/helpers/Resolution'
import GameElement from './GameElement'
import * as PIXI from 'pixi.js'
import { animate } from '@/helpers/AnimationHelper'
import PlayerService from './services/PlayerService'
import Distance from '@/helpers/Distance'
import Miner from './models/Miner'
import JumpConfirmModal from './components/JumpConfirmModal'
import MapComponent from './components/MapComponent'
import GTilingSprite from './pixi-scale/GTilingSprite'
import TutorialService from './services/TutorialService'
import Comete from './models/Comete'
import StakingComet from './models/StakingComet'
import MapDetailModal from './components/MapDetailModal'
import NotificationManager from './components/notifications/NotificationManager'
import { addTooltip, TooltipPosition } from '@/helpers/TooltipHelper'
import { MapFilter } from './models/map/MapFilter'
import MinerPreviewTooltip from './components/MinerPreviewTooltip'
import { OutlineFilter } from '@pixi/filter-outline'
import Colors from '@/helpers/Colors'
import MainBitmapText from './pixi-scale/MainBitmapText'
import MapService from './services/MapService'
import MinerJumpPreviewComponent from './components/MinerJumpPreviewComponent'
import CometPreviewComponent from './components/CometPreviewComponent'
import ContextualMenuService, { ContextualMenuEvent, ContextualMenuItem } from './services/ContextualMenuService'
import StarPreviewComponent from './components/stars/StarPreviewComponent'
import GalaxyComponent from './components/stars/GalaxyComponent'
import JumpManagerService from './services/web3/JumpManagerService'
import StakedSpaceshipsService from './services/web3/StakedSpaceshipsService'
import CometManagerService from './services/web3/CometManagerService'
import KeyboardService from './services/KeyboardService'
import NotificationService from './services/NotificationService'
import { Notification, NotificationType } from './models/notification/Notification'
import MiningManagerService from './services/web3/MiningManagerService'
import RoverManagerService from './services/web3/RoverManagerService'
import LeaderboardModal from './components/modals/LeaderboardModal'
import MiniLeaderboardComponent from './components/MiniLeaderboardComponent'
import GalaxyService from '@/services/GalaxyService'

class JumpPathItem {
    constructor(public text: MainBitmapText, public miner: Miner, public active: boolean = false) {}
}

export default class GMap implements GameElement {
    public onEntityPreview: (entity: Comete | StakingComet | Miner, y: number) => void = () => {
        return
    }
    public onEntityPreviewClear: () => void = () => {
        return
    }

    private container: PIXI.Container = new PIXI.Container()
    private cometPreviewContainer: PIXI.Container = new PIXI.Container()
    private loaderContainer: PIXI.Container = new PIXI.Container()
    private mapContainer: PIXI.Container = new PIXI.Container()
    private jumpPathContainer: PIXI.Container = new PIXI.Container()
    private toolsContainer: PIXI.Container = new PIXI.Container()
    private priceHeatContainer: PIXI.Container = new PIXI.Container()
    private leaderboardContainer: PIXI.Container = new PIXI.Container()
    private icLeaderboard = PIXI.Sprite.from('ic_leaderboard')
    private bg!: PIXI.TilingSprite
    private spaceBg!: PIXI.Sprite
    private currentMiner: Miner = PlayerService.getInstance().miner
    private currentModal?: MapDetailModal
    private currentJumpConfirm: JumpConfirmModal = new JumpConfirmModal()
    private mapCloser!: PIXI.Sprite
    private jumpGraphic = new PIXI.Graphics()
    private mapComponent = new MapComponent()
    private notificationManager: NotificationManager = NotificationManager.getInstance()
    private centerBtn = PIXI.Sprite.from('map_center')
    private strategyBtn = PIXI.Sprite.from('ic_strategy_on')
    private galaxyBtn = PIXI.Sprite.from('ic_galaxy')
    private minerJumpPreviewComponent: MinerJumpPreviewComponent = new MinerJumpPreviewComponent()
    private cometPreviewComponent: CometPreviewComponent = new CometPreviewComponent()
    private currentStar: StarPreviewComponent = new StarPreviewComponent()
    private leaderboardModal: LeaderboardModal = new LeaderboardModal()
    private miniLeaderboard: MiniLeaderboardComponent = new MiniLeaderboardComponent()
    private galaxyMap: GalaxyComponent = new GalaxyComponent()
    private loaderBg = PIXI.Sprite.from('pixel')
    private loaderText = new MainBitmapText('Loading...', { fontSize: 8 })

    private mouseOverMiner?: Miner = undefined
    private isJumping = false
    private isDragging = false
    private mouseX = 0
    private mouseY = 0
    private isAnimating = false
    private jumpPath: JumpPathItem[] = []
    private tempPathItem?: JumpPathItem = undefined
    private tempJumpPathPreview: JumpPathItem[] = []
    private minerHeight = 0

    public static SWITCH_TO_SOLAR_SYSTEM_EVENT = 'SWITCH_TO_SOLAR_SYSTEM_EVENT'

    private minerPreview!: MinerPreviewTooltip

    public onClose: () => void = () => {
        return
    }

    constructor(public pixi: PIXI.Application) {
        this.container.addChild(this.mapContainer)

        document.addEventListener(GMap.SWITCH_TO_SOLAR_SYSTEM_EVENT, event => {
            const toSystemId = (event as CustomEvent).detail
            this.switchToSolarSystem(toSystemId)
        })

        KeyboardService.getInstance().setOnShiftStateChanged(it => {
            this.drawJumpGraphic()
        })

        this.minerPreview = new MinerPreviewTooltip()
        this.mapComponent.onMinerOver = miner => {
            this.mouseOverMiner = miner
            if (this.isDragging) return

            this.minerPreview.getContainer().filters = [new OutlineFilter(Resolution.scale, Colors.Blue300) as any]

            this.tempPathItem = this.constructJumpItem(miner)
            this.drawJumpGraphic()

            this.minerPreview.asyncUpdate(miner)
            this.container.addChild(this.minerPreview.getContainer())
        }
        this.mapComponent.onMinerOut = miner => {
            this.mouseOverMiner = undefined
            this.tempPathItem?.text.destroy()
            this.tempPathItem = undefined
            this.drawJumpGraphic()
            this.onMinerOut()
        }
        this.mapComponent.onMinerLeaveInteractiveArea = (miner: Miner) => {
            if (miner.id == this.mouseOverMiner?.id) {
                this.onMinerOut()
            }
        }
        this.mapComponent.onReady = () => {
            // nothing
        }
        this.bg = new GTilingSprite('map_bg') as GTilingSprite
        this.spaceBg = PIXI.Sprite.from('star_bg_default')
        this.bg.anchor.set(0, 0)
        this.mapContainer.addChild(this.spaceBg)
        this.mapContainer.addChild(this.bg)
        this.mapContainer.interactive = true
        this.mapContainer.cursor = 'move'
        let baseBgX = 0
        let baseBgY = 0
        let baseContainerX = 0
        let baseContainerY = 0
        this.mapContainer.on('pointerdown', (event: PIXI.InteractionEvent) => {
            this.isDragging = true
            baseBgX = this.bg.tilePosition.x - event.data.global.x / Resolution.scale
            baseBgY = this.bg.tilePosition.y - event.data.global.y / Resolution.scale

            baseContainerX = this.mapComponent.getContainer().position.x - event.data.global.x
            baseContainerY = this.mapComponent.getContainer().position.y - event.data.global.y
        })
        document.addEventListener('pointerup', () => {
            this.isDragging = false
        })
        this.mapContainer.on('pointermove', (event: PIXI.InteractionEvent) => {
            if (this.isDragging) {
                this.bg.tilePosition.x = baseBgX + event.data.global.x / Resolution.scale
                this.bg.tilePosition.y = baseBgY + event.data.global.y / Resolution.scale
                this.mapComponent.getContainer().position.x = baseContainerX + event.data.global.x
                this.mapComponent.getContainer().position.y = baseContainerY + event.data.global.y

                this.recenterSpaceBg()
                this.updateMapArea()
            }
        })
        this.setPositions()

        this.bg.y = -10000

        this.mapContainer.addChild(this.mapComponent.getContainer())
        this.container.addChild(this.galaxyMap)
        this.galaxyMap.visible = false
        this.galaxyMap.onClose = () => {
            this.toggleGalaxyMap()
        }

        this.constructPriceHeatMapBar()
        this.priceHeatContainer.y = 0
        this.priceHeatContainer.x = -2

        this.strategyBtn.alpha = 0.8
        this.strategyBtn.interactive = true
        this.strategyBtn.cursor = 'pointer'
        this.strategyBtn.on('pointertap', () => {
            if (this.mapComponent.mapFilter == MapFilter.Friendly) {
                this.mapComponent.setPullPriceFilter()
                this.strategyBtn.texture = PIXI.Texture.from('ic_strategy_on')
                this.priceHeatContainer.visible = true
                this.onResize()
            } else {
                this.mapComponent.setFriendlyFilter()
                this.strategyBtn.texture = PIXI.Texture.from('ic_strategy_off')
                this.priceHeatContainer.visible = false
                this.onResize()
            }
        })
        this.strategyBtn.on('mouseover', () => {
            this.strategyBtn.alpha = 1
        })
        this.strategyBtn.on('mouseout', () => {
            this.strategyBtn.alpha = 0.8
        })

        this.centerBtn.alpha = 0.8
        this.centerBtn.interactive = true
        this.centerBtn.cursor = 'pointer'
        this.centerBtn.on('pointertap', () => {
            this.centerMap()
        })
        this.centerBtn.on('mouseover', () => {
            this.centerBtn.alpha = 1
        })
        this.centerBtn.on('mouseout', () => {
            this.centerBtn.alpha = 0.8
        })

        this.mapCloser = PIXI.Sprite.from('ic_close_map')
        this.mapCloser.alpha = 0.8
        this.mapCloser.interactive = true
        this.mapCloser.cursor = 'pointer'
        this.mapCloser.on('pointertap', () => {
            this.onClose()
        })
        this.mapCloser.on('mouseover', () => {
            this.mapCloser.alpha = 1
        })
        this.mapCloser.on('mouseout', () => {
            this.mapCloser.alpha = 0.8
        })

        this.galaxyBtn = PIXI.Sprite.from('ic_galaxy')
        this.galaxyBtn.alpha = 0.9
        this.galaxyBtn.interactive = true
        this.galaxyBtn.cursor = 'pointer'
        this.galaxyBtn.on('pointertap', () => {
            this.toggleGalaxyMap()

            this.centerMap()
        })
        this.galaxyBtn.on('mouseover', () => {
            this.galaxyBtn.alpha = 1
        })
        this.galaxyBtn.on('mouseout', () => {
            this.galaxyBtn.alpha = 0.9
        })

        addTooltip(this.strategyBtn, this.toolsContainer, 'Pull Price Filter', TooltipPosition.Left)
        addTooltip(this.centerBtn, this.toolsContainer, 'Re-Center Map', TooltipPosition.Left)
        addTooltip(this.mapCloser, this.toolsContainer, 'Close Map', TooltipPosition.Left)
        addTooltip(this.galaxyBtn, this.toolsContainer, 'Toggle Galaxy Map', TooltipPosition.Left)

        this.currentJumpConfirm.onJumpIndexUpdated = index => {
            this.drawJumpGraphic()
        }
        this.currentJumpConfirm.onStepRemoved = index => {
            this.deleteJumpPath(index)
        }

        /**
         * LEADERBOARD
         */
        this.icLeaderboard.interactive = true
        this.icLeaderboard.alpha = 0.8
        this.icLeaderboard.on('pointertap', () => {
            this.onResize()
            this.leaderboardModal.refresh()
            this.pixi.stage.addChild(this.leaderboardModal.getContainer())
            this.leaderboardModal.enterAnimation(() => {
                return
            })
            this.leaderboardModal.onExit = () => {
                this.leaderboardModal.exitAnimation(() => {
                    this.pixi.stage.removeChild(this.leaderboardModal.getContainer())
                    return
                })
            }
            // window.open(window.location + 'leaderboard')
        })
        this.icLeaderboard.on('mouseover', () => {
            this.icLeaderboard.alpha = 1
        })
        this.icLeaderboard.on('mouseout', () => {
            this.icLeaderboard.alpha = 0.8
        })
        this.icLeaderboard.cursor = 'pointer'
        addTooltip(this.icLeaderboard, this.leaderboardContainer, 'Ranking')

        this.miniLeaderboard.onRefreshed = () => {
            this.onResize()
        }
        this.loaderBg.alpha = 0.5
        this.toolsContainer.addChild(this.mapCloser)
        this.toolsContainer.addChild(this.centerBtn)
        this.toolsContainer.addChild(this.strategyBtn)
        this.toolsContainer.addChild(this.galaxyBtn)
        this.loaderContainer.addChild(this.loaderBg)
        this.loaderContainer.addChild(this.loaderText)
        this.container.addChild(this.loaderContainer)
        this.container.addChild(this.minerJumpPreviewComponent.getContainer())
        this.container.addChild(this.cometPreviewContainer)
        this.container.addChild(this.currentStar)
        this.leaderboardContainer.addChild(this.icLeaderboard)
        this.leaderboardContainer.addChild(this.miniLeaderboard)
        this.leaderboardContainer.visible = false
        this.container.addChild(this.leaderboardContainer)
        this.cometPreviewContainer.addChild(this.cometPreviewComponent.getContainer())
        this.container.addChild(this.priceHeatContainer)
        this.container.addChild(this.toolsContainer)

        this.minerJumpPreviewComponent.onOpening = perc => {
            this.cometPreviewContainer.y = Resolution.margin6 + this.minerHeight * perc
        }
        this.minerJumpPreviewComponent.onClosing = perc => {
            this.cometPreviewContainer.y = this.minerHeight - this.minerHeight * perc
        }

        this.mapComponent.onPlayerClick = miner => {
            const isCurrentMiner = miner.id == PlayerService.getInstance().miner.id
            if (!isCurrentMiner) {
                // TODO MULTI JUMP

                if (!KeyboardService.getInstance().isShiftPressed && miner.isSelf) {
                    const event = new ContextualMenuEvent()
                    event.getX = () => {
                        return miner.x * this.mapComponent.scale + this.mapComponent.container.x
                    }
                    event.getY = () => {
                        return miner.y * this.mapComponent.scale + this.mapComponent.container.y
                    }

                    let jumpItemText = 'Jump'
                    if (PlayerService.getInstance().allowance.lt(miner.pullingPrice)) {
                        jumpItemText = 'Approve'
                    }
                    event.items.push(
                        new ContextualMenuItem(jumpItemText, () => {
                            this.handleJumpPush(miner)
                            this.batchJump()
                        }),
                        new ContextualMenuItem('Select', () => {
                            PlayerService.getInstance().updateMainPlayer(miner)
                        })
                    )
                    ContextualMenuService.getInstance().requestContextualMenu(event)
                } else {
                    this.startJumpConfirmation(miner)
                }
            }
        }
        this.mapComponent.onCometClick = (comet: Comete | StakingComet) => {
            this.mapComponent.focusedComet = comet
            this.mapComponent.drawCometeOrbits()
            this.cometPreviewComponent.update(comet)
            this.cometPreviewComponent.enterAnimation(() => {
                return
            })
            this.cometPreviewContainer.position.x = Resolution.realWidth - this.cometPreviewContainer.width - Resolution.margin6
        }

        document.addEventListener('serverPostTick', () => {
            if (this.currentJumpConfirm) {
                this.drawJumpGraphic()
            }

            this.currentModal?.update()
        })

        PlayerService.getInstance().setOnCurrentPlayerChange(miner => {
            if (miner.solarSystemID != MapService.getInstance().currentSolarSystemId) {
                this.switchToSolarSystem(miner.solarSystemID)
            }

            if (miner.solarSystemID != this.currentMiner.solarSystemID) {
                this.stopJumpModal()
            }
            this.stopJumpModal()
            this.currentMiner = miner
        })

        this.handlePreviewEvents()

        document.addEventListener(MapService.CURRENT_SOLAR_SYSTEM_CHANGING, () => {
            //console.log('CHANGING')
            this.loaderContainer.visible = true
            animate('easeInQuad', 100, perc => {
                this.loaderBg.alpha = perc / 2
                this.loaderText.alpha = perc
            })
        })
        document.addEventListener(MapService.CURRENT_SOLAR_SYSTEM_CHANGED, async () => {
            this.setInfos()
            this.centerMap()
            await animate('easeInQuad', 300, perc => {
                this.loaderBg.alpha = 0.5 - perc / 2
                this.loaderText.alpha = 1 - perc
            })
            this.loaderContainer.visible = false
            //console.log('CHANGED')
        })

        document.addEventListener(MapService.MINER_ADDED_ON_MAP_EVENT, () => {
            this.setInfos()
        })

        document.addEventListener(MapService.COMET_ADDED_ON_MAP_EVENT, () => {
            this.setInfos()
        })

        document.addEventListener(MapService.STAKING_COMET_ADDED_ON_MAP_EVENT, () => {
            this.setInfos()
        })

        document.addEventListener(StakedSpaceshipsService.SHIP_LEAVE_GAME_EVENT, () => {
            this.setInfos()
        })

        document.addEventListener(CometManagerService.REMOVE_COMET_EVENT, event => {
            const cometId = (event as CustomEvent).detail.cometId
            if (this.cometPreviewComponent.comet && cometId == this.cometPreviewComponent.comet.id) {
                this.cleanCometFocus()
            }
            this.setInfos()
        })

        document.addEventListener(MiningManagerService.MINED_EVENT, event => {
            const cometId = (event as CustomEvent).detail.cometId
            if (this.cometPreviewComponent.comet && cometId == this.cometPreviewComponent.comet.id) {
                let comet = MapService.getInstance().map.currentStar.cometes.find(it => it.id == cometId)
                if (!comet) comet = MapService.getInstance().map.currentStar.stakingComets.find(it => it.id == cometId)
                this.cometPreviewComponent.update(comet)
            }
        })

        document.addEventListener(MiningManagerService.UPDATE_COMET, event => {
            const cometId = (event as CustomEvent).detail.cometId
            if (this.cometPreviewComponent.comet && cometId == this.cometPreviewComponent.comet.id) {
                this.cometPreviewComponent.update(MapService.getInstance().map.currentStar.cometes.find(it => it.id == cometId))
            }
        })

        document.addEventListener(RoverManagerService.ROVER_DEPOSED_EVENT, event => {
            const cometId = (event as CustomEvent).detail.cometId
            if (this.cometPreviewComponent.comet && cometId == this.cometPreviewComponent.comet.id) {
                const comet = MapService.getInstance().map.currentStar.stakingComets.find(it => it.id == cometId)
                this.cometPreviewComponent.update(comet)
            }
        })

        document.addEventListener(RoverManagerService.ROVER_HARVEST_EVENT, event => {
            const cometId = (event as CustomEvent).detail.cometId
            if (this.cometPreviewComponent.comet && cometId == this.cometPreviewComponent.comet.id) {
                const comet = MapService.getInstance().map.currentStar.stakingComets.find(it => it.id == cometId)
                this.cometPreviewComponent.update(comet)
            }
        })

        this.mapComponent.container.addChild(this.jumpGraphic)
        this.mapComponent.container.addChild(this.jumpPathContainer)
        this.onResize()
        PlayerService.getInstance().updateMainPlayer(PlayerService.getInstance().miningPlayers[0])
    }

    public setInfos() {
        this.currentStar.updateData(MapService.getInstance().map.currentStar)
        this.leaderboardContainer.visible = GalaxyService.getInstance().canDisplayLeaderboard(MapService.getInstance().currentSolarSystemId)
        this.spaceBg.texture = PIXI.Texture.from(GalaxyService.getInstance().getBackground(MapService.getInstance().map.currentStar.id))
        this.onResize()
    }

    updateZoom = (event: WheelEvent) => {
        if (this.isAnimating) {
            return
        }

        let ratio = 0
        if (event.deltaY < 0) {
            ratio = Math.exp(1 / (this.mapComponent.zoom + 0.5) / 10) - 1
        } else {
            ratio = Math.log10(this.mapComponent.zoom + 1.5) / 2
        }
        const delta = event.deltaY > 0 ? 1 - ratio : 1 + ratio

        this.zoomTo(this.mouseX, this.mouseY, delta)
    }

    getMousePosition = (event: MouseEvent) => {
        this.mouseX = event.clientX
        this.mouseY = event.clientY

        if (this.minerPreview) {
            this.minerPreview.getContainer().x = this.mouseX + 5 * Resolution.scale
            this.minerPreview.getContainer().y = this.mouseY
            if (this.minerPreview.getContainer().x + this.minerPreview.getContainer().width > Resolution.realWidth) {
                this.minerPreview.getContainer().x = this.mouseX - this.minerPreview.getContainer().width - 5 * Resolution.scale
            }
        }
    }

    async enterAnimation(callback: () => void): Promise<void> {
        this.onResize()
        document.addEventListener('wheel', this.updateZoom)
        document.addEventListener('mousemove', this.getMousePosition)
        this.mapComponent.container.position.x = Resolution.realWidth / 2
        this.mapComponent.container.position.y = Resolution.realHeight / 2 - 10 * Resolution.scale
        this.updateMapArea()
        this.recenterSpaceBg()
        this.spaceBg.alpha = 0
        await animate('easeOutQuad', 300, (perc: number) => {
            this.bg.position.x = Resolution.realWidth - Resolution.realWidth * perc
            this.bg.position.y = Resolution.realHeight - Resolution.realHeight * perc
            this.bg.tilePosition.x = (Resolution.realWidth / Resolution.scale) * perc
            this.bg.tilePosition.y = (Resolution.realHeight / Resolution.scale) * perc
            this.mapComponent.container.alpha = 1 * perc
        })
        await animate('easeOutQuad', 100, (perc: number) => {
            this.spaceBg.alpha = perc
        })
        callback()
        TutorialService.getInstance().startTutorialForMap()
    }

    async exitAnimation(callback: () => void): Promise<void> {
        this.currentModal?.onExit(false)
        document.removeEventListener('wheel', this.updateZoom)
        document.removeEventListener('mousemove', this.getMousePosition)
        const initialTileX = this.bg.tilePosition.x
        const initialTileY = this.bg.tilePosition.y
        await animate('easeOutQuad', 100, (perc: number) => {
            this.spaceBg.alpha = 1 - perc
        })
        await animate('easeInQuad', 300, (perc: number) => {
            this.bg.position.x = Resolution.realWidth * perc
            this.bg.position.y = Resolution.realHeight * perc
            this.bg.tilePosition.x = initialTileX - (Resolution.realWidth / Resolution.scale) * perc
            this.bg.tilePosition.y = initialTileY - (Resolution.realHeight / Resolution.scale) * perc
            this.mapComponent.container.alpha = 1 - 1 * perc
        })
        callback()
    }

    onResize(): void {
        this.currentStar.scale.set(Resolution.scale)
        this.leaderboardContainer.scale.set(Resolution.scale)
        this.toolsContainer.scale.set(Resolution.scale)
        this.priceHeatContainer.scale.set(Resolution.scale)
        this.leaderboardModal.onResize()
        this.strategyBtn.y = 0
        this.centerBtn.y = this.strategyBtn.y + this.strategyBtn.height + 6
        this.mapCloser.y = this.centerBtn.y + this.centerBtn.height + 6
        this.galaxyBtn.y = this.mapCloser.y + this.mapCloser.height + 6

        this.priceHeatContainer.position.x =
            Resolution.realWidth - this.centerBtn.width * Resolution.scale - Resolution.margin6 * 2 - 18 * Resolution.scale
        this.priceHeatContainer.position.y = Resolution.realHeight - this.priceHeatContainer.height - Resolution.margin6 - 46 * Resolution.scale

        this.toolsContainer.position.x = Resolution.realWidth - this.centerBtn.width * Resolution.scale - Resolution.margin6
        this.toolsContainer.position.y = Resolution.realHeight - this.toolsContainer.height - Resolution.margin6 - 32 * Resolution.scale

        this.currentStar.position.x = Resolution.realWidth - this.currentStar.width - Resolution.margin6
        this.currentStar.position.y = Resolution.realHeight - this.currentStar.height - Resolution.margin6

        this.leaderboardContainer.x = Math.floor(Resolution.realWidth - 65 * Resolution.scale - this.miniLeaderboard.width * Resolution.scale)
        this.leaderboardContainer.y = Math.floor(Resolution.realHeight - 59 * Resolution.scale + Resolution.margin2 * 5 - Resolution.margin6)
        this.icLeaderboard.x = this.miniLeaderboard.width + 2

        this.loaderContainer.scale.set(Resolution.scale)
        this.loaderBg.tint = 0x000000
        this.loaderBg.width = Resolution.realWidth / Resolution.scale
        this.loaderBg.height = Resolution.realHeight / Resolution.scale
        this.loaderText.y = Math.floor(Resolution.realHeight / Resolution.scale / 2 - this.loaderText.height / 2)
        this.loaderText.x = Math.floor(Resolution.realWidth / Resolution.scale / 2 - this.loaderText.width / 2)

        this.minerJumpPreviewComponent.onResize()
        this.minerJumpPreviewComponent.getContainer().x =
            Resolution.realWidth - this.minerJumpPreviewComponent.getContainer().width - Resolution.margin6
        this.minerJumpPreviewComponent.getContainer().y = Resolution.margin6
        this.minerHeight = this.minerJumpPreviewComponent.getContainer().height

        this.cometPreviewComponent.onResize()
        if (this.minerJumpPreviewComponent.getContainer().visible) {
            this.cometPreviewContainer.position.y = Resolution.margin6 + this.minerHeight
        } else {
            this.cometPreviewContainer.position.y = 0
        }
        this.cometPreviewContainer.position.x = Resolution.realWidth - this.cometPreviewContainer.width - Resolution.margin6

        this.bg.width = Resolution.realWidth / Resolution.scale
        this.bg.height = Resolution.realHeight / Resolution.scale
        this.setPositions()
        this.currentModal?.onResize()
        this.currentJumpConfirm?.onResize()
        this.mapComponent.onResize()
        this.recenterSpaceBg()
        this.galaxyMap.onResize()
    }

    private setPositions() {
        // todo
    }

    public tick(): void {
        // this.bg!.tilePosition.y += 0.1
        this.currentModal?.tick()
        this.leaderboardModal.tick()
    }

    public getContainer(): PIXI.Container {
        return this.container
    }

    private async switchToSolarSystem(solarSystemID: number) {
        const initX = this.mapComponent.getContainer().x
        const initY = this.mapComponent.getContainer().y
        this.isAnimating = true
        const initialZoom = this.mapComponent.zoom
        await animate('easeOutQuad', 500, perc => {
            this.mapComponent.updateZoom(initialZoom * (1 - perc) + 0.000001)
            this.mapComponent.getContainer().x = initX * (1 - perc) + (Resolution.realWidth / 2) * perc
            this.mapComponent.getContainer().y = initY * (1 - perc) + (Resolution.realHeight / 2 - 10 * Resolution.scale) * perc
        })
        this.isAnimating = false
        await MapService.getInstance().switchToSolarSystem(solarSystemID)
    }

    private zoomTo(x: number, y: number, delta: number) {
        const oldX = x - this.mapComponent.getContainer().x
        const oldY = y - this.mapComponent.getContainer().y
        this.mapComponent.updateZoom(this.mapComponent.getZoom() * delta)

        this.mapComponent.getContainer().x = x - oldX * delta
        this.mapComponent.getContainer().y = y - oldY * delta
        this.drawJumpGraphic()

        this.updateMapArea()
        this.recenterSpaceBg()
    }

    private drawJumpGraphic() {
        if (MapService.getInstance().currentSolarSystemId != this.currentMiner.solarSystemID) return

        if (this.isJumping) {
            this.jumpGraphic.alpha = 1
        } else {
            this.jumpGraphic.alpha = 0.8
        }

        this.jumpGraphic.clear()

        let fromX = this.currentMiner.x
        let fromY = this.currentMiner.y

        const finalJumpPath = this.jumpPath.map(it => it)
        if (this.tempPathItem) {
            if (KeyboardService.getInstance().isShiftPressed && this.jumpPath.length > 0) {
                finalJumpPath.splice(this.currentJumpConfirm.jumpIndex + 1, 0, this.tempPathItem)
            } else {
                finalJumpPath.splice(this.currentJumpConfirm.jumpIndex, 1, this.tempPathItem).forEach(it => it.text.parent?.removeChild(it.text))
            }
        }
        if (!finalJumpPath.length) return

        finalJumpPath.forEach((target, i) => {
            const toX = fromX - (fromX - target.miner.x) / 2
            const toY = fromY - (fromY - target.miner.y) / 2

            if (!target.text.parent) {
                this.jumpPathContainer.addChild(target.text)
            }

            if (target.text.text != (i + 1).toFixed(0)) {
                target.text.text = (i + 1).toFixed()
            }
            target.text.scale.set(Resolution.scale)
            target.text.x = Math.floor(toX * this.mapComponent.scale - target.text.width / 2)
            target.text.y = Math.floor(toY * this.mapComponent.scale - target.text.height / 2)
            target.text.hitArea = new PIXI.Circle(2, 2, 3.5)

            this.jumpGraphic.lineStyle(Resolution.scale, 0xaa0000, 1)
            if (i == finalJumpPath.length - 1) {
                const radius = Distance.getDistance(0, 0, toX, toY) * this.mapComponent.scale
                this.jumpGraphic.drawCircle(0, 0, radius)
            }
            this.jumpGraphic.lineStyle(Resolution.scale, 0xff0000, 1)
            this.jumpGraphic.moveTo(fromX * this.mapComponent.scale, fromY * this.mapComponent.scale)
            this.jumpGraphic.lineTo(toX * this.mapComponent.scale, toY * this.mapComponent.scale)
            this.jumpGraphic.lineStyle(Resolution.scale, 0x880000, 1)
            this.jumpGraphic.moveTo(toX * this.mapComponent.scale, toY * this.mapComponent.scale)
            if (i == 0 || (i >= 1 && target.miner.id != finalJumpPath[i - 1].miner.id))
                this.drawDashLine(
                    toX * this.mapComponent.scale,
                    toY * this.mapComponent.scale,
                    target.miner.x * this.mapComponent.scale,
                    target.miner.y * this.mapComponent.scale
                )

            fromX = toX
            fromY = toY
        })

        fromX = this.currentMiner.x
        fromY = this.currentMiner.y
        finalJumpPath.forEach((target, i) => {
            const toX = fromX - (fromX - target.miner.x) / 2
            const toY = fromY - (fromY - target.miner.y) / 2

            if (target.active) {
                this.jumpGraphic.lineStyle(Resolution.scale, 0xff4444, 1)
                this.jumpGraphic.beginFill(0xcc3333)
            } else {
                this.jumpGraphic.lineStyle(Resolution.scale, 0xff0000, 1)
                this.jumpGraphic.beginFill(0xaa0000)
            }

            let circleRadius = 3.5
            if (i == this.currentJumpConfirm?.jumpIndex) {
                circleRadius = 5
            }

            this.jumpGraphic.drawCircle(toX * this.mapComponent.scale, toY * this.mapComponent.scale, circleRadius * Resolution.scale)

            fromX = toX
            fromY = toY
        })
    }

    private stopJumping() {
        if (this.currentJumpConfirm) {
            this.isJumping = false
            this.jumpPathContainer.removeChildren()
            this.jumpPath = []
            this.currentJumpConfirm.update([])
            this.jumpGraphic.clear()

            this.minerJumpPreviewComponent.update(undefined)
            this.minerJumpPreviewComponent.exitAnimation(() => {
                return
            })
        }
    }

    private drawDashLine(fromX: number, fromY: number, toX: number, toY: number) {
        const currentPosition = {
            x: fromX,
            y: fromY
        }

        const dash = Math.max(4 * Resolution.scale, (4 * Resolution.scale * this.mapComponent.scale) / 2)

        const distance = Distance.getDistance(fromX, fromY, toX, toY)
        const diffX = (toX - fromX) / distance
        const diffY = (toY - fromY) / distance

        const comparator = (val: number, a: number, b: number) => {
            return val > 0 ? a <= b : b <= a
        }

        const additioner = (val: number, adder: number) => {
            return val >= 0 ? adder : adder
        }

        const mustAddX = additioner(diffX, dash) * diffX
        const mustAddY = additioner(diffY, dash) * diffY

        let index = 0
        while ((comparator(diffX, currentPosition.x, toX) || comparator(diffY, currentPosition.y, toY)) && index < 1000) {
            currentPosition.x = comparator(diffX, currentPosition.x + mustAddX, toX) ? currentPosition.x + mustAddX : toX
            currentPosition.y = comparator(diffY, currentPosition.y + mustAddY, toY) ? currentPosition.y + mustAddY : toY

            if (index % 2) {
                this.jumpGraphic.lineTo(currentPosition.x, currentPosition.y)
            } else {
                this.jumpGraphic.moveTo(currentPosition.x, currentPosition.y)
            }

            index++
        }
    }

    private fitMapToBounds() {
        this.mapComponent.updateZoom(this.getFitRatio())
    }

    private getFitRatio() {
        let minX = Number.MAX_VALUE
        let minY = Number.MAX_VALUE
        let maxX = Number.MIN_VALUE
        let maxY = Number.MIN_VALUE

        let players = PlayerService.getInstance().miningPlayers.filter(a => a.solarSystemID == MapService.getInstance().currentSolarSystemId)

        if (players.length == 0) {
            players = MapService.getInstance().map.currentStar.miners
        }

        if (players.length == 0) {
            players = []
            minX = -900
            maxX = 900
            minY = -900
            maxY = 900
        }

        players.forEach(player => {
            const x = Distance.getDistance(0, 0, player.x, player.y)
            const y = x

            if (x > maxX) {
                maxX = x
            }
            if (y > maxY) {
                maxY = y
            }
            if (x < minX) {
                minX = x
            }
            if (y < minY) {
                minY = y
            }
        })

        if (-maxX < minX) {
            minX = -maxX
        }
        if (-maxY < minY) {
            minY = -maxY
        }

        const yDist = maxY - minY + 20
        const xDist = maxX - minX + 20

        let yRatio = Resolution.realHeight / Resolution.scale / 1.2 / yDist
        let xRatio = Resolution.realWidth / Resolution.scale / 1.2 / xDist

        if (yRatio < xRatio) {
            xRatio = yRatio
        } else {
            yRatio = xRatio
        }

        return xRatio
    }

    private async centerMap() {
        if (this.isAnimating) {
            return
        }
        const fitRatio = this.getFitRatio()
        const initialZoom = this.mapComponent.zoom

        const initX = this.mapComponent.getContainer().x
        const initY = this.mapComponent.getContainer().y
        this.isAnimating = true
        await animate('easeOutQuad', 500, perc => {
            this.mapComponent.updateZoom(initialZoom * (1 - perc) + fitRatio * perc)
            this.mapComponent.getContainer().x = initX * (1 - perc) + (Resolution.realWidth / 2) * perc
            this.mapComponent.getContainer().y = initY * (1 - perc) + (Resolution.realHeight / 2 - 10 * Resolution.scale) * perc

            this.recenterSpaceBg()
            this.updateMapArea()
        })
        this.isAnimating = false
    }

    private updateMapArea() {
        this.mapComponent.updateAreaVisibility(
            new PIXI.Rectangle(
                -this.mapComponent.getContainer().position.x,
                -this.mapComponent.getContainer().position.y,
                Resolution.realWidth,
                Resolution.realHeight
            )
        )
    }

    private constructPriceHeatMapBar() {
        const bg = PIXI.Sprite.from('bg_pull_price_bar')
        const title = new MainBitmapText('Pull Price Scale', {
            fontSize: 5
        })
        title.rotation = -Math.PI / 2
        title.y = bg.height - 3
        title.x = 2

        const helpText = new MainBitmapText('Click on legend to toggle visibility', {
            fontSize: 3
        })
        helpText.rotation = -Math.PI / 2
        helpText.y = bg.height - 3
        helpText.x = 19
        helpText.filters = [new OutlineFilter(Resolution.scale, Colors.Blue300) as any]

        this.priceHeatContainer.addChild(bg)
        this.priceHeatContainer.addChild(title)
        this.priceHeatContainer.addChild(helpText)
        for (let index = 0; index < 10; index++) {
            const sprite = PIXI.Sprite.from('pixel')
            sprite.width = 6
            sprite.height = 5.6
            sprite.y = 3 + 1 * index + sprite.height * index
            sprite.x = 10
            sprite.tint = this.mapComponent.getHeatMapPercColor(9 - index)
            this.priceHeatContainer.addChild(sprite)

            sprite.interactive = true
            sprite.cursor = 'pointer'
            addTooltip(
                sprite,
                this.priceHeatContainer,
                () => {
                    return this.getTextForQuantile(9 - index)
                },
                TooltipPosition.Left
            )

            sprite.alpha = this.mapComponent.isQuantileDisabled(9 - index) ? 0.1 : 1
            sprite.interactive = true
            sprite.buttonMode = true
            sprite.on('pointertap', () => {
                if (this.mapComponent.isQuantileDisabled(9 - index)) {
                    this.mapComponent.enableQuantile(9 - index)
                    sprite.alpha = 1
                } else {
                    this.mapComponent.disableQuantile(9 - index)
                    sprite.alpha = 0.15
                }
            })
        }
        this.priceHeatContainer.visible = true
    }

    private getTextForQuantile(index: number) {
        const quantiles = MapService.getInstance().quantiles
        if (quantiles.length == 0) return '-'

        if (index == 0) {
            return 'Below ' + quantiles[0]
        } else if (index >= 9) {
            return 'Above ' + quantiles[8]
        } else {
            return 'Between ' + quantiles[index - 1] + ' and ' + quantiles[index]
        }
    }

    private onMinerOut() {
        if (!this.isJumping) {
            this.stopJumping()
        }
        this.container.removeChild(this.minerPreview.getContainer())
    }

    private startJumpConfirmation(miner: Miner) {
        if (miner.solarSystemID != this.currentMiner.solarSystemID) return

        this.handleJumpPush(miner)
        this.minerJumpPreviewComponent.update(miner)
        this.minerJumpPreviewComponent.enterAnimation(() => {
            return
        })

        if (this.isJumping) {
            this.currentJumpConfirm?.update(this.jumpPath.map(a => a.miner))
            this.drawJumpGraphic()
            return
        }

        this.isJumping = true

        this.currentJumpConfirm?.update(this.jumpPath.map(a => a.miner))
        this.container.addChild(this.currentJumpConfirm.getContainer())
        this.currentJumpConfirm.enterAnimation(() => {
            return
        })
        this.currentJumpConfirm.onCancel = () => {
            this.stopJumpModal()
        }
        this.currentJumpConfirm.onConfirm = (gasPriceGWEI: number) => {
            this.currentJumpConfirm?.exitAnimation(() => {
                this.batchJump(gasPriceGWEI)
                this.stopJumpModal()
            })
        }
        this.drawJumpGraphic()
    }

    private batchJump(gasPriceGWEI?: number) {
        JumpManagerService.getInstance()
            .batchjump(
                PlayerService.getInstance().miner.id,
                this.jumpPath.map(item => item.miner.id),
                this.jumpPath.map(item => item.miner.pullingPrice.toString()),
                this.currentMiner.solarSystemID,
                gasPriceGWEI
            )
            .then(() => {
                this.notificationManager.cleanMiningNotification(this.currentMiner.id)
            })
        this.stopJumpModal()
    }

    private stopJumpModal() {
        if (this.currentJumpConfirm) {
            const confirm = this.currentJumpConfirm!
            confirm.exitAnimation(() => {
                this.container.removeChild(confirm.getContainer())
            })
            this.stopJumping()
        }
    }

    private handlePreviewEvents() {
        this.minerJumpPreviewComponent.onRequireClose = () => {
            this.stopJumpModal()
        }
        this.cometPreviewComponent.onRequireClose = () => {
            this.cleanCometFocus()
        }
        this.cometPreviewComponent.onRequirePreview = (comet: Comete | StakingComet) => {
            this.onEntityPreview(comet, this.cometPreviewContainer.y + Resolution.margin6)
        }
        this.cometPreviewComponent.onExitPreview = () => {
            this.onEntityPreviewClear()
        }
        this.minerJumpPreviewComponent.onRequirePreview = (miner: Miner) => {
            this.onEntityPreview(miner, this.minerJumpPreviewComponent.getContainer().y)
        }
        this.minerJumpPreviewComponent.onExitPreview = () => {
            this.onEntityPreviewClear()
        }
    }

    private cleanCometFocus() {
        this.cometPreviewComponent.update(undefined)
        this.mapComponent.focusedComet = undefined
        this.mapComponent.drawCometeOrbits()
        this.cometPreviewComponent.exitAnimation(() => {
            return
        })
    }

    private recenterSpaceBg() {
        this.spaceBg.anchor.set(0.5)
        let scale = this.mapComponent.scale
        if (scale < 1) {
            scale = Math.log(scale)
        }

        this.spaceBg.scale.set(Resolution.scale + (scale / 50) * Resolution.scale)
        this.spaceBg.x =
            (this.mapComponent.getContainer().position.x * 0.01 * Math.abs(scale)) / 20 + 1371 / 2 + Resolution.realWidth / 2 / Resolution.scale
        this.spaceBg.y =
            (this.mapComponent.getContainer().position.y * 0.01 * Math.abs(scale)) / 20 + 914 / 2 + Resolution.realHeight / 2 / Resolution.scale
    }

    private async toggleGalaxyMap() {
        this.stopJumpModal()
        this.cleanCometFocus()

        if (this.isAnimating) return

        if (this.galaxyMap.visible) {
            this.mapComponent.getContainer().visible = true
            const currentStar = MapService.getInstance().map.currentStar
            this.galaxyMap.centerToStar(currentStar, true)
            this.priceHeatContainer.visible = this.mapComponent.mapFilter == MapFilter.PullPrice
            this.centerBtn.interactive = true
            this.strategyBtn.interactive = true
            this.mapCloser.interactive = true
            this.priceHeatContainer.alpha = 0
            this.centerBtn.alpha = 0
            this.strategyBtn.alpha = 0
            this.mapCloser.alpha = 0
            this.galaxyBtn.texture = PIXI.Texture.from('ic_galaxy')
            await animate('easeOutQuad', 500, perc => {
                this.galaxyMap.alpha = 1 - perc
                this.priceHeatContainer.alpha = perc
                this.centerBtn.alpha = perc * 0.8
                this.strategyBtn.alpha = perc * 0.8
                this.mapCloser.alpha = perc * 0.8
            })
            this.galaxyMap.visible = false
            this.isAnimating = false
        } else {
            const initX = this.mapComponent.getContainer().x
            const initY = this.mapComponent.getContainer().y
            this.isAnimating = true
            const initialZoom = this.mapComponent.zoom

            const currentStar = MapService.getInstance().map.currentStar

            this.galaxyMap.visible = true
            this.galaxyMap.alpha = 0
            this.galaxyMap.centerToStar(currentStar, false)

            this.galaxyBtn.texture = PIXI.Texture.from('ic_back_to_solar')
            await animate('easeOutQuad', 500, perc => {
                this.mapComponent.updateZoom(initialZoom * (1 - perc) + 0.000001)
                this.mapComponent.getContainer().x = initX * (1 - perc) + (Resolution.realWidth / 2) * perc
                this.mapComponent.getContainer().y = initY * (1 - perc) + (Resolution.realHeight / 2 - 10 * Resolution.scale) * perc

                this.priceHeatContainer.alpha = 1 - perc
                this.centerBtn.alpha = (1 - perc) * 0.8
                this.strategyBtn.alpha = (1 - perc) * 0.8
                this.mapCloser.alpha = (1 - perc) * 0.8
                this.galaxyMap.alpha = perc

                this.recenterSpaceBg()
                this.updateMapArea()
            })
            this.priceHeatContainer.visible = false
            this.centerBtn.interactive = false
            this.strategyBtn.interactive = false
            this.mapCloser.interactive = false
            this.mapComponent.getContainer().visible = false
            this.isAnimating = false
        }
    }

    private constructJumpItem(miner: Miner): JumpPathItem {
        const text = new MainBitmapText('1', {
            fontSize: 4
        })
        text.interactive = true
        const item = new JumpPathItem(text, miner)

        text.cursor = 'pointer'
        text.on('mouseover', () => {
            item.active = true
            this.minerPreview.asyncUpdateJumpStep(miner)
            this.container.addChild(this.minerPreview.getContainer())
            this.drawJumpGraphic()
        })
        text.on('mouseout', () => {
            item.active = false
            this.drawJumpGraphic()
            this.onMinerOut()
        })
        text.on('pointertap', (event: any) => {
            const index = parseInt(text.text) - 1
            if (event.data.button == 2) {
                this.deleteJumpPath(index)
            } else {
                if (this.currentJumpConfirm) {
                    this.currentJumpConfirm.updateJumpIndex(index)
                    this.drawJumpGraphic()
                }
            }

            return false
        })

        return item
    }

    private deleteJumpPath(index: number) {
        this.jumpPath.splice(index, 1).forEach(it => it.text.destroy())
        this.currentJumpConfirm.update(this.jumpPath.map(a => a.miner))
        if (this.jumpPath.length == 0) {
            this.stopJumpModal()
        }
        this.onMinerOut()

        this.drawJumpGraphic()
    }

    private handleJumpPush(miner: Miner) {
        if (this.jumpPath.length == 0) {
            this.jumpPath.push(this.constructJumpItem(miner))
        } else {
            if (KeyboardService.getInstance().isShiftPressed) {
                if (this.jumpPath.length >= JumpManagerService.MAX_JUMP_PATH_LENGTH) {
                    const notif = new Notification(NotificationType.Error)
                    notif.title = 'Error while adding a new jump.\nMax ' + JumpManagerService.MAX_JUMP_PATH_LENGTH + ' jump points allowed'
                    NotificationService.getInstance().add(notif)
                } else {
                    this.jumpPath.splice(this.currentJumpConfirm.jumpIndex + 1, 0, this.constructJumpItem(miner))
                }
            } else {
                this.tempJumpPathPreview.forEach(it => it.text.destroy)
                this.tempJumpPathPreview = this.jumpPath.splice(this.currentJumpConfirm.jumpIndex, 1, this.constructJumpItem(miner))
                this.tempJumpPathPreview.forEach(it => it.text.parent?.removeChild(it.text))
            }
        }
    }

    /*
    private handleMinerMouseOut() {
        if (KeyboardService.getInstance().isShiftPressed) {
            this.jumpPath.splice(this.currentJumpConfirm.jumpIndex + 1, 1)?.forEach(it => {
                it.text.destroy()
            })
        } else if (this.tempJumpPathPreview.length > 0) {
            this.jumpPath.splice(this.currentJumpConfirm.jumpIndex, 1, this.tempJumpPathPreview[0]).forEach(it => it.text.destroy())
            this.tempJumpPathPreview = []
        } else {
            this.jumpPath.splice(this.currentJumpConfirm.jumpIndex, 1).forEach(it => it.text.destroy())
        }
    }*/
}
