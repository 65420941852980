<template>
    <!-- container fixing body overflow hidden -->
    <div class="w-screen h-screen overflow-x-hidden overflow-y-auto bg-gray-50">
    <Header />
    <div class="max-w-7xl mx-auto mt-20 pb-2">
        <div class="px-4 sm:px-6 lg:px-8">

             <p v-if="noCodeProvided">No code provided</p>
             <p v-if="codeProvidedIsInvalide">The code provided is invalid</p>
             <p class="text-xl" v-if="!noCodeProvided && !codeProvidedIsInvalide">The code provided is <span class="bold">{{code}}</span></p>
        </div>
    </div>
  

    <transition name="fade" mode="out-in">
            <FriendList :friends="friends" />
    </transition>
    </div>
</template>

<script lang="ts" src="./Ambassador.ts"></script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
