<template>
    <div class="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">





    </div>
</template>

<script lang="ts" src="./ShipList.ts"></script>
