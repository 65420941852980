import { Vue } from 'vue-class-component'
import { Percent, ChainId, Token, TokenAmount, Pair, TradeType, Route, Trade, Fetcher } from '@cometh-game/comethswap-sdk'
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import Web3Service from '@/game/services/Web3Service'
import ReferralStarterKitService from '@/game/services/web3/ReferralStarterKitService'
import { Options } from 'vue-class-component'
import Header from '@/components/header/Header.vue'
import HeaderTs from '@/components/header/Header'
import Connection, { ConnectionType } from '@/components/connection/Connection'
import config from '@/config'

import Modal from '@/components/modal/Modal.vue'
import { ExclamationIcon, CheckIcon, XIcon } from '@heroicons/vue/outline'
import Countdown from './Countdown.vue'
import Rules from './Rules.vue'
import Academy from './Academy.vue'
import Ships from './Ships.vue'
import Game from './Game.vue'
import ButtonPrimary from '@/components/ButtonPrimary.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import axios from 'axios'
import LoaderIcon from '@/components/LoaderIcon.vue'

@Options({
    components: {
        Header,
        Modal,
        ExclamationIcon,
        CheckIcon,
        XIcon,
        Countdown,
        Rules,
        Academy,
        Ships,
        Game,
        ButtonPrimary,
        ButtonSecondary,
        LoaderIcon
    }
})
export default class Mule extends Vue {
    $refs!: {
        header: HeaderTs
    }

    public baseProvider!: JsonRpcProvider
    public matic!: Token
    public must!: Token
    public pair!: Pair
    public route!: Route
    public amountOut!: any

    /*
    public shipPrice = Web3Service.getInstance()
        .getWeb3()
        .utils.toWei('', 'ether') // Need to be equal to Starter Kit Price in Smart Contract
    public maticToSwap = Web3Service.getInstance()
        .getWeb3()
        .utils.toWei('10', 'ether')
    */

    public starterKitPrice = Web3Service.getInstance()
        .getWeb3()
        .utils.toWei('10', 'ether')
    public maticToSwap = Web3Service.getInstance()
        .getWeb3()
        .utils.toWei('5', 'ether')

    displayedMustAmount: string | null = null
    displayedStarterKitPrice = null

    muleModelId = config.mulesId

    isConnectedWallet = false
    hasEnoughMatic = false
    loginModal = false
    maticModal = false

    isOrderComplete = false

    isBuyingInitiated = false
    isBuying = false

    //isInsufficientBalance = false
    isChangingConnection = false

    codeIsProvided = false

    showConfirmationModal = false
    userHasConfirmed = false

    referralID = ''
    validatingCode = false
    codeError = false
    validReferralCodeAdded = false

    isRampPurchaseCreated = false

    USDPrice = ''

    async mounted() {
        if (this.$route.query.code) {
            this.referralID = this.$route.query.code?.toString()
            await this.validateCode()
        }

        this.isChangingConnection = Connection.getInstance().isConnecting

        Connection.getInstance().on('connecting', () => {
            console.log('connecting...')
            this.isChangingConnection = true
        })

        Connection.getInstance().on('providerChanged', async provider => {
            // console.log('provider changed...')
            this.isChangingConnection = false
            // check if disconnected
            if (!provider) {
                // user is disconnected
                return
            }

            console.log('user is connected, call init')
            await this.init()
        })

        const web3 = Web3Service.getInstance().getWssWeb3()

        this.baseProvider = new Web3Provider(web3.currentProvider)

        this.matic = await Fetcher.fetchTokenData(ChainId.MATIC, '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', this.baseProvider)
        this.must = await Fetcher.fetchTokenData(ChainId.MATIC, '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f', this.baseProvider)
        this.pair = await Fetcher.fetchPairData(this.matic, this.must, this.baseProvider)
        this.route = new Route([this.pair], this.matic)

        const trade = new Trade(this.route, new TokenAmount(this.matic, this.maticToSwap), TradeType.EXACT_INPUT)
        const slippageTolerance = new Percent('50', '10000') // 50 bips, or 0.50%
        this.amountOut = trade.minimumAmountOut(slippageTolerance).raw.toString() // needs to be converted to e.g. hex

        this.displayedStarterKitPrice = web3.utils.fromWei(this.starterKitPrice, 'ether')

        this.displayedMustAmount = trade.minimumAmountOut(slippageTolerance).toSignificant(6)

        this.init()
        this.getUSDPrice()
    }

    getUSDPrice() {
        axios.get(`https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USDC`).then(response => {
            const mulePriceUSDC = (Number(response.data.USDC) * 10).toFixed(2)
            this.USDPrice = mulePriceUSDC.toString()
        })
    }

    async init() {
        if (Connection.getInstance().getConnectionType() == ConnectionType.Metamask) {
            try {
                await (window as any).ethereum.request({
                    id: 1,
                    jsonrpc: '2.0',
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x' + config.network.toString(16),
                            chainName: config.l2NetworkLabel,
                            rpcUrls: [config.rpc],
                            iconUrls: ['https://polygon.technology/wp-content/uploads/2021/01/logo-polygon.png'],
                            nativeCurrency: {
                                name: 'Matic',
                                symbol: 'Matic',
                                decimals: 18
                            },
                            blockExplorerUrls: [config.etherscanUrl]
                        }
                    ]
                })
            } catch (e) {
                // metamask is probably outdated here
                console.log(e)
            }
        }

        //const isInsufficientBalance = await this.notEnoughMatic()

        if (this.isBuyingInitiated) {
            console.log('isBuyingInitiated is true, call buy function')
            await this.buy()
        }
    }

    async notEnoughMatic() {
        const web3 = Web3Service.getInstance().getWeb3()
        const to = await Web3Service.getInstance().getAccount()
        if (!to) return

        const balance = new web3.utils.BN(await web3.eth.getBalance(to))
        return balance.lt(new web3.utils.BN(this.starterKitPrice))
    }

    /* eslint-disable @typescript-eslint/camelcase */
    async buy() {
        if (!Connection.getInstance().isConnected()) {
            this.isBuyingInitiated = true
            this.$refs.header.openModal()
            return
        }

        const isInsufficientBalance = await this.notEnoughMatic()

        if (isInsufficientBalance) {
            this.isBuyingInitiated = true
            this.buyCrypto()
            return
        }

        if (!this.userHasConfirmed) {
            this.showConfirmationModal = true
            return
        }

        this.userHasConfirmed = false

        this.isBuying = true
        const provider = await Connection.getInstance().provider
        const web3 = Web3Service.getInstance().getWeb3()
        web3.setProvider(provider)

        const to = await Web3Service.getInstance().getAccount()

        const deadline = Math.floor(Date.now() / 1000) + 60 * 20 // 20 minutes from the current Unix time

        let code = ''
        if (this.validReferralCodeAdded) code = this.referralID

        await ReferralStarterKitService.getInstance().buyReferralStarterKit(
            this.amountOut,
            to,
            deadline,
            this.muleModelId,
            code,
            this.starterKitPrice
        )

        this.isBuying = false
        this.isBuyingInitiated = false
        this.isOrderComplete = true
        if ((window as any).sendinblue) {
          (window as any).sendinblue.track('get_mule_by_mail', {
            email: Connection.getInstance().getConnectedEmail(),
            mule_referral_code: this.referralID
          })
        }

        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({ event: 'get_mule_by_mail' })
        }
        this.$router.push('#get_mule_by_mail')
    }

    buyCrypto() {
        this.$refs.header.buyCrypto('20')
    }

    async onRampPurchased(payload: any) {
        this.isRampPurchaseCreated = false
        this.init()
    }

    async onRampPurchaseCreated(payload: any) {
        console.log('onRampPurchaseCreated')
        this.isRampPurchaseCreated = true
    }

    async onRampPurchaseFailed(payload: any) {
        console.log('onRampPurchaseFailed')
        this.isRampPurchaseCreated = false
    }

    async onRampCloseRequestConfirmed(payload: any) {
        console.log('onRampCloseRequestConfirmed')
        this.isRampPurchaseCreated = false
    }

    askForLogin() {
        this.loginModal = true
    }

    cancelLogin() {
        this.loginModal = false
    }

    logWithEmail() {
        this.isConnectedWallet = true
        this.loginModal = false
        this.buy()
    }

    askForMatic() {
        this.maticModal = true
    }

    cancelMaticBuy() {
        this.maticModal = false
    }

    confirmPurchase() {
        this.userHasConfirmed = true
        this.showConfirmationModal = false
        this.buy()
    }

    async validateCode() {
        this.codeError = false

        if (this.referralID?.trim() === '') {
            this.codeError = true
            return
        }

        this.validatingCode = true
        const referrer = await ReferralStarterKitService.getInstance().referrerByCode(this.referralID)
        this.validatingCode = false

        console.log('referrer', referrer)
        if (referrer != '0x0000000000000000000000000000000000000000') {
            this.validReferralCodeAdded = true
        } else {
            this.codeError = true
        }
    }

    removeCode() {
        this.referralID = ''
    }
}
