import * as PIXISound from 'pixi-sound'
import * as PIXI from 'pixi.js'

export default class SoundService {
    public static instance: SoundService

    public static getInstance(): SoundService {
        if (!this.instance) {
            this.instance = new SoundService()
        }

        return this.instance
    }

    public mainMusic: PIXISound.default.Sound

    constructor() {
        PIXISound.default.volumeAll = localStorage.getItem('volume') ? Number(localStorage.getItem('volume')) : 0.5
        this.mainMusic = (PIXI.Loader.shared.resources.music1 as any).sound
        this.mainMusic.loop = true
        this.mainMusic.play()
    }

    getMainVolume() {
        return PIXISound.default.volumeAll
    }

    updateMainVolume(volume: number) {
        PIXISound.default.volumeAll = volume
        localStorage.setItem('volume', volume.toFixed(2))
    }

    setMusicInGarage() {
        this.mainMusic.filters = []
    }

    setMusicNormal() {
        this.mainMusic.filters = []
    }
}
