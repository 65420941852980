import { ShipWithGameStat } from './ShipWithGameStat'
import Rent from '@/game/models/Rent'
import TheGraphService from '@/game/services/TheGraphService'
import WalletService from '@/game/services/web3/WalletService'
import Web3 from 'web3'

export default class RentalWithStat {
    id = ''
    lender = ''
    tenant = ''
    start = 0
    end = 0
    percentageForLender = 0
    ships: ShipWithGameStat[] = []
    shipsIds: string[] = []
    contractAddress = ''

    private _claimed = 0
    private _claimedMust = 0

    constructor(rent: Rent) {
        this.id = rent.id
        this.lender = rent.lender
        this.tenant = rent.tenant
        this.start = rent.start
        this.end = rent.end
        this.percentageForLender = rent.percentageForLender
        this.shipsIds = rent.nftIds
    }

    public async init(): Promise<RentalWithStat> {
        for (let i = 0; i < this.shipsIds.length; i++) {
            this.ships.push(await new ShipWithGameStat(this.shipsIds[i], this.id).init())
        }
        const claims = await TheGraphService.getInstance().getRentalClaims(this.id)
        const walletService = WalletService.getInstance()
        for (let i = 0; i < claims.length; i++) {
            const price = await walletService.USDPriceOf(claims[i].token.id)
            if (claims[i].token.id == WalletService.MUST_ADDRESS) {
                this._claimedMust += price * parseFloat(Web3.utils.fromWei(claims[i].amount))
            } else {
                this._claimed += price * parseFloat(Web3.utils.fromWei(claims[i].amount))
            }
        }

        return this
    }

    claimed(): number {
        return Math.floor(this._claimed * this.percentageForLender) / 100
    }

    mustReward(): number {
        return Math.floor(this._claimedMust * this.percentageForLender) / 100
    }

    reward(): number {
        let reward = 0
        for (let i = 0; i < this.ships.length; i++) {
            reward += this.ships[i].earned()
        }
        return Math.floor(reward * this.percentageForLender) / 100
    }
}
